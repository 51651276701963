export const TEXT = {
	name: {
		patient: `Patient | {{name}}`,
		edit: `Edit`,
	},
	fields: {
		dateOfBirth: `Date of Birth`,
		id: `Patient ID (Optional)`,
		gender: `Gender Identity (Optional)`,
		race: `Race (Optional)`,
		ethnicity: `Ethnicity (Optional)`,
		education: `Education Level`,
		handedness: `Dominant Hand`,
		language: `Language`,
		sex: 'Sex',
	},
	notes: {
		show: `Show Notes`,
		hide: `Hide Notes`,
	},
};
