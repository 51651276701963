import styled from 'styled-components';
import { H2, P1, Subtitle } from '../../shared/designSystem/TextComponents';
import { Trans, useTranslation } from 'react-i18next';
import { AddBatteryProps } from '../../shared/DataTable/schemas/patientsSchema';

type RemoveBatteryForPatientHeadeProps = {
	patient: AddBatteryProps;
};

const RemoveBatteryForPatientHeader = ({
	patient,
}: RemoveBatteryForPatientHeadeProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledHeader>
			<StyledTitle>
				<StyledH2>{t`web.shared.removeBatteryModal.removeBattery`}</StyledH2>
			</StyledTitle>
			<StyledSubtitle>
				<Trans
					i18nKey='web.shared.removeBatteryModal.confirmRemoval'
					values={{
						firstName: patient?.firstName,
						lastName: patient?.lastName,
					}}
					components={{
						b: <strong />,
					}}
				/>
			</StyledSubtitle>
			<StyledSubtitle>{t`web.shared.removeBatteryModal.pleaseNoteIssues`}</StyledSubtitle>
		</StyledHeader>
	);
};

export { RemoveBatteryForPatientHeader };

const StyledHeader = styled.div`
	width: 548px;
`;

const StyledTitle = styled(Subtitle)`
	display: flex;
	justify-content: flex-start;
`;

const StyledSubtitle = styled(P1)(
	({ theme: { spacing, color } }) => `
	padding-top: ${spacing.sm};
	color: ${color.textSubtitle}
`
);

const StyledH2 = styled(H2)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
`
);
