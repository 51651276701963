import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const ScoreValue = styled(P1)<{ scoreColor: string }>(
	({ scoreColor, theme: { weight } }) => css`
		font-weight: ${weight.semi};
		color: ${scoreColor};
	`
);

export const Content = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: flex-start;
		gap: ${spacing.sm};
		width: 100%;
	`
);

export const Info = styled.div(
	({ theme: { spacing } }) => css`
		flex: none;
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-wrap: wrap;
		gap: ${spacing.sm};
	`
);

export const Title = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const ScoreBox = styled.div<{ backgroundColor: string }>(
	({ backgroundColor, theme: { spacing } }) => css`
		display: inline-flex;
		white-space: nowrap;
		align-items: center;
		border-radius: 8px;
		gap: ${spacing.xxs};
		padding: ${spacing.xs} 21px;
		background-color: ${backgroundColor};
	`
);

export const MedP2 = styled.div(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);
