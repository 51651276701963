/* eslint-disable */
/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/

/*

  Data model files.  Turns raw data into usable objects.

*/

//################################################################################
//An upload object
//################################################################################
function dctUpload(arg_raw) {
	//Save raw
	this._raw = arg_raw;

	//Initialization
	this._sections = (arg_raw.sections || []).map(function (s, i) {
		return new dctSection(s, null, i);
	});
}
dctUpload.prototype.getDate = function () {
	return this._date;
};

//################################################################################
//Ordered strokes object - used for generating CSKs
//################################################################################
function dctOrderedStrokeList(arg_strokes, arg_device_type, arg_device_serial) {
	//Sort the strokes by start time
	arg_strokes.sort(function (a, b) {
		return a.start_time - b.start_time;
	});

	//Save
	this.device_type = arg_device_type;
	this.device_serial = arg_device_serial;
	this.strokes = arg_strokes;
	this.metrics = dctOrderedStrokeList.ORDERED_STROKE_METRICS;
}

//Constants
dctOrderedStrokeList.ORDERED_STROKE_METRICS = ['x', 'y', 'tdelta', 'pressure'];

//CSK
dctOrderedStrokeList.prototype.getCSK = function () {
	//Test start time
	var last_page_address = null;
	var stroke_count = 0;
	var stroke_lines = [];
	var header = [];
	header.push('<ClockSketch version="2.0">');
	header.push('<PenData>');
	header.push('Pen id: ' + this.device_serial);
	header.push('Number of pages: 1'); //Not used - it's ALWAYS 1 in our system, included for legacy compatibility.

	//Metrics
	var metric_x = this.metrics.indexOf('x');
	var metric_y = this.metrics.indexOf('y');
	var metric_tdelta = this.metrics.indexOf('tdelta');
	var metric_pressure = this.metrics.indexOf('pressure');
	var metric_count = this.metrics.length;

	//Walk the strokes
	for (var s = 0; s < this.strokes.length; s++) {
		var stroke = this.strokes[s];
		stroke_count++;
		if (last_page_address !== stroke.page_address) {
			//First time it appears, goes in the header.  Second time, goes in the middle.
			var push_to = last_page_address ? stroke_lines : header;

			//Generate address line.  "#1", part is irrelevant, it's old page number, not used but added for legacy's sake.
			push_to.push(
				'Page address: ' +
					stroke.page_address.replace(/^0\./, '') +
					'#1'
			);
			if (stroke.page_bounds) {
				var b = stroke.page_bounds;
				push_to.push(
					'Page bounds: ' +
						[b.top, b.left, b.bottom, b.right].join(' ')
				);
			}

			//Save previous
			last_page_address = stroke.page_address;
		}
		stroke_lines.push('StrokeID: ' + stroke_count);
		stroke_lines.push(
			'Number of samples: ' + stroke.data.length / metric_count
		);
		stroke_lines.push(
			'StartTime: ' + (stroke.start_time / 1000).toFixed(3)
		);

		//Walk through all the data
		var data = stroke.data;
		for (var i = 0; i < data.length; i += metric_count) {
			//Variable convenience
			var x = data[i + metric_x],
				y = data[i + metric_y],
				tdelta = data[i + metric_tdelta],
				pressure = data[i + metric_pressure];
			stroke_lines.push(
				[x.toFixed(4), y.toFixed(4), tdelta, pressure].join(' ')
			);
		}
	}

	//Rest of the header
	header.push('Number of strokes: ' + stroke_count);

	var footer = [];
	footer.push('</PenData>');
	footer.push('</ClockSketch>');

	return (
		header.join('\r\n') +
		'\n' +
		stroke_lines.join('\r\n') +
		'\n' +
		footer.join('\r\n')
	);
};

//Merge multiple
dctOrderedStrokeList.merge = function (arg_ordered_strokes_array) {
	//Concatenate the raw strokes together
	var raw_strokes = Array.prototype.concat.apply(
		[],
		arg_ordered_strokes_array.map(function (s) {
			return s.strokes;
		})
	);

	//Data from the first one is used
	var first = arg_ordered_strokes_array[0];

	//Return a new strokelist
	return new dctOrderedStrokeList(
		raw_strokes,
		first.device_type,
		first.device_serial
	);
};

//################################################################################
//A test suite object (corresponds to a collection of test rows)
//################################################################################
function dctTestSuite(arg_raw) {
	//Save raw
	this._raw = arg_raw;
	this._first_raw = arg_raw[0] || {};

	//Some initializations
	this.tests = this._raw.map(function (row) {
		return new dctTest(row);
	});
	this.suite_meta = this._first_raw.doc_data.suite_meta || {};
	this.patient_info = this._first_raw.doc_data.patient_info;
	this.device_info = this._first_raw.doc_data.device_info;
	this.device_state = this._first_raw.doc_data.device_state;
	this.start_time = this.suite_meta.start_time;
	this.end_time = this.suite_meta.end_time;
	this.count = this.tests.length;
}

dctTestSuite.prototype.getFinalStartTime = function () {
	return this._first_raw.test_data.start_time;
};
dctTestSuite.prototype.getComment = function () {
	return this._first_raw.test_data.comment;
};

dctTestSuite.prototype.getTestId = function () {
	return this._first_raw.test_id;
};
dctTestSuite.prototype.getIdentifyData = function () {
	return this._first_raw.identify_data;
};
dctTestSuite.prototype.getPatientInfo = function () {
	return this._first_raw.doc_data.patient_info;
};
dctTestSuite.prototype.getOrgId = function () {
	return this._first_raw.org_id;
};
dctTestSuite.prototype.getProviderId = function () {
	return this._first_raw.provider_id;
};
dctTestSuite.prototype.getProviderData = function () {
	return this._first_raw.provider_data;
};
dctTestSuite.prototype.getDeletedBy = function () {
	return this._first_raw.deleted_by;
};

dctTestSuite.prototype.getStartDate = function () {
	return new Date(this.start_time);
};
dctTestSuite.prototype.getEndDate = function () {
	return new Date(this.end_time);
};

dctTestSuite.prototype.getStartTime_Epoch = function () {
	return this.start_time;
};
dctTestSuite.prototype.getStopTime_Epoch = function () {
	return this.end_time;
};
dctTestSuite.prototype.getDuration = function () {
	return this.end_time - this.start_time;
};

dctTestSuite.prototype.getDeviceType = function () {
	return this._first_raw.device_type;
};
dctTestSuite.prototype.getDeviceSerial = function () {
	return this._first_raw.device_serial;
};

dctTestSuite.prototype.getSectionsMissing = function () {
	return this._first_raw.sections_missing || [];
};

//################################################################################
//A test object (corresponds to a document)
//################################################################################
function dctTest(arg_raw) {
	//Save raw
	this._raw = arg_raw;
	this._transformLegacy();

	//Some initialization
	var self = this;
	this._date = new Date();
	this._date.setTime(this._raw.start_time);

	//Get drawing sections - a semi-hack to get legacy objects into a particular format
	var test_data = this.getTestData();
	var sections = [];
	for (var k in test_data) {
		if (test_data[k].drawing) {
			test_data[k].key = k;
			sections.push(test_data[k]);
		}
	}
	sections.sort(function (a, b) {
		return a.start_time - b.start_time;
	});
	this._sections = sections.map(function (s, i) {
		return new dctSection(s, self, i);
	});

	//Stop time calculation
	//XXX TODO RESTORE
	//this._duration = this._sections.length ? this._sections[this._sections.length-1].getStopTime_Test() : 0;
}

//Transform legacy tests into new format
dctTest.prototype._transformLegacy = function () {
	//If it has the "sections" object, it's a legacy test; convert the sections.
	var doc_data = this._raw.doc_data;
	if (doc_data.sections && doc_data.sections.length) {
		//Add the suite meta property
		const last_section = doc_data.sections[doc_data.sections.length - 1];
		const end_time =
			last_section.segments[last_section.segments.length - 1].stop +
			doc_data.start_time;
		doc_data.suite_meta = {
			start_time: doc_data.start_time,
			end_time: end_time,
		};

		//Add some other properties
		doc_data.patient_info = {};
		doc_data.device_info = {
			device_type: doc_data.device_type,
			device_serial: doc_data.device_serial,
		};
		doc_data.device_state = {};

		//Conversion function
		function convert_legacy_section(key, test_data, sections) {
			//Find it
			var s = sections.find(function (s) {
				return s.id === key;
			});
			if (!s) return;

			//Assign it
			const result = (test_data[key] = {});

			result.start_time = doc_data.start_time + s.segments[0].start;
			const drawing = (result.drawing = {});
			drawing.page_address = s.page_address;
			const drawing_data = (drawing.data = {});
			drawing_data.interruptions = [];
			drawing_data.page_address = drawing.page_address;
			drawing_data.bounds = {
				x: s.page_bounds.left,
				y: s.page_bounds.top,
				width: s.page_bounds.right - s.page_bounds.left,
				height: s.page_bounds.bottom - s.page_bounds.top,
			};
			drawing_data.metrics = s.metrics;
			drawing_data.start_time = result.start_time;
			drawing_data.page_params = {};
			drawing_data.end_time =
				doc_data.start_time + s.segments[s.segments.length - 1].stop;
			drawing_data.id = s.id;
			drawing.bounds = drawing_data.bounds; //Why is this copied?
			drawing.page_params = drawing_data.page_params; //Why is this copied?

			const stroke_data = (drawing_data.stroke_data = []);
			s.segments.forEach(function (seg) {
				if (!seg.on) return; //Ignore off segments
				stroke_data.push(seg.data);
			});

			return result;
		}

		//Test data
		doc_data.test_data = {};
		doc_data.test_data.test_params = {};
		convert_legacy_section(
			'command_clock',
			doc_data.test_data,
			doc_data.sections
		);
		convert_legacy_section(
			'copy_clock',
			doc_data.test_data,
			doc_data.sections
		);
		convert_legacy_section(
			'metadata',
			doc_data.test_data,
			doc_data.sections
		);
		doc_data.test_data.start_time = doc_data.suite_meta.start_time;
		doc_data.test_data.end_time = doc_data.suite_meta.end_time;

		//Remove old stuff
		delete doc_data.sections;
		delete doc_data.metrics;
	}
};

//Get motion sections
dctTest.prototype.getMotionSections = function () {
	var sections = [];
	var test_data = this.getTestData();
	for (var k in test_data) {
		if (test_data[k].motion_data)
			sections.push({
				section_id: k,
				motion_data: test_data[k].motion_data,
			});
	}
	sections.sort(function (a, b) {
		return a.motion_data.start_time - b.motion_data.start_time;
	});
	return sections;
};

//Map data blocks
dctTest.prototype.getDocData = function () {
	return this._raw.doc_data;
};
dctTest.prototype.getTestData = function () {
	return this._raw.doc_data.test_data;
};
dctTest.prototype.getType = function () {
	return this._raw.doc_data.test_type;
};
dctTest.prototype.getDate = function () {
	return this._date;
};
dctTest.prototype.getDuration = function () {
	return this._stop_time; /* XXX TODO */
};
dctTest.prototype.getId = function () {
	return '__whole';
}; //Static section id for whole test
dctTest.prototype.getPageBounds = function () {
	return this._raw.page_bounds;
};
dctTest.prototype.getSuiteIndex = function () {
	return this._raw.suite_index;
};
dctTest.prototype.getHostedData = function (key = 'report_hosted') {
	return this._raw.doc_data[key];
};

//Time functions
dctTest.prototype.getStartTime_Test = function () {
	return 0;
};
dctTest.prototype.getStartTime_Epoch = function () {
	return this._raw.start_time;
};
dctTest.prototype.getStopTime_Test = function () {
	return this._duration;
};
dctTest.prototype.getStopTime_Epoch = function () {
	return this._duration + this._raw.start_time;
};

dctTest.prototype.getDeviceType = function () {
	return this._raw.device_type;
};
dctTest.prototype.getDeviceSerial = function () {
	return this._raw.device_serial;
};

//Sections apply to drawing tests only
dctTest.prototype.getSections = function () {
	return this._sections;
};
dctTest.prototype.getSection = function (index) {
	return this._sections[index];
};
dctTest.prototype.getSectionById = function (arg_id) {
	if (arg_id === '__whole') return this.getCombinedSection();
	for (var i = 0; i < this._sections.length; i++) {
		if (this._sections[i].getId() === arg_id) return this._sections[i];
	}
	return null;
};
//Get a combined section
dctTest.prototype.getCombinedSection = function getCombinedSection() {
	return dctSection.getCombinedSection(this, this._sections);
};

//Get a variable value
dctTest.prototype.getVariable = function getVariable(
	arg_key,
	arg_allow_hidden
) {
	var metric_map = this._raw.doc_data.metric_map;
	var hidden_metric_map = this._raw.doc_data.hidden_metric_map;
	if (metric_map && metric_map.hasOwnProperty(arg_key))
		return metric_map[arg_key];
	if (
		arg_allow_hidden &&
		hidden_metric_map &&
		metric_map.hasOwnProperty(arg_key)
	)
		return hidden_metric_map[arg_key];
	return null;
};

//Overlap logic
dctTest.timesOverlap = function (
	arg_start_a,
	arg_stop_a,
	arg_start_b,
	arg_stop_b
) {
	//Really simple overlap logic.  Works amazingly well for something so compact.
	return (
		Math.max(arg_start_a, arg_start_b) < Math.min(arg_stop_a, arg_stop_b)
	);
};

//Does this test overlap with a second test?
dctTest.prototype.overlapsTimeWith = function (arg_test) {
	//Really simple overlap logic.  Works amazingly well for something so compact.
	return dctTest.timesOverlap(
		this.getStartTime_Epoch(),
		this.getStopTime_Epoch(),
		arg_test.getStartTime_Epoch(),
		arg_test.getStopTime_Epoch()
	);
};

//################################################################################
//A drawing section object
//################################################################################
function dctSection(arg_raw, arg_test, arg_index) {
	//Save vars
	this._raw = arg_raw.drawing.data;
	this._test = arg_test;
	this._index = arg_index;

	//Some initialization
	var self = this;

	//Bounds
	this._bounds = new dctBounds(this._raw.bounds);
	//Metrics processing
	this._metrics_map = {};
	this._raw.metrics.forEach(
		function (m, i) {
			this._metrics_map[m] = i;
		}.bind(this)
	);
	this._m_x = this.getMetricIndex('x');
	this._m_y = this.getMetricIndex('y');
	this._m_t = this.getMetricIndex('time');
	this._m_p = this.getMetricIndex('pressure');
	this._m_count = this._raw.metrics.length;

	//Build segments
	this._makeSegments();

	//Time ranges
	this._start_time = this._segments.length
		? this._segments[0].getStartTime_Test()
		: 0;
	this._stop_time = this._segments.length
		? this._segments[this._segments.length - 1].getStopTime_Test()
		: 0;
}
dctSection.prototype.getIndex = function () {
	return this._index;
};
dctSection.prototype.getPageAddress = function () {
	return this._raw.page_address;
};
dctSection.prototype.getPageBounds = function () {
	return this._bounds;
};
dctSection.prototype.getMetrics = function () {
	return this._raw.metrics;
};
dctSection.prototype.getMetricsCount = function () {
	return this._raw.metrics.length;
};
dctSection.prototype.getMetricIndex = function (k) {
	return this._metrics_map[k];
};
dctSection.prototype.getSegments = function () {
	return this._segments;
};
dctSection.prototype.getSegment = function (i) {
	return this._segments[i];
};
dctSection.prototype.getId = function () {
	return (this._raw.id || '').trim();
};
dctSection.prototype.getDuration = function () {
	return this._stop_time - this._start_time;
};

dctSection.prototype.getStartTime_Section = function () {
	return 0;
};
dctSection.prototype.getStartTime_Test = function () {
	return this._start_time;
};
dctSection.prototype.getStartTime_Epoch = function () {
	return this._start_time + this._test.getStartTime_Epoch();
};
dctSection.prototype.getStopTime_Section = function () {
	return this._stop_time - this._start_time;
};
dctSection.prototype.getStopTime_Test = function () {
	return this._stop_time;
};
dctSection.prototype.getStopTime_Epoch = function () {
	return this._stop_time + this._test.getStartTime_Epoch();
};

dctSection.prototype.isIdentifying = function () {
	return this._raw.identifying;
};

dctSection.prototype._makeSegments = function () {
	this._segments = [];
	var prev_stop = null;
	for (var i = 0; i < this._raw.stroke_data.length; i++) {
		var s = this._raw.stroke_data[i];
		var s_start = s[this._m_t];
		var s_stop = s[s.length - this._m_count + this._m_t];
		if (prev_stop)
			this._segments.push(
				new dctSegment(
					{ start: prev_stop, stop: s_start, on: false },
					this,
					this._test
				)
			);
		this._segments.push(
			new dctSegment(
				{ start: s_start, stop: s_stop, on: true, data: s },
				this,
				this._test
			)
		);
		prev_stop = s_stop;
	}
};

//Get the drawing bounds of a segment
dctSection.prototype.getDrawingBounds = function () {
	//Cached copy?
	if (this._drawing_bounds_cache) return this._drawing_bounds_cache;

	//Calculate
	var bounds = null;
	for (var i = 0; i < this._segments.length; i++) {
		if (!this._segments[i].isDown()) continue;
		var seg_bounds = this._segments[i].getDrawingBounds();
		bounds = bounds ? dctBounds.combine(bounds, seg_bounds) : seg_bounds;
	}

	//Cache & return
	this._drawing_bounds_cache = bounds;
	return bounds;
};

//Get how big the canvas should be
dctSection.prototype.getCanvasSize = function (arg_max_width, arg_max_height) {
	var ratio = this._bounds.getWidth() / this._bounds.getHeight();
	var width = arg_max_width;
	var height = Math.round(width / ratio);
	if (height > arg_max_height) {
		height = arg_max_height;
		width = Math.round(height * ratio);
	}
	return { width: width, height: height };
};

//Fill a canvas - must be pre-sized with getCanvasSize()
dctSection.prototype.paintToCanvas = function (
	arg_ctx,
	arg_offset_x,
	arg_offset_y,
	arg_width,
	arg_height,
	arg_segment_styler
) {
	//Reset basics
	arg_ctx.lineCap = 'round';
	arg_ctx.lineJoin = 'round';
	arg_ctx.lineWidth = 1;
	arg_ctx.strokeStyle = '#000000';

	//Scale calculation is simple, presuming already correctly did sizing with getCanvasSize
	var scale = arg_width / this._bounds.getWidth();

	//Draw all segments to canvas
	for (var i = 0; i < this._segments.length; i++) {
		this._segments[i].paintToCanvas(
			arg_ctx,
			scale,
			arg_offset_x,
			arg_offset_y,
			this._bounds,
			arg_segment_styler
		);
	}
};

//Get a segment at a particular time
dctSection.prototype.getSegmentAt = function (arg_time) {
	//A simple binary search
	var guess,
		min = 0,
		max = this._segments.length - 1;
	while (min <= max) {
		guess = Math.floor((min + max) / 2);
		if (this._segments[guess].getStopTime_Section() < arg_time)
			min = guess + 1;
		else if (this._segments[guess].getStartTime_Section() > arg_time)
			max = guess - 1;
		else return this._segments[guess];
	}
};

//Build a section that combines them all
dctSection.getCombinedSection = function getCombinedSection(
	arg_test,
	arg_sections
) {
	//Cache?
	if (arg_test._cached_combined_section)
		return arg_test._cached_combined_section;

	//Start it
	var raw = {
		metrics: arg_sections[0]._raw.metrics,
		page_address: arg_sections[0]._raw.page_address,
		id: '__whole',
		identifying: false, //Updated from all
		segments: [], //Updated from all
		page_bounds: null, //Updated from all
	};

	//Bounds
	var stroke_segments = [];
	var paper_spec_bounds = null;
	var discovered_bounds = null;
	for (var i = 0; i < arg_sections.length; i++) {
		//Section reference
		var sec = arg_sections[i];

		//Identifying?
		raw.identifying |= sec.isIdentifying();

		//Add all segments that are NOT pauses
		for (
			var segindex = 0;
			segindex < sec._raw.segments.length;
			segindex++
		) {
			var seg = sec._raw.segments[segindex];
			if (seg.on) stroke_segments.push(seg);
		}

		//Discover page bounds, for paper we don't recognize
		var section_bounds =
			//Recognized papers will have a paper spec, which includes the full page bounds.  Use that.
			sec._raw.paper_spec && sec._raw.paper_spec.page_bounds
				? new dctBounds(sec._raw.paper_spec.page_bounds)
				: //Unrecognized papers won't have this;  we'll have to calculate the bounds from the combined sections.
				  sec.getPageBounds();

		//Combine bounds.
		discovered_bounds = discovered_bounds
			? dctBounds.combine(section_bounds, discovered_bounds)
			: section_bounds;
	}

	//Sort the segments by time
	stroke_segments.sort(function (a, b) {
		return a.start - b.start;
	});

	//Add all segments in, generating pauses dynamically
	for (var i = 0; i < stroke_segments.length; i++) {
		//Generate a pause
		if (i)
			raw.segments.push({
				on: false,
				start: stroke_segments[i - 1].stop,
				stop: stroke_segments[i].start,
			});

		//Add the stroke
		raw.segments.push(stroke_segments[i]);
	}

	//Final adjustments
	raw.page_bounds = discovered_bounds._raw;

	//Cache and return
	arg_test._cached_combined_section = new dctSection(raw, arg_test, null);
	arg_test._cached_combined_section.getSegments().sort(dctSegment.compare);
	return arg_test._cached_combined_section;
};

//################################################################################
//Page bounds
//################################################################################
function dctBounds(arg_raw) {
	this._raw = arg_raw;

	//Translate from different format?
	if (this._raw.x !== undefined) this._raw.left = this._raw.x;
	if (this._raw.y !== undefined) this._raw.top = this._raw.y;
	if (this._raw.width !== undefined)
		this._raw.right = this._raw.width + this._raw.left;
	if (this._raw.height !== undefined)
		this._raw.bottom = this._raw.height + this._raw.top;
}
dctBounds.prototype.getLeft = function () {
	return this._raw.left;
};
dctBounds.prototype.getTop = function () {
	return this._raw.top;
};
dctBounds.prototype.getRight = function () {
	return this._raw.right;
};
dctBounds.prototype.getBottom = function () {
	return this._raw.bottom;
};
dctBounds.prototype.getWidth = function () {
	return this._raw.right - this._raw.left;
};
dctBounds.prototype.getHeight = function () {
	return this._raw.bottom - this._raw.top;
};

//Combine two.  Use with Array.reduce!
dctBounds.combine = function (previous, current) {
	if (!previous) return new dctBounds(current._raw);
	return new dctBounds({
		left: Math.min(previous.getLeft(), current.getLeft()),
		right: Math.max(previous.getRight(), current.getRight()),
		top: Math.min(previous.getTop(), current.getTop()),
		bottom: Math.max(previous.getBottom(), current.getBottom()),
	});
};

//Get a rounded off version of the bounds
dctBounds.prototype.getRounded = function () {
	return new dctBounds({
		left: Math.floor(this.getLeft()),
		right: Math.ceil(this.getRight()),
		top: Math.floor(this.getTop()),
		bottom: Math.ceil(this.getBottom()),
	});
};

//################################################################################
//A segment with data points!
//################################################################################
function dctSegment(arg_raw, arg_section, arg_test) {
	this._raw = arg_raw;
	this._section = arg_section;
	this._test = arg_test;
}

dctSegment.prototype.getStartTime_Section = function () {
	return this._raw.start - this._section.getStartTime_Test();
};
dctSegment.prototype.getStartTime_Test = function () {
	return this._raw.start;
};
dctSegment.prototype.getStartTime_Epoch = function () {
	return this._raw.start + this._test.getStartTime_Epoch();
};
dctSegment.prototype.getStopTime_Section = function () {
	return this._raw.stop - this._section.getStartTime_Test();
};
dctSegment.prototype.getStopTime_Test = function () {
	return this._raw.stop;
};
dctSegment.prototype.getStopTime_Epoch = function () {
	return this._raw.stop + this._test.getStartTime_Epoch();
};

dctSegment.prototype.getDuration = function () {
	return this._raw.stop - this._raw.start;
};
dctSegment.prototype.isDown = function () {
	return this._raw.data ? true : false;
};
dctSegment.prototype.isStroke = function () {
	return !!this._raw.on;
};
dctSegment.prototype.isPause = function () {
	return !this._raw.on;
};
dctSegment.prototype.getCount = function () {
	return this._raw.data.length / this._section._m_count;
};
dctSegment.prototype.getX = function (i) {
	return this._raw.data[i * this._section._m_count + this._section._m_x];
};
dctSegment.prototype.getY = function (i) {
	return this._raw.data[i * this._section._m_count + this._section._m_y];
};
dctSegment.prototype.getRealTime = function (i) {
	return this._raw.data[i * this._section._m_count + this._section._m_t];
};
dctSegment.prototype.getTime = function (i) {
	return (
		this._raw.data[i * this._section._m_count + this._section._m_t] -
		this._section.getStartTime_Test()
	);
};
dctSegment.prototype.getPressure = function (i) {
	return this._raw.data[i * this._section._m_count + this._section._m_p];
};
dctSegment.prototype.getNotes = function () {
	return this._raw.notes || [];
};

//Get the drawing bounds of a segment
dctSegment.prototype.getDrawingBounds = function () {
	//Cache?
	if (this._drawing_bounds_cache) return this._drawing_bounds_cache;

	//Calculate
	var bounds = {
		left: this.getX(0),
		right: this.getX(0),
		top: this.getY(0),
		bottom: this.getY(0),
	};
	var count = this.getCount();
	for (var i = 0; i < count; i++) {
		bounds.left = Math.min(bounds.left, this.getX(i));
		bounds.right = Math.max(bounds.right, this.getX(i));
		bounds.top = Math.min(bounds.top, this.getY(i));
		bounds.bottom = Math.max(bounds.bottom, this.getY(i));
	}

	//Cache and return
	this._drawing_bounds_cache = new dctBounds(bounds);
	return this._drawing_bounds_cache;
};

//Get stroke type
dctSegment.prototype.getStrokeType = function () {
	if (this.isPause()) return 'PAUSE';
	return this._raw.stroke_type || 'UNKNOWN';
};

//Get stroke type confidence level
dctSegment.prototype.getStrokeTypeConfidence = function () {
	if (this.isPause()) return 1; //We are 100% confident a pause is a pause.
	if (typeof this._raw.stroke_type_confidence === 'number')
		return this._raw.stroke_type_confidence;
	return null;
};

//Get confidence color
dctSegment.prototype.getStrokeTypeConfidenceColor = function () {
	//Constants
	var unknown = '#888888';
	var colors = [
		'#D84315', //0.0
		'#D84315', //0.1
		'#D84315', //0.2
		'#D84315', //0.3
		'#D84315', //0.4
		'#EF6C00', //0.5
		'#EF6C00', //0.6
		'#EF6C00', //0.7
		'#F9A825', //0.8
		'#558B2F', //0.9
		'#2E7D32', //1.0
	];

	//Calculate
	var c = this.getStrokeTypeConfidence();
	return typeof c !== 'number'
		? unknown
		: colors[
				Math.max(
					0,
					Math.min(colors.length - 1, Math.floor(colors.length * c))
				)
		  ];
};

//This gets a DATA URL for a thumbnail of a segment.
dctSegment.prototype.getThumbnail = function (
	arg_width,
	arg_height,
	arg_padding
) {
	return this.makeThumbnailCanvas(
		document.createElement('canvas'),
		arg_width,
		arg_height,
		arg_padding
	).toDataURL();
};

//Fill a canvas with a thumbnail
dctSegment.prototype.makeThumbnailCanvas = function (
	arg_canvas,
	arg_width,
	arg_height,
	arg_padding
) {
	//Padding calculations (you need SOME padding or lines at the top/bottom get cropped)
	var padding = typeof arg_padding === 'number' ? arg_padding : 2;
	var adjusted_width = arg_width - 2 * padding;
	var adjusted_height = arg_height - 2 * padding;

	//Calculate min, max of coordinates
	var bounds = this.getDrawingBounds();

	//Scale, with divide-by-zero protection
	var scale_x = adjusted_width / (bounds.getWidth() || 1);
	var scale_y = adjusted_height / (bounds.getHeight() || 1);
	var scale = Math.min(scale_x, scale_y);
	var offset_x =
		padding +
		0.5 *
			(scale >= scale_x ? 0 : adjusted_width - scale * bounds.getWidth());
	var offset_y =
		padding +
		0.5 *
			(scale >= scale_y
				? 0
				: adjusted_height - scale * bounds.getHeight());

	//Canvas creation
	arg_canvas.width = arg_width;
	arg_canvas.height = arg_height;

	//Context - some hardcoded stuff here, but okay with that for now, eventually add style parameters if necessary
	var ctx = arg_canvas.getContext('2d');
	ctx.lineCap = 'round';
	ctx.lineJoin = 'round';
	ctx.lineWidth = 2;
	ctx.strokeStyle = '#000000';

	//Paint
	this.paintToCanvas(ctx, scale, offset_x, offset_y, bounds);

	//Return the canvas
	return arg_canvas;
};

//Paint segment to canvas
dctSegment.prototype.paintToCanvas = function (
	arg_ctx,
	arg_scale,
	arg_offset_x,
	arg_offset_y,
	arg_bounds,
	arg_segment_styler
) {
	//Don't paint pauses!
	if (!this.isDown()) return;

	//Draw the segments
	arg_ctx.beginPath();
	for (var i = 0; i < this.getCount(); i++) {
		var adjusted_x =
			arg_offset_x + arg_scale * (this.getX(i) - arg_bounds.getLeft());
		var adjusted_y =
			arg_offset_y + arg_scale * (this.getY(i) - arg_bounds.getTop());
		if (arg_segment_styler)
			arg_segment_styler(this, i, adjusted_x, adjusted_y);
		if (i === 0) arg_ctx.moveTo(adjusted_x, adjusted_y);
		else arg_ctx.lineTo(adjusted_x, adjusted_y);
	}
	arg_ctx.stroke();
};

//Sort function
dctSegment.compare = function (a, b) {
	return a.getStartTime_Epoch() - b.getStartTime_Epoch();
};

//NodeJS Export
if (
	typeof module === 'object' &&
	module &&
	typeof module.exports === 'object'
) {
	module.exports.dctUpload = dctUpload;
	module.exports.dctTestSuite = dctTestSuite;
	module.exports.dctTest = dctTest;
	module.exports.dctSection = dctSection;
	module.exports.dctBounds = dctBounds;
	module.exports.dctSegment = dctSegment;
}

//################################################################################
//Additional test functionality for drawing tests only
//################################################################################

//Get ordered list of stroke objects - suitable for CSK generation.
dctTest.prototype.getOrderedStrokes = function (arg_filter) {
	//Stroke objects
	var strokes = [];

	//Test properties
	var test_start = this.getStartTime_Epoch();
	var page_bounds = this._raw;

	//Walk all sections
	for (var sec_index = 0; sec_index < this._sections.length; sec_index++) {
		//Walk them segments
		var section = this._sections[sec_index];
		var page_bounds =
			(section._raw.paper_spec && section._raw.paper_spec.page_bounds) ||
			undefined;

		//Walk all the pieces
		for (
			var seg_index = 0;
			seg_index < section._segments.length;
			seg_index++
		) {
			//Dereference, skip if this is a pause.
			var segment = section._segments[seg_index];
			if (!segment.isDown()) continue;

			//Filter?
			if (arg_filter && !arg_filter(segment)) continue;

			//Start the stroke object
			var stroke_object = {
				page_address: section.getPageAddress(),
				page_bounds: page_bounds,
			};
			strokes.push(stroke_object);

			//Get the absolute start time for this stroke
			stroke_object.start_time = segment.getStartTime_Epoch();
			var prev_time = stroke_object.start_time - test_start;

			//Build data
			stroke_object.data = [];
			var count = segment.getCount();
			for (var i = 0; i < count; i++) {
				var time = segment.getRealTime(i);
				var time_delta = time - prev_time;
				prev_time = time;
				stroke_object.data.push(
					segment.getX(i),
					segment.getY(i),
					time_delta,
					segment.getPressure(i)
				);
			}
		}
	}

	//Return
	return new dctOrderedStrokeList(
		strokes,
		this._raw.device_type,
		this._raw.device_serial
	);
};

//Get ordered stroke objects from merged tests
dctTest.getOrderedStrokesMerged = function (arg_tests, arg_filter) {
	//Empty?
	if (arg_tests.length === 0) return new dctOrderedStrokeList([], null, null);

	//Merge the tests
	return dctOrderedStrokeList.merge(
		arg_tests.map(function (test) {
			return test.getOrderedStrokes(arg_filter);
		})
	);
};

//Get CSK from a test
dctTest.prototype.getCSK = function () {
	//Get the ordered strokes
	return this.getOrderedStrokes().getCSK();
};
