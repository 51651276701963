import {
	QuestionTypes_instruction,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import i18n from 'i18n';

import {
	QnrRowData,
	getQuestionnaireScore,
	getQuestionnaireTranslations,
} from '../CustomQuestionnaire.utils';

export function parseQuestions(
	qnrData: QuestionnaireSpecWithScoringResult
): QnrRowData[] {
	const parsedAnswers: QnrRowData[] = [];

	const qnrTranslations = getQuestionnaireTranslations(qnrData);

	if (qnrData) {
		qnrData.components.forEach((component) => {
			if (component.type !== QuestionTypes_instruction.INSTRUCTION) {
				const translationKey = component.id;
				component.choices.forEach((choice) => {
					if (choice.answer && translationKey) {
						parsedAnswers.push({
							question: qnrTranslations[translationKey],
							answers: {
								grade: choice.answer.grade,
								answerText:
									qnrTranslations[choice.choiceI18nKey],
								value: choice.value,
							},
						});
					}
				});
			}
		});
	}

	// add the last row of the table as the scorer text
	parsedAnswers.push({
		question: i18n.t('web.report.uniqueResponses.total').toUpperCase(),
		answers: {
			totalScore: getQuestionnaireScore(qnrData),
		},
	});

	return parsedAnswers;
}
