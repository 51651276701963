import { ReactNode } from 'react';
import { ConfigProvider } from 'react-avatar';

import { Memoize } from 'helpers/Memoize';
import { theme } from '../StyleProvider/theme';

const getAvatarColors = () => [
	theme.color.avatarBg1,
	theme.color.avatarBg2,
	theme.color.avatarBg3,
	theme.color.avatarBg4,
	theme.color.avatarBg5,
	theme.color.avatarBg6,
	theme.color.avatarBg7,
];

export const AvatarProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element => {
	return (
		// @ts-expect-error Inconsistency between types due to React 18
		<ConfigProvider colors={getAvatarColors()}>
			<Memoize>{children}</Memoize>
		</ConfigProvider>
	);
};
