/**
 * @description This normalizes the RAF code in case it's out of bounds
 * regarding decimals.
 * @param rafCode The code to be normalized
 * @returns The normalized RAF code
 */
export const normalizeRafCode = (rafCode: string | null) => {
	let resultingRafCode: string | null = null;
	if (rafCode) {
		const numberRafCode = Number(rafCode);
		if (!isNaN(numberRafCode)) {
			resultingRafCode = (
				Math.ceil(numberRafCode * 1000) / 1000
			).toString();
		} else {
			resultingRafCode = rafCode;
		}
	} else {
		resultingRafCode = rafCode;
	}
	return resultingRafCode;
};
