export const Breakpoints = {
	xs: 375,
	sm: 425,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1366,
	hd: 1440,
	fhd: 1920,
};

export const MediaQueries = {
	minWidth: {
		xs: `(min-width: ${Breakpoints.xs}px)`,
		sm: `(min-width: ${Breakpoints.sm}px)`,
		md: `(min-width: ${Breakpoints.md}px)`,
		lg: `(min-width: ${Breakpoints.lg}px)`,
		xl: `(min-width: ${Breakpoints.xl}px)`,
		hd: `(min-width: ${Breakpoints.hd}px)`,
		fhd: `(min-width: ${Breakpoints.fhd}px)`,
	},
	minHeight: {
		xs: `(min-height: ${Breakpoints.xs}px)`,
		sm: `(min-height: ${Breakpoints.sm}px)`,
		md: `(min-height: ${Breakpoints.md}px)`,
		lg: `(min-height: ${Breakpoints.lg}px)`,
		xl: `(min-height: ${Breakpoints.xl}px)`,
		hd: `(min-height: ${Breakpoints.hd}px)`,
		fhd: `(min-height: ${Breakpoints.fhd}px)`,
	},
	maxWidth: {
		xs: `(max-width: ${Breakpoints.xs}px)`,
		sm: `(max-width: ${Breakpoints.sm}px)`,
		md: `(max-width: ${Breakpoints.md}px)`,
		lg: `(max-width: ${Breakpoints.lg}px)`,
		xl: `(max-width: ${Breakpoints.xl}px)`,
		hd: `(max-width: ${Breakpoints.hd}px)`,
		fhd: `(max-width: ${Breakpoints.fhd}px)`,
	},
	maxHeight: {
		xs: `(max-height: ${Breakpoints.xs}px)`,
		sm: `(max-height: ${Breakpoints.sm}px)`,
		md: `(max-height: ${Breakpoints.md}px)`,
		lg: `(max-height: ${Breakpoints.lg}px)`,
		xl: `(max-height: ${Breakpoints.xl}px)`,
		hd: `(max-height: ${Breakpoints.hd}px)`,
		fhd: `(max-height: ${Breakpoints.fhd}px)`,
	},
	smmobile: `(max-width: ${Breakpoints.sm - 1}px)`,
	mobile: `(max-width: ${Breakpoints.md - 1}px)`,
	tablet: `(min-width: ${Breakpoints.md}px) and (max-width: ${
		Breakpoints.lg - 1
	}px)`,
	desktop: `(min-width: ${Breakpoints.lg}px)`,
	isIOS: `(-webkit-touch-callout: none)`,
	isAndroid: `not (-webkit-touch-callout: none)`,
	isBrowser: `screen and (-webkit-min-device-pixel-ratio: 0)`,
	iPad: {
		iPad12MiniAndAir: `(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)`,
		iPad34AndPro: `(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)`,
		iPadPro13Inch: {
			landscape: `(min-width: ${Breakpoints.xxl}px)`,
			portrait: `(min-height: ${Breakpoints.xxl}px)`,
		},
	},
	androidTablet: {
		nexus9: `(max-width: ${Breakpoints.lg}px) and (max-resolution: 300dpi)`,
		nexus10: `(min-width: ${
			Breakpoints.lg + 1
		}px) and (max-resolution: 320dpi)`,
	},
};
