import { P1 } from 'components/shared/designSystem';
import styled, { css } from 'styled-components';

type Props = {
	label: string;
};

const Chip = ({ label }: Props) => {
	return (
		<Container>
			<LabelText>{label}</LabelText>
		</Container>
	);
};

export { Chip };

const Container = styled.div(
	({ theme: { colors, spacing } }) => css`
		display: flex;
		width: 64px;
		padding: ${spacing.xs};
		justify-content: center;
		align-items: center;
		gap: ${spacing.xxs};
		border-radius: ${spacing.sm};
		background: ${colors.gray_70};
	`
);

const LabelText = styled(P1)(
	({ theme: { color, weight } }) => css`
		color: ${color.bodyText};
		text-align: center;
		font-weight: ${weight.semi};
	`
);
