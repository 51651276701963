import { t } from 'i18n';
import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../metrics';

export const getInformationProcessingDataSet = (
	dataMap: MetricHash
): DataSet => {
	// Parse the map and get values of interest in chart-friendly data structure
	const informationProcessingMap = {
		commandClock: [
			{
				x: infoProcessingStrings.percentThinkTime,
				y: dataMap?.COMPercentThinkTime_s?.value,
			},
			{
				x: infoProcessingStrings.averageLatency,
				y: dataMap?.COMAverageLatency_s?.value,
			},
			{
				x: infoProcessingStrings.latencyVariability,
				y: dataMap?.COMLatencyVariability_s?.value,
			},
			{
				x: infoProcessingStrings.relativeLongLatency,
				y: dataMap?.COMRelativeLongLatency_s?.value,
			},
			{
				x: infoProcessingStrings.longLatencyCount,
				y: dataMap?.COMLongLatencyCount_s?.value,
			},
			{
				x: infoProcessingStrings.longestLatency,
				y: dataMap?.COMLongestLatency_s?.value,
			},
		],
		copyClock: [
			{
				x: infoProcessingStrings.percentThinkTime,
				y: dataMap?.COPPercentThinkTime_s?.value,
			},
			{
				x: infoProcessingStrings.averageLatency,
				y: dataMap?.COPAverageLatency_s?.value,
			},
			{
				x: infoProcessingStrings.latencyVariability,
				y: dataMap?.COPLatencyVariability_s?.value,
			},
			{
				x: infoProcessingStrings.relativeLongLatency,
				y: dataMap?.COPRelativeLongLatency_s?.value,
			},
			{
				x: infoProcessingStrings.longLatencyCount,
				y: dataMap?.COPLongLatencyCount_s?.value,
			},
			{
				x: infoProcessingStrings.longestLatency,
				y: dataMap?.COPLongestLatency_s?.value,
			},
		],
	};

	return informationProcessingMap as DataSet;
};

export const infoProcessingTooltipKeys = [
	'web.dcrReport.tooltips.percentThinkTime',
	'web.dcrReport.tooltips.averageLatency',
	'web.dcrReport.tooltips.latencyVariability',
	'web.dcrReport.tooltips.relativeLongLatency',
	'web.dcrReport.tooltips.longLatencyCount',
	'web.dcrReport.tooltips.longestLatencyCount',
];
export const infoProcessingStrings = {
	percentThinkTime: t('web.report.segmentUtils.percentThinkTime'),
	averageLatency: t('web.report.segmentUtils.averageLatency'),
	latencyVariability: t('web.report.segmentUtils.latencyVariability'),
	relativeLongLatency: t('web.report.segmentUtils.relativeLongLatency'),
	longLatencyCount: t('web.report.segmentUtils.longLatencyCount'),
	longestLatency: t('web.report.segmentUtils.longestLatency'),
};
