import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { icons } from '../../enums/icons';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { Icon } from '../shared/designSystem/Icon';
import { fetchLabelData, Label } from 'utils/label';

import {
	BarcodeImage,
	BottomRowImg,
	StyledAddress,
	StyledAddressLine,
	StyledBoldTitle,
	StyledConsultant,
	StyledContainer,
	StyledHeader,
	StyledIconContainer,
	StyledIsoImage,
	StyledLabel,
	StyledLink,
	StyledRow,
	StyledSectionTitle,
} from './RegulatoryLabel.style';

export const RegulatoryLabel = (): JSX.Element => {
	const isNewerIntendedUse = useFeatureFlag(FeatureFlags.IntendedUseV3);
	const theme = useTheme();
	const { t } = useTranslation();

	const [label, setLabel] = useState<Label>();

	const coreCognitiveEvaluation = t`web.regulatory.title`;
	const clockDrawing = t`web.shared.assessments.clockDrawing`;

	useEffect(() => {
		async function setLabelData() {
			const data = await fetchLabelData();
			setLabel(data);
		}

		setLabelData();
	}, []);

	return (
		<StyledContainer>
			<StyledHeader>{coreCognitiveEvaluation}</StyledHeader>
			<StyledRow>
				<img
					src='/img/regulatory/ref.png'
					alt={t`web.regulatory.imageAltText.referenceNumber`}
				/>
				<StyledLabel>{label && label.ref}</StyledLabel>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/lot.png'
					alt={t`web.regulatory.imageAltText.lotNumber`}
				/>
				<StyledLabel>{label && label.lotNumber}</StyledLabel>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/ManufactureDate.png'
					alt={t`web.regulatory.imageAltText.dateOfManufacture`}
				/>
				<StyledLabel>{label && label.manufactureDate}</StyledLabel>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/Manufacturer.png'
					alt={t`web.regulatory.imageAltText.manufacturer`}
				/>
				<StyledAddress>
					<StyledAddressLine>
						{`Linus Health, Inc.`}
					</StyledAddressLine>
					<StyledAddressLine>{`280 Summer Street`}</StyledAddressLine>
					<StyledAddressLine>{`10th Floor`}</StyledAddressLine>
					<StyledAddressLine>{`Boston, MA 02210, USA`}</StyledAddressLine>
				</StyledAddress>
			</StyledRow>
			<StyledBoldTitle>
				<img
					src='/img/regulatory/EURepresentative.svg'
					alt={t`web.regulatory.imageAltText.euRep`}
				/>
				<StyledIsoImage
					src='/img/regulatory/ISO.svg'
					alt='ISO 15223-1 Importer Symbol'
				/>
			</StyledBoldTitle>
			<StyledRow>
				<StyledConsultant>
					<StyledAddressLine>
						{`Linus Health Europe,`}
					</StyledAddressLine>
					<StyledAddressLine>{`NexusUCD, Belfield Office Park,`}</StyledAddressLine>
					<StyledAddressLine>{`Clonskeagh, Dublin D04 V2N9,`}</StyledAddressLine>
					<StyledAddressLine>{`Ireland`}</StyledAddressLine>
				</StyledConsultant>
			</StyledRow>
			<StyledBoldTitle>{'UK RESPONSIBLE PERSON'}</StyledBoldTitle>
			<StyledRow>
				<StyledConsultant>
					<StyledAddressLine>
						{`iMed Consultancy Ltd, Bloxham Mill`}
					</StyledAddressLine>
					<StyledAddressLine>{`Business Centre, Barford Road,`}</StyledAddressLine>
					<StyledAddressLine>{`Bloxham, Banbury, Oxfordshire, OX15`}</StyledAddressLine>
					<StyledAddressLine>{`4FF, United Kingdom`}</StyledAddressLine>
				</StyledConsultant>
			</StyledRow>
			<StyledRow>
				<img
					src='/img/regulatory/UDI.png'
					alt={t`web.regulatory.imageAltText.udi`}
				/>
				{/* change me to svg barcode */}
				{label?.barcode && (
					<BarcodeImage
						src={`data:image/svg+xml,${encodeURIComponent(
							label.barcode
						)}`}
						alt={t`web.regulatory.imageAltText.barcode`}
					/>
				)}
			</StyledRow>
			<div>
				<StyledSectionTitle>
					{t`web.regulatory.intendedUse.title`}
				</StyledSectionTitle>
				{isNewerIntendedUse ? (
					<Trans i18nKey='web.regulatory.intendedUse.contentV3' />
				) : (
					<Trans
						i18nKey='web.regulatory.intendedUse.content'
						values={{
							coreCognitiveEvaluation: coreCognitiveEvaluation,
							clockDrawing: clockDrawing,
						}}
					/>
				)}
			</div>
			<div>
				<StyledSectionTitle>
					{t`web.regulatory.warningsAndPrecautions.title`}
				</StyledSectionTitle>
				<Trans
					i18nKey='web.regulatory.warningsAndPrecautions.content'
					values={{
						coreCognitiveEvaluation: coreCognitiveEvaluation,
					}}
				/>
			</div>
			<StyledRow>
				<BottomRowImg
					src='/img/regulatory/MD.png'
					alt={t`web.regulatory.imageAltText.medicalDevice`}
				/>
				<BottomRowImg
					src='/img/regulatory/CE.svg'
					alt={t`web.regulatory.imageAltText.ce`}
				/>
				<StyledIconContainer>
					<Icon
						icon={icons.UKCA}
						color={theme.color.black}
						title={t`web.shared.iconAltText.ukca`}
					/>
				</StyledIconContainer>
				<BottomRowImg
					src='/img/regulatory/rxOnly.png'
					alt={t`web.regulatory.imageAltText.rxOnly`}
				/>
				<BottomRowImg
					src='/img/regulatory/ConsultIFU.png'
					alt={t`web.regulatory.imageAltText.consultInstructions`}
				/>
				<StyledLink to='/about/instructions-for-use'>{t`web.regulatory.instructionsForUseLink`}</StyledLink>
			</StyledRow>
		</StyledContainer>
	);
};
