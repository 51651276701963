import styled, { css } from 'styled-components';

import { H4 } from 'components/shared/designSystem';
import { StyledCard } from 'components/shared/StyledCard';

export const SummaryCardContainer = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
		padding: ${spacing.lg} 0;
	`
);

export const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
		margin-left: ${spacing.lg};
	`
);

export const Title = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const Tooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 298px;
		font-size: ${fontSize._14};
		line-height: 17px;
	`
);

export const ScoreInterpretationWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin-left: ${spacing.lg};
	`
);
