import { t } from 'i18n';
import * as yup from 'yup';
import { maxCharacterLimits } from '../../../../utils/stringUtils';
import { phoneRegExp } from '../utils/phoneNumberFormat';

export type addNewUserModel = {
	avatarUrl?: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	roleId: string;
	suffixIds?: string;
};

export const getModel = (): addNewUserModel =>
	({
		avatarUrl: '',
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		roleId: '',
		suffixIds: '',
	} as addNewUserModel);

export const addNewUserSchema = yup.object().shape(
	{
		firstName: yup
			.string()
			.max(
				maxCharacterLimits.firstname,
				t`web.shared.forms.schemas.maxCharLimit`
			)
			.required(t`web.shared.forms.schemas.required`),
		lastName: yup
			.string()
			.max(
				maxCharacterLimits.lastname,
				t`web.shared.forms.schemas.maxCharLimit`
			)
			.required(t`web.shared.forms.schemas.required`),
		email: yup
			.string()
			.email(t`web.shared.forms.schemas.invalidEmail`)
			.required(t`web.shared.forms.schemas.required`),
		roleId: yup.string().required(t`web.shared.forms.schemas.required`),
		phoneNumber: yup
			.string()
			.max(20, t`web.shared.forms.schemas.invalidPhone`)
			.nullable()
			.notRequired()
			.when('phoneNumber', {
				is: (value: string) => value?.length,
				then: yup
					.string()
					.matches(
						phoneRegExp,
						t`web.shared.forms.schemas.invalidPhone`
					),
			}),
	},
	[
		// Add cyclic dependencies when making 'requires' to itself
		['phoneNumber', 'phoneNumber'],
	]
);
