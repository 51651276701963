import { CellContext } from '@tanstack/react-table';

import { TableWord } from 'components/mosaic/molecules/TableWord';

import i18n from 'i18n';
import { AnswerResponse } from '../DelayedRecognition.types';

import { Column, Divider, Selection } from './DelayedRecognitionTable.style';

export type DelayedRecognitionData = {
	group: string;
	selection: JSX.Element;
};

export const COLUMNS = [
	{
		accessorKey: 'group',
		header: () => (
			<span>{i18n.t('web.report.pvltDelayedRecognition.group')}</span>
		),
	},
	{
		accessorKey: 'selection',
		header: () => (
			<span>
				{i18n.t('web.report.pvltDelayedRecognition.selections')}
			</span>
		),
		cell: (props: CellContext<DelayedRecognitionData, unknown>) => {
			const value = props.getValue<React.ReactNode>();

			return <Column>{value}</Column>;
		},
	},
];

const uppercaseWord = (word: string) =>
	word[0].toUpperCase() + word.substring(1);

export const buildTableData = (answers: AnswerResponse[]) => {
	return answers.map((answerGroup, i) => {
		const { answer, answerOptions, correctAnswer } = answerGroup;
		const selectedCorrectAnswer = answer === correctAnswer;
		return {
			group: i18n.t(`web.report.pvltDelayedRecognition.group_${i + 1}`),
			selection: (
				<Column>
					{answerOptions.map((option, _index) => (
						<>
							<TableWord
								key={`${option}_${_index}`}
								correct={option === correctAnswer}
								selected={option === answer}
							>
								<Selection
									$selected={option === answer}
									$unselectedCorrect={
										!selectedCorrectAnswer &&
										option === answer
									}
								>
									{uppercaseWord(option)}
								</Selection>
							</TableWord>
							{answerOptions.length - 1 != _index && <Divider />}
						</>
					))}
				</Column>
			),
		};
	});
};
