import { QueryClient } from '@tanstack/react-query';

import { getArtifacts } from 'api/battery';
import { QueryKey } from 'api/query';
import { ERROR } from 'logging/linusLogger';
import { FeatureType } from '../../../generated/graphql';
import { publicFetchUtil } from '../../../s3ApiFetch';

import { useOrganizationFeatures } from './useOrganizationFeatures';

export type UFSurveyResponse = {
	scores: {
		frailty: string;
		dukeActivityScale: string;
		dominantGripStrength: string[];
	};
	options: [
		{
			id: string;
			selected: boolean;
		}
	];
};

export async function getUFSurveyJsonUrl(
	batteryResultId: string,
	client: QueryClient
) {
	const fileName = 'uf_survey/uf_survey.json';
	return await client.fetchQuery({
		queryKey: [QueryKey.Artifacts, batteryResultId, fileName],
		queryFn: ({ signal }) =>
			getArtifacts(batteryResultId, fileName, signal),
		staleTime: Infinity,
	});
}

export async function getUFSurveyJson(
	batteryResultId: string,
	client: QueryClient
): Promise<UFSurveyResponse | null> {
	const abortController = new AbortController();
	const signedUrl = await getUFSurveyJsonUrl(batteryResultId, client);
	if (!signedUrl) {
		return null;
	}
	try {
		const response = await publicFetchUtil<UFSurveyResponse>({
			url: signedUrl,
			signal: abortController.signal,
		});
		return response;
	} catch (errb) {
		ERROR(
			'getUFSurveyJson: Error calling getUFSurveyJson.publicFetchUtil',
			{ errb }
		);
		return null;
	}
}

export function useUFSurvey() {
	const features = useOrganizationFeatures();
	return features.includes(FeatureType.UfSurvey);
}
