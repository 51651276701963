import { Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { InterpretedScore, ScoreTag } from 'components/mosaic';

import { MAX_SCORE } from './parseImmediateRecallMetrics';

type ImmediateRecallScoreProps = {
	correctResponses: string;
	requiresReview: boolean;
};

export function ImmediateRecallScore({
	correctResponses,
	requiresReview,
}: Readonly<ImmediateRecallScoreProps>) {
	const { t } = useTranslation();

	if (requiresReview) {
		const scoreValue = `--/${MAX_SCORE}`;

		return (
			<Stack maw={445}>
				<InterpretedScore
					interpretationDescription={t(
						'web.report.pvltImmediateReport.rcr'
					)}
					scoreLabel={t(
						'web.report.pvltImmediateReport.correctResponses'
					)}
					scoreValue={scoreValue}
					scoreType='unanalyzable'
				/>
			</Stack>
		);
	}

	const scoreValue = correctResponses;
	return (
		<ScoreTag
			label={t('web.report.uniqueResponses.correctResponses')}
			value={scoreValue}
			type='unknown'
		/>
	);
}
