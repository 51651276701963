import { usePrevious } from '@mantine/hooks';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
	AnalyticsAction,
	sendDebouncedEventData,
	sendEventData,
} from 'analytics';
import { t } from 'i18n';
import { useTheme } from 'styled-components';
import { icons } from '../../enums/icons';
import {
	BatteryResultStatus,
	ClinicalResultTableSearchField,
	ClinicalResultTableSearchInput,
	ClinicalResultTableSortField,
	SortDir,
	useGetPaginatedClinicalBatteryResultsLazyQuery,
} from '../../generated/graphql';
import { UserContext } from '../context/UserContext';
import { LinusPaginatedDataTable } from '../shared/DataTable/PaginatedDataTable/';
import {
	columns,
	mapData,
	PAGE_SIZE,
} from '../shared/DataTable/schemas/resultTableSchema';
import { Header } from '../shared/Header';
import { useActiveTableHeader } from '../shared/hooks/useActiveTableHeader';
import { getRequestParams } from './resultsHelpers';

export type SortProps = {
	dir: SortDir | null;
	prop: ClinicalResultTableSortField | null;
};

export type SearchProps = {
	value: string | null;
	prop: ClinicalResultTableSearchField;
};

const DEFAULT_SEARCH = {
	value: null,
	prop: ClinicalResultTableSearchField.ParticipantFullName,
};

export const Results = (): JSX.Element => {
	const { activeHeader, setActiveHeader } = useActiveTableHeader();
	const theme = useTheme();
	const { currentUser } = useContext(UserContext);

	const tableRef = useRef<{ clearSearchInput(): void }>(null);
	const hasInitialData = useRef(false);
	const [page, setPage] = useState(1);
	const [sort, setSort] = useState<SortProps | null>(null);
	const [search, setSearch] = useState<SearchProps>({
		value: null,
		prop: ClinicalResultTableSearchField.ParticipantExternalIdAndFullName,
	});

	const [getPaginatedBatteryResultsQuery, { data, loading, previousData }] =
		useGetPaginatedClinicalBatteryResultsLazyQuery();

	const fetchBatteryResults = ({
		orgId,
		searchInput,
	}: Partial<{
		orgId: string;
		searchInput: Partial<{
			page: number;
			sort: SortProps | null;
			search: SearchProps;
		}>;
	}> = {}) => {
		const params = getRequestParams({
			page: searchInput?.page ?? page,
			sort: searchInput?.sort ?? sort,
			search: searchInput?.search ?? search,
		}) as ClinicalResultTableSearchInput;
		// this is a stop gap until we get to migrating the entirety of frontend queries to use new "searches" parameter
		params.searches = [
			{
				prop: ClinicalResultTableSearchField.BatteryResultStatus,
				value: [
					BatteryResultStatus.DataComplete,
					BatteryResultStatus.AnalysisComplete,
					BatteryResultStatus.ParticipantComplete,
					BatteryResultStatus.MetricsComplete,
					BatteryResultStatus.PendingShortPhrases,
				].join(','),
			},
		];
		if (params.search) {
			params.searches.push(params.search);
			delete params.search;
		}
		getPaginatedBatteryResultsQuery({
			variables: {
				orgId: orgId ?? currentUser?.organizationId,
				searchInput: params,
			},
		});
	};

	/**
	 * Logs
	 */
	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedResults });
	}, []);

	/**
	 * Refetch table data when filters (or dependant data) change
	 */
	const prevPage = usePrevious(page);
	const prevSearch = usePrevious(search.value);
	const prevSort = usePrevious(sort);
	useEffect(() => {
		if (
			prevPage !== page ||
			prevSort !== sort ||
			prevSearch !== search.value
		) {
			fetchBatteryResults();
		}
	}, [page, sort, search.value]);

	/**
	 * Reset data when organization changes
	 */
	const prevOrganisationId = usePrevious(currentUser.organizationId);
	useEffect(() => {
		if (prevOrganisationId !== currentUser.organizationId) {
			resetData();
			fetchBatteryResults({
				orgId: currentUser.organizationId,
				searchInput: { page: 1, sort: null, search: DEFAULT_SEARCH },
			});
		}
	}, [currentUser.organizationId]);

	useEffect(() => {
		if (search.value && !loading) {
			sendDebouncedEventData({
				eventType: AnalyticsAction.SearchingTable,
				eventProperties: {
					hasResults:
						!!data?.clinicalResultsTableSearch.batteryResults
							.length,
				},
			});
		}
	}, [
		search.value,
		data?.clinicalResultsTableSearch.batteryResults.length,
		loading,
	]);

	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';
	const defaultTimezone = currentUser.organizationDefaultTimezone;

	const rootData = data ?? previousData;

	const columnData = useMemo(
		() => columns(theme, dateFormat, defaultTimezone),
		[theme, dateFormat, defaultTimezone]
	);
	const tableData = useMemo(
		() => mapData(rootData?.clinicalResultsTableSearch?.batteryResults),
		[rootData?.clinicalResultsTableSearch?.batteryResults]
	);

	if (data?.clinicalResultsTableSearch.batteryResults.length) {
		hasInitialData.current = true;
	}

	const count = rootData?.clinicalResultsTableSearch?.count || 0;
	const total = rootData?.clinicalResultsTableSearch?.total || 0;

	const noDataIcon = icons.EmptyClipboardSearch;

	const notFoundTitle = t('web.shared.search.noMatchFound', {
		entity: t('web.results.patientsResults').toLowerCase(),
	});
	const notFoundSubtitle = t('web.results.notFoundSubtitle', {
		entity: t('web.stringStrategies.clinical.participant').toLowerCase(),
	});

	const onSort = (dir: SortDir | undefined, prop: string) => {
		if (!dir) {
			setSort(null);
		} else {
			setActiveHeader(prop);
			setSort({
				dir,
				prop: prop as ClinicalResultTableSortField,
			});
		}
	};

	const resetData = (): void => {
		setPage(1);
		setSort(null);
		setSearch(DEFAULT_SEARCH);
		setActiveHeader(undefined);
		tableRef.current?.clearSearchInput();
	};

	return (
		<>
			<Header />
			<LinusPaginatedDataTable
				_ref={tableRef}
				title={t`web.results.title`}
				columns={columnData}
				tableData={tableData}
				rowsPerPage={PAGE_SIZE}
				longText={t('web.results.emptyTablePlaceholder', {
					entity: t(
						'web.stringStrategies.clinical.participant'
					).toLowerCase(),
				})}
				noDataIcon={noDataIcon}
				searchBarPlaceholder={t`web.shared.search.byNameOrId`}
				notFoundTitle={notFoundTitle}
				notFoundSubtitle={notFoundSubtitle}
				count={count}
				total={total}
				currentPage={page}
				setCurrentPage={(pageNumber) => setPage(pageNumber)}
				loading={loading}
				hasInitialData={hasInitialData.current}
				onSort={onSort}
				onFilter={(predicate) =>
					setSearch({ ...search, value: predicate })
				}
				activeHeader={activeHeader}
			/>
		</>
	);
};
