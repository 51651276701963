import {
	AnswerTypes,
	GradeMarks,
	QuestionTypes_instruction,
	QuestionTypes_singleSelect,
	QuestionnaireScoringResultIds,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import { getInfoFromType } from '../Gad7Report.utils';

import { Gad7ResultData } from './IndividualAnswers/Table';

export const Gad7DataResult: QuestionnaireSpecWithScoringResult = {
	id: 'gad-7',
	name: 'Generalized Anxiety Disorder (GAD-7)',
	description: 'Patient Anxiety Questionnaire',
	version: '1.0.0',
	localizations: [
		{
			locale: 'en_US',
			controlTranslations: {
				progress: 'Question {{current}} of {{total}}',
				start: 'Start',
				next: 'Next',
				back: 'Back',
				done: 'Done',
			},
			translations: [
				{
					key: 'gad-unknown-category',
					translation: 'Unknown category',
				},
				{
					key: 'gad-minimal-anxiety-category',
					translation: 'Minimal anxiety',
				},
				{
					key: 'gad-mild-anxiety-category',
					translation: 'Mild anxiety',
				},
				{
					key: 'gad-instructions-1',
					translation: 'In this task, you will see 7 questions.',
				},
				{
					key: 'gad-moderate-anxiety-category',
					translation: 'Moderate anxiety',
				},
				{
					key: 'gad-severe-anxiety-category',
					translation: 'Severe anxiety',
				},
				{
					key: 'gad-instructions-2',
					translation:
						'For each question, tap one answer and then press the next button.  You can go back and change your answer at any time in the task.',
				},
				{
					key: 'gad-instructions-3',
					translation: 'Press start when you are ready to begin.',
				},
				{
					key: 'over-the-last-two-weeks',
					translation:
						'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
				},
				{
					key: 'nervous-anxious',
					translation: '1. Feeling nervous, anxious, or on edge',
				},
				{
					key: 'control-worrying',
					translation:
						'2. Not being able to stop or control worrying',
				},
				{
					key: 'worrying-too-much',
					translation: '3. Worrying too much about different things',
				},
				{
					key: 'trouble-relaxing',
					translation: '4. Trouble relaxing',
				},
				{
					key: 'so-restless',
					translation:
						'5. Being so restless that it is hard to sit still',
				},
				{
					key: 'easily-annoyed',
					translation: '6. Becoming easily annoyed or irritable',
				},
				{
					key: 'feeling-afraid',
					translation:
						'7. Feeling afraid as if something awful might happen',
				},
				{
					key: 'how-difficult',
					translation:
						'8. If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
				},
				{
					key: 'not-at-all',
					translation: 'Not at all',
				},
				{
					key: 'several-days',
					translation: 'Several days',
				},
				{
					key: 'more-than-half',
					translation: 'More than half the days',
				},
				{
					key: 'nearly-every-day',
					translation: 'Nearly every day',
				},
				{
					key: 'not-difficult',
					translation: 'Not difficult at all',
				},
				{
					key: 'somewhat-difficult',
					translation: 'Somewhat difficult',
				},
				{
					key: 'very-difficult',
					translation: 'Very difficult',
				},
				{
					key: 'extremely-difficult',
					translation: 'Extremely difficult',
				},
			],
		},
	],
	components: [
		{
			id: 'instructions',
			type: QuestionTypes_instruction.INSTRUCTION,
			instructionsI18nKeys: [
				'gad-instructions-1',
				'gad-instructions-2',
				'gad-instructions-3',
			],
		},
		{
			id: 'gad-q1',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'nervous-anxious',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '1',
					},
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '1',
		},
		{
			id: 'gad-q2',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'control-worrying',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '2',
		},
		{
			id: 'gad-q3',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'worrying-too-much',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
		{
			id: 'gad-q4',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'trouble-relaxing',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '1',
					},
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '1',
		},
		{
			id: 'gad-q5',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'so-restless',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '2',
		},
		{
			id: 'gad-q6',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'easily-annoyed',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: {
						value: '3',
						grade: GradeMarks.NOT_GRADED,
						score: '3',
					},
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '3',
		},
		{
			id: 'gad-q7',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'feeling-afraid',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
		{
			id: 'gad-q8',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: false,
			promptI18nKey: 'how-difficult',
			choices: [
				{
					choiceI18nKey: 'not-difficult',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{
					choiceI18nKey: 'somewhat-difficult',
					value: 1,
					answer: null,
				},
				{
					choiceI18nKey: 'very-difficult',
					value: 2,
					answer: null,
				},
				{
					choiceI18nKey: 'extremely-difficult',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
	],
	scores: {
		total: {
			id: QuestionnaireScoringResultIds.TOTAL,
			score: '5',
		},
		category: {
			id: QuestionnaireScoringResultIds.CATEGORY,
			score: 'gad-mild-anxiety-category',
		},
	},
};

export const GAD7_TABLE_DATA: Gad7ResultData[] = [
	{
		question: '1. Feeling nervous, anxious, or on edge',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '2. Not being able to stop or control worrying',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '3. Worrying too much about different things',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '1',
					},
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '4. Trouble relaxing',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: {
						value: '3',
						grade: GradeMarks.NOT_GRADED,
						score: '3',
					},
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '5. Being so restless that it is hard to sit still',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '6. Becoming easily annoyed or irritable',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: {
						value: '3',
						grade: GradeMarks.NOT_GRADED,
						score: '3',
					},
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '7. Feeling afraid as if something awful might happen',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question:
			'8. If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-difficult',
					value: 0,
					answer: null,
					text: 'Not difficult at all',
				},
				{
					choiceI18nKey: 'somewhat-difficult',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Somewhat difficult',
				},
				{
					choiceI18nKey: 'very-difficult',
					value: 2,
					answer: null,
					text: 'Very difficult',
				},
				{
					choiceI18nKey: 'extremely-difficult',
					value: 3,
					answer: null,
					text: 'Extremely difficult',
				},
			],
		},
	},
	{
		question: 'TOTAL SCORE',
		answers: {
			totalScore: 11,
		},
	},
];

export const GAD_INFO_EXAMPLE = {
	minimum: {
		score: '0',
		color: getInfoFromType('gad-minimal-anxiety-category').color,
		text: getInfoFromType('gad-minimal-anxiety-category').text,
		tableData: GAD7_TABLE_DATA,
	},
	mild: {
		score: '5',
		color: getInfoFromType('gad-mild-anxiety-category').color,
		text: getInfoFromType('gad-mild-anxiety-category').text,
		tableData: GAD7_TABLE_DATA,
	},
	moderate: {
		score: '10',
		color: getInfoFromType('gad-moderate-anxiety-category').color,
		text: getInfoFromType('gad-moderate-anxiety-category').text,
		tableData: GAD7_TABLE_DATA,
	},
	severe: {
		score: '15',
		color: getInfoFromType('gad-severe-anxiety-category').color,
		text: getInfoFromType('gad-severe-anxiety-category').text,
		tableData: GAD7_TABLE_DATA,
	},
};
