import {
	Box,
	Flex,
	Group,
	SimpleGrid,
	SimpleGridProps,
	Stack,
	StackProps,
	UnstyledButton,
} from '@mantine/core';
import { t } from 'i18n';
import React, { Children, useContext } from 'react';
import { Trans } from 'react-i18next';
import Xarrow, { anchorType } from 'react-xarrows';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useZendeskUrlQuery } from 'api/user';
import { UserContext } from 'components/context';
import { theme } from 'components/providers/StyleProvider/theme';
import { Icon } from 'components/shared/designSystem';
import { Caption, P2 } from 'components/shared/designSystem/TextComponents';
import { icons } from 'enums/icons';
import { PathwayType } from 'generated/graphql';

import { TEXT } from './Pathway.i18n';
import { PathwayID } from './Pathway.types';

type HighlightColor = 'green' | 'yellow' | 'red';

export function Block({
	children,
	bold,
	highlight,
	gap,
	...props
}: {
	children: React.ReactNode;
	bold?: boolean;
	highlight?: HighlightColor;
	gap?: number;
} & StackProps) {
	return (
		<Stack
			className='pathway-block'
			style={{
				position: 'relative',
				gap: gap ?? 0,
				padding: 8,
				width: '100%',
				border: `${bold ? 2 : 1}px solid ${theme.colors.gray_30}`,
				borderRadius: 4,
				backgroundColor: `${theme.colors.white}`,
			}}
			{...props}
		>
			{children}
			{highlight && <Highlight color={highlight} thick={bold} />}
		</Stack>
	);
}

function SolidBlock({
	value,
	highlight,
	...props
}: {
	value: string;
	highlight?: HighlightColor;
} & StackProps) {
	return (
		<Stack
			className='pathway-block-solid'
			style={{
				position: 'relative',
				gap: 0,
				padding: 4,
				alignItems: 'center',
				backgroundColor: theme.colors.gray_30,
				border: `1px solid ${theme.colors.gray_30}`,
			}}
			{...props}
		>
			<Caption
				style={{
					color: theme.color.white,
					fontWeight: theme.weight.medium,
				}}
			>
				{value}
			</Caption>
			{highlight && <Highlight color={highlight} borderRadius={0} />}
		</Stack>
	);
}

function HeaderBlock({
	value,
	children,
	maw,
	bold,
	highlight,
}: {
	value: string;
	children?: React.ReactNode;
	maw?: number;
	bold?: boolean;
	highlight?: HighlightColor;
}) {
	return (
		<Flex
			className='pathway-block-header'
			style={{
				zIndex: 1,
				position: 'relative',
				padding: '8px 16px',
				alignSelf: 'center',
				width: '100%',
				maxWidth: maw ?? 354,
				minHeight: 40,
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.colors.gray_70,
				border: `${bold ? 2 : 1}px solid ${theme.colors.gray_30}`,
				borderBottom: 0,
				borderTopLeftRadius: 4,
				borderTopRightRadius: 4,
			}}
		>
			<P2 style={{ fontWeight: theme.weight.semi }}>{value}</P2>
			{children}
			{highlight && (
				<Highlight
					color={highlight}
					thick={bold}
					hidden={{ bottom: true }}
				/>
			)}
		</Flex>
	);
}

function ContainerBlock({
	id,
	children,
}: {
	id?: string;
	children: React.ReactNode;
}) {
	return (
		<Stack
			id={id}
			className='pathway-block-container'
			gap={0}
			w='100%'
			align='center'
		>
			{children}
		</Stack>
	);
}

function GridBlock({
	children,
	...props
}: { children: React.ReactNode } & SimpleGridProps) {
	return (
		<SimpleGrid
			cols={2}
			spacing={40}
			verticalSpacing={24}
			style={{ width: '100%', maxWidth: 520, alignItems: 'flex-start' }}
			{...props}
		>
			{children}
		</SimpleGrid>
	);
}

Block.Solid = SolidBlock;
Block.Header = HeaderBlock;
Block.Container = ContainerBlock;
Block.Grid = GridBlock;

function Highlight({
	color,
	thick,
	borderRadius,
	hidden,
}: {
	color: HighlightColor;
	thick?: boolean;
	borderRadius?: number;
	hidden?: { bottom?: boolean };
}) {
	const colorMap: Record<typeof color, string> = {
		green: theme.colors.green_lightest,
		yellow: theme.colors.yellow_lightest,
		red: theme.colors.orange_lightest,
	};

	return (
		<Box
			className='pathway-block-highlight'
			style={{
				position: 'absolute',
				top: thick ? -6 : -5,
				left: thick ? -6 : -5,
				height: `calc(100% + ${
					(thick ? 12 : 10) / (hidden?.bottom ? 2 : 1)
				}px)`,
				width: `calc(100% + ${thick ? 12 : 10}px)`,
				border: `4px solid ${colorMap[color]}`,
				borderRadius: borderRadius ?? 8,
				pointerEvents: 'none',

				...(hidden?.bottom && {
					borderBottom: 0,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				}),
			}}
		/>
	);
}

export function YouAreHere({
	offset = {},
}: {
	offset?: Partial<{
		top: number;
		left: number;
	}>;
}) {
	return (
		<Flex
			data-testid='you-are-here'
			className='pathway-block__you-are-here'
			style={{
				zIndex: 1,
				position: 'absolute',
				top: offset.top ?? -20,
				left: offset.left ?? -34,
			}}
		>
			<Flex
				style={{
					position: 'relative',
					borderRadius: 30,
					backgroundColor: theme.colors.blue,

					'::after': {
						content: '""',
						position: 'absolute',
						top: '100%',
						left: '50%',
						borderWidth: 6,
						borderStyle: 'solid',
						marginLeft: -6,
						borderColor: `${theme.colors.blue} transparent transparent transparent`,
					},
				}}
			>
				<Caption
					style={{
						padding: '4px 8px',
						color: theme.color.white,
						fontWeight: theme.weight.medium,
					}}
				>
					{t(TEXT.youAreHere)}
				</Caption>
			</Flex>
		</Flex>
	);
}

export function List({
	type = 'unordered',
	listStyleType = 'disc',
	gap,
	children,
}: {
	type?: 'ordered' | 'unordered';
	listStyleType?:
		| 'decimal'
		| 'disc'
		| 'circle'
		| 'square'
		| 'upper-roman'
		| 'lower-alpha';
	gap?: number;
	children: React.ReactNode;
}) {
	function countDigits(number: number) {
		return number.toString().length;
	}

	function getMarginOffset() {
		let offset = 20;

		const wordPixelsLength = 15;
		const count = Children.count(children);
		const _offset = countDigits(count) * wordPixelsLength;

		if (_offset > 15 && listStyleType === 'decimal') offset = _offset;

		return offset;
	}

	const componentMap: Record<typeof type, 'ol' | 'ul'> = {
		ordered: 'ol',
		unordered: 'ul',
	};

	return (
		<Box
			component={componentMap[type]}
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap,
				marginLeft: getMarginOffset(),

				li: {
					listStyleType,

					'::marker': {
						fontSize: listStyleType === 'disc' ? 12 : 'inherit',
					},
				},
			}}
		>
			{children}
		</Box>
	);
}

function ListItem({
	children,
	styles,
}: {
	children: React.ReactNode;
	styles?: React.CSSProperties;
}) {
	return (
		<Box component='li' style={styles}>
			{children}
		</Box>
	);
}

List.Item = ListItem;

/**
 * Transforms `<b>`, `<u>` string tags into JSX elements
 *
 * TODO: In the future, consider:
 * https://www.npmjs.com/package/react-markdown
 */
export function renderTags(
	value: string,
	components?: Record<string, React.ReactNode>
) {
	return (
		<Trans
			defaults={value}
			components={{
				u: <u />,
				b: (
					<b
						style={{
							fontWeight: theme.weight.medium,
						}}
					/>
				),
				...components,
			}}
			/**
			 * Reason: https://stackoverflow.com/questions/58269428/i18next-react-trans-component-escaping-characters-correctly
			 */
			shouldUnescape
		/>
	);
}

interface LineProps {
	/** component `id` */
	start: string;
	/** component `id` */
	end: string;
	/** line start direction */
	startAnchor?: anchorType;
	/** line end direction */
	endAnchor?: anchorType;
	showHead?: boolean;
}

export function Line() {
	return null;
}

function SolidLine({
	start,
	end,
	startAnchor,
	endAnchor,
	showHead = false,
}: LineProps) {
	return (
		<Xarrow
			start={start}
			end={end}
			color={theme.colors.gray_30}
			strokeWidth={2}
			showHead={showHead}
			{...(startAnchor && { startAnchor })}
			{...(endAnchor && { endAnchor })}
		/>
	);
}

function DashedLine({
	start,
	end,
	startAnchor,
	endAnchor,
	showHead = false,
}: LineProps) {
	return (
		<Xarrow
			start={start}
			end={end}
			color={theme.colors.gray_30}
			strokeWidth={1}
			showHead={showHead}
			dashness={{ strokeLen: 3.5, nonStrokeLen: 3.5 }}
			path='grid'
			{...(startAnchor && { startAnchor })}
			{...(endAnchor && { endAnchor })}
		/>
	);
}

Line.Solid = SolidLine;
Line.Dashed = DashedLine;

export function ExternalLink({
	to,
	color,
	children,
	fromPathwayOverview = false,
	fromReferencesModal = false,
	pathwayExternalLink = false,
	pathwayId,
	isZendeskUrl,
}: {
	to: string;
	color?: string;
	children?: React.ReactNode;
	fromPathwayOverview?: boolean;
	fromReferencesModal?: boolean;
	pathwayExternalLink?: boolean;
	pathwayId?: PathwayType | PathwayID;
	isZendeskUrl?: boolean;
}) {
	const { currentUser } = useContext(UserContext);
	const { organizationId, id: userId } = currentUser;

	const { data: redirectUrl } = useZendeskUrlQuery({
		organizationId,
		userId,
	});

	async function getAuthLink() {
		if (isZendeskUrl) {
			window.open(`${redirectUrl}/${to}`);
			return;
		}

		window.open(to);
	}
	function handleClick(e: React.MouseEvent) {
		e.stopPropagation();
		e.preventDefault();
		getAuthLink();

		if (fromReferencesModal) {
			sendEventData({
				eventType: AnalyticsAction.ClickedRefLink,
				eventProperties: {
					url: to,
				},
			});

			return;
		}
		if (pathwayExternalLink && pathwayId) {
			sendEventData({
				eventType: AnalyticsAction.ClickedExternalLink,
				eventProperties: {
					url: to,
					pathwayId: pathwayId,
				},
			});

			return;
		}

		if (fromPathwayOverview) {
			sendEventData({
				eventType: AnalyticsAction.ClickedPathwayLearnmore,
			});

			return;
		}

		sendEventData({
			eventType: AnalyticsAction.ClickedCcp,
		});
	}

	return (
		<a
			href={to}
			target='_blank'
			rel='noreferrer'
			style={{
				color: color ?? theme.colors.purple_dark,
				textDecoration: 'underline',
			}}
			onClick={handleClick}
		>
			{children}
		</a>
	);
}

export function ClickableText({
	onClick,
	fontWeight,
	children,
}: {
	onClick(): void;
	fontWeight?: string;
	children?: React.ReactNode;
}) {
	return (
		<UnstyledButton
			style={{
				display: 'inline-flex',
				fontSize: 'inherit',
				lineHeight: 'inherit',
				fontFamily: theme.baseFont,
			}}
			onClick={onClick}
		>
			<span
				style={{
					color: theme.colors.blue,
					textDecoration: 'underline',
					fontWeight: fontWeight ?? theme.weight.bold,
				}}
			>
				{children}
			</span>
		</UnstyledButton>
	);
}

export function LearnMore({
	to = links.learnMore,
	fromPathwayOverview = false,
}: {
	to?: string;
	fromPathwayOverview?: boolean;
}) {
	return (
		<ExternalLink to={to} fromPathwayOverview={fromPathwayOverview}>
			<Group gap={4} wrap='nowrap'>
				<Icon
					icon={icons.ExternalLink}
					color={theme.colors.purple_dark}
				/>
				{t(TEXT.learnMore)}
			</Group>
		</ExternalLink>
	);
}

/**
 * List of unique block IDs
 * - used for drawing lines
 */
export const ID = {
	addressHearingConcern: 'addressHearingConcern',
	addressReversibleCauses: 'addressReversibleCauses',
	concernsList: 'concernsList',
	considerCognitiveCare: 'considerCognitiveCare',
	delayedRecallLessThan: 'delayedRecallLessThan',
	encourage: 'encourage',
	hearingConcerns: 'hearingConcerns',
	ifNotPresent: 'ifNotPresent',
	ifPresent: 'ifPresent',
	ifHearingNotPresent: 'ifHearingNotPresent',
	ifHearingPresent: 'ifHearingPresent',
	immediateRecallEquals: 'immediateRecallEquals',
	immediateRecallLessThan: 'immediateRecallLessThan',
	impairmentStillPresent: 'impairmentStillPresent',
	pathwayHeader: 'pathwayHeader',
	referToNeurology: 'referToNeurology',
	retestAfter: 'retestAfter',
	retestAsap: 'retestAsap',
	reversibleCauses: 'reversibleCauses',
	and: 'and',
	yes: 'yes',
	no: 'no',
};

const links = {
	learnMore: `https://www.cms.gov/cognitive`,
};

export function tMany(key: string) {
	const items = t(key, { returnObjects: true });

	if (Array.isArray(items)) {
		return items;
	}

	return [items];
}
