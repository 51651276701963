import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { Report } from '../CognitiveEvaluation.types';

import { HearingScreener } from './HearingScreener';

type HearingScreenerProps = {
	baterryResult?: Report;
	segmentResult?: DeepSegmentResult;
};

const HearingScreenerReportLayout = ({
	baterryResult,
	segmentResult,
}: HearingScreenerProps) => {
	return (
		<HearingScreener
			batteryResult={baterryResult}
			segmentResult={segmentResult}
		/>
	);
};

export { HearingScreenerReportLayout };
