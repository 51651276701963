import {
	Assignment,
	DefaultApiCreateAssignmentRequest,
} from '@lh/eng-platform-battery-service-rest-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function createAssignment(
	searchInput: DefaultApiCreateAssignmentRequest
) {
	const service = await getBatteryService();
	const { data } = await service.createAssignment(searchInput);
	return data;
}

export function useCreateAssignmentMutation() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error creating assignment',
		},
		mutationFn: createAssignment,
		onSuccess: async (createdAssignment, variables) => {
			// TODO: Should be consumed when porting fully to REST
			// client.setQueryData(
			//     [QueryKey.Subjects, organizationId],
			//     (data: InfiniteData<PaginatedSubjects>) => {
			//         if (!data) {
			//             return;
			//         }

			//         return {
			//             ...data,
			//             pages: data.pages.map((page) => ({
			//                 ...page,
			//                 results: page.results.map((subject) => {
			//                     if (subject.id !== variables.subjectId) {
			//                         return subject;
			//                     }

			//                     return {
			//                         ...subject,
			//                         assignment: createdAssignment
			//                     };
			//                 })
			//             }))
			//         };
			//     }
			// );
			client.setQueryData<Assignment | null>(
				[
					QueryKey.Assignment,
					variables.createAssignmentInputV2.participantId,
				],
				createdAssignment
			);
		},
	});
}
