import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)(
	({ theme: { fontSize, colors } }) => `
    margin: 0 0 0 0;
	font-weight: 400;
	color: ${colors.blue} !important;
	font-size: ${fontSize._16};
	line-height: 19px;
	letter-spacing: 0.5px;
`
);
