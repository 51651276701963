import { Trans, useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { theme } from 'components/providers/StyleProvider/theme';
import { Accordion } from 'components/shared/Accordion';
import { Caption, P1, P2 } from 'components/shared/designSystem';

import {
	ContentWrapper,
	Disclaimer,
	DisclaimerMedium,
	Prediction,
	ScoreContainer,
	ScoreWrapper,
	SemiP1,
	Separator,
	Tag,
	TagText,
	TitleContainer,
} from './MMSEAccordion.style';

type Props = {
	score: number;
	marginOfError: number;
};

export function MMSEAccordion({ marginOfError, score }: Readonly<Props>) {
	const { t } = useTranslation();

	function handleOpen() {
		sendEventData({
			eventType: AnalyticsAction.ExpandedMmseAccordion,
		});
	}

	return (
		<Accordion
			styles={{
				backgroundColor: theme.colors.white,
			}}
			onOpen={handleOpen}
			title={
				<TitleContainer>
					<SemiP1>{t('web.report.mmse.accordion.title')}</SemiP1>
					<Tag>
						<TagText>
							{t('web.report.shortProvider.dcrPlus.research')}
						</TagText>
					</Tag>
				</TitleContainer>
			}
		>
			<Content marginOfError={marginOfError} score={score} />
		</Accordion>
	);
}

function Content({ marginOfError, score }: Readonly<Props>) {
	const { t } = useTranslation();

	return (
		<ContentWrapper>
			<P1>{t('web.report.mmse.accordion.subtitle')}</P1>
			<ScoreContainer>
				<ScoreBox marginOfError={marginOfError} score={score} />
				<Prediction>
					{t('web.report.mmse.accordion.scoreLabel')}
				</Prediction>
			</ScoreContainer>
			<Disclaimer>
				<Separator />
				<P2>
					<Trans
						i18nKey='web.report.mmse.accordion.disclaimer'
						components={{
							medium: <DisclaimerMedium />,
						}}
					/>
				</P2>
			</Disclaimer>
		</ContentWrapper>
	);
}

export function ScoreBox({ marginOfError, score }: Readonly<Props>) {
	const _score = score < 0 ? '--' : score;

	return (
		<ScoreWrapper>
			<SemiP1>{_score}</SemiP1>
			<Caption>(± {marginOfError})</Caption>
		</ScoreWrapper>
	);
}
