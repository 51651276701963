import { css, styled } from 'styled-components';

import { P1 } from 'components/shared/designSystem';
import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';

export const TableContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const SpanSemi = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const TotalLabel = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-height: 40px;
		padding-top: 15px;
	`
);

export const TotalScore = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-height: 40px;
		padding-top: 15px;
	`
);

export const QuestionTableContainer = styled(P1)`
	height: 100%;
`;

export const ChoicesContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.xs};
		margin-right: 120px;
		max-width: 370px;

		@media ${MediaQueries.maxWidth.lg} {
			margin-right: 0px;
		}
	`
);

export const SelectedChoice = styled(P1)(
	({ theme: { colors, spacing, weight } }) => css`
		padding: ${spacing.xs};
		background-color: ${colors.gray_80};
		border: 1px solid ${colors.gray_60};
		border-radius: 4px;
		font-weight: ${weight.medium};
		min-width: 372px;
		white-space: nowrap;

		@media ${MediaQueries.maxWidth.lg} {
			min-width: unset;
		}
	`
);

export const SelectedFaded = styled(P1)(
	({ theme: { color, spacing } }) => css`
		padding: ${spacing.xs};
		color: ${color.bodyTextSecondary};
		min-width: 372px;
		white-space: nowrap;

		@media ${MediaQueries.maxWidth.lg} {
			min-width: unset;
		}
	`
);

export const EndOfQuestionnaire = styled(P1)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
		text-align: center;
	`
);
