import {
	GetReasonsByOrganizationFilterField,
	GetReasonsByOrganizationFilterOperator,
	GetReasonsByOrganizationLogicalOperator,
} from '@lh/eng-platform-organization-service-rest-client';

import { ValueListItem } from 'generated/graphql';

export enum FieldType {
	FilterField = 'filter-field',
	FilterOperator = 'filter-operator',
	LogicalOperator = 'logical-operator',
}

export function getReasonsByOrgFields(length: number, type: FieldType) {
	if (length === 0) {
		return;
	}

	if (type === FieldType.FilterField) {
		return Array(length).fill(
			GetReasonsByOrganizationFilterField.DiagnosisCode
		);
	}

	if (type === FieldType.FilterOperator) {
		return Array(length).fill(
			GetReasonsByOrganizationFilterOperator.Equals
		);
	}

	if (type === FieldType.LogicalOperator) {
		return Array(length - 1).fill(
			GetReasonsByOrganizationLogicalOperator.Or
		);
	}
}

export function getAdministratorCredentials(suffix: ValueListItem[]) {
	const credentials: string[] = [];

	if (suffix && suffix.length > 0)
		suffix.forEach((item) => {
			if (item.display) {
				credentials.push(item.display);
			}
		});

	if (credentials.length > 0) {
		return `, ${credentials.join(', ')}`;
	}

	return '';
}
