import { Center, Stack } from '@mantine/core';

import { theme } from 'components/providers/StyleProvider/theme';
import { H4, P2 } from 'components/shared/designSystem/TextComponents';
import { t } from 'i18n';

import { TEXT } from './Pathway.i18n';

interface Props {
	id?: string;
	type: 'green' | 'yellow' | 'red';
	patient: {
		name: string;
		score: number | null;
	};
}

export function Header({ id, type, patient }: Props) {
	const { title, description } = TEXT.header;

	const dataMap: Record<
		typeof type,
		{
			color: string;
			description: string;
		}
	> = {
		green: {
			color: theme.colors.green,
			description: description.noCognitiveImpairment,
		},
		yellow: {
			color: theme.colors.yellow,
			description: description.borderlineCognitiveImpairment,
		},
		red: {
			color: theme.colors.orange,
			description: description.cognitiveImpairment,
		},
	};

	const data = dataMap[type];

	return (
		<Container id={id} color={data.color}>
			<Score
				value={t(title, {
					name: patient.name,
					score: patient.score,
					maxScore: 5,
				})}
				color={data.color}
			/>
			<Description value={t(data.description)} />
		</Container>
	);
}

function Container({
	id,
	color,
	children,
}: {
	id?: string;
	color: string;
	children: React.ReactNode;
}) {
	return (
		<Stack
			id={id}
			style={{
				gap: 0,
				width: '100%',
				maxWidth: 460,
				border: `2px solid ${color}`,
				borderRadius: 6,
				overflow: 'hidden',
			}}
		>
			{children}
		</Stack>
	);
}

function Score({ value, color }: { value: string; color: string }) {
	return (
		<Center
			data-testid='pathway-header'
			style={{
				padding: 4,
				minHeight: 35,
				textAlign: 'center',
				backgroundColor: color,
			}}
		>
			<H4
				style={{
					color: theme.colors.gray_10,
					fontWeight: theme.weight.medium,
				}}
			>
				{value}
			</H4>
		</Center>
	);
}

function Description({ value }: { value: string }) {
	return (
		<Center py={14.5} px={8}>
			<P2
				style={{
					color: theme.colors.gray_10,
					fontWeight: theme.weight.semi,
				}}
			>
				{value}
			</P2>
		</Center>
	);
}
