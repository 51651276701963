import { P1 } from 'components/shared/designSystem';
import styled, { css } from 'styled-components';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.sm};
	`
);

export const LineWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin: ${spacing.md} 0;
	`
);

export const ReferenceWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin: ${spacing.md} 0;
	`
);

export const InterpretationContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const InterpretationTitle = styled(P1)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		margin-bottom: ${spacing.md};
	`
);

export const ScoreWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const ScoreRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xs};
	`
);

export const Score = styled.div<{ backgroundColor: string; color: string }>(
	({ backgroundColor, color, theme: { borderRadius, weight } }) => css`
		width: 77px;
		color: ${color};
		border-radius: ${borderRadius.searchBox};
		background-color: ${backgroundColor};
		text-align: center;
		font-weight: ${weight.semi};
	`
);

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const ButtonWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: center;
		margin-top: ${spacing.sm};
	`
);
