import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SegmentType } from '../../../../../generated/graphql';
import { H4 } from '../../../../shared/designSystem/TextComponents';
import { StyledCard } from '../../../../shared/StyledCard';
import { FormattedSegmentResults } from '../../../cognitiveEvalParseData/cognitiveEvalParseData';
import { DctClockSubscore } from '../../../SegmentComponents/DctClockSubscore/DctClockSubscore';
import { RecallSubscore } from '../../../SegmentComponents/RecallSubscore/RecallSubscore';

type MiniModalSubscoresProps = {
	miniModalSubscoreSegments: FormattedSegmentResults[];
};

export const MiniModalSubscores = ({
	miniModalSubscoreSegments,
}: MiniModalSubscoresProps): JSX.Element => {
	const { t } = useTranslation();

	// Immediate Recall
	const immediateRecallData = miniModalSubscoreSegments.find(
		(subscoreSegment) =>
			subscoreSegment?.segmentType === SegmentType.RecallImmediate
	);

	// DCTClock
	const dctClockData = miniModalSubscoreSegments.find(
		(subscoreSegment) => subscoreSegment?.segmentType === SegmentType.Clock
	);

	// Delayed Recall
	const delayedRecallData = miniModalSubscoreSegments.find(
		(subscoreSegment) =>
			subscoreSegment?.segmentType === SegmentType.RecallDelayed
	);

	return (
		<StyledWrapper>
			<StyledTitle>{t`web.dcrReport.subscores.title`}</StyledTitle>
			<StyledContainer>
				<RecallSubscore
					title={t`web.dcrReport.subscores.recall.immediateRecall.title`}
					recallData={immediateRecallData}
				/>
				<StyledSeparator />
				<DctClockSubscore dctClockData={dctClockData} />
				<StyledSeparator />
				<RecallSubscore
					title={t`web.dcrReport.subscores.recall.delayedRecall.title`}
					recallData={delayedRecallData}
					showConcerns={true}
				/>
			</StyledContainer>
		</StyledWrapper>
	);
};

const StyledWrapper = styled(StyledCard)`
	height: auto;
	min-width: 1100px;
	padding: 30px;
`;

const StyledTitle = styled(H4)(
	({ theme: { weight, spacing } }) => `
		font-weight: ${weight.medium};
		margin-bottom: ${spacing.md};
	`
);

const StyledContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-content: center;
`;

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
		border-left: 1px solid ${color.infoSeparator};
	`
);
