import {
	AssessmentType,
	DeepAssessmentResult,
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import { t } from 'i18n';

import { TabItem } from 'components/layout/TabbedLayout/TabbedLayout';
import { Questionnaire } from 'enums/questionnaire';
import { BatteryType } from '../../../generated/graphql';
import { MiniModalReport } from '../AssessmentComponents/MiniModal/MiniModalReport';
import { BdstReport } from '../BDST';
import { Report } from '../CognitiveEvaluation.types';
import { DCTClockLayout } from '../DCTClockLayout';
import { FastReport } from '../FAST/FastReport';
import { Gad7Report } from '../Gad7';
import { GdsReport } from '../GeriatricDepressionScale';
import { HearingScreenerReportLayout } from '../HearingScreener/HearingScreenerReport';
import { OrientationLayout } from '../OrientationQuestionaire';
import { LifeAndHealthReport } from '../SegmentComponents/LifeAndHealth/LifeAndHealthReport';
import { RecallRecording } from '../SegmentComponents/RecallRecording/RecallRecording';
import { TrailsLayout } from '../TrailsLayout';
import { PhonemicLayout } from '../custom/Fluency/Phonemic';
import { SemanticLayout } from '../custom/Fluency/Semantic';
import { DelayedRecallReport } from '../custom/PVLT/DelayedRecall';
import { DelayedRecognitionReport } from '../custom/PVLT/DelayedRecognition';
import { ImmediateRecallReport } from '../custom/PVLT/ImmediateRecall';
import { IadlLayout } from '../custom/iadl/IadlLayout/IadlLayout';

export const cognitiveEvaluationAccessibleTabs = (
	batteryTabs: TabItem[],
	batteryType: string,
	showCDSTab: boolean
): TabItem[] => {
	if (batteryType === BatteryType.Lhq) {
		return batteryTabs?.filter(
			(tab) =>
				tab?.name ===
				t`web.report.cognitiveEvaluation.batteryTabs.lifeHealth`
		);
	}
	if (batteryType === BatteryType.Dcr) {
		return batteryTabs?.filter(
			(tab) =>
				tab?.name === t`web.report.cognitiveEvaluation.batteryTabs.dcr`
		);
	}
	if (!showCDSTab) {
		return batteryTabs?.filter(
			(tab) => tab?.name !== t`cds.fullArticle.recommendationsLabel`
		);
	}
	return batteryTabs;
};

export function generateDynamicTabs(batteryResult: Report) {
	const batteryTabs: TabItem[] = [];

	if (batteryResult.batteryResultById) {
		const { assessmentResults } = batteryResult.batteryResultById;

		assessmentResults.forEach((assessmentResult) => {
			const { segmentResults } = assessmentResult;

			const assessmentTab = assessmentTabIdentifier(
				assessmentResult,
				batteryResult
			);

			if (assessmentTab) {
				batteryTabs.push(assessmentTab);
			} else {
				segmentResults.forEach((segmentResult) => {
					const tab = tabIdentifier(segmentResult, batteryResult);
					if (tab) {
						batteryTabs.push(tab);
					}
				});
			}
		});
	}

	return batteryTabs;
}

function assessmentTabIdentifier(
	assessmentResult: DeepAssessmentResult,
	batteryResult: Report
): TabItem | undefined {
	const { assessment } = assessmentResult;

	switch (assessment?.assessmentType) {
		case AssessmentType.Dcr:
			return {
				name: t`web.report.cognitiveEvaluation.batteryTabs.dcr`,
				Component: MiniModalReport,
				props: {
					batteryResult,
				},
			};
		default:
			return undefined;
	}
}

function tabIdentifier(
	segmentResult: DeepSegmentResult,
	batteryResult: Report
): TabItem | undefined {
	const { segment } = segmentResult;

	switch (segment.segmentType) {
		case SegmentType.BackwardsDigitSpan:
			return {
				name: t`web.report.bdstReport.backwardsDigitSpan`,
				Component: BdstReport,
				props: {
					batteryResult,
					segmentResult,
				},
			};
		case SegmentType.RecallImmediate:
			return {
				name: segment.name,
				Component: RecallRecording,
				props: {
					subTitle: t`web.dcrReport.recordings.recall.immediate.subtitle`,
					title: t`web.dcrReport.recordings.recall.immediate.title`,
					segment: segmentResult,
					tooltipText: t`web.dcrReport.recordings.recall.immediate.tooltipText`,
				},
			};
		case SegmentType.PhonemicFluency:
			return {
				name: t`web.report.phonemicFluencyReport.phonemic`,
				Component: PhonemicLayout,
				props: {
					batteryResult,
					segmentResult,
				},
			};
		case SegmentType.CustomQuestionnaire:
			if (segment.metadata && segment.metadata.qnrId) {
				if (segment.metadata.qnrId === Questionnaire.FAST) {
					return {
						name: t`web.report.fastReport.fastEmpty`,
						Component: FastReport,
						props: {
							data: batteryResult,
							segmentResult,
						},
					};
				}
				if (segment.metadata.qnrId === Questionnaire.GAD_7) {
					return {
						name: t`web.report.gad7.gadEmpty`,
						Component: Gad7Report,
						props: {
							data: batteryResult,
							segmentResult,
						},
					};
				}
				if (segment.metadata.qnrId === Questionnaire.GDS) {
					return {
						name: t`web.report.gds.gdsEmpty`,
						Component: GdsReport,
						props: {
							batteryResult,
							segmentResult,
						},
					};
				}
				if (segment.metadata.qnrId === Questionnaire.ADL) {
					return {
						name: t`web.report.iadl.tab`,
						Component: IadlLayout,
						props: {
							type: 'adl',
							iadlQuestionnaireData: batteryResult,
						},
					};
				}
				if (segment.metadata.qnrId === Questionnaire.IADL) {
					return {
						name: t`web.report.iadl.tab`,
						Component: IadlLayout,
						props: {
							iadlQuestionnaireData: batteryResult,
						},
					};
				}
				if (segment.metadata.qnrId === Questionnaire.ORIENTATION_6) {
					return {
						name: t`web.report.orientationQuestionnaireReport.orientationEmpty`,
						Component: OrientationLayout,
						props: {
							orientationQuestionnaireData: batteryResult,
							segmentResult,
						},
					};
				}
			}
			break;
		case SegmentType.RecallDelayed:
			return {
				name: segment.name,
				Component: RecallRecording,
				props: {
					subTitle: t`web.dcrReport.recordings.recall.delayed.subtitle`,
					title: t`web.dcrReport.recordings.recall.delayed.title`,
					segment: segmentResult,
					tooltipText: t`web.dcrReport.recordings.recall.delayed.tooltipText`,
				},
			};
		case SegmentType.SemanticFluency:
			return {
				name: t`web.report.semanticFluencyReport.semantic`,
				Component: SemanticLayout,
				props: {
					batteryResult,
					segmentResult,
				},
			};
		case SegmentType.Clock:
			return {
				name: segment.name,
				Component: DCTClockLayout,
				props: {
					segmentResult,
				},
			};
		case SegmentType.Lhq15:
		case SegmentType.Lhq32:
			return {
				name: segment.name,
				Component: LifeAndHealthReport,
				props: {
					segmentResult,
				},
			};
		case SegmentType.HearingScreener:
			return {
				name: segment.name,
				Component: HearingScreenerReportLayout,
				props: {
					segmentResult,
				},
			};
		case SegmentType.TrailsA:
		case SegmentType.TrailsB:
		case SegmentType.TrailsV2A:
		case SegmentType.TrailsV2B:
			return {
				name: t`web.report.trailsReport.title`,
				Component: TrailsLayout,
				props: {
					segmentResult,
				},
			};
		case SegmentType.ImmediateRecall6:
			return {
				name: t`web.report.pvltImmediateReport.immediateRecall`,
				Component: ImmediateRecallReport,
				props: {
					segmentResult,
				},
			};
		case SegmentType.DelayedRecall6:
			return {
				name: t`web.report.pvltDelayedReport.delayedRecall`,
				Component: DelayedRecallReport,
				props: {
					segmentResult,
				},
			};
		case SegmentType.DelayedRecognition:
			return {
				name: t`web.report.pvltDelayedRecognition.title`,
				Component: DelayedRecognitionReport,
				props: {
					segmentResult,
				},
			};
		default:
			return undefined;
	}
}
