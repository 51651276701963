import styled, { css } from 'styled-components';

import { Field, useField } from 'formik';
import { useState } from 'react';

import { P1 } from 'components/shared/designSystem/TextComponents';
import { LinusInputChildrenProps } from '../../../../types';

import { definedProps } from './definedProps';

const NUMBER_FORMAT = '000-000-0000';
const MAX_LENGTH = 12;

export const PHONE_NUMBER_REGEX = /^\d{3}-\d{3}-\d{4}$/;

export const PhoneNumber = <T,>(
	props: LinusInputChildrenProps<T>
): JSX.Element => {
	const [field] = useField(props.name);
	const definedProps_ = definedProps(props);

	const [isFocused, setIsFocused] = useState(false);
	const [phonePlaceholder, setPhonePlaceholder] = useState({
		value: NUMBER_FORMAT,
	});

	function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
		e.target.value = e.target.value.replace(/\D/g, '');
		const inputValue = e.target.value.replace(/-/g, '');

		let formattedValue = '';
		for (let i = 0; i < inputValue.length; i++) {
			if (i === 3 || i === 6) {
				formattedValue = formattedValue + '-';
			}
			formattedValue = formattedValue + inputValue[i];
		}

		setPhonePlaceholder((prevState) => ({
			...prevState,
			value: NUMBER_FORMAT.slice(formattedValue.length),
		}));

		e.target.value = formattedValue;
		field.onChange(e);
	}

	return (
		<Container>
			<StyledField
				data-testid='contactPhone'
				{...definedProps_}
				onChange={handleInputChange}
				onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
					field.onBlur(e);
					setIsFocused(false);
				}}
				onFocus={() => setIsFocused(true)}
				placeholder={definedProps_.label}
				maxLength={MAX_LENGTH}
			/>
			{phonePlaceholder.value !== NUMBER_FORMAT && isFocused && (
				<PlaceholderContainer>
					<Placeholder>{phonePlaceholder.value}</Placeholder>
				</PlaceholderContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
`;

const Placeholder = styled(P1)(
	({ theme: { fontSize, weight, colors } }) => css`
		font-size: ${fontSize._16};
		font-weight: ${weight.light};
		color: ${colors.gray_40};
		position: absolute;
		right: 0;
	`
);

const PlaceholderContainer = styled.div`
	position: absolute;
	top: 22.5px;
	left: 20px;
	width: 116px;
`;

const StyledField = styled(Field)(
	({ value, error, disabled, theme: { color } }) => css`
		position: relative;
		display: flex;
		align-items: flex-end;
		width: 100%;
		height: 100%;
		border-radius: 6px;
		background: ${disabled ? color.formDisabledBg : color.white};
		box-sizing: border-box;
		padding-left: 16px;
		color: ${color.formText};
		font-size: 16px;
		transition: 0.2s ease all;
		border: 1px solid ${error ? color.formError : color.inputBorder};
		padding-top: ${value ? '16px' : '0px'};

		&::placeholder {
			color: ${disabled ? color.formTextDisabled : color.formText};
		}

		&:hover {
			border: 1px solid ${disabled ? color.inputBorder : color.inputHover};
			cursor: ${disabled ? 'not-allowed' : 'pointer'};
		}

		&:focus {
			border: 1px solid ${color.inputFocus};
			outline: none;
		}
	`
);
