import styled from 'styled-components';

import { useEffect, useState } from 'react';

import { AnalyticsAction, sendEventData } from 'analytics';
import { SortDir } from '../../../../generated/graphql';
import { P1 } from '../../designSystem/TextComponents';
import { TableColumn } from '../DataTable';
import { SortArrow } from '../SortArrow';

type HeaderCellProps<T> = {
	column: TableColumn<T>;
	value: string | JSX.Element;
	onSortData?: (dir: SortDir | undefined, prop: string) => void;
	activeHeader?: string;
};

const PaginatedHeaderCell = <T,>({
	column,
	value,
	onSortData,
	activeHeader,
}: HeaderCellProps<T>): JSX.Element | null => {
	const [sortDir, setSortDir] = useState<SortDir | undefined>(undefined);
	const prop = column.sortProperty || column.propertyName;

	useEffect(() => {
		if (!activeHeader || activeHeader !== prop) {
			setSortDir(undefined);
		}
	}, [activeHeader, prop]);

	const onSort = () => {
		onSortData && onSortData(getSortDir(sortDir), prop);
		setSortDir((sortDir) => getSortDir(sortDir));
	};

	const sortProp = {
		onClick: () => {
			sendEventData({
				eventType: column.sortable
					? AnalyticsAction.ClickedSortableColumnHeader
					: AnalyticsAction.ClickedUnsortableColumnHeader,
				eventProperties: { columnName: column.headerDisplay ?? '' },
			});
			column.sortable && onSort();
		},
	};

	return (
		<StyledHeaderCell
			data-testid={`header_cell_${column.headerDisplay}`}
			value={value}
			width={column.width}
			$minWidth={column.minWidth}
			{...sortProp}
		>
			<StyledP1>{value}</StyledP1>
			{column.sortable ? (
				<StyledSortArrow>
					<SortArrow sortDir={sortDir} />
				</StyledSortArrow>
			) : null}{' '}
		</StyledHeaderCell>
	);
};

const getSortDir = (prevSortDir: SortDir | undefined) => {
	if (!prevSortDir) {
		return SortDir.Asc;
	} else if (prevSortDir === SortDir.Asc) {
		return SortDir.Desc;
	} else {
		return undefined;
	}
};

export { PaginatedHeaderCell };

interface StyledHeaderCellProps {
	width?: string;
	$minWidth?: string;
	value: string | JSX.Element;
}

const StyledHeaderCell = styled.div<StyledHeaderCellProps>(
	({ width, $minWidth, value, theme: { color, spacing } }) => `
	padding: 0 ${spacing.lg};
	box-sizing: border-box;
	width: ${width || '100%'};
	min-width: ${$minWidth || 'none'};
	max-width: 100%;
	height: 62px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: 0.2s ease all;
	
	&:first-child {
		border-left: none;
	}

	&:hover {
		background: ${value ? color.cellHoverBg : ''};
		cursor: ${value ? 'pointer' : ''};
	}
	`
);

const StyledP1 = styled(P1)(
	({ theme: { weight } }) => `
	display: flex;
	align-items: center;
	height: auto;
	margin: 0;
	font-weight: ${weight.medium};
	`
);

const StyledSortArrow = styled.div`
	margin-left: 5px;
`;
