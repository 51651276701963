import amplitude from 'amplitude-js';
import i18n from 'i18n';
import { debounce } from 'lodash';

import { analyticsAction as analyticsActionEnum } from 'enums/analyticsAction';
import { CurrentUser } from 'types';
import { config } from '../config';

import { AnalyticsAction, AnalyticsActionType } from './AnalyticsAction';

const HALF_SECOND_DELAY_MILLIS = 500;

type SendEventDataProps = {
	eventType: AnalyticsActionType;
	eventProperties?: Record<string, string | boolean | string[]>;
};

export const updateAmplitudeWithProps = (currentUser: CurrentUser): void => {
	const userProps = (({
		id,
		roleId,
		organizationId,
		organizationName,
		organizationType,
		organizationStatus,
	}) => ({
		id,
		roleId,
		organizationId,
		organizationName,
		organizationType,
		organizationStatus,
	}))(currentUser);
	const _role = i18n.exists(userProps.roleId)
		? i18n.t(userProps.roleId)
		: userProps.roleId;
	const userPropsAreValid = Object.values(userProps).every((item) => item);
	try {
		if (!userPropsAreValid) {
			throw new Error('Missing some or all user properties');
		}
		amplitude.getInstance().setUserId(userProps.id);
		amplitude.getInstance().setUserProperties({
			role: _role,
			orgId: userProps.organizationId,
			orgName: userProps.organizationName,
			orgType: userProps.organizationType,
			orgStatus: userProps.organizationStatus,
			aws_region: config.awsRegion,
		});
	} catch (e) {
		throw new Error(
			`Error updating Amplitude session with user properties: ${e}`
		);
	}
};

export const initAmplitude = (): void => {
	try {
		amplitude.getInstance().init(config.amplitudeApiKey);
	} catch (e) {
		throw new Error('Error instantiating Amplitude SDK');
	}
};

export const sendEventData = ({
	eventType,
	eventProperties = {},
}: SendEventDataProps): void => {
	const eventDisplay = analyticsActionEnum.fromValue(eventType)?.display;
	if (!eventDisplay) {
		console.error('No display property for event type: ', eventType);
		return;
	}

	amplitude
		.getInstance()
		.logEvent(eventDisplay.toLowerCase(), eventProperties);
};

export const sendDebouncedEventData = debounce(
	sendEventData,
	HALF_SECOND_DELAY_MILLIS,
	{ trailing: true }
);

export const logout = (): void => {
	sendEventData({ eventType: AnalyticsAction.LoggedOut });
	amplitude.getInstance().setUserId(null);
	amplitude.getInstance().setUserProperties({
		role: null,
		orgId: null,
		orgName: null,
		orgType: null,
		orgStatus: null,
		aws_region: null,
	});
};
