// TODO: Starting point for phone number formatting once
// there is a need for a more general aproach of the formatting
// using this library https://www.npmjs.com/package/libphonenumber-js
// const DEFAULT_COUNTRY_CODE = "US";
// export function formatPhoneNumber(
// 	phoneNumber?: string | null,
// 	countryCode?: CountryCode
// ) {
// 	if (!phoneNumber) {
// 		return '';
// 	}

// 	if (!isValidPhoneNumber(phoneNumber, countryCode ?? DEFAULT_COUNTRY_CODE)) {
// 		return phoneNumber;
// 	}

// 	const formattedNumber = formatNumber(
// 		phoneNumber,
// 		countryCode ?? DEFAULT_COUNTRY_CODE,
// 		'INTERNATIONAL'
// 	);

// 	// everything before the first space is considered the dialing code
// 	const numberWithDashes = formattedNumber
// 		.substring(formattedNumber.indexOf(' ') + 1)
// 		.replaceAll(' ', '-');

// 	return numberWithDashes;
// }

export function formatPhoneNumber(phoneNumber?: string | null) {
	if (!phoneNumber) {
		return '';
	}
	const formattedNumber = phoneNumber.replace(/\D/g, '');
	let formattedValue = '';
	for (let i = 0; i < formattedNumber.length; i++) {
		if (i === 3 || i === 6) {
			formattedValue = formattedValue + '-';
		}
		formattedValue = formattedValue + formattedNumber[i];
	}
	return formattedValue;
}
