import { createContext } from 'react';
import { CdsRecommendation } from '../../generated/graphql';
export type CDSContextType = {
	contentVersion: string;
	cdsEnabled: boolean;
	cdsRecommendations: CdsRecommendation[];
};
const defaultContext = {
	contentVersion: '1.2.0',
	cdsEnabled: false,
	cdsRecommendations: [],
};
export const CDSContext = createContext<CDSContextType>(defaultContext);
