import { useQuery } from '@tanstack/react-query';

import { getSubjectSubService } from 'api/getSubjectSubService';
import { QueryKey } from 'api/query';

export async function getSubject(subjectId: string, organizationId: string) {
	if (!subjectId) {
		throw new Error("useGetSubjectQuery: subjectId can't be null");
	}

	if (!organizationId) {
		throw new Error("useGetSubjectQuery: organizationId can't be null");
	}

	const service = await getSubjectSubService();
	const { data } = await service.getSubjectByOrgIdAndUuid({
		subjectId,
		organizationId,
	});
	return data;
}

export function useSubjectQuery(subjectId: string, organizationId: string) {
	return useQuery({
		enabled: !!subjectId && !!organizationId,
		meta: {
			errorMessage: 'Error fetching subject',
		},
		queryKey: [QueryKey.Subject, subjectId, organizationId],
		queryFn: () => getSubject(subjectId, organizationId),
		staleTime: Infinity,
	});
}
