import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { Caption, H4, P1 } from 'components/shared/designSystem';

export const IadlTableContainer = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
		padding: ${spacing.lg};
	`
);

export const TableHeaderContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const H4Med = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const AnswerInfoContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: ${spacing.sm};
	`
);

export const AdditionalInfoContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-wrap: wrap;
		gap: ${spacing.md};
		margin-right: ${spacing.xs};
	`
);

export const CaptionRed = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.textAlert};
	`
);

export const CaptionGreen = styled(Caption)(
	({ theme: { colors } }) => css`
		color: ${colors.green_darkest};
	`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);

export const IconContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xxs};
	`
);

export const QuestionsContainer = styled(P1)`
	display: flex;
`;

export const SpanSemi = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const TotalLabel = styled(P1)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		display: flex;
		justify-content: flex-end;
		padding-top: ${spacing.md};
	`
);

export const IconScoreContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
		padding-top: ${spacing.md};
	`
);

export const P1Semi = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const IconAnswerContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

export const GradeIconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
`;

export const EndOfQuestionnaire = styled(P1)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
		text-align: center;
	`
);

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const SemiSpan = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);
