import { AssignmentStatus } from '../../generated/graphql';
import { generateTranslationEnum } from '../../utils/enumTranslationUtils';

export const assignmentStatus = generateTranslationEnum<
	typeof AssignmentStatus
>(AssignmentStatus, 'web.enums.assignmentStatus');

assignmentStatus.Deleted.index = 1;
assignmentStatus.Assigned.index = 2;
assignmentStatus.Complete.index = 3;
