import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H1 } from '../../../../shared/designSystem/TextComponents';
import { QuestionnaireQuestion } from '../QuestionnaireQuestion';
import { StyledCard } from '../../../../shared/StyledCard';
import { icons, Icons } from '../../../../../enums/icons';
import { Pillar } from '../../../../../enums/Pillar';
import { EnumItem } from '../../../../../enums/enumeration';
import { RefObject, useEffect } from 'react';
import { scrollToRefByOffset } from '../../../../layout/TabbedLayout/TabbedLayout';
import { Answer, LHQ32QuestionList } from '@lh/eng-lhq-questions-common';

type QuestionnaireLayoutProps = {
	questions: LHQ32QuestionList;
	patientAnswers: Answer[];
	offsetComponentRef?: RefObject<HTMLDivElement>;
};
const QuestionnaireLayout = ({
	questions,
	patientAnswers,
	offsetComponentRef,
}: QuestionnaireLayoutProps): JSX.Element => {
	const { t } = useTranslation();
	const getQuestionRows = () => {
		const rows = [];
		//Loop in groups of 2
		for (let i = 0; i < patientAnswers.length; i += 2) {
			const questionTitle = t`web.report.lifeAndHealth.answers.questionTitle`;

			const leftIndex = i;
			const rightIndex = leftIndex + 1;

			const questionLeftId = patientAnswers[leftIndex]?.id;
			const questionRightId = patientAnswers[rightIndex]?.id;
			const questionLeft = questions[questionLeftId];
			const questionRight = questions[questionRightId];

			const iconLeft =
				icons[
					`${
						Pillar.fromValue(questionLeft?.pillar)?.key
					}LHQ` as keyof Icons
				];
			const patientAnswerLeft = patientAnswers.find(
				(x) => x.id === questionLeftId
			);
			const patientAnswerRight =
				questionRight &&
				patientAnswers.find((x) => x.id === questionRightId);

			const iconRight =
				questionRight &&
				icons[
					`${
						Pillar.fromValue(questionRight.pillar)?.key
					}LHQ` as keyof Icons
				];

			const questionLeftText = t(questionLeft?.questionTestKey);
			const questionRightText = t(questionRight?.questionTestKey);

			// TODO: The numbers will need to be considered when we have non-b10 number sets for a locale
			rows.push(
				<StyledQuestionRow
					data-testid={`questionRow${(i + 2) / 2}`}
					key={leftIndex}
				>
					<QuestionnaireQuestion
						name={`${questionTitle} ${leftIndex + 1}`} // Question 1
						text={questionLeftText}
						patientAnswer={patientAnswerLeft?.answer}
						correctAnswer={questionLeft.positiveAnswer}
						icon={iconLeft}
						pillar={
							Pillar.fromValue(questionLeft.pillar) as EnumItem
						}
					/>
					{questionRight && (
						<QuestionnaireQuestion
							data-testid={`questionRight:${questionTitle} ${
								i + 2
							}`}
							name={`${questionTitle} ${rightIndex + 1}`} // Question 2
							text={questionRightText}
							patientAnswer={patientAnswerRight?.answer}
							correctAnswer={questionRight.positiveAnswer}
							icon={iconRight}
							pillar={
								Pillar.fromValue(
									questionRight.pillar
								) as EnumItem
							}
						/>
					)}
				</StyledQuestionRow>
			);
		}
		return rows;
	};

	useEffect(() => {
		scrollToRefByOffset(offsetComponentRef);
	}, []);

	return (
		<StyledWrapper>
			<StyledH1>{t`web.report.lifeAndHealth.answers.individualAnswers`}</StyledH1>
			{getQuestionRows()}
			<StyledFooter>{t`web.report.lifeAndHealth.answers.endOfQuestionnaire`}</StyledFooter>
		</StyledWrapper>
	);
};

export { QuestionnaireLayout };

const StyledWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => `
	margin: ${spacing.xl} 0;
	padding: ${spacing.xl};
	
`
);
const StyledH1 = styled(H1)(
	({ theme: { weight } }) => `
	font-weight: ${weight.light};
`
);
const StyledQuestionRow = styled.div(
	({ theme: { color } }) => `
	display: flex;
	align-items: flex-start;
	width: 100%;
	border-bottom: 1px solid ${color.tableBorder};
	@media print {
    	break-inside: avoid;
	}
`
);
const StyledFooter = styled.div(
	({ theme: { spacing, color } }) => `
	width: 100%;
	padding: ${spacing.lg} 0 0 0;
	display: flex;
	justify-content: center;
	color: ${color.bodyTextSecondary};
`
);
