import { Flex, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import {
	AdjustedScore,
	ReportInfoPanel,
	ScoringDetailsCta,
} from 'components/mosaic';
import { theme } from 'components/providers/StyleProvider/theme';
import { AudioWidget } from 'components/shared/AudioWidget';
import { H4, P1 } from 'components/shared/designSystem';

import { CutoffsScoringDetailsModal } from '../../CutoffsScoringDetailsModal';

import { BdstPerformance, BreakdownScores } from '../BdstReport.types';
import { BdstScore } from '../BdstScore';
import { BDSTTable } from '../Table';

import {
	AudioContainer,
	AudioWidgetWrapper,
	BdstLayoutContainer,
	CaptionFaded,
	P2Med,
	RecordingsWrapper,
} from './BdstLayout.style';

export type BdstLayoutProps = {
	breakdownScores: BreakdownScores;
	performance: BdstPerformance;
	urls: {
		trialOne: string;
		trialTwo: string;
		trialThree: string;
	};
};

export function BdstLayout({
	breakdownScores,
	performance,
	urls,
}: Readonly<BdstLayoutProps>) {
	const [opened, { open: openModal, close: closeModal }] =
		useDisclosure(false);
	const { t } = useTranslation();

	return (
		<BdstLayoutContainer>
			<ReportInfoPanel
				title={t('web.report.bdstReport.backwardsDigitSpan')}
				tooltipText={
					t('web.report.bdstReport.description', {
						returnObjects: true,
					}) as string[]
				}
			>
				<Flex gap={theme.spacing.md}>
					<Stack gap={12}>
						<BdstScore performance={performance} />
						{performance.score.adjusted && <AdjustedScore />}
						{performance.cutoff && (
							<ScoringDetailsCta onClick={openModal} />
						)}
					</Stack>
					<BDSTTable
						trialOne={{
							any: breakdownScores.trialOne.score.any,
							serial: breakdownScores.trialOne.score.serial,
						}}
						trialTwo={{
							any: breakdownScores.trialTwo.score.any,
							serial: breakdownScores.trialTwo.score.serial,
						}}
						trialThree={{
							any: breakdownScores.trialThree.score.any,
							serial: breakdownScores.trialThree.score.serial,
						}}
					/>
				</Flex>
			</ReportInfoPanel>
			<Recordings urls={urls} breakdownScores={breakdownScores} />
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
			{performance.cutoff && (
				<CutoffsScoringDetailsModal
					ageRange={performance.cutoff.ageRange}
					cutoff={performance.cutoff.value}
					open={opened}
					onClose={closeModal}
				/>
			)}
		</BdstLayoutContainer>
	);
}

type RecordingsProps = {
	breakdownScores: BdstLayoutProps['breakdownScores'];
	urls: BdstLayoutProps['urls'];
};

function Recordings({ breakdownScores, urls }: Readonly<RecordingsProps>) {
	const { t } = useTranslation();

	return (
		<RecordingsWrapper>
			<H4
				style={{
					marginLeft: '24px',
					fontWeight: 500,
				}}
			>
				{t('web.shared.recordings')}
			</H4>
			<AudioContainer>
				<AudioWidgetWrapper
					style={{
						gridColumn: '1/2',
						gridRow: '1/2',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
					}}
				>
					<div>
						<P1
							style={{
								fontWeight: 600,
							}}
						>
							{t('web.report.bdstReport.trial_1')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
						<P2Med
							style={{
								marginTop: '2px',
							}}
						>
							{t('web.shared.prompt')}
							{': '}
							{breakdownScores.trialOne.audio.prompt}
						</P2Med>
						<P2Med>
							{t('web.shared.backwards')}
							{': '}
							{breakdownScores.trialOne.audio.backwards}
						</P2Med>
					</div>
					<AudioWidget srcUrl={urls.trialOne} />
				</AudioWidgetWrapper>
				<AudioWidgetWrapper
					style={{
						gridColumn: '2/2',
						gridRow: '1/2',
						display: 'flex',
						flexDirection: 'column',

						gap: '12px',
					}}
				>
					<div>
						<P1
							style={{
								fontWeight: 600,
							}}
						>
							{t('web.report.bdstReport.trial_2')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
						<P2Med
							style={{
								marginTop: '2px',
							}}
						>
							{t('web.shared.prompt')}
							{': '}
							{breakdownScores.trialTwo.audio.prompt}
						</P2Med>
						<P2Med>
							{t('web.shared.backwards')}
							{': '}
							{breakdownScores.trialTwo.audio.backwards}
						</P2Med>
					</div>
					<AudioWidget srcUrl={urls.trialTwo} />
				</AudioWidgetWrapper>
				<AudioWidgetWrapper
					style={{
						gridColumn: '1/2',
						gridRow: '2/3',
						display: 'flex',
						flexDirection: 'column',
						gap: '12px',
					}}
				>
					<div>
						<P1
							style={{
								fontWeight: 600,
							}}
						>
							{t('web.report.bdstReport.trial_3')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</P1>
						<P2Med
							style={{
								marginTop: '2px',
							}}
						>
							{t('web.shared.prompt')}
							{': '}
							{breakdownScores.trialThree.audio?.prompt}
						</P2Med>
						<P2Med>
							{t('web.shared.backwards')}
							{': '}
							{breakdownScores.trialThree.audio?.backwards}
						</P2Med>
					</div>
					<AudioWidget srcUrl={urls.trialThree} />
				</AudioWidgetWrapper>
			</AudioContainer>
		</RecordingsWrapper>
	);
}
