import { tMany } from './Pathway.misc';

export const TEXT = {
	and: 'cds.pathway.and',
	no: 'cds.pathway.no',
	yes: 'cds.pathway.yes',
	addressHearingConcern: 'cds.pathway.addressHearingConcern',
	addressReversibleCauses: 'cds.pathway.addressReversibleCauses',
	concernsList: {
		header: {
			DCR: 'cds.pathway.concernsList.header.DCR',
			hearing: 'cds.pathway.concernsList.header.hearing',
			noConcerns: 'cds.pathway.concernsList.header.noConcerns',
		},
	},
	considerCognitiveCare: 'cds.pathway.considerCognitiveCare',
	delayedRecall: {
		lessThan_2: 'cds.pathway.delayedRecall.lessThan_2',
	},
	encourage: {
		title: 'cds.pathway.encourage.title',
		list: tMany('cds.pathway.encourage.list'),
	},
	header: {
		title: 'cds.pathway.header.title',
		description: {
			noCognitiveImpairment:
				'cds.pathway.header.description.noCognitiveImpairment',
			borderlineCognitiveImpairment:
				'cds.pathway.header.description.borderlineCognitiveImpairment',
			cognitiveImpairment:
				'cds.pathway.header.description.cognitiveImpairment',
		},
	},
	ifNotPresent: 'cds.pathway.ifNotPresent',
	ifPresent: 'cds.pathway.ifPresent',
	ifHearingNotPresent: 'cds.pathway.ifHearingNotPresent',
	ifHearingPresent: 'cds.pathway.ifHearingPresent',
	immediateReecall: {
		equals_3: 'cds.pathway.immediateReecall.equals_3',
		lessThan_3: 'cds.pathway.immediateReecall.lessThan_3',
	},
	impairmentStillPresent: 'cds.pathway.impairmentStillPresent',
	learnMore: 'cds.pathway.learnMore',
	personalizedTag: {
		immediateRecall: 'cds.pathway.personalizedTag.immediateRecall',
		delayedRecall: 'cds.pathway.personalizedTag.delayedRecall',
		score: 'cds.pathway.personalizedTag.score',
	},
	referToNeurology: 'cds.pathway.referToNeurology',
	retestAfter: {
		_3: 'cds.pathway.retestAfter._3',
		_6: 'cds.pathway.retestAfter._6',
		_12: 'cds.pathway.retestAfter._12',
	},
	retestAsap: 'cds.pathway.retestAsap',
	reversibleCauses: {
		title: 'cds.pathway.reversibleCauses.title',
	},
	youAreHere: 'cds.pathway.youAreHere',
};
