import { css, styled } from 'styled-components';

export const ConfirmationContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: ${spacing.sm};
		width: 100%;
	`
);

export const HeaderIconContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		gap: ${spacing.sm};
	`
);

export const Center = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
