import {
	CreateSubjectInput,
	Persona,
} from '@lh/eng-platform-subject-service-rest-client';

import { getContactMethod } from '../patient.helpers';

import { PartnerInfo } from './CarePartner.types';

export function modifyPartnerValues(values: PartnerInfo): CreateSubjectInput {
	const displayedContactPhone = values.contactPhone
		? values.contactPhone
		: null;
	const displayedContactEmail = values.contactEmail
		? values.contactEmail
		: null;

	return {
		firstName: values.firstName ?? '',
		lastName: values.lastName ?? '',
		contactPreference: getContactMethod(
			displayedContactEmail,
			displayedContactPhone
		),
		contactEmail: displayedContactEmail,
		contactPhone: displayedContactPhone,
		language: values.language ?? 'TO_BE_COLLECTED_AT_TIME_OF_TESTING',
		personas: values.personas ?? [Persona.CarePartner],
	};
}
