import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useMemo } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';

import { parseImmediateRecallMetrics } from './parseImmediateRecallMetrics';

enum ImmediateRecallFilename {
	ImmediateRecall1 = 'immediate-recall-1',
	ImmediateRecall2 = 'immediate-recall-2',
}

function buildFilesToFetch(segmentResultId: string): S3Segment[] {
	return [
		{
			segmentResultId,
			type: ImmediateRecallFilename.ImmediateRecall1,
			filename: 'immediate-recall-audio-1.mp4',
		},
		{
			segmentResultId,
			type: ImmediateRecallFilename.ImmediateRecall1,
			filename: 'immediate-recall-audio-1.webm',
		},
		{
			segmentResultId,
			type: ImmediateRecallFilename.ImmediateRecall2,
			filename: 'immediate-recall-audio-2.mp4',
		},
		{
			segmentResultId,
			type: ImmediateRecallFilename.ImmediateRecall2,
			filename: 'immediate-recall-audio-2.webm',
		},
	];
}

export function useImmediateRecallData(segmentResult: DeepSegmentResult) {
	const { trialsResult, requiresReview } = useMemo(() => {
		return parseImmediateRecallMetrics(segmentResult.metricItems);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	audioFiles.forEach((file) => {
		if (file.type === ImmediateRecallFilename.ImmediateRecall1) {
			trialsResult.trial1.url = file.responseUrl;
		}

		if (file.type === ImmediateRecallFilename.ImmediateRecall2) {
			trialsResult.trial2.url = file.responseUrl;
		}
	});

	return { trialsResult, requiresReview };
}
