import {
	ApolloClient,
	ApolloProvider as ApolloProviderDefault,
	DefaultOptions,
	createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import { config } from '../../../config';
import { Auth } from '../../../features/auth-service';
import { cache } from './cache';

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'network-only',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	},
	mutate: {
		errorPolicy: 'all',
	},
};

const httpLink = createHttpLink({
	uri: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}/${config.apiDomain}`,
});

interface ApolloProviderProps {
	children: React.ReactNode;
}

export function ApolloProvider({ children }: ApolloProviderProps) {
	const client = useMemo(() => {
		const authLink = setContext(async (_, { headers }) => {
			const jwt = await Auth.currentIdToken();

			if (jwt) {
				return {
					headers: {
						...headers,
						authorization: `Bearer ${jwt.token}`,
					},
				};
			}

			return {
				headers,
			};
		});

		return new ApolloClient({
			link: authLink.concat(httpLink),
			cache,
			defaultOptions,
		});
	}, []);

	return (
		<ApolloProviderDefault client={client}>
			{children}
		</ApolloProviderDefault>
	);
}
