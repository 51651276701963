import { createContext } from 'react';

import {
	OrganizationStatus,
	OrganizationType,
	UserStatus,
	ValueListType,
} from '@lh/eng-platform-organization-service-rest-client';

import { Enumeration, VLEnum } from 'enums/enumeration';
import { EnumItem, locale } from 'enums/locale';
import { CurrentUser } from 'types';

export type UserContextProps = {
	currentUser: CurrentUser;
	setCurrentUser: (user: CurrentUser, isImpersonation?: boolean) => void;
	updateCurrentUser: () => void;
	refetchCurrentUser: () => void;
	onLogin: (user: CurrentUser) => void;
	logout: (redirectUrl?: string) => void;
	clearImpersonation: () => void;
};

export type ValueListEntries = {
	[key in ValueListType]?: Enumeration<VLEnum>;
};

export type OrganizationSettings = {
	organizationDateFormat: string;
	organizationDefaultUserLocale: EnumItem;
	organizationValueLists: ValueListEntries;
	organizationParticipantLanguages: Enumeration<VLEnum>;
	organizationId: string;
	organizationType: OrganizationType;
	organizationStatus: OrganizationStatus;
	organizationName: string;
	organizationFeatures: string[];
	organizationDefaultTimezone: string;
	organizationCdsConcerns: string[];
};

export const nulloUser: CurrentUser = {
	id: '',
	firstName: '',
	lastName: '',
	email: '',
	avatarUrl: '',
	roles: [],
	roleId: '',
	operations: [],
	organizationId: '',
	organizationType: OrganizationType.Linus,
	organizationStatus: OrganizationStatus.Live,
	organizationName: '',
	organizationDateFormat: '',
	organizationDefaultUserLocale: locale.EnUs,
	organizationDefaultTimezone: 'US/Eastern',
	organizationValueLists: [],
	organizationParticipantLanguages: [],
	organizationFeatures: [],
	organizationCdsConcerns: [],
	suffix: [],
	userStatus: UserStatus.Deactivated,
};

export const UserContext = createContext<UserContextProps>({
	currentUser: nulloUser,
	onLogin: () => undefined,
	logout: () => undefined,
	setCurrentUser: () => undefined,
	updateCurrentUser: () => undefined,
	clearImpersonation: () => undefined,
	refetchCurrentUser: () => undefined,
});
