import { GradeMarks } from '@lh/qnr-svc-rest-client';

import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';

export const MOCKED_RESULT_DATA: QnrRowData[] = [
	{
		question: '<b>1</b> - No difficulty either subjectively or objectively',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>1</b> - No difficulty either subjectively or objectively',
			value: 0,
		},
	},
	{
		question:
			'<b>2</b> - Complains of forgetting the location of objects—subjective word-finding difficulties',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>2</b> - Complains of forgetting the location of objects—subjective word-finding difficulties',
			value: 0,
		},
	},
	{
		question:
			'<b>3</b> - Decreased job functioning evident to co-workers. Difficulty in traveling to new locations. Decreased organizational capacity',
		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>3</b> - Decreased job functioning evident to co-workers. Difficulty in traveling to new locations. Decreased organizational capacity',
			value: 0,
		},
	},
	{
		question:
			'<b>4</b> - Decreased ability to perform complex tasks (e.g., planning dinner for guests, handling personal finances, difficulty marketing, etc.)',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>4</b> - Decreased ability to perform complex tasks (e.g., planning dinner for guests, handling personal finances, difficulty marketing, etc.)',
			value: 0,
		},
	},
	{
		question:
			'<b>5</b> - Requires assistance in choosing proper clothing to wear for the day, season, or occasion (e.g., a patient may wear the same clothing repeatedly unless supervised)',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>5</b> - Requires assistance in choosing proper clothing to wear for the day, season, or occasion (e.g., a patient may wear the same clothing repeatedly unless supervised)',
			value: 0,
		},
	},
	{
		question:
			'<b>6a</b> - Improperly putting on clothes without assistance or prompting (e.g., may put street clothes on overnight clothes, put shoes on wrong feet, or have difficulty buttoning clothing) occasionally or more frequently over the past weeks',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>6a</b> - Improperly putting on clothes without assistance or prompting (e.g., may put street clothes on overnight clothes, put shoes on wrong feet, or have difficulty buttoning clothing) occasionally or more frequently over the past weeks',
			value: '6a',
		},
	},
	{
		question:
			'<b>6b</b> - Unable to bathe properly (e.g., difficulty adjusting bathwater temp.) occasionally or more frequently over the past weeks',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>6b</b> - Unable to bathe properly (e.g., difficulty adjusting bathwater temp.) occasionally or more frequently over the past weeks',
			value: 0,
		},
	},
	{
		question:
			'<b>6c</b> - Inability to handle mechanics of toileting (e.g., forgets to flush the toilet, does not wipe properly or properly dispose of toilet tissue) occasionally or more frequently over the past weeks',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>6c</b> - Inability to handle mechanics of toileting (e.g., forgets to flush the toilet, does not wipe properly or properly dispose of toilet tissue) occasionally or more frequently over the past weeks',
			value: 0,
		},
	},
	{
		question:
			'<b>6d</b> - Urinary incontinence occasionally or more frequently over the past weeks',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>6d</b> - Urinary incontinence occasionally or more frequently over the past weeks',
			value: 0,
		},
	},
	{
		question:
			'<b>6e</b> - Fecal incontinence occasionally or more frequently over the past weeks',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>6e</b> - Fecal incontinence occasionally or more frequently over the past weeks',
			value: 0,
		},
	},
	{
		question:
			'<b>7a</b> - Ability to speak limited to approximately a half-dozen different intelligible words or fewer in an average day or the course of an intensive interview',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>7a</b> - Ability to speak limited to approximately a half-dozen different intelligible words or fewer in an average day or the course of an intensive interview',
			value: 0,
		},
	},
	{
		question:
			'<b>7b</b> - Speech ability is limited to using a single intelligible word on an average day or the course of an intensive interview (the person may repeat the word over and over)',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>7b</b> - Speech ability is limited to using a single intelligible word on an average day or the course of an intensive interview (the person may repeat the word over and over)',
			value: 0,
		},
	},
	{
		question:
			'<b>7c</b> - Ambulatory ability is lost (cannot walk without personal assistance)',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>7c</b> - Ambulatory ability is lost (cannot walk without personal assistance)',
			value: 0,
		},
	},
	{
		question: '<b>7d</b> - Cannot sit up without assistance',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText: '<b>7d</b> - Cannot sit up without assistance',
			value: 0,
		},
	},
	{
		question: '<b>7e</b> - Loss of ability to smile',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText: '<b>7e</b> - Loss of ability to smile',
			value: 0,
		},
	},
	{
		question: '<b>7f</b> - Loss of ability to hold head up independently',

		answers: {
			grade: GradeMarks.NOT_GRADED,
			answerText:
				'<b>7f</b> - Loss of ability to hold head up independently',
			value: 0,
		},
	},
];
