import {
	Patient,
	PatientApiAddPatientRelationshipRequest,
} from '@lh/eng-platform-subject-service-rest-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getPatientSubService } from 'api/getPatientSubService';
import { QueryKey } from 'api/query';

export async function addRelationship(
	searchInput: PatientApiAddPatientRelationshipRequest
) {
	if (!searchInput.organizationId) {
		throw new Error("useAddRelationship: organizationId cant' be null");
	}

	if (!searchInput.subjectId) {
		throw new Error("useAddRelationship: subjectId cant' be null");
	}

	if (!searchInput.relatedSubjectInput) {
		throw new Error(
			"useAddRelationship: relatedSubjectInput cant' be null"
		);
	}

	const service = await getPatientSubService();
	const { data } = await service.addPatientRelationship(searchInput);
	return data;
}

export function useAddRelationship() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error adding relationship',
		},
		mutationFn: addRelationship,
		onSuccess: async (updatedPatient) => {
			client.setQueryData<Patient>(
				[
					QueryKey.Patient,
					updatedPatient.id,
					updatedPatient.organizationId,
				],
				updatedPatient
			);
		},
	});
}
