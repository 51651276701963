import {
	DeepSegmentResult,
	MetricItem,
} from '@lh/eng-platform-battery-service-rest-client';

import { SegmentType } from 'generated/graphql';

import { Report } from '../CognitiveEvaluation.types';
import { TRAILS_COLORS, TrailsData } from './TrailsLayout.interface';

function getMetricItemsData(
	nodes: {
		__typename?: 'MetricItem';
		id: string;
		key: string;
		value: string;
		algorithmVersion: string;
	}[],
	key: string
) {
	return nodes.find((element) => {
		return element.key === key;
	})?.value;
}

export function parseTrailsData(
	data?: Report,
	segmentResult?: DeepSegmentResult
): TrailsData {
	let metricResults: DeepSegmentResult | undefined;
	let nodes: MetricItem[] = [];

	if (!data && segmentResult) {
		nodes = segmentResult.metricItems ?? [];
	}

	if (data && !segmentResult) {
		metricResults = data?.batteryResultById?.assessmentResults
			.map((element) => {
				return element.segmentResults.find(
					(element) =>
						element.segment.segmentType === SegmentType.TrailsV2B
				);
			})
			.filter(Boolean)[0];
		nodes = metricResults?.metricItems ?? [];
	}

	const duration = getMetricItemsData(nodes, 'trails_b_trails_duration');

	const ageRange = getMetricItemsData(nodes, 'age_range') ?? 'NotAvailable';

	const durationColor = getMetricItemsData(nodes, 'trails_b_duration_color');

	const greenDurationThreshold = getMetricItemsData(
		nodes,
		'trails_b_duration_green_threshold'
	);

	const redDurationThreshold = getMetricItemsData(
		nodes,
		'trails_b_duration_red_threshold'
	);

	const totalMistakes = getMetricItemsData(nodes, 'trails_b_total_mistakes');

	const totalMistakesColor =
		getMetricItemsData(nodes, 'trails_b_total_mistakes_color') ??
		'NotAvailable';

	const redThreshold =
		getMetricItemsData(nodes, 'trails_b_total_mistakes_red_threshold') ??
		'NotAvailable';

	const greenThreshold =
		getMetricItemsData(nodes, 'trails_b_total_mistakes_green_threshold') ??
		'NotAvailable';

	const yellowThreshold =
		getMetricItemsData(nodes, 'trails_b_total_mistakes_yellow_threshold') ??
		'NotAvailable';

	const maxTimeReached = getMetricItemsData(
		nodes,
		'trails_b_max_time_reached'
	);

	const errorsQuartile = getMetricItemsData(
		nodes,
		'trails_b_total_mistakes_quartile'
	);

	const durationQuartile = getMetricItemsData(
		nodes,
		'trails_b_duration_quartile'
	);

	const adjustedByEducation = getMetricItemsData(
		nodes,
		'trails_b_thresholds_adjusted_by_education'
	);

	return {
		adjustedByEducation: adjustedByEducation === 'true',
		ageRange: ageRange,
		duration: Number(duration) ? Number(duration) : 0,
		durationColor:
			TRAILS_COLORS[durationColor as keyof typeof TRAILS_COLORS],
		durationThreshold: {
			greenThreshold: greenDurationThreshold ?? 'NotAvailable',
			redThreshold: redDurationThreshold ?? 'NotAvailable',
		},
		totalMistakes: Number(totalMistakes ?? 0),
		totalMistakesColor:
			TRAILS_COLORS[totalMistakesColor as keyof typeof TRAILS_COLORS],
		thresholds: {
			green: greenThreshold,
			yellow: yellowThreshold,
			red: redThreshold,
		},
		maxTimeReached: maxTimeReached === 'False' ? false : true,
		image: '',
		durationQuartile: durationQuartile ?? 'NotAvailable',
		errorsQuartile: errorsQuartile ?? 'NotAvailable',
	};
}

export function removePathFromBase64(value: string) {
	const svgPath = /<path[^>]*\/>/g;

	const blob = atob(value).replaceAll(svgPath, '');
	return btoa(blob);
}
