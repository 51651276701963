import { Typography } from '@lh/eng-web-mosaic-common';

import { css, styled } from 'styled-components';

import { theme } from 'components/providers/StyleProvider/theme';

interface InfoContentProps {
	infoContent: InfoContent[];
}

type InfoContent = { subtitle: string; info?: string };

export function InfoContent({ infoContent }: Readonly<InfoContentProps>) {
	return (
		<ContentWrapper>
			{infoContent.map((element) => {
				return (
					<ContentContainer key={element.subtitle}>
						<Typography.C
							data-id={
								'content-container-subtitle-' +
								element.subtitle
									.trim()
									.toLowerCase()
									.replace(/\W/g, '-')
							}
							color={theme.color.bodyTextSecondary}
							weight='500'
						>
							{element.subtitle}
						</Typography.C>
						{element.info && (
							<Typography.P1
								weight='500'
								data-id={
									'content-container-info-' +
									element.subtitle
										.trim()
										.toLowerCase()
										.replace(/\W/g, '-')
								}
							>
								{element.info}
							</Typography.P1>
						)}
					</ContentContainer>
				);
			})}
		</ContentWrapper>
	);
}

const ContentWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

const ContentContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.xs};
	`
);
