import styled, { css } from 'styled-components';

import { Caption, H4 } from 'components/shared/designSystem';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		margin-bottom: ${spacing.sm};
		gap: ${spacing.lg};
	`
);

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const Header = styled(H4)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		margin-left: ${spacing.lg};
	`
);

export const Grid = styled.section(
	({ theme: { spacing, breakpoints } }) => css`
		display: grid;
		gap: ${spacing.xl};
		grid-template-columns: 1fr 1fr;
		margin-bottom: ${spacing.lg};
		width: 100%;

		${breakpoints.tabletDown} {
			grid-template-columns: 1fr;
		}
	`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
