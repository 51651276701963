import { createRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { CDSBackToFullReportLink } from '../CDSFullArticle/CDSBackToFullReportLink';

type CDSArticleProps = {
	articleSrc: string;
};

const CDSArticle = ({ articleSrc }: CDSArticleProps): JSX.Element | null => {
	const params = useParams();
	const containerRef = createRef<HTMLDivElement>();

	useEffect(() => {
		// Scroll to top on load
		containerRef.current?.parentElement?.parentElement?.scrollTo(0, 0);
	}, []);

	const { participantId, reportId } = params;
	if (!participantId || !reportId) return null;
	return (
		<div>
			<StyledFullArticleContainer ref={containerRef}>
				<StyledHeader>
					<CDSBackToFullReportLink
						participantId={participantId}
						reportId={reportId}
					/>
				</StyledHeader>
				<StyledSeparator />
				<StyledIFrame>
					<iframe
						width={'100%'}
						height={'875px'}
						title={'iframe'}
						frameBorder='0'
						src={articleSrc}
					/>
				</StyledIFrame>
			</StyledFullArticleContainer>
		</div>
	);
};

const StyledFullArticleContainer = styled.div(
	({ theme: { spacing, color } }) => `
	height: 950px;
    padding: ${spacing.xl};
    gap: ${spacing.md};
    background: ${color.white};
    border-radius: 20px;
  	overflow-y: scroll;
`
);

const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 15px;
`;

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	width: 100%;
	border-bottom: 1px solid ${color.infoSeparator};
    margin-bottom: 275px;
`
);

const StyledIFrame = styled.div`
	width: 50%;
	height: 100%;
	display: block;
	margin: auto;
	-webkit-transform: scale(1.6);
	-moz-transform: scale(1.6);
	-o-transform: scale(1.6);
	-ms-transform: scale(1.6);
`;

export { CDSArticle };
