import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { addBatteryModel } from 'components/shared/Forms/Schemas/addBatterySchema';
import { Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { DropdownOption } from '../../../types';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import {
	CONTACT_PREFERENCES,
	LOCATION_BATTERY_TYPE,
	LocationBatteryType,
	contactPreferences,
} from '../patient.helpers';

import {
	ContactInformationCaption,
	ContactInformationContainer,
	ContactInformationWrapper,
	Container,
	EditButton,
	Header,
	HeaderContainer,
	Info,
	RemoteDetailsContainer,
	Row,
	Subheader,
} from './AssessmentConfiguration.styles';

type AssesmentConfigurationProps = {
	type: LocationBatteryType;
	batteriesOptions: DropdownOption[];
	values: addBatteryModel;
	remoteBatteriesOptions: DropdownOption[];
	hasInitialValue: boolean;
};

type RemoteDetailsProps = {
	batteriesOptions: DropdownOption[];
	remoteBatteriesOptions: DropdownOption[];
	values: addBatteryModel;
	hasInitialValues: boolean;
};

type ContactInformationProps = {
	values: addBatteryModel;
	hasInitialValues: boolean;
};

export function AssessmentConfiguration({
	batteriesOptions,
	hasInitialValue,
	remoteBatteriesOptions,
	type,
	values,
}: Readonly<AssesmentConfigurationProps>) {
	const { t } = useTranslation();

	return (
		<Container>
			<Header>
				{t('web.patients.forms.remoteAssessment.configuration.title')}
			</Header>
			{type === LOCATION_BATTERY_TYPE.IN_CLINIC ? (
				<LinusInput
					name='battery'
					type='select'
					label={t`web.shared.battery`}
					dropUpSpace='8px'
					dropdownOptions={batteriesOptions}
					width='360px'
					styles={{
						marginTop: 12,
						marginBottom: 0,
					}}
				/>
			) : (
				<RemoteDetails
					batteriesOptions={batteriesOptions}
					remoteBatteriesOptions={remoteBatteriesOptions}
					hasInitialValues={hasInitialValue}
					values={values}
				/>
			)}
		</Container>
	);
}

function RemoteDetails({
	hasInitialValues,
	remoteBatteriesOptions,
	values,
}: Readonly<RemoteDetailsProps>) {
	const { t } = useTranslation();

	return (
		<RemoteDetailsContainer data-testid='remote-details'>
			<Row>
				<LinusInput
					name='remoteBattery'
					type='select'
					label={t`web.shared.battery`}
					dropUpSpace='8px'
					dropdownOptions={remoteBatteriesOptions}
					width='360px'
					styles={{
						marginTop: 12,
						marginBottom: values.contactMethod ? 24 : 0,
					}}
				/>
				<LinusInput
					name='contactMethod'
					type='select'
					dropUpSpace='8px'
					dropdownOptions={contactPreferences}
					label={t(
						'web.patients.forms.remoteAssessment.configuration.contactMethod.label'
					)}
					width='360px'
					styles={{
						marginTop: 12,
						marginBottom: values.contactMethod ? 24 : 0,
					}}
				/>
			</Row>
			{values.contactMethod && (
				<ContactInformation
					values={values}
					hasInitialValues={hasInitialValues}
				/>
			)}
		</RemoteDetailsContainer>
	);
}

function ContactInformation({
	values,
	hasInitialValues,
}: Readonly<ContactInformationProps>) {
	const { t } = useTranslation();

	const [editMode, setEditMode] = useState(!hasInitialValues);

	function handleEditMode() {
		setEditMode((s) => !s);
	}

	const showEmail =
		values.contactMethod === CONTACT_PREFERENCES.EMAIL_ONLY ||
		values.contactMethod === CONTACT_PREFERENCES.EMAIL_AND_PHONE;
	const showPhone =
		values.contactMethod === CONTACT_PREFERENCES.PHONE_ONLY ||
		values.contactMethod === CONTACT_PREFERENCES.EMAIL_AND_PHONE;

	return (
		<ContactInformationContainer
			data-testid='remote-contact-information'
			isEdit={editMode}
		>
			<HeaderContainer>
				<Header>
					{t(
						'web.patients.forms.remoteAssessment.configuration.patientInfo.title'
					)}
				</Header>
				{!editMode && (
					<EditButton
						data-testid='editButton'
						onClick={handleEditMode}
					>
						{'Edit'}
						<Icon icon={icons.PencilFilled} />
					</EditButton>
				)}
			</HeaderContainer>
			{editMode && (
				<Subheader>
					{t(
						'web.patients.forms.remoteAssessment.configuration.patientInfo.description'
					)}
				</Subheader>
			)}
			{!editMode && (
				<Info>
					{showEmail && (
						<ContactInformationWrapper>
							<ContactInformationCaption>
								{t(
									'web.patients.forms.remoteAssessment.configuration.email'
								)}
							</ContactInformationCaption>
							<P1>{values.contactEmail}</P1>
						</ContactInformationWrapper>
					)}
					{showPhone && (
						<ContactInformationWrapper
							style={{ marginLeft: showEmail ? 24 : 0 }}
						>
							<ContactInformationCaption>
								{t(
									'web.patients.forms.remoteAssessment.configuration.phoneNumber'
								)}
							</ContactInformationCaption>
							<P1>{values.contactPhone}</P1>
						</ContactInformationWrapper>
					)}
				</Info>
			)}
			{editMode && (
				<Row>
					{showEmail && (
						<LinusInput
							width='360px'
							name='contactEmail'
							label={t(
								'web.patients.forms.remoteAssessment.configuration.email'
							)}
							styles={{
								marginTop: 12,
								marginBottom: 0,
							}}
						/>
					)}
					{showPhone && (
						<LinusInput
							name='contactPhone'
							label={t(
								'web.patients.forms.remoteAssessment.configuration.phoneNumber'
							)}
							type='contactPhone'
							width='360px'
							styles={{
								marginTop: 12,
								marginBottom: 0,
							}}
						/>
					)}
				</Row>
			)}
		</ContactInformationContainer>
	);
}
