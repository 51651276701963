import { EnumItem } from '../enums/enumeration';

export const validateParticipantLanguages = (items: EnumItem[]): boolean => {
	// Filter out all participantLang items that have been chosen, split on locale (via |)
	// then smash into a set and check lengths; if diff lengths, then dupes exist
	const checkDups: {
		[key: string]: number;
	} = {};
	for (let i = 0; i < items.length; i++) {
		const value = items[i].key.split('|')[0];
		if (checkDups[value]) {
			return false;
		} else {
			checkDups[value] = 1;
		}
	}

	return true;
};

/**
 *
 * @param dateValue A value to validate as a valid date value.
 * @returns Whether a data is valid or not.
 */
export const validateDate = (dateValue: any) => {
	return dateValue instanceof Date && !isNaN(dateValue.valueOf());
};
