import styled, { css } from 'styled-components';

import { H2, H4 } from 'components/shared/designSystem/TextComponents';
import { StyledCard } from 'components/shared/StyledCard';

export const VerticalPaddedStyledCard = styled(StyledCard)(
	({ theme: { spacing } }) =>
		css`
			padding: ${spacing.lg} 0;
		`
);

export const StyledSummaryTitle = styled(H4)(
	({ theme: { spacing, weight } }) =>
		css`
			display: flex;
			flex-direction: row;
			gap: ${spacing.sm};
			align-items: center;
			padding: 0 ${spacing.lg};
			font-weight: ${weight.medium};
		`
);

export const Tooltip = styled.div(
	() =>
		css`
			width: 314px;
		`
);

export const StyledTitleDivider = styled.div(
	({ theme: { color, spacing } }) =>
		css`
			height: 1px;
			background-color: ${color.infoSeparator};
			margin: ${spacing.md} 0;
		`
);

export const StyledReportSummary = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${spacing.lg};
		padding: 0 ${spacing.lg};
		flex-wrap: wrap;
	`
);

export const StyledScoreCard = styled.div<{ bgColor: string }>(
	({ bgColor, theme: { borderRadius, spacing } }) =>
		css`
			display: flex;
			flex-direction: row;
			align-items: center;
			border-radius: ${borderRadius.cdsCard};
			gap: ${spacing.sm};
			background-color: ${bgColor}33;
			padding: ${spacing.md};
			width: 380px;
		`
);

export const StyledScorePanel = styled.div<{ bgColor: string }>(
	({ bgColor, theme: { borderRadius, spacing } }) =>
		css`
			background: ${bgColor};
			border-radius: ${borderRadius.cdsCard};
			padding: ${spacing.xs} ${spacing.sm};
		`
);

export const StyledLegendContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
		padding: 0 ${spacing.md};
		flex-grow: 1;
	`
);

export const StyledLegendRowDivider = styled.div(
	({ theme: { color } }) =>
		css`
			height: 1px;
			background-color: ${color.infoSeparator};
		`
);

export const StyledScoringDetailsContainer = styled.div(
	() =>
		css`
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		`
);

export const H2Med = styled(H2)(
	({ theme: { weight } }) => `
    font-weight: ${weight.medium};
`
);
