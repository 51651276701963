import { OptionalFields } from '@lh/eng-platform-organization-service-rest-client';
import {
	Gender,
	Handedness,
	Patient,
	PatientApiCreatePatientRequest,
	PatientApiUpdatePatientRequest,
	SexAssignedAtBirth,
	UpdatePatientInput,
} from '@lh/eng-platform-subject-service-rest-client';

import { AxiosResponse } from 'axios';

import { Input } from 'components/patients/PatientForm.types';
import { t } from 'i18n';
import { Enumeration, VLEnum } from '../../enums/enumeration';
import { DropdownOption } from '../../types';

import { AddPatientModel } from './AddPatientForm';
import { getContactMethod } from './patient.helpers';

export const buildLanguageDisplayOptions = (
	valueListLanguageOptions: Enumeration<VLEnum>
): DropdownOption[] => {
	let orderedOptions = valueListLanguageOptions.toOptions();
	const atTimeOfTestingElement = orderedOptions.find(
		(e) => e.value === 'TO_BE_COLLECTED_AT_TIME_OF_TESTING'
	);

	if (atTimeOfTestingElement) {
		orderedOptions = [
			...orderedOptions.filter((e) => e !== atTimeOfTestingElement),
			atTimeOfTestingElement,
		];
	}

	return orderedOptions.map((option) => {
		return {
			value: option.value,
			display: t(option.display),
		};
	});
};

export async function createDynamicInputs(
	inputs: Input[],
	optionalFields: OptionalFields[] | undefined
) {
	let dynamicInputs: Input[] = [];

	if (optionalFields) {
		dynamicInputs = inputs.filter(
			(item) =>
				item.isRequired ||
				(item.fieldName && optionalFields.includes(item.fieldName))
		);
	}

	return dynamicInputs;
}

export async function createPatient(
	organizationId: string,
	values: AddPatientModel,
	createPatient: (
		patientInput: PatientApiCreatePatientRequest
	) => Promise<Patient>
) {
	const utcDate = new Date(values.birthDate);
	// WEB-2434
	utcDate.setUTCHours(0, 0, 0, 0);

	const createResultResponse = await createPatient({
		organizationId,
		createPatientInput: {
			...values,
			gender:
				values.gender?.length === 0
					? undefined
					: (values.gender as Gender),
			handedness: values.handedness as Handedness,
			birthDate: utcDate.toISOString(),
			sexAssignedAtBirth: values.sexAssignedAtBirth as SexAssignedAtBirth,
			ethnicityIds: values.ethnicityIds
				? values.ethnicityIds.split(/, ?/)
				: [],
			raceIds: values.raceIds ? values.raceIds.split(/, ?/) : [],
			educationIds: [values.educationIds],
			externalId: values.externalId ?? undefined,
			contactPreference: getContactMethod(
				values.contactEmail,
				values.contactPhone
			),
			contactEmail: values.contactEmail ?? null,
			contactPhone: values.contactPhone ?? null,
		},
	});

	return createResultResponse;
}

export async function updatePatient(
	organizationId: string,
	participantId: string,
	values: AddPatientModel,
	updatePatient: (
		searchInput: PatientApiUpdatePatientRequest
	) => Promise<AxiosResponse<Patient, any> | undefined>
) {
	const updatePatientInput: UpdatePatientInput = {
		language: values.language,
		firstName: values.firstName,
		lastName: values.lastName,
		handedness: values.handedness as Handedness,
		gender: (values.gender as Gender) ?? undefined,
		externalId: values.externalId ?? undefined,
		birthDate: values.birthDate,
		sexAssignedAtBirth: values.sexAssignedAtBirth as SexAssignedAtBirth,
		educationIds: [values.educationIds],
		ethnicityIds: values.ethnicityIds
			? values.ethnicityIds.split(/, ?/)
			: [],
		raceIds: values.raceIds ? values.raceIds.split(/, ?/) : [],
		contactPreference: getContactMethod(
			values.contactEmail,
			values.contactPhone
		),
		contactEmail: values.contactEmail ? values.contactEmail : undefined,
		contactPhone: values.contactPhone
			? values.contactPhone.replace(/-/g, '')
			: undefined,
	};

	const updatePatientResponse = await updatePatient({
		organizationId,
		subjectId: participantId,
		updatePatientInput,
	});

	return updatePatientResponse;
}
