import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import styled, { css } from 'styled-components';

import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';

import { AnalyticsAction, sendDebouncedEventData } from 'analytics';
import { EnumItem } from '../../enums/enumeration';
import { SortOrder } from '../../types';

import { DataTable, TableColumn } from './DataTable/DataTable';
import { DataTableFooter } from './DataTable/DataTableFooter';
import { DataTableHeader } from './DataTable/DataTableHeader';
import { DataTableSearchBar } from './DataTable/DataTableSearchBar';
import { pageItemGenerator } from './DataTable/pager/pageItemGenerator';
import { StyledCard } from './StyledCard';

export const HALF_SECOND_DELAY_MILLIS = 500;

export type LinusDataTableProps<T> = {
	title: string;
	titleIcon?: EnumItem;
	columns: TableColumn<T>[];
	tableData: T[];
	buttonText?: string;
	dataId?: string;
	onHeaderButtonClick?: (event: React.MouseEvent) => void;
	operations?: OperationToken[];
	buttonIcon?: EnumItem;
	rowsPerPage?: number;
	maxPage?: number;
	hideFooter?: boolean;
	headerColor?: string;
	deviantWidth?: string;
	longText?: string;
	noDataIcon?: EnumItem;
	emptyTableComponent?: () => React.ReactNode;
	searchBarPlaceholder?: string;
	notFoundTitle?: string;
	notFoundSubtitle?: string;
	filterFunction?: (filter: string, candidate: T) => boolean;
};

const LinusDataTable = <T,>({
	title,
	titleIcon,
	columns,
	tableData,
	buttonText,
	dataId,
	onHeaderButtonClick,
	operations,
	buttonIcon,
	rowsPerPage = 5,
	maxPage = 5,
	hideFooter,
	headerColor,
	deviantWidth,
	longText,
	noDataIcon,
	searchBarPlaceholder,
	notFoundTitle,
	notFoundSubtitle,
	filterFunction,
}: LinusDataTableProps<T>): JSX.Element => {
	const [currentPage, setCurrentPage] = useState(1);
	const [sortOrder, setSortOrder] = useState<SortOrder>();
	const [currentPageItems, setCurrentPageItems] = useState<T[]>([]);
	const [currentTableData, setCurrentTableData] = useState<T[]>(tableData);
	const [isFilteringData, setIsFilteringData] = useState(false);
	const [totalRows, setTotalRows] = useState(tableData.length);
	const [tableHasData, setTableHasData] = useState(tableData.length > 0);
	const [returnToDefault, setReturnToDefault] = useState(false);

	const pagerInfo = {
		totalRows,
		setTotalRows,
		currentPage,
		setCurrentPage,
		sortOrder,
		setSortOrder,
		rowsPerPage,
		maxPage,
	};

	useEffect(() => {
		if (tableData) {
			if (tableData?.length && tableData.length !== totalRows) {
				setReturnToDefault(true);
				pagerInfo.setSortOrder(undefined);
			}

			setCurrentTableData(tableData);
			setTotalRows(tableData?.length);
			setTableHasData(tableData.length > 0);
		}
	}, [tableData, setCurrentTableData]);

	useEffect(() => {
		if (currentTableData) {
			setCurrentPageItems(pageItemGenerator(currentTableData, pagerInfo));
		}
	}, [
		currentTableData,
		pagerInfo.currentPage,
		pagerInfo.sortOrder,
		setCurrentPageItems,
		pageItemGenerator,
	]);

	useEffect(() => {
		if (returnToDefault) {
			setReturnToDefault(false);
		}
	}, [returnToDefault, setReturnToDefault]);

	const handleSearchChange = (items: T[]) => {
		setCurrentTableData(items);
		// If items is equal to total tableData length, search has been cleared
		items.length !== tableData.length &&
			sendDebouncedEventData({
				eventType: AnalyticsAction.SearchingTable,
				eventProperties: {
					hasResults: !!items.length,
				},
			});
		if (items) {
			setCurrentPage(1);
			setTotalRows(items.length);
			setCurrentPageItems(pageItemGenerator(items, pagerInfo));
			setTableHasData(items.length > 0);
		}
	};

	return (
		<StyledContainer data-id={title + ' Data Table'}>
			<DataTableHeader
				title={title}
				titleIcon={titleIcon}
				buttonText={buttonText}
				dataId={dataId}
				onHeaderButtonClick={onHeaderButtonClick}
				operations={operations}
				buttonIcon={buttonIcon}
				headerColor={headerColor}
			/>
			{filterFunction ? (
				<DataTableSearchBar
					placeholder={searchBarPlaceholder}
					searchableList={tableData}
					filterFunction={filterFunction}
					onChange={debounce(
						handleSearchChange,
						HALF_SECOND_DELAY_MILLIS
					)}
					setIsFilteringData={setIsFilteringData}
				/>
			) : (
				<></>
			)}
			<DataTable
				columns={columns}
				tableData={currentPageItems}
				onSort={pagerInfo.setSortOrder}
				fallbackText={title}
				longText={longText}
				deviantWidth={deviantWidth}
				noDataIcon={noDataIcon}
				isFilteringData={isFilteringData}
				notFoundTitle={notFoundTitle}
				notFoundSubtitle={notFoundSubtitle}
				returnToDefault={returnToDefault}
			/>
			{tableHasData && !hideFooter && (
				<DataTableFooter pagerInfo={pagerInfo} />
			)}
		</StyledContainer>
	);
};

export { LinusDataTable };

const StyledContainer = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		box-sizing: border-box;
	`
);
