import { Flex } from '@mantine/core';

import { ScoreInterpretation, ScoreTag } from 'components/mosaic';

import { ScoreType } from '../../types';

type InterpretedScoreProps = {
	interpretationDescription: string;
	interpretationCaption?: string | null;
	scoreLabel: string;
	scoreType: ScoreType;
	scoreValue: string;
};

export function InterpretedScore({
	interpretationDescription,
	interpretationCaption,
	scoreLabel,
	scoreType,
	scoreValue,
}: Readonly<InterpretedScoreProps>) {
	return (
		<Flex>
			<ScoreTag
				composed
				label={scoreLabel}
				value={scoreValue}
				type={scoreType}
			/>
			<ScoreInterpretation
				description={interpretationDescription}
				caption={interpretationCaption}
				type={scoreType}
			/>
		</Flex>
	);
}
