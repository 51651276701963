const articles =
	process.env.JEST_WORKER_ID === undefined
		? require.context('../../../../../public/cdsArticles')
		: null;

import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from 'components/context';
import { FileNotFound } from 'components/FileNotFound';
import { LoadingDots } from 'components/shared/LoadingDots';
import { useGetReport } from 'components/shared/hooks/useGetReport';
import { CDSArticle } from '../CDSArticle/CDSArticle';
import { Breadcrumbs } from '../../../shared/breadcrumbs/Breadcrumbs';
import { Header } from '../../../shared/Header';

import { MOCKED_ARTICLES } from './CDSArticlePage.fixtures';
import { gatherArticles } from './CDSArticlePage.logic';

interface Props {
	isTest?: boolean;
}

export function CDSArticlePage({ isTest = false }: Readonly<Props>) {
	const { currentUser } = useContext(UserContext);
	const { participantId, reportId, contentId } = useParams() as {
		participantId: string;
		reportId: string;
		contentId: string;
	};

	const gatheredArticles = articles
		? gatherArticles(articles.keys())
		: MOCKED_ARTICLES;
	const availableArticles = isTest ? MOCKED_ARTICLES : gatheredArticles;
	const articleSrc =
		availableArticles[(contentId || '') as keyof typeof availableArticles];

	const {
		data: { battery: batteryResult, participant: batteryParticipant },
		loading: { loadingBattery: loadingBatteryResult, loadingParticipant },
	} = useGetReport({
		batteryResultId: reportId,
		orgId: currentUser.organizationId,
		participantId,
	});

	if (
		loadingBatteryResult ||
		loadingParticipant ||
		!batteryResult ||
		!batteryParticipant
	) {
		return <LoadingDots />;
	}

	if (!articleSrc || !batteryResult.batteryResultById) {
		return <FileNotFound />;
	}

	return (
		<>
			<Header />
			<Breadcrumbs
				patient={{
					firstName: batteryParticipant.firstName,
					lastName: batteryParticipant.lastName,
				}}
				batteryType={
					batteryResult.batteryResultById.battery.batteryType
				}
			/>
			<CDSArticle articleSrc={articleSrc} />
		</>
	);
}
