import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { t } from 'i18n';

import { AnalyticsAction, sendEventData } from 'analytics';
import { theme } from 'components/providers/StyleProvider/theme';
import { Caption } from 'components/shared/designSystem/TextComponents';
import { PathwayType } from 'generated/graphql';

import { MedicationListModal } from '../Modals';
import { Table } from '../Pathway.table';
import { PathwayID } from '../Pathway.types';

import { getReversibleCause } from './ReversibleCause';
import { reversibleCausesMap } from './ReversibleCauses.data';
import { ReversibleCause } from './ReversibleCauses.types';

interface Props {
	pathwayId: PathwayID | PathwayType;
}

export function ReversibleCauses({ pathwayId }: Props) {
	const [showMedicationList, medicationList] = useDisclosure(false);

	const columns = [
		t('cds.reversibleCauses.dementias'),
		t('cds.reversibleCauses.nextSteps'),
	];
	const data = Object.values(reversibleCausesMap);

	function handleMedicationListClick() {
		sendEventData({
			eventType: AnalyticsAction.ClickedMeds,
		});

		medicationList.open();
	}

	return (
		<>
			<Table
				columns={columns}
				data={data}
				renderColumn={(column) => (
					<Column key={column}>{column}</Column>
				)}
				renderRow={(row) => (
					<Row
						pathwayId={pathwayId}
						key={row.id}
						reversibleCause={row}
						onClick={{ medicationList: handleMedicationListClick }}
					/>
				)}
				withRowBorders
			/>

			{showMedicationList && (
				<MedicationListModal onClose={medicationList.close} />
			)}
		</>
	);
}

function Column({ children }: { children: React.ReactNode }) {
	return (
		<Table.Column>
			<Box py={6} data-testid='reversible-causes-column'>
				<Caption style={{ fontWeight: theme.weight.medium }}>
					{children}
				</Caption>
			</Box>
		</Table.Column>
	);
}

function Row({
	reversibleCause,
	pathwayId,
	onClick,
}: {
	reversibleCause: ReversibleCause;
	pathwayId: PathwayID | PathwayType;
	onClick: {
		medicationList(): void;
	};
}) {
	const { Summary, NextSteps } = getReversibleCause(
		reversibleCause,
		pathwayId
	);

	return (
		<Table.Row>
			<Table.Cell width={350}>
				<Summary />
			</Table.Cell>
			<Table.Cell>
				<NextSteps onClick={onClick} />
			</Table.Cell>
		</Table.Row>
	);
}
