import semver from 'semver';

import { SegmentType } from '../../../../../../generated/graphql';
import { FormattedSegmentResults } from '../../../../cognitiveEvalParseData/cognitiveEvalParseData';
import { Metric } from '../../../../segmentUtilities/metrics';

export function getTitleKey(score: Metric): string {
	const scoreValue = Number(score.value) || 0;

	let key = '';

	if (inRange(scoreValue, 0, 1)) {
		key = `cognitivelyImpaired`;
	}
	if (inRange(scoreValue, 2, 3)) {
		key = `likelyCognitivelyUnimpaired`;
	}
	if (inRange(scoreValue, 4, 5)) {
		key = `cognitivelyUnimpaired`;
	}

	function inRange(value: number, from: number, to: number): boolean {
		return value >= from && value <= to;
	}

	const rootKey = `web.dcrReport.overview.scoringDescription`;
	const contentVersion = getContentVersion(score.version);

	return `${rootKey}.${key}.text_${contentVersion}`;
}

function getContentVersion(version: string): string {
	if (semver.valid(version) && semver.lt(version, '1.3.1')) {
		return '1.0.0';
	}

	return '1.1.0';
}

export function getDCRScoreCounts(input: {
	score: Metric;
	miniModalSubscoreSegments: FormattedSegmentResults[];
}) {
	const { score, miniModalSubscoreSegments } = input;

	const delayedRecallData = miniModalSubscoreSegments.find(
		(subscoreSegment) =>
			subscoreSegment?.segmentType === SegmentType.RecallDelayed
	);

	return {
		dctClock: {
			/**
			 * Substracting delayed recall score from score value to get the DCTClock score (N/2)
			 */
			correct:
				Number(score.value || 0) -
				Number(
					delayedRecallData?.metricItems?.correct_words_count
						?.value || 0
				),
			expected: 2,
		},
		delayedRecall: {
			correct:
				delayedRecallData?.metricItems?.correct_words_count?.value || 0,
			expected:
				delayedRecallData?.metricItems?.expected_words_count?.value ||
				0,
		},
	};
}
