import { CookieStorage, Storage } from '@lh/eng-shared-auth';
import { config } from '../../config';

const TokenStorage = new CookieStorage(config.host, config.nodeEnv);

export class AuthStorage extends Storage {
	constructor() {
		super();
	}

	async get<D>(key: string) {
		return TokenStorage.get<D>(key);
	}

	async set<D>(key: string, value: D) {
		return TokenStorage.set(key, JSON.stringify(value));
	}

	async remove(key: string | string[]) {
		return TokenStorage.remove(key);
	}

	async allKeys(): Promise<string[]> {
		throw new Error('Method not implemented.');
	}
}
