import {
	Assessment,
	DeepAssessmentResult,
	DeepSegmentResult,
} from '@lh/eng-platform-battery-service-rest-client';

import {
	AssessmentType,
	BatteryHeaderFragment,
	BatteryType,
	SegmentType,
} from '../../../generated/graphql';
import { dateFormatter } from '../../../stringStrategy/dateFormatStringStrategy';
import {
	MetricHash,
	createMapFromMetricsArray,
} from '../segmentUtilities/metrics';

import { Report } from '../CognitiveEvaluation.types';

export type FormattedSegmentResults = {
	id: string;
	rawDataUrl?: string;
	name: string;
	segmentType: string;
	metricItems: MetricHash;
};

export type FormattedAssessmentResults = {
	assessment?: Partial<Assessment>;
	metricItems: MetricHash;
	segmentResults: FormattedSegmentResults[];
	features?: {
		clockShortPhrases?: boolean;
	};
};

interface AssessmentDataProps {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
}

interface AssessmentResultsProps {
	assessmentResult?: DeepAssessmentResult;
	segmentResult?: DeepSegmentResult;
}

const formatAssessmentResults = ({
	assessmentResult,
	segmentResult,
}: AssessmentResultsProps): FormattedAssessmentResults | undefined => {
	let assessmentMetrics: MetricHash = {};
	let updatedSegmentResults: FormattedSegmentResults[] = [];

	if (segmentResult && !assessmentResult) {
		assessmentMetrics = segmentResult.metricItems
			? createMapFromMetricsArray(segmentResult.metricItems)
			: {};
		updatedSegmentResults = [
			{
				id: segmentResult.id,
				rawDataUrl: segmentResult.rawDataUrl,
				name: segmentResult.segment.name,
				segmentType: segmentResult.segment.segmentType,
				metricItems: segmentResult.metricItems
					? createMapFromMetricsArray(segmentResult.metricItems)
					: {},
			},
		];

		return {
			metricItems: assessmentMetrics,
			segmentResults: updatedSegmentResults,
		};
	}

	if (!segmentResult && assessmentResult) {
		assessmentMetrics = assessmentResult?.metricItems
			? createMapFromMetricsArray(assessmentResult.metricItems)
			: {};
		updatedSegmentResults = assessmentResult?.segmentResults
			? assessmentResult.segmentResults.map((segmentResult) => {
					/* eslint-disable no-mixed-spaces-and-tabs */
					return {
						id: segmentResult.id,
						rawDataUrl: segmentResult.rawDataUrl,
						name: segmentResult.segment.name,
						segmentType: segmentResult.segment.segmentType,
						metricItems: segmentResult.metricItems
							? createMapFromMetricsArray(
									segmentResult.metricItems
							  )
							: {},
					};
			  })
			: [];
		return {
			assessment: assessmentResult?.assessment,
			metricItems: assessmentMetrics,
			segmentResults: updatedSegmentResults,
		};
	}
};

const getMiniModalAssessmentData = ({
	batteryResult,
	segmentResult,
}: AssessmentDataProps): FormattedAssessmentResults | undefined => {
	const assessmentResult =
		batteryResult?.batteryResultById?.assessmentResults?.find(
			(assessmentResult) =>
				assessmentResult.assessment?.assessmentType ===
					AssessmentType.Dcr ||
				assessmentResult.assessment?.assessmentType ===
					AssessmentType.DcrPlus
		);

	const assessmentResults = assessmentResult
		? formatAssessmentResults({
				assessmentResult,
		  })
		: formatAssessmentResults({
				segmentResult,
		  });

	if (assessmentResults) return assessmentResults;
};

const getLifeAndHealthAssessmentData = ({
	batteryResult,
	segmentResult,
}: AssessmentDataProps): FormattedAssessmentResults | undefined => {
	// If we ever get more than one LHQ32, this will become a problem again
	const assessmentResult =
		batteryResult?.batteryResultById?.assessmentResults?.find((ar) => {
			return ar.segmentResults.some(
				(sr) =>
					sr.segment.segmentType === SegmentType.Lhq32 ||
					sr.segment.segmentType === SegmentType.Lhq15
			);
		});

	const assessmentResults = assessmentResult
		? formatAssessmentResults({
				assessmentResult,
		  })
		: formatAssessmentResults({
				segmentResult,
		  });

	if (assessmentResults) return assessmentResults;
};

export const getAssessmentData = ({
	batteryResult,
	segmentResult,
}: AssessmentDataProps): {
	miniModalAssessment: FormattedAssessmentResults | undefined;
	lifeAndHealthAssessment: FormattedAssessmentResults | undefined;
} => {
	const miniModalAssessment = getMiniModalAssessmentData({
		batteryResult,
		segmentResult,
	});
	const lifeAndHealthAssessment = getLifeAndHealthAssessmentData({
		batteryResult,
		segmentResult,
	});
	return { miniModalAssessment, lifeAndHealthAssessment };
};

export const getCognitiveEvalDocumentTitle = ({
	batteryResult,
	batteryParticipant,
	reportType,
	dateFormat,
	defaultTimezone,
	batteryType,
}: {
	batteryResult?: Report;
	batteryParticipant?: BatteryHeaderFragment;
	dateFormat: string;
	defaultTimezone: string;
	reportType: 'ProviderReport' | 'PatientReport';
	batteryType: string;
}): string => {
	const patientName = `${batteryParticipant?.firstName
		?.charAt(0)
		.toUpperCase()}${batteryParticipant?.lastName
		?.charAt(0)
		.toUpperCase()}${batteryParticipant?.lastName?.slice(1)}`;
	const dateTaken = dateFormatter(
		batteryResult?.batteryResultById?.endTime ?? '',
		dateFormat,
		defaultTimezone
	);

	if (reportType === 'ProviderReport') {
		// TODO: Replace it with translations key
		return `CognitiveEvaluation-ProviderReport-${patientName}-${dateTaken}`;
	}
	if (batteryType === BatteryType.Dcr) {
		// TODO: Replace it with translations key
		return `DigitalClockAndRecall-Results-${patientName}-${dateTaken}`;
	}
	// TODO: Replace it with translations key
	return `CognitiveEvaluation-ResultsAndActionPlan-${patientName}-${dateTaken}`;
};
