import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { DelayedRecognitionLayout } from './DelayedRecognitionLayout';
import { useDelayedRecognitionData } from './useDelayedRecognitionData';

type DelayedRecognitionReportProps = {
	segmentResult: DeepSegmentResult;
};

export const DelayedRecognitionReport = ({
	segmentResult,
}: Readonly<DelayedRecognitionReportProps>) => {
	const { answers, performance } = useDelayedRecognitionData(segmentResult);

	return (
		<DelayedRecognitionLayout answers={answers} performance={performance} />
	);
};
