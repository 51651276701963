import { Box, Center, Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { LinusModal } from 'components/shared/LinusModal';
import { ButtonSm, H3, P1 } from 'components/shared/designSystem';

import { List, renderTags } from '../../Pathway.misc';
import { data } from './MedicationListModal.data';

interface Props {
	onClose(): void;
}

export function MedicationListModal({ onClose }: Props) {
	const { t } = useTranslation();

	return (
		<LinusModal width='760px' onClose={onClose}>
			<Stack gap={16} w={760} data-testid='medication-list-modal'>
				<Title>{data.title}</Title>
				<Content>
					{data.sections.map((section) => (
						<Section
							key={section.title}
							title={section.title}
							content={section.content}
						/>
					))}
					<References
						title={data.references.title}
						content={data.references.content}
					/>
				</Content>
				<Center>
					<ButtonSm
						text={t('cds.medicationListModal.close')}
						width='158px'
						onClick={onClose}
					/>
				</Center>
			</Stack>
		</LinusModal>
	);
}

function Title({ children }: { children: React.ReactNode }) {
	return (
		<H3
			style={{
				color: theme.colors.gray_10,
				fontWeight: theme.weight.medium,
			}}
		>
			{children}
		</H3>
	);
}

function Content({ children }: { children: React.ReactNode }) {
	return (
		<Stack
			style={{
				gap: 8,
				flex: 1,
				maxHeight: 432,
				overflowY: 'auto',
			}}
		>
			{children}
		</Stack>
	);
}

function Section({ title, content }: { title: string; content: string[] }) {
	return (
		<Stack gap={8}>
			<Box p={8} bg={theme.colors.blue_ultraLight}>
				<P1 style={{ fontWeight: theme.weight.medium }}>{title}</P1>
			</Box>
			<P1>
				<List gap={8}>
					{content.map((value) => (
						<List.Item key={value}>{renderTags(value)}</List.Item>
					))}
				</List>
			</P1>
		</Stack>
	);
}

function References({ title, content }: { title: string; content: string[] }) {
	return (
		<Stack gap={8}>
			<H3
				style={{
					color: theme.colors.gray_10,
					fontWeight: theme.weight.medium,
				}}
			>
				{title}
			</H3>
			<P1 style={{ color: theme.colors.gray_30 }}>
				<List type='ordered' listStyleType='decimal' gap={8}>
					{content.map((value) => (
						<List.Item key={value}>{value}</List.Item>
					))}
				</List>
			</P1>
		</Stack>
	);
}
