import {
	DefaultApiUpdateUserRequest,
	User,
} from '@lh/eng-platform-organization-service-rest-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function updateUser(
	searchInput: DefaultApiUpdateUserRequest,
	signal?: AbortSignal
) {
	if (!searchInput.organizationId) {
		throw new Error("useUpdateUser: organizationId can't be null");
	}
	if (!searchInput.userId) {
		throw new Error("useUpdateUser: userId can't be null");
	}
	if (!searchInput.userUpdate) {
		throw new Error("useUpdateUser: userUpdate can't be null");
	}

	const service = await getOrganizationService();
	const { data } = await service.updateUser(searchInput, { signal });
	return data;
}

export function useUpdateUser(organizationId: string) {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: `Error updating user`,
		},
		mutationFn: updateUser,
		onSuccess: async (updatedUser) => {
			client.setQueryData<User>(
				[QueryKey.User, organizationId, updatedUser.id],
				updatedUser
			);
			await client.invalidateQueries({
				queryKey: [QueryKey.Users, organizationId],
			});
		},
	});
}
