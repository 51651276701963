import {
	IssueContext,
	IssueType,
} from '@lh/eng-platform-battery-service-rest-client';

import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import {
	useDeleteAssignmentMutation,
	useRegisterAssignmentIssueMutation,
} from 'api/assignment';
import { QueryKey } from 'api/query';
import { UserContext } from 'components/context';
import { icons } from 'enums/icons';
import { MessageEnumItem, messageEnum } from 'enums/messageEnum';
import { Form, Formik, FormikHelpers } from 'formik';
import { t } from 'i18n';
import { BasicOption } from 'types';
import { RemoveBatteryProps } from '../../shared/DataTable/schemas/patientsSchema';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import {
	getModel,
	removeBatteryModel,
	removeBatterySchema,
} from '../../shared/Forms/Schemas/removeBatterySchema';
import { InfoMessage } from '../../shared/InfoMessage';
import { LinusModalDialog } from '../../shared/LinusModalDialog';
import { ButtonSm } from '../../shared/designSystem/ButtonSm';
import { P1 } from '../../shared/designSystem/TextComponents';
import { messages } from '../../shared/errorMessages';

import { ERROR } from 'logging/linusLogger';
import { RemoveBatteryForPatientHeader } from './RemoveBatteryForPatientHeader';

export const buildIssueOptions = (): BasicOption[] => {
	// Need to manually create list of issues to maintain order
	const issueOptions = [
		IssueType.Declined,
		IssueType.Motor,
		IssueType.Hearing,
		IssueType.Fatigue,
		IssueType.Vision,
		IssueType.Device,
		IssueType.PrimaryLang,
	];
	return issueOptions.map((issue: IssueType | string) => {
		return {
			value: issue,
			display: t(`web.shared.removeBatteryModal.issues.${issue}`),
		};
	});
};

export type RemoveBatteryForPatientProps = {
	onCancel: () => void;
	patient: RemoveBatteryProps;
};

const RemoveBatteryForPatient = ({
	onCancel,
	patient,
}: RemoveBatteryForPatientProps): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const theme = useTheme();
	const { t } = useTranslation();

	const queryClient = useQueryClient();

	const { mutateAsync: deleteAssignmentForParticipant } =
		useDeleteAssignmentMutation();
	const { mutateAsync: registerAssignmentIssueForParticipant } =
		useRegisterAssignmentIssueMutation();

	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const issueOptions = buildIssueOptions();

	const onSubmit = async (
		values: removeBatteryModel,
		{ setSubmitting }: FormikHelpers<removeBatteryModel>
	) => {
		const { issues, otherIssues } = values;
		// If there are no chips selected (or notes provided), we return valid issue type
		const patientIssues =
			!issues.length && !otherIssues.length
				? [IssueType.NoResponse]
				: issues;
		if (!patient.id || !patient.currentAssignmentId) return;
		try {
			await deleteAssignmentForParticipant(patient.currentAssignmentId);

			await registerAssignmentIssueForParticipant({
				assignmentId: patient.currentAssignmentId,
				issueContext: IssueContext.AssignmentRemoved,
				options: patientIssues as IssueType[],
				note: otherIssues,
				reporterId: currentUser.id,
			});

			await queryClient.invalidateQueries({
				queryKey: [QueryKey.Assignment, patient.id],
			});
			sendEventData({
				eventType: AnalyticsAction.RemovedBattery,
				eventProperties: {
					sessionIssues: patientIssues,
					otherIssues: !!otherIssues,
				},
			});

			setShowSuccessModal(true);
			setSubmitting(false);
		} catch (err) {
			ERROR(
				`Unexpected error on onSubmit from RemoveBatteryForPatient, assignmentId: ${patient.currentAssignmentId}. organizationId: ${currentUser.organizationId}: `,
				err
			);
			Sentry.captureException(err);
			setServerSideMessage(messageEnum.error(messages.mutationHookError));
		}
	};

	return (
		<Formik
			initialValues={getModel()}
			validationSchema={removeBatterySchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid }) => {
				return showSuccessModal ? (
					<LinusModalDialog
						onClose={onCancel}
						title={t`web.shared.removeBatteryModal.title`}
						titleIcon={icons.CheckmarkSolid}
						titleIconColor={theme.color.alertSuccess}
						acceptButtonText={t`web.shared.close`}
						acceptButtonCallback={onCancel}
					>
						<StyledSuccessMessage>
							<Trans
								i18nKey='web.shared.removeBatteryModal.batteryRemoved'
								values={{
									firstName: patient?.firstName,
									lastName: patient?.lastName,
								}}
								components={{
									b: <strong />,
								}}
							/>
						</StyledSuccessMessage>
					</LinusModalDialog>
				) : (
					<StyledFormContainer>
						<RemoveBatteryForPatientHeader patient={patient} />
						<StyledForm>
							<StyledIssues>
								<LinusInput
									headerTitle='web.shared.removeBatteryModal.issuesToNote'
									label=''
									name='issues'
									type='chipSelect'
									options={issueOptions}
									customLineBreak={[2, 3]}
									width='580px'
								/>
								<StyledNotes>
									<StyledSubtitle>{t`web.shared.removeBatteryModal.otherIssuesToNote`}</StyledSubtitle>
									<LinusInput
										label=''
										showLabel={false}
										placeholder={t`web.shared.removeBatteryModal.writeOtherReasons`}
										name='otherIssues'
										type='textarea'
										width='550px'
										rows={2}
									/>
								</StyledNotes>
							</StyledIssues>
							<StyledInfoMessage>
								<InfoMessage
									messageEnum={serverSideMessage}
									showIf={!!serverSideMessage}
								/>
							</StyledInfoMessage>
							<StyledActionRow>
								<ButtonSm
									text={t`web.shared.close`}
									width='158px'
									onClick={onCancel}
									dataId='add-battery-cancel-button'
								/>
								<StyledSpacer />
								<ButtonSm
									disabled={!isValid || isSubmitting}
									text={t`web.shared.removeBatteryModal.confirmRemoveBattery`}
									type='submit'
									width='158px'
									primary
									dataId='add-battery-submit-button'
								/>
							</StyledActionRow>
						</StyledForm>
					</StyledFormContainer>
				);
			}}
		</Formik>
	);
};

export { RemoveBatteryForPatient };

const StyledFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 548px;
	height: 100%;
`;

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
`;

const StyledIssues = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const StyledNotes = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 100px;
`;

const StyledSubtitle = styled(P1)(
	({ theme: { spacing, color, weight } }) => `
	padding-top: ${spacing.sm};
	color: ${color.textSubtitle};
	font-weight: ${weight.medium};
`
);

const StyledActionRow = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 32px;
	flex-grow: 1;
	align-items: flex-end;
`;

const StyledSpacer = styled.span(
	({ theme: { spacing } }) => `
	width: ${spacing.xl};
`
);

const StyledSuccessMessage = styled.div`
	max-width: 548px;
`;

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.lg}
`
);
