import React from 'react';

import { icons } from 'enums/icons';
import { Icon } from 'components/shared/designSystem';

import { Row, Wrapper } from './TableWord.style';

export const TableWordTestIds = {
	correctIcon: 'correct-selected-word',
	incorrectIcon: 'incorrect-selected-word',
};

type TableWordProps = {
	correct?: boolean;
	selected?: boolean;
	children: React.ReactNode;
};

export const TableWord = ({
	correct = false,
	selected = false,
	children,
}: TableWordProps) => {
	return (
		<Wrapper selected={selected}>
			{correct === false && selected === false ? (
				<div style={{ marginLeft: 18 }}>{children}</div>
			) : (
				<Row>
					{correct ? (
						<div
							style={{ marginRight: 2 }}
							data-testid={TableWordTestIds.correctIcon}
						>
							<Icon icon={icons.GreenCheckmark} />
						</div>
					) : (
						<div
							style={{ marginRight: 2 }}
							data-testid={TableWordTestIds.incorrectIcon}
						>
							<Icon icon={icons.RedClose} />
						</div>
					)}
					{children}
				</Row>
			)}
		</Wrapper>
	);
};
