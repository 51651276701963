import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getBattery, getBatteryIssue } from 'api/battery';
import { QueryKey } from 'api/query';
import { getUser } from 'api/user';
import { UserContext } from 'components/context';
import { ButtonSm } from '../../designSystem/ButtonSm';

type ViewResultButtonProps = {
	participantId: string | undefined;
	batteryResultId: string | undefined;
	text?: string | undefined;
	disabled?: boolean | undefined;
};

const ViewResultButton = ({
	participantId,
	batteryResultId,
	text = `web.dataTable.viewResultsButton.view`,
	disabled,
}: ViewResultButtonProps): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();
	const client = useQueryClient();
	const { t } = useTranslation();

	async function handleHover() {
		if (!disabled && batteryResultId) {
			client.prefetchQuery({
				queryKey: [QueryKey.BatteryIssue, batteryResultId],
				queryFn: () => getBatteryIssue(batteryResultId),
				staleTime: Infinity,
			});
			client.prefetchQuery({
				queryKey: [QueryKey.Battery, batteryResultId],
				queryFn: () => getBattery(batteryResultId, true),
				staleTime: Infinity,
			});
			client.prefetchQuery({
				queryKey: [
					QueryKey.User,
					currentUser.organizationId,
					currentUser.id,
				],
				queryFn: ({ signal }) =>
					getUser(currentUser.organizationId, currentUser.id, signal),
				staleTime: Infinity,
			});
		}
	}

	const isDisabled = disabled || !(batteryResultId || participantId);
	return (
		<ButtonSm
			text={t`${text}`}
			primary={false}
			disabled={isDisabled}
			width='123px'
			onClick={() =>
				navigate(`/results/${participantId}/${batteryResultId}`)
			}
			onHover={handleHover}
		/>
	);
};

export { ViewResultButton };
