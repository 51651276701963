import { P2 } from 'components/shared/designSystem';
import { PathwayType } from 'generated/graphql';

import { RenderList } from '../Pathway.list';
import { PathwayID } from '../Pathway.types';
import { ClickableText, ExternalLink, renderTags } from '../Pathway.misc';

import { reversibleCausesLinks } from './ReversibleCauses.data';
import { ReversibleCause } from './ReversibleCauses.types';

/**
 * Handles depedency injection of `reversibleCause` argument in components.
 *
 * Components: `Summary`, `NextSteps`
 */
export function getReversibleCause(
	reversibleCause: ReversibleCause,
	pathwayId: PathwayID | PathwayType
) {
	return {
		Summary: Summary.bind(null, reversibleCause.title),
		NextSteps: NextSteps.bind(null, reversibleCause.nextSteps, pathwayId),
	};
}

function Summary(value: ReversibleCause['title']) {
	if (Array.isArray(value)) {
		return (
			<>
				{value.map((v) => (
					<P2 key={v}>{renderTags(v)}</P2>
				))}
			</>
		);
	}

	return <P2>{renderTags(value)}</P2>;
}

function NextSteps(
	value: ReversibleCause['nextSteps'],
	pathwayId: PathwayID | PathwayType,
	{
		onClick,
	}: {
		onClick: {
			medicationList(): void;
		};
	}
) {
	const tagComponents = {
		medicationList: (
			<ClickableText
				fontWeight='inherit'
				onClick={onClick.medicationList}
			/>
		),
		ABC: (
			<ExternalLink
				to={reversibleCausesLinks.ABC}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		GDS: (
			<ExternalLink
				to={reversibleCausesLinks.GDS}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		PHQ_9: (
			<ExternalLink
				to={reversibleCausesLinks.PHQ_9}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		GAD_7: (
			<ExternalLink
				to={reversibleCausesLinks.GAD_7}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
		pittsburgh: (
			<ExternalLink
				to={reversibleCausesLinks.pittsburgh}
				pathwayExternalLink
				pathwayId={pathwayId}
			/>
		),
	};

	if (typeof value === 'object') {
		return (
			<P2>
				<RenderList
					list={value}
					renderItem={(item) => renderTags(item, tagComponents)}
				/>
			</P2>
		);
	}

	return <P2>{renderTags(value, tagComponents)}</P2>;
}
