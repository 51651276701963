import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { UserContext } from 'components/context';
import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { Breadcrumb } from 'components/shared/Breadcrumb';
import { Caption, H3 } from 'components/shared/designSystem';
import i18n from 'i18n';

import { InfoTimeCard } from './InfoTimeCard';

const BREADCRUMBS = [
	{
		text: i18n.t('web.shared.about'),
		link: '/about',
	},

	{ text: i18n.t('web.shared.aboutAssessments.title') },
];

export function AboutAssessments() {
	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation();

	return (
		<AboutAssessmentsContainer>
			<Header>
				<H3>{currentUser.organizationName}</H3>
				<Breadcrumb breadcrumbs={BREADCRUMBS} />
			</Header>
			<Info>
				<InfoTimeCard
					title={t('web.shared.aboutAssessments.cce.title')}
					subtitle={t('web.shared.aboutAssessments.cce.time')}
					info={t('web.shared.aboutAssessments.cce.info')}
					footer={t('web.shared.aboutAssessments.cce.footer')}
				/>
				<InfoTimeCard
					title={t('web.shared.aboutAssessments.clock.title')}
					subtitle={t('web.shared.aboutAssessments.clock.time')}
					info={t('web.shared.aboutAssessments.clock.info')}
					footer={t('web.shared.aboutAssessments.clock.footer')}
				/>
				<InfoTimeCard
					title={t('web.shared.aboutAssessments.dcr.title')}
					subtitle={t('web.shared.aboutAssessments.dcr.time')}
					info={t('web.shared.aboutAssessments.dcr.info')}
					footer={t('web.shared.aboutAssessments.dcr.footer')}
				/>
			</Info>
			<CaptionFaded>
				{t('web.shared.aboutAssessments.footer')}
			</CaptionFaded>
		</AboutAssessmentsContainer>
	);
}

const AboutAssessmentsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 20px;
`;

const Info = styled.div`
	display: flex;
	gap: 24px;

	@media ${MediaQueries.maxWidth.lg} {
		flex-direction: column;
	}
`;

const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);

const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);
