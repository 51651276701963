import { t } from 'i18n';
import styled, { DefaultTheme, css } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';

import { icons } from '../../../../enums/icons';
import {
	BatteryResult,
	BatteryType,
	FeatureType,
	ParticipantWithBatteryResultsFragment,
} from '../../../../generated/graphql';
import { dateFormatter } from '../../../../stringStrategy/dateFormatStringStrategy';
import { Icon } from '../../designSystem/Icon';
import { TableColumn } from '../DataTable';
import { ViewResultButton } from '../columnComponents/ViewResultButton';

import {
	Notification,
	isProcessing,
	showNotification,
} from './resultTableSchema';
import { ShowIfAuthorized } from '../../../shared/ShowIfAuthorized';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

export type TableData = {
	battery: string;
	batteryType: BatteryType;
	date: string;
	administrator: string;
	id: string;
	notification: boolean;
	participantId: string;
	processing: boolean;
	delayed: boolean;
};

export function showNoteTemplate(batteryType?: BatteryType) {
	if (batteryType === BatteryType.CognitiveEvaluation) {
		return true;
	}

	if (batteryType === BatteryType.Dcr) {
		return true;
	}

	return false;
}

const columns = (
	theme: DefaultTheme,
	dateFormat: string,
	defaultTimezone: string,
	features: FeatureType[],
	onNotesIconPress: (assessmentId: string) => void
): TableColumn<TableData>[] => {
	function handleNoteTemplateOpen(assessmentId: string | undefined) {
		if (assessmentId) {
			sendEventData({
				eventType: AnalyticsAction.ClickedNote,
				eventProperties: {
					source: 'Session History',
				},
			});
			onNotesIconPress?.(assessmentId);
		}
	}

	const NoteTemplateColumn: TableColumn<TableData> = {
		propertyName: 'Note',
		headerDisplay: t`web.note`,
		minWidth: '90px',
		rowColumnComponent: ({ row }) => {
			return (
				<NoteTemplateWrapper>
					{showNoteTemplate(row?.batteryType) && (
						<Icon
							onClick={() => handleNoteTemplateOpen(row?.id)}
							icon={icons.NoteClipboard}
						/>
					)}
				</NoteTemplateWrapper>
			);
		},
	};

	const data: TableColumn<TableData>[] = [
		{
			propertyName: 'battery',
			headerDisplay: t`web.shared.battery`,
			minWidth: '199.5px',
		},
		{
			propertyName: 'date',
			headerDisplay: t`web.completed`,
			minWidth: '162px',
			formatProperty: ({ value }) =>
				dateFormatter(value as string, dateFormat, defaultTimezone),
		},
		{
			propertyName: 'administrator',
			headerDisplay: t`web.shared.administrator`,
			minWidth: '395px',
		},
		...(features.includes(FeatureType.NoteTemplate)
			? [NoteTemplateColumn]
			: []),
		{
			propertyName: 'report',
			headerDisplay: t`web.shared.report`,
			minWidth: '155px',
			rowColumnComponent: ({ row }) => {
				return row?.delayed ? (
					<StyledResultsDelayed>
						<Notification
							text={t`web.shared.resultsTable.resultsDelayedTip`}
						>
							<Icon
								icon={icons.InfoOutlined}
								title={t`web.shared.notification`}
								color={theme.color.alertError}
							/>
						</Notification>
						<StyledDelayed>{t`web.shared.resultsTable.resultsDelayed`}</StyledDelayed>
					</StyledResultsDelayed>
				) : (
					<ShowIfAuthorized operations={[OperationToken.ViewReport]}>
						<ViewResultButton
							disabled={row?.processing}
							text={
								row?.processing
									? t`web.shared.resultsTable.processing`
									: t`web.shared.view`
							}
							batteryResultId={row?.id}
							participantId={row?.participantId}
						/>
					</ShowIfAuthorized>
				);
			},
		},
		{
			propertyName: 'notification',
			headerDisplay: ' ',
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ value }) => {
				return value ? (
					<StyledWrapper>
						<Notification
							text={t`web.shared.resultsTable.concerns`}
						>
							<Icon
								icon={icons.AlertSolid}
								color={theme.color.alertError}
								title={t`web.shared.notification`}
							/>
						</Notification>
					</StyledWrapper>
				) : (
					<StyledBlank>{` `}</StyledBlank>
				);
			},
		},
	];

	return data;
};

const mapData = (data: ParticipantWithBatteryResultsFragment): TableData[] =>
	(data?.batteryResults?.nodes || []).map((x) => {
		return {
			battery: t(x.battery.displayKey) || '',
			date: x.endTime || '',
			administrator:
				`${x.proctor?.firstName} ${x.proctor?.lastName}` || '',
			id: x.id || '',
			notification: showNotification(x as BatteryResult),
			participantId: data.id || '',
			processing: isProcessing(x as BatteryResult),
			delayed: x.assessmentResults.nodes.some(
				(ar) =>
					ar.hasProcessingError ||
					ar.segmentResults.nodes.some((sr) => sr.hasProcessingError)
			),
			batteryType: x.battery.batteryType,
		};
	});

export { columns, mapData };

const StyledWrapper = styled.div(
	({ theme: { fontSize } }) => css`
		font-size: ${fontSize._14};
	`
);

const StyledBlank = styled.span`
	width: 24px;
`;

const NoteTemplateWrapper = styled.div`
	align-items: flex-start;
	display: flex;
`;

const StyledDelayed = styled.span(({ theme: { color, spacing } }) => {
	return css`
		color: ${color.deactivatedText};
		padding-left: ${spacing.xs};
	`;
});

const StyledResultsDelayed = styled.div`
	display: flex;
	align-items: center;
`;
