import { P1 } from 'components/shared/designSystem/TextComponents';
import styled from 'styled-components';

export type ClockConcernsPropType = {
	concerns: string[];
	disclaimer: string;
	title: string;
	betaTitle: string;
	sectionName: string;
};

export const ClockConcerns = ({
	concerns,
	disclaimer,
	title,
	betaTitle,
	sectionName,
}: ClockConcernsPropType): JSX.Element => {
	return (
		<StyledMarginFlexContainer>
			<StyledTextContainer>
				<StyledTextTitleContainer>
					<StyledTextTitleText data-testid='clock-concern-title'>
						{title}
					</StyledTextTitleText>
					{betaTitle && (
						<StyledStatusContainer data-testid='clock-concern-beta-title'>
							{betaTitle}
						</StyledStatusContainer>
					)}
				</StyledTextTitleContainer>
				<StyledList data-testid='concerns-list'>
					{(concerns || []).map((element) => (
						<StyledListItem
							data-testid='clock-concern-item'
							key={`${sectionName}_${element}`}
						>
							{element}
						</StyledListItem>
					))}
				</StyledList>
				<StyledStyledDisclaimer data-testid='clock-concern-disclaimer'>
					{disclaimer}
				</StyledStyledDisclaimer>
			</StyledTextContainer>
		</StyledMarginFlexContainer>
	);
};

const StyledMarginFlexContainer = styled.div`
	flex: 1;
	margin-left: 1em;
`;

const StyledTextContainer = styled.div(
	({ theme: { color } }) => `
		text-align: left;
		color: ${color.white};
	`
);

const StyledTextTitleContainer = styled.div``;

const StyledTextTitleText = styled.span(
	({ theme: { color } }) => `
	font-family: 'IBM Plex Sans';
	color: ${color.clockConcernTitle};
	letter-spacing: 0.5px;
	font-weight: 400;
`
);

const StyledStatusContainer = styled.span(
	({ theme: { color } }) => `
	background: ${color.clockConcernsStatusBackground};
	color: ${color.clockConcernsStatusText};
	padding: 0.6em;
	padding-top: 0.4em;
	padding-bottom: 0.4em;
	border-radius: 4px;
	font-weight: 500;
	margin-left: 0.5em;
`
);

const StyledList = styled.ul`
	padding-left: 1.2em;
	margin-top: 0.5em;
`;

const StyledListItem = styled.li(
	({ theme: { color } }) => `
	list-style-type: disc;
	color: ${color.clockConcernsItem}
`
);

const StyledStyledDisclaimer = styled(P1)(
	({ theme: { color } }) => `
	margin-top: 1em;
	color: ${color.clockConcernsDisclaimer}
`
);
