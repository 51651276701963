import { AnalyticsAction, sendEventData } from 'analytics';
import { t } from 'i18n';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

type RegulatoryLinkProps = {
	RegulatoryUrl?: string;
	text?: string;
	isRegulatoryPage?: boolean;
};

export const RegulatoryLink = ({
	RegulatoryUrl = '/regulatory-label',
	text = t`web.shared.regulatoryLink`,
}: RegulatoryLinkProps): JSX.Element => {
	const { pathname } = useLocation();
	return pathname === '/regulatory-label' ? (
		<StyledText>{text}</StyledText>
	) : (
		<StyledLink
			to={RegulatoryUrl}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedRegulatoryLabel,
				})
			}
		>
			{text}
		</StyledLink>
	);
};

const StyledLink = styled(Link)`
	text-decoration: underline;
`;
const StyledText = styled.p`
	margin: 0;
`;
