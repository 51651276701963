import { H4 } from 'components/shared/designSystem';

import {
	H2Med,
	ScoreContainer,
	ScoreInterpretationContainer,
} from './ScoreInterpretation.style';

type ScoreInterpretationProps = {
	score: string;
	text: React.ReactNode;
};

export function ScoreInterpretation({
	score,
	text,
}: Readonly<ScoreInterpretationProps>) {
	return (
		<ScoreInterpretationContainer>
			<Score score={score}></Score>
			<H4>{text}</H4>
		</ScoreInterpretationContainer>
	);
}

function Score({ score }: Readonly<{ score: string }>) {
	return (
		<ScoreContainer>
			<H2Med>{score}</H2Med>
		</ScoreContainer>
	);
}
