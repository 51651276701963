import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';

import { FluencyLayout } from '../FluencyLayout';

import { usePhonemicFluencyData } from './usePhonemicFluencyData';

type PhonemicLayoutProps = {
	segmentResult: DeepSegmentResult;
};

export function PhonemicLayout({
	segmentResult,
}: Readonly<PhonemicLayoutProps>) {
	const data = usePhonemicFluencyData(segmentResult);
	const { t } = useTranslation();

	return (
		<FluencyLayout
			{...data}
			title={t('web.report.fluency.phonemic.title')}
			tooltipText={t('web.report.fluency.phonemic.description', {
				returnObjects: true,
			})}
		/>
	);
}
