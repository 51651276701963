import { Trans, useTranslation } from 'react-i18next';

import {
	AskedQuestion,
	CaptionFaded,
	Card,
	Content,
	Header,
	Info,
	MediumSpan,
	Title,
} from './IndividualAnswers.style';
import { Gad7ResultData, Table } from './Table';

type IndividualAnswersProps = {
	resultsTableData: Gad7ResultData[];
};

export function IndividualAnswers({
	resultsTableData,
}: Readonly<IndividualAnswersProps>) {
	const { t } = useTranslation();
	return (
		<Card>
			<Content>
				<Header>
					<Info>
						<Title>{t('web.report.gad7.answersCard.title')}</Title>
						<CaptionFaded>
							{t('web.report.gad7.answersCard.subtitle')}
						</CaptionFaded>
					</Info>
					<AskedQuestion>
						<Trans
							i18nKey='web.report.gad7.answersCard.info'
							components={{
								b: <MediumSpan />,
							}}
						/>
					</AskedQuestion>
				</Header>
				<Table resultsTableData={resultsTableData} />
			</Content>
		</Card>
	);
}
