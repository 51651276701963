import { t } from 'i18n';
import * as yup from 'yup';
import { CurrentUser } from '../../../../types';
import { phoneRegExp } from '../utils/phoneNumberFormat';

export type UserProfileModel = {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber?: string;
	avatarUrl: string;
	suffixIds: string;
	roleId: string;
};

export const getModel = (user?: CurrentUser): UserProfileModel => {
	return {
		id: user?.id,
		firstName: user?.firstName || '',
		lastName: user?.lastName || '',
		email: user?.email || '',
		phoneNumber: user?.phoneNumber || '',
		avatarUrl: user?.avatarUrl || '',
		suffixIds: user?.suffix?.map((v) => v.id).join(', '),
		roleId: user?.roleId || '',
	} as UserProfileModel;
};

export const userProfileSchema = yup.object().shape(
	{
		firstName: yup.string().required(t`web.shared.forms.schemas.required`),
		lastName: yup.string().required(t`web.shared.forms.schemas.required`),
		email: yup
			.string()
			.email(t`web.authErrors.invalidEmail`)
			.required(t`web.authErrors.emailRequired`),
		phoneNumber: yup
			.string()
			.max(20, t`web.shared.forms.schemas.invalidPhone`)
			.nullable()
			.notRequired()
			.when('phoneNumber', {
				is: (value: string) => value?.length,
				then: yup
					.string()
					.matches(
						phoneRegExp,
						t`web.shared.forms.schemas.invalidPhone`
					),
			}),
	},
	[
		// Add cyclic dependencies when making 'requires' to itself
		['phoneNumber', 'phoneNumber'],
	]
);
