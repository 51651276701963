import { ERROR } from 'logging/linusLogger';
import {
	FetchElementsProps,
	HandleScrollEndProps,
	HandleScrollTimeUpdateProps,
} from '../interface';
import { ApolloError } from '@apollo/client';

export const fetchElements = async ({
	searchFunction,
	_searchInput,
	pageSize,
	page,
	setReachedEnd,
	filteredOptions,
	setFilteredOptions,
	setSearching,
}: FetchElementsProps) => {
	setSearching(true);
	try {
		const resultingFiltered = await searchFunction({
			search: _searchInput,
			pageSize: pageSize,
			pageNumber: page,
		});
		if (`${_searchInput}`.length < 1) {
			setFilteredOptions([]);
			setReachedEnd(true);
		} else if (!resultingFiltered.length) {
			const newFiltered =
				page > 0 ? filteredOptions.concat(resultingFiltered) : [];
			setFilteredOptions(newFiltered);
			setReachedEnd(true);
		} else if (filteredOptions.length) {
			const newFiltered =
				page > 0
					? filteredOptions.concat(resultingFiltered)
					: resultingFiltered;
			setFilteredOptions(newFiltered);
			setReachedEnd(false);
		} else {
			setFilteredOptions(resultingFiltered);
			setReachedEnd(false);
		}
		setSearching(false);
	} catch (e) {
		setSearching(false);
		const errorMessage =
			(e as ApolloError)?.message ||
			'Error trying to search reasons for testing.';
		ERROR(errorMessage, e);
	}
};
export const handleScrollEnd = ({
	scrollableListRef,
	setScrollEndTime,
}: HandleScrollEndProps) => {
	const element = scrollableListRef.current;
	if (!element) {
		return;
	}
	if (element.scrollTop < 0) {
		return;
	}
	if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
		setScrollEndTime(Date.now());
	}
};

export const handleScrollTimeUpdate = ({
	filteredOptions,
	pageSize,
	reachedEnd,
	setPage,
	page,
}: HandleScrollTimeUpdateProps) => {
	if (
		filteredOptions?.length &&
		filteredOptions.length >= pageSize &&
		!reachedEnd
	) {
		setPage(page + 1);
	}
};
