import { LineDivider } from 'components/shared/LineDivider';

import { ReportCardHeader } from '../../atoms/ReportCardHeader';
import { Card, Stack } from './ReportInfoPanel.style';

type ReportInfoPanelProps = {
	children: React.ReactNode;
	title: string;
	tooltipText?: string | string[];
};

export function ReportInfoPanel({
	children,
	title,
	tooltipText,
}: Readonly<ReportInfoPanelProps>) {
	return (
		<Card>
			<Stack>
				<ReportCardHeader title={title} tooltipText={tooltipText} />
				<LineDivider />
				{children}
			</Stack>
		</Card>
	);
}
