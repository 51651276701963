export const gatherArticles = (keys: string[]): Record<string, string> => {
	const _basePath = '/cdsArticles/';
	return keys
		.filter((key) => key.includes('.html'))
		.reduce((acc, curr) => {
			const match = curr.match(/\/(.+?)\//);
			const articleKey = match ? match[1] : '';
			const formattedPath = curr.replace('./', _basePath);
			return { ...acc, [articleKey]: formattedPath };
		}, {});
};
