import styled, { css, keyframes } from 'styled-components';

import { H3 } from 'components/shared/designSystem';
import { StyledCard } from 'components/shared/StyledCard';

export const HeaderContainer = styled.div(
	({ theme: { color } }) => css`
		background-color: ${color.body};
		position: sticky;
		top: 0;
		z-index: 3;
		box-shadow: 0px -10px 0 10px ${color.body};
	`
);

export const Body = styled(H3)(
	({ theme: { spacing } }) => css`
		padding-top: 20px;
		padding-bottom: ${spacing.lg};
	`
);

export const DefaultPathway = styled.img(
	({ theme: { spacing } }) => css`
		max-height: 100%;
		max-width: 100%;
		margin: ${spacing.lg} 0;
	`
);

export const ActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const AnimatedIcon = styled.div`
	animation: ${rotateAnimation} 1s linear infinite;
`;

export const FullStyledCard = styled(StyledCard)`
	height: 100%;
`;

export const PatientProfileHeader = styled.div`
	display: flex;
	gap: 24px;
`;

export const PatientDetailsContainer = styled.div`
	flex: 3;
`;

export const CarePartnerContainer = styled.div`
	flex: 1;
`;
