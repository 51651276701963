import { Reason } from '@lh/eng-platform-organization-service-rest-client';
import { useDebouncedValue } from '@mantine/hooks';
import { listVariants } from 'components/shared/Forms/utils/listVariants';
import { Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	StyledOptionProps,
	TDefaultRenderOptionsProps,
	TDropdownElement,
	TypeAheadResultsListProps,
} from '../interface';
import {
	fetchElements,
	handleScrollEnd,
	handleScrollTimeUpdate,
} from './ResultsList.utils';
import { useTranslation } from 'react-i18next';
import { normalizeRafCode } from '../sharedUtils';
import { DropdownOption } from 'types';

export const defaultRenderOptions = ({
	options,
	handleSelect,
	display,
	selectedOptions,
}: TDefaultRenderOptionsProps<Reason>) => {
	const selectedOptionsFound = selectedOptions.filter((selectedElement) => {
		return options.find(
			(foundElement) =>
				foundElement.dropdownOption.value ===
				selectedElement.dropdownOption.value
		);
	});
	const anySelected = selectedOptionsFound.length > 0;
	return (options || []).map((option: TDropdownElement<Reason>) => {
		const value = option.dropdownOption.value;
		const selected = !!selectedOptionsFound.find(
			(selectedElement) => selectedElement.dropdownOption.value === value
		);
		return (
			<StyledOptionContainer
				selected={selected}
				anySelected={anySelected}
				key={value}
			>
				<StyledOption
					data-id={`search-result-${value}`}
					key={value}
					selected={false}
					onClick={() => {
						handleSelect(option);
					}}
					data-testid={`test-search-result-${value}`}
				>
					<StyledOptionListText
						data-id={`search-result-text-${value}`}
						data-testid={`test-search-result-text-${value}`}
					>
						<StyledOptionTextFirstLine selected={selected}>
							<b>{value}</b>&nbsp;
							<span>
								{
									option.dropdownOption[
										display as keyof DropdownOption
									]
								}
							</span>
						</StyledOptionTextFirstLine>
						{option.dataElement.rafCode && (
							<StyledRAFContainer>
								<StyledRAFSpan>
									RAF{' '}
									{normalizeRafCode(
										option.dataElement.rafCode
									)}
								</StyledRAFSpan>
							</StyledRAFContainer>
						)}
					</StyledOptionListText>
					{selected && (
						<StyledOptionListCheckmarkContainer
							data-testid={`test-search-result-check-${value}`}
						>
							<Icon
								icon={icons.CheckboxSideCheck}
								width={8}
								height={8}
							/>
						</StyledOptionListCheckmarkContainer>
					)}
				</StyledOption>
			</StyledOptionContainer>
		);
	});
};

const StyledOptionContainer = styled.li<{
	selected: boolean;
	anySelected: boolean;
}>(({ selected, anySelected, theme: { spacing, color } }) => {
	let style;
	if (anySelected) {
		if (selected) {
			style = `
				width: calc(100% + ${spacing.md});
				padding-right: 26px;
				padding-left: 6px;
				margin-left: -${spacing.sm};
				border-left: ${spacing.xs} solid ${color.inputFocus};
				background: ${color.chipActiveBackgroundColor};
			`;
		} else {
			style = `width: calc(100% - 2px);`;
		}
	} else {
		style = `width: 100%;`;
	}
	return style;
});

const StyledOptionTextFirstLine = styled(P1)<{ selected: boolean }>(
	({ selected, theme: { weight } }) => `
	span {
		font-weight: ${selected ? '600' : '400'};
		text-align: left;
	}

	b {
		font-weight: ${weight.semi};
		text-align: left;
	}
`
);

const StyledRAFSpan = styled.span`
	font-family: IBM Plex Sans;
	font-size: 10px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.2px;
	text-align: left;
	color: #676671;
`;

const StyledRAFContainer = styled.div`
	margin-top: -8px;
`;

//Animations must be in the component here to access props
const defaultListTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.25,
};

export const TypeAheadResultsList = ({
	renderOptions,
	dataId,
	dropUpSpace,
	listTransition = defaultListTransition,
	search,
	page,
	setPage,
	filteredOptions,
	setFilteredOptions,
	display,
	handleSelect,
	selectedOptions,
	searchFunction,
}: TypeAheadResultsListProps<Reason>) => {
	const { t } = useTranslation();
	const scrollableListRef = useRef<HTMLUListElement>(null);
	const [scrollEndTime, setScrollEndTime] = useState(0);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [searching, setSearching] = useState(false);
	const [_searchInput] = useDebouncedValue(search, 500, {
		leading: true,
	});
	const pageSize = 20;

	useEffect(() => {
		if (_searchInput && !searching) {
			fetchElements({
				searchFunction,
				_searchInput,
				pageSize,
				page,
				setReachedEnd,
				filteredOptions,
				setFilteredOptions,
				setSearching,
			});
		} else if (!_searchInput) {
			setSearching(false);
			setFilteredOptions([]);
		}
	}, [_searchInput, page]);

	useEffect(() => {
		handleScrollTimeUpdate({
			filteredOptions,
			pageSize,
			reachedEnd,
			setPage,
			page,
		});
	}, [scrollEndTime]);

	useEffect(() => {
		const handleScroll = () => {
			handleScrollEnd({
				scrollableListRef,
				setScrollEndTime,
			});
		};
		scrollableListRef.current?.addEventListener('scrollend', handleScroll);
		return () => {
			scrollableListRef.current?.removeEventListener(
				'scrollend',
				handleScroll
			);
		};
	}, []);

	const isEmpty = !searching && filteredOptions.length < 1;

	return (
		<StyledList
			tabIndex={1}
			initial='initial'
			animate='in'
			exit='out'
			variants={listVariants(dropUpSpace)}
			transition={listTransition}
			data-id={dataId}
			ref={scrollableListRef}
			empty={isEmpty}
			data-testid={`styled-list-component`}
		>
			{isEmpty && (
				<StyledOptionListText data-testid='styled-list-not-found'>
					<StyledNotFoundSpan>
						{t('web.reasonsForTesting.noResults')}
					</StyledNotFoundSpan>
				</StyledOptionListText>
			)}
			{renderOptions
				? renderOptions(filteredOptions)
				: defaultRenderOptions({
						options: filteredOptions,
						handleSelect,
						display,
						selectedOptions,
				  })}
		</StyledList>
	);
};

const StyledNotFoundSpan = styled.span`
	font-family: IBM Plex Sans;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0.5px;
	text-align: left;
	color: #676671;
`;

const StyledList = styled(motion.ul)<{ empty: boolean }>(
	({ empty, theme: { boxShadow, spacing, color } }) => `
	position: absolute;
	top: calc((100% + 32px) * -1);
	left: -1px;
	padding: ${spacing.sm};
	padding-top: 0px;
	width: calc(100% + 2px);
	max-height: 360px;
	background: white;
	border: 1px solid ${color.inputFocus};
	border-radius: 10px;
	box-shadow: ${boxShadow.standard};
	overflow: auto;

	${empty ? `padding-top: ${spacing.md};` : ``}
	${empty ? `padding-bottom: ${spacing.md};` : ``}

	&::-webkit-scrollbar {
		display: none;
	}
	&::-webkit-scrollbar-track {
		display: none;
	}
	&::-webkit-scrollbar-thumb {
		display: none;
	}
`
);

/**
 * Default render option options.
 */
const StyledOption = styled.div<StyledOptionProps>(
	({ selected, theme: { color, spacing } }) => `
	padding: ${spacing.sm};
    padding-top: ${spacing.md};
    padding-bottom: ${spacing.md};
	border-bottom: 1px solid ${color.dropdownItemBorder};
	background: ${selected ? color.kebabLinkHover : 'auto'};
	cursor: pointer;
    display: inline-flex;
    width: inherit;
    position: relative;

	&:hover {
		background: ${color.kebabLinkHover};
	}
`
);

const StyledOptionListText = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-right: 12px;
`;

const StyledOptionListCheckmarkContainer = styled.span`
	justify-content: center;
	align-items: center;
	display: flex;
	top: 0;
	height: 100%;
	align-self: center;
`;
