import { useState } from 'react';
import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import {
	GetPaginatedSubjects200Response,
	GetPaginatedSubjectsFilterField,
	GetPaginatedSubjectsFilterOperator,
	GetPaginatedSubjectsLogicalOperator,
	GetPaginatedSubjectsSortField,
	GetPaginatedSubjectsSortOrder,
} from '@lh/eng-platform-organization-service-rest-client';
import { QueryKey } from 'api/query';
import { getOrganizationSearchService } from 'api/getOrganizationService';
import { useOrganizationStore } from 'store';
import { TableData } from 'components/shared/DataTable/schemas/patientsSchema';

export const DEFAULT_PAGE_SIZE = 10;

type PatientsParams = {
	sortField: GetPaginatedSubjectsSortField[];
	sortOrder: GetPaginatedSubjectsSortOrder[];
	page: number;
	filterValue?: string[];
	filterField?: GetPaginatedSubjectsFilterField[];
	filterOperator?: GetPaginatedSubjectsFilterOperator[];
	logicalOperator?: GetPaginatedSubjectsLogicalOperator[];
};

type FetchPatients = {
	organizationId: string | null;
	signal?: AbortSignal;
} & PatientsParams;

export async function getPaginatedPatients({
	organizationId,
	page,
	sortField,
	sortOrder,
	filterField,
	filterOperator,
	filterValue,
	logicalOperator,
	signal,
}: FetchPatients): Promise<GetPaginatedSubjects200Response> {
	if (!organizationId) {
		throw new Error(
			"useGetPaginatedPatientsQuery: organizationId can't be null"
		);
	}

	const service = await getOrganizationSearchService();
	const { data } = await service.getPaginatedSubjects(
		{
			organizationId,
			pageSize: DEFAULT_PAGE_SIZE,
			page,
			sortField,
			sortOrder,
			filterField,
			filterOperator,
			filterValue,
			logicalOperator,
		},
		{
			signal,
		}
	);
	return data;
}

export function usePaginatedPatientsQuery({
	sortField,
	sortOrder,
	page,
	filterField,
	filterOperator,
	filterValue,
	logicalOperator,
}: PatientsParams) {
	const organizationId = useOrganizationStore((state) => state.id);
	const client = useQueryClient();
	const [isRefreshing, setIsRefreshing] = useState(false);
	const query = useQuery({
		enabled: !!organizationId,
		meta: {
			errorMessage: `Error fetching patients for organization ID: ${organizationId}`,
		},
		queryKey: [
			QueryKey.Patients,
			organizationId,
			sortField,
			sortOrder,
			filterValue,
			page,
		],
		queryFn: ({ signal }) =>
			getPaginatedPatients({
				organizationId,
				page,
				sortField,
				sortOrder,
				signal,
				filterField,
				filterOperator,
				filterValue,
				logicalOperator,
			}),
		placeholderData: keepPreviousData,
		staleTime: Infinity,
		select: (data) => {
			return {
				data: (data.results.flat().filter(Boolean) ?? []).map((x) => {
					return {
						id: x?.subjectId || '',
						firstName: x.firstName || '',
						lastName: x?.lastName || '',
						patientExternalId: x?.externalId || '',
						name: `${x?.firstName || ''} ${x?.lastName || ''}`,
						birthDate: x?.birthDate || '',
						lastCompletedBattery: x?.endTime || '',
						assignmentStatus: x?.assignmentStatus || '',
						currentAssignmentId: x?.assignmentId || '',
					};
				}) as TableData[],
				totalCount: data?.totalCount,
			};
		},
	});

	async function refresh() {
		setIsRefreshing(true);
		await client.invalidateQueries({
			queryKey: [
				QueryKey.Patients,
				organizationId,
				sortField,
				sortOrder,
				filterValue,
				page,
			],
		});
		setIsRefreshing(false);
	}

	return {
		...query,
		data: query.data?.data ?? [],
		totalCount: query?.data?.totalCount,
		isRefreshing,
		refresh,
	};
}
