import { Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { InterpretedScore, ScoreTag, ScoreType } from 'components/mosaic';

import { BdstPerformance } from '../BdstReport.types';
import { useQuartileInterpretation } from './useQuartileInterpretation';

const IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY: Record<number, string> = {
	0: 'web.report.bdstReport.performance.good',
	1: 'web.report.bdstReport.performance.bad',
};
const IMPAIRED_TO_SCORE_TYPE: Record<number, ScoreType> = {
	0: 'not-impaired',
	1: 'impaired',
};

type BdstScoreProps = {
	performance: BdstPerformance;
};

export function BdstScore({ performance }: Readonly<BdstScoreProps>) {
	const { t } = useTranslation();

	const quartileInterpretation = useQuartileInterpretation(
		performance.quartile
	);
	const requiresReview = performance.score.value === null;
	const scoreValue = performance.score.value ?? '--/15';

	if (requiresReview) {
		return (
			<Stack maw={445}>
				<InterpretedScore
					interpretationDescription={t('web.report.bdstReport.rcr')}
					scoreLabel={t('web.report.bdstReport.scoreLabel')}
					scoreType='unanalyzable'
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	if (performance.impaired > -1) {
		const impairmentInterpretation = t(
			IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY[performance.impaired]
		);
		const scoreType = IMPAIRED_TO_SCORE_TYPE[performance.impaired];

		return (
			<Stack maw={445}>
				<InterpretedScore
					interpretationCaption={quartileInterpretation}
					interpretationDescription={impairmentInterpretation}
					scoreLabel={t('web.report.bdstReport.scoreLabel')}
					scoreType={scoreType}
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	return (
		<Stack maw={445}>
			{quartileInterpretation ? (
				<InterpretedScore
					interpretationDescription={quartileInterpretation}
					scoreLabel={t('web.report.bdstReport.scoreLabel')}
					scoreType='unknown'
					scoreValue={scoreValue}
				/>
			) : (
				<ScoreTag
					label={t('web.report.bdstReport.scoreLabel')}
					type='unknown'
					value={scoreValue}
				/>
			)}
		</Stack>
	);
}
