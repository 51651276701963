import { useTranslation } from 'react-i18next';

import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

import { Trial } from '../BdstReport.types';
import { BdstOrderField } from './BdstOrderField';

import {
	Table,
	TableHeaderCell,
	TableHeaderRow,
	TableHeaderTitle,
	TableRow,
	TableRowCell,
	TableTitle,
	Tooltip,
} from './BdstTable.style';

interface BDSTTableProps {
	trialOne: Trial;
	trialTwo: Trial;
	trialThree: Trial;
}

export function BDSTTable({
	trialOne,
	trialTwo,
	trialThree,
}: Readonly<BDSTTableProps>) {
	const { t } = useTranslation();

	return (
		<Table>
			<thead>
				<tr>
					<TableHeaderCell $index={0}>
						<TableTitle>
							{t('web.report.bdstReport.breakdown')}
						</TableTitle>
					</TableHeaderCell>
					<TableHeaderCell $index={1}>
						<TableHeaderRow>
							<TableHeaderTitle>
								{t('web.report.bdstReport.serialOrder')}
							</TableHeaderTitle>
							<LinusTooltip
								overlay={
									<Tooltip>
										{t(
											'web.report.bdstReport.serialOrderRecall'
										)}
									</Tooltip>
								}
								tooltipIcon={
									<Icon
										icon={icons.InfoOutlined}
										iconWidth={24}
										iconHeight={24}
									/>
								}
							/>
						</TableHeaderRow>
					</TableHeaderCell>
					<TableHeaderCell $index={2}>
						<TableHeaderRow>
							<TableHeaderTitle>
								{t('web.report.bdstReport.anyOrder')}
							</TableHeaderTitle>
							<LinusTooltip
								overlay={
									<Tooltip>
										{t(
											'web.report.bdstReport.anyOrderRecall'
										)}
									</Tooltip>
								}
								tooltipIcon={
									<Icon
										icon={icons.InfoOutlined}
										iconWidth={24}
										iconHeight={24}
									/>
								}
							/>
						</TableHeaderRow>
					</TableHeaderCell>
				</tr>
			</thead>
			<tbody>
				<TableRow $index={0}>
					<TableRowCell
						$index={0}
						style={{
							paddingLeft: 0,
						}}
					>
						<TableHeaderTitle>
							{t('web.report.bdstReport.trial_1')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</TableHeaderTitle>
					</TableRowCell>
					<TableRowCell $index={1}>
						<BdstOrderField value={trialOne.serial} />
					</TableRowCell>
					<TableRowCell $index={2}>
						<BdstOrderField value={trialOne.any} />
					</TableRowCell>
				</TableRow>
				<TableRow $index={1}>
					<TableRowCell
						$index={0}
						style={{
							paddingLeft: 0,
						}}
					>
						<TableHeaderTitle>
							{t('web.report.bdstReport.trial_2')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</TableHeaderTitle>
					</TableRowCell>
					<TableRowCell $index={1}>
						<BdstOrderField value={trialTwo.serial} />
					</TableRowCell>
					<TableRowCell $index={2}>
						<BdstOrderField value={trialTwo.any} />
					</TableRowCell>
				</TableRow>
				<TableRow $index={2}>
					<TableRowCell
						$index={0}
						style={{
							paddingLeft: 0,
						}}
					>
						<TableHeaderTitle>
							{t('web.report.bdstReport.trial_3')}
							{': '}
							{t('web.report.bdstReport.backwardsDigitSpan')}
						</TableHeaderTitle>
					</TableRowCell>
					<TableRowCell $index={1}>
						<BdstOrderField value={trialThree.serial} />
					</TableRowCell>
					<TableRowCell $index={2}>
						<BdstOrderField value={trialThree.any} />
					</TableRowCell>
				</TableRow>
			</tbody>
		</Table>
	);
}
