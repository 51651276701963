import styled from 'styled-components';
import { useField } from 'formik';
import { DropdownOption, LinusInputChildrenProps } from '../../../../types';
import { definedProps } from './definedProps';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Icon } from '../../designSystem/Icon';
import { icons } from '../../../../enums/icons';
import { listVariants } from '../utils/listVariants';
import { useTranslation } from 'react-i18next';

const TypeAheadSelect = <T,>(
	props: LinusInputChildrenProps<T>
): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, error, helpers] = useField(props.name);
	const { t } = useTranslation();
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [filteredOptions, setFilteredOptions] = useState<DropdownOption[]>();
	const { setValue } = helpers;
	useEffect(() => {
		setFilteredOptions(props?.dropdownOptions || []);
	}, [props?.dropdownOptions]);

	const [filterString, setFilterString] = useState('');
	const [selectedOption, setSelectedOption] = useState<
		DropdownOption | undefined
	>(
		(props.dropdownOptions || []).find(
			(x: DropdownOption) => x.value === props.value
		)
	);
	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		if (isOpen) {
			document.addEventListener('keyup', handleKeyPress, false);
		}
		return () => {
			// eslint-disable-next-line no-use-before-define
			document.removeEventListener('keyup', handleKeyPress, false);
		};
	});

	useEffect(() => {
		const onBlurComponentEvent = (e: MouseEvent) => {
			const clickTarget = (e.target as HTMLElement).nodeName;
			const clickElementName = (e.target as HTMLElement).getAttribute(
				'name'
			);
			const isNotInListElement =
				clickTarget !== 'LI' && clickTarget !== 'UL';
			const isOnBlurClick =
				isOpen &&
				isNotInListElement &&
				clickElementName !== props.name &&
				clickElementName !== 'innerInput';
			if (isOnBlurClick) {
				closeOpenDisplayOptions();
			}
		};
		if (isOpen) {
			document.addEventListener('mousedown', onBlurComponentEvent);
		}
		return () =>
			document.removeEventListener('mousedown', onBlurComponentEvent);
	}, [isOpen, props.name]);

	//Animations must be in the component here to access props
	const listTransition = {
		type: 'spring',
		ease: 'anticipate',
		duration: 0.25,
	};

	const componentProps = definedProps(props);
	const dropdownOptions = componentProps.dropdownOptions as DropdownOption[];
	/**
	 *  Close the opened Display Options
	 * @param selected
	 */
	const closeOpenDisplayOptions = (selected?: DropdownOption) => {
		setValue(selected ? selected.value : selectedOption?.value);
		setFilteredOptions(props?.dropdownOptions);
		setIsOpen(false);
		setSelectedIndex(-1);
		setFilterString('');
		if (selected) {
			setSelectedOption(selected);
		}
	};
	const display =
		componentProps.preferDisplayLength === 'long'
			? 'longDisplay'
			: 'display';

	const handleKeyPress = (e: KeyboardEvent) => {
		e.stopImmediatePropagation();
		const options = filteredOptions || [];
		switch (e.key) {
			case 'Enter': {
				if (isOpen && selectedIndex > -1) {
					handleSelect(options[selectedIndex]);
				}
				break;
			}
			case 'Escape':
				closeOpenDisplayOptions();
				break;
			case 'ArrowLeft':
			case 'ArrowUp':
				setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : 0);
				break;
			case 'ArrowRight':
			case 'ArrowDown':
				setSelectedIndex(
					selectedIndex < options.length - 1
						? selectedIndex + 1
						: options.length - 1
				);
				break;
			case 'Backspace':
				if (options.length > 1) {
					setSelectedIndex(0);
				}
				break;
			default:
				break;
		}
	};
	/**
	 * Handle onClick event
	 * @param e
	 * @returns
	 */
	const onHandleClick = () => {
		if (componentProps.disabled) {
			return;
		}
		setIsOpen(!isOpen);
		setFilterString('');
	};

	const handleSelect = (option: DropdownOption) => {
		if (option.value === selectedOption?.value) {
			setSelectedOption(undefined);
		} else {
			closeOpenDisplayOptions(option);
		}
	};

	const renderOptions = (options?: DropdownOption[]) => {
		return (options || []).map((option: DropdownOption, idx: number) => (
			<StyledOption
				data-id={option.display}
				key={option.value}
				selected={
					option.value === selectedOption?.value ||
					selectedIndex === idx
				}
				onClick={() => handleSelect(option)}
			>
				{option[display]}
			</StyledOption>
		));
	};
	/**
	 *  Handle Onchage event
	 * @param e
	 * @returns
	 */
	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const filterValue = e.target.value;
		if (filterValue.length === 0) {
			setFilteredOptions(dropdownOptions);
			setFilterString('');
			return;
		}
		const filtered = dropdownOptions.filter((ddo) => {
			const terms = ddo.display.toLowerCase().split(' ');
			// search criteria if the letter is in the first name or last name make the match
			// or if an substring as part of the options
			const term1 = terms[0]?.trim() || '';
			const term2 = terms[1]?.trim() || '';
			return (
				term1?.startsWith(e.target.value.toLowerCase()) ||
				term2?.startsWith(e.target.value.toLowerCase()) ||
				(ddo?.display?.toLowerCase() || '').includes(
					e.target.value.toLowerCase()
				)
			);
		});
		// If no results display messages no match found
		if (filtered.length === 0) {
			filtered.push({
				value: '',
				display: t('web.shared.search.noMatchFound', {
					entity: t('web.shared.providers').toLowerCase(),
				}),
			});
		}
		setFilteredOptions(filtered);
		setFilterString(e.target.value);
		if (!isOpen) {
			setIsOpen(true);
		}
	};
	const placeholder = !componentProps.value
		? ((componentProps.placeholder || componentProps.label) as string)
		: '';
	// Display value in input
	const displayValue = () => {
		if (isOpen) {
			return filterString;
		}
		if (selectedOption) {
			// Prevent the no match results won't be selected
			return selectedOption.value ? selectedOption[display] : placeholder;
		}
		return placeholder;
	};

	return (
		<>
			<StyledSelectField
				data-id={props.name}
				disabled={props.disabled}
				expand={props.value}
				error={!!error.error}
			>
				<StyledInnerInput
					tabIndex={0}
					name='innerInput'
					onChange={onChange}
					onBlur={field.onBlur}
					onClick={onHandleClick}
					disabled={props.disabled}
					value={displayValue()}
				/>
				<StyledArrow onClick={onHandleClick}>
					{isOpen ? (
						<Icon
							icon={icons.ArrowUp}
							title={t`web.shared.forms.closeDropdown`}
						/>
					) : (
						<Icon
							icon={icons.ArrowDown}
							title={t`web.shared.forms.openDropdown`}
						/>
					)}
				</StyledArrow>
			</StyledSelectField>
			<AnimatePresence>
				{isOpen && (
					<StyledList
						tabIndex={1}
						initial='initial'
						animate='in'
						exit='out'
						variants={listVariants(props.dropUpSpace)}
						transition={listTransition}
						data-id={props.name + '_options'}
						className={`typeaheadselect_styled_list_${
							props.name || ''
						}`}
					>
						{renderOptions(filteredOptions)}
					</StyledList>
				)}
			</AnimatePresence>
		</>
	);
};

export { TypeAheadSelect };
type StyledSelectFieldProps = {
	expand: boolean;
	disabled?: boolean;
	error: boolean;
};
const StyledSelectField = styled.div<StyledSelectFieldProps>(
	({ expand, error, disabled, theme: { color } }) => `
	position: relative;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	background: ${disabled ? color.formDisabledBg : color.white};
	box-sizing: border-box;
	padding: 0 23px;
	color: ${disabled ? color.formTextDisabled : color.formText};
	font-size: 16px;
	border: 1px solid #D9D8DC;
	transition: 0.2s ease all;
	border: 1px solid ${error ? color.formError : color.inputBorder};
	padding-top: ${expand ? '20px' : '0px'};
	justify-content: space-between;

	&:hover {
		border: 1px solid ${disabled ? color.inputBorder : color.inputHover};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
	}

	&:focus {
		border: 1px solid ${color.inputHover};
		outline: none;
	}
	`
);

const StyledInnerInput = styled.input(
	({ disabled, theme: { color } }) => `
	width:100%;
	height:100%;
	color: ${disabled ? color.formTextDisabled : color.formText};

	&:focus-visible {
    outline: 0px;
}
`
);

const StyledList = styled(motion.ul)(
	({ theme: { boxShadow, spacing, color } }) => `
	position: absolute;
	top: calc((100% + 32px) * -1);
	left: -1px;
	padding: ${spacing.sm};
	width: calc(100% + 2px);
	max-height: 204px;
	background: white;
	border: 1px solid ${color.inputFocus};
	border-radius: 10px;
	box-shadow: ${boxShadow.standard};
	overflow: auto;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 12px;
	}
	&::-webkit-scrollbar-track {
		-webkit-appearance: none;
		margin: ${spacing.sm} 0;
		background: ${color.formDisabledBg};
		border-radius: 10px;
		box-shadow: inset ${boxShadow.standard};
	}
	&::-webkit-scrollbar-thumb {
		-webkit-appearance: none;
		background: ${color.dropdownItemBorder};
		border-radius: 10px;
	}
`
);
type StyledOptionProps = {
	selected: boolean;
};

const StyledOption = styled.li<StyledOptionProps>(
	({ selected, theme: { color, spacing } }) => `
	padding: ${spacing.sm};
	border-bottom: 1px solid ${color.dropdownItemBorder};
	background: ${selected ? color.kebabLinkHover : 'auto'};

	&:hover {
		background: ${color.kebabLinkHover};
	}

	&:last-of-type {
		border-bottom: none;
	}
`
);

const StyledArrow = styled.div`
	display: flex;
	justify-self: flex-end;
	align-items: center;
	transition: 0.15s ease all;
	height: 100%;
`;
