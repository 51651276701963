import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { MediaQueries } from '../../components/providers/StyleProvider/MediaQueries';
import { P2, Subtitle } from './designSystem/TextComponents';
import { RegulatoryLink } from './RegulatoryLink';
import { PrivacyPolicyLink } from './PrivacyPolicyLink';
import { TermsOfUseLink } from './TermsOfUseLink';

const Footer = (): JSX.Element => {
	const { t } = useTranslation();

	const versionLabel = t`web.shared.footer.versionLabel`;
	return (
		<StyledFooterContainer>
			<StyledLinksContainer>
				<StyledVersion>{`${versionLabel} ${process.env.APPLICATION_VERSION}`}</StyledVersion>
				{' | '}
				<PrivacyPolicyLink />
				{' | '}
				<TermsOfUseLink />
				{' | '}
				<RegulatoryLink />
			</StyledLinksContainer>
			<StyledCopyright>
				<Trans
					i18nKey='web.shared.footer.copyrightText'
					values={{
						copyrightYear: new Date().getFullYear(),
					}}
				/>
			</StyledCopyright>
		</StyledFooterContainer>
	);
};

export { Footer };

const StyledFooterContainer = styled.div(
	({ theme: { color, spacing } }) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 100%;
		height: 64px;
		padding: 0 128px;
		background-color: ${color.footer};
		position: relative;
		gap: ${spacing.sm};
		flex-wrap: wrap;
		@media (${MediaQueries.maxWidth.lg}) {
			padding: ${spacing.sm} ${spacing.xxxl};
		}
	`
);

const StyledLinksContainer = styled(Subtitle)(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-width: 450px;
		flex-wrap: wrap;
		gap: ${spacing.sm};
		@media (${MediaQueries.maxWidth.lg}) {
			width: 100%;
			min-width: unset;
			justify-content: flex-start;
		}
	`
);
const StyledCopyright = styled(P2)(
	({ theme: { color } }) => css`
		margin: 0 0 0 0;
		color: ${color.footerCopyright};
	`
);

const StyledVersion = styled(P2)(
	({ theme: { color } }) => css`
		margin: 0 0 0 0;
		color: ${color.footerCopyright};
	`
);
