import styled, { css } from 'styled-components';

import { P2 } from 'components/shared/designSystem';
import { Chip } from '../../atoms/Chip';

type ResponsesProps = {
	responseText: string;
	score: string;
};

export const ResponsesTestIds = {
	container: 'responses-container',
};

const Responses = ({ responseText, score }: ResponsesProps) => {
	return (
		<ResponsesContainer data-testid={ResponsesTestIds.container}>
			<Chip label={score} />
			<P2>{responseText}</P2>
		</ResponsesContainer>
	);
};

const ResponsesContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
		align-items: center;
	`
);

export { Responses };
