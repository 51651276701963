import { AnalyticsAction, sendEventData } from 'analytics';
import { t } from 'i18n';
import { ExternalLink } from './ExternalLink';
import { URLS } from './constants';

type TermsOfUseLinkProps = {
	termsOfUseUrl?: string;
	text?: string;
	underline?: boolean;
};

export const TermsOfUseLink = ({
	termsOfUseUrl = URLS.termsOfUse,
	text = t`web.shared.terms` as string,
	underline = true,
}: TermsOfUseLinkProps): JSX.Element => {
	return (
		<ExternalLink
			text={text}
			href={termsOfUseUrl}
			onClick={() =>
				sendEventData({ eventType: AnalyticsAction.ViewedTermsOfUse })
			}
			underline={underline}
		/>
	);
};
