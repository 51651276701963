import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { Caption, H3, P1, P2 } from 'components/shared/designSystem';

export const Wrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		max-width: 850px;
		padding: ${spacing.lg} 0;
	`
);

export const Title = styled(H3)(
	({ theme: { colors, spacing, weight } }) => css`
		color: ${colors.gray_10};
		font-weight: ${weight.medium};
		padding: 0 ${spacing.lg};
	`
);

export const LineDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin: ${spacing.md} 0;
	`
);

export const Info = styled.div(
	({ theme: { spacing } }) => css`
		padding: 0 ${spacing.lg};
	`
);

export const Container = styled.div`
	flex-direction: column;
`;

export const CompletionTimeContainer = styled.div`
	width: 100%;
	flex-direction: row;
	display: flex;
`;

export const Description = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.xxs};
		margin: 0 12px;
	`
);

export const DescriptionText = styled(P1)(
	({ theme: { color, weight } }) => css`
		color: ${color.graphTextPrimary};
		font-weight: ${weight.regular};
	`
);

export const DurationValue = styled(H3)(
	({ theme: { color, spacing, weight } }) => css`
		color: ${color.graphTextPrimary};
		font-weight: ${weight.medium};
		padding: ${spacing.sm} 0;
	`
);

export const HeaderText = styled(Caption)(
	({ theme: { color, weight } }) => css`
		color: ${color.graphTextPrimary};
		font-weight: ${weight.bold};
	`
);

export const TimeLimit = styled(P2)(
	({ theme: { color, weight } }) => css`
		color: ${color.graphTextSecondary};
		font-weight: ${weight.light};
	`
);

export const TotalDuration = styled.div(
	({ theme: { spacing } }) => css`
		min-width: 112px;
		align-items: center;
		display: flex;
		justify-content: center;
		border-top-left-radius: ${spacing.sm};
		border-bottom-left-radius: ${spacing.sm};
	`
);

export const Quartile = styled(Caption)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.bold};
		margin-top: 8px;
	`
);

export const SolidDot = styled.div<{ color: string; size: number }>(
	({ color, size }) => css`
		margin-left: 12px;
		min-width: ${size}px;
		min-height: ${size}px;
		border-radius: ${size}px;
		background-color: ${color};
		align-self: flex-start;
		margin-top: 4px;
	`
);

export const Content = styled.div<{ color: string }>(
	({ color, theme: { spacing } }) => css`
		display: flex;
		background-color: ${color};
		padding: ${spacing.xs} 0;
		min-height: 46px;
		align-items: center;
		width: 100%;
	`
);

export const AgeUnavailable = styled(P2)`
	margin-top: 12px;
`;
