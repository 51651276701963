import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MessageEnumItem } from '../../enums/messageEnum';
import { P1 } from './designSystem/TextComponents';

export type InfoMessageProps = {
	messageEnum?: MessageEnumItem;
	hideIcon?: boolean;
	showIf: boolean;
	width?: string;
};

const InfoMessage = ({
	messageEnum,
	hideIcon,
	showIf,
	width,
}: InfoMessageProps): JSX.Element | null => {
	const { t } = useTranslation();
	if (!showIf || !messageEnum) {
		return null;
	}
	const Icon = messageEnum.icon as React.ElementType;

	return (
		<AnimatePresence>
			<StyledInfoMessage
				initial='initial'
				animate='in'
				exit='out'
				data-id='infoMessageBox'
				variants={notificationVariants}
				transition={notificationTransition}
				width={width}
			>
				{!hideIcon && Icon && (
					<Icon title={t`${messageEnum.display}`} />
				)}
				<StyledP1 $_messageEnum={messageEnum}>
					{messageEnum.displayNode ?? messageEnum.display}
				</StyledP1>
			</StyledInfoMessage>
		</AnimatePresence>
	);
};

export { InfoMessage };
type StyledP1Props = { $_messageEnum: MessageEnumItem };

const StyledP1 = styled(P1)<StyledP1Props>(
	({ $_messageEnum, theme: { spacing, color } }) => `
	margin: 0 0 0 ${spacing.sm};
	color: ${
		$_messageEnum.value === 'success'
			? color.textSuccess
			: $_messageEnum.value === 'error'
			? color.alertErrorText
			: color.black
	} !important
	`
);

const StyledInfoMessage = styled(motion.div)<{ width?: string }>(
	({ width }) => `
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${width} || 317px;
`
);

const notificationVariants = {
	initial: {
		opacity: 0,
		x: '-200px',
	},
	in: {
		opacity: 1,
		x: 0,
	},
	out: {
		opacity: 0,
		x: '200px',
	},
};

const notificationTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.4,
};
