import { OrganizationType } from '../generated/graphql';
import { CurrentUser } from '../types';

export const menuFilterEnum = () => {
	return {
		linusOnly: (currentUser: CurrentUser) =>
			currentUser.organizationType === OrganizationType.Linus,
		excludingLinus: (currentUser: CurrentUser) =>
			currentUser.organizationType !== OrganizationType.Linus,
		excludeAll: () => false,
	};
};
