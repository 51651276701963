import styled from 'styled-components';
import { TDropdownElement } from '../interface';
import { Reason } from '@lh/eng-platform-organization-service-rest-client';
import { Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { useTranslation } from 'react-i18next';
import { normalizeRafCode } from '../sharedUtils';

export const SelectedOptionsElementsContainer = (props: {
	selectedOptions: TDropdownElement<Reason>[];
	handleSelect: (option: TDropdownElement<Reason>) => void;
}) => {
	const { t } = useTranslation();
	return (
		<SelectedOptionsContainer data-testid='selection-options-container'>
			{props.selectedOptions.map((element) => (
				<SelectedOptionsElement
					key={element.dropdownOption.value}
					data-testid={`test-option-${element.dropdownOption.value}`}
				>
					<SelectedOptionsElementText
						hasRAFCode={!!element.dataElement.rafCode}
					>
						{element.dropdownOption.value}{' '}
						{element.dropdownOption.display}
					</SelectedOptionsElementText>
					{element.dataElement.rafCode && (
						<SelectedElementRAFContainer>
							RAF: {normalizeRafCode(element.dataElement.rafCode)}
						</SelectedElementRAFContainer>
					)}
					<SelectedOptionsElementClose
						data-testid={`test-option-close-${element.dropdownOption.value}`}
						onClick={() => {
							props.handleSelect(element);
						}}
					>
						<Icon
							icon={icons.Close}
							title={t`web.shared.forms.closeDropdown`}
							width={4}
							height={4}
						/>
					</SelectedOptionsElementClose>
				</SelectedOptionsElement>
			))}
		</SelectedOptionsContainer>
	);
};

const SelectedElementRAFContainer = styled.div(
	({ theme: { color, weight, fontSize } }) => `
	font-size: ${fontSize._10};
	font-weight: ${weight.medium};
	line-height: ${fontSize._14};
	letter-spacing: 0.2px;
	text-align: left;
	color: ${color.bodyTextSecondary};
	margin-right: 20px;
	text-wrap: nowrap;
	align-self: center;
	margin-left: ${fontSize._12};
`
);

const SelectedOptionsContainer = styled.div`
	margin-top: 8px;
`;

const SelectedOptionsElementClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 5px;
	height: 100%;
	top: 0;
	cursor: pointer;
`;

const SelectedOptionsElementText = styled(P1)<{
	hasRAFCode: boolean;
}>(
	({ hasRAFCode, theme: { weight, fontSize } }) => `
	font-size: ${fontSize._12};
	font-weight: ${weight.medium};
	line-height: ${fontSize._14};
	letter-spacing: 0.2px;
	${!hasRAFCode ? `margin-right: ${fontSize._18};` : ''}
	text-transform: uppercase;
`
);
const SelectedOptionsElement = styled(P1)(
	({ theme: { spacing, color } }) => `
	border: 1px solid ${color.chipBorderColor};
	border-radius: ${spacing.xs};
	display: flex;
	padding: ${spacing.xs};
	display: inline-flex;
	max-width: 80%;
	position: relative;
	margin: ${spacing.sm};
    margin-left: 0;
	margin-bottom: 0;
	background: ${color.white};
`
);
