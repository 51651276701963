import { t } from 'i18n';
import * as yup from 'yup';

export type CreateAccountModel = {
	firstName: string;
	lastName: string;
	password: string;
	repeatPassword: string;
	email: string;
};

export const getModel = (params: CreateAccountModel): CreateAccountModel => ({
	firstName: params.firstName,
	lastName: params.lastName,
	password: '',
	repeatPassword: '',
	email: params.email,
});

export const createAccountSchema = yup.object().shape({
	firstName: yup.string().required(t`web.shared.forms.schemas.required`),
	lastName: yup.string().required(t`web.shared.forms.schemas.required`),
	email: yup
		.string()
		.email(t`web.authErrors.invalidEmail`)
		.required(t`web.authErrors.emailRequired`),
	password: yup
		.string()
		.min(8, t`web.authErrors.passwordTooShort`)
		.matches(/[a-z]/, t`web.password.lowercaseRequired`)
		.matches(/[A-Z]/, t`web.password.uppercaseRequired`)
		.matches(/\d/, t`web.password.numberRequired`)
		.matches(
			/[$&+,~_`:#;=?[\]@|{}'<>".^*()%!/\\-]/,
			t`web.authErrors.passwordOneSpecialChar`
		)
		.required(t`web.authErrors.passwordRequired`),
	repeatPassword: yup
		.string()
		.required(t`web.authErrors.passwordRequired`)
		.oneOf(
			[yup.ref('password'), null],
			t`web.authErrors.passwordNotMatching`
		),
});
