import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { Divider } from '@mantine/core';
import { CellContext } from '@tanstack/react-table/';
import { Trans, useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import i18n from 'i18n';

import {
	EndContainer,
	EndOfAssessment,
	FastTableContainer,
	P1Faded,
	P1Med,
	P1Selected,
	QuestionContainer,
	TableContainer,
} from './FastTable.style';
import { Header } from './Header';

export const FAST_TABLE_COLUMNS = [
	{
		accessorKey: 'answers',
		header: () => (
			<P1Med>
				{i18n.t('web.report.fastReport.table.selection').toUpperCase()}
			</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const data = props.getValue<QnrRowData['answers']>();
			if ('totalScore' in data) return null;
			const selected = !!data.value;

			return (
				<QuestionContainer $selected={selected}>
					{selected ? (
						<P1Selected>
							<Trans
								defaults={data.answerText}
								components={{
									b: <></>,
								}}
							/>
						</P1Selected>
					) : (
						<P1Faded>
							<Trans
								defaults={data.answerText}
								components={{
									b: <></>,
								}}
							/>
						</P1Faded>
					)}
				</QuestionContainer>
			);
		},
	},
];

type FastTableProps = {
	data: QnrRowData[];
};

export function FastTable({ data }: Readonly<FastTableProps>) {
	const { t } = useTranslation();

	return (
		<FastTableContainer>
			<Header />
			<TableContainer>
				<SimpleTable<QnrRowData>
					borderColor={theme.colors.gray_60}
					columns={FAST_TABLE_COLUMNS}
					data={data}
					horizontalSpacing={theme.spacing.sm}
					striped={false}
					verticalSpacing={theme.spacing.sm}
				/>
				<EndContainer>
					<Divider color={theme.colors.gray_60} />
					<EndOfAssessment>
						{t('web.report.fastReport.table.end')}
					</EndOfAssessment>
				</EndContainer>
			</TableContainer>
		</FastTableContainer>
	);
}
