import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { useOrganizationStore } from 'store';

export async function getOrganization(
	organizationId: string | null,
	signal?: AbortSignal
) {
	if (!organizationId) {
		throw new Error(
			"useGetOrganizationQuery: organizationId can't be null"
		);
	}

	const service = await getOrganizationService();
	const { data } = await service.getOrganization(
		{ organizationId },
		{ signal }
	);
	return data;
}

export function useOrganizationQuery() {
	const organizationId = useOrganizationStore((state) => state.id);
	return useQuery({
		enabled: !!organizationId,
		meta: {
			errorMessage: 'Error fetching organization',
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.Organization, organizationId],
		queryFn: ({ signal }) => getOrganization(organizationId, signal),
		staleTime: Infinity,
	});
}
