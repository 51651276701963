import { theme } from 'components/providers/StyleProvider/theme';

export function getBackgroundColor(DCRScore: number) {
	const colors = { solid: '', light: '' };

	handleDCR(DCRScore, {
		unanalyzable: () => {
			colors.solid = theme.colors.gray_60;
			colors.light = theme.colors.gray_60;
		},
		_0_1: () => {
			colors.solid = theme.colors.orange_light;
			colors.light = theme.colors.orange_lightest;
		},
		_2_3: () => {
			colors.solid = theme.colors.yellow_light;
			colors.light = theme.colors.yellow_lightest;
		},
		_4_5: () => {
			colors.solid = theme.colors.green_light;
			colors.light = theme.colors.green_lightest;
		},
	});

	return colors;
}

export function handleDCR(
	score: number,
	callback: {
		unanalyzable(): void;
		_0_1(): void;
		_2_3(): void;
		_4_5(): void;
	}
) {
	if (score === -1) return callback.unanalyzable();
	if (inRange(score, 0, 1)) return callback._0_1();
	if (inRange(score, 2, 3)) return callback._2_3();
	if (inRange(score, 4, 5)) return callback._4_5();
}

function inRange(value: number, from: number, to: number): boolean {
	return value >= from && value <= to;
}
