import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { Auth } from 'features/auth-service';
import { ERROR } from 'logging/linusLogger';
import { fetchUtil, prefix } from 's3ApiFetch';

export type S3Handler = {
	response: AxiosResponse;
	type: string;
	processData?: (response: Blob) => Promise<any>;
};

export type S3Segment = {
	segmentResultId: string;
	type: string;
	filename: string;
	processData?: (response: Blob) => Promise<any>;
};

export type S3Response = {
	type: string;
	responseUrl: string;
	data?: any;
};

export type useFetchS3SegmentsProps = {
	segments: S3Segment[];
	blobType?: string;
};

export const useFetchS3Segments = ({
	segments,
	blobType = 'json',
}: useFetchS3SegmentsProps) => {
	const envPrefix = prefix();

	const [s3Responses, setS3Responses] = useState<S3Response[]>([]);

	const handleSegmentReceived = useCallback(
		async ({ response, type, processData }: S3Handler) => {
			const blob = new Blob([response.data], {
				type: blobType,
			});
			const blobUrl = URL.createObjectURL(blob);
			let data = {};
			if (processData) {
				data = await processData(response.data);
			}
			setS3Responses((existingResponses) => [
				...existingResponses,
				{
					responseUrl: blobUrl,
					type,
					data,
				},
			]);
		},
		[]
	);

	useEffect(() => {
		const abortController = new AbortController();
		segments.forEach((segment) => {
			const url = `${envPrefix}s3MetricFiles/${segment.segmentResultId}/${segment.filename}`;
			fetchUtil({
				url: url,
				signal: abortController.signal,
				onSuccess: (response) =>
					handleSegmentReceived({
						response,
						type: segment.type,
						processData: segment.processData,
					}),
				responseType: 'blob',
				auth: Auth,
			}).catch(() =>
				ERROR('Error retrieving the files for segments: ', segments)
			);
		});

		return () => {
			abortController.abort();
		};
	}, [segments.length, envPrefix, handleSegmentReceived]);

	return s3Responses;
};
