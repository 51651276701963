import {
	OrganizationStatus,
	OrganizationType,
	UserOrganizationsRole,
	ValueListItem,
	ValueListType,
} from '@lh/eng-platform-organization-service-rest-client';
import { create } from 'zustand';

export type ValueListContainer = {
	[key in ValueListType]?: ValueListItem[];
};

interface State {
	id: string | null;
	name: string | null;
	type: OrganizationType;
	status: string | null;
	role: Partial<UserOrganizationsRole> | null;
	setId: (id: string) => void;
	setName: (name: string) => void;
	setType: (type: OrganizationType) => void;
	setStatus: (status: OrganizationStatus) => void;
	setRole: (role: Partial<UserOrganizationsRole>) => void;
	reset: () => void;
}

export const useOrganizationStore = create<State>((set) => ({
	id: null,
	name: null,
	type: OrganizationType.Linus,
	status: null,
	role: null,
	setId: (id) => set({ id }),
	setName: (name) => set({ name }),
	setType: (type) => set({ type }),
	setStatus: (status) => set({ status }),
	setRole: (role) => set({ role }),
	reset: () =>
		set({
			id: null,
			name: null,
			type: OrganizationType.Linus,
			status: null,
			role: null,
		}),
}));
