import { AssignmentIssueInput } from '@lh/eng-platform-battery-service-rest-client';

import { useMutation } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';

export async function registerAssignmentIssue(
	assignmentIssueInput: AssignmentIssueInput
) {
	const service = await getBatteryService();
	const { data } = await service.saveAssignmentIssue({
		assignmentIssueInput,
	});
	return data;
}

export function useRegisterAssignmentIssueMutation() {
	return useMutation({
		meta: {
			errorMessage: 'Error registering assignment issue',
		},
		mutationFn: registerAssignmentIssue,
	});
}
