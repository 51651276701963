import { DefaultApiGetAssignmentsRequest } from '@lh/eng-platform-battery-service-rest-client';

import { useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getAssignments(
	searchInput: DefaultApiGetAssignmentsRequest
) {
	if (!searchInput.participantId) {
		throw new Error("useGetAssignmentsQuery: participantId can't be null");
	}

	const batteryService = await getBatteryService();
	const { data } = await batteryService.getAssignments(searchInput);
	return data;
}

export function useAssignmentsQuery(
	searchInput: DefaultApiGetAssignmentsRequest
) {
	return useQuery({
		enabled: !!searchInput.participantId,
		meta: {
			errorMessage: `Error fetching assignments for participant ${searchInput.participantId}`,
		},
		queryKey: [QueryKey.Assignments, searchInput.participantId],
		queryFn: () => getAssignments(searchInput),
		staleTime: Infinity,
	});
}
