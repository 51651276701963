import { useContext } from 'react';

import { METRIC_ITEM_CONCERNS } from 'components/shared/DataTable/schemas/resultTableSchema';
import {
	SortDir,
	useGetBatteryResultsForIndividualQuery,
} from 'generated/graphql';
import { UserContext } from '../../context/UserContext';

export function useGetBatteryResults(participantId: string) {
	const { currentUser } = useContext(UserContext);

	const { data, loading } = useGetBatteryResultsForIndividualQuery({
		variables: {
			participantId: participantId || '',
			connection: {
				filter: {
					AND: [
						{
							key: 'status',
							op: 'in',
							value: 'ANALYSIS_COMPLETE,DATA_COMPLETE,PARTICIPANT_COMPLETE,METRICS_COMPLETE',
						},
					],
				},
				sort: [{ field: 'endTime', direction: SortDir.Desc }],
			},
			metricItemsConnection: {
				filter: {
					AND: [
						{
							key: 'key',
							op: 'equals',
							value: METRIC_ITEM_CONCERNS,
						},
					],
				},
			},
			orgId: currentUser.organizationId,
		},
	});

	return { data, loading };
}
