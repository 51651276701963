import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getRoles(organizationId: string, signal?: AbortSignal) {
	if (!organizationId) {
		throw new Error("useGetRoles: organizationId can't be null");
	}

	const organizationService = await getOrganizationService();
	const { data } = await organizationService.getRolesByOrgId(
		{ organizationId },
		{ signal }
	);
	return data;
}

export function useRolesQuery(organizationId: string) {
	return useQuery({
		enabled: !!organizationId,
		meta: {
			errorMessage: `Error fetching roles by organization id: ${organizationId}`,
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.Roles, organizationId],
		queryFn: ({ signal }) => getRoles(organizationId, signal),
		staleTime: Infinity,
	});
}
