import { t } from 'i18n';

export type Messages = Record<string, string>;
// the values here will eventually be replaced by translation ids.
export const messages: Messages = {
	mutationHookError: t`web.shared.errorMessages.mutationHookError`,
	mutationPayloadError: t`web.shared.errorMessages.mutationPayloadError`,
};

export const ERROR_CODES: Record<string, string> = {
	EXTERNAL_ID_EXISTS: t`web.participant.forms.errors.externalId.invalid`,
};
