import { Typography } from '@lh/eng-web-mosaic-common';

import { Trans } from 'react-i18next';

type FastScoringDescriptionProps = {
	text: string;
};

export function FASTScoringDescription({
	text,
}: Readonly<FastScoringDescriptionProps>) {
	return (
		<Typography.H4 weight='400'>
			<Trans
				defaults={text}
				components={{
					b: <strong />,
				}}
			/>
		</Typography.H4>
	);
}
