import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { useMemo } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';

import { parseDelayedRecallMetrics } from './parseDelayedRecallMetrics';

enum DelayedRecallFilename {
	DelayedRecall = 'delayed-recall',
}

function buildFilesToFetch(segmentResultId: string): S3Segment[] {
	return [
		{
			filename: 'delayed-recall-audio.mp4',
			segmentResultId,
			type: DelayedRecallFilename.DelayedRecall,
		},
		{
			filename: 'delayed-recall-audio.webm',
			segmentResultId,
			type: DelayedRecallFilename.DelayedRecall,
		},
	];
}

export function useDelayedRecallData(segmentResult: DeepSegmentResult) {
	const data = useMemo(() => {
		return parseDelayedRecallMetrics(segmentResult);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});

	audioFiles.forEach((file) => {
		if (file.type === DelayedRecallFilename.DelayedRecall) {
			data.trial.url = file.responseUrl;
		}
	});

	return data;
}
