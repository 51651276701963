import { useCallback } from 'react';

import { usePreferencesStore } from './preferences/usePreferencesStore';
import { useModalStore } from './useModalStore';
import { useOrganizationStore } from './useOrganizationStore';

export function useStoreReset() {
	const resetOrgStore = useOrganizationStore((state) => state.reset);
	const resetPreferencesStore = usePreferencesStore((state) => state.reset);
	const resetModalStore = useModalStore((state) => state.reset);

	return useCallback(() => {
		resetOrgStore();
		resetPreferencesStore();
		resetModalStore();
	}, []);
}
