import { Typography } from '@lh/eng-web-mosaic-common';

import { ScoreType, ScoreVariant } from '../../types';
import { RichText } from '../RichText';
import { ScoreInterpretationContainer } from './ScoreInterpretation.style';

const TYPE_TO_VARIANT: Record<ScoreType, ScoreVariant> = {
	'not-impaired': 'success',
	impaired: 'danger',
	unanalyzable: 'neutral',
	unknown: 'neutral',
};

type ScoreInterpretationProps = {
	description: string;
	caption?: string | null;
	type?: ScoreType;
};

export function ScoreInterpretation({
	description,
	caption,
	type = 'unknown',
}: Readonly<ScoreInterpretationProps>) {
	const variant = TYPE_TO_VARIANT[type];

	if (type === 'unanalyzable') {
		return (
			<ScoreInterpretationContainer $variant={variant}>
				<Typography.P2 weight='500' testId='no-performance-description'>
					<RichText fontWeight='700'>{description}</RichText>
				</Typography.P2>
			</ScoreInterpretationContainer>
		);
	}

	return (
		<ScoreInterpretationContainer $variant={variant}>
			<Typography.P1>
				<RichText fontWeight='500'>{description}</RichText>
			</Typography.P1>
			{!!caption && <Typography.C>{caption}</Typography.C>}
		</ScoreInterpretationContainer>
	);
}
