import { SegmentEvent as SegmentEventComponent } from './SegmentEvent';
import { icons } from '../../../../enums/icons';

export enum SegmentEventTypeEnums {
	INSTRUCTIONS_REPEATED = 'INSTRUCTIONS_REPEATED',
	SEGMENT_RESTARTED = 'SEGMENT_RESTARTED',
}

type SegmentEventContext = {
	subsegment?: string;
	version?: string;
};

export type SegmentEvent = {
	eventType: SegmentEventTypeEnums;
	timestamp: number;
	segmentType: string;
	context?: SegmentEventContext;
};

export type SegmentEventsProps = {
	segmentEvents: SegmentEvent[];
};

const SegmentEventIconsMap = {
	[SegmentEventTypeEnums.SEGMENT_RESTARTED]: icons.IconRestart,
	[SegmentEventTypeEnums.INSTRUCTIONS_REPEATED]: icons.ActionRepeat,
};

const SegmentEventTextMap = {
	[SegmentEventTypeEnums.SEGMENT_RESTARTED]:
		'web.report.events.activityRestarted',
	[SegmentEventTypeEnums.INSTRUCTIONS_REPEATED]:
		'web.report.events.instructionsRepeated',
};

export const SegmentEvents = ({
	segmentEvents,
}: SegmentEventsProps): JSX.Element => {
	// Moving Activity Restarted to the first index
	segmentEvents.sort((a, b) =>
		a.eventType === SegmentEventTypeEnums.SEGMENT_RESTARTED
			? -1
			: b.eventType === SegmentEventTypeEnums.SEGMENT_RESTARTED
			? 1
			: 0
	);

	return (
		<>
			{segmentEvents.map((segmentEvent) => {
				return (
					<SegmentEventComponent
						icon={SegmentEventIconsMap[segmentEvent.eventType]}
						textKey={SegmentEventTextMap[segmentEvent.eventType]}
						key={segmentEvent.eventType}
					/>
				);
			})}
		</>
	);
};
