import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { config } from 'config';
import { icons } from 'enums/icons';

import { Icon } from '../shared/designSystem/Icon';
import { H2, ButtonSm } from 'components/shared/designSystem';

function getDomainUrl(params: URLSearchParams) {
	const type = params.get('ot');

	switch (type) {
		case 'm':
			return config.managementDomainUrl;
		case 'r':
			return config.researchDomainUrl;
		case 'c':
		default:
			return config.clinicalDomainUrl;
	}
}

const AccessDenied = (): JSX.Element | null => {
	const { t } = useTranslation();
	const { search } = useLocation();
	const urlParams = new URLSearchParams(search);
	const domainUrl = getDomainUrl(urlParams);

	// Special Case - deactivated users HCD-1079
	const reason = urlParams.get('reason') ?? '';
	const messageBody =
		reason === 'deactivated'
			? t('web.shared.accessDenied.deactivatedBody')
			: t('web.shared.accessDenied.body');

	return (
		<StyledPage>
			<StyledFormContainer>
				<Icon icon={icons.WarningRound} />
				<StyledH2 data-testid={'access-denied-txt-title'}>
					{t`web.shared.accessDenied.title`}
				</StyledH2>
				<StyledBodyText data-testid={'access-denied-txt-body'}>
					{messageBody}
				</StyledBodyText>
				<StyledButtonContainer>
					<ButtonSm
						data-testid={'access-denied-btn-redirect'}
						primary={true}
						text={t`web.login.title`}
						onClick={() => {
							window.open(
								`${domainUrl}/auth/login`,
								domainUrl.includes(window.location.hostname)
									? '_self'
									: '_blank'
							);
						}}
					/>
				</StyledButtonContainer>
				<StyledLogoWrapper>
					<StyledLogo>
						<Icon icon={icons.Logo} title={t`web.shared.logo`} />
					</StyledLogo>
				</StyledLogoWrapper>
			</StyledFormContainer>
		</StyledPage>
	);
};

const StyledPage = styled.div(
	({ theme: { spacing } }) =>
		`
	display: flex;
	justify-content: center;
	padding: ${spacing.xxxl}
`
);

const StyledFormContainer = styled.div(
	({ theme: { color, boxShadow, spacing } }) => `
		justify-content: center;
		align-content: center;
		text-align: center;
		width: 548px;
		height: auto;
		padding: ${spacing.xxl} ${spacing.xl};
		background: ${color.white};
		border-radius: ${spacing.xxl} 0 ${spacing.xxl} ${spacing.xxl};
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: ${boxShadow.standard};
	`
);

const StyledH2 = styled(H2)(
	({ theme: { fontSize, color, spacing } }) => `
        font-size: '${fontSize._32}';
        line-height: 40px;
        -webkit-letter-spacing: 0.2px;
        -moz-letter-spacing: 0.2px;
        -ms-letter-spacing: 0.2px;
        letter-spacing: 0.2px;
        font-weight: 400;
        color: ${color.textBlue};
        padding-top: ${spacing.xl};
        font-weight: 600;
        margin: 0;
	`
);

const StyledBodyText = styled.div(
	({ theme: { spacing, fontSize, color } }) => `
		color: ${color.textInfo};
		padding-top: ${spacing.md};
		font-size: ${fontSize._16};
		line-height: 19px;
		-webkit-letter-spacing: 0.5px;
		-moz-letter-spacing: 0.5px;
		-ms-letter-spacing: 0.5px;
		letter-spacing: 0.5px;
	`
);

const StyledButtonContainer = styled.div(
	({ theme: { spacing } }) =>
		`
		display: flex;
		justify-content: center;
		margin-top: ${spacing.xl};
	`
);

const StyledLogoWrapper = styled.div(
	() => `
	`
);

const StyledLogo = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.xxl};
`
);

export { AccessDenied };
