import { useEffect } from 'react';

import { ValueListType } from '@lh/eng-platform-organization-service-rest-client';

import { useOrganizationQuery, useValueListItemsQuery } from 'api/organization';

import {
	INITIAL_VALUE_LISTS,
	usePreferencesStore,
} from './usePreferencesStore';

export function usePreferences() {
	const { data: organization = null } = useOrganizationQuery();
	const setPreferences = usePreferencesStore((state) => state.setPreferences);
	const valueListsResults = useValueListItemsQuery(organization);

	useEffect(() => {
		const done = valueListsResults.every(
			(result) => result.isSuccess === true
		);

		if (!done) {
			return;
		}

		if (organization) {
			const { features, participantLanguages, cdsConcernConfigurations } =
				organization.preferences;

			const { defaultUserLocale, defaultTimezone, dateFormat } =
				organization;

			const languages = participantLanguages?.slice();

			const lists = INITIAL_VALUE_LISTS;
			lists[ValueListType.ParticipantLanguage] = languages;

			for (const result of valueListsResults) {
				if (!result.data) {
					continue;
				}

				lists[result.data.type] = result.data.items;
			}

			setPreferences({
				features,
				cdsConcerns: cdsConcernConfigurations,
				lists,
				defaultUserLocale,
				defaultTimezone,
				dateFormat,
			});
		}
	}, [organization, valueListsResults]);
}
