import { t } from 'i18n';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import { VictoryLabelProps } from 'victory';

import { LinusTooltip, OnPopupAlign } from 'components/shared/LinusTooltip';
import { P2 } from 'components/shared/designSystem/TextComponents';
import { Icon } from 'components/shared/designSystem/Icon';
import { icons } from 'enums/icons';

function Label({ text }: { text: string }) {
	const result = text.split(/\r?\n/);

	return (
		<LabelContainer>
			{result.map((item: any) => (
				<span>{item}</span>
			))}
		</LabelContainer>
	);
}

interface Props extends VictoryLabelProps {
	x?: number;
	y?: number;
	text?: string;
	slideDataX: number;
	index?: number;
	tooltipKeys?: string[];
}
export function TickLabelWithTooltips({
	x,
	y,
	text,
	index,
	slideDataX,
	tooltipKeys,
}: Props) {
	const [isTooltipAbove, setTooltipAbove] = useState(true);

	if (!tooltipKeys || index === undefined) {
		return null;
	}

	function handlePopupAlign(
		_: Parameters<OnPopupAlign>[0],
		align: Parameters<OnPopupAlign>[1]
	) {
		// check if overlay position is bottom
		if (align.points && align.points[1].includes('t')) {
			setTooltipAbove(false);
		} else {
			setTooltipAbove(true);
		}
	}

	const notchPlacement =
		index === 0 && tooltipKeys !== undefined
			? 'left'
			: index === tooltipKeys.length - 1
			? 'right'
			: 'middle';

	return (
		<svg
			x={x}
			y={y}
			overflow={'visible'}
			transform={`translate(${slideDataX})`}
		>
			<g transform={`translate(${slideDataX * 3.2})`}>
				<foreignObject x={0} y={-6} height='100%' width='25%'>
					<Container>
						<Label text={text as string} />
						<LinusTooltip
							onPopupAlign={handlePopupAlign}
							placement={
								index === 0
									? 'bottomLeft'
									: index === tooltipKeys.length - 1
									? 'bottomRight'
									: 'bottom'
							}
							overlay={
								<OverlayContainer>
									<Notch
										placement={notchPlacement}
										bottom={isTooltipAbove}
									/>

									<OverlayTitle>
										{t(`${tooltipKeys[index]}.term`)}
									</OverlayTitle>
									<OverlayText>
										{t(`${tooltipKeys[index]}.definition`)}
									</OverlayText>
								</OverlayContainer>
							}
							tooltipIcon={
								<Icon
									icon={icons.InfoOutlined}
									iconWidth={24}
									iconHeight={24}
								/>
							}
						/>
					</Container>
				</foreignObject>
			</g>
		</svg>
	);
}

const OverlayContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10px;
	gap: 17px;
	max-width: 314px;
	border-radius: 6px;
`;

const LabelContainer = styled.span(
	({ theme: { fontSize } }) => css`
		display: flex;
		flex-direction: column;
		overflow: visible;
		font-size: ${fontSize._12};
		line-height: ${fontSize._12};
	`
);

const OverlayTitle = styled(P2)(
	({ theme: { fontSize, weight } }) => css`
		font-size: ${fontSize._14};
		line-height: 17px;
		text-align: left;
		font-weight: ${weight.medium};
		color: white;
	`
);

const OverlayText = styled(P2)(
	({ theme: { fontSize, weight } }) => css`
		font-size: ${fontSize._12};
		line-height: 17px;
		font-weight: ${weight.regular};
		text-align: left;
		color: white;
	`
);

function setVerticalPlacement(bottom: boolean) {
	return bottom ? 'top: -4px;' : 'bottom: -4px;';
}

function setHorizontalPlacement(placement: 'left' | 'right' | 'middle') {
	if (placement === 'left') {
		return 'left: 5px;';
	}

	if (placement === 'right') {
		return 'right: 5px;';
	}
	return 'right: calc(50% - 8px)';
}

const Notch = styled.div<{
	placement: 'left' | 'right' | 'middle';
	bottom: boolean;
}>`
	position: absolute;
	height: 16px;
	width: 16px;
	background-color: #676671;
	transform: rotate(45deg);
	border-radius: 2px;

	${({ bottom }) => setVerticalPlacement(bottom)}
	${({ placement }) => setHorizontalPlacement(placement)}
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	overflow: visible;
`;
