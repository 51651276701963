import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { SegmentType } from '../../../../generated/graphql';
import { H2, H3, P1 } from '../../../shared/designSystem/TextComponents';
import { useCDS } from '../../../shared/hooks/useCDS';
import { FormattedSegmentResults } from '../../cognitiveEvalParseData/cognitiveEvalParseData';
import { Concerns } from '../Concerns/Concerns';
import { Warning } from '../Warning/Warning';

interface StyledRecallScoreTagProps {
	backgroundColor: string;
	color: string;
}

export type RecallSubscoreProps = {
	title: string;
	recallData?: FormattedSegmentResults;
	showConcerns?: boolean;
};

export const calculateDCRWarning = (
	transcriptionLength: string | number | undefined
): boolean => {
	return Number(transcriptionLength) > 20; //NaN value comp is always false
};

export const RecallSubscore = ({
	title,
	recallData,
	showConcerns,
}: RecallSubscoreProps): JSX.Element => {
	const { t } = useTranslation();
	const cdsEnabled = useCDS();
	const theme = useTheme();

	const correctWordCount = Number(
		recallData?.metricItems?.correct_words_count?.value || 0
	);
	const expectedWordCount = Number(
		recallData?.metricItems?.expected_words_count?.value || 0
	);
	const score = expectedWordCount
		? Number(
				Math.floor(
					(correctWordCount / expectedWordCount) * 100
				).toFixed(0)
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  )
		: 0;

	const backgroundColor =
		score <= 33
			? theme.color.scoringRowBgAlertError
			: score <= 66
			? theme.color.scoringRowBgAlertWarning
			: theme.color.scoringRowBgSuccess;

	const color =
		score <= 33
			? theme.color.graphTextAlert
			: score <= 66
			? theme.color.graphYellowDarkest
			: theme.color.graphGreenDarkest;

	const hasConcerns = showConcerns && correctWordCount <= 1;
	const showWarning = calculateDCRWarning(
		recallData?.metricItems?.transcription_length?.value
	);

	const isDelayedRecall =
		recallData?.segmentType === SegmentType.RecallDelayed;
	const concerns = isDelayedRecall
		? [t`web.report.segmentUtils.verbalMemory`]
		: [t`web.report.segmentUtils.workingMemory`];

	return (
		<StyledSubscoreContainer>
			<StyledTitle>{title}</StyledTitle>
			<StyledRecallScoreTag
				backgroundColor={backgroundColor}
				color={color}
			>
				{`${correctWordCount}/${expectedWordCount}`}
			</StyledRecallScoreTag>
			<StyledSubtitle>
				<Trans
					i18nKey={'web.report.recall.subscore.correctWordsLabel'}
				/>
			</StyledSubtitle>
			<StyledWarningContainer>
				{!cdsEnabled && hasConcerns && <Concerns concerns={concerns} />}
				{showWarning && <Warning />}
			</StyledWarningContainer>
		</StyledSubscoreContainer>
	);
};

const StyledTitle = styled(H3)(
	({ theme: { fontSize, weight } }) => `
		font-size: ${fontSize._21}	;
		font-weight: ${weight.regular};
	`
);

const StyledSubscoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 290px;
	margin: 0 15px;
`;

const StyledWarningContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const StyledRecallScoreTag = styled(H2)<StyledRecallScoreTagProps>(
	({ theme: { weight, spacing }, backgroundColor, color }) => `
		line-height: 44px;
		font-weight: ${weight.medium};
		background-color: ${backgroundColor};
		color: ${color};
		padding: 4px 12px;
		margin-top: 19px;
		margin-bottom: 16px;
		border-radius: ${spacing.sm};
	`
);

const StyledSubtitle = styled(P1)(
	({ theme: { color } }) => `
		color: ${color.graphTextPrimary};
		margin-bottom: 19px;
	`
);
