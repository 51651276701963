import { useContext } from 'react';
import styled, { css } from 'styled-components';

import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { EnumItem } from '../../../enums/enumeration';
import { ButtonSm } from '../designSystem/ButtonSm';
import { Icon } from '../designSystem/Icon';
import { H4 } from '../designSystem/TextComponents';
import { ShowIfAuthorized } from '../ShowIfAuthorized';
import { UserContext } from 'components/context';

interface HeaderProps {
	title: string;
	titleIcon?: EnumItem;
	buttonText?: string;
	dataId?: string;
	onHeaderButtonClick?: (event: React.MouseEvent) => void;
	operations?: OperationToken[];
	buttonIcon?: EnumItem;
	headerColor?: string;
}

const DataTableHeader = ({
	title,
	titleIcon,
	buttonText,
	dataId,
	onHeaderButtonClick,
	buttonIcon,
	operations = [],
	headerColor,
}: HeaderProps): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	return (
		<StyledHeaderContainer>
			<StyledTitle
				headerColor={headerColor}
				id={title.replace(/\s/g, '-').toLowerCase()}
			>
				{titleIcon && (
					<StyledIcon>
						<Icon
							icon={titleIcon}
							title={title}
							iconWidth={32}
							iconHeight={32}
						/>
					</StyledIcon>
				)}
				{title}
			</StyledTitle>
			<StyledHeaderContent>
				{onHeaderButtonClick && (
					<ShowIfAuthorized
						operations={operations}
						acceptEmpty={!operations || !operations.length}
					>
						<ButtonSm
							onClick={onHeaderButtonClick}
							primary={true}
							text={buttonText}
							dataId={dataId}
							dataTestId={dataId}
							IconLeft={buttonIcon ? buttonIcon : undefined}
							disabled={!currentUser?.organizationValueLists}
						/>
					</ShowIfAuthorized>
				)}
			</StyledHeaderContent>
		</StyledHeaderContainer>
	);
};

export { DataTableHeader };

const StyledHeaderContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: ${spacing.md} 0;
	`
);
// TODO: trying to use styled(Icon) does not honor
// the styling here.
const StyledIcon = styled.div(
	({ theme: { spacing } }) => css`
		margin-right: ${spacing.sm};
	`
);
type StyledTitleProps = {
	headerColor: string | undefined;
};
const StyledTitle = styled(H4)<StyledTitleProps>(
	({ headerColor, theme: { spacing, weight } }) => css`
		display: flex;
		align-items: center;
		font-weight: ${weight.medium};
		padding: 0 0 0 ${spacing.md};
		color: ${headerColor};
	`
);
const StyledHeaderContent = styled.div`
	display: flex;
	justify-content: space-around;
	align-content: center;
`;
