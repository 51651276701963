import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Memoize } from 'helpers/Memoize';

const queryClient = new QueryClient();

interface Props {
	children: React.ReactNode;
	storybook?: boolean;
	test?: boolean;
}

export function ReactQueryProvider({
	children,
	storybook = false,
	test = false,
}: Readonly<Props>) {
	return (
		<QueryClientProvider client={queryClient}>
			<Memoize>{children}</Memoize>
			{!storybook && !test && (
				<ReactQueryDevtools initialIsOpen={false} />
			)}
		</QueryClientProvider>
	);
}
