import { useTranslation } from 'react-i18next';

import { LinusModal } from 'components/shared/LinusModal';
import { ButtonSm } from 'components/shared/designSystem';
import { DotThresholds } from './DotThresholds';

import { ButtonsContainer, Heading } from './ScoringDetailsModal.styles';

type ScoringDetailsModalProps = {
	readonly title: string;
	readonly heading: string;
	readonly greenText: string;
	readonly yellowText: string;
	readonly redText: string;
	readonly onClose: () => void;
};

export function ScoringDetailsModal({
	title,
	heading,
	greenText,
	yellowText,
	redText,
	onClose,
}: ScoringDetailsModalProps) {
	const { t } = useTranslation();

	return (
		<LinusModal title={title} width='636px' onClose={onClose}>
			<Heading>{heading}</Heading>
			<DotThresholds
				greenText={greenText}
				yellowText={yellowText}
				redText={redText}
			/>
			<ButtonsContainer>
				<ButtonSm
					primary
					text={t`web.shared.close`}
					width='160px'
					onClick={onClose}
				/>
			</ButtonsContainer>
		</LinusModal>
	);
}
