// GENERATED BY ./scripts/iconScript.ts

type IconProps = {
	title?: string;
	width?: string;
	height?: string;
	color?: string;
	rotate?: number;
};

export const CheckmarkInternal = ({
	title,
	width = '24',
	height = '24',
	color = '#676671',
	rotate = 0,
}: IconProps): JSX.Element => {
	return (
		<svg
			viewBox='0 0 18 18'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='CheckmarkInternal-svg-component'
			transform={`rotate(${rotate})`}
			fill={color}
			width={width}
			height={height}
		>
			<title>{title ? title : 'CheckmarkInternal'}</title>
			<path
				d='M13.8817 6.32297C14.0601 6.11217 14.0338 5.79668 13.823 5.61831C13.6122 5.43994 13.2967 5.46623 13.1183 5.67703L7.99754 11.7288L5.37963 8.67461C5.19992 8.46494 4.88427 8.44066 4.6746 8.62037C4.46494 8.80009 4.44066 9.11573 4.62037 9.3254L7.62037 12.8254C7.71572 12.9366 7.85507 13.0005 8.00159 13C8.14811 12.9995 8.28705 12.9348 8.38169 12.823L13.8817 6.32297Z'
				fill='current'
			/>
		</svg>
	);
};
