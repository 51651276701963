import { PaginatedPatients } from '@lh/eng-platform-subject-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { getOptionalFields } from 'api/organization';
import { usePatientQuery } from 'api/patient';
import { QueryKey } from 'api/query';
import { ArchivePatientConfirmation } from 'components/patients/ArchivePatient/ArchivePatientConfirmation';
import { LinusModalDialog } from 'components/shared/LinusModalDialog';
import { icons } from 'enums/icons';
import { gender as genderEnum } from 'enums/participantEnums/gender';
import { handedness as handednessEnum } from 'enums/participantEnums/handedness';
import { sex as sexEnum } from 'enums/participantEnums/sex';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { FeatureType, OptionalFields } from 'generated/graphql';
import { formatPhoneNumber } from 'utils/phone';
import { truncateString } from 'utils/stringUtils';
import { utcDateFormatter } from '../../stringStrategy/dateFormatStringStrategy';
import { UserContext } from '../context/UserContext';
import { LinusModal } from '../shared/LinusModal';
import { Avatar } from '../shared/avatar/Avatar';

import { EditParticipantInfoForm } from './EditParticipantInfoForm';
import {
	HeaderRow,
	Item,
	Label,
	Link,
	List,
	Separator,
	StyledAvatar,
	StyledH3,
	Text,
	Wrapper,
	participantInfoTransition,
	participantInfoVariants,
} from './ParticipantInfo.style';

export type ParticipantInfoProps = {
	participantId: string;
	dateFormat: string;
	isTest?: boolean;
};

const ParticipantInfo = ({
	participantId,
	dateFormat,
	isTest = false,
}: ParticipantInfoProps): JSX.Element | null => {
	const archivePatientFlag = useFeatureFlag(FeatureFlags.ArchivePatient);
	const theme = useTheme();
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const {
		data: patientData,
		isLoading,
		refetch: refetchPatient,
	} = usePatientQuery(participantId, currentUser.organizationId);

	const [isEditParticipantModalOpen, setIsEditParticipantModalOpen] =
		useState(false);
	const [isArchiveConfirmationModalOpen, setIsArchiveConfirmationModalOpen] =
		useState(false);
	const [
		isArchivedSuccessfullyModalOpen,
		setIsArchivedSuccessfullyModalOpen,
	] = useState(false);
	const [isCannotArchiveModalOpen, setIsCannotArchiveModalOpen] =
		useState(false);
	const [optionalFields, setOptionalFields] = useState<OptionalFields[]>([]);

	useEffect(() => {
		getFields().catch(() => {
			// Used just for testing purposes
			return {};
		});
		refetchPatient();
	}, []);

	const onEditParticipantInfo = () => {
		setIsEditParticipantModalOpen(true);
	};

	const handleCancel = () => {
		setIsEditParticipantModalOpen(false);
		setIsArchiveConfirmationModalOpen(false);
		setIsArchivedSuccessfullyModalOpen(false);
		setIsCannotArchiveModalOpen(false);
	};

	const handleArchive = () => {
		setIsEditParticipantModalOpen(false);
		setIsArchiveConfirmationModalOpen(true);
	};

	const handleSuccessArchive = () => {
		setIsArchiveConfirmationModalOpen(false);
		setIsArchivedSuccessfullyModalOpen(true);
		queryClient.setQueryData<PaginatedPatients>(
			[QueryKey.Patients, currentUser.organizationId],
			(data) => {
				if (!data) {
					return;
				}
				return {
					...data,
					totalCount: +data.totalCount - 1,
				};
			}
		);
	};

	const handleCannotArchive = () => {
		setIsArchiveConfirmationModalOpen(false);
		setIsCannotArchiveModalOpen(true);
	};

	const handleArchivedSuccessfully = () => {
		handleCancel();
		navigate('/patients');
	};

	async function getFields() {
		const optionalFields = await queryClient.fetchQuery({
			queryKey: [QueryKey.OptionalFields, currentUser.organizationId],
			queryFn: () => getOptionalFields(currentUser.organizationId),
			staleTime: Infinity,
		});
		if (optionalFields) {
			setOptionalFields(optionalFields);
		}
	}

	function handleEditParticipantSubmit() {
		refetchPatient();
		handleCancel();
	}

	if (isLoading || !patientData) {
		return null;
	}

	const displayBirthdate = utcDateFormatter(
		patientData?.birthDate || '',
		dateFormat
	);

	//Get display values from enums
	const displayGender = patientData?.gender
		? genderEnum.fromValue(patientData.gender)?.display
		: undefined;
	const displayEthnicity = patientData?.ethnicity?.map((x, i) => {
		const display = x.displayKey ? t(x.displayKey) : x.display || '-';
		const ethnicityList = patientData?.ethnicity || [];
		return (
			<span key={`ethnicity-${x.id}`}>
				{`${display}${i < ethnicityList.length - 1 ? ', ' : ''}`}
				<br />
			</span>
		);
	});
	const displayRace = patientData?.race?.map((x, i) => {
		const display = x.displayKey ? t(x.displayKey) : x.display || '-';
		const raceList = patientData?.race || [];
		return (
			<span key={`race-${x.id}`}>
				{`${display}${i < raceList.length - 1 ? ', ' : ''}`}
				<br />
			</span>
		);
	});
	const displayHandedness = handednessEnum.fromValue(
		patientData?.handedness
	)?.display;
	const displaySex = sexEnum.fromValue(
		patientData?.sexAssignedAtBirth
	)?.display;
	const displayLanguage =
		currentUser?.organizationParticipantLanguages.fromValue(
			patientData?.language
		);
	const patientEducation =
		patientData?.education && patientData?.education[0];

	function showPatientInfo() {
		const participantFixtures = [
			{
				dataId: 'participantInfofName',
				label: t`web.patients.forms.firstNameLabel`,
				text: truncateString(patientData?.firstName || '', 12),
				isRequired: true,
			},
			{
				dataId: 'participantInfolName',
				label: t`web.patients.forms.lastNameLabel`,
				text: truncateString(patientData?.lastName || '', 20),
				isRequired: true,
			},
			{
				dataId: 'participantInfoAge',
				label: t`web.patients.forms.ageLabel`,
				text: patientData?.age,
				isRequired: true,
			},
			{
				dataId: 'participantInfoDob',
				label: t`web.patients.forms.dateOfBirthLabel`,
				text: displayBirthdate,
				isRequired: true,
			},
			{
				dataId: 'participantInfoLanguage',
				label: t`web.patients.forms.languageLabel`,
				text: t(displayLanguage?.display),
				isRequired: true,
			},
			{
				dataId: 'participantInfoEducation',
				label: t`web.patients.forms.educationLevelLabel`,
				text: patientEducation?.displayKey
					? t(patientEducation.displayKey)
					: patientEducation?.display,
				isRequired: true,
			},
			{
				dataId: 'participantInfoHandedness',
				label: t`web.patients.forms.dominantHandLabel`,
				text: displayHandedness,
				isRequired: true,
			},
			{
				dataId: 'participantInfoSex',
				label: t`web.patients.forms.sexLabel`,
				text: displaySex,
				isRequired: true,
			},
			{
				dataId: 'participantInfoGender',
				label: t`web.patients.forms.genderLabel`,
				text: displayGender,
				isRequired: isTest ?? false,
				fieldName: OptionalFields.Gender,
			},
			{
				dataId: 'participantInfoRace',
				label: t`web.patients.forms.raceLabel`,
				text: displayRace || '-',
				isRequired: isTest ?? false,
				fieldName: OptionalFields.Race,
			},
			{
				dataId: 'participantInfoEthnicity',
				label: t`web.patients.forms.ethnicityLabel`,
				text: displayEthnicity || '-',
				isRequired: isTest ?? false,
				fieldName: OptionalFields.Ethnicity,
			},
			{
				dataId: 'participantId',
				label: t`web.patients.forms.patientIdLabel`,
				text: patientData?.externalId,
				isRequired: isTest ?? false,
				fieldName: OptionalFields.ExternalId,
			},
			{
				dataId: 'contactEmail',
				label: t`web.patients.forms.contactEmailLabel`,
				text: patientData?.contactEmail,
				isRequired: currentUser.organizationFeatures.includes(
					FeatureType.RemoteAssessment
				),
			},
			{
				dataId: 'contactPhone',
				label: t`web.patients.forms.contactPhoneLabel`,
				text: formatPhoneNumber(patientData?.contactPhone),
				isRequired: currentUser.organizationFeatures.includes(
					FeatureType.RemoteAssessment
				),
			},
			{
				dataId: 'participantInfoNotes',
				label: t`web.patients.forms.notesLabel`,
				text:
					patientData?.notes ||
					t`web.patients.info.notesDefaultValue`,
				isRequired: isTest ?? false,
				fieldName: OptionalFields.Notes,
			},
		];

		return (
			<List>
				{participantFixtures.map((item) => {
					return (
						(item.isRequired ||
							(item.fieldName &&
								optionalFields.includes(
									item.fieldName || ''
								))) && (
							<Item data-id={item.dataId} key={item.dataId}>
								<Label>{item.label}</Label>
								<Text>{item.text}</Text>
							</Item>
						)
					);
				})}
			</List>
		);
	}

	return (
		<>
			<AnimatePresence>
				{isEditParticipantModalOpen && (
					<LinusModal
						title={t`web.patients.info.title`}
						onClose={handleCancel}
						titleIcon={icons.UserSolid}
						titleIconColor={theme.color.iconAddUserSolid}
					>
						<EditParticipantInfoForm
							participant={patientData}
							onArchive={handleArchive}
							onCancel={handleCancel}
							onEditParticipantSubmit={
								handleEditParticipantSubmit
							}
						/>
					</LinusModal>
				)}
				{isArchiveConfirmationModalOpen && archivePatientFlag && (
					<LinusModal
						title={t`web.archivePatientModal.title`}
						titleIcon={icons.RemoveUserSolid}
						titleIconColor={theme.color.alertError}
						onClose={handleCancel}
					>
						<ArchivePatientConfirmation
							type='EditParticipant'
							patientId={patientData?.id || ''}
							onCancel={handleCancel}
							onSuccess={handleSuccessArchive}
							onFail={handleCannotArchive}
							firstName={patientData?.firstName || ''}
							lastName={patientData?.lastName || ''}
							organizationName={currentUser.organizationName}
						/>
					</LinusModal>
				)}
				{isArchivedSuccessfullyModalOpen && archivePatientFlag && (
					<LinusModalDialog
						width='485px'
						title={t`web.archivePatientModal.archived`}
						titleIcon={icons.CheckmarkSolid}
						titleIconColor={theme.color.alertSuccess}
						acceptButtonText={t`web.archivePatientModal.close`}
						acceptButtonCallback={handleArchivedSuccessfully}
						onClose={handleArchivedSuccessfully}
					>
						<Trans
							i18nKey='web.archivePatientModal.success'
							values={{
								firstName: patientData?.firstName,
								lastName: patientData?.lastName,
							}}
							components={{
								b: <strong />,
							}}
						/>
					</LinusModalDialog>
				)}
				{isCannotArchiveModalOpen && archivePatientFlag && (
					<LinusModalDialog
						width='485px'
						onClose={handleCancel}
						title={t`web.archivePatientModal.cannotArchive`}
						titleIcon={icons.AlertShieldSolid}
						titleIconColor={theme.color.alertError}
						acceptButtonText={t`web.archivePatientModal.close`}
						acceptButtonCallback={handleCancel}
					>
						<Trans i18nKey='web.archivePatientModal.takingAssessment' />
					</LinusModalDialog>
				)}
			</AnimatePresence>
			<Wrapper
				data-id='participantInfo'
				data-testid='participantInfo'
				initial='initial'
				animate='in'
				exit='out'
				variants={participantInfoVariants}
				transition={participantInfoTransition}
			>
				<HeaderRow>
					<StyledAvatar>
						<Avatar
							firstName={patientData?.firstName || ''}
							lastName={patientData?.lastName || ''}
						/>
					</StyledAvatar>
					<StyledH3 data-id='participantInfoHeader'>
						{truncateString(patientData?.firstName || '', 12)}{' '}
						{truncateString(patientData?.lastName || '', 12)}
					</StyledH3>
					<Link
						data-id='particpantEditInfo'
						onClick={onEditParticipantInfo}
					>
						{t`web.patients.info.edit`}
					</Link>
				</HeaderRow>
				<Separator />
				{showPatientInfo()}
			</Wrapper>
		</>
	);
};

export { ParticipantInfo };
