import { useMemo } from 'react';

import { parseEnvForAttributes } from './parseEnvForAttributes';
import { FeatureFlags } from './constants';
import { useFeatureFlagsContext } from './useFeatureFlagsContext';

export function useFeatureFlag(flag: FeatureFlags) {
	const client = useFeatureFlagsContext();
	const attributes = parseEnvForAttributes(location.host);

	const value = useMemo(() => {
		const treatment = client?.getTreatment(flag, attributes);
		return treatment === 'on';
	}, [attributes, client, flag]);

	return value;
}
