import { Dispatch, SetStateAction, useRef } from 'react';
import { IReactToPrintProps, useReactToPrint } from 'react-to-print';

// This hook allow us to lazy load our components/data fetching right until we need to print them.
// handlePrint is returned for the parent component to trigger the print dialog (e.g. on a button click)
// callbackRef is returned to pass down to the components we want printed.
// https://github.com/gregnb/react-to-print/issues/469#issuecomment-1060578699

interface UseGetPrintingRefProps extends Partial<IReactToPrintProps> {
	setIsPrinting: Dispatch<SetStateAction<boolean>>;
}

export const useGetPrintingRef = ({
	setIsPrinting,
	...options
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseGetPrintingRefProps): [any, (node: any) => void] => {
	const printingRef = useRef(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onBeforeGetContentResolve = useRef<((value: any) => void) | null>(
		null
	);
	const handlePrint = useReactToPrint({
		content: () => printingRef.current,
		onBeforeGetContent: () => {
			return new Promise((resolve) => {
				setIsPrinting(true);
				onBeforeGetContentResolve.current = resolve;
			});
		},
		onAfterPrint: () => setIsPrinting(false),
		...options,
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const callbackRef = (node: any) => {
		if (node !== null && onBeforeGetContentResolve.current !== null) {
			printingRef.current = node;
			onBeforeGetContentResolve.current(null);
		}
	};

	return [handlePrint, callbackRef];
};
