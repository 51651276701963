import { useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getAssignment(assignmentId: string) {
	if (!assignmentId) {
		throw new Error("useGetAssignmentQuery: assignmentId can't be null");
	}

	const service = await getBatteryService();
	const { data } = await service.getAssignment({ assignmentId });
	return data;
}

export function useAssignmentQuery(assignmentId: string) {
	return useQuery({
		enabled: !!assignmentId,
		meta: {
			errorMessage: `Error fetching assignment ${assignmentId}`,
		},
		queryKey: [QueryKey.Assignment, assignmentId],
		queryFn: () => getAssignment(assignmentId),
		staleTime: Infinity,
	});
}
