import {
	QuestionnaireSpecWithScoringResult,
	QuestionTypes_instruction,
} from '@lh/qnr-svc-rest-client';
import {
	getQuestionnaireScore,
	getQuestionnaireTranslations,
	QnrRowData,
} from 'components/report/CustomQuestionnaire.utils';
import i18n from 'i18n';

export function parseQuestions(
	qnrData: QuestionnaireSpecWithScoringResult
): QnrRowData[] {
	const parsedAnswers: QnrRowData[] = [];

	const qnrTranslations = getQuestionnaireTranslations(qnrData);

	if (qnrData) {
		qnrData.components.forEach((component) => {
			if (component.type !== QuestionTypes_instruction.INSTRUCTION) {
				const { promptI18nKey } = component;
				component.choices.forEach((choice) => {
					if (choice.answer && promptI18nKey) {
						parsedAnswers.push({
							question: qnrTranslations[promptI18nKey],
							answers: {
								grade: choice.answer.grade,
								answerText:
									qnrTranslations[choice.choiceI18nKey],
								value: Number(choice.value),
							},
						});
					}
				});
			}
		});
	}

	// add the last row of the table as the scorer text
	parsedAnswers.push({
		question: i18n.t('web.report.uniqueResponses.total').toUpperCase(),
		answers: {
			totalScore: getQuestionnaireScore(qnrData),
		},
	});
	return parsedAnswers;
}
