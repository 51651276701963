import { Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { InterpretedScore, ScoreTag, ScoreType } from 'components/mosaic';
import { usePercentileText } from 'components/report/usePercentileText';

import { DelayedRecallPerformance } from '../DelayedRecall.types';
import { createDelayedRecallScore } from '../createDelayedRecallScore';

const IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY: Record<number, string> = {
	0: 'web.report.pvltDelayedReport.performance.good',
	1: 'web.report.pvltDelayedReport.performance.bad',
};
const IMPAIRED_TO_SCORE_TYPE: Record<number, ScoreType> = {
	0: 'not-impaired',
	1: 'impaired',
};

type DelayedRecallScoreProps = {
	performance: DelayedRecallPerformance;
	requiresReview: boolean;
};

export function DelayedRecallScore({
	performance,
	requiresReview,
}: Readonly<DelayedRecallScoreProps>) {
	const { t } = useTranslation();

	const percentileText = usePercentileText(performance.percentile);
	const percentileInterpretation = percentileText
		? t('web.report.pvltDelayedReport.performance.value', {
				value: percentileText,
		  })
		: null;
	const scoreValue =
		performance.score.value ?? createDelayedRecallScore('--');

	if (requiresReview) {
		return (
			<Stack maw={445}>
				<InterpretedScore
					interpretationDescription={t(
						'web.report.pvltDelayedReport.rcr'
					)}
					scoreLabel={t('web.report.pvltDelayedReport.scoreLabel')}
					scoreType='unanalyzable'
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	if (performance.impaired > -1) {
		const impairmentInterpretation = t(
			IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY[performance.impaired]
		);
		const scoreType = IMPAIRED_TO_SCORE_TYPE[performance.impaired];

		return (
			<Stack maw={445}>
				<InterpretedScore
					interpretationCaption={percentileInterpretation}
					interpretationDescription={impairmentInterpretation}
					scoreLabel={t('web.report.pvltDelayedReport.scoreLabel')}
					scoreType={scoreType}
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	return (
		<Stack maw={445}>
			<ScoreTag
				label={t('web.report.pvltDelayedReport.scoreLabel')}
				type='unknown'
				value={scoreValue}
			/>
		</Stack>
	);
}
