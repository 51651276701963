import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { CurrentUser } from './types';

export const hasOperation = (
	currentUser: CurrentUser,
	operations: OperationToken[] = []
): boolean => {
	return currentUser?.operations.some((op) => operations.includes(op));
};
