import { useContext, useMemo } from 'react';

import { FeatureType } from '../../../generated/graphql';
import { UserContext } from '../../context/UserContext';

export enum CDSVersions {
	V1 = 'v1',
	V2 = 'v2',
}

export function useCDS() {
	const { currentUser } = useContext(UserContext);

	const features = useMemo(() => {
		return currentUser.organizationFeatures;
	}, [currentUser]);

	if (features) {
		const isV1 = features.includes(FeatureType.ClinicalDecisionSupport);
		const isV2 = features.includes(FeatureType.ClinicalDecisionSupportV2);

		if (isV2) {
			return CDSVersions.V2;
		}

		if (isV1) {
			return CDSVersions.V1;
		}
	}

	return false;
}
