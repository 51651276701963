import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { ReactNode, useContext } from 'react';

import { hasOperation } from '../../hasOperation';
import { UserContext } from './../context/UserContext';

type ShowIfAuthorizedProps = {
	operations: OperationToken[];
	children: ReactNode;
	acceptEmpty?: boolean;
};

export const ShowIfAuthorized = ({
	operations,
	children,
	acceptEmpty,
}: ShowIfAuthorizedProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	return acceptEmpty || hasOperation(currentUser, operations) ? (
		<>{children}</>
	) : null;
};
