import { get } from 'lodash';

import {
	OrganizationStatus,
	OrganizationType,
	UserStatus,
	OperationToken,
	ConcernType,
	ValueListItem,
	UserOrganizationsRole,
} from '@lh/eng-platform-organization-service-rest-client';
import { decodeOrganizationPermissions } from '@lh/eng-platform-rest-common/permissions/operationService';
import { OrganizationPermissionsBitmask } from '@lh/eng-platform-rest-common/authentication/dtos';

import { CurrentUser } from 'types';
import { vlEnumeration } from 'enums/enumeration';
import { locale } from 'enums/locale';
import { participantLanguageToPatientDisplayTransform } from 'utils/valueListUtils';
import { UserWithOrganizationData } from 'api/user/types';

export type GQLCurrentUserArgs = {
	user: UserWithOrganizationData | null | undefined;
	organizationId: any;
	organizationName: any;
	organizationStatus: any;
	organizationType: any;
	organizationRole: Partial<UserOrganizationsRole> | null;
	dateFormat: any;
	defaultTimezone: any;
	defaultUserLocale: any;
	features: any;
	lists: any;
	cdsConcerns: any;
};

export const buildGQLCurrentUserFromRest = ({
	user,
	organizationId,
	organizationName,
	organizationStatus,
	organizationType,
	organizationRole,
	dateFormat,
	defaultTimezone,
	defaultUserLocale,
	features,
	lists,
	cdsConcerns,
}: GQLCurrentUserArgs): CurrentUser => {
	const mappedLangs = vlEnumeration(
		lists.PARTICIPANT_LANGUAGE,
		participantLanguageToPatientDisplayTransform
	);

	// Reduce the value lists down to a single obj and transform the value list items into EnumItems for backwards compat
	const mappedValueLists = Object.entries(lists || []).reduce(
		(prev, [currK, currV]) => {
			return {
				...prev,
				[currK]: vlEnumeration(currV as ValueListItem[]),
			};
		},
		{}
	);

	return {
		// User info
		id: get(user, 'id', ''),
		email: get(user, 'email', ''),
		firstName: get(user, 'firstName', ''),
		lastName: get(user, 'lastName', ''),
		phoneNumber: get(user, 'phoneNumber', ''),
		suffix: get(user, 'suffix', []),
		userStatus: get(user, 'status', UserStatus.Active),
		avatarUrl: get(user, 'avatarUrl', ''),

		// Role info
		roles: get(user, 'roles', []),
		roleId: get(organizationRole, 'roleId', ''),
		operations: decodeCurrentOrgOperations(
			user?.organizationPermissions || {},
			organizationId ?? ''
		),

		// Org info
		organizationDateFormat: dateFormat ?? '',
		organizationDefaultTimezone: defaultTimezone ?? '',
		organizationDefaultUserLocale:
			locale.fromDisplay(defaultUserLocale || '') ?? locale.EnUs,
		organizationFeatures: features ?? [],
		organizationId: organizationId ?? '',
		organizationName: organizationName ?? '',
		organizationParticipantLanguages: mappedLangs,
		organizationStatus:
			(organizationStatus as OrganizationStatus) ??
			OrganizationStatus.Test,
		organizationType: organizationType ?? OrganizationType.Clinical,
		organizationValueLists: mappedValueLists,
		organizationCdsConcerns: (cdsConcerns as ConcernType[]) ?? [],
	};
};

export const decodeCurrentOrgOperations = (
	orgPermissions: { [key: string]: string },
	currentOrgId: string
) => {
	const operationsForRole = Object.entries(orgPermissions).find(
		([k]) => k === currentOrgId
	);

	if (operationsForRole) {
		const ofr: OrganizationPermissionsBitmask = {
			[operationsForRole[0]]: operationsForRole[1],
		};
		return decodeOrganizationPermissions(
			ofr,
			Object.values(OperationToken)
		)[0].operations as OperationToken[];
	}

	return [];
};
