import styled, { css, useTheme } from 'styled-components';

import { Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

type AlertErrorProps = {
	text: string;
};

export function AlertError({ text }: AlertErrorProps) {
	const theme = useTheme();

	return (
		<Container>
			<IconContainer>
				<Icon
					icon={icons.AlertShieldSolid}
					color={theme.color.alertError}
					iconHeight={27}
					iconWidth={24}
				/>
			</IconContainer>
			<P1>{text}</P1>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
`;

const IconContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: center;
		margin-right: ${spacing.md};
		min-width: 40px;
		height: 40px;
	`
);
