import styled, { css } from 'styled-components';
import { Caption } from '../../shared/designSystem/TextComponents';
import { Trans } from 'react-i18next';

type CognitiveEvaluationFooterProps = {
	batteryResultsId: string;
	patientId: string;
};

export const CognitiveEvaluationFooter = ({
	batteryResultsId,
	patientId,
}: CognitiveEvaluationFooterProps): JSX.Element => {
	return (
		<StyledFooterContainer>
			<StyledText>
				<Trans
					i18nKey={'web.report.cognitiveEvaluation.footer.patientId'}
				/>
				: {patientId}
			</StyledText>
			<StyledText>
				<Trans
					i18nKey={
						'web.report.cognitiveEvaluation.footer.batteryResultsId'
					}
				/>
				: {batteryResultsId}
			</StyledText>
		</StyledFooterContainer>
	);
};

const StyledFooterContainer = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 0;
		gap: ${spacing.sm};
	`
);

const StyledText = styled(Caption)(
	({ theme: { weight, color } }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		line-height: 13.5px;
		letter-spacing: 0.2px;
		font-weight: ${weight.medium};
		padding: 5px;
		color: ${color.bodyText};
		text-align: center;
		text-transform: uppercase;
		background: ${color.reportFooterIdsBg};
	`
);
