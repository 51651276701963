import { Patient, Subject } from '@lh/eng-platform-subject-service-rest-client';

import { useContext } from 'react';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useAddRelationship, usePatientQuery } from 'api/patient';
import { useSubjectQuery, useUpdateSubject } from 'api/subject';
import { UserContext } from 'components/context';
import { messages } from 'components/shared/errorMessages';
import { messageEnum } from 'enums/messageEnum';
import { ERROR } from 'logging/linusLogger';
import { useModalStore } from 'store';
import { PartnerInfo } from '../CarePartner.types';
import { modifyPartnerValues } from '../CarePartner.utils';
import { useErrorMessageStore } from '../useErrorMessageStore';

import { CarePartnerCardUi } from './Ui';

interface CarePartnerEditCardProps {
	carePartnerId: string;
	patientId: string;
}

export function CarePartnerEditCard({
	carePartnerId,
	patientId,
}: Readonly<CarePartnerEditCardProps>) {
	const { currentUser } = useContext(UserContext);
	const { mutateAsync: addPatientRelationship } = useAddRelationship();
	const { mutateAsync: updateSubject } = useUpdateSubject();

	const { data: partnerInfo } = useSubjectQuery(
		carePartnerId,
		currentUser.organizationId
	);

	const { data: patientInfo } = usePatientQuery(
		patientId,
		currentUser.organizationId
	);

	const openModal = useModalStore((state) => state.open);
	const closeModal = useModalStore((state) => state.close);

	const setErrorMessage = useErrorMessageStore(
		(state) => state.setErrorMessage
	);

	async function handleAddRelationship(newPartnerInfo: PartnerInfo) {
		if (!patientInfo) {
			ERROR('There is no patientInfo in handleAddRelationship');
			setErrorMessage(messageEnum.error(messages.mutationPayloadError));
			return;
		}

		if (!newPartnerInfo.id) {
			ERROR('There is no newPartnerInfo.id in handleAddRelationship');
			setErrorMessage(messageEnum.error(messages.mutationPayloadError));
			return;
		}

		if (!newPartnerInfo.patientRelationship) {
			ERROR(
				"Care partner doesn't have a set patientRelationship in handleAddRelationship"
			);
			setErrorMessage(messageEnum.error(messages.mutationPayloadError));
			return;
		}

		try {
			const newPartner = await updateSubject({
				organizationId: patientInfo.organizationId,
				subjectId: newPartnerInfo.id,
				updateSubjectInput: modifyPartnerValues(newPartnerInfo),
			});

			const newPatient = await addPatientRelationship({
				organizationId: patientInfo.organizationId,
				subjectId: patientInfo.id,
				relatedSubjectInput: {
					type: newPartnerInfo.patientRelationship,
					relatedSubjectId: newPartnerInfo.id,
				},
			});

			handleRelationshipSuccess(newPartner, newPatient);
		} catch (e) {
			ERROR(
				`Unexpected error in handleAddRelationship, orgId: ${patientInfo.organizationId}, subjectId: ${newPartnerInfo.id}, patientId: ${patientInfo.id} `,
				e
			);
			setErrorMessage(messageEnum.error(messages.mutationPayloadError));
		}
	}

	function handleRelationshipSuccess(
		partnerInfo: Subject,
		patientInfo: Patient
	) {
		sendEventData({ eventType: AnalyticsAction.EditedCarepartner });
		openModal('EditCarePartnerConfirmation', {
			fullNameCarePartner: `${partnerInfo.firstName} ${partnerInfo.lastName}`,
			fullNamePatient: `${patientInfo.firstName} ${patientInfo.lastName}`,
		});

		closeModal('EditCarePartner');
	}

	function handleEdit() {
		if (!patientInfo) return;

		sendEventData({ eventType: AnalyticsAction.ClickedEditCarePartner });
		openModal('EditCarePartner', {
			partnerInfo,
			patientInfo,
			onSubmit: (newPartnerInfo) => handleAddRelationship(newPartnerInfo),
		});
	}

	if (!partnerInfo || !patientInfo) return null;

	return (
		<CarePartnerCardUi
			partnerInfo={partnerInfo}
			patientInfo={patientInfo}
			onEdit={handleEdit}
		/>
	);
}
