import { useTranslation } from 'react-i18next';

import { Trials } from 'components/mosaic';

import {
	BreakdownTitle,
	TrialsContainer,
	TrialsDivider,
} from './ImmediateRecall.style';
import { TrialsResult } from './ImmediateRecall.types';

type ImmediateRecallBreakdownProps = {
	trials: TrialsResult;
};

export function ImmediateRecallBreakdown({
	trials,
}: Readonly<ImmediateRecallBreakdownProps>) {
	const { t } = useTranslation();
	const { trial1, trial2 } = trials;

	return (
		<TrialsContainer>
			<BreakdownTitle>
				{t('web.report.pvltImmediateReport.breakdown')}
			</BreakdownTitle>
			<Trials
				responseText={
					trial1.score.includes('--')
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				score={trial1.score}
				title={t('web.report.pvltImmediateReport.trials1')}
			/>
			<TrialsDivider />
			<Trials
				responseText={
					trial2.score.includes('--')
						? t('web.report.uniqueResponses.cannotCalculate')
						: t('web.report.uniqueResponses.correctResponses')
				}
				score={trial2.score}
				title={t('web.report.pvltImmediateReport.trials2')}
			/>
		</TrialsContainer>
	);
}
