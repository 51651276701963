import i18n from 'i18n';
import { DropdownOption } from 'types';

export function getContactMethod(
	contactEmail?: string | null,
	contactPhone?: string | null
) {
	if (contactEmail && contactPhone) {
		return CONTACT_PREFERENCES.EMAIL_AND_PHONE;
	}

	if (contactEmail) {
		return CONTACT_PREFERENCES.EMAIL_ONLY;
	}

	if (contactPhone) {
		return CONTACT_PREFERENCES.PHONE_ONLY;
	}

	return null;
}

export const CONTACT_PREFERENCES = {
	EMAIL_ONLY: 'email_only',
	PHONE_ONLY: 'phone_only',
	EMAIL_AND_PHONE: 'email_and_phone',
};

export const CONTACT_OPTION = {
	EMAILANDPHONE: i18n.t(
		'web.patients.forms.remoteAssessment.configuration.contactMethod.emailAndPhone'
	),
	EMAILONLY: i18n.t(
		'web.patients.forms.remoteAssessment.configuration.contactMethod.emailOnly'
	),
	PHONEONLY: i18n.t(
		'web.patients.forms.remoteAssessment.configuration.contactMethod.phoneOnly'
	),
};

export const LOCATION_BATTERY_TYPE = {
	IN_CLINIC: i18n.t('web.patients.forms.remoteAssessment.type.inClinic'),
	REMOTE: i18n.t('web.patients.forms.remoteAssessment.type.remote'),
};

export type LocationBatteryType =
	(typeof LOCATION_BATTERY_TYPE)[keyof typeof LOCATION_BATTERY_TYPE];

export const contactPreferences: DropdownOption[] = [
	{
		display: CONTACT_OPTION.EMAILANDPHONE,
		value: CONTACT_PREFERENCES.EMAIL_AND_PHONE,
	},
	{
		display: CONTACT_OPTION.EMAILONLY,
		value: CONTACT_PREFERENCES.EMAIL_ONLY,
	},
	{
		display: CONTACT_OPTION.PHONEONLY,
		value: CONTACT_PREFERENCES.PHONE_ONLY,
	},
];

export const batteryTypeOptions: DropdownOption[] = [
	{
		display: LOCATION_BATTERY_TYPE.IN_CLINIC,
		value: LOCATION_BATTERY_TYPE.IN_CLINIC,
	},
	{
		display: LOCATION_BATTERY_TYPE.REMOTE,
		value: LOCATION_BATTERY_TYPE.REMOTE,
	},
];
