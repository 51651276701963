import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { LinusTooltip } from 'components/shared/LinusTooltip';
import { StyledLinkButton } from 'components/shared/StyledLinkButton';
import { Icon } from 'components/shared/designSystem';
import { H4 } from 'components/shared/designSystem/TextComponents';
import { icons } from 'enums/icons';
import { LegendRow } from '../sharedComponents';

import {
	H2Med,
	StyledLegendContainer,
	StyledLegendRowDivider,
	StyledReportSummary,
	StyledScoreCard,
	StyledScorePanel,
	StyledScoringDetailsContainer,
	StyledSummaryTitle,
	StyledTitleDivider,
	Tooltip,
	VerticalPaddedStyledCard,
} from './ResultsSummaryCard.style';
import { ScoringDetailsModal } from './ScoringDetailsModal';

type ResultsSummaryCardProps = {
	score: number | string;
	interpretation: string;
};

enum InterpretationType {
	Normal = 'gds-interpretation-normal',
	Mild = 'gds-interpretation-mild',
	Moderate = 'gds-interpretation-moderate',
	Severe = 'gds-interpretation-severe',
}

export const ResultsSummaryCard = ({
	score,
	interpretation,
}: ResultsSummaryCardProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [showScoringDetailsModal, setShowScoringDetailsModal] =
		useState(false);

	const getStylesForScore = (interpretation: string) => {
		if (interpretation === InterpretationType.Normal) {
			return {
				color: theme.color.noIndication,
				label: 'web.report.gds.resultsSummaryCard.noneLabel',
			};
		}
		if (interpretation === InterpretationType.Mild) {
			return {
				color: theme.color.mildIndication,
				label: 'web.report.gds.resultsSummaryCard.mildLabel',
			};
		}
		if (interpretation === InterpretationType.Moderate) {
			return {
				color: theme.color.moderateIndication,
				label: 'web.report.gds.resultsSummaryCard.moderateLabel',
			};
		}
		if (interpretation === InterpretationType.Severe) {
			return {
				color: theme.color.severeIndication,
				label: 'web.report.gds.resultsSummaryCard.severeLabel',
			};
		}

		return {
			color: theme.color.focusAreaBackground,
			label: 'web.report.gds.resultsSummaryCard.invalidResult',
		};
	};

	const { color: bgColor, label: scoreLabel } =
		getStylesForScore(interpretation);

	return (
		<>
			<VerticalPaddedStyledCard>
				<StyledSummaryTitle>
					{t('web.report.gds.resultsSummaryCard.title')}
					<LinusTooltip
						overlay={
							<Tooltip>
								{t('web.report.gds.resultsSummaryCard.tooltip')}
							</Tooltip>
						}
						tooltipIcon={
							<Icon
								icon={icons.InfoOutlined}
								viewBox='0 0 20 20'
								iconWidth={24}
								iconHeight={24}
							/>
						}
					/>
				</StyledSummaryTitle>
				<StyledTitleDivider />
				<StyledReportSummary>
					<div>
						<StyledScoreCard bgColor={bgColor}>
							<StyledScorePanel bgColor={bgColor}>
								<H2Med>
									{typeof score === 'number' && score >= 0
										? score
										: '--'}
									/15
								</H2Med>
							</StyledScorePanel>
							<H4 data-testid='gds-score-label'>
								{t(scoreLabel)}
							</H4>
						</StyledScoreCard>
					</div>
					<StyledLegendContainer>
						<LegendRow
							color={theme.color.noIndication}
							range={t(
								'web.report.gds.resultsSummaryCard.noneRange'
							)}
							label={t(
								'web.report.gds.resultsSummaryCard.noneLabel'
							)}
						/>
						<StyledLegendRowDivider />
						<LegendRow
							color={theme.color.mildIndication}
							range={t(
								'web.report.gds.resultsSummaryCard.mildRange'
							)}
							label={t(
								'web.report.gds.resultsSummaryCard.mildLabel'
							)}
						/>
						<StyledLegendRowDivider />
						<LegendRow
							color={theme.color.moderateIndication}
							range={t(
								'web.report.gds.resultsSummaryCard.moderateRange'
							)}
							label={t(
								'web.report.gds.resultsSummaryCard.moderateLabel'
							)}
						/>
						<StyledLegendRowDivider />
						<LegendRow
							color={theme.color.severeIndication}
							range={t(
								'web.report.gds.resultsSummaryCard.severeRange'
							)}
							label={t(
								'web.report.gds.resultsSummaryCard.severeLabel'
							)}
						/>
						<StyledScoringDetailsContainer>
							<StyledLinkButton
								onClick={() => setShowScoringDetailsModal(true)}
							>
								{t(
									'web.report.gds.resultsSummaryCard.viewScoringDetailsLink'
								)}
							</StyledLinkButton>
						</StyledScoringDetailsContainer>
					</StyledLegendContainer>
				</StyledReportSummary>
			</VerticalPaddedStyledCard>
			{showScoringDetailsModal && (
				<ScoringDetailsModal
					onClose={() => setShowScoringDetailsModal(false)}
				/>
			)}
		</>
	);
};
