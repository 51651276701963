import { Locale, ReportType } from 'generated/graphql';

export const AnalyticsReportType = {
	[ReportType.PatientReport]: 'CCE BHAP',
	[ReportType.PatientReportActionPlan]: 'CCE BHAP',
	[ReportType.ProviderReport]: 'CCE Provider Report',
	[ReportType.ProviderReportWithCds]: 'CCE Provider Report with CDS',
};

export enum AnalyticsReportName {
	CCE_PROVIDER_REPORT = 'CCE Provider Report',
	CCE_SHORT_PROVIDER_REPORT = 'CCE Short Provider Report',
	CCE_PATIENT_REPORT_BHAP = 'CCE Patient Report/BHAP',
	COMMON_SPIRIT_PRIMARY_CARE_PROVIDER_REPORT = 'CommonSpirit Pegasus Protocol-Primary Care Clinic Battery Provider Report',
	COMMON_SPIRIT_DEMENTICA_CLINIC = 'CommonSpirit Pegasus Protocol-Dementia Clinic Battery Provider Report',
	DCR_PROVIDER_REPORT = 'DCR Provider Report',
	DCR_SHORT_PROVIDER_REPORT = 'DCR Short Provider Report',
	DCR_TRAILS = 'DCR™ - Digital Clock and Recall and dTMT b',
	DCR_PATIENT_REPORT_BHAP = 'DCR Patient Report/BHAP',
	EMORY_PROVIDER_REPORT = 'Emory-Digital Consult Pathway Battery Provider Report',
	IADL_CARE_PARTNER_ACTION_PLAN = 'iADL Care Partner Action Plan',
	IADL_PATIENT_REPORT_BHAP = 'iADL Patient Report and Action Plan',
	ADL_CARE_PARTNER_ACTION_PLAN = 'ADL Care Partner Action Plan',
	ADL_PATIENT_REPORT_BHAP = 'ADL Patient Report and Action Plan',
	IUH_PROVIDER_REPORT = 'IUH-Brain Health Navigator Battery Provider Report',
	LHQ_PROVIDER_REPORT = 'LHQ Provider Report',
	LHQ_SHORT_PROVIDER_REPORT = 'LHQ Short Provider Report',
	LHQ_PATIENT_REPORT_BHAP = 'LHQ Patient Report/BHAP',
	HEARING_SCREENER_PROVIDER_REPORT = 'Hearing Screener Provider Report',
	DTMT_PROVIDER_REPORT = 'dTMT B Provider Report',
	ORIENTATION_QNR6_PROVIDER_REPORT = 'Orientation QNR-6 Provider Report',
	GDS_PROVIDER_REPORT = 'GDS Provider Report',
	GAD_7_PROVIDER_REPORT = 'GAD-7 Provider Report',
	ADL_PROVIDER_REPORT = 'ADL Provider Report',
	IADL_PROVIDER_REPORT = 'iADL Provider Report',
	IADL_FAST_PROVIDER_REPORT = 'iADLs and FAST Provider Report',
	FAST_PROVIDER_REPORT = 'FAST Provider Report',
	PHONEMIC_FLUENCY_PROVIDER_REPORT = 'Phonemic Fluency Provider Report',
	SEMANTIC_FLUENCY_PROVIDER_REPORT = 'Semantic Fluency Provider Report',
	BACKAWRDS_DIGIT_SPAN_REPORT = 'Backwards Digit Span Provider Report',
	PVLT_PROVIDER_REPORT = 'PVLT Provider Report',
	CCE_GDS = 'CCE and GDS Provider Report',
	CCE_GDS_PATIENT_REPORT = 'CCE and GDS Patient Report',
	CLINICAL_PATHWAYS = 'Linus Clinical Pathways',
}

const ES_US = 'ES_US';

export const AnalyticsLanguage: Record<string, string> = {
	[Locale.EnGb]: 'EN_GB',
	[Locale.EnUs]: 'EN_US',
	[Locale.EsMx]: 'ES_MX',
	[ES_US]: 'ES_US',
};
