import { AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { icons } from '../../../../enums/icons';
import { ERROR } from '../../../../logging/linusLogger';
import { fetchUtil, prefix } from '../../../../s3ApiFetch';
import { UserContext } from '../../../context/UserContext';
import { Icon } from '../../../shared/designSystem/Icon';
import { H2, H4, P2 } from '../../../shared/designSystem/TextComponents';
import { useRestartSegment } from '../../../shared/hooks/useRestartSegment';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { StyledCard } from '../../../shared/StyledCard';
import { SegmentEvent as SegmentEventComponent } from '../SegmentEvents/SegmentEvent';
import { SegmentEvent, SegmentEvents } from '../SegmentEvents/SegmentEvents';
import { Auth } from 'features/auth-service';

export type RecallRecordingProps = {
	title: string;
	subTitle: string;
	tooltipText: string;
	segment: Record<string, any>;
};

export const RecallRecording = ({
	title,
	subTitle,
	tooltipText,
	segment,
}: RecallRecordingProps): JSX.Element | null => {
	const [audioSource, setAudioSource] = useState('');
	const audioElRef = useRef<HTMLMediaElement | null>(null);
	const isRestartSegmentEnabled = useRestartSegment();
	const [activityEvents, setActivityEvents] = useState<SegmentEvent[]>([]);
	const envPrefix = prefix();
	const showWarning =
		Number(segment.metricItems?.transcription_length?.value) > 20;
	const audioHandler = useCallback(
		({ data }: AxiosResponse) => {
			const blob = new Blob([data], {
				type: 'audio/mp4',
			});
			const blobUrl = URL.createObjectURL(blob);
			setAudioSource(blobUrl);
		},
		[setAudioSource]
	);
	const fetchData = useCallback(fetchUtil, []);

	const setAudioStartTimeToResponseOffset = () => {
		if (audioElRef.current && segment.metricItems) {
			audioElRef.current.currentTime =
				Number(segment.metricItems?.response_offset?.value) || 0;
			audioElRef.current.onended = () => {
				if (audioElRef.current)
					audioElRef.current.currentTime =
						Number(segment.metricItems?.response_offset?.value) ||
						0;
			};
		}
	};

	useEffect(setAudioStartTimeToResponseOffset, [audioElRef.current, segment]);

	const { currentUser } = useContext(UserContext);

	const getErrorHandler = (fileName: string) => {
		return (_error: any) => {
			ERROR(
				`Missing ${fileName} for segment result id: ${segment.id} in organization: ${currentUser.organizationId}.`
			);
		};
	};

	useEffect(() => {
		const abortController = new AbortController();
		const fileName = 'response.mp4';
		const audioUrl = `${envPrefix}s3MetricFiles/${segment.id}/${fileName}`;
		const responseType = 'blob';

		const errorHandler = getErrorHandler(fileName);

		fetchUtil({
			url: audioUrl,
			signal: abortController.signal,
			onSuccess: audioHandler,
			onError: errorHandler,
			responseType,
			auth: Auth,
		}).catch(console.error);

		return () => {
			abortController.abort();
		};
	}, [envPrefix, segment.id, audioHandler]);

	useEffect(
		() => () => {
			//return the appropriate cleanup function (also calls the previous cleanup function)
			URL.revokeObjectURL(audioSource);
		},
		[audioSource]
	);

	useEffect(() => {
		const abortController = new AbortController();
		const fileName = 'segment_result_metadata.json';
		const url = `${envPrefix}s3MetricFiles/${segment.id}/${fileName}`;

		const responseHandler = (response: any) => {
			const segmentEventData: SegmentEvent[] =
				Array.isArray(response.data) &&
				response.data.filter(
					(item: SegmentEvent) =>
						item.segmentType === segment.segmentType
				);
			segmentEventData.length && setActivityEvents(segmentEventData);
		};

		const errorHandler = getErrorHandler(fileName);

		const fetchS3Resource = async () => {
			await fetchData({
				url,
				signal: abortController.signal,
				onSuccess: responseHandler,
				onError: errorHandler,
				auth: Auth,
			});
		};
		fetchS3Resource();

		return () => {
			abortController.abort();
		};
	}, [
		currentUser.organizationId,
		envPrefix,
		segment.id,
		segment.segmentType,
	]);

	const { t } = useTranslation();
	let expectedWordsBody;
	if (segment.metricItems?.expected_words?.value) {
		expectedWordsBody = segment.metricItems?.expected_words?.value
			.split(',')
			.map((word: string, idx: number) => (
				<StyledWord data-testid={`recallWord_${idx}`} key={idx}>
					{word}
				</StyledWord>
			));
	} else {
		expectedWordsBody = (
			<StyledWord data-testid={`unavailableRecallWords`}>
				{t`web.report.recall.unavailable`}
			</StyledWord>
		);
	}

	return (
		<StyledWrapper>
			<StyledTitle>
				<H4>{title}</H4>
				<LinusTooltip
					overlay={<StyledTooltip>{tooltipText}</StyledTooltip>}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
			</StyledTitle>
			<StyledPrintHide>
				{isRestartSegmentEnabled && (
					<SegmentEvents segmentEvents={activityEvents} />
				)}
				{showWarning && (
					<SegmentEventComponent
						icon={icons.WarningOutlined}
						textKey='web.report.recall.lengthWarningShort'
					/>
				)}
			</StyledPrintHide>
			<P2>{subTitle}</P2>
			<StyledBody>{expectedWordsBody}</StyledBody>
			<StyledAudio
				controls
				controlsList='nodownload'
				src={audioSource}
				ref={audioElRef}
				onLoadedData={setAudioStartTimeToResponseOffset}
				onPlay={() => {
					sendEventData({
						eventType: AnalyticsAction.PlayedRecording,
						eventProperties: { recordingType: title },
					});
				}}
			/>
		</StyledWrapper>
	);
};

const StyledWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => `
		min-width: 536px;
		padding: ${spacing.lg};
		vertical-align: bottom;
    `
);

const StyledTitle = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		align-items: center;
		gap: 8px;
		margin-bottom: ${spacing.xl};
	`
);

const StyledBody = styled.div(
	({ theme: { spacing } }) => `
		width: fit-content;
		margin: auto;
		height: 265px;
		padding: ${spacing.md};
	`
);

const StyledWord = styled(H2)(
	({ theme: { spacing } }) => `
		margin: ${spacing.lg};
	`
);

const StyledAudio = styled.audio(
	({ theme: { spacing } }) => `
		width: 100%;
		height: ${spacing.xl};
	`
);

const StyledTooltip = styled.div(
	({ theme: { fontSize } }) => `
		width: 500px;
		font-size: ${fontSize._16};
	`
);

const StyledPrintHide = styled.div`
	@media print {
		display: none;
	}
`;
