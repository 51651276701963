import i18nBase, { i18n } from 'i18n';
import { Settings } from 'luxon';
import { ReactNode } from 'react';
import { I18nextProvider as BaseI18nextProvider } from 'react-i18next';

import { Memoize } from 'helpers/Memoize';

interface I18nextProviderProps {
	children: ReactNode;
	i18n?: i18n;
}

const I18nextProvider = ({
	children,
	i18n = i18nBase,
}: I18nextProviderProps): JSX.Element => {
	//Grab browser language pref and set Luxon locale
	const currentLocale = window.navigator.language;
	Settings.defaultLocale = currentLocale;

	return (
		<BaseI18nextProvider i18n={i18n}>
			<Memoize>{children}</Memoize>
		</BaseI18nextProvider>
	);
};

export { I18nextProvider };
