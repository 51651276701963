import { UserContextProvider as UserProviderAuth0 } from './UserContextProvider.auth0';

type UserProviderProps = {
	children: React.ReactNode;
	storybook?: boolean;
};

export function UserProvider({
	storybook = false,
	children,
}: Readonly<UserProviderProps>) {
	if (storybook) {
		return <>{children}</>;
	}
	return <UserProviderAuth0>{children}</UserProviderAuth0>;
}
