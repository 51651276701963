import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { icons } from 'enums/icons';
import { ButtonSm, Icon } from '../../components/shared/designSystem';

import {
	ButtonContainer,
	Container,
	Content,
	Logo,
	Page,
	Title,
} from './SessionTimeout.style';

export function SessionTimeout() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function handleClick() {
		navigate('/auth/login');
	}

	return (
		<Page>
			<Container>
				<Icon icon={icons.WarningRound} title='WarningRound' />
				<Title data-testid='session-timeout-txt-title'>
					{'Session Timeout'}
				</Title>
				<Content data-testid='session-timeout-txt-body'>
					{
						'Your session has expired due to inactivity. Please log in again to continue.'
					}
				</Content>
				<ButtonContainer>
					<ButtonSm
						data-testid='session-timeout-btn-redirect'
						primary
						text={t`web.login.title`}
						onClick={handleClick}
					/>
				</ButtonContainer>
				<Logo data-testid='Logo'>
					<Icon icon={icons.Logo} title={t`web.shared.logo`} />
				</Logo>
			</Container>
		</Page>
	);
}
