import { Typography } from '@lh/eng-web-mosaic-common';
import { Modal, Stack } from '@mantine/core';

type SimpleModalProps = {
	children: React.ReactNode;
	open?: boolean;
	title: string;
	onClose: () => void;
};

export function SimpleModal({
	children,
	open = false,
	title,
	onClose,
}: Readonly<SimpleModalProps>) {
	return (
		<Modal.Root
			centered
			maw={700}
			opened={open}
			size='auto'
			onClose={onClose}
		>
			<Modal.Overlay backgroundOpacity={0.5} />
			<Modal.Content
				styles={{
					content: {
						borderRadius: 10,
						padding: 24,
					},
				}}
			>
				<Modal.Header mih='auto' p={0}>
					<Typography.H2 weight='500'>{title}</Typography.H2>
					<Modal.CloseButton size={32} />
				</Modal.Header>
				<Modal.Body mt={24} p={0}>
					<Stack gap={24}>{children}</Stack>
				</Modal.Body>
			</Modal.Content>
		</Modal.Root>
	);
}
