import { v4 } from 'uuid';

export type AstNodeFormat = 'bold';

export type AstNode = {
	content: string | AstNode[];
	format?: AstNodeFormat;
	id: string;
};

type Rule = {
	format: AstNodeFormat;
	symbol: string;
};

const OPENING_SYMBOL = '<';
const CLOSING_SYMBOL = '/';

const RULES: Map<string, Rule> = new Map([
	[
		'b',
		{
			format: 'bold',
			symbol: 'b',
		},
	],
]);

function getId() {
	return v4();
}

/**
 * Parses a text and returns an abstract syntax tree
 * @param text Text to parse
 */
export function parseRichText(text: string | string[]) {
	let tree: AstNode[] = [];

	if (Array.isArray(text)) {
		text.forEach((value) => (tree = tree.concat(buildTree(value))));
	} else {
		tree = buildTree(text);
	}

	return tree;
}

function buildTree(text: string) {
	const characters = text.split('');
	const { found, tree } = getTree(characters);

	if (found) {
		tree.forEach((_node) => {
			if (typeof _node.content === 'string') {
				const freshTree = buildTree(_node.content);

				if (freshTree.length > 1) {
					_node.content = freshTree;
				}
			}
		});
	}

	return tree;
}

function getTree(characters: string[]) {
	const tree: AstNode[] = [];
	let node: AstNode = {
		content: '',
		id: '',
	};

	let activeRule: Rule | null = null;
	let foundRule = false;
	let content = '';
	let skip = 0;

	for (let i = 0; i < characters.length; i++) {
		const char = characters[i];
		const next = characters[i + 1];
		const nextNext = characters[i + 2];

		if (skip) {
			skip--;
			continue;
		}

		if (char === OPENING_SYMBOL && activeRule) {
			if (next === CLOSING_SYMBOL && nextNext === activeRule.symbol) {
				node.content = content;
				node.id = getId();
				tree.push(node);

				node = {
					content: '',
					id: '',
				};
				content = '';

				skip = 3;
				activeRule = null;
				foundRule = true;
				continue;
			}
		}

		if (char === OPENING_SYMBOL && !activeRule) {
			if (content) {
				node.content = content;
				node.id = getId();
				tree.push(node);

				node = {
					content: '',
					id: '',
				};
				content = '';
			}

			if (next === CLOSING_SYMBOL) {
				skip = 3;
				activeRule = null;
			} else {
				skip = 2;

				const symbol = next;
				const rule = RULES.get(symbol);

				if (rule) {
					node.format = rule.format;
					activeRule = rule;
				}
			}

			continue;
		}

		content = content.concat(char);
	}

	if (content) {
		node.content = content;
		node.id = getId();
		tree.push(node);
	}

	return { found: foundRule, tree };
}
