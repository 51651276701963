import { Button, Text } from './TextLink.style';

type TextLinkProps = {
	children: string;
	onClick?: () => void;
};

export function TextLink({ children, onClick }: Readonly<TextLinkProps>) {
	return (
		<Button onClick={onClick}>
			<Text>{children}</Text>
		</Button>
	);
}
