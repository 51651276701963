import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { FieldMetaProps } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const TypeAheadSearchBar = ({
	name,
	disabled,
	expand,
	error,
	onChange,
	onBlur,
	onClick,
	displayValue,
	placeHolder,
	isOpen,
}: {
	name: string;
	disabled: boolean;
	expand: boolean;
	error: FieldMetaProps<string>;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void | null;
	onBlur: {
		(e: React.FocusEvent<HTMLElement>): void;
		<T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
	};
	onClick?: React.MouseEventHandler<HTMLInputElement>;
	displayValue: string;
	isOpen: boolean;
	placeHolder: string;
}) => {
	const { t } = useTranslation();
	return (
		<StyledSelectField
			data-id={name}
			disabled={disabled}
			expand={expand}
			error={!!error.error}
			data-testid={`test_${name}`}
		>
			<StyledInnerInput
				tabIndex={0}
				name='innerInput'
				onChange={onChange}
				onBlur={onBlur}
				onClick={onClick}
				disabled={disabled}
				value={displayValue}
				placeholder={placeHolder || ''}
				data-testid={`inner_input`}
			/>
			<SearchIconContainer>
				<Icon icon={icons.MagnifyingGlass} width={24} height={24} />
			</SearchIconContainer>
			<StyledRemoveText
				onClick={onClick}
				data-testid='remove-text-container'
			>
				{isOpen && (
					<Icon
						icon={icons.Close}
						title={t`web.shared.forms.closeDropdown`}
					/>
				)}
			</StyledRemoveText>
		</StyledSelectField>
	);
};

type StyledSelectFieldProps = {
	expand: boolean;
	disabled?: boolean;
	error: boolean;
};
const StyledSelectField = styled.div<StyledSelectFieldProps>(
	({ expand, error, disabled, theme: { color } }) => `
	position: relative;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	background: ${disabled ? color.formDisabledBg : color.white};
	box-sizing: border-box;
	padding: 0 23px;
	color: ${disabled ? color.formTextDisabled : color.formText};
	font-size: 16px;
	border: 1px solid #D9D8DC;
	transition: 0.2s ease all;
	border: 1px solid ${error ? color.formError : color.inputBorder};
	padding-top: ${expand ? '20px' : '0px'};
	justify-content: space-between;
	min-height: 48px;

	&:hover {
		border: 1px solid ${disabled ? color.inputBorder : color.inputHover};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
	}

	&:focus {
		border: 1px solid ${color.inputHover};
		outline: none;
	}
	`
);

const StyledInnerInput = styled.input(
	({ disabled, theme: { color } }) => `
	width:100%;
	height:100%;
	color: ${disabled ? color.formTextDisabled : color.formText};
	margin-left: 18px;

	&:focus-visible {
    outline: 0px;
}
`
);

const SearchIconContainer = styled.div`
	position: absolute;
	left: 16px;
`;

const StyledRemoveText = styled.div`
	display: flex;
	justify-self: flex-end;
	align-items: center;
	transition: 0.15s ease all;
	height: 100%;
`;
