import { Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { InterpretedScore, ScoreTag, ScoreType } from 'components/mosaic';
import { usePercentileText } from 'components/report/usePercentileText';

import { DelayedRecognitionPerformance } from '../DelayedRecognition.types';
import { createDelayedRecognitionScore } from '../createDelayedRecognitionScore';

const IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY: Record<number, string> = {
	0: 'web.report.pvltDelayedRecognitionReport.performance.good',
	1: 'web.report.pvltDelayedRecognitionReport.performance.bad',
};
const IMPAIRED_TO_SCORE_TYPE: Record<number, ScoreType> = {
	0: 'not-impaired',
	1: 'impaired',
};

type DelayedRecognitionScoreProps = {
	performance: DelayedRecognitionPerformance;
};

export function DelayedRecognitionScore({
	performance,
}: Readonly<DelayedRecognitionScoreProps>) {
	const { t } = useTranslation();

	const percentileText = usePercentileText(performance.percentile);
	const percentileInterpretation = percentileText
		? t('web.report.pvltDelayedRecognitionReport.performance.value', {
				value: percentileText,
		  })
		: null;
	const requiresReview = performance.score.value === null;
	const scoreValue =
		performance.score.value ?? createDelayedRecognitionScore('--');

	if (requiresReview) {
		return (
			<Stack align='start' gap={12} maw={445}>
				<InterpretedScore
					interpretationDescription={t(
						'web.report.pvltDelayedRecognitionReport.rcr-web'
					)}
					scoreLabel={t(
						'web.report.pvltDelayedRecognitionReport.scoreLabel'
					)}
					scoreType='unanalyzable'
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	if (performance.impaired > -1) {
		const impairmentInterpretation = t(
			IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY[performance.impaired]
		);
		const scoreType = IMPAIRED_TO_SCORE_TYPE[performance.impaired];

		return (
			<Stack align='start' gap={12} maw={445}>
				<InterpretedScore
					interpretationCaption={percentileInterpretation}
					interpretationDescription={impairmentInterpretation}
					scoreLabel={t(
						'web.report.pvltDelayedRecognitionReport.scoreLabel'
					)}
					scoreType={scoreType}
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	return (
		<Stack maw={445}>
			<ScoreTag
				label={t('web.report.pvltDelayedRecognitionReport.scoreLabel')}
				type='unknown'
				value={scoreValue}
			/>
		</Stack>
	);
}
