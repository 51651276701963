import { Typography } from '@lh/eng-web-mosaic-common';
import styled, { css } from 'styled-components';

export const Button = styled.button(
	({ theme }) => css`
		background: none;
		border: none;
		cursor: pointer;
		padding: 2px 4px;
		text-underline-offset: 2px;
		width: fit-content;

		&:hover {
			text-decoration: underline;
			text-decoration-color: ${theme.colors.purple_dark};
			text-decoration-thickness: 1px;
		}
	`
);

export const Text = styled(Typography.P2)(
	({ theme }) => css`
		color: ${theme.colors.purple_dark};
	`
);
