import { UserStatus } from '@lh/eng-platform-organization-service-rest-client';

import { Buffer } from 'buffer';
import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useUpdateUser } from 'api/user';
import { ERROR } from 'logging/linusLogger';
import { icons } from '../../../enums/icons';
import { messageEnum, MessageEnumItem } from '../../../enums/messageEnum';
import { Auth } from '../../../features/auth-service';
import { UserContext } from '../../context/UserContext';
import { ButtonLg } from '../../shared/designSystem/ButtonLg';
import { Icon } from '../../shared/designSystem/Icon';
import { H2 } from '../../shared/designSystem/TextComponents';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import { LinusPasswordInput } from '../../shared/Forms/Components/LinusPasswordInput';
import { LinusPasswordInputWithStrength } from '../../shared/Forms/Components/LinusPasswordInputWithStrength';
import {
	CreateAccountModel,
	createAccountSchema,
} from '../../shared/Forms/Schemas/createAccountSchema';
import { InfoMessage } from '../../shared/InfoMessage';
import { PrivacyPolicyLink } from '../../shared/PrivacyPolicyLink';
import { TermsOfUseLink } from '../../shared/TermsOfUseLink';
import { loginLandingPage } from '../login/loginLandingPage';

import { getExpiredInvitationMessageEnum } from './CreateAccountForm.helpers';

const CreateAccountFormCognito = (): JSX.Element => {
	const { currentUser, setCurrentUser } = useContext(UserContext);
	const theme = useTheme();
	const { t } = useTranslation();
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const {
		mutateAsync: updateUser,
		error: updateUserError,
		isPending,
	} = useUpdateUser(currentUser.organizationId);

	const messages = {
		finishSignUpError: t`web.auth.shared.messages.finishSignUpError`,
		invalidInvitationError: t`web.auth.shared.messages.invalidInvitationErrorCognito`,
	};

	if (updateUserError && !serverSideMessage) {
		setServerSideMessage(
			messageEnum.error(
				messages.finishSignUpError,
				icons.AlertShieldSolid
			)
		);
	}

	const navigate = useNavigate();
	// If you need to change these param names, it must also be changed in the AWS trigger
	const params = new URLSearchParams(document.location.search);

	const json = Buffer.from(params.get('payload') as string, 'hex').toString();
	const payload = JSON.parse(json);

	const onSubmit = async (
		values: CreateAccountModel,
		{ setSubmitting }: FormikHelpers<CreateAccountModel>
	) => {
		// Resetting the error state on submit click
		setServerSideMessage(undefined);
		try {
			const success = await Auth.finishSignup({
				username: values.email,
				tempPass: payload.tempPass,
				newPass: values.password,
			});
			if (!success) {
				setServerSideMessage(
					messageEnum.error(
						messages.finishSignUpError,
						icons.AlertShieldSolid
					)
				);
				return;
			}
		} catch (err) {
			setServerSideMessage(getExpiredInvitationMessageEnum(theme));
		}

		try {
			const user = await updateUser({
				userUpdate: {
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
				},
				organizationId: currentUser.organizationId,
				userId: currentUser.id,
			});

			if (user) {
				setCurrentUser({
					...currentUser,
					...user,
					userStatus: UserStatus.Active,
				});
				const path = loginLandingPage(currentUser);

				navigate(path);
			}

			setSubmitting(false);
		} catch (err) {
			ERROR(
				`Error trying to update user from CreateAccountForm.cognito, userId: ${currentUser.id}, orgId: ${currentUser.organizationId}: `,
				err
			);
			setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={{
				firstName: payload.firstName,
				lastName: payload.lastName,
				password: '',
				repeatPassword: '',
				email: payload.email,
			}}
			validationSchema={createAccountSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<StyledContainer>
						<StyledMessageContainer>
							{!serverSideMessage && (
								<>
									<Icon
										icon={icons.InviteOutlined}
										iconWidth={40}
										iconHeight={40}
										color={theme.color.iconInvite}
										title={t`web.auth.forms.inviteMail`}
									/>
									<StyledInviteMessage>
										<Trans
											i18nKey='web.auth.shared.fragments.invited'
											values={{
												firstName:
													payload.inviterFirstName,
												lastName:
													payload.inviterLastName,
											}}
											components={[
												<strong />,
												<StyledSpacing />,
											]}
										/>
										<strong>{payload.orgName}</strong>
									</StyledInviteMessage>
								</>
							)}
						</StyledMessageContainer>
						<H2>{t`web.auth.forms.title`}</H2>
						<StyledForm>
							<StyledRow>
								<LinusInput
									width='360px'
									name='firstName'
									data-id='setup-new-pass-first-name'
									label={t`web.shared.firstName`}
								/>
								<LinusInput
									width='360px'
									name='lastName'
									data-id='setup-new-pass-last-name'
									label={t`web.shared.lastName`}
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									data-id='setup-new-pass-email'
									name='email'
									type='email'
									width='100%'
									disabled
									label={t`web.shared.email`}
								/>
							</StyledRow>
							<StyledRow>
								<LinusPasswordInputWithStrength
									width='360px'
									name='password'
									label={t`web.shared.password`}
								/>
								<LinusPasswordInput
									width='360px'
									name='repeatPassword'
									label={t`web.shared.repeatPassword`}
								/>
							</StyledRow>
							<InfoMessage
								showIf={!!serverSideMessage}
								messageEnum={serverSideMessage}
							/>
							<StyledAgreementContainer>
								<StyledAgreementText>
									<Trans
										i18nKey='web.auth.forms.agreement'
										components={[
											<PrivacyPolicyLink
												text={t`web.shared.LHPP`}
												underline={false}
											/>,
											<TermsOfUseLink
												underline={false}
											/>,
										]}
									/>
								</StyledAgreementText>
							</StyledAgreementContainer>
							<StyledSubmitButtonWrapper>
								<ButtonLg
									dataId='createAccountBtn'
									disabled={
										!(isValid && dirty) || isSubmitting
									}
									text={t`web.auth.forms.createAccountBtn`}
									type='submit'
									width='360px'
									primary={true}
									loading={isPending}
								/>
							</StyledSubmitButtonWrapper>
						</StyledForm>
					</StyledContainer>
				);
			}}
		</Formik>
	);
};

export { CreateAccountFormCognito };

const StyledContainer = styled.div`
	width: 100%;
	height: 760px;
	padding: 56px 120px;
	transition: 0.4s ease all;
	text-align: center;
	justify-content: space-between;
`;

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const StyledMessageContainer = styled.div(
	({ theme: { spacing } }) => `
        display: flex;
        align-items: center;
		margin: ${spacing.xl} 0;
    `
);

const StyledForm = styled(Form)(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);
const StyledAgreementContainer = styled.div`
	display: flex;
`;

const StyledAgreementText = styled.span(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);

const StyledSubmitButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 ${spacing.md} 0;
	display: flex;
	justify-content: center;
	`
);

const StyledInviteMessage = styled.span(
	({ theme: { spacing } }) => `
	text-align: center;
	margin: 0 0 0 ${spacing.md};
	`
);

const StyledSpacing = styled.span(
	({ theme: { spacing } }) => `
	margin: 0 ${spacing.xs}
`
);
