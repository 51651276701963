import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { ReactNode, useCallback, useContext, useMemo } from 'react';

import { UserContext } from '../../context/UserContext';
import { CurrentUser } from 'types';
import { UserOnlyContext } from '../UserProvider/UserOnlyContext';
import { isEmpty } from 'lodash';
import { buildGQLCurrentUserFromRest } from 'helpers/gqlToRestMappers';
import { DEBUG } from 'logging/linusLogger';
import { useOrganizationStore, usePreferencesStore } from 'store';
import { getSessionStorage } from 'utils/sessionStorage';
import { SessionStorageEnum } from 'enums/sessionStorageKeysEnum';

export const UserContextProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const { user } = useContext(UserOnlyContext);
	const isDeeplinking = getSessionStorage(SessionStorageEnum.ImpersonatedOrg);

	const organizationId = useOrganizationStore((state) => state.id);
	const organizationName = useOrganizationStore((state) => state.name);
	const organizationStatus = useOrganizationStore((state) => state.status);
	const organizationType = useOrganizationStore((state) => state.type);
	const organizationRole = useOrganizationStore((state) => state.role);

	const dateFormat = usePreferencesStore((state) => state.dateFormat);
	const defaultTimezone = usePreferencesStore(
		(state) => state.defaultTimezone
	);
	const defaultUserLocale = usePreferencesStore(
		(state) => state.defaultUserLocale
	);
	const features = usePreferencesStore((state) => state.features);
	const lists = usePreferencesStore((state) => state.lists);
	const cdsConcerns = usePreferencesStore((state) => state.cdsConcerns);

	const _currentUser = useMemo((): CurrentUser => {
		const result = buildGQLCurrentUserFromRest({
			user,
			organizationId,
			organizationName,
			organizationStatus,
			organizationType,
			organizationRole,
			dateFormat,
			defaultTimezone,
			defaultUserLocale,
			features,
			lists,
			cdsConcerns,
		});

		if (isDeeplinking) {
			// If we're deeplinking from MGMT, you're allowed to do everything
			result.operations = Object.values(OperationToken);
		}
		return result;
	}, [user?.id, user?.status, organizationId, ...Object.values(lists)]);

	const _setCurrentUser = useCallback((_: CurrentUser) => {
		DEBUG('GQL setCurrentUser() called');
	}, []);

	const _updateCurrentUser = useCallback(() => {
		DEBUG('GQL updateCurrentUser() called');
	}, []);

	const _logout = useCallback(() => {
		DEBUG('GQL logout() called');
	}, []);

	const _onLogin = useCallback(() => {
		DEBUG('GQL onLogin() called');
	}, []);

	const _clearImpersonation = useCallback(() => {
		DEBUG('GQL clearImpersonation() called');
	}, []);

	const _refetchCurrentUser = useCallback(() => {
		DEBUG('GQL refetchCurrentUser() called');
	}, []);

	const providedValue = useMemo(
		() => ({
			currentUser: _currentUser,
			setCurrentUser: _setCurrentUser,
			updateCurrentUser: _updateCurrentUser,
			onLogin: _onLogin,
			logout: _logout,
			clearImpersonation: _clearImpersonation,
			refetchCurrentUser: _refetchCurrentUser,
			isLoggedIn: !isEmpty<CurrentUser>(_currentUser),
		}),
		[_currentUser]
	);

	// In order to avoid showing the Linus Login page, prevent showing anything unless logged in
	if (isEmpty<CurrentUser>(_currentUser)) {
		return null;
	}

	return (
		<UserContext.Provider value={providedValue}>
			{children}
		</UserContext.Provider>
	);
};
