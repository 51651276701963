import { DefaultApiUpdateUserOrganizationsRoleStatusRequest } from '@lh/eng-platform-organization-service-rest-client';

import { useMutation } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';

export async function updateOrganizationRoleStatus(
	searchInput: DefaultApiUpdateUserOrganizationsRoleStatusRequest,
	signal?: AbortSignal
) {
	if (!searchInput.organizationId) {
		throw new Error(
			"updateOrganizationRoleStatus: organizationId can't be null"
		);
	}

	if (!searchInput.userId) {
		throw new Error("updateOrganizationRoleStatus: userId can't be null");
	}

	const service = await getOrganizationService();
	const { data } = await service.updateUserOrganizationsRoleStatus(
		searchInput,
		{ signal }
	);
	return data;
}

export function useUpdateRoleStatus() {
	return useMutation({
		meta: {
			errorMessage: `Error on updateOrganizationRoleStatus`,
		},
		mutationFn: updateOrganizationRoleStatus,
	});
}
