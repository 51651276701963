import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { NoteTemplate } from 'components/report/NoteTemplate/NoteTemplate';
import type { NoteTemplateState } from 'components/report/NoteTemplate/NoteTemplate.types';
import { useGetBatteryResults } from 'components/shared/hooks';
import { useOrganizationFeatures } from 'components/shared/hooks/useOrganizationFeatures';
import { icons } from '../../../../enums/icons';
import { FileNotFound } from '../../../FileNotFound';
import { UserContext } from '../../../context/UserContext';
import { ParticipantInfo } from '../../../participants/ParticipantInfo';
import {
	columns,
	mapData,
} from '../../../shared/DataTable/schemas/resultsSchema';
import { Header } from '../../../shared/Header';
import { LinusDataTable } from '../../../shared/LinusDataTable';

export const PatientDetails_V1 = (): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();
	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedPatientDetails });
	}, []);
	const features = useOrganizationFeatures();

	const { currentUser } = useContext(UserContext);
	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';
	const defaultTimezone = currentUser.organizationDefaultTimezone;

	const [noteTemplate, setNoteTemplate] = useState<NoteTemplateState>({
		open: false,
		batteryResultId: '',
	});

	const { patientId } = useParams() as { patientId: string };
	const { data, loading } = useGetBatteryResults(patientId);

	if (loading) {
		return null;
	}
	if (!data?.participant) {
		return <FileNotFound />;
	}

	function handleNoteTemplateClose() {
		setNoteTemplate({ open: false, batteryResultId: '' });
	}

	function handleNoteTemplateOpen(batteryResultId: string) {
		setNoteTemplate({ open: true, batteryResultId });
	}

	const patientBatteryResults = mapData(data?.participant);
	return (
		<StyledContainer>
			<StyledContent>
				<Header />
				<LinusDataTable
					title={t`web.sessionHistory`}
					titleIcon={icons.Results}
					columns={columns(
						theme,
						dateFormat,
						defaultTimezone,
						features,
						handleNoteTemplateOpen
					)}
					tableData={patientBatteryResults}
					operations={[]}
					hideFooter={true}
					headerColor={theme.color.headerTextSecondary}
					deviantWidth='1090px'
					rowsPerPage={patientBatteryResults.length}
				/>
			</StyledContent>
			<AnimatePresence>
				{loading ? null : (
					<ParticipantInfo
						participantId={patientId || ''}
						dateFormat={dateFormat}
					/>
				)}
			</AnimatePresence>
			{noteTemplate.open && (
				<NoteTemplate
					batteryDataLoading={loading}
					batteryData={data}
					participantId={patientId}
					batteryResultId={noteTemplate.batteryResultId}
					orgId={currentUser.organizationId}
					onClose={handleNoteTemplateClose}
				/>
			)}
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	display: flex;
`;

const StyledContent = styled.div`
	width: calc(100% - 248px);
`;
