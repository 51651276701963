import { t } from 'i18n';
import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../metrics';

export const getCompositeScalesDataSet = (dataMap: MetricHash): DataSet => {
	// Parse the map and get values of interest in chart-friendly data structure
	const compositeScalesMap = {
		commandClock: [
			{
				x: compositeScalesStrings.drawingEfficiency,
				y: dataMap?.COMDrawingEfficiency_s?.value,
			},
			{
				x: compositeScalesStrings.motor,
				y: dataMap?.COMSimpleMotor_s?.value,
			},
			{
				x: compositeScalesStrings.infoProcessing,
				y: dataMap?.COMInformationProcessing_s?.value,
			},
			{
				x: compositeScalesStrings.spatialReasoning,
				y: dataMap?.COMSpatialReasoning_s?.value,
			},
		],
		copyClock: [
			{
				x: compositeScalesStrings.drawingEfficiency,
				y: dataMap?.COPDrawingEfficiency_s?.value,
			},
			{
				x: compositeScalesStrings.motor,
				y: dataMap?.COPSimpleMotor_s?.value,
			},
			{
				x: compositeScalesStrings.infoProcessing,
				y: dataMap?.COPInformationProcessing_s?.value,
			},
			{
				x: compositeScalesStrings.spatialReasoning,
				y: dataMap?.COPSpatialReasoning_s?.value,
			},
		],
	};

	return compositeScalesMap as DataSet;
};

export const compositeScalesTooltipKeys = [
	'web.dcrReport.tooltips.drawingEfficiency',
	'web.dcrReport.tooltips.simpleAndComplexMotor',
	'web.dcrReport.tooltips.informationProcessing',
	'web.dcrReport.tooltips.spatialReasoning',
];

export const compositeScalesStrings = {
	drawingEfficiency: t(`web.report.segmentUtils.drawingEfficiency`),
	motor: t(`web.report.segmentUtils.simpleComplexMotor`),
	infoProcessing: t(`web.report.segmentUtils.infoProcessing`),
	spatialReasoning: t(`web.report.segmentUtils.spatialReasoning`),
};
