import { useState } from 'react';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';
import { SessionModalIconModal } from './ModalIcon';

export interface ModalIconComponentProps {
	titleText: string;
	contentText: string;
}

export const ModalIconComponent = ({
	titleText,
	contentText,
}: ModalIconComponentProps): JSX.Element => {
	const [display, setDisplay] = useState(false);
	return (
		<div>
			<HeaderIcon
				/* eslint-disable react/prop-types */
				onClick={(event: React.MouseEvent) => {
					event.stopPropagation && event.stopPropagation();
					setDisplay(!display);
				}}
			/>
			{display && (
				<SessionModalIconModal
					titleText={titleText}
					contentText={contentText}
					/* eslint-disable react/prop-types */
					onClose={(props: any) => {
						props.stopPropagation && props.stopPropagation();
						setDisplay(false);
					}}
				/>
			)}
		</div>
	);
};
