import styled, { css } from 'styled-components';

import { P1, P2, P3 } from 'components/shared/designSystem';

export const SemiP1 = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const ContentWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
		flex-direction: column;
	`
);

export const Disclaimer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Prediction = styled(P2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const DisclaimerMedium = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const ScoreContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: ${spacing.sm};
	`
);

export const ScoreWrapper = styled.div(
	({ theme: { borderRadius, colors, spacing } }) => css`
		border-radius: ${borderRadius.searchBox};
		display: inline-flex;
		align-items: center;
		padding: ${spacing.xs} 20px;
		gap: ${spacing.xxs};
		background-color: ${colors.gray_80};
	`
);

export const Separator = styled.div(
	({ theme: { color, spacing } }) => css`
		width: 100%;
		border-bottom: 1px solid ${color.infoSeparator};
		margin-top: ${spacing.sm};
	`
);

export const Tag = styled.div(
	({ theme: { colors } }) => css`
		background-color: ${colors.purple_lightest};
		border-radius: 2px;
		padding: 2px 4px;
	`
);

export const TagText = styled(P3)(
	({ theme: { colors, fontSize, weight } }) => css`
		color: ${colors.purple_darker};
		font-size: ${fontSize._14};
		font-weight: ${weight.semi};
		text-transform: uppercase;
	`
);

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
