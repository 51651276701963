import { Typography } from '@lh/eng-web-mosaic-common';
import { Stack } from '@mantine/core';

import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

import { RichText } from '../RichText';
import { Title } from './ReportCardHeader.style';
import { ReportCardHeaderContainer } from './ReportCardHeaderContainer';
import { theme } from 'components/providers/StyleProvider/theme';

type ReportCardHeaderProps = {
	title: string;
	tooltipText?: string | string[];
};

export function ReportCardHeader({
	title,
	tooltipText,
}: Readonly<ReportCardHeaderProps>) {
	if (Array.isArray(tooltipText)) {
		return (
			<ReportCardHeaderContainer>
				<Title>{title}</Title>
				<LinusTooltip
					overlay={
						<Stack gap={theme.spacing.md} maw={480}>
							{tooltipText.map((paragraph) => (
								<Typography.P2 key={paragraph}>
									<RichText>{paragraph}</RichText>
								</Typography.P2>
							))}
						</Stack>
					}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
							viewBox='0 0 20 20'
						/>
					}
				/>
			</ReportCardHeaderContainer>
		);
	}

	return (
		<ReportCardHeaderContainer>
			<Title>{title}</Title>
			{tooltipText && (
				<LinusTooltip
					overlay={
						<Stack maw={480}>
							<Typography.P2>
								<RichText>{tooltipText}</RichText>
							</Typography.P2>
						</Stack>
					}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
							viewBox='0 0 20 20'
						/>
					}
				/>
			)}
		</ReportCardHeaderContainer>
	);
}
