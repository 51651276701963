import { ValueListItem as ValueListItemRest } from '@lh/eng-platform-organization-service-rest-client';

import titleize from 'titleize';
import { EnumItem } from '../enums/enumeration';
import {
	ValueList,
	ValueListConnection,
	ValueListItem,
	ValueListType,
} from '../generated/graphql';
import { DropdownOption } from '../types';

export const humanizeValue = (value: string): string => {
	return value
		.split('_')
		.map((v) => titleize(v))
		.join(' ');
};

export type ValueListRollup = {
	[key in ValueListType]: DropdownOption[];
};

export const inlineSuffix = (suffixes: ValueListItemRest[] = []): string => {
	const joinedSuffixes = suffixes.map((suffix) => suffix.display).join(', ');
	// A list of joined suffixes should be prepended will a leading comma e.g. ', RN, MD, PHD'.
	// Resulting in a display of "Bubba Jones, RN, MD, PHD"
	return joinedSuffixes.length > 0 ? `, ${joinedSuffixes}` : '';
};

export const rollupValueLists = (nodes: ValueList[]): ValueListRollup => {
	return (nodes || []).reduce((acc, vl) => {
		const collectionType = acc[vl.type];
		const dropdownOption = {
			value: vl.id,
			display: vl.name || humanizeValue(vl.type),
		};
		if (collectionType) {
			collectionType.push(dropdownOption);
		} else {
			acc[vl.type] = [dropdownOption];
		}
		return acc;
	}, {} as ValueListRollup);
};

export const participantLanguageToOrgDisplayTransform = (
	v: ValueListItem
): EnumItem => {
	return {
		...v,
		value: v.id,
		key: v.value,
	} as EnumItem;
};

export const getValueListItems = (
	valueLists: ValueListConnection,
	valueListType: ValueListType
): ValueListItem[] => {
	const list = (valueLists?.nodes || []).find(
		(list: ValueList) => list.type === valueListType
	);
	return !list ? [] : list.items.nodes;
};

export const participantLanguageToPatientDisplayTransform = (
	v: ValueListItemRest
): EnumItem => {
	return {
		value: v.value?.split('|')[0] || '',
		key: v.displayKey || v.display || '',
		display: v.displayKey || v.display,
		longDisplay: v.longDisplay || v.display,
	} as EnumItem;
};

export const voidListener = () => {
	return null;
};

export function orderValueLists(items: ValueListItemRest[]) {
	let orderedList = [...items];
	const atTimeOfTestingElement = orderedList.find(
		(e) => e.value === 'TO_BE_COLLECTED_AT_TIME_OF_TESTING'
	);

	if (atTimeOfTestingElement) {
		orderedList = [
			...orderedList.filter((e) => e !== atTimeOfTestingElement),
			atTimeOfTestingElement,
		];
	}

	return orderedList;
}
