import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useReasonsQuery } from 'api/organization';
import { UserContext } from 'components/context';
import {
	MAX_ATTEMPTS,
	POLLING_INTERVAL,
} from 'components/report/AssessmentComponents/MiniModal/MiniModalOverview/MiniModalOverview.utils';
import {
	BatteryAssignment,
	BatteryIssue,
	Report,
} from 'components/report/CognitiveEvaluation.types';
import {
	FieldType,
	getReasonsByOrgFields,
} from 'components/report/NoteTemplate/NoteTemplate.controller';
import { Icon, LoadingButton } from 'components/shared/designSystem';
import {
	getUFSurveyJson,
	useOrganizationFeatures,
	useUFSurvey,
} from 'components/shared/hooks';
import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { icons } from 'enums/icons';
import { sex } from 'enums/participantEnums/sex';
import { Auth } from 'features/auth-service';
import {
	BatteryHeaderFragment,
	BatteryType,
	IssueType,
	ReportType,
} from 'generated/graphql';
import { ERROR } from 'logging/linusLogger';
import {
	dateFormatter,
	utcDateFormatter,
} from 'stringStrategy/dateFormatStringStrategy';
import { onGetReportData, openDataAsBlob } from 'utils/reportUtils';
import { inlineSuffix } from 'utils/valueListUtils';
import {
	PDFDownloadLinkResponse,
	PdfGenerateInput,
} from '../PdfDownloadService';

import { AnalyticsLanguage } from './BatteryHeader.analytics';
import {
	getCarePartnerActionPlanName,
	getPatientReportName,
	getProviderReportName,
	shouldGenerateCarePartnerReport,
	shouldGeneratePatientReport,
	showReasonsForTesting,
	showSessionObservations,
	showTaAttestation,
} from './BatteryHeader.helpers';
import {
	BodyContainer,
	Container,
	Header,
	HeaderContainer,
	PDFContainer,
	PatientContainer,
	PrintOnlyLogo,
	TextContainer,
	TextContent,
	TextTitle,
	Title,
} from './BatteryHeader.style';
import { TestAdministratorNotes } from './TestAdministratorNotes/TestAdministratorNotes';
import { ReasonsForTesting } from './TestAdministratorNotes/TestAdministratorNotes.interface';

export interface BatteryHeaderProps {
	batteryAssignment?: BatteryAssignment;
	batteryIssue?: BatteryIssue;
	batteryTitle: string;
	batteryResultId: string;
	batteryResult?: Report;
	batteryParticipant?: BatteryHeaderFragment;
	batteryType: BatteryType;
	isTest?: boolean;
}

export const BatteryHeader = ({
	batteryAssignment,
	batteryIssue,
	batteryTitle,
	batteryType,
	batteryResultId,
	batteryResult,
	batteryParticipant,
	isTest = false,
}: BatteryHeaderProps) => {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();
	const organizationFeatures = useOrganizationFeatures();

	const { t } = useTranslation();
	const hasUFSurvey = useUFSurvey();

	const [attestation, setAttestation] = useState('');
	const [reasonsForTesting, setReasonsForTesting] =
		useState<ReasonsForTesting>({ data: [], note: '' });

	const generateReportInputRef = useRef<PdfGenerateInput | null>(null);

	const reasonForTestingCodes = batteryAssignment?.reason?.codes ?? [];
	const { data: reasonsForTestingData } = useReasonsQuery({
		organizationId: currentUser.organizationId,
		filterValue: reasonForTestingCodes,
		filterField: getReasonsByOrgFields(
			reasonForTestingCodes.length,
			FieldType.FilterField
		),
		filterOperator: getReasonsByOrgFields(
			reasonForTestingCodes.length,
			FieldType.FilterOperator
		),
		logicalOperator: getReasonsByOrgFields(
			reasonForTestingCodes.length,
			FieldType.LogicalOperator
		),
	});

	const hasPatientReport = shouldGeneratePatientReport(
		organizationFeatures,
		batteryResult
	);

	const hasCarePartnerReport = shouldGenerateCarePartnerReport(
		organizationFeatures,
		batteryResult
	);

	const {
		firstName: providerFirstName,
		lastName: providerLastName,
		suffix: providerSuffix,
	} = batteryAssignment?.interpretingUser ?? {
		firstName: t`web.report.cognitiveEvaluation.noInterpretingUser.firstName`,
		lastName: t`web.report.cognitiveEvaluation.noInterpretingUser.lastName`,
	};
	const dateFormat = currentUser.organizationDateFormat ?? 'MM/dd/yyyy';
	const defaultTimezone = currentUser.organizationDefaultTimezone;
	const dateTaken =
		dateFormatter(
			batteryResult?.batteryResultById?.endTime ?? '',
			dateFormat,
			defaultTimezone
		) ?? '';
	const displaySuffix = inlineSuffix(providerSuffix) ?? '';
	const dob =
		utcDateFormatter(batteryParticipant?.birthDate, dateFormat) ?? '';

	const patientSex =
		sex.fromValue(batteryParticipant?.sexAssignedAtBirth)?.display ?? '';
	const patientName =
		batteryParticipant?.firstName && batteryParticipant?.lastName
			? `${batteryParticipant?.firstName} ${batteryParticipant?.lastName}`
			: '';
	const providerName =
		providerFirstName && providerLastName
			? `${providerFirstName} ${providerLastName}${displaySuffix}`
			: '';

	useEffect(() => {
		async function getAccordionData() {
			if (batteryAssignment) {
				if (batteryAssignment.attestation) {
					const minutesTranslation = t`web.minutes`;
					const testingTimeRegex = new RegExp(
						`(\\d+\\s?${minutesTranslation})`
					);
					setAttestation(
						batteryAssignment.attestation.attestationNote.replace(
							testingTimeRegex,
							'<b>$1</b>'
						)
					);
				}

				setReasonsForTesting({
					data:
						reasonsForTestingData &&
						reasonForTestingCodes.length > 0
							? reasonsForTestingData
							: [],
					note: batteryAssignment?.reason?.note,
				});
			}
		}

		getAccordionData();
	}, [batteryAssignment, reasonsForTestingData]);

	function getReportName(reportType: ReportType) {
		if (reportType === ReportType.CarePartnerReportActionPlan) {
			return getCarePartnerActionPlanName(batteryType, batteryTitle);
		}

		if (
			reportType === ReportType.ProviderReport ||
			reportType === ReportType.ProviderReportWithCds
		) {
			return getProviderReportName(
				batteryType,
				organizationFeatures,
				batteryTitle
			);
		}

		return getPatientReportName(batteryType, batteryTitle);
	}

	async function handlePollingSuccess(response: PDFDownloadLinkResponse) {
		if (!response?.downloadLink) return;
		if (generateReportInputRef.current) {
			sendEventData({
				eventType: AnalyticsAction.DownloadedReport,
				eventProperties: {
					downloadType: 'print PDF',
					language:
						AnalyticsLanguage[
							generateReportInputRef.current.language
						],
					reportName: getReportName(
						generateReportInputRef.current.reportType
					),
					batteryResultId,
					source: 'Results page',
				},
			});
		}
		if (
			response.downloadLink.match('response-content-disposition=inline')
		) {
			//If the response content disposition is inline (meant to be previewed before downloading)
			window.open(response.downloadLink, '_blank');
		} else {
			//If the file is meant to be downloaded directly.
			window.location.assign(response.downloadLink);
		}
	}

	const showTaNotes =
		showSessionObservations(organizationFeatures, batteryIssue) ||
		showReasonsForTesting(organizationFeatures, reasonsForTesting) ||
		showTaAttestation(organizationFeatures, attestation);

	return (
		<Container>
			<HeaderContainer>
				<Title>
					<Header>{`${batteryTitle}`}</Header>
				</Title>
				<PrintOnlyLogo>
					<Icon icon={icons.Logo} />
				</PrintOnlyLogo>
				<PDFContainer>
					{(hasCarePartnerReport || isTest) && (
						<ShowIfAuthorized
							operations={[OperationToken.CreateReport]}
						>
							<LoadingButton
								text={t('web.patients.carePartner.actionPlan')}
								interval={POLLING_INTERVAL}
								maxAttempts={MAX_ATTEMPTS}
								onGetData={() =>
									onGetReportData(
										Auth,
										batteryResultId,
										currentUser,
										batteryParticipant,
										'CalculateCarePartnerReport',
										(input) => {
											generateReportInputRef.current =
												input;
										}
									)
								}
								onPollSuccess={handlePollingSuccess}
							/>
						</ShowIfAuthorized>
					)}
					{(hasPatientReport || isTest) && (
						<ShowIfAuthorized
							operations={[OperationToken.CreateReport]}
						>
							<LoadingButton
								width={250}
								text={t`web.report.cognitiveEvaluation.batteryHeader.patientReportActionPlanButton`}
								interval={POLLING_INTERVAL}
								maxAttempts={MAX_ATTEMPTS}
								onGetData={() =>
									onGetReportData(
										Auth,
										batteryResultId,
										currentUser,
										batteryParticipant,
										'CalculatePatientReport',
										(input) => {
											generateReportInputRef.current =
												input;
										}
									)
								}
								onPollSuccess={handlePollingSuccess}
							/>
						</ShowIfAuthorized>
					)}
					<ShowIfAuthorized
						operations={[OperationToken.CreateReport]}
					>
						<LoadingButton
							text={t`web.report.cognitiveEvaluation.batteryHeader.providerReportButton`}
							interval={POLLING_INTERVAL}
							maxAttempts={MAX_ATTEMPTS}
							onGetData={() =>
								onGetReportData(
									Auth,
									batteryResultId,
									currentUser,
									batteryParticipant,
									'CalculateProviderReport',
									(input) => {
										generateReportInputRef.current = input;
									}
								)
							}
							onPollSuccess={handlePollingSuccess}
						/>
					</ShowIfAuthorized>
					{hasUFSurvey && batteryResultId && (
						<ShowIfAuthorized
							operations={[OperationToken.CreateReport]}
						>
							<LoadingButton
								text={t`Custom Data`}
								interval={POLLING_INTERVAL}
								maxAttempts={MAX_ATTEMPTS}
								onGetData={async () =>
									getUFSurveyJson(batteryResultId, client)
								}
								onPollSuccess={async (response: any) => {
									if (response) {
										openDataAsBlob({
											data: response,
											title: `UF-linus-survey-${Date.now()}.json`,
											type: 'application/json',
										});
									}
								}}
								onPollFailure={(err) => {
									ERROR('Failed to fetch ', { err });
									return '' as never;
								}}
							/>
						</ShowIfAuthorized>
					)}
				</PDFContainer>
			</HeaderContainer>
			<BodyContainer>
				<PatientContainer>
					<TextContainer>
						<TextTitle>
							{t`web.report.cognitiveEvaluation.batteryHeader.patientLabel`}
						</TextTitle>
						<TextContent>{patientName}</TextContent>
					</TextContainer>
					<TextContainer>
						<TextTitle>
							{t`web.report.cognitiveEvaluation.batteryHeader.dateOfBirthLabel`}
						</TextTitle>
						<TextContent>{dob}</TextContent>
					</TextContainer>
					<TextContainer>
						<TextTitle>
							{t`web.report.cognitiveEvaluation.batteryHeader.sexLabel`}
						</TextTitle>
						<TextContent>{patientSex}</TextContent>
					</TextContainer>
					<TextContainer>
						<TextTitle>
							{t`web.report.cognitiveEvaluation.batteryHeader.providerLabel`}
						</TextTitle>
						<TextContent>{providerName}</TextContent>
					</TextContainer>
					<TextContainer>
						<TextTitle>
							{t`web.report.cognitiveEvaluation.batteryHeader.dateTakenLabel`}
						</TextTitle>
						<TextContent>{dateTaken}</TextContent>
					</TextContainer>
				</PatientContainer>
				{(showTaNotes || isTest) && (
					<TestAdministratorNotes
						attestation={
							showTaAttestation(organizationFeatures, attestation)
								? attestation
								: ''
						}
						reasonsForTesting={reasonsForTesting}
						sessionObservationsProps={{
							issues: batteryIssue?.options as IssueType[],
							additionalNotes: batteryIssue?.note,
							reporter: batteryIssue?.reporter,
						}}
						showReasonsForTesting={showReasonsForTesting(
							organizationFeatures,
							reasonsForTesting
						)}
						showSessionObservations={showSessionObservations(
							organizationFeatures,
							batteryIssue
						)}
					/>
				)}
			</BodyContainer>
		</Container>
	);
};
