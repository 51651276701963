import { t } from 'i18n';
import * as yup from 'yup';

export type ResetPasswordModel = {
	password: string;
	repeatPassword: string;
};
export const getModel = (): ResetPasswordModel => {
	return {
		password: '',
		repeatPassword: '',
	};
};

export const resetPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.min(8, t`web.authErrors.passwordTooShort`)
		.matches(/[a-z]/, t`web.shared.forms.schemas.lowercaseRequired`)
		.matches(/[A-Z]/, t`web.shared.forms.schemas.uppercaseRequired`)
		.matches(/\d/, t`web.shared.forms.schemas.numberRequired`)
		.matches(
			/[$&+,~_`:#;=?[\]@|{}'<>".^*()%!/\\-]/,
			t`web.authErrors.passwordOneSpecialChar`
		)
		.required(t`web.authErrors.passwordRequired`),
	repeatPassword: yup
		.string()
		.required(t`web.authErrors.passwordRequired`)
		.oneOf(
			[yup.ref('password'), null],
			t`web.authErrors.passwordNotMatching`
		),
});
