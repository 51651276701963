import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getReportLabelBreadcrumb } from 'utils/reportText';
import { BatteryType } from '../../../generated/graphql';
import { CDSContext } from '../../context/CDSContext';

interface Props {
	batteryType: Readonly<BatteryType>;
	batteryName?: string;
	patient?: Readonly<{
		firstName?: string;
		lastName?: string;
	}>;
}

export function Breadcrumbs({
	batteryType,
	batteryName,
	patient,
}: Readonly<Props>) {
	const { contentVersion } = useContext(CDSContext);
	const { participantId, reportId, contentId } = useParams() as {
		participantId: string;
		reportId: string;
		contentId: string;
	};
	const { t } = useTranslation();

	const reportText = getReportLabelBreadcrumb({
		batteryType,
		batteryName,
	});

	// Edge case for Motor Dysfunction Articles that have additional info after hyphen (e.g. Recommendations for Motor Dysfunction Concern – Parkinsonian)
	// Removing the additional info after hyphen because the same CDS article displayed in both cases
	const articleName = t(
		`cds.recommendations.${contentId}.caption_${contentVersion}`
	).split('–')[0];

	if (!patient) {
		return null;
	}

	return (
		<StyledBreadCrumbContainer>
			<StyledBreadCrumb isLink={true}>
				<StyledLink>
					<Link to={'/results'}>{t`web.enums.routes.results`}</Link>
					<StyledSlash>{` / `}</StyledSlash>
				</StyledLink>
			</StyledBreadCrumb>
			<StyledBreadCrumb isLink={true}>
				<StyledLink>
					<Link to={`/patients/${participantId}`}>
						{patient?.firstName} {patient?.lastName}
					</Link>
					<StyledSlash>{` / `}</StyledSlash>
				</StyledLink>
			</StyledBreadCrumb>
			{contentId ? (
				<StyledBreadCrumb isLink={true} data-testid='cceReportTextLink'>
					<StyledLink>
						<Link to={`/results/${participantId}/${reportId}`}>
							{reportText}
						</Link>
						<StyledSlash>{` / `}</StyledSlash>
					</StyledLink>
				</StyledBreadCrumb>
			) : (
				<StyledBreadCrumb isLink={false} data-testid='cceReportText'>
					{reportText}
				</StyledBreadCrumb>
			)}
			{contentId && (
				<StyledBreadCrumb
					isLink={false}
					data-testid='articleTextWithoutLink'
				>
					{articleName}
				</StyledBreadCrumb>
			)}
		</StyledBreadCrumbContainer>
	);
}

const StyledBreadCrumbContainer = styled.div(
	({ theme: { spacing } }) => css`
		margin: 0 ${spacing.lg} ${spacing.xl} 0;
		display: flex;
		flex-direction: row;
		padding-left: 2px;
	`
);

type StyledBreadCrumbProps = {
	isLink: boolean;
};

const StyledBreadCrumb = styled.div<StyledBreadCrumbProps>(
	({ theme: { spacing, weight, fontSize, color }, isLink }) => css`
		margin-right: ${spacing.sm};
		font-weight: ${weight.medium};
		font-size: ${fontSize._16};
		line-height: 19px;
		letter-spacing: 0.5px;
		display: inline;
		color: ${isLink ? color.link : color.linkDisabled};
	`
);

const StyledLink = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledSlash = styled.div(
	({ theme: { color } }) => css`
		color: ${color.linkDisabled} !important;
		margin-left: 5px;
	`
);
