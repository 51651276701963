import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import styled from 'styled-components';
import { icons } from '../../../enums/icons';
import { truncateString } from '../../../utils/stringUtils';
import { UserContext } from '../../context/UserContext';
import { LinusTooltip } from '../LinusTooltip';
import { LoadingDots } from '../LoadingDots';
import { Avatar } from '../avatar/Avatar';
import { Icon } from '../designSystem/Icon';
import { UserNavigationMenu } from './UserNavigationMenu';

type UserNavigationProps = {
	isCollapsed?: boolean;
	setIsCollapsed: Dispatch<SetStateAction<boolean>>;
};

const UserNavigation = ({
	isCollapsed,
	setIsCollapsed,
}: UserNavigationProps): JSX.Element => {
	const [showProfileControls, setShowProfileControls] =
		useState<boolean>(false);

	//Open the menu if it's closed onclick
	const openMenu = () => {
		setIsCollapsed(!isCollapsed);
	};

	useEffect(() => {
		if (isCollapsed) {
			setShowProfileControls(false);
		}
	}, [isCollapsed]);

	const handleMenu = () => setShowProfileControls(!showProfileControls);

	const { currentUser } = useContext(UserContext);
	if (!currentUser) return <LoadingDots />;
	const { avatarUrl, firstName, lastName } = currentUser;

	const truncatedName = `${truncateString(firstName, 10)} ${truncateString(
		lastName,
		10
	)}`;
	return (
		<StyledNavBar>
			<StyledNavBarContent
				$isCollapsed={isCollapsed}
				onClick={isCollapsed ? openMenu : handleMenu}
				id='navbar'
				data-id='navbar'
			>
				<Avatar
					data-id='avatar'
					imageSrc={avatarUrl}
					firstName={firstName}
					lastName={lastName}
				/>
				{!isCollapsed && (
					<>
						<StyledFullName data-id='user-name'>
							{firstName.length > 10 || lastName.length > 10 ? (
								<LinusTooltip
									overlay={
										<div>{firstName + ' ' + lastName}</div>
									}
									tooltipIcon={<div>{truncatedName}</div>}
								/>
							) : (
								firstName + ' ' + lastName
							)}
						</StyledFullName>
						{showProfileControls ? (
							<>
								<StyledArrow
									dataId='user-menu-up-arrow'
									icon={icons.ArrowUp}
								/>
								<UserNavigationMenu />
							</>
						) : (
							<StyledArrow
								dataId='user-menu-down-arrow'
								icon={icons.ArrowDown}
							/>
						)}
					</>
				)}
			</StyledNavBarContent>
		</StyledNavBar>
	);
};

export { UserNavigation };

const StyledNavBar = styled.div`
	margin-top: auto;
	width: 100%;
	position: relative;
`;

type StyledNavbarContentProps = {
	$isCollapsed?: boolean;
};

const StyledNavBarContent = styled.div<StyledNavbarContentProps>(
	({ $isCollapsed }) => `
	display: flex;
	flex-direction: row;
	justify-content: ${$isCollapsed ? 'center' : 'space-between'};
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`
);

const StyledFullName = styled.div(
	({ theme: { fontSize, color } }) => `
    width: 110px; 
    font-size: ${fontSize._16};
    color: ${color.menuText};
	user-select: none;
`
);

const StyledArrow = styled(Icon)`
	user-select: none;
`;
