import { useQuery } from '@tanstack/react-query';

import { getPatientSubService } from 'api/getPatientSubService';
import { QueryKey } from 'api/query';

export async function getPatient(
	patientId: string,
	organizationId: string,
	signal?: AbortSignal
) {
	if (!patientId) {
		throw new Error("useGetPatientQuery: patientId can't be null");
	}

	if (!organizationId) {
		throw new Error("useGetPatientQuery: organizationId can't be null");
	}

	const service = await getPatientSubService();
	const { data } = await service.getPatientByOrgIdAndUuid(
		{
			subjectId: patientId,
			organizationId,
		},
		{ signal }
	);
	return data;
}

export function usePatientQuery(patientId: string, organizationId: string) {
	return useQuery({
		enabled: !!patientId && !!organizationId,
		meta: {
			errorMessage: 'Error fetching patient',
		},
		queryKey: [QueryKey.Patient, patientId, organizationId],
		queryFn: ({ signal }) => getPatient(patientId, organizationId, signal),
		staleTime: Infinity,
	});
}
