import { useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getBatteryIssue(batteryResultId: string) {
	const service = await getBatteryService();
	const { data } = await service.getBatteryResultIssue({ batteryResultId });
	return data;
}

export function useBatteryIssueQuery(batteryResultId: string) {
	return useQuery({
		enabled: !!batteryResultId,
		meta: {
			errorMessage: `Error fetching battery issue for ${batteryResultId}`,
		},
		queryKey: [QueryKey.BatteryIssue, batteryResultId],
		queryFn: () => getBatteryIssue(batteryResultId),
		staleTime: Infinity,
	});
}
