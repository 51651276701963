import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getOptionalFields(organizationId: string) {
	if (!organizationId) {
		throw new Error(
			"useGetOptionalFieldsQuery: organizationId can't be null"
		);
	}

	const service = await getOrganizationService();
	const { data } = await service.getVisibleOptionalFields({ organizationId });
	return data;
}

export function useOptionalFieldsQuery(organizationId: string) {
	return useQuery({
		enabled: !!organizationId,
		meta: {
			errorMessage: `Error fetching organization's ${organizationId} optional fields`,
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.OptionalFields, organizationId],
		queryFn: () => getOptionalFields(organizationId),
		staleTime: Infinity,
	});
}
