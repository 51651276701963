import { H4 } from 'components/shared/designSystem';

import { GdsScoreContainer, H2Med, ScoreContainer } from './GadScore.style';

export const GAD_7_MAX_TOTAL_SCORE = '21';

type GadScoreProps = {
	score: string;
	color: string;
	text: string;
};

export function GadScore({ score, color, text }: Readonly<GadScoreProps>) {
	return (
		<GdsScoreContainer $backgroundColor={color}>
			<Score score={score} color={color} />
			<H4>{text}</H4>
		</GdsScoreContainer>
	);
}

type ScoreProps = {
	score: string;
	color: string;
};

function Score({ score, color }: Readonly<ScoreProps>) {
	return (
		<ScoreContainer $backgroundColor={color}>
			<H2Med>{`${score}/${GAD_7_MAX_TOTAL_SCORE}`}</H2Med>
		</ScoreContainer>
	);
}
