import { Auth } from '@lh/eng-shared-auth';

import { pollPromiseForPDFLink } from 'components/report/BatteryComponents/PdfDownloadService';
import {
	BatteryHeaderFragment,
	FeatureType,
	ReportType,
} from 'generated/graphql';
import { CurrentUser } from 'types';

const TO_BE_COLLECTED = 'TO_BE_COLLECTED_AT_TIME_OF_TESTING';

type ReportInput = {
	organizationId: string;
	batteryResultId: string;
	language: string;
	reportType:
		| 'PATIENT_REPORT_ACTION_PLAN'
		| 'PROVIDER_REPORT'
		| 'PROVIDER_REPORT_WITH_CDS'
		| 'CARE_PARTNER_REPORT_ACTION_PLAN';
};

function determineReportLanguage(
	currentUser: CurrentUser,
	reportType: ReportType,
	reportData?: BatteryHeaderFragment
) {
	if (
		reportType === ReportType.PatientReport ||
		reportType === ReportType.PatientReportActionPlan ||
		reportType === ReportType.CarePartnerReportActionPlan
	) {
		const participantLanguage = reportData?.language || TO_BE_COLLECTED;
		return participantLanguage !== TO_BE_COLLECTED
			? participantLanguage
			: currentUser.organizationDefaultUserLocale.display || '';
	} else {
		return currentUser.organizationDefaultUserLocale.display || '';
	}
}

export function determineReportType(
	currentUser: CurrentUser,
	type?:
		| 'CalculatePatientReport'
		| 'CalculateProviderReport'
		| 'CalculateCarePartnerReport'
) {
	if (type === 'CalculatePatientReport') {
		return ReportType.PatientReportActionPlan;
	}

	if (type === 'CalculateCarePartnerReport') {
		return ReportType.CarePartnerReportActionPlan;
	}

	const hasCDS = currentUser.organizationFeatures.includes(
		FeatureType.GenerateProviderReportWithCds
	);

	const providerReport = hasCDS
		? ReportType.ProviderReportWithCds
		: ReportType.ProviderReport;

	return providerReport;
}

export function onGetReportData(
	auth: Auth,
	batteryResultId: string,
	currentUser: CurrentUser,
	reportData?: BatteryHeaderFragment,
	type?:
		| 'CalculatePatientReport'
		| 'CalculateProviderReport'
		| 'CalculateCarePartnerReport',
	onRetrieveInput?: (input: ReportInput) => void
) {
	const reportType = determineReportType(currentUser, type);
	const language = determineReportLanguage(
		currentUser,
		reportType,
		reportData
	);

	const reportInput: ReportInput = {
		organizationId: currentUser.organizationId,
		batteryResultId,
		reportType,
		language,
	};

	onRetrieveInput?.(reportInput);

	return pollPromiseForPDFLink(auth, reportInput);
}

/**
 * @param param0 The data for the file
 * @returns It transforms the data from a JSON to a file object.
 */
export function JSONToFile({
	data,
	title,
	type,
}: {
	data: any;
	title: string;
	type: string;
}) {
	return new File([JSON.stringify(data)], title, {
		type,
	});
}

/**
 * @description Opens a given data payload as a JSON blob on a new tab and
 * returns the Object URL.
 * @param param0 The provided data as JSON.
 * @returns The object URL
 */
export function openDataAsBlob({
	data,
	title,
	type = 'application/json',
}: {
	data: any;
	title: string;
	type?: string;
}) {
	const url = URL.createObjectURL(JSONToFile({ data, title, type }));
	window.open(url, '_blank');
	return url;
}
