import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useOptionalFieldsQuery } from 'api/organization';
import { useCreatePatientMutation } from 'api/patient';
import { Input, PatientForm } from 'components/patients/PatientForm.types';
import { AlertError } from 'components/shared/Forms/Components/AlertError';
import { LoadingDots } from 'components/shared/LoadingDots';
import { groupIntoN } from 'utils/arrays';
import { MessageEnumItem, messageEnum } from '../../enums/messageEnum';
import { ERROR } from '../../logging/linusLogger';
import { UserContext } from '../context/UserContext';
import { AddBatteryProps } from '../shared/DataTable/schemas/patientsSchema';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import {
	AddParticipantModel,
	addPatientSchemaWithFormats,
	nullOParticipant,
} from '../shared/Forms/Schemas/addParticipantSchema';
import { InfoMessage } from '../shared/InfoMessage';
import { ButtonLg } from '../shared/designSystem/ButtonLg';
import { ERROR_CODES, messages } from '../shared/errorMessages';

import { createDynamicInputs, createPatient } from './AddPatientFormLogic';
import { useInputs } from './PatientForm.utils';

export type AddPatientFormProps = {
	initialValues?: AddPatientModel;
	onCancel: () => void;
	onFinish?: () => void;
	setPatient: (p: AddBatteryProps) => void;
};

export type AddPatientModel = AddParticipantModel & {
	notes: string;
};

const nullOPatient = Object.assign(nullOParticipant, { notes: '' });

export const AddPatientForm = ({
	initialValues = nullOPatient,
	onCancel,
	onFinish,
	setPatient,
}: AddPatientFormProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	const { t } = useTranslation();

	const { data: optionalFields } = useOptionalFieldsQuery(
		currentUser.organizationId
	);
	const { mutateAsync: createPatientMutation, isPending: loading } =
		useCreatePatientMutation();

	const initialInputs = useInputs(currentUser, PatientForm.Add);

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const [inputList, setInputList] = useState<Input[] | null>(null);

	const dateFormat =
		currentUser?.organizationDateFormat?.toUpperCase() || 'MM/DD/YYYY';

	useEffect(() => {
		async function getDynamicInputs() {
			const dynamicInputs = await createDynamicInputs(
				initialInputs,
				optionalFields
			);
			setInputList(dynamicInputs);
		}

		if (!inputList && currentUser && optionalFields) {
			getDynamicInputs();
		}
	}, [currentUser, inputList, optionalFields]);

	const onSubmit = async (
		values: AddPatientModel,
		{ setSubmitting }: FormikHelpers<AddPatientModel>
	) => {
		try {
			const patient = await createPatient(
				currentUser.organizationId,
				values,
				createPatientMutation
			);

			if (!patient) {
				setServerSideMessage(
					messageEnum.error(messages.mutationPayloadError)
				);
				setSubmitting(false);
				return;
			}

			const { id, firstName, lastName, organizationId } = patient;

			setPatient({
				id,
				firstName,
				lastName,
				newPatient: true,
				organizationId,
			} as AddBatteryProps);
			sendEventData({ eventType: AnalyticsAction.AddedNewPatient });
			onFinish?.();
			setSubmitting(false);
		} catch (err) {
			ERROR(
				`Error trying to create a patient from AddPatientForm, organizationId: ${currentUser.organizationId}`,
				err
			);

			setSubmitting(false);

			//@ts-expect-error error is an AxiosError that typescript doesn't know about
			if (ERROR_CODES[err?.response?.data?.errorCode]) {
				setServerSideMessage(
					messageEnum.error(
						//@ts-expect-error error is an AxiosError that typescript doesn't know about
						ERROR_CODES[err?.response?.data?.errorCode]
					)
				);
				return;
			}

			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
			return;
		}
	};

	if (inputList === null) {
		return <LoadingDots />;
	}

	function getButtonState(isSubmitting: boolean) {
		if (isSubmitting || !currentUser.organizationValueLists) {
			return true;
		}

		return false;
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={addPatientSchemaWithFormats(dateFormat)}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, touched, errors, isValid, submitCount }) => {
				return (
					<>
						{!isValid && submitCount !== 0 && (
							<AlertContainer>
								<AlertError
									text={t(
										'web.participant.forms.errors.missingInfo'
									)}
								/>
							</AlertContainer>
						)}
						<StyledForm data-testid='addems'>
							{inputList &&
								groupIntoN(inputList, 2).map((row) => (
									<StyledRow
										key={`${String(row[0].name)}-row`}
									>
										{row.map((input) => {
											let showError = false,
												isTouched = false;
											if (
												errors[
													input.name as keyof AddPatientModel
												]
											) {
												showError = true;
											}
											if (
												touched[
													input.name as keyof AddPatientModel
												]
											) {
												isTouched = true;
											}

											return (
												<LinusInput
													err={showError}
													isTouched={isTouched}
													key={input.name}
													name={input.name}
													label={input.label}
													helperText={
														!showError
															? input.helperText
															: !isTouched
															? input.helperText
															: ''
													}
													clearable={input.clearable}
													type={input.type}
													dropdownOptions={
														input.dropdownOptions
													}
													dropUpSpace={
														input.dropUpSpace
													}
													preferDisplayLength={
														input.preferDisplayLength
													}
												/>
											);
										})}
									</StyledRow>
								))}
							<StyledButtonAndErrorRow>
								<StyledInfoMessage>
									<InfoMessage
										messageEnum={serverSideMessage}
										showIf={!!serverSideMessage}
									/>
								</StyledInfoMessage>
								<StyledButtonRow>
									<StyledButtonWrapper>
										<ButtonLg
											onClick={onCancel}
											text={t`web.patients.forms.cancelCTA`}
											width='200px'
										/>
									</StyledButtonWrapper>
									<ButtonLg
										primary
										disabled={getButtonState(isSubmitting)}
										loading={loading}
										text={t`web.patients.forms.saveCTA`}
										type='submit'
										width='200px'
									/>
								</StyledButtonRow>
							</StyledButtonAndErrorRow>
						</StyledForm>
					</>
				);
			}}
		</Formik>
	);
};

const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 667px;
`;

const StyledRow = styled.div(
	({ theme: { color } }) => css`
		display: flex;
		justify-content: space-between;

		[name='birthDate'] {
			height: fit-content;
			margin-bottom: 0;
		}

		[name='birthDate'] input[data-error] {
			border-color: ${color.formError};
		}

		[name='birthDate'] div.mantine-DateInput-wrapper {
			margin-bottom: 0;
		}
	`
);

const StyledButtonRow = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledButtonWrapper = styled.div`
	margin: 0 25px 0 0;
`;

const StyledButtonAndErrorRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: ${spacing.xxxl} ${spacing.md} ${spacing.xl};
	`
);

const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => css`
		padding-bottom: ${spacing.md};
	`
);

export const AlertContainer = styled.div`
	width: 667px;
	margin-top: 8px;
	margin-bottom: 16px;
`;
