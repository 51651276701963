import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AssignmentIssueInput, IssueType } from '../../../../generated/graphql';
import { P1 } from '../../../shared/designSystem/TextComponents';

const OPTIONS_TO_TRANS_KEYS = {
	[IssueType.Declined]: 'battery.adminQnr.declined',
	[IssueType.Device]: 'battery.adminQnr.device',
	[IssueType.Fatigue]: 'battery.adminQnr.alertness',
	[IssueType.Hearing]: 'battery.adminQnr.hearing',
	[IssueType.Interrupt]: 'battery.adminQnr.interruption',
	[IssueType.Motor]: 'battery.adminQnr.drawing',
	[IssueType.NoResponse]: 'battery.adminQnr.gaveUp',
	[IssueType.TechStruggle]: 'battery.adminQnr.technology',
	[IssueType.Vision]: 'battery.adminQnr.vision',
	[IssueType.PrimaryLang]: 'battery.adminQnr.language',
};

export type Props = Omit<
	AssignmentIssueInput,
	'assignmentId' | 'issueContext'
> & {
	reporterName: string;
};

const BatteryTestingIssueDetails = ({
	options,
	note,
	reporterName,
}: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<StyledBatteryTestingIssueDetailsContainer>
			{options.length > 0 && (
				<StyledIssuesSection>
					<StyledIssuesReportedTitle>{t`web.shared.sessionIssues.issuesReportedAfterCompletion`}</StyledIssuesReportedTitle>
					<StyledList>
						{options.map((option) => (
							<StyledListItem key={option}>
								{t(OPTIONS_TO_TRANS_KEYS[option])}
							</StyledListItem>
						))}
					</StyledList>
				</StyledIssuesSection>
			)}
			{!!note && (
				<StyledNotesSection>
					<StyledNotesSectionTitle>
						{t`web.shared.sessionIssues.additionalNotes`}
					</StyledNotesSectionTitle>
					<StyledNotes>{note}</StyledNotes>
				</StyledNotesSection>
			)}
			<StyledReporterNameSection>
				{t`web.shared.sessionIssues.reportedByTextOnly`}:{' '}
				<StyledReporterContainer>
					<StyledReporterName>{reporterName}</StyledReporterName>
				</StyledReporterContainer>
			</StyledReporterNameSection>
		</StyledBatteryTestingIssueDetailsContainer>
	);
};

export { BatteryTestingIssueDetails };

const StyledBatteryTestingIssueDetailsContainer = styled.div(
	({ theme: { spacing } }) => css`
		max-width: 768px;
		margin-top: ${spacing.md};
		padding: ${spacing.md} 0px;
	`
);
const StyledIssuesSection = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.md};
	`
);
const StyledIssuesReportedTitle = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);
const StyledList = styled.ul`
	margin-left: 10px;
	columns: 2 auto;
`;
const StyledListItem = styled.li(
	({ theme: { spacing, fontSize } }) => css`
		font-size: ${fontSize._16};
		padding-top: ${spacing.sm};
		margin-left: ${spacing.md};
		list-style: disc;
	`
);
const StyledNotesSection = styled.div``;
const StyledNotesSectionTitle = styled(P1)(
	({ theme: { color, weight } }) => css`
		color: ${color.bodyTextSecondary};
		font-weight: ${weight.medium};
	`
);
const StyledNotes = styled(P1)(
	({ theme: { color } }) => css`
		margin-top: 12px;
		padding: 12px;
		border: 1px solid ${color.secondaryBorder};
		border-radius: 6px;
		white-space: pre-wrap;
		word-wrap: break-word;
	`
);
const StyledReporterNameSection = styled(P1)(
	({ theme: { color, spacing, weight } }) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: ${spacing.md};
		color: ${color.bodyTextSecondary};
		font-weight: ${weight.light};
	`
);

const StyledReporterContainer = styled.div(
	({ theme: { spacing, color } }) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: ${spacing.md};
		align-items: center;
		padding: ${spacing.xs} ${spacing.sm};
		gap: ${spacing.xs};
		height: ${spacing.xl};
		background: ${color.badgeBackgroundColor};
		border-radius: ${spacing.md};
		max-width: fit-content;
	`
);

const StyledReporterName = styled(P1)(
	({ theme: { weight } }) => css`
		display: inline;
		font-weight: ${weight.medium};
		text-transform: capitalize;
	`
);
