import {
	GradeMarks,
	QuestionTypes_instruction,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import {
	QnrRowData,
	getQuestionnaireTranslations,
} from 'components/report/CustomQuestionnaire.utils';

export function parseQuestions(qnrData: QuestionnaireSpecWithScoringResult) {
	const parsedAnswers: QnrRowData[] = [];

	const qnrTranslations = getQuestionnaireTranslations(qnrData);
	let textScore: string | number | undefined;

	if (qnrData) {
		qnrData.components.forEach((component) => {
			if (component.type !== QuestionTypes_instruction.INSTRUCTION) {
				component.choices.forEach((choice) => {
					parsedAnswers.push({
						question: qnrTranslations[choice.choiceI18nKey],
						answers: {
							grade:
								choice.answer?.grade ?? GradeMarks.NOT_GRADED,
							answerText: qnrTranslations[choice.choiceI18nKey],
							value: choice.answer?.score
								? choice.answer.score
								: 0,
						},
					});
					if (choice.answer?.score) textScore = choice.answer?.score;
				});
			}
		});
	}

	return {
		questions: parsedAnswers,
		translations: qnrTranslations,
		textScore,
	};
}
