import { Trans, useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { Icon } from '../../../shared/designSystem/Icon';
import {
	ButtonSmText,
	Caption,
	P1,
	P2,
	Subtitle,
} from '../../../shared/designSystem/TextComponents';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { forwardRef, Ref, useContext } from 'react';
import { generateCardLinkReplacements } from './CDSCardLink';
import { CDSContext } from '../../../context/CDSContext';

type CDSCardProps = {
	contentId: string;
	handleBHAPLink: (contentId: string) => void;
};

export const CDSCard = forwardRef(
	({ contentId, handleBHAPLink }: CDSCardProps, ref: Ref<HTMLDivElement>) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const theme = useTheme();
		const params = useParams();
		const { contentVersion } = useContext(CDSContext);
		const participantId = params.participantId || '';
		const reportId = params.reportId || '';

		const cdsCardLinkReplacements = generateCardLinkReplacements(
			contentId,
			handleBHAPLink
		);

		if (!contentId) return null;
		const prefix = `cds.recommendations.${contentId}`;

		function handleArticleLink() {
			const articleLink = `/results/${participantId}/${reportId}/${contentId}`;
			navigate(articleLink);
		}

		return (
			<StyledContainer
				data-testid={`CDSCard_${contentId}`}
				ref={ref}
				tabIndex={0}
			>
				<StyledCaption>
					<StyledCaptionText>
						{t(`${prefix}.caption_${contentVersion}`).toUpperCase()}
					</StyledCaptionText>
					<LinusTooltip
						overlay={
							<StyledToolTip>
								<StyledTooltipTitle>
									<Trans
										i18nKey={`cds.tooltips.rationale_${contentVersion}`}
									/>
								</StyledTooltipTitle>
								<StyledTooltipText>
									{t(`${prefix}.tooltip`)}
								</StyledTooltipText>
							</StyledToolTip>
						}
						tooltipIcon={
							<StyledInfoIcon>
								<Icon
									icon={icons.InfoOutlined}
									color={theme.color.cdsInfoIcon}
									width={20}
									height={20}
								/>
							</StyledInfoIcon>
						}
					/>
				</StyledCaption>
				<StyledSubtitle>
					{t('cds.recommendations.subtitle')}
				</StyledSubtitle>
				<StyledClinicalSignificance>
					{t(`${prefix}.clinicalSignificance_${contentVersion}`)}
				</StyledClinicalSignificance>
				<StyledDivider />
				<StyledSubtitleRow>
					<StyledSubtitle borderColor={'#FFCA28'}>
						{t('cds.recommendations.subtitleTwo')}
					</StyledSubtitle>
					<StyledStarIcon>
						<Icon
							icon={icons.Star}
							color={theme.color.cdsStarIcon}
							width={24}
							height={24}
						/>
					</StyledStarIcon>
				</StyledSubtitleRow>
				<StyledRecommendations>
					<Trans
						i18nKey={`${prefix}.recommendations_${contentVersion}`}
						components={{
							unorderedList: <ul />,
							listItem: <StyledListItem />,
							b: <Medium />,
							...cdsCardLinkReplacements,
						}}
					/>
				</StyledRecommendations>
				<StyledViewFullArticleLink>
					<Icon
						icon={icons.ExternalLink}
						color={theme.color.cdsExternalLinkIcon}
						width={20}
						height={20}
					/>
					<StyledViewFullArticleText onClick={handleArticleLink}>
						{t('cds.recommendations.viewArticle')}
					</StyledViewFullArticleText>
				</StyledViewFullArticleLink>
			</StyledContainer>
		);
	}
);

const StyledContainer = styled.div(
	({ theme: { spacing, color, borderRadius } }) => css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: ${spacing.md};
		gap: ${spacing.md};
		height: fit-content;
		background: ${color.white};
		border-radius: ${borderRadius.cdsCard};
		border: 1px solid ${color.cdsPanelBorder};

		&:focus {
			border: 2px solid ${color.cdsCardFocus};
			border-radius: 10px;
			cursor: pointer;
		}
	`
);

const StyledCaption = styled.div`
	display: flex;
	flex-direction: row;
	min-width: 296px;
`;

const Medium = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

type StyledSubtitleProps = {
	borderColor?: string;
};

const StyledSubtitle = styled(Subtitle)<StyledSubtitleProps>(
	({ theme: { spacing, weight, color }, borderColor }) => css`
		padding-left: ${spacing.sm};
		border-left: 2px solid ${borderColor || color.cdsCardSubtitleBorder};
		font-weight: ${weight.medium};
	`
);

const StyledClinicalSignificance = styled(Subtitle)``;

const StyledDivider = styled.div(
	({ theme: { color } }) => css`
		border: 1px solid ${color.infoSeparator};
		width: 472px;
		height: 0px;
	`
);

const StyledCaptionText = styled(Caption)(
	({ theme: { weight, color } }) => css`
		min-height: 14px;
		font-weight: ${weight.regular};
		color: ${color.cdsCardCaption};
	`
);

const StyledSubtitleRow = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledRecommendations = styled(P1)(
	({ theme: { spacing, color } }) => css`
		padding-left: ${spacing.sm};
		margin-bottom: auto;
		color: ${color.cdsCardRecommendations};
	`
);

const StyledTooltipText = styled(P2)(
	({ theme: { spacing, color } }) => css`
		margin: ${spacing.sm} ${spacing.xs} 0 ${spacing.xs};
		color: ${color.white};
	`
);

const StyledTooltipTitle = styled(Caption)(
	({ theme: { spacing, color, weight } }) => css`
		margin: 0 ${spacing.xxs} ${spacing.md} ${spacing.xxs};
		color: ${color.white};
		font-weight: ${weight.bold};
	`
);

const StyledToolTip = styled.div`
	width: 284px;
	text-align: left;
`;

const StyledInfoIcon = styled.div`
	position: relative;
	margin-left: 6.5px;
	top: -3px;
`;

const StyledStarIcon = styled.div`
	position: relative;
	margin-left: 10px;
	top: -1px;
`;

const StyledViewFullArticleLink = styled.a`
	display: flex;
	flex-direction: row;
	margin-top: auto;
	@media print {
		display: none;
	}
`;

const StyledViewFullArticleText = styled(ButtonSmText)(
	({ theme: { color } }) => css`
		margin-top: 4px;
		color: ${color.cdsExternalLink} !important;
	`
);

const StyledListItem = styled.li(
	({ theme: { spacing } }) => css`
		margin-left: ${spacing.md};
		list-style: disc;
	`
);
