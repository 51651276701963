import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import { useMemo } from 'react';
import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';
import {
	AnswerResponse,
	DelayedRecognitionPerformance,
} from './DelayedRecognition.types';

import { parseDelayedRecognitionMetrics } from './parseDelayedRecognitionMetrics';

export const MAX_SCORE = '6';

export type DelayedRecognitionData = {
	performance: DelayedRecognitionPerformance;
	answers: AnswerResponse[];
};

export const useDelayedRecognitionData = (
	segmentResult: DeepSegmentResult
): DelayedRecognitionData => {
	let answers: AnswerResponse[] = [];

	const { performance } = useMemo(() => {
		return parseDelayedRecognitionMetrics(segmentResult);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const s3Segments = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'json',
	});

	s3Segments.forEach((file) => {
		if (file.type === DelayedRecognitionFilenames.DelayedRecognition) {
			answers = (file?.data?.data || []) as AnswerResponse[];
		}
	});
	return { performance, answers };
};

export enum DelayedRecognitionFilenames {
	DelayedRecognition = 'delayed-recognition',
}

const processQuestionnaires = async (response: Blob) => {
	const responseText = await response.text();
	return JSON.parse(responseText);
};

export const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			segmentResultId,
			type: DelayedRecognitionFilenames.DelayedRecognition,
			filename: 'delayed-recognition.json',
			processData: processQuestionnaires,
		},
	];
};
