import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { ButtonSm } from '../../../shared/designSystem/ButtonSm';
import { Icon } from '../../../shared/designSystem/Icon';
import { H2, H4 } from '../../../shared/designSystem/TextComponents';

import { CompositeScales } from '../../graphs/CompositeScales';
import { CompositeScalesLegend } from '../../graphs/CompositeScalesLegend';

import {
	getConcerningScores,
	getDataSetCharacteristics,
} from '../../segmentUtilities/barChart';
import {
	getCompositeScalesDataSet,
	compositeScalesTooltipKeys,
} from '../../segmentUtilities/clock/compositeScales';
import {
	getDrawingEfficiencyDataSet,
	drawingEfficiencyTooltipKeys,
} from '../../segmentUtilities/clock/drawingEfficiency';
import {
	getInformationProcessingDataSet,
	infoProcessingTooltipKeys,
} from '../../segmentUtilities/clock/informationProcessing';
import {
	getMotorDataSet,
	motorTooltipkeys,
} from '../../segmentUtilities/clock/simpleAndComplexMotor';
import {
	getSpatialReasoningDataSet,
	spatialReasoningTooltipKeys,
} from '../../segmentUtilities/clock/spatialReasoning';
import { MetricHash } from '../../segmentUtilities/metrics';

type DctClockCompositeScalesProps = {
	metricData: MetricHash;
	title?: string;
	onClose?: () => void;
};

export const DctClockCompositeScales = ({
	metricData,
	title,
	onClose,
}: DctClockCompositeScalesProps): JSX.Element => {
	const { t } = useTranslation();
	const theme = useTheme();

	const { color } = theme;
	const capColors: string[] = [color.graphBlue, color.graphGreen];

	// When requirements are locked down, we will probably want to break out each of these into their own component
	// Overview
	const compositeDataSet = getCompositeScalesDataSet(metricData);
	const defaultDataSetInfo = getDataSetCharacteristics(compositeDataSet);
	const chartConcernArray = getConcerningScores(metricData);
	const chartConcerns = chartConcernArray.length
		? chartConcernArray
		: undefined;
	// Drawing Efficiency
	const drawingEfficiencyDataSet = getDrawingEfficiencyDataSet(metricData);
	const drawingEfficiencyDataSetInfo = getDataSetCharacteristics(
		drawingEfficiencyDataSet
	);

	// Simple & Complex Motor
	const motorDataSet = getMotorDataSet(metricData);
	const motorDataSetInfo = getDataSetCharacteristics(motorDataSet);

	// Information Processing
	const informationDataSet = getInformationProcessingDataSet(metricData);
	const informationDataSetInfo =
		getDataSetCharacteristics(informationDataSet);

	// Spatial Reasoning
	const spatialReasoningDataSet = getSpatialReasoningDataSet(metricData);
	const spatialReasoningDataSetInfo = getDataSetCharacteristics(
		spatialReasoningDataSet
	);

	return (
		<>
			<StyledTitleContainer>
				<StyledTitleRow>
					<StyledH2>{title}</StyledH2>
					<StyledIconWrapper onClick={onClose}>
						<Icon
							icon={icons.Close}
							title={t`web.shared.closeModal`}
						/>
					</StyledIconWrapper>
				</StyledTitleRow>

				<CompositeScalesLegend colors={capColors} />
			</StyledTitleContainer>
			<StyledDataGraphs>
				<StyledCompositeScale>
					<CompositeScales
						subtitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.standardDeviations.subtitle`}
						dataSet={compositeDataSet}
						concerns={chartConcerns}
						tooltipKeys={compositeScalesTooltipKeys}
						width={946}
						height={346}
						barWidth={94}
						barDistance={100}
						xAxisRange={[0, compositeDataSet.commandClock.length]}
						yAxisRange={[
							defaultDataSetInfo.min,
							defaultDataSetInfo.max,
						]}
						squeezeEdgesX={[-98, 110]}
						slideDataX={-24}
						customYAxisTickValues={defaultDataSetInfo.yTickValues}
						makeResponsive={true}
					/>
				</StyledCompositeScale>
			</StyledDataGraphs>
			<PrintOnlyBreak />
			<StyledDataGraphs>
				<StyledH4>{t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.title`}</StyledH4>
				<StyledCompositeScale>
					<CompositeScales
						subtitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.standardDeviations.subtitle`}
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.drawingEfficiency.chartTitle`}
						dataSet={drawingEfficiencyDataSet}
						tooltipKeys={drawingEfficiencyTooltipKeys}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[
							0,
							drawingEfficiencyDataSet.commandClock.length,
						]}
						yAxisRange={[
							drawingEfficiencyDataSetInfo.min,
							drawingEfficiencyDataSetInfo.max,
						]}
						squeezeEdgesX={[-78, 72]}
						slideDataX={-24}
						customYAxisTickValues={
							drawingEfficiencyDataSetInfo.yTickValues
						}
						makeResponsive={true}
					/>
				</StyledCompositeScale>
				<StyledCompositeScale>
					<CompositeScales
						subtitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.standardDeviations.subtitle`}
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.simpleAndComplexMotor.chartTitle`}
						dataSet={motorDataSet}
						tooltipKeys={motorTooltipkeys}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[0, motorDataSet.commandClock.length]}
						yAxisRange={[
							motorDataSetInfo.min,
							motorDataSetInfo.max,
						]}
						squeezeEdgesX={[-78, 72]}
						slideDataX={-24}
						customYAxisTickValues={motorDataSetInfo.yTickValues}
						makeResponsive={true}
					/>
				</StyledCompositeScale>
				<StyledCompositeScale>
					<CompositeScales
						subtitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.standardDeviations.subtitle`}
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.informationProcessing.chartTitle`}
						dataSet={informationDataSet}
						tooltipKeys={infoProcessingTooltipKeys}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[0, informationDataSet.commandClock.length]}
						yAxisRange={[
							informationDataSetInfo.min,
							informationDataSetInfo.max,
						]}
						squeezeEdgesX={[-72, 72]}
						slideDataX={-24}
						customYAxisTickValues={
							informationDataSetInfo.yTickValues
						}
						makeResponsive={true}
					/>
				</StyledCompositeScale>
				<StyledCompositeScale>
					<CompositeScales
						subtitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.standardDeviations.subtitle`}
						chartTitle={t`web.report.DCTClock.subscore.info.compositeScalesModal.graphs.subscales.spatialReasoning.chartTitle`}
						dataSet={spatialReasoningDataSet}
						tooltipKeys={spatialReasoningTooltipKeys}
						width={946}
						height={346}
						barWidth={50}
						barDistance={58}
						xAxisRange={[
							0,
							spatialReasoningDataSet.commandClock.length,
						]}
						yAxisRange={[
							spatialReasoningDataSetInfo.min,
							spatialReasoningDataSetInfo.max,
						]}
						squeezeEdgesX={[-120, 98]}
						slideDataX={-24}
						customYAxisTickValues={
							spatialReasoningDataSetInfo.yTickValues
						}
						makeResponsive={true}
					/>
				</StyledCompositeScale>

				{onClose && (
					<StyledButtonWrapper>
						<ButtonSm
							text={t`web.report.DCTClock.subscore.info.compositeScalesModal.closeButton.text`}
							width='158px'
							onClick={onClose}
						/>
					</StyledButtonWrapper>
				)}
			</StyledDataGraphs>
		</>
	);
};

const StyledTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	transform: translateY(-32px);

	padding-top: 32px;
`;

const StyledTitleRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const StyledCompositeScale = styled.div`
	margin-bottom: 15px;
	@media print {
		page-break-inside: avoid;
		height: 300px;
		margin-bottom: 40px;
	}
`;

const StyledH2 = styled(H2)(
	({ theme: { weight, color } }) => `
	color:${color.titleText}
	font-size: 24px;
	font-weight: ${weight.medium};
`
);

const StyledIconWrapper = styled.div(
	({ theme: { spacing } }) => `
	
	width: ${spacing.lg};
	height: ${spacing.lg};
	&:hover {
		cursor: pointer;
	}
`
);

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: space-around;
`;

const StyledDataGraphs = styled.div(
	({ theme: { spacing } }) => `

	@media print {
		padding:${spacing.lg};
	}
`
);

const StyledH4 = styled(H4)(
	({ theme: { spacing } }) => `
    margin: 0 ${spacing.lg} ${spacing.lg} 0;
	@media print {
		margin-bottom: ${spacing.xs};
	}
	`
);

const PrintOnlyBreak = styled.div`
	display: none;
	@media print {
		width: 100%;
		display: block;
		page-break-before: always;
	}
`;
