import { GradeMarks } from '@lh/qnr-svc-rest-client';
import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';

export const IADL_RESULT_DATA: QnrRowData[] = [
	{
		question: 'I am answering these questions on behalf of myself.',
		answers: { grade: GradeMarks.NOT_GRADED, answerText: 'Yes', value: '' },
	},
	{
		question:
			"I need help with transportation, for example going to doctor's visits.",
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help grocery shopping.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question: 'I need help online shopping.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question: 'I need help doing housework.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help handling my money.',
		answers: { grade: GradeMarks.RED_X, answerText: 'No', value: '' },
	},
	{
		question: 'I need help using the telephone.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question: 'I need help preparing meals.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help doing laundry.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help taking my medications.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question:
			'I need help using technology like computers, smartphones, or tablets.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'TOTAL SCORE',
		answers: {
			totalScore: 7,
		},
	},
];

export const ADL_RESULT_DATA: QnrRowData[] = [
	{
		question: 'I am answering these questions on behalf of myself.',
		answers: { grade: GradeMarks.NOT_GRADED, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help using the toilet.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help with bathing.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question:
			'I need help eating food from the plate / I need help getting food from a plate to my mouth.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question: 'I need help getting dressed.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'I need help getting in and out of bed or a chair.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question: 'I need help with personal care, like brushing my hair.',
		answers: { grade: GradeMarks.GREEN_CHECK, answerText: 'No', value: '' },
	},
	{
		question:
			'I have control over my bladder and bowels and rarely have accidents.',
		answers: { grade: GradeMarks.RED_X, answerText: 'Yes', value: '' },
	},
	{
		question: 'TOTAL SCORE',
		answers: {
			totalScore: 3,
		},
	},
];
