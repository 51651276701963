import { theme, Typography } from '@lh/eng-web-mosaic-common';

import styled, { css } from 'styled-components';

import { ScoreVariant } from '../../types';

const DEFAULT_BORDER_RADIUS = theme.spacing.sm;
const COMPOSED_BORDER_RADIUS = `${theme.spacing.sm} 0 0 ${theme.spacing.sm}`;
const VARIANT_TO_COLOR: Record<ScoreVariant, string> = {
	neutral: theme.colors.gray_60,
	danger: theme.colors.orange_light,
	success: theme.colors.green_light,
};

export const ScoreTagContainer = styled.div<{
	$composed: boolean;
	$variant: ScoreVariant;
}>(
	({ $composed, theme, $variant }) => css`
		display: flex;
		padding: 12px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: ${theme.spacing.xxs};
		border-radius: ${$composed
			? COMPOSED_BORDER_RADIUS
			: DEFAULT_BORDER_RADIUS};
		background: ${VARIANT_TO_COLOR[$variant]};
		height: 104px;
		max-width: 124px;
	`
);

export const LabelText = styled(Typography.P2)(
	({ theme }) => css`
		font-weight: ${theme.weight.medium} !important;
		text-align: center;
		text-transform: uppercase;
	`
);

export const ScoreText = styled(Typography.H2)(
	({ theme }) => css`
		font-weight: ${theme.weight.medium} !important;
		text-align: center;
	`
);
