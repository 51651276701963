import styled from 'styled-components';
import {
	concernsTitleHandler,
	concernsMessageHandler,
	dotColorHandler,
	Footer,
} from './helpers';
import { StyledCard } from '../../shared/StyledCard';
import { P1, H4 } from '../../shared/designSystem';

interface ConcernProps {
	readonly volume: string | number;
}

export function HearingScreenerConcern({ volume }: ConcernProps) {
	const contentMessage = concernsMessageHandler(volume);
	const _color = dotColorHandler(volume);
	const title = concernsTitleHandler(volume);

	return (
		<StyledContainer
			className='concern'
			data-testid='HearingScreenerConcern'
		>
			<Header>
				<Bullet
					data-testid='HearingScreenerConcernBullet'
					style={{ backgroundColor: `${_color}` }}
				/>
				<Title>{title}</Title>
			</Header>
			<Content>{contentMessage}</Content>
			<Footer volume={volume} />
		</StyledContainer>
	);
}

type StyledContainerProps = {
	width?: string;
};

const StyledContainer = styled(StyledCard)<StyledContainerProps>(
	({ theme: { spacing } }) => `
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: ${spacing.xl};
        margin-bottom: ${spacing.xl};
    `
);

const Header = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: ${spacing.md};
    `
);

const Bullet = styled.div(
	({ theme: { spacing } }) => `
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: ${spacing.sm};
    `
);

const Content = styled(P1)(
	({ theme: { weight, spacing } }) => `
        font-weight: ${weight.regular};
        margin-bottom: ${spacing.md};
    `
);

const Title = styled(H4)(
	({ theme: { weight } }) => `
		font-weight: ${weight.medium};
	`
);
