import {
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import styled, { css } from 'styled-components';

import { LoadingLayout } from '../../../components/shared/ReportLoader';
import { Report } from '../CognitiveEvaluation.types';
import { parseSegmentResult } from '../Report.utils';

import { useBDSTData } from './useBDSTData';
import { BdstLayout } from './Layout';

type BdstReportProps = {
	batteryResult: Report;
	segmentResult?: DeepSegmentResult;
};

export function BdstReport({
	batteryResult,
	segmentResult,
}: Readonly<BdstReportProps>) {
	let _segmentResult: DeepSegmentResult;

	if (segmentResult) {
		_segmentResult = segmentResult;
	} else {
		_segmentResult = parseSegmentResult(
			SegmentType.BackwardsDigitSpan,
			batteryResult
		);
	}

	const {
		breakdownScores,
		performance,
		recordings: { trialOne, trialTwo, trialThree },
	} = useBDSTData(_segmentResult);

	if (!segmentResult && !batteryResult) {
		return (
			<LoadingLayout
				title='web.report.bdstReport.backwardsDigitSpan'
				tooltipText='web.report.bdstReport.subtitle'
			/>
		);
	}

	return (
		<BdstReportContainer>
			<BdstLayout
				breakdownScores={breakdownScores}
				performance={performance}
				urls={{
					trialOne,
					trialTwo,
					trialThree,
				}}
			/>
		</BdstReportContainer>
	);
}

const BdstReportContainer = styled.div(
	({ theme: { spacing } }) => css`
		// account for distance between layout and FooterId container
		margin-bottom: ${spacing.sm};
	`
);
