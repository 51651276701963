import {
	GetReasonsByOrganizationFilterField,
	GetReasonsByOrganizationFilterOperator,
	GetReasonsByOrganizationLogicalOperator,
	Reason,
} from '@lh/eng-platform-organization-service-rest-client';

import { QueryClient } from '@tanstack/react-query';

import { getReasons } from 'api/organization';
import { QueryKey } from 'api/query';
import { IOrgReasonsSeachParams, TDropdownElement } from '../interface';

/**
 * @param param0 The required search data to assemble the search payload
 * @returns The search payload to be fed to the organization service
 */
export const assembleOrganizationSearchPayload = ({
	organizationId,
	search,
	pageSize,
	pageNumber,
}: IOrgReasonsSeachParams) => {
	const requestPayload = {
		organizationId: organizationId,
		filterField: [
			GetReasonsByOrganizationFilterField.DiagnosisCode,
			GetReasonsByOrganizationFilterField.DiagnosisDescription,
		],
		filterOperator: [
			GetReasonsByOrganizationFilterOperator.Ilike,
			GetReasonsByOrganizationFilterOperator.Ilike,
		],
		logicalOperator: [GetReasonsByOrganizationLogicalOperator.Or],
		filterValue: [search, search],
		pageSize: pageSize,
		page: pageNumber,
	};
	return requestPayload;
};

/**
 * @description Searches the reasons by organizations
 * @param props The required data
 * @returns The found codes
 */
export const searchReasonsByOrganizations = async (
	props: IOrgReasonsSeachParams,
	client: QueryClient
): Promise<TDropdownElement<Reason>[]> => {
	const requestPayload = assembleOrganizationSearchPayload(props);
	const result = await client.fetchQuery({
		queryKey: [QueryKey.Reasons, props.organizationId, props.search],
		queryFn: () => getReasons(requestPayload),
		staleTime: Infinity,
	});
	const setOfKeys = [
		...new Set(result.map((element) => element.diagnosisCode)).values(),
	];
	const results = setOfKeys.map((diagnosisCode) =>
		result.find((element) => element.diagnosisCode === diagnosisCode)
	) as Reason[];
	const resultingFiltered = results.map((element) => ({
		dropdownOption: {
			value: element.diagnosisCode,
			display: `${element.diagnosisDescription || ''}`,
		},
		dataElement: element,
	}));
	return resultingFiltered;
};
