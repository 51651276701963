import styled from 'styled-components';

type CDSCardLinkProps = {
	linkKey: string;
	children?: React.ReactChild;
	isActionLink?: boolean;
	actionLink?: () => void;
};

const cdsLinks: { [linkKey: string]: string } = {
	iadls: `https://www.alz.org/careplanning/downloads/lawton-iadl.pdf`,
	adls: `https://www.alz.org/careplanning/downloads/katz-adl.pdf`,
	anticholinergic: `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6908498/`,
	gad7: `https://adaa.org/sites/default/files/GAD-7_Anxiety-updated_0.pdf`,
	gds: `https://geriatrictoolkit.missouri.edu/cog/GDS_SHORT_FORM.PDF`,
	psqi: `https://www.sleep.pitt.edu/wp-content/uploads/Study_Instruments_Measures/PSQI-Instrument.pdf`,
	fingerRubTest: `http://www.meddean.luc.edu/lumen/meded/medicine/pulmonar/pd/pstep14.htm#:~:text=With%20eyes%20closed%2C%20the%20patient,next%20to%20the%20patient's%20ear`,
	whisperVoiceTest: `https://geriatrics.ucsf.edu/sites/geriatrics.ucsf.edu/files/2018-06/whispertest.pdf`,
	ess: `https://www.blf.org.uk/support-for-you/obstructive-sleep-apnoea-osa/diagnosis/epworth-sleepiness-scale`,
	tugtest: `https://www.cdc.gov/steadi/pdf/TUG_test-print.pdf`,
	medications: `https://www.brightfocus.org/alzheimers/article/is-it-something-im-taking-medications-that-can-mimic-dementia`,
	phq: `https://www.apa.org/depression-guideline/patient-health-questionnaire.pdf`,
	sleepHygiene: `https://www.sleep.pitt.edu/wp-content/uploads/Study_Instruments_Measures/PSQI-Instrument.pdf`,
	sleepApnea: `https://www.mdcalc.com/calc/3992/stop-bang-score-obstructive-sleep-apnea`,
	bloodWork: `http://alz.org/national/documents/LabEvaluDementiaTable.pdf`,
	rwt: `https://www.ncbi.nlm.nih.gov/books/NBK431071/`,
	stopBang: `http://www.stopbang.ca/osa/screening.php`,
	bhap: `#`,
};

const CDSCardLink = ({
	linkKey,
	children,
	isActionLink,
	actionLink,
}: CDSCardLinkProps): JSX.Element | null => {
	return isActionLink && actionLink ? (
		<StyledActionLink>
			<StyledActionLinkText onClick={actionLink}>
				{children}
			</StyledActionLinkText>
		</StyledActionLink>
	) : (
		<a href={cdsLinks[linkKey] || '#'} target='_blank' rel='noreferrer'>
			{children}
		</a>
	);
};

const StyledActionLink = styled.a`
	text-decoration: underline;
`;

const StyledActionLinkText = styled.span(
	({ theme: { weight } }) => `
    font-weight: ${weight.semi};
    padding: 0;
`
);

export { CDSCardLink };

export const generateCardLinkReplacements = (
	contentId: string,
	handleBHAPLink: (contentId: string) => void
): Record<string, JSX.Element> => {
	return {
		iadlsLink: <CDSCardLink linkKey={'iadls'} />,
		adlsLink: <CDSCardLink linkKey={'adls'} />,
		anticholinergicLink: <CDSCardLink linkKey={'anticholinergic'} />,
		gad7Link: <CDSCardLink linkKey={'gad7'} />,
		gdsLink: <CDSCardLink linkKey={'gds'} />,
		psqiLink: <CDSCardLink linkKey={'psqi'} />,
		frtLink: <CDSCardLink linkKey={'fingerRubTest'} />,
		wvtLink: <CDSCardLink linkKey={'whisperVoiceTest'} />,
		essLink: <CDSCardLink linkKey={'ess'} />,
		tugtestLink: <CDSCardLink linkKey={'tugtest'} />,
		medicationsLink: <CDSCardLink linkKey={'medications'} />,
		phqLink: <CDSCardLink linkKey={'phq'} />,
		sleepHygieneLink: <CDSCardLink linkKey={'sleepHygiene'} />,
		sleepApneaLink: <CDSCardLink linkKey={'sleepApnea'} />,
		bloodWorkLink: <CDSCardLink linkKey={'bloodWork'} />,
		rwtLink: <CDSCardLink linkKey={'rwt'} />,
		stopBangLink: <CDSCardLink linkKey={'stopBang'} />,
		bhapLink: (
			<CDSCardLink
				linkKey={'bhap'}
				isActionLink={true}
				actionLink={() => {
					handleBHAPLink(contentId);
				}}
			/>
		),
	};
};
