import { Trans } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../../shared/designSystem/Icon';
import { P1, P2 } from '../../../shared/designSystem/TextComponents';
import { LinusTooltip } from '../../../shared/LinusTooltip';

type CCEImpressionSectionProps = {
	header: string;
	sectionText: string | JSX.Element | null;
	score?: string | JSX.Element;
	dcrScore?: number;
	dcrWarning?: JSX.Element | null;
	tooltipKey: string;
	linkText: string;
	badge: JSX.Element | null;
	resultsLink?: () => void;
};

const CCEImpressionSection = ({
	score,
	dcrScore,
	header,
	sectionText,
	linkText,
	resultsLink,
	dcrWarning,
	tooltipKey,
	badge,
}: CCEImpressionSectionProps): JSX.Element => {
	const theme = useTheme();
	const ResultsLink = (): JSX.Element => (
		<StyledLink onClick={resultsLink}>
			<StyledLinkTitle>{linkText}</StyledLinkTitle>
			<Icon
				icon={icons.TraditionalArrowRight}
				iconWidth={24}
				iconHeight={24}
				color={theme.color.cdsPanelTitleLink}
			/>
		</StyledLink>
	);
	return (
		<StyledSection>
			<StyledDcrHeader>
				<StyledHeaderText>{header}</StyledHeaderText>
				<LinusTooltip
					overlay={
						<StyledToolTip>
							<StyledTooltipText>
								<Trans
									i18nKey={tooltipKey}
									components={{
										newLine: <br />,
										bold: <b />,
									}}
								/>
							</StyledTooltipText>
						</StyledToolTip>
					}
					tooltipIcon={
						<StyledInfoIcon>
							<Icon
								icon={icons.InfoOutlined}
								color={theme.color.cdsHeaderInfoIcon}
								width={20}
								height={20}
							/>
						</StyledInfoIcon>
					}
				/>
				{!sectionText && ResultsLink()}
			</StyledDcrHeader>
			<StyledScoreRow displayRow={!sectionText}>
				{badge}
				<StyledScoreColumn>
					<StyledScoreText>{sectionText}</StyledScoreText>
					{dcrScore !== undefined && score}
				</StyledScoreColumn>
				{sectionText && ResultsLink()}
			</StyledScoreRow>
			{dcrWarning}
		</StyledSection>
	);
};

const StyledSection = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const StyledTooltipText = styled(P2)(
	({ theme: { color } }) => `
    padding: 5px;
	color: ${color.white};
`
);

const StyledToolTip = styled.div`
	width: 304px;
	height: auto;
	text-align: left;
`;

const StyledInfoIcon = styled.div`
	position: relative;
	margin-left: 6.5px;
	top: -1px;
`;

const StyledDcrHeader = styled(P1)`
	display: flex;
	flex-direction: row;
`;

const StyledHeaderText = styled(P1)``;

type StyledScoreRowProps = {
	displayRow: boolean;
};

const StyledScoreRow = styled.div<StyledScoreRowProps>(
	({ theme: { spacing }, displayRow }) => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: ${spacing.md};
		display: ${displayRow && 'none'};
		width: 100%;
	`
);

const StyledScoreColumn = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 10px;
`;

const StyledScoreText = styled(P1)``;

const StyledLink = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	justify-content: space-between;
	margin-left: auto;
	&:hover {
		cursor: pointer;
	}
`;

const StyledLinkTitle = styled.div(
	({ theme: { color, weight, fontSize } }) => `
	font-weight: ${weight.medium};
	font-size: ${fontSize._12};
	line-height: 13.5px;
	letter-height: 0.2px;
	color: ${color.cdsPanelTitleLink};
    margin-right: 8px;
`
);

export { CCEImpressionSection };
