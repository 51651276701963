import { dateFormatter } from '../../../stringStrategy/dateFormatStringStrategy';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledCard } from '../../shared/StyledCard';
import { H4, P1 } from '../../shared/designSystem';

interface AssessmentsStatusProps {
	readonly endTime?: string | number;
	readonly seconds?: string | number;
	readonly timezone?: string;
}
export function HearingScreenerStatus({
	endTime,
	seconds,
	timezone,
}: AssessmentsStatusProps) {
	const { t } = useTranslation();

	const endTimeLowercase = endTime
		? dateFormatter(`${endTime}`, 't', timezone)
				.toLowerCase()
				.replace(/\s/, '')
		: undefined;
	const date = endTime
		? `${dateFormatter(
				`${endTime}`,
				"MMM d', ' yyyy ' at ",
				timezone
		  )}${endTimeLowercase}`
		: undefined;
	const duration = Math.floor(Number(seconds) * 10) / 10;

	return (
		<StyledContainer data-testid='HearingScreenerStatus'>
			<Title>{`${t(
				'web.report.hearingScreener.assessmentStatus.title'
			)}`}</Title>
			<CompletionDate data-testid='CompletionDate'>
				<Trans
					i18nKey='web.report.hearingScreener.assessmentStatus.completionDate'
					components={{ b: <StyledBold /> }}
					values={{ date }}
				/>
			</CompletionDate>
			<Duration data-testid='Duration'>
				<Trans
					i18nKey='web.report.hearingScreener.assessmentStatus.taskDuration'
					components={{ b: <StyledBold /> }}
					values={{ seconds: duration }}
				/>
			</Duration>
		</StyledContainer>
	);
}

const StyledBold = styled.span(
	({ theme: { weight, color } }) => `
		font-weight: ${weight.semi};
		color: ${color.bodyText};
    `
);

const StyledContainer = styled(StyledCard)(
	({ theme: { spacing, weight, color } }) => `
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: ${spacing.xl};
        margin-bottom: ${spacing.xl};
		color: ${color.bodyText};
		
		b {
			weight: ${weight.semi};
		}
    `
);

const Title = styled(H4)(
	({ theme: { weight, color, spacing } }) => `
        font-weight: ${weight.regular}; 
        margin-bottom: ${spacing.md};
		color: ${color.bodyText};
    `
);

const CompletionDate = styled(P1)(
	({ theme: { weight, color, fontSize, spacing } }) => `
        font-weight: ${weight.regular}; 
        margin-bottom: ${spacing.md};
		font-size: ${fontSize._16};
		color: ${color.bodyText};
    `
);

const Duration = styled(P1)(
	({ theme: { weight, color } }) => `
        font-weight: ${weight.regular};
		color: ${color.bodyText};
    `
);
