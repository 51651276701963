import { useField } from 'formik';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { icons } from '../../../../enums/icons';
import { LinusInputChildrenProps } from '../../../../types';
import { Icon } from '../../designSystem/Icon';

export const Checkbox = <T,>({
	name,
}: LinusInputChildrenProps<T>): JSX.Element => {
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, _, helpers] = useField(name);
	const { setValue } = helpers;
	const handleClick = () => {
		setValue(field.value ? !field.value : true);
	};
	return (
		<StyledCheckbox onClick={handleClick}>
			{field.value ? (
				<motion.div
					key='expanded'
					initial='initial'
					animate='in'
					exit='out'
					variants={checkboxVariants}
					transition={checkboxTransition}
				>
					<Icon
						icon={icons.CheckboxCheck}
						title={t`web.shared.forms.checked`}
					/>
				</motion.div>
			) : (
				<motion.div
					key='collapsed'
					initial='initial'
					animate='in'
					exit='out'
					variants={checkboxVariants}
					transition={checkboxTransition}
				>
					<Icon
						icon={icons.CheckboxEmpty}
						title={t`web.shared.forms.notChecked`}
					/>
				</motion.div>
			)}
		</StyledCheckbox>
	);
};

const StyledCheckbox = styled.div`
	margin-right: 10px;

	&:hover {
		cursor: pointer;
	}
`;

const checkboxVariants = {
	initial: {
		opacity: 0,
		scale: 0.25,
		rotate: -30,
	},
	in: {
		opacity: 1,
		scale: 1,
		rotate: 0,
	},
	out: {
		opacity: 0,
		scale: 0.25,
		rotate: -30,
	},
};
const checkboxTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.2,
};
