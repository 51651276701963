import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { UserContext } from 'components/context';
import { icons } from '../../enums/icons';
import { Icon } from '../shared/designSystem/Icon';
import { Footer } from '../shared/Footer';

import { RegulatoryLabel } from './RegulatoryLabel';

const RegulatoryPage = (): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const theme = useTheme();
	const { t } = useTranslation();
	const isUserLoggedIn = currentUser?.id;

	return (
		<>
			<StyledContainer>
				<StyledHeader>{'Linus Health'}</StyledHeader>
				<StyledLink isUserLoggedIn={!!isUserLoggedIn}>
					<Icon
						icon={icons.LeftPointerArrow}
						color={theme.color.link}
						title={t`web.shared.iconAltText.leftArrow`}
					/>
					<Link to={'/about'}>{t`web.regulatory.backButton`}</Link>
				</StyledLink>
				<RegulatoryLabel />
			</StyledContainer>
			{!isUserLoggedIn && <Footer />}
		</>
	);
};

export { RegulatoryPage };

const StyledHeader = styled.h2(
	({ theme: { fontSize, spacing } }) => css`
		font-size: ${fontSize._36};
		margin: ${spacing.lg} 0 ${spacing.sm};
		line-height: ${spacing.xxxxl};
	`
);

type StyledLinkProps = {
	isUserLoggedIn?: boolean;
};

const StyledLink = styled.div<StyledLinkProps>(
	({ theme: { spacing, weight } }) => css`
		display: flex;
		align-items: center;
		font-weight: ${weight.bold};
		width: 60px;
		justify-content: space-between;
		margin-bottom: ${spacing.xl};
	`
);
const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1338px;
	margin: 0 auto;
`;
