import styled, { useTheme } from 'styled-components';
import { Trans } from 'react-i18next';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../../shared/designSystem/Icon';
import { P2 } from '../../../shared/designSystem/TextComponents';

export const Warning = (): JSX.Element => {
	const theme = useTheme();
	return (
		<StyledWarningRow>
			<StyledWarningIcon>
				<Icon
					icon={icons.WarningSolid}
					color={theme.color.alertWarningText}
				/>
			</StyledWarningIcon>
			<StyledWarning>
				<Trans i18nKey='web.report.recall.lengthWarning' />
			</StyledWarning>
		</StyledWarningRow>
	);
};

const StyledWarningIcon = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 1px;
	align-self: flex-start;
`;

const StyledWarningRow = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	gap: 11px;
  	margin: ${spacing.sm} auto;
`
);

const StyledWarning = styled(P2)(
	({ theme: { color } }) => `
    color: ${color.alertWarningTextDark};
    `
);
