import { css, styled } from 'styled-components';

import { H2, P1 } from '../../components/shared/designSystem';

export const Logo = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.xxl};
	`
);

export const Page = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			justify-content: center;
			padding: ${spacing.xxxl};
		`
);

export const Title = styled(H2)(
	({ theme: { color, spacing, weight } }) => css`
		padding-top: ${spacing.xl};
		color: ${color.textBlue};
		font-weight: ${weight.semi};
	`
);

export const Container = styled.div(
	({ theme: { color, boxShadow, spacing } }) => css`
		justify-content: center;
		align-content: center;
		text-align: center;
		width: 548px;
		height: auto;
		padding: ${spacing.xxl} ${spacing.xl};
		background: ${color.white};
		border-radius: ${spacing.xxl} 0 ${spacing.xxl} ${spacing.xxl};
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: ${boxShadow.standard};
	`
);

export const Content = styled(P1)(
	({ theme: { spacing, color } }) => css`
		color: ${color.textInfo};
		padding-top: ${spacing.md};
	`
);

export const ButtonContainer = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			justify-content: center;
			margin-top: ${spacing.xl};
		`
);
