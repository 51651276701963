import styled, { css } from 'styled-components';

import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { P1 } from 'components/shared/designSystem';

export const TableWrapper = styled.table(
	({ theme: { color } }) => css`
		width: 100%;

		tbody tr:first-child td {
			border-top: 1px solid ${color.tableBorder};
		}
		tbody tr:last-child td {
			border-bottom: 1px solid ${color.tableBorder};
		}

		tbody tr:nth-child(even) {
			background-color: ${color.cellHoverBg};
		}
	`
);

export const TableHeader = styled.th(
	({ theme: { spacing } }) => css`
		padding: 6px;
		padding-left: ${spacing.sm};
	`
);

export const LeftTableHead = styled(TableHeader)`
	width: 75%;

	@media ${MediaQueries.maxWidth.lg} {
		width: 60%;
	}
`;

export const RightTableHead = styled(TableHeader)`
	width: 25%;

	@media ${MediaQueries.maxWidth.lg} {
		width: 40%;
	}
`;

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const P1Semi = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const LeftFooter = styled.div`
	width: 75%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: 32px;

	@media ${MediaQueries.maxWidth.lg} {
		width: 60%;
		margin-left: 16px;
	}
`;

export const FooterResultContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		justify-content: left;
		gap: 12px;
		margin: ${spacing.sm} 0;
	`
);

export const TableHeadingsWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const FooterWrapper = styled.div(
	({ theme: { spacing } }) => css`
		padding: ${spacing.sm};
		display: flex;
		gap: ${spacing.sm};
		align-items: 'center';
	`
);

export const FooterResults = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

export const StyledContentContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: flex-start;
		padding-left: ${spacing.xs};
		padding-top: ${spacing.xs};
		padding-bottom: ${spacing.xs};
	`
);

export const StyledIconContainer = styled.div<{ positivelyAnswered: boolean }>(
	({ positivelyAnswered }) => css`
		top: ${positivelyAnswered && '-5px'};
		margin-right: 20px;
		position: relative;
		width: 17px;
		height: 17px;
	`
);

export const SpanSemi = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const QuestionTableContainer = styled(P1)`
	margin: 10px 0;
`;

export const TotalLabel = styled(P1)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		display: flex;
		justify-content: flex-end;
		margin: ${spacing.sm} 0;
	`
);
