import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getQuestionnaireService } from 'api/getQuestionnaireService';
import { QueryKey } from 'api/query';

export async function getQuestionnaire(
	batteryResultId: string,
	segmentResultId: string
) {
	if (!batteryResultId) {
		throw new Error(
			"useGetQuestionnaireQuery: batteryResultId can't be null"
		);
	}

	if (!segmentResultId) {
		throw new Error(
			"useGetQuestionnaireQuery: segmentResultId can't be null"
		);
	}

	const service = await getQuestionnaireService();
	const { result } = await service.getQuestionnaireResult({
		batteryResultId,
		segmentResultId,
	});
	return result;
}

export function useQuestionnaireQuery(
	batteryResultId: string,
	segmentResultId: string
) {
	return useQuery({
		enabled: !!batteryResultId && !!segmentResultId,
		meta: {
			errorMessage: `Error fetching questionnaire with batteryResultId: ${batteryResultId} and segmentResultId: ${segmentResultId}`,
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.Questionnaire, batteryResultId, segmentResultId],
		queryFn: () => getQuestionnaire(batteryResultId, segmentResultId),
		staleTime: Infinity,
	});
}
