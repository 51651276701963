import { Subject } from '@lh/eng-platform-subject-service-rest-client';

export type PartnerInfo = Partial<Subject> & AdditionalValues;

type AdditionalValues = {
	patientRelationship?: string;
};

export enum CarePartnerType {
	Add,
	Edit,
}

export type EmailOrPhone = EmailAndPhone | OnlyEmail | OnlyPhone;

type EmailAndPhone = {
	email: string;
	phone: string;
};

type OnlyEmail = {
	email: string;
};

type OnlyPhone = {
	phone: string;
};
