import { useTheme } from 'styled-components';
// fix me please
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BarCapLabel = ({
	x,
	y,
	datum,
	colors,
	width,
	translateX,
}: // fix me please
// eslint-disable-next-line @typescript-eslint/no-explicit-any
any): JSX.Element => {
	const { color } = useTheme();

	//Accepts an array of colors, and will apply colors in order by set of bars
	const capColor = colors[datum._stack - 1];

	//Calculate position and color values for caps based on +/- input value
	const capX = x - width / 2 + (translateX ? translateX : 0);

	const textX = x + (translateX ? translateX : 0);
	let capY;
	let capBorderRadiusModifier;
	let fontColor;
	let space;

	if (datum._y > 0) {
		capY = y;
		capBorderRadiusModifier = y + 2;
		fontColor = color.graphTextSecondary;
		space = -8;
	} else {
		capY = y - 4;
		capBorderRadiusModifier = y - 4;
		space = 20;

		//If score is below 1 std. dev from norm, apply warning textcolor
		if (datum._y >= -1) {
			fontColor = color.graphTextSecondary;
		} else {
			fontColor = color.graphTextAlert;
		}
	}

	return (
		<>
			<rect
				fill={capColor}
				width={width}
				height={4}
				dx={0}
				dy={space}
				x={capX}
				y={capY}
				rx={4}
			/>
			<rect
				fill={capColor}
				width={width}
				height={2}
				dx={0}
				dy={space}
				x={capX}
				y={capBorderRadiusModifier}
			/>
			<text
				x={textX}
				y={y}
				dx={0}
				dy={space}
				fontSize={14}
				fontFamily='IBM Plex Sans'
				fill={fontColor}
				textAnchor='middle'
				style={{ padding: 8 }}
			>
				{datum._y}
			</text>
		</>
	);
};

export { BarCapLabel };
