import { useTranslation } from 'react-i18next';

export function usePercentileText(percentile: number | null) {
	const { t } = useTranslation();

	if (percentile === null || percentile === 0) {
		return null;
	}

	return t('web.report.common.percentile', {
		count: percentile,
		ordinal: true,
	});
}
