import styled from 'styled-components';

import { P2 } from '../../../shared/designSystem/TextComponents';

import { DctScoreClassification } from './DctClockSubscore';

export const StyledSubscoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 45px;
	width: 313px;
`;

export const StyledBoxContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
`;

export const StyledProgressBar = styled.div(
	({ theme: { spacing } }) => `
		min-height: 120px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: ${spacing.md};
		position: relative;
	`
);

export const StyledProgressScore = styled.div(
	({ theme: { fontSize, spacing, weight, color } }) => `
		font-size: ${fontSize._32};
		line-height: ${spacing.xxxxl};
		text-align: center;
		font-weight: ${weight.medium};
		color: ${color.graphTextSecondary};
		position: absolute;
		padding-bottom: 10px;

		@media print {
			top: 0;
			transform: translateY(calc(50% + 16px));
		}
	`
);

export const StyledScoringInfo = styled.span(
	({ theme: { color, spacing } }) => `
		display: flex;
		align-items: center;
		color: ${color.graphTextPrimary};
		margin-bottom: ${spacing.md};
		margin-top: ${spacing.sm};
	`
);

export const StyledLinkWrapper = styled.a(
	({ theme: { spacing } }) => `
		margin-top: ${spacing.xs};

		@media print {
			display: none;
		}
	`
);

export const StyledScoreTitle = styled.span(
	({ theme: { color, spacing } }) => `
		color: ${color.graphTextPrimary};
		margin-right: ${spacing.xs};
	`
);

type StyledIconProps = {
	classification: DctScoreClassification;
};

export const StyledIcon = styled.span<StyledIconProps>(
	({ theme: { spacing }, classification }) => `
		margin-left: ${spacing.sm};
		padding-bottom: ${classification === 'indeterminate' ? '6px' : 0}
	`
);

type StyledScoreTextProps = {
	scoreTextColor: string;
};

export const StyledScoreText = styled.span<StyledScoreTextProps>(
	({ scoreTextColor, theme: { fontSize } }) => `
		color: ${scoreTextColor};
		font-size: ${fontSize._12};
		margin: 0 5px;
	`
);

export const StyledTooltipText = styled(P2)`
	color: white;
	text-align: left;
	margin-bottom: 10px;
`;

export const StyledTooltipTextContainer = styled.div`
	width: 300px;
`;

export const NoScoreContainer = styled(P2)(
	({ theme: { fontSize, color, weight } }) => `
		display: flex;
		align-items: center;
		text-align: center;
		font-size: ${fontSize._32};
		margin: 12px;
		color: ${color.graphTextSecondary};
		font-weight: ${weight.bold};
	`
);

export const StyledTextContainer = styled(P2)(
	({ theme: { fontSize, spacing, color, weight } }) => `
		display: flex;
		align-items: center;
		text-align: center;
		font-size: ${fontSize._16};
		margin-top: ${spacing.md};
		color: ${color.graphTextSecondary};
		font-weight: ${weight.bold};
	`
);

export const LoadingContainer = styled.div`
	height: 40vh;
`;
