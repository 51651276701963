import styled, { css } from 'styled-components';

type TableRowCellProps<T> = {
	width: string;
	minWidth?: string;
	minHeight?: string;
	text: T[keyof T] | JSX.Element;
};

const RowCell = <T,>({
	text,
	width,
	minWidth,
	minHeight,
}: TableRowCellProps<T>): JSX.Element => {
	return (
		<StyledRowCell
			data-testid='data_table_row_cell'
			width={width}
			$minWidth={minWidth}
			$minHeight={minHeight}
		>
			<StyledSpan>{text as React.ReactNode}</StyledSpan>
		</StyledRowCell>
	);
};

export { RowCell };

interface StyledRowCellProps {
	width?: string;
	$minWidth?: string;
	$minHeight?: string;
}

const StyledRowCell = styled.div<StyledRowCellProps>(
	({ width, $minWidth, $minHeight, theme: { spacing } }) => css`
		padding: ${spacing.xs} ${spacing.md};
		box-sizing: border-box;
		width: ${width || '100%'};
		min-width: ${$minWidth || 'none'};
		max-width: 100%;
		min-height: ${$minHeight || 'none'};
		display: flex;
		align-items: center;
	`
);

const StyledSpan = styled.span`
	margin: 0;
	width: 100%;
`;
