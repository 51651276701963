import '@mantine/dates/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { initAmplitude } from 'analytics';
import { App } from './components/App';
import './i18n';
import { initSentry } from './sentry';

// Analytics
initAmplitude();
// Crash Reporting
initSentry();

const container = document.getElementById('root');
// We already know that the container is always available https://react.dev/blog/2022/03/08/react-18-upgrade-guide
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
