import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useQuestionnaireQuery } from 'api/questionnaire';
import { Questionnaire } from 'enums/questionnaire';
import { LoadingLayout, RCRLayout } from '../../shared/ReportLoader';
import { Report } from '../CognitiveEvaluation.types';
import {
	QnrRowData,
	getQuestionnaireScore,
	parseQuestionnaireData,
} from '../CustomQuestionnaire.utils';

import { GDSLayout } from './GdsLayout';
import { parseQuestions } from './GdsReport.utils';

type GdsReportProps = {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
	mockedReportData?: GdsReportData;
};

type GdsReportData = {
	questions: QnrRowData[];
	score: {
		total: number | string;
		interpretation: string;
	};
};

export function GdsReport({
	batteryResult,
	mockedReportData,
	segmentResult,
}: Readonly<GdsReportProps>) {
	const { segmentResultId } = useMemo(() => {
		const { segmentResultId } = parseQuestionnaireData(
			Questionnaire.GDS,
			batteryResult,
			segmentResult
		);

		return { segmentResultId };
	}, [batteryResult]);

	const [reportData, setReportData] = useState<GdsReportData | null>(
		mockedReportData ?? null
	);

	const { data: qnrData, isLoading: qnrLoading } = useQuestionnaireQuery(
		batteryResult?.batteryResultById?.id ?? '',
		segmentResultId ?? ''
	);

	useEffect(() => {
		if (qnrData) {
			const score = getQuestionnaireScore(qnrData);
			const interpretation =
				qnrData?.scores?.interpretation?.score.toString() ?? '';

			const questions = parseQuestions(qnrData);

			setReportData({
				questions,
				score: { total: score, interpretation },
			});
		}
	}, [qnrData]);

	if (!mockedReportData && qnrLoading) {
		return (
			<LoadingLayout
				title='web.report.gds.resultsSummaryCard.title'
				tooltipText='web.report.gds.resultsSummaryCard.tooltip'
			/>
		);
	}

	if (!reportData) {
		return (
			<RCRLayout
				description='web.report.unknown'
				title='web.report.gds.resultsSummaryCard.title'
				tooltipText='web.report.gds.resultsSummaryCard.tooltip'
			/>
		);
	}

	return (
		<GdsReportContainer>
			<GDSLayout
				score={reportData.score}
				resultData={reportData.questions}
			/>
		</GdsReportContainer>
	);
}

const GdsReportContainer = styled.div(
	({ theme: { spacing } }) => css`
		// account for distance between last element of the layout and the patient id & battery id container
		margin-bottom: ${spacing.sm};
	`
);
