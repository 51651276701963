import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { icons } from '../../../../enums/icons';
import { Maybe, SegmentType } from '../../../../generated/graphql';
import { ERROR } from '../../../../logging/linusLogger';
import { fetchUtil, prefix } from '../../../../s3ApiFetch';
import { UserContext } from '../../../context/UserContext';
import { Icon } from '../../../shared/designSystem/Icon';
import { H1, H4 } from '../../../shared/designSystem/TextComponents';
import { useRestartSegment } from '../../../shared/hooks/useRestartSegment';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { StyledCard } from '../../../shared/StyledCard';
import { ClockAnimation } from '../ClockAnimation/ClockAnimation';
import { ClockConcernsPropType } from '../ClockConcerns/ClockConcerns';
import { SegmentEvent, SegmentEvents } from '../SegmentEvents/SegmentEvents';
import { Auth } from 'features/auth-service';

type DctClockRecordingProps = {
	title: string;
	tooltipText: string;
	// TODO: turn this into enum
	clockType?: 'COMMAND' | 'COPY';
	segmentId?: string;
	// TODO: getting rid of this breaks the storybook, revisit
	clockDataUrl?: string;
	contentLoaded?: () => void;
	testData?: {
		clockData: any;
	};
	concern?: ClockConcernsPropType;
};

export const DctClockRecording = ({
	title,
	tooltipText,
	clockType,
	segmentId,
	contentLoaded,
	testData,
	concern,
}: DctClockRecordingProps): JSX.Element | null => {
	const { t } = useTranslation();
	const envPrefix = prefix();
	const [clockData, setClockData] = useState<Maybe<Record<string, any>>>(
		(testData?.clockData || undefined) as any
	);
	const [clockImage, setClockImage] = useState('');
	const [activityEvents, setActivityEvents] = useState<SegmentEvent[]>([]);
	const fetchData = useCallback(fetchUtil, []);
	const [hasFetchedData, setHasFetchedData] = useState(false);
	const { currentUser } = useContext(UserContext);
	const isRestartSegmentEnabled = useRestartSegment();

	useEffect(() => {
		const abortController = new AbortController();
		const url = `${envPrefix}s3MetricFiles/${segmentId}/clock.json`;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const responseHandler = (response: any) => {
			setClockData(response.data);
		};
		fetchData({
			url,
			signal: abortController.signal,
			onSuccess: responseHandler,
			auth: Auth,
		}).catch(console.error);
		return () => {
			abortController.abort();
		};
	}, [fetchData, segmentId, envPrefix]);

	useEffect(() => {
		const abortController = new AbortController();
		const fileName =
			clockType === 'COPY'
				? 'final-drawing-copy-clock.png'
				: 'final-drawing-command-clock.png';
		const url = `${envPrefix}s3MetricFiles/${segmentId}/${fileName}`;
		const responseType = 'blob';

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const responseHandler = (response: any) => {
			const blob = new Blob([response.data]);
			const blobUrl = URL.createObjectURL(blob);
			setClockImage(blobUrl);
			setHasFetchedData(true);
		};

		const errorHandler = (_error: any) => {
			ERROR(
				`Missing ${fileName} for segment result id: ${segmentId} in organization: ${currentUser.organizationId}.`
			);
			setHasFetchedData(true);
		};

		fetchData({
			url,
			signal: abortController.signal,
			onSuccess: responseHandler,
			onError: errorHandler,
			responseType,
			auth: Auth,
		});
		return () => {
			abortController.abort();
		};
	}, [fetchData, clockType, segmentId, envPrefix]);

	useEffect(() => {
		const abortController = new AbortController();
		const fileName = 'segment_result_metadata.json';
		const url = `${envPrefix}s3MetricFiles/${segmentId}/${fileName}`;

		const responseHandler = (response: any) => {
			const segmentEventData: SegmentEvent[] =
				Array.isArray(response.data) &&
				response.data.filter(
					(segment: SegmentEvent) =>
						segment.segmentType === SegmentType.Clock &&
						segment?.context?.subsegment?.toUpperCase() ===
							clockType
				);
			segmentEventData.length && setActivityEvents(segmentEventData);
		};

		const errorHandler = (_error: any) => {
			ERROR(
				`Missing ${fileName} for segment result id: ${segmentId} in organization: ${currentUser.organizationId}.`
			);
		};

		const fetchS3Resource = async () => {
			await fetchData({
				url,
				signal: abortController.signal,
				onSuccess: responseHandler,
				onError: errorHandler,
				auth: Auth,
			});
		};
		fetchS3Resource();

		return () => {
			abortController.abort();
		};
	}, [clockType, currentUser.organizationId, envPrefix, segmentId]);

	useEffect(() => () => URL.revokeObjectURL(clockImage), [clockImage]);

	// alert parent component that all the data is loaded an ready to be printed
	useEffect(() => {
		if (clockData && hasFetchedData) {
			contentLoaded && contentLoaded();
		}
	});

	if (!clockData || !clockType) {
		return null;
	}

	return (
		<StyledWrapper>
			<StyledTitle>
				<H4>{title}</H4>
				<LinusTooltip
					overlay={<StyledTooltip>{tooltipText}</StyledTooltip>}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
			</StyledTitle>
			<StyledPrintHide>
				{isRestartSegmentEnabled && (
					<SegmentEvents segmentEvents={activityEvents} />
				)}
			</StyledPrintHide>
			<StyledBody>
				{clockData[clockType.toLowerCase() + '_clock'].drawing?.data
					?.stroke_data?.length > 0 ? (
					<StyledPrintHide>
						<ClockAnimation
							rawData={clockData}
							section_name={clockType}
							concern={concern}
						/>
					</StyledPrintHide>
				) : (
					<StyledUnavailableText>
						<H1>{t`web.report.DCTClock.unavailable`}</H1>
					</StyledUnavailableText>
				)}
				{clockImage ? (
					<StyledImg
						src={clockImage}
						alt={t`web.report.DCTClock.recording.imageAltText`}
					/>
				) : (
					<StyledUnavailableText>
						<Trans
							i18nKey={
								'web.dcrReport.recordings.clock.missingImage'
							}
							components={[
								<StyledMissingImageText />,
								<StyledMissingImageSubtext />,
							]}
						/>
					</StyledUnavailableText>
				)}
			</StyledBody>
		</StyledWrapper>
	);
};

const StyledWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => `
		min-width: 536px;
		height: auto;
		padding: ${spacing.lg};
		vertical-align: bottom;

		@media print {
			min-width: unset;
		}
	`
);

const StyledTitle = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		align-items: center;
		gap: ${spacing.sm};
		margin-bottom: ${spacing.md};
	`
);

const StyledBody = styled.div`
	width: fit-content;
	margin: auto;
	height: 100%;
	text-align: center;
`;

const StyledImg = styled.img`
	max-width: 100%;
	max-height: 100%;
	display: none;

	@media print {
		display: block;
		border: 1px solid rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		margin: 10px;
		/* height - margin - border - header height */
		max-height: calc(100% - 22px - 80px);
	}
`;

const StyledPrintHide = styled.div`
	@media print {
		display: none;
	}
`;

const StyledTooltip = styled.div`
	margin-left: 11px;
	margin-top: 3px;
`;

const StyledUnavailableText = styled.div`
	display: none;

	@media print {
		display: flex;
		flex-direction: column;
		border: 1px solid rgba(0, 0, 0, 0.25);
		margin: 10px;
		height: 50%;
		justify-content: center;
		align-self: stretch;
		width: 100%;
		padding: 10%;
		border-style: dashed;
	}
`;

const StyledMissingImageText = styled(H4)`
	font-weight: 500;
`;

const StyledMissingImageSubtext = styled.div``;
