import { Reason } from '@lh/eng-platform-organization-service-rest-client';

import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useReasonsQuery } from 'api/organization';
import { LinusModal } from 'components/shared/LinusModal';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { LoadingDots } from 'components/shared/LoadingDots';
import { ButtonSm, Icon } from 'components/shared/designSystem';
import { useGetReport } from 'components/shared/hooks/useGetReport';
import { icons } from 'enums/icons';
import { BatteryType } from 'generated/graphql';
import { ERROR } from 'logging/linusLogger';
import { DCTClassificationString } from '../AssessmentComponents/MiniModal/MiniModalReport';
import { getAssessmentData } from '../cognitiveEvalParseData';

import {
	FieldType,
	getAdministratorCredentials,
	getReasonsByOrgFields,
} from './NoteTemplate.controller';
import {
	Assessment,
	Buttons,
	Container,
	Copied,
	Evaluation,
	HelperText,
	LineDivider,
	P1Med,
	PlainText,
	Title,
	TitleContainer,
	Tooltip,
	Wrapper,
} from './NoteTemplate.style';
import type {
	NoteTemplateProps,
	NoteTemplateScoresData,
	TestAdiministratorData,
} from './NoteTemplate.types';

export function NoteTemplate({
	batteryResultId,
	isTest = false,
	batteryData,
	batteryDataLoading,
	participantId,
	mockedAttestation,
	mockedReasonsForTesting,
	orgId,
	onClose,
	score,
	totalScore = 5,
	requiresReview = false,
}: Readonly<NoteTemplateProps>) {
	const theme = useTheme();
	const { t } = useTranslation();

	const {
		data: { assignment: batteryAssignment, battery: batteryResult },
		loading: { loadingAssignment, loadingBattery },
	} = useGetReport({
		participantId,
		batteryResultId,
		orgId,
	});

	const [attestation, setAttestation] =
		useState<TestAdiministratorData | null>(mockedAttestation ?? null);
	const [reasonsForTesting, setReasonsForTesting] = useState<string | null>(
		mockedReasonsForTesting ?? null
	);
	const [showCopiedText, setShowCopiedText] = useState(false);
	const [scoresData, setScoresData] = useState<NoteTemplateScoresData>({
		dcrScore: score ? Number(score) : null,
		requiresReview: false,
	});

	const nodes = batteryData?.participant.batteryResults.nodes ?? [];

	const reasonForTestingCodes = batteryAssignment?.reason?.codes ?? [];
	const { data: reasonsForTestingData } = useReasonsQuery({
		organizationId: orgId,
		filterValue: reasonForTestingCodes,
		filterField: getReasonsByOrgFields(
			reasonForTestingCodes.length,
			FieldType.FilterField
		),
		filterOperator: getReasonsByOrgFields(
			reasonForTestingCodes.length,
			FieldType.FilterOperator
		),
		logicalOperator: getReasonsByOrgFields(
			reasonForTestingCodes.length,
			FieldType.LogicalOperator
		),
	});

	useEffect(() => {
		if (batteryResult) {
			if (nodes.length === 0) {
				setScoresData({ dcrScore: null, requiresReview: false });
			} else {
				const { miniModalAssessment } = getAssessmentData({
					batteryResult,
				});
				const dcrScore = miniModalAssessment?.metricItems[
					'dcr_score'
				] ?? {
					value: -1,
					algorithmVersion: '0.1',
				};
				const miniModalClassification =
					(miniModalAssessment?.metricItems['dcr_classification']
						?.value as string) || '';

				const requiresReview =
					Number(dcrScore.value) < 0 ||
					miniModalClassification ===
						DCTClassificationString.UNANALYZABLE;

				setScoresData({
					dcrScore: Number(dcrScore.value),
					requiresReview,
				});
			}
		}
	}, [batteryResult, nodes]);

	useEffect(() => {
		async function getNoteTemplateData() {
			if (batteryAssignment) {
				try {
					const currentAssessment = getCurrentAssessment();
					const testAdministratorData = currentAssessment?.proctor;
					const testAdiminstratorCredentials =
						getAdministratorCredentials(
							testAdministratorData?.suffix ?? []
						);

					const testAdiminstrator = `${testAdministratorData?.firstName} ${testAdministratorData?.lastName}${testAdiminstratorCredentials}`;
					parseReasonsForTesting(
						reasonForTestingCodes.length > 0
							? reasonsForTestingData
							: []
					);
					setAttestation({
						fullNameWithTitle: testAdiminstrator,
						testingTime:
							batteryAssignment.attestation?.attestationTime,
					});
				} catch (err) {
					console.error(err);
					ERROR('Error Creating Services', err);
				}
			}
		}

		getNoteTemplateData();
	}, [batteryAssignment, reasonsForTestingData]);

	function getDcrPerformanceText() {
		const dcrScore = score ?? scoresData.dcrScore;

		if (Number(dcrScore) === -1) {
			return '';
		}

		if (Number(dcrScore) <= 1) {
			return t`web.report.noteTemplate.container.assessment.impairment`;
		}

		if (Number(dcrScore) <= 3) {
			return t`web.report.noteTemplate.container.assessment.borderline`;
		}

		return t`web.report.noteTemplate.container.assessment.nonImpairment`;
	}

	function getCompletedAssesments() {
		const currentAssessment = getCurrentAssessment();

		if (
			currentAssessment?.battery.batteryType ===
			BatteryType.CognitiveEvaluation
		) {
			return [
				t`web.report.noteTemplate.container.assessment.dcr`,
				t`web.report.noteTemplate.container.assessment.lhq`,
			];
		}

		return [t`web.report.noteTemplate.container.assessment.dcr`];
	}

	function parseReasonsForTesting(data?: Reason[]) {
		if (!data?.length) {
			setReasonsForTesting('');
			return;
		}

		const mappedData = data.map(
			(item) => `${item.diagnosisCode}: ${item.diagnosisDescription}`
		);

		setReasonsForTesting(mappedData.join(', '));
	}

	function getCurrentAssessment() {
		return batteryData?.participant.batteryResults.nodes.find(
			(node) => node.id === batteryResultId
		);
	}

	function handleCopy() {
		navigator.clipboard.writeText(clipboardText.replace(/<\/?b>/g, ''));
		setShowCopiedText(true);
	}

	const DCRTextTranslation =
		requiresReview || scoresData.requiresReview
			? t('web.report.noteTemplate.container.assessment.rcr')
			: t('web.report.noteTemplate.container.assessment.dcrScore', {
					score: score ?? scoresData.dcrScore,
					total: totalScore,
			  });

	const reasonsForTestingText =
		reasonsForTesting && reasonsForTesting.length > 0
			? `${t(
					'web.report.noteTemplate.container.assessment.reason'
			  )} ${reasonsForTesting}\n\n`
			: '';
	const taAttestationText = attestation?.testingTime
		? `${t('web.report.noteTemplate.container.assessment.testInfo', {
				fullNameWithTitle: attestation?.fullNameWithTitle,
				minutes: attestation?.testingTime,
		  })}\n\n`
		: '';

	const clipboardText = `${t(
		'web.report.noteTemplate.container.assessment.administered'
	)}${getCompletedAssesments().map(
		(item) => ` ${item}`
	)}\n\n${taAttestationText}${reasonsForTestingText}${DCRTextTranslation}${getDcrPerformanceText()}\n\n${t(
		'web.report.noteTemplate.container.evaluation.timeSpent'
	)} `;

	const loading = loadingBattery || !batteryResult || batteryDataLoading;
	const loadingCopy =
		loadingAssignment ||
		!batteryAssignment ||
		loadingBattery ||
		!batteryResult ||
		batteryDataLoading ||
		scoresData.dcrScore === null ||
		attestation === null ||
		reasonsForTesting === null;

	return (
		<LinusModal
			width='666px'
			title={t('web.report.noteTemplate.title')}
			titleIcon={icons.NoteClipboard}
			onClose={onClose}
		>
			{!isTest && loading ? (
				<Wrapper>
					<LoadingDots data-testid='loading-dots' />
				</Wrapper>
			) : (
				<>
					<HelperText>
						{t('web.report.noteTemplate.helperText')}
					</HelperText>
					<Container data-testid='note-template-modal'>
						<Assessment>
							<TitleContainer data-testid='assessment-header'>
								<Title>
									{t(
										'web.report.noteTemplate.container.assessment.title'
									)}
								</Title>
								<LinusTooltip
									overlay={
										<Tooltip>
											<Trans
												i18nKey={
													'web.report.noteTemplate.container.assessment.tooltip'
												}
											/>
										</Tooltip>
									}
									tooltipIcon={
										<Icon
											icon={icons.InfoOutlined}
											iconWidth={24}
											iconHeight={24}
										/>
									}
								/>
							</TitleContainer>
							<PlainText>
								<div>
									{t(
										'web.report.noteTemplate.container.assessment.administered'
									)}
								</div>
								{getCompletedAssesments().map((item) => (
									<Box key={item} component='li'>
										{item}
									</Box>
								))}
								<br />
								{attestation?.testingTime && (
									<div data-testid='attestation'>
										<Trans
											components={{
												b: <strong />,
											}}
											i18nKey='web.report.noteTemplate.container.assessment.testInfo'
											values={{
												fullNameWithTitle:
													attestation.fullNameWithTitle,
												minutes:
													attestation.testingTime,
											}}
										/>
										<br />
										<br />
									</div>
								)}
								{reasonsForTesting &&
									reasonsForTesting?.length > 0 && (
										<>
											<div data-testid='reasons-for-testing'>
												{t(
													'web.report.noteTemplate.container.assessment.reason'
												)}{' '}
												{reasonsForTesting}
											</div>
											<br />
										</>
									)}
								<div>
									{DCRTextTranslation}
									{getDcrPerformanceText()}
								</div>
							</PlainText>
						</Assessment>
						<LineDivider />
						<Evaluation data-testid='evaluation'>
							<TitleContainer>
								<Title>
									{t(
										'web.report.noteTemplate.container.evaluation.title'
									)}
								</Title>
								<LinusTooltip
									overlay={
										<Tooltip>
											<Trans
												i18nKey={
													'web.report.noteTemplate.container.evaluation.tooltip'
												}
											/>
										</Tooltip>
									}
									tooltipIcon={
										<Icon
											icon={icons.InfoOutlined}
											iconWidth={24}
											iconHeight={24}
										/>
									}
								/>
							</TitleContainer>
							<PlainText data-testid='time-spent-evaluation'>
								<Trans
									i18nKey={
										'web.report.noteTemplate.container.evaluation.timeSpent'
									}
									components={{
										b: <strong />,
									}}
								/>
							</PlainText>
						</Evaluation>
					</Container>
					{(showCopiedText || isTest) && (
						<Copied>
							<Icon
								icon={icons.CheckmarkOutlined}
								iconHeight={24}
								iconWidth={24}
								color={theme.colors.gray_10}
							/>
							<P1Med data-testid='copied-text'>
								{t('web.report.noteTemplate.copied')}
							</P1Med>
						</Copied>
					)}
					<Buttons data-testid='buttons-container'>
						<ButtonSm
							text={t`web.shared.close`}
							width='160px'
							onClick={onClose}
						/>
						<ButtonSm
							dataTestId='copy-button'
							primary
							disabled={loadingCopy && !isTest}
							loading={loadingCopy && !isTest}
							type='submit'
							text={t`web.shared.copy`}
							width='160px'
							onClick={handleCopy}
						/>
					</Buttons>
				</>
			)}
		</LinusModal>
	);
}
