import { OptionalFields } from '@lh/eng-platform-organization-service-rest-client';

import { AddParticipantModel } from 'components/shared/Forms/Schemas/addParticipantSchema';
import { EditParticipantModel } from 'components/shared/Forms/Schemas/editParticipantSchema';
import { DropdownOption } from 'types';

export type Input = {
	isRequired: boolean;
	label: string;
	name: keyof EditParticipantModel | keyof AddParticipantModel;
	preferDisplayLength?: 'long' | 'short';
	clearable?: boolean;
	disabled?: boolean;
	dropdownOptions?: DropdownOption[];
	dropUpSpace?: string;
	fieldName?: OptionalFields;
	helperText?: string;
	type?: string;
	err?: boolean;
	height?: string;
	width?: string;
};

export enum PatientForm {
	'Add',
	'Edit',
}
