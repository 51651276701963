import { OptionalFields } from '@lh/eng-platform-organization-service-rest-client';

import { useTranslation } from 'react-i18next';

import { useOrganizationFeatures } from 'components/shared/hooks/useOrganizationFeatures';
import { gender } from 'enums/participantEnums/gender';
import { handedness } from 'enums/participantEnums/handedness';
import { sex } from 'enums/participantEnums/sex';
import { FeatureType, ValueListType } from 'generated/graphql';
import { CurrentUser } from 'types';

import { buildLanguageDisplayOptions } from './AddPatientFormLogic';
import { Input, PatientForm } from './PatientForm.types';

export const useInputs = (currentUser: CurrentUser, type: PatientForm) => {
	const features = useOrganizationFeatures();
	const { t } = useTranslation();

	const languageDropdownOptions = buildLanguageDisplayOptions(
		currentUser.organizationParticipantLanguages
	);

	const inputData: Input[] = [
		{
			name: 'firstName',
			label: t`web.patients.forms.firstNameLabel`,
			isRequired: true,
		},
		{
			name: 'lastName',
			label: t`web.patients.forms.lastNameLabel`,
			isRequired: true,
		},
		{
			name: 'birthDate',
			label: t`web.patients.forms.dateOfBirthLabel`,
			type: 'date',
			clearable: false,
			isRequired: true,
		},
		{
			name: 'language',
			label: t`web.patients.forms.languageLabel`,
			disabled:
				type === PatientForm.Edit &&
				!currentUser?.organizationValueLists,
			type: 'select',
			dropdownOptions: languageDropdownOptions,
			isRequired: true,
			preferDisplayLength: 'short',
		},
		{
			name: 'educationIds',
			label: t`web.patients.forms.educationLevelLabel`,
			type: 'select',
			disabled:
				!currentUser?.organizationValueLists ||
				(type === PatientForm.Edit &&
					!currentUser?.organizationValueLists),
			dropdownOptions:
				currentUser?.organizationValueLists?.[
					ValueListType.Education
				]?.toOptions(),
			helperText: t`web.patients.forms.helpers.educationLevel`,
			isRequired: true,
		},
		{
			name: 'handedness',
			label: t`web.patients.forms.dominantHandLabel`,
			type: 'select',
			dropdownOptions: handedness?.toOptions(),
			helperText: t`web.patients.forms.helpers.dominantHand`,
			isRequired: true,
		},
		{
			name: 'sexAssignedAtBirth',
			label: t`web.patients.forms.sexLabel`,
			type: 'select',
			dropdownOptions: sex?.toOptions(),
			helperText: t`web.patients.forms.helpers.sex`,
			isRequired: true,
		},
		{
			name: 'gender',
			label: t`web.patients.forms.genderLabel`,
			type: 'select',
			dropdownOptions: gender?.toOptions(),
			isRequired: false,
			helperText: t`web.patients.forms.helpers.gender`,
			fieldName: OptionalFields.Gender,
		},
		{
			name: 'raceIds',
			label: t`web.patients.forms.raceLabel`,
			type: 'multi',
			disabled:
				!currentUser?.organizationValueLists ||
				(type === PatientForm.Edit &&
					!currentUser?.organizationValueLists),
			dropdownOptions:
				currentUser?.organizationValueLists?.[
					ValueListType.Race
				]?.toOptions(),
			isRequired: false,
			fieldName: OptionalFields.Race,
		},
		{
			name: 'ethnicityIds',
			label: t`web.patients.forms.ethnicityLabel`,
			type: 'multi',
			disabled:
				!currentUser?.organizationValueLists ||
				(type === PatientForm.Edit &&
					!currentUser?.organizationValueLists),
			dropdownOptions:
				currentUser?.organizationValueLists?.[
					ValueListType.Ethnicity
				]?.toOptions(),
			isRequired: false,
			fieldName: OptionalFields.Ethnicity,
		},
		{
			name: 'externalId',
			label: t('web.patients.forms.patientIdLabel'),
			isRequired: false,
			fieldName: OptionalFields.ExternalId,
		},
		{
			name: 'contactEmail',
			label: t('web.patients.forms.contactEmailLabel'),
			isRequired: features.includes(FeatureType.RemoteAssessment),
		},
		{
			name: 'contactPhone',
			label: t('web.patients.forms.contactPhoneLabel'),
			type: 'contactPhone',
			isRequired: features.includes(FeatureType.RemoteAssessment),
		},
		{
			name: 'notes',
			label: t`web.patients.forms.notesLabel`,
			type: 'textarea',
			isRequired: false,
			fieldName: OptionalFields.Notes,
		},
	];
	return inputData;
};
