import {
	Handedness,
	Patient,
	SexAssignedAtBirth,
	Status,
} from '@lh/eng-platform-subject-service-rest-client';

import { InitialValues } from './ControllModal.types';

export const EMPTY_INPUTS: InitialValues = {
	firstName: '',
	lastName: '',
	contactEmail: '',
	contactPhone: '',
	patientRelationship: '',
};

export const MOCKED_PATIENT_INFO: Patient = {
	age: 0,
	birthDate: '',
	education: [],
	firstName: '',
	lastName: '',
	handedness: Handedness.Ambidextrous,
	sexAssignedAtBirth: SexAssignedAtBirth.Complex,
	id: '',
	language: '',
	organizationId: '',
	personas: [],
	relationships: [],
	status: Status.Active,
};
