import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';
import { Responses } from '../Responses';

type TrialsProps = {
	responseText: string;
	score: string;
	title: string;
};

export const TrialsTestIds = {
	container: 'trials-container',
};

const Trials = ({ responseText, score, title }: TrialsProps) => {
	return (
		<TrialsContainer data-testid={TrialsTestIds.container}>
			<Title>{title}</Title>
			<Responses responseText={responseText} score={score} />
		</TrialsContainer>
	);
};

const TrialsContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: ${spacing.sm};
	`
);

const Title = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export { Trials };
