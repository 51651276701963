import { ReactNode } from 'react';

import { useCurrentUser } from 'api/user/useCurrentUser';
import { Memoize } from 'helpers/Memoize';
import { useOrganizationStore } from 'store';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';

import { UserOnlyContext } from './UserOnlyContext';

export const UserOnlyProvider = ({
	children,
}: {
	children: ReactNode;
}): JSX.Element | null => {
	const { data: user, refetch } = useCurrentUser();

	const orgId = useOrganizationStore((state) => state.id);

	useUpdateEffect(() => {
		refetch();
	}, [orgId]);

	if (!user) {
		return null;
	}

	const providedValue = {
		user,
		refetchUser: refetch,
	};

	return (
		<UserOnlyContext.Provider value={providedValue}>
			<Memoize>{children}</Memoize>
		</UserOnlyContext.Provider>
	);
};
