import {
	DefaultApiInviteUserRequest,
	User,
} from '@lh/eng-platform-organization-service-rest-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function inviteUser(searchInput: DefaultApiInviteUserRequest) {
	if (!searchInput.organizationId) {
		throw new Error("useInviteNewUser: organizationId can't be null");
	}

	const service = await getOrganizationService();
	const { data } = await service.inviteUser(searchInput);
	return data;
}

export function useInviteNewUser() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: `Error on inviting user`,
		},
		mutationFn: inviteUser,
		onSuccess: async (invitedUser, variables) => {
			client.setQueryData<User>(
				[QueryKey.User, variables.organizationId, invitedUser.id],
				invitedUser
			);
		},
	});
}
