import {
	DefaultApiCreateSubjectRequest,
	Subject,
} from '@lh/eng-platform-subject-service-rest-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubjectSubService } from 'api/getSubjectSubService';
import { QueryKey } from 'api/query';

export async function createSubject(
	subjectInput: DefaultApiCreateSubjectRequest
) {
	if (!subjectInput.organizationId) {
		throw new Error(
			"useCreateSubjectMutation: organizationId can't be null"
		);
	}

	if (!subjectInput.createSubjectInput) {
		throw new Error(
			"useCreateSubjectMutation: createSubjectInput can't be null"
		);
	}

	const service = await getSubjectSubService();
	const { data } = await service.createSubject(subjectInput);
	return data;
}

export function useCreateSubjectMutation() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error creating subject',
		},
		mutationFn: createSubject,
		onSuccess: async (newSubject) => {
			client.setQueryData<Subject>(
				[QueryKey.Subject, newSubject.id, newSubject.organizationId],
				newSubject
			);
		},
	});
}
