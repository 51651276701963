import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { parsePhrases } from '../AssessmentComponents/MiniModal//MiniModalRecordings/MiniModalRecordings';
import { getAssessmentData } from '../cognitiveEvalParseData/cognitiveEvalParseData';
import { Report } from 'components/report/CognitiveEvaluation.types';
import { useShortPhrases } from 'components/shared/hooks';
import { LoadingDots } from 'components/shared/LoadingDots';
import { SegmentType } from '../../../generated/graphql';
import { ClockConcernsPropType } from '../SegmentComponents/ClockConcerns/ClockConcerns';
import { DctClockRecording } from '../SegmentComponents/DctClockRecording/DctClockRecording';
import { DctScoreClassification } from '../SegmentComponents/DctClockSubscore/DctClockSubscore';
import { DctClockCompositeScales } from '../SegmentComponents/DctClockSubscore/DctClockCompositeScales';
import { H4 } from '../../shared/designSystem/TextComponents';

type DCTClockLayoutProps = {
	readonly batteryResult?: Report;
	readonly segmentResult?: DeepSegmentResult;
	readonly copyClockLoaded?: () => void;
	readonly commandClockLoaded?: () => void;
};

export const DCTClassificationString = {
	UNANALYZABLE: 'unanalyzable',
};

export function DCTClockLayout({
	batteryResult,
	segmentResult,
	copyClockLoaded,
	commandClockLoaded,
}: DCTClockLayoutProps) {
	const { t } = useTranslation();

	const clockShortPhrases = useShortPhrases();

	const { miniModalAssessment: miniModalData } = getAssessmentData({
		batteryResult,
		segmentResult,
	});

	if (!miniModalData) {
		return (
			<LoadingContainer>
				<LoadingDots />
			</LoadingContainer>
		);
	}

	const miniModalSubscoreSegments = miniModalData.segmentResults || [];
	const dctClockData =
		miniModalSubscoreSegments.length > 0
			? miniModalSubscoreSegments.find(
					(subscoreSegment) =>
						subscoreSegment?.segmentType === SegmentType.Clock
			  )
			: undefined;

	if (miniModalData) {
		miniModalData.features = { clockShortPhrases };
	}

	if (!dctClockData) {
		return (
			<LoadingContainer>
				<LoadingDots />
			</LoadingContainer>
		);
	}

	const dctClockSegment = miniModalSubscoreSegments.find(
		(segment) => segment.segmentType === SegmentType.Clock
	);

	if (!dctClockSegment) return null;

	let copyClockShortPhrases: ClockConcernsPropType | undefined = undefined;
	let commandClockShortPhrases: ClockConcernsPropType | undefined = undefined;

	if (miniModalData?.features?.clockShortPhrases) {
		const result = parsePhrases(dctClockSegment);
		copyClockShortPhrases = result.copyClockShortPhrases;
		commandClockShortPhrases = result.commandClockShortPhrases;
	}

	const dctClockMetricData = dctClockData.metricItems;
	const classification = dctClockMetricData['DCTClassificationString']
		? (dctClockMetricData['DCTClassificationString']
				.value as DctScoreClassification)
		: null;

	return (
		<StyledContainer>
			<StyledWrapper>
				<StyledColumn>
					<StyledClock>
						<DctClockRecording
							title={t`web.dcrReport.recordings.clock.copy.title`}
							clockType='COPY'
							tooltipText={t`web.dcrReport.recordings.clock.copy.tooltipText`}
							segmentId={dctClockSegment.id}
							contentLoaded={copyClockLoaded}
							concern={copyClockShortPhrases}
						/>
					</StyledClock>
				</StyledColumn>
				<StyledColumn>
					<StyledClock>
						<DctClockRecording
							title={t`web.dcrReport.recordings.clock.command.title`}
							clockType='COMMAND'
							tooltipText={t`web.dcrReport.recordings.clock.command.tooltipText`}
							segmentId={dctClockSegment.id}
							contentLoaded={commandClockLoaded}
							concern={commandClockShortPhrases}
						/>
					</StyledClock>
				</StyledColumn>
			</StyledWrapper>
			{!!classification &&
				classification !== DCTClassificationString.UNANALYZABLE && (
					<StyledPrintOnlyWrapper>
						<StyledH4>{t`web.dcrReport.print.clockDrawingCompositeScales.title`}</StyledH4>
						<DctClockCompositeScales
							metricData={dctClockMetricData}
						/>
					</StyledPrintOnlyWrapper>
				)}
		</StyledContainer>
	);
}

const LoadingContainer = styled.div`
	height: 40vh;
`;

const StyledContainer = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;
		margin-bottom: ${spacing.xl};

		@media print {
			margin-top: ${spacing.xl};
			break-before: page;
		}
	`
);

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => css`
		height: auto;
		display: flex;
		gap: ${spacing.xl};
	`
);

const StyledColumn = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		min-height: auto;
		gap: ${spacing.xl};
		width: 100%;

		@media print {
			min-height: 0;
		}
	`
);

const StyledClock = styled.div`
	height: auto;
`;

const StyledPrintOnlyWrapper = styled.div`
	display: none;

	@media print {
		display: block;
	}
`;

const StyledH4 = styled(H4)(
	({ theme: { spacing } }) => `
		margin-left: ${spacing.lg}
	`
);
