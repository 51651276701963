import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { useQuestionnaireQuery } from 'api/questionnaire';
import { Questionnaire } from 'enums/questionnaire';
import { LoadingLayout, RCRLayout } from '../../shared/ReportLoader';
import { Report } from '../CognitiveEvaluation.types';
import {
	getQuestionnaireScore,
	parseQuestionnaireData,
} from '../CustomQuestionnaire.utils';

import { Gad7Type, getInfoFromType, parseQuestions } from './Gad7Report.utils';
import { Gad7Layout } from './Layout';
import { Gad7ResultData } from './Layout/IndividualAnswers/Table';

type Gad7ReportData = {
	questions: Gad7ResultData[];
	score: {
		category: Gad7Type;
		total: string;
	};
};

type Props = {
	data?: Report;
	segmentResult?: DeepSegmentResult;
	mockedReportData?: Gad7ReportData;
};

export function Gad7Report({
	data,
	segmentResult,
	mockedReportData,
}: Readonly<Props>) {
	const { segmentResultId } = useMemo(() => {
		const { segmentResultId } = parseQuestionnaireData(
			Questionnaire.GAD_7,
			data,
			segmentResult
		);

		return { segmentResultId };
	}, [data]);

	const { data: qnrData, isLoading: qnrLoading } = useQuestionnaireQuery(
		data?.batteryResultById?.id ?? '',
		segmentResultId ?? ''
	);

	const [reportData, setReportData] = useState<Gad7ReportData | null>(
		mockedReportData ?? null
	);

	useEffect(() => {
		if (qnrData) {
			const score = getQuestionnaireScore(qnrData).toString();
			const category = (qnrData?.scores?.category?.score.toString() ??
				'') as Gad7Type;
			const questions = parseQuestions(qnrData);

			setReportData({
				questions,
				score: {
					category,
					total: score,
				},
			});
		}
	}, [qnrData]);

	const { color, text } = useMemo(
		() => getInfoFromType(reportData?.score.category),
		[reportData?.score.category]
	);

	if (!mockedReportData && qnrLoading) {
		return (
			<LoadingLayout
				title='web.report.gad7.titleReport'
				tooltipText='web.report.gad7.infoPanel.tooltip'
			/>
		);
	}

	if (!reportData) {
		return (
			<RCRLayout
				description='web.report.unknown'
				title='web.report.gad7.titleReport'
				tooltipText='web.report.gad7.infoPanel.tooltip'
			/>
		);
	}

	return (
		<Gad7Layout
			color={color}
			score={reportData.score.total}
			text={text}
			tableData={reportData.questions}
		/>
	);
}
