import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { usePreferences } from 'store';
import { loginLandingPage } from '../../auth/login/loginLandingPage';
import { UserContext } from '../../context/UserContext';

export const RootRoute = (): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	usePreferences();

	if (!currentUser?.id) {
		return <Navigate to={`auth/login${window.location.search}`} />;
	}
	return (
		<Navigate
			to={`${loginLandingPage(currentUser)}${window.location.search}`}
		/>
	);
};
