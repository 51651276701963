import { Center, Group, Stack } from '@mantine/core';
import { useContext, useRef } from 'react';

import { AnalyticsAction, sendEventData } from 'analytics';
import { UserContext } from 'components/context';
import { PatientScores } from 'components/patients/PatientDetails/PatientDetails_V2/PatientDetails.fixtures';
import { theme } from 'components/providers/StyleProvider/theme';
import {
	AnalyticsLanguage,
	AnalyticsReportName,
} from 'components/report/BatteryComponents/BatteryHeader/BatteryHeader.analytics';
import {
	PDFDownloadLinkResponse,
	PdfGenerateInput,
} from 'components/report/BatteryComponents/PdfDownloadService';
import { Report } from 'components/report/CognitiveEvaluation.types';
import { NoPathway } from 'components/shared/designSystem/Icons/svgs/Svgs';
import { Caption, P1, P2 } from 'components/shared/designSystem/TextComponents';
import { Auth } from 'features/auth-service';
import {
	BatteryHeaderFragment,
	BatteryType,
	PathwayType as PathType,
} from 'generated/graphql';
import { t } from 'i18n';
import { onGetReportData } from 'utils/reportUtils';

import { Concerns } from './Concerns';
import { Header } from './Pathway.header';
import { TEXT } from './Pathway.i18n';
import {
	Block,
	ID,
	LearnMore,
	Line,
	List,
	renderTags,
	YouAreHere,
} from './Pathway.misc';
import { Body, Container, Title } from './Pathway.style';
import {
	AdditionalConcerns,
	BhapFunction,
	PathwayID,
	Pathway as PathwayType,
} from './Pathway.types';
import { PersonalizedTag } from './PersonalizedTag';
import { ReversibleCauses } from './ReversibleCauses';

interface Patient {
	name: string;
	score: PatientScores;
	lastBatteryId: string;
	batteryResult?: Report;
	batteryParticipant?: BatteryHeaderFragment;
}

interface Props {
	patient: Patient;
	pathway: PathwayType;
}

export function Pathway({ patient, pathway }: Props) {
	const { currentUser } = useContext(UserContext);
	const generateReportInputRef = useRef<PdfGenerateInput | null>(null);

	const isDcrOnly =
		patient.batteryResult?.batteryResultById?.battery?.batteryType ===
		BatteryType.Dcr;

	const isLhqOnly =
		patient.batteryResult?.batteryResultById?.battery?.batteryType ===
		BatteryType.Lhq;

	function getReportName() {
		if (isDcrOnly) {
			return AnalyticsReportName.DCR_PATIENT_REPORT_BHAP;
		}
		if (isLhqOnly) {
			return AnalyticsReportName.LHQ_PATIENT_REPORT_BHAP;
		}

		return AnalyticsReportName.CCE_PATIENT_REPORT_BHAP;
	}

	function handlePollingSuccess(response: PDFDownloadLinkResponse) {
		if (!response || !response.downloadLink) return;

		if (generateReportInputRef.current) {
			sendEventData({
				eventType: AnalyticsAction.DownloadedReport,
				eventProperties: {
					downloadType: 'print PDF',
					language:
						AnalyticsLanguage[
							generateReportInputRef.current.language
						],
					reportName: getReportName(),
					batteryResultId:
						generateReportInputRef.current.batteryResultId,
					source: 'CDS Pathway',
				},
			});
		}

		if (
			response.downloadLink.match('response-content-disposition=inline')
		) {
			//If the response content disposition is inline (meant to be previewed before downloading)
			window.open(response.downloadLink, '_blank');
		} else {
			//If the file is meant to be downloaded directly.
			window.location.assign(response.downloadLink);
		}
	}

	async function getReport() {
		const res = await onGetReportData(
			Auth,
			patient.lastBatteryId,
			currentUser,
			patient.batteryParticipant,
			'CalculatePatientReport',
			(input) => {
				generateReportInputRef.current = input;
			}
		);
		handlePollingSuccess(res);
	}

	const handleBhapClick = { BHAP: getReport };

	const pathwayMap: Record<PathType | PathwayID, JSX.Element> = {
		GreenHearingGoodAdditionalConcerns: (
			<GreenHearingGoodAdditionalConcerns
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		GreenHearingBadAdditionalConcerns: (
			<GreenHearingBadAdditionalConcerns
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		GreenNoConcerns: <GreenNoConcerns patient={patient} />,
		GreenHearingBad: (
			<GreenHearingBad
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		YellowHearingGood: (
			<YellowHearingGood
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		YellowHearingBadDelayedRecallGood: (
			<YellowHearingBadDelayedRecallGood
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		YellowHearingBadDelayedRecallBad: (
			<YellowHearingBadDelayedRecallBad
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		RedHearingGood: (
			<RedHearingGood
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		RedHearingBad: (
			<RedHearingBad
				patient={patient}
				pathway={pathway}
				handleBhapClick={handleBhapClick}
			/>
		),
		Empty: <Empty patient={patient} />,
	};

	return pathwayMap[pathway.pathwayInstanceId] ?? null;
}

function Empty({ patient }: { patient: Patient }) {
	return (
		<Container>
			<NoPathway />
			<Body>
				<Title>{t(`cds.pathway.nothingToSee`)}</Title>
				<P1>
					{patient.score.DCR === -1
						? t(`cds.pathway.assessmentNotScored`)
						: t(`cds.pathway.noScoreAvailable`)}
				</P1>
			</Body>
		</Container>
	);
}

function GreenHearingGoodAdditionalConcerns({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack gap={24} align='center' data-testid='green-hearing-good'>
			<Header
				id={ID.pathwayHeader}
				type='green'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block id={ID.encourage} maw={354} px={16} highlight='green' bold>
				<YouAreHere />
				<Stack gap={4}>
					<P2>{t(TEXT.encourage.title)}</P2>
					<Caption style={{ fontWeight: theme.weight.medium }}>
						<List>
							{TEXT.encourage.list.map((item) => (
								<List.Item key={item}>
									{renderTags(item)}
								</List.Item>
							))}
						</List>
					</Caption>
				</Stack>
			</Block>
			<Block.Container id={ID.concernsList}>
				<Block.Header value={t(TEXT.concernsList.header.DCR)} />
				{pathway.concernsList.length === 0 ? (
					<Block maw={400}>
						<Center bg={theme.colors.gray_90} py={8}>
							<P2 style={{ fontWeight: theme.weight.medium }}>
								{t(TEXT.concernsList.header.noConcerns)}
							</P2>
						</Center>
					</Block>
				) : (
					<Block>
						<Concerns
							scores={patient.score}
							firstName={patient.name}
							pathwayId={pathway.pathwayInstanceId}
							ids={pathway.concernsList}
							onClick={handleBhapClick}
						/>
					</Block>
				)}
			</Block.Container>
			<Block id={ID.retestAfter} maw={250}>
				<P2>{t(TEXT.retestAfter._12)}</P2>
			</Block>

			{/* Lines */}
			<Line.Solid start={ID.pathwayHeader} end={ID.encourage} />
			<Line.Solid start={ID.encourage} end={ID.concernsList} />
			<Line.Dashed
				start={ID.concernsList}
				end={ID.retestAfter}
				showHead
			/>
		</Stack>
	);
}

function GreenHearingBadAdditionalConcerns({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack
			gap={24}
			align='center'
			data-testid='green-hearing-bad-additional-concerns'
		>
			<Header
				id={ID.pathwayHeader}
				type='green'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block id={ID.encourage} maw={354} highlight='green' bold>
				<Center>
					<P2>{t(TEXT.encourage.title)}</P2>
				</Center>
			</Block>
			<Block
				id={ID.immediateRecallLessThan}
				maw={354}
				highlight='green'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.lessThan_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.concernsList}>
				<Block.Header
					value={t(TEXT.concernsList.header.DCR)}
					highlight='green'
					bold
				>
					<YouAreHere />
				</Block.Header>
				<Block highlight='green' bold>
					<Concerns
						scores={patient.score}
						firstName={patient.name}
						pathwayId={pathway.pathwayInstanceId}
						onClick={handleBhapClick}
						ids={[
							AdditionalConcerns.Hearing,
							...pathway.concernsList,
						]}
					/>
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifHearingPresent}
						value={t(TEXT.ifHearingPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifHearingNotPresent}
						value={t(TEXT.ifHearingNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressHearingConcern}>
					<P2>{t(TEXT.addressHearingConcern)}</P2>
				</Block>
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._12)}</P2>
				</Block>
				<Block id={ID.retestAsap}>
					<P2>{t(TEXT.retestAsap)}</P2>
				</Block>
			</Block.Grid>

			{/* Lines */}
			<Line.Solid start={ID.pathwayHeader} end={ID.encourage} />
			<Line.Solid start={ID.encourage} end={ID.immediateRecallLessThan} />
			<Line.Solid
				start={ID.immediateRecallLessThan}
				end={ID.concernsList}
			/>
			<Line.Dashed start={ID.concernsList} end={ID.ifHearingPresent} />
			<Line.Dashed
				start={ID.ifHearingPresent}
				end={ID.addressHearingConcern}
				showHead
			/>
			<Line.Dashed
				start={ID.addressHearingConcern}
				end={ID.retestAsap}
				showHead
			/>
			<Line.Dashed start={ID.concernsList} end={ID.ifHearingNotPresent} />
			<Line.Dashed
				start={ID.ifHearingNotPresent}
				end={ID.retestAfter}
				showHead
			/>
		</Stack>
	);
}

function GreenNoConcerns({ patient }: { patient: Patient }) {
	return (
		<Stack gap={24} align='center' data-testid='green-no-concerns'>
			<Header
				id={ID.pathwayHeader}
				type='green'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block id={ID.encourage} maw={354} px={16} highlight='green' bold>
				<YouAreHere />
				<Stack gap={4}>
					<P2>{t(TEXT.encourage.title)}</P2>
					<Caption style={{ fontWeight: theme.weight.medium }}>
						<List>
							{TEXT.encourage.list.map((item) => (
								<List.Item key={item}>
									{renderTags(item)}
								</List.Item>
							))}
						</List>
					</Caption>
				</Stack>
			</Block>
			<Block id={ID.retestAfter} maw={250}>
				<P2>{t(TEXT.retestAfter._12)}</P2>
			</Block>

			{/* Lines */}
			<Line.Solid start={ID.pathwayHeader} end={ID.encourage} />
			<Line.Solid start={ID.encourage} end={ID.retestAfter} showHead />
		</Stack>
	);
}

function GreenHearingBad({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack gap={24} align='center' data-testid='green-hearing-bad'>
			<Header
				id={ID.pathwayHeader}
				type='green'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block id={ID.encourage} maw={354} px={16} highlight='green' bold>
				<Stack gap={4}>
					<P2>{t(TEXT.encourage.title)}</P2>
					<Caption style={{ fontWeight: theme.weight.medium }}>
						<List>
							{TEXT.encourage.list.map((item) => (
								<List.Item key={item}>
									{renderTags(item)}
								</List.Item>
							))}
						</List>
					</Caption>
				</Stack>
			</Block>
			<Block
				id={ID.immediateRecallLessThan}
				maw={354}
				highlight='green'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.lessThan_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.hearingConcerns}>
				<Block.Header
					value={t(TEXT.concernsList.header.hearing)}
					highlight='green'
					bold
				>
					<YouAreHere />
				</Block.Header>
				<Block highlight='green' bold>
					<Concerns
						scores={patient.score}
						firstName={patient.name}
						pathwayId={pathway.pathwayInstanceId}
						ids={[AdditionalConcerns.Hearing]}
						onClick={handleBhapClick}
					/>
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifPresent}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifNotPresent}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressHearingConcern}>
					<P2>{t(TEXT.addressHearingConcern)}</P2>
				</Block>
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._12)}</P2>
				</Block>
				<Block id={ID.retestAsap}>
					<P2>{t(TEXT.retestAsap)}</P2>
				</Block>
			</Block.Grid>

			{/* Lines */}
			<Line.Solid start={ID.pathwayHeader} end={ID.encourage} />
			<Line.Solid start={ID.encourage} end={ID.immediateRecallLessThan} />
			<Line.Solid
				start={ID.immediateRecallLessThan}
				end={ID.hearingConcerns}
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifPresent} />
			<Line.Dashed
				start={ID.ifPresent}
				end={ID.addressHearingConcern}
				showHead
			/>
			<Line.Dashed
				start={ID.addressHearingConcern}
				end={ID.retestAsap}
				showHead
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifNotPresent} />
			<Line.Dashed
				start={ID.ifNotPresent}
				end={ID.retestAfter}
				showHead
			/>
		</Stack>
	);
}

function YellowHearingGood({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack gap={24} align='center' data-testid='yellow-hearing-good'>
			<Header
				id={ID.pathwayHeader}
				type='yellow'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block
				id={ID.immediateRecallEquals}
				maw={354}
				highlight='yellow'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.equals_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.reversibleCauses}>
				<Block.Header
					value={t(TEXT.reversibleCauses.title)}
					maw={350}
					bold
				>
					<YouAreHere />
				</Block.Header>
				<Block bold>
					<ReversibleCauses pathwayId={pathway.pathwayInstanceId} />
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifPresent}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifNotPresent}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressReversibleCauses}>
					<P2>{t(TEXT.addressReversibleCauses)}</P2>
				</Block>
				<div />
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._3)}</P2>
				</Block>
				<div />
				<Block id={ID.impairmentStillPresent}>
					<P2>{t(TEXT.impairmentStillPresent)}</P2>
				</Block>
			</Block.Grid>
			<Block.Container id={ID.concernsList}>
				<Block.Header value={t(TEXT.concernsList.header.DCR)} />
				{pathway.concernsList.length === 0 ? (
					<Block maw={400}>
						<Center bg={theme.colors.gray_90} py={8}>
							<P2 style={{ fontWeight: theme.weight.medium }}>
								{t(TEXT.concernsList.header.noConcerns)}
							</P2>
						</Center>
					</Block>
				) : (
					<Block>
						<Concerns
							scores={patient.score}
							firstName={patient.name}
							pathwayId={pathway.pathwayInstanceId}
							ids={pathway.concernsList}
							onClick={handleBhapClick}
						/>
					</Block>
				)}
			</Block.Container>
			<Block id={`${ID.retestAfter}2`} maw={250}>
				<P2>{t(TEXT.retestAfter._6)}</P2>
			</Block>
			<Block id={ID.considerCognitiveCare} maw={400}>
				<Group gap={4} justify='space-between' wrap='nowrap'>
					<P2>{t(TEXT.considerCognitiveCare)}</P2>
					<LearnMore />
				</Group>
			</Block>

			{/* Lines */}
			<Line.Solid
				start={ID.pathwayHeader}
				end={ID.immediateRecallEquals}
			/>
			<Line.Solid
				start={ID.immediateRecallEquals}
				end={ID.reversibleCauses}
			/>
			<Line.Dashed start={ID.reversibleCauses} end={ID.ifPresent} />
			<Line.Dashed
				start={ID.ifPresent}
				end={ID.addressReversibleCauses}
				showHead
			/>
			<Line.Dashed
				start={ID.addressReversibleCauses}
				end={ID.retestAfter}
				showHead
			/>
			<Line.Dashed
				start={ID.retestAfter}
				end={ID.impairmentStillPresent}
				showHead
			/>
			<Line.Dashed
				start={ID.impairmentStillPresent}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed start={ID.reversibleCauses} end={ID.ifNotPresent} />
			<Line.Dashed
				start={ID.ifNotPresent}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed
				start={ID.concernsList}
				end={`${ID.retestAfter}2`}
				showHead
			/>
			<Line.Dashed
				start={`${ID.retestAfter}2`}
				end={ID.considerCognitiveCare}
				showHead
			/>
		</Stack>
	);
}

function YellowHearingBadDelayedRecallGood({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack
			gap={24}
			align='center'
			data-testid='yellow-hearing-bad-delayed-recall-good'
		>
			<Header
				id={ID.pathwayHeader}
				type='yellow'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block
				id={ID.immediateRecallLessThan}
				maw={354}
				highlight='yellow'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.lessThan_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.hearingConcerns}>
				<Block.Header
					value={t(TEXT.concernsList.header.hearing)}
					highlight='yellow'
					bold
				>
					<YouAreHere />
				</Block.Header>
				<Block highlight='yellow' bold>
					<Concerns
						scores={patient.score}
						firstName={patient.name}
						pathwayId={pathway.pathwayInstanceId}
						ids={[AdditionalConcerns.Hearing]}
						onClick={handleBhapClick}
					/>
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifPresent}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifNotPresent}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressHearingConcern}>
					<P2>{t(TEXT.addressHearingConcern)}</P2>
				</Block>
				<Block id={ID.delayedRecallLessThan} gap={8}>
					<P2>{t(TEXT.delayedRecall.lessThan_2)}</P2>
					<PersonalizedTag.Tooltip>
						<PersonalizedTag
							type='delayedRecall'
							name={patient.name}
							score={patient.score.delayedRecall}
						/>
					</PersonalizedTag.Tooltip>
				</Block>
				<div />
				<Center>
					<Block.Solid id={ID.no} value={t(TEXT.no)} miw={40} />
				</Center>
				<Block id={ID.retestAsap}>
					<P2>{t(TEXT.retestAsap)}</P2>
				</Block>
				<Block id={ID.encourage}>
					<Stack gap={4}>
						<P2>{t(TEXT.encourage.title)}</P2>
						<Caption style={{ fontWeight: theme.weight.medium }}>
							<List>
								{TEXT.encourage.list.map((item) => (
									<List.Item key={item}>
										{renderTags(item)}
									</List.Item>
								))}
							</List>
						</Caption>
					</Stack>
				</Block>
				<div />
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._6)}</P2>
				</Block>
			</Block.Grid>

			{/* Lines */}
			<Line.Solid
				start={ID.pathwayHeader}
				end={ID.immediateRecallLessThan}
			/>
			<Line.Solid
				start={ID.immediateRecallLessThan}
				end={ID.hearingConcerns}
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifPresent} />
			<Line.Dashed
				start={ID.ifPresent}
				end={ID.addressHearingConcern}
				showHead
			/>
			<Line.Dashed
				start={ID.addressHearingConcern}
				end={ID.retestAsap}
				showHead
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifNotPresent} />
			<Line.Dashed
				start={ID.ifNotPresent}
				end={ID.delayedRecallLessThan}
				showHead
			/>
			<Line.Dashed start={ID.delayedRecallLessThan} end={ID.no} />
			<Line.Dashed start={ID.no} end={ID.encourage} showHead />
			<Line.Dashed start={ID.encourage} end={ID.retestAfter} showHead />
		</Stack>
	);
}

function YellowHearingBadDelayedRecallBad({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack
			gap={24}
			align='center'
			data-testid='yellow-hearing-bad-delayed-recall-bad'
		>
			<Header
				id={ID.pathwayHeader}
				type='yellow'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block
				id={ID.immediateRecallLessThan}
				maw={354}
				highlight='yellow'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.lessThan_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.hearingConcerns}>
				<Block.Header
					value={t(TEXT.concernsList.header.hearing)}
					highlight='yellow'
					bold
				>
					<YouAreHere />
				</Block.Header>
				<Block highlight='yellow' bold>
					<Concerns
						scores={patient.score}
						firstName={patient.name}
						pathwayId={pathway.pathwayInstanceId}
						ids={[AdditionalConcerns.Hearing]}
						onClick={handleBhapClick}
					/>
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifPresent}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifNotPresent}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressHearingConcern}>
					<P2>{t(TEXT.addressHearingConcern)}</P2>
				</Block>
				<Block id={ID.delayedRecallLessThan} gap={8}>
					<P2>{t(TEXT.delayedRecall.lessThan_2)}</P2>
					<PersonalizedTag.Tooltip>
						<PersonalizedTag
							type='delayedRecall'
							name={patient.name}
							score={patient.score.delayedRecall}
						/>
					</PersonalizedTag.Tooltip>
				</Block>
				<div />
				<Center>
					<Block.Solid id={ID.yes} value={t(TEXT.yes)} miw={40} />
				</Center>
				<Block id={ID.retestAsap}>
					<P2>{t(TEXT.retestAsap)}</P2>
				</Block>
			</Block.Grid>
			<Block.Container id={ID.reversibleCauses}>
				<Block.Header
					value={t(TEXT.reversibleCauses.title)}
					maw={350}
				/>
				<Block>
					<ReversibleCauses pathwayId={pathway.pathwayInstanceId} />
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={`${ID.ifPresent}2`}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={`${ID.ifNotPresent}2`}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressReversibleCauses}>
					<P2>{t(TEXT.addressReversibleCauses)}</P2>
				</Block>
				<div />
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._3)}</P2>
				</Block>
				<div />
				<Block id={ID.impairmentStillPresent}>
					<P2>{t(TEXT.impairmentStillPresent)}</P2>
				</Block>
			</Block.Grid>
			<Block.Container id={ID.concernsList}>
				<Block.Header value={t(TEXT.concernsList.header.DCR)} />
				{pathway.concernsList.length === 0 ? (
					<Block maw={400}>
						<Center bg={theme.colors.gray_90} py={8}>
							<P2 style={{ fontWeight: theme.weight.medium }}>
								{t(TEXT.concernsList.header.noConcerns)}
							</P2>
						</Center>
					</Block>
				) : (
					<Block>
						<Concerns
							scores={patient.score}
							firstName={patient.name}
							pathwayId={pathway.pathwayInstanceId}
							ids={pathway.concernsList}
							onClick={handleBhapClick}
						/>
					</Block>
				)}
			</Block.Container>
			<Block id={`${ID.retestAfter}2`} maw={250}>
				<P2>{t(TEXT.retestAfter._6)}</P2>
			</Block>
			<Block id={ID.considerCognitiveCare} maw={400}>
				<Group gap={4} justify='space-between' wrap='nowrap'>
					<P2>{t(TEXT.considerCognitiveCare)}</P2>
					<LearnMore />
				</Group>
			</Block>

			{/* Lines */}
			<Line.Solid
				start={ID.pathwayHeader}
				end={ID.immediateRecallLessThan}
			/>
			<Line.Solid
				start={ID.immediateRecallLessThan}
				end={ID.hearingConcerns}
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifPresent} />
			<Line.Dashed
				start={ID.ifPresent}
				end={ID.addressHearingConcern}
				showHead
			/>
			<Line.Dashed
				start={ID.addressHearingConcern}
				end={ID.retestAsap}
				showHead
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifNotPresent} />
			<Line.Dashed
				start={ID.ifNotPresent}
				end={ID.delayedRecallLessThan}
				showHead
			/>
			<Line.Dashed start={ID.delayedRecallLessThan} end={ID.yes} />
			<Line.Dashed
				start={ID.yes}
				end={ID.reversibleCauses}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed start={ID.reversibleCauses} end={`${ID.ifPresent}2`} />
			<Line.Dashed
				start={`${ID.ifPresent}2`}
				end={ID.addressReversibleCauses}
				showHead
			/>
			<Line.Dashed
				start={ID.addressReversibleCauses}
				end={ID.retestAfter}
				showHead
			/>
			<Line.Dashed
				start={ID.retestAfter}
				end={ID.impairmentStillPresent}
				showHead
			/>
			<Line.Dashed
				start={ID.impairmentStillPresent}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed
				start={ID.reversibleCauses}
				end={`${ID.ifNotPresent}2`}
			/>
			<Line.Dashed
				start={`${ID.ifNotPresent}2`}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed
				start={ID.concernsList}
				end={`${ID.retestAfter}2`}
				showHead
			/>
			<Line.Dashed
				start={`${ID.retestAfter}2`}
				end={ID.considerCognitiveCare}
				showHead
			/>
		</Stack>
	);
}

function RedHearingGood({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack gap={24} align='center' data-testid='red-hearing-good'>
			<Header
				id={ID.pathwayHeader}
				type='red'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block
				id={ID.immediateRecallEquals}
				maw={354}
				highlight='red'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.equals_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.reversibleCauses}>
				<Block.Header
					value={t(TEXT.reversibleCauses.title)}
					maw={350}
					highlight='red'
					bold
				>
					<YouAreHere />
				</Block.Header>
				<Block highlight='red' bold>
					<ReversibleCauses pathwayId={pathway.pathwayInstanceId} />
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifPresent}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifNotPresent}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressReversibleCauses}>
					<P2>{t(TEXT.addressReversibleCauses)}</P2>
				</Block>
				<div />
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._3)}</P2>
				</Block>
				<div />
				<Block id={ID.impairmentStillPresent}>
					<P2>{t(TEXT.impairmentStillPresent)}</P2>
				</Block>
			</Block.Grid>
			<Block.Container id={ID.concernsList}>
				<Block.Header value={t(TEXT.concernsList.header.DCR)} />
				{pathway.concernsList.length === 0 ? (
					<Block maw={400}>
						<Center bg={theme.colors.gray_90} py={8}>
							<P2 style={{ fontWeight: theme.weight.medium }}>
								{t(TEXT.concernsList.header.noConcerns)}
							</P2>
						</Center>
					</Block>
				) : (
					<Block>
						<Concerns
							scores={patient.score}
							firstName={patient.name}
							pathwayId={pathway.pathwayInstanceId}
							ids={pathway.concernsList}
							onClick={handleBhapClick}
						/>
					</Block>
				)}
			</Block.Container>
			<Block id={ID.referToNeurology} maw={250}>
				<P2>{t(TEXT.referToNeurology)}</P2>
			</Block>
			<Block id={`${ID.retestAfter}2`} maw={250}>
				<P2>{t(TEXT.retestAfter._3)}</P2>
			</Block>
			<Block id={ID.considerCognitiveCare} maw={400}>
				<Group gap={4} justify='space-between' wrap='nowrap'>
					<P2>{t(TEXT.considerCognitiveCare)}</P2>
					<LearnMore />
				</Group>
			</Block>

			{/* Lines */}
			<Line.Solid
				start={ID.pathwayHeader}
				end={ID.immediateRecallEquals}
			/>
			<Line.Solid
				start={ID.immediateRecallEquals}
				end={ID.reversibleCauses}
			/>
			<Line.Dashed start={ID.reversibleCauses} end={ID.ifPresent} />
			<Line.Dashed
				start={ID.ifPresent}
				end={ID.addressReversibleCauses}
				showHead
			/>
			<Line.Dashed
				start={ID.addressReversibleCauses}
				end={ID.retestAfter}
				showHead
			/>
			<Line.Dashed
				start={ID.retestAfter}
				end={ID.impairmentStillPresent}
				showHead
			/>
			<Line.Dashed
				start={ID.impairmentStillPresent}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed start={ID.reversibleCauses} end={ID.ifNotPresent} />
			<Line.Dashed
				start={ID.ifNotPresent}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed
				start={ID.concernsList}
				end={ID.referToNeurology}
				showHead
			/>
			<Line.Dashed
				start={ID.referToNeurology}
				end={`${ID.retestAfter}2`}
				showHead
			/>
			<Line.Dashed
				start={`${ID.retestAfter}2`}
				end={ID.considerCognitiveCare}
				showHead
			/>
		</Stack>
	);
}

function RedHearingBad({
	patient,
	pathway,
	handleBhapClick,
}: {
	patient: Patient;
	pathway: PathwayType;
	handleBhapClick: BhapFunction;
}) {
	return (
		<Stack gap={24} align='center' data-testid='red-hearing-bad'>
			<Header
				id={ID.pathwayHeader}
				type='red'
				patient={{ name: patient.name, score: patient.score.DCR }}
			/>
			<Block
				id={ID.immediateRecallLessThan}
				maw={354}
				highlight='red'
				gap={8}
				bold
			>
				<Center>
					<P2>{t(TEXT.immediateReecall.lessThan_3)}</P2>
				</Center>
				<PersonalizedTag.Tooltip>
					<PersonalizedTag
						type='immediateRecall'
						name={patient.name}
						score={patient.score.immediateRecall}
					/>
				</PersonalizedTag.Tooltip>
			</Block>
			<Block.Container id={ID.hearingConcerns}>
				<Block.Header
					value={t(TEXT.concernsList.header.hearing)}
					highlight='red'
					bold
				>
					<YouAreHere />
				</Block.Header>
				{pathway.concernsList.length === 0 ? (
					<Block maw={400} highlight='red' bold>
						<Center bg={theme.colors.gray_90} py={8}>
							<P2 style={{ fontWeight: theme.weight.medium }}>
								{t(TEXT.concernsList.header.noConcerns)}
							</P2>
						</Center>
					</Block>
				) : (
					<Block highlight='red' bold>
						<Concerns
							scores={patient.score}
							firstName={patient.name}
							pathwayId={pathway.pathwayInstanceId}
							ids={[AdditionalConcerns.Hearing]}
							onClick={handleBhapClick}
						/>
					</Block>
				)}
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={ID.ifPresent}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={ID.ifNotPresent}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressHearingConcern}>
					<P2>{t(TEXT.addressHearingConcern)}</P2>
				</Block>
				<Block id={ID.delayedRecallLessThan} gap={8}>
					<P2>{t(TEXT.delayedRecall.lessThan_2)}</P2>
					<PersonalizedTag.Tooltip>
						<PersonalizedTag
							type='delayedRecall'
							name={patient.name}
							score={patient.score.delayedRecall}
						/>
					</PersonalizedTag.Tooltip>
				</Block>
				<div />
				<Center id={ID.yes}>
					<Block.Solid value={t(TEXT.yes)} miw={40} />
				</Center>
				<Block id={ID.retestAsap}>
					<P2>{t(TEXT.retestAsap)}</P2>
				</Block>
			</Block.Grid>
			<Block.Container id={ID.reversibleCauses}>
				<Block.Header
					value={t(TEXT.reversibleCauses.title)}
					maw={350}
				/>
				<Block>
					<ReversibleCauses pathwayId={pathway.pathwayInstanceId} />
				</Block>
			</Block.Container>
			<Block.Grid>
				<Center>
					<Block.Solid
						id={`${ID.ifPresent}2`}
						value={t(TEXT.ifPresent)}
						miw={110}
					/>
				</Center>
				<Center>
					<Block.Solid
						id={`${ID.ifNotPresent}2`}
						value={t(TEXT.ifNotPresent)}
						miw={110}
					/>
				</Center>
				<Block id={ID.addressReversibleCauses}>
					<P2>{t(TEXT.addressReversibleCauses)}</P2>
				</Block>
				<div />
				<Block id={ID.retestAfter}>
					<P2>{t(TEXT.retestAfter._3)}</P2>
				</Block>
				<div />
				<Block id={ID.impairmentStillPresent}>
					<P2>{t(TEXT.impairmentStillPresent)}</P2>
				</Block>
			</Block.Grid>
			<Block.Container id={ID.concernsList}>
				<Block.Header value={t(TEXT.concernsList.header.DCR)} />
				{pathway.concernsList.length === 0 ? (
					<Block maw={400}>
						<Center bg={theme.colors.gray_90} py={8}>
							<P2 style={{ fontWeight: theme.weight.medium }}>
								{t(TEXT.concernsList.header.noConcerns)}
							</P2>
						</Center>
					</Block>
				) : (
					<Block>
						<Concerns
							scores={patient.score}
							firstName={patient.name}
							pathwayId={pathway.pathwayInstanceId}
							ids={pathway.concernsList}
							onClick={handleBhapClick}
						/>
					</Block>
				)}
			</Block.Container>
			<Block id={ID.referToNeurology} maw={250}>
				<P2>{t(TEXT.referToNeurology)}</P2>
			</Block>
			<Block id={`${ID.retestAfter}2`} maw={250}>
				<P2>{t(TEXT.retestAfter._3)}</P2>
			</Block>
			<Block id={ID.considerCognitiveCare} maw={400}>
				<Group gap={4} justify='space-between' wrap='nowrap'>
					<P2>{t(TEXT.considerCognitiveCare)}</P2>
					<LearnMore />
				</Group>
			</Block>

			{/* Lines */}
			<Line.Solid
				start={ID.pathwayHeader}
				end={ID.immediateRecallLessThan}
			/>
			<Line.Solid
				start={ID.immediateRecallLessThan}
				end={ID.hearingConcerns}
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifPresent} />
			<Line.Dashed
				start={ID.ifPresent}
				end={ID.addressHearingConcern}
				showHead
			/>
			<Line.Dashed
				start={ID.addressHearingConcern}
				end={ID.retestAsap}
				showHead
			/>
			<Line.Dashed start={ID.hearingConcerns} end={ID.ifNotPresent} />
			<Line.Dashed
				start={ID.ifNotPresent}
				end={ID.delayedRecallLessThan}
				showHead
			/>
			<Line.Dashed start={ID.delayedRecallLessThan} end={ID.yes} />
			<Line.Dashed
				start={ID.yes}
				end={ID.reversibleCauses}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed start={ID.reversibleCauses} end={`${ID.ifPresent}2`} />
			<Line.Dashed
				start={`${ID.ifPresent}2`}
				end={ID.addressReversibleCauses}
				showHead
			/>
			<Line.Dashed
				start={ID.addressReversibleCauses}
				end={ID.retestAfter}
				showHead
			/>
			<Line.Dashed
				start={ID.retestAfter}
				end={ID.impairmentStillPresent}
				showHead
			/>
			<Line.Dashed
				start={ID.impairmentStillPresent}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed
				start={ID.reversibleCauses}
				end={`${ID.ifNotPresent}2`}
			/>
			<Line.Dashed
				start={`${ID.ifNotPresent}2`}
				end={ID.concernsList}
				startAnchor='bottom'
				showHead
			/>
			<Line.Dashed
				start={ID.concernsList}
				end={ID.referToNeurology}
				showHead
			/>
			<Line.Dashed
				start={ID.referToNeurology}
				end={`${ID.retestAfter}2`}
				showHead
			/>
			<Line.Dashed
				start={`${ID.retestAfter}2`}
				end={ID.considerCognitiveCare}
				showHead
			/>
		</Stack>
	);
}
