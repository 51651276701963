import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const LegendRow = ({
	color,
	range,
	label,
}: {
	color: string;
	range: string;
	label: string;
}) => {
	return (
		<StyledLegendRow>
			<StyledRangeContainer>
				<Circle $color={color} />
				<P1Med>{range}</P1Med>
			</StyledRangeContainer>
			<P1>{label}</P1>
		</StyledLegendRow>
	);
};

const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

const StyledLegendRow = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: ${spacing.sm};
		`
);

const StyledRangeContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${spacing.xs};
	`
);

const Circle = styled.div<{ $color: string }>(
	({ $color }) =>
		css`
			border-radius: 50%;
			background-color: ${$color};
			width: 12px;
			height: 12px;
		`
);
