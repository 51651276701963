import { StyleProvider as MosaicStyleProvider } from '@lh/eng-web-mosaic-common';

import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';

import { FeatureFlagsProvider } from '../features/feature-flags';

import { ApolloProvider } from './providers/ApolloProvider/ApolloProvider';
import { I18nextProvider } from './providers/I18nextProvider/I18nextProvider';
import { ReactQueryProvider } from './providers/ReactQueryProvider/ReactQueryProvider';
import { RouteProvider } from './providers/RouteProvider/RouteProvider';
import { SessionTimeoutProvider } from './providers/SessionTimeoutProvider/SessionTimeoutProvider';
import { AvatarProvider } from './providers/StyleProvider/AvatarProvider';
import { StyleProvider } from './providers/StyleProvider/StyleProvider';

export const App = (): JSX.Element => {
	return (
		<FeatureFlagsProvider>
			<BrowserRouter>
				<ApolloProvider>
					<MosaicStyleProvider>
						<StyleProvider>
							{/* @ts-ignore @ts-expect-error Inconsistency between types due to React 18 */}
							<ModalProvider>
								<I18nextProvider>
									<AvatarProvider>
										<ReactQueryProvider>
											<SessionTimeoutProvider>
												<RouteProvider />
											</SessionTimeoutProvider>
										</ReactQueryProvider>
									</AvatarProvider>
								</I18nextProvider>
							</ModalProvider>
						</StyleProvider>
					</MosaicStyleProvider>
				</ApolloProvider>
			</BrowserRouter>
		</FeatureFlagsProvider>
	);
};
