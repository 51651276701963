import { useTimeout } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, createContext, useEffect } from 'react';

import { config } from '../../../config';
import { Auth } from '../../../features/auth-service';

const THIRTY_MINUTES = 30 * 60 * 1000;

export const userInteractionEvents = {
	CLICK: 'click',
	LOAD: 'load',
	KEY_DOWN: 'keydown',
	MOUSE_MOVE: 'mousemove',
	TOUCH_START: 'touchstart',
};

export const SessionTimeoutContext = createContext<SessionTimeoutContextProps>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	startTimer: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	resetTimer: () => {},
});

export type SessionTimeoutContextProps = {
	startTimer: () => void;
	resetTimer: () => void;
};

export const SessionTimeoutProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const queryClient = useQueryClient();

	const { start, clear } = useTimeout(
		async () => {
			clear();
			queryClient.clear();
			await Auth.logout(`${config.clinicalDomainUrl}/session-timeout`);
		},
		THIRTY_MINUTES,
		{
			autoInvoke: false,
		}
	);

	const resetTimer = () => {
		clear();
		start();
	};

	useEffect(() => {
		const events = [
			userInteractionEvents.CLICK,
			userInteractionEvents.LOAD,
			userInteractionEvents.KEY_DOWN,
			userInteractionEvents.MOUSE_MOVE,
			userInteractionEvents.TOUCH_START,
		];
		const resetTimeout = () => resetTimer();

		events.forEach((event) => window.addEventListener(event, resetTimeout));
		start();

		return () => {
			events.forEach((event) =>
				window.removeEventListener(event, resetTimeout)
			);
			clear();
		};
	}, []);

	return (
		<SessionTimeoutContext.Provider
			value={{ startTimer: start, resetTimer: resetTimer }}
		>
			{children}
		</SessionTimeoutContext.Provider>
	);
};
