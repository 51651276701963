import {
	UserOrganizationsRoleStatus,
	UserStatus,
} from '@lh/eng-platform-organization-service-rest-client';

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { icons } from '../../../enums/icons';
import { LinusModalDialog } from '../../shared/LinusModalDialog';

import { ChangeStatusSuccessModal } from './ChangeStatusSuccessModal';

export type PayloadType = {
	status: UserStatus;
	action?: string;
	organizationStatus?: UserOrganizationsRoleStatus;
};

type ChangeStatusModalProps = {
	firstname: string;
	lastname: string;
	onCancelModal: () => void;
	onSubmit: (payload: PayloadType) => Promise<boolean>;
	statusAction: string;
	closeParentModal: () => void;
};

const ChangeStatusModal = ({
	firstname,
	lastname,
	onCancelModal,
	onSubmit,
	statusAction,
	closeParentModal,
}: ChangeStatusModalProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

	let title;
	let body;
	let icon;
	let iconColor;
	let payload: PayloadType;
	switch (statusAction) {
		case 'deactivateUser': {
			title = t`web.team.changeStatusModal.deactivateUser.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.deactivateUser.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			icon = icons.RemoveUserSolid;
			iconColor = theme.color.alertError;
			payload = { status: UserStatus.Deactivated };
			break;
		}
		case 'cancelInvitation': {
			title = t`web.team.changeStatusModal.cancelInvitation.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.cancelInvitation.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			icon = icons.InviteOutlined;
			iconColor = theme.color.iconInvite;
			payload = { status: UserStatus.Deactivated };

			break;
		}
		case 'resendInvitation': {
			title = t`web.team.changeStatusModal.resendInvitation.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.resendInvitation.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			icon = icons.InviteOutlined;
			iconColor = theme.color.iconInvite;

			payload = { status: UserStatus.Invited };

			break;
		}
		default: {
			title = t`web.team.changeStatusModal.activateUser.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusModal.activateUser.areYouSure'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			icon = icons.AddUserSolid;
			iconColor = theme.color.alertSuccess;
			payload = { status: UserStatus.Active };
		}
	}

	const handleOnSubmit = async () => {
		const success = await onSubmit(payload);
		if (!success) {
			onCancelModal();
		}
		setShowSuccessModal(true);
	};

	const onCloseSuccessModal = () => {
		setShowSuccessModal(false);
		onCancelModal();
		closeParentModal();
	};
	return (
		<>
			{showSuccessModal ? (
				<ChangeStatusSuccessModal
					firstname={firstname}
					lastname={lastname}
					onCancelModal={onCloseSuccessModal}
					statusAction={statusAction}
				/>
			) : (
				<LinusModalDialog
					onClose={onCancelModal}
					title={title}
					titleIcon={icon}
					titleIconColor={iconColor}
					declineButtonCallback={onCancelModal}
					declineButtonText={t`web.team.sharedModal.cancelButton`}
					acceptButtonCallback={handleOnSubmit}
					acceptButtonText={title}
					width={'500px'}
				>
					{body()}
				</LinusModalDialog>
			)}
		</>
	);
};

export { ChangeStatusModal };
