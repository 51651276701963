import React from 'react';

import { FeatureFlags } from './constants';
import { useFeatureFlag } from './useFeatureFlag';

interface FeatureFlagGateProps {
	children: React.ReactNode;
	flag: FeatureFlags;
}

export function FeatureFlagGate({ children, flag }: FeatureFlagGateProps) {
	const value = useFeatureFlag(flag);

	if (!value) {
		return null;
	}

	return <>{children}</>;
}
