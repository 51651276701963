import styled, { css } from 'styled-components';

import { Caption, P1, P2 } from 'components/shared/designSystem';

type CommonProps = {
	$index: number;
};

export const Table = styled.table`
	border-collapse: collapse;
	border-spacing: 0px;
	flex-grow: 1;
`;

export const TableRow = styled.tr<CommonProps>(
	({ $index, theme: { colors } }) => css`
		border-bottom: ${$index === 2
			? 'unset'
			: `1px solid ${colors.gray_60}`};
	`
);

export const TableTitle = styled(P2)(
	({ theme: { color } }) => css`
		color: ${color.graphTextSecondary};
		font-weight: 500;
	`
);

export const TableCaption = styled(Caption)<CommonProps>(
	({ $index }) => css`
		font-weight: 600;
		margin-left: ${$index > 0 ? '6px' : 'unset'};
	`
);

export const TableHeaderCell = styled.td<CommonProps>(
	({ $index, theme: { colors } }) => css`
		border-right: ${$index === 2
			? 'unset'
			: $index === 0
			? `1px solid ${colors.gray_40}`
			: `1px solid ${colors.gray_60}`};
		border-bottom: 1px solid ${colors.gray_40};
	`
);

export const TableHeaderRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xs};
		margin-left: ${spacing.lg};
	`
);

export const TableHeaderTitle = styled(P1)`
	font-weight: 600;
`;

export const TableRowCell = styled.td<CommonProps>(
	({ $index, theme: { colors } }) => css`
		border-right: ${$index === 2
			? 'unset'
			: $index === 0
			? `1px solid ${colors.gray_40}`
			: `1px solid ${colors.gray_60}`};
		padding: 10px 12px;
	`
);

export const Tooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._14};
	`
);
