import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { theme } from '../../../components/providers/StyleProvider/theme';
import { Report } from '../CognitiveEvaluation.types';
import { SegmentType } from '../../../generated/graphql';
import i18n from '../../../i18n';

const volumeThreshold = {
	none: {
		min: 0,
		max: 35,
	},
	minor: {
		min: 36,
		max: 60,
	},
	major: {
		min: 61,
		max: 100,
	},
};

function getTextNodes(
	volume: number,
	translation: TFunction<'translation', undefined>
) {
	const nodes: { value: string; bolded?: boolean }[] = [
		{
			value: translation(
				'web.report.hearingScreener.concern.footer.none'
			),
		},
		{
			value: translation(
				'web.report.hearingScreener.concern.footer.minor'
			),
		},
		{
			value: translation(
				'web.report.hearingScreener.concern.footer.major'
			),
		},
	];

	if (inRange(volume, volumeThreshold.none.min, volumeThreshold.none.max)) {
		nodes[0].bolded = true;
	}

	if (inRange(volume, volumeThreshold.minor.min, volumeThreshold.minor.max)) {
		nodes[1].bolded = true;
	}

	if (inRange(volume, volumeThreshold.major.min, volumeThreshold.major.max)) {
		nodes[2].bolded = true;
	}

	const JSXNodes = nodes.map((node) => {
		const { value, bolded } = node;

		if (bolded)
			return (
				<span
					data-testid='ScoringCategory'
					style={{ fontWeight: '500' }}
				>
					{value}
				</span>
			);

		return <>{value}</>;
	});

	return JSXNodes;
}

function inRange(value: number, from: number, to: number): boolean {
	return value >= from && value <= to;
}

interface FooterProps {
	readonly volume: string | number;
}

export function Footer({ volume }: FooterProps) {
	const _volume = Number(volume);
	const { t } = useTranslation();

	return (
		<Text data-testid='HearingScreenerConcernFooter'>
			<BoldTitle>
				{t(`web.report.hearingScreener.concern.footer.details`)}{' '}
			</BoldTitle>
			{getTextNodes(_volume, t).map((node, index, nodes) => {
				const isLast = index === nodes.length - 1;

				if (isLast) return node;

				return (
					<NodeText key={`${node.props.children}`}>{node}</NodeText>
				);
			})}
		</Text>
	);
}

const BoldTitle = styled.span(
	({ theme: { weight, color, fontSize } }) => `
		text-transform: uppercase;
		font-family: IBM Plex Sans;
		color: ${color.bodyTextSecondary};
		font-weight: ${weight.medium};
		font-size: ${fontSize._12};
		line-height: ${fontSize._14};
		letter-spacing: 0.2px;
	`
);

const NodeText = styled.span(
	({ theme: { weight, color, fontSize } }) => `
		font-family: IBM Plex Sans;
		font-size: ${fontSize._12};
		font-weight: ${weight.regular};
		line-height: 15px;
		letter-spacing: 0.4px;
		text-align: left;
		color: ${color.bodyTextSecondary};
	`
);

const Text = styled.span(
	({ theme: { weight, color, fontSize } }) => `
		color: ${color.bodyTextSecondary};
		font-size: ${fontSize._12};
		font-weight: ${weight.regular};
	`
);

const LineSeparator = styled.div<{ heightPX: number }>(
	({ heightPX }) => `
		height: ${heightPX}px;
	`
);

export function concernsMessageHandler(volume: string | number) {
	volume = Number(volume);
	if (
		volume >= volumeThreshold.none.min &&
		volume <= volumeThreshold.none.max
	) {
		return (
			<>
				{i18n.t('web.report.hearingScreener.concern.volume', {
					volumeLevel: volume,
				})}
			</>
		);
	}

	if (
		volume >= volumeThreshold.minor.min &&
		volume <= volumeThreshold.minor.max
	) {
		return (
			<>
				{i18n.t('web.report.hearingScreener.concern.volume', {
					volumeLevel: volume,
				})}
				<LineSeparator heightPX={27} />
				{i18n.t('web.report.hearingScreener.concern.minor.description')}
			</>
		);
	}

	if (
		volume >= volumeThreshold.major.min &&
		volume <= volumeThreshold.major.max
	) {
		return (
			<>
				{i18n.t('web.report.hearingScreener.concern.volume', {
					volumeLevel: volume,
				})}
				<LineSeparator heightPX={27} />
				{i18n.t('web.report.hearingScreener.concern.major.description')}
			</>
		);
	}
}

export function dotColorHandler(volume: string | number) {
	volume = Number(volume);
	if (
		volume >= volumeThreshold.none.min &&
		volume <= volumeThreshold.none.max
	) {
		return theme.colors.green;
	}

	if (
		volume >= volumeThreshold.major.min &&
		volume <= volumeThreshold.major.max
	) {
		return theme.colors.orange;
	}

	if (
		volume >= volumeThreshold.minor.min &&
		volume <= volumeThreshold.minor.max
	) {
		return theme.colors.yellow;
	}
}

export function concernsTitleHandler(volume: string | number) {
	volume = Number(volume);
	if (
		volume >= volumeThreshold.major.min &&
		volume <= volumeThreshold.major.max
	) {
		return i18n.t('web.report.hearingScreener.concern.major.header', {
			volume,
		});
	}

	if (
		volume >= volumeThreshold.minor.min &&
		volume <= volumeThreshold.minor.max
	) {
		return i18n.t('web.report.hearingScreener.concern.minor.header', {
			volume,
		});
	}

	if (
		volume >= volumeThreshold.none.min &&
		volume <= volumeThreshold.none.max
	) {
		return i18n.t('web.report.hearingScreener.concern.no.header', {
			volume,
		});
	}
}

interface MetricElement {
	value: string | number;
	version: string;
}

interface MetricsObject {
	messageClass: MetricElement;
	color: MetricElement;
	cutoffs: MetricElement;
	duration?: MetricElement;
	finalVolume?: MetricElement;
	score: MetricElement;
	endTime?: MetricElement;
}

interface KeyMapping {
	hearing_screener_class: keyof MetricsObject;
	hearing_screener_color: keyof MetricsObject;
	hearing_screener_cutoffs: keyof MetricsObject;
	hearing_screener_duration: keyof MetricsObject;
	hearing_screener_final_volume: keyof MetricsObject;
	hearing_screener_score: keyof MetricsObject;
}

const keyMapping: KeyMapping = {
	hearing_screener_class: 'messageClass',
	hearing_screener_color: 'color',
	hearing_screener_cutoffs: 'cutoffs',
	hearing_screener_duration: 'duration',
	hearing_screener_final_volume: 'finalVolume',
	hearing_screener_score: 'score',
};

interface GetMetricItemsProps {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
}

function metricItemsBuilder(
	segmentResult: DeepSegmentResult,
	batteryResult?: Report
) {
	const result: MetricsObject = {} as MetricsObject;

	segmentResult.metricItems.forEach((metricItemElement) => {
		const key = keyMapping[metricItemElement.key as keyof KeyMapping];
		if (key) {
			result[key] = {
				value: metricItemElement.value,
				version: metricItemElement.algorithmVersion,
			};
		}
	});
	result.endTime = {
		value: batteryResult?.batteryResultById?.endTime
			? batteryResult.batteryResultById.endTime
			: segmentResult.endTime
			? segmentResult.endTime
			: '',
		version: '',
	};

	return result;
}

export function getMetricItems({
	batteryResult,
	segmentResult,
}: GetMetricItemsProps): MetricsObject | null {
	let result: MetricsObject = {} as MetricsObject;

	if (batteryResult) {
		batteryResult.batteryResultById?.assessmentResults?.forEach(
			(element) => {
				const segmentResult = element.segmentResults.find((element) => {
					return (
						element.segment.segmentType ===
						SegmentType.HearingScreener
					);
				});
				if (segmentResult) {
					result = metricItemsBuilder(
						segmentResult,

						batteryResult
					);
					return true;
				}
				return false;
			}
		);
	}

	if (segmentResult) {
		result = metricItemsBuilder(segmentResult);
	}

	return result;
}
