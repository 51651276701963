import styled from 'styled-components';
import { H2 } from '../shared/designSystem/TextComponents';
import { useTheme } from 'styled-components';
import { icons } from '../../enums/icons';
import { Icon } from '../shared/designSystem/Icon';
import { Trans, useTranslation } from 'react-i18next';

const AddBatteryForPatientHeader = ({
	patient,
}: {
	patient: any;
}): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<StyledHeader>
			<StyledTitle>
				<StyledTitleIcon>
					<Icon
						dataId='add-battery-header-title-icon'
						icon={icons.AddSolid}
						iconWidth={40}
						iconHeight={40}
						title={t`web.patients.forms.addBattery`}
						color={theme.color.iconAddBattery}
					/>
				</StyledTitleIcon>
				<StyledH2 data-id='add-battery-header-title-text'>{t`web.patients.forms.addBattery`}</StyledH2>
			</StyledTitle>
			<StyledSubtitle data-id='add-battery-header-subtitle'>
				<Trans
					i18nKey='web.patients.forms.assignAssessmentLabel'
					values={{
						name: ` ${patient?.firstName} ${patient?.lastName} `,
					}}
					components={[<strong key='strong_element_name' />]}
				/>
			</StyledSubtitle>
		</StyledHeader>
	);
};

export { AddBatteryForPatientHeader };

const StyledSubtitle = styled.div(
	({ theme: { spacing } }) => `
	margin-top: ${spacing.sm};
`
);

const StyledHeader = styled.div`
	width: 548px;
`;

const StyledTitle = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const StyledTitleIcon = styled.div(
	({ theme: { spacing } }) => `
padding-right:${spacing.md}
`
);
const StyledH2 = styled(H2)(
	({ theme: { weight } }) => `
	font-weight: ${weight.medium};
`
);
