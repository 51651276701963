import { UnstyledButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';

import { Caption } from './designSystem';

type ViewDetailsButtonProps = {
	onClick?: () => void;
};

export function ViewDetailsButton({
	onClick,
}: Readonly<ViewDetailsButtonProps>) {
	const { t } = useTranslation();

	return (
		<UnstyledButton ff={theme.baseFont} onClick={onClick}>
			<Caption
				style={{
					cursor: 'pointer',
					color: theme.colors.blue,
					fontWeight: theme.weight.medium,
				}}
			>
				{t('web.shared.viewDetails').toUpperCase()}
			</Caption>
		</UnstyledButton>
	);
}
