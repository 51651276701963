import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { LoadingDots } from 'components/shared/LoadingDots';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { icons } from '../../enums/icons';
import { OrganizationType } from '../../generated/graphql';
import { orgSpecificStringStrategy } from '../../stringStrategy/orgSpecificStringStrategy';
import { Footer } from '../shared/Footer';
import { Icon } from '../shared/designSystem/Icon';
import { useStoreReset } from 'store';

import { PublicRoutes } from './PublicRoutes';

interface Props {
	finishingSignup?: boolean;
}

const AuthenticationLayout = ({
	finishingSignup = false,
}: Props): JSX.Element => {
	useStoreReset();

	const { pathname } = useLocation();
	const isAuth0Universal = useFeatureFlag(FeatureFlags.Auth0Universal);
	const { t } = useTranslation();
	const isFinishSignup =
		pathname === '/auth/finish-signup' || finishingSignup;
	const imgSrc = GetImgSrc(isFinishSignup);

	const loginContainer =
		isAuth0Universal && !isFinishSignup ? (
			<StyledLayoutContainer>
				<StyledLoginContainer data-id='login-container'>
					<LoadingDots />
				</StyledLoginContainer>
			</StyledLayoutContainer>
		) : (
			<StyledLayoutContainer>
				<StyledLoginContainer data-id='login-container'>
					<StyledLoginWrapper isFinishSignup={isFinishSignup}>
						<StyledBackgroundImage
							src={imgSrc}
							alt={t`web.shared.images.ptWithProvider`}
							draggable='false'
						/>
						<StyledFormContainer isFinishSignup={isFinishSignup}>
							<PublicRoutes />
							<StyledLogoWrapper isFinishSignup={isFinishSignup}>
								<StyledLogo>
									<Icon
										icon={icons.Logo}
										title={t`web.shared.logo`}
									/>
								</StyledLogo>
							</StyledLogoWrapper>
						</StyledFormContainer>
					</StyledLoginWrapper>
				</StyledLoginContainer>
				<Footer />
			</StyledLayoutContainer>
		);

	return loginContainer;
};

/* TODO: Eventually we're gonna need to find another way to change the images for non-finishSignup pages */
export const GetImgSrc = (isFinishSignup: boolean) => {
	const params = new URLSearchParams(useLocation().search);
	const imgDefault = orgSpecificStringStrategy(
		'LoginImg',
		undefined,
		OrganizationType.Clinical.valueOf()
	);

	try {
		const json = Buffer.from(
			params.get('payload') as string,
			'hex'
		).toString();
		const payload = JSON.parse(json);

		if (!!payload?.orgType && isFinishSignup) {
			return orgSpecificStringStrategy(
				'LoginImg',
				undefined,
				payload.orgType
			);
		} else {
			return imgDefault;
		}
	} catch (err) {
		// TODO: Winston this
		console.debug(`Error parsing JSON: ${err}`);
		return imgDefault;
	}
};

export { AuthenticationLayout };

const StyledLayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledLoginContainer = styled.div`
	padding: 16px;
	text-align: center;
	width: 100%;
	height: calc(100vh - 64px);
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

type FinishSignupProps = {
	isFinishSignup: boolean;
};
const StyledLoginWrapper = styled.div<FinishSignupProps>(
	({ isFinishSignup }) => css`
		width: ${isFinishSignup ? '1517px' : '1103px'};
		height: 665px;
		display: flex;
		position: relative;
	`
);

const StyledBackgroundImage = styled.img`
	width: 666px;
	height: 666px;
	border-radius: 40px 0 40px 40px;
`;

const StyledFormContainer = styled.div<FinishSignupProps>(
	({ isFinishSignup, theme: { color, boxShadow } }) => css`
		align-items: flex-start;
		justify-content: flex-start;
		width: ${isFinishSignup ? '992px' : '600px'};
		height: ${isFinishSignup ? '760px' : '608px'};
		background: ${color.white};
		transition: 0.2s ease all;
		border-radius: 40px 0 40px 40px;
		position: absolute;
		right: 0;
		top: ${isFinishSignup ? '-44px' : '29px'};
		overflow: hidden;
		box-sizing: border-box;
		box-shadow: ${boxShadow.standard};

		@media ${MediaQueries.maxWidth.lg} {
			max-width: ${isFinishSignup ? '700px' : 'unset'};
		}
	`
);

const StyledLogoWrapper = styled.div<FinishSignupProps>(
	({ isFinishSignup }) => css`
		position: absolute;
		bottom: 0;
		left: ${isFinishSignup
			? 'calc((992px - 168px) / 2)'
			: 'calc((578px - 168px) / 2)'};

		@media ${MediaQueries.maxWidth.lg} {
			left: ${isFinishSignup
				? 'calc((700px - 168px) / 2)'
				: 'calc((578px - 168px) / 2)'};
		}
	`
);

const StyledLogo = styled.div`
	position: absolute;
	bottom: 56px;
`;
