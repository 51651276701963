import { ClinicalResultTableSearchInput } from '../../generated/graphql';
import { PAGE_SIZE } from '../shared/DataTable/schemas/resultTableSchema';
import { SearchProps, SortProps } from './Results';

type GetRequestParamsProps = {
	page?: number;
	sort?: SortProps | null;
	search?: SearchProps;
};

export const getRequestParams = ({
	page,
	sort,
	search,
}: GetRequestParamsProps): Partial<ClinicalResultTableSearchInput> => {
	const params: {
		[k: string]: string | number | Record<string, unknown>;
	} = {};
	if (page) {
		params['limit'] = PAGE_SIZE;
		params['offset'] = (page - 1) * PAGE_SIZE;
	}
	if (sort?.prop) {
		params['sort'] = sort;
	}
	if (search?.value) {
		params['search'] = search;
	}
	return params;
};
