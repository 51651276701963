import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { Caption, H4, P1 } from 'components/shared/designSystem';

export const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
	`
);

export const Content = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const Title = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Info = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
		align-items: center;
		flex-wrap: wrap;
	`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);

export const AskedQuestion = styled(P1)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const MediumSpan = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);
