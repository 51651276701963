import styled, { css, useTheme } from 'styled-components';
import { Trans } from 'react-i18next';

import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

type ViewFollowUpButtonProps = {
	onClick: () => void;
};

export function ViewFollowUpButton({
	onClick,
}: Readonly<ViewFollowUpButtonProps>) {
	const theme = useTheme();

	return (
		<ViewFollowUpContainer onClick={onClick}>
			<Icon icon={icons.Pathway} />
			<TextWrapper>
				<Trans
					i18nKey={'web.dcrReport.overview.viewRecommendedFollowUp'}
				/>
			</TextWrapper>
			<Icon
				iconHeight={24}
				iconWidth={24}
				color={theme.colors.blue}
				icon={icons.TraditionalArrowRight}
			/>
		</ViewFollowUpContainer>
	);
}

const ViewFollowUpContainer = styled.button(
	({ theme: { color, spacing } }) => css`
		background-color: ${color.infoTipCardBackgroundColor};
		border-width: 0px;
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		padding: ${spacing.sm};
		cursor: pointer;
	`
);

const TextWrapper = styled.div(
	({ theme: { color } }) => css`
		color: ${color.label};
		font-weight: 500;
		margin-left: 4px;
		margin-right: 4px;
	`
);
