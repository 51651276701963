import styled, { css } from 'styled-components';

import { Caption, H4 } from 'components/shared/designSystem';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		margin-bottom: ${spacing.sm};
		gap: ${spacing.lg};
	`
);

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const Header = styled(H4)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		margin-left: ${spacing.lg};
	`
);

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
