export const TEXT = {
	title: 'cds.dcrSummaryWidget.titleWithTrademark',
	tooltip: 'cds.dcrSummaryWidget.tooltip',
	viewReport: 'cds.dcrSummaryWidget.viewReport',
	mostRecent: 'cds.dcrSummaryWidget.mostRecent',
	breakdown: {
		unanalyzable:
			'web.dcrReport.overview.scoringDescription.requiresClinicianReview.text',
		empty: 'cds.dcrSummaryWidget.breakdown.empty',
		_4_5: 'web.dcrReport.overview.scoringDescription.cognitivelyUnimpaired.text_bolded',
		_2_3: 'web.dcrReport.overview.scoringDescription.likelyCognitivelyUnimpaired.text_bolded',
		_0_1: 'web.dcrReport.overview.scoringDescription.cognitivelyImpaired.text_bolded',
		total: 'web.dcrReport.overview.scoringDescription.total',
		pointsFrom: {
			clockDrawing:
				'web.dcrReport.overview.scoringDescription.dctClock.points',
			delayedRecall:
				'web.dcrReport.overview.scoringDescription.delayedRecall.points',
		},
	},
};
