import { OrganizationType } from '@lh/eng-platform-organization-service-rest-client';

import { CurrentUser } from '../../../types';

export const loginLandingPage = (currentUser: CurrentUser): string => {
	const administratorRoleIds = [
		'1acc5377-259e-4786-82ca-bc5db70b0bad', // Clinical Administrator
		'1f1a9c06-a3a6-4d50-9a01-19208e184658', // Linus Administrator
		'44a2edae-48c3-40b6-89a3-43995b45170e', // Linus Limited Administator
		'2a4af633-39a8-4ed3-b63d-f80ba47bb5c6', // Linus Integrations Administator
		'f8a7710a-9c43-41cd-bc18-9ee3293d13f3', // Linus Read Only
		'a2fb4171-76ce-4ed6-b024-39262f911c8c', // Linus IT SEC
	];
	switch (currentUser?.organizationType) {
		case OrganizationType.Clinical: {
			if (administratorRoleIds.includes(currentUser?.roleId || '')) {
				return '/organization';
			} else {
				return '/patients';
			}
		}
		default: {
			return '/organization';
		}
	}
};
