import { getBrowserLanguage } from './components/shared/getLanguage';
import { locale, EnumItem } from './enums/locale';
import { getValidValue, coerceToBoolean } from './typeGuard';

const nodeEnvs = ['development', 'ci', 'qa', 'production'];
type NodeEnv = (typeof nodeEnvs)[number];

export type ExternalConfig = {
	host: string;
	cookieDomain: string;
	apiHost: string;
	apiPort: string;
	apiDomain: string;
	apiProtocol: string;
	amplifyRegion: string;
	amplifyUserPoolId: string;
	amplifyUserPoolWebClientId: string;
	amplitudeApiKey: string;
	fallbackToDefaultLng: boolean;
	clinicalDomainUrl: string;
	researchDomainUrl: string;
	sentryDSN: string;
	preferredUserProvider: string;
	auth0Domain: string;
	auth0ClientId: string;
	splitIoAuthKey: string;
};

type Config = {
	nodeEnv: NodeEnv;
	// should be specific options once we know what they will be
	logging_level: string;
	// should be specific options once we know what they will be
	logging_transports: string;
	host: string;
	cookieDomain: string;
	port: number;
	locale: EnumItem;
	apiHost: string;
	apiPort: number;
	apiDomain: string;
	apiProtocol: string;
	amplifyRegion: string;
	amplifyUserPoolId: string;
	amplifyUserPoolWebClientId: string;
	amplitudeApiKey: string;
	applicationEnv: string;
	fallbackToDefaultLng: boolean;
	clinicalDomainUrl: string;
	researchDomainUrl: string;
	managementDomainUrl: string;
	sentryDSN: string;
	splitIoAuthKey: string;
	preferredUserProvider: string;
	auth0Domain: string;
	auth0ClientId: string;
	awsRegion: string;
};
let fallbackToDefaultLngFromEnv = coerceToBoolean(
	process.env.I18N_FALLBACK_TO_DEFAULT_LNG
);
if (
	process.env.I18N_FALLBACK_TO_DEFAULT_LNG === undefined ||
	process.env.I18N_FALLBACK_TO_DEFAULT_LNG === null
) {
	fallbackToDefaultLngFromEnv = true;
}

let _config: Config = {
	nodeEnv: getValidValue<NodeEnv>(
		process.env.NODE_ENV || 'development',
		nodeEnvs
	),
	logging_level: process.env.LOGGING_LEVEL || 'INFO',
	// list of logging outputs e.g. console, logly, someother transport
	logging_transports: process.env.LOGGING_TRANSPORTS || 'console',
	host: process.env.HOST || '0.0.0.0',
	cookieDomain: process.env.COOKIE_DOMAIN || '',
	port: isNaN(parseInt(process.env.PORT || '', 10))
		? 3000
		: parseInt(process.env.PORT || '', 10),
	locale: locale.fromValue(getBrowserLanguage()) || locale.EnUs,
	apiHost: process.env.API_HOST || '0.0.0.0',
	apiPort: isNaN(parseInt(process.env.API_PORT || '', 10))
		? 3000
		: parseInt(process.env.API_PORT || '', 10),
	apiDomain: process.env.API_DOMAIN || 'graphql',
	apiProtocol: process.env.API_PROTOCOL || 'http',
	amplifyRegion: process.env.REACT_APP_AWS_REGION || '',
	amplifyUserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
	amplifyUserPoolWebClientId:
		process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
	amplitudeApiKey: process.env.REACT_APP_AMPLITUDE || '',

	applicationEnv: process.env.APPLICATION_ENV || 'development',
	fallbackToDefaultLng: fallbackToDefaultLngFromEnv,
	clinicalDomainUrl: process.env.REACT_APP_CLINICAL_DOMAIN_URL || '',
	researchDomainUrl: process.env.REACT_APP_RESEARCH_DOMAIN_URL || '',
	managementDomainUrl: process.env.REACT_APP_MANAGEMENT_DOMAIN_URL || '',
	sentryDSN: process.env.REACT_APP_SENTRY_DSN || '',
	splitIoAuthKey: process.env.SPLIT_IO_AUTHORIZATION_KEY || '',
	preferredUserProvider: process.env.PREFERRED_USER_PROVIDER || 'cognito',
	auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
	auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
	awsRegion: process.env.AMPLIFY_REGION || '',
};

export const getConfig = (): Config => {
	const hasExternalConfig =
		!!window.externalConfig && window.externalConfig?.apiHost;
	if (hasExternalConfig) {
		const externalConfig = window.externalConfig;
		let fallbackToDefaultLng = coerceToBoolean(
			externalConfig.fallbackToDefaultLng
		);
		if (
			externalConfig.fallbackToDefaultLng === undefined ||
			externalConfig.fallbackToDefaultLng === null
		) {
			fallbackToDefaultLng = true;
		}
		_config = {
			..._config,
			host: externalConfig.host,
			cookieDomain: externalConfig.cookieDomain,
			apiHost: externalConfig.apiHost,
			apiPort: parseInt(externalConfig.apiPort),
			apiDomain: externalConfig.apiDomain,
			apiProtocol: externalConfig.apiProtocol,
			amplifyRegion: externalConfig.amplifyRegion,
			amplifyUserPoolId: externalConfig.amplifyUserPoolId,
			amplifyUserPoolWebClientId:
				externalConfig.amplifyUserPoolWebClientId,
			amplitudeApiKey: externalConfig.amplitudeApiKey,
			splitIoAuthKey: externalConfig.splitIoAuthKey,
			auth0ClientId: externalConfig.auth0ClientId,
			auth0Domain: externalConfig.auth0Domain,
			fallbackToDefaultLng: fallbackToDefaultLng,
			applicationEnv: externalConfig.applicationEnv,
			clinicalDomainUrl: externalConfig.clinicalDomainUrl,
			researchDomainUrl: externalConfig.researchDomainUrl,
			managementDomainUrl: externalConfig.managementDomainUrl,
			sentryDSN: externalConfig.sentryDSN,
			preferredUserProvider:
				externalConfig.preferredUserProvider || 'cognito',
			awsRegion: externalConfig.amplifyRegion,
		};
	}
	return _config;
};

export const config: Config = getConfig();
