import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { Divider } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styled-components';

import { theme } from 'components/providers/StyleProvider/theme';
import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';

import { EndOfQuestionnaire, IadlTableContainer } from './IadlTable.style';
import { IADL_TABLE_COLUMNS } from './IadlTable.utils';
import { TableHeader } from './IadlTableHeader';

type IadlTableCardProps = {
	tableData: QnrRowData[];
	type?: 'iadl' | 'adl';
};

export function IadlTableCard({
	tableData,
	type = 'iadl',
}: Readonly<IadlTableCardProps>) {
	const { t } = useTranslation();

	return (
		<IadlTableContainer>
			<TableHeader type={type} />
			<StyledTable
				verticalSpacing={theme.spacing.md}
				horizontalSpacing={theme.spacing.sm}
				columns={IADL_TABLE_COLUMNS}
				striped='even'
				data={tableData}
			/>
			<Divider color={theme.colors.gray_60} />
			<EndOfQuestionnaire>
				{t(`web.report.${type}.endOfQuestionnaire`)}
			</EndOfQuestionnaire>
		</IadlTableContainer>
	);
}

const StyledTable = styled(SimpleTable<QnrRowData>)(
	({ theme }) => css`
		tbody > tr:last-child {
			background-color: white;
			border-top: 1px solid ${theme.colors.gray_60};
		}
	`
);
