import { User } from '@lh/eng-platform-organization-service-rest-client';

import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getInterpretingProviderUsers(
	organizationId: string,
	client: QueryClient
) {
	if (!organizationId) {
		throw new Error(
			"useInterpretingProviders: organizationId can't be null"
		);
	}

	const service = await getOrganizationService();
	const { data } = await service.getInterprettingProviderUsers({
		organizationId,
	});

	data?.results.forEach((user) => {
		client.setQueryData<User>(
			[QueryKey.User, organizationId, user.id],
			user
		);
	});

	return data;
}

export function useInterpretingProvidersQuery(organizationId = '') {
	const client = useQueryClient();

	return useQuery({
		enabled: !!organizationId,
		meta: {
			errorMessage: `Error fetching interpreting users with organizationId: ${organizationId}`,
		},
		queryKey: [QueryKey.UserInterpreting, organizationId],
		queryFn: () => getInterpretingProviderUsers(organizationId, client),
		staleTime: Infinity,
	});
}
