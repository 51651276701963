import {
	BaseStateHandlers,
	CloseOpenDisplayOptionsProps,
	HandleBlurComponentProps,
	HandleKeyPress,
	OnChangeProps,
	OnHandleClickProps,
} from './interface';

export const defaultSearchValue = ' ';

/**
 *  Handle Onchage event
 * @param e
 * @returns
 */
export const onChange = <T>({
	event,
	setFilterString,
	setSearch,
	setPage,
	isOpen,
	setIsOpen,
}: OnChangeProps<T>) => {
	const filterValue = event.target.value;
	setFilterString(filterValue);
	if (filterValue === '') {
		//If no search is specified, we default to defaultSearchValue
		//in order to have 'on click' display of reasons
		setSearch(defaultSearchValue);
	} else {
		setSearch(filterValue);
	}

	setPage(0);
	if (!isOpen) {
		setIsOpen(true);
	}
};

/**
 * @description Handles the click of a found item
 */
export const onHandleClick = ({
	disabled,
	setIsOpen,
	setSearch,
	setPage,
	filterValue,
	isOpen,
}: OnHandleClickProps) => {
	if (disabled) {
		return;
	}
	//If no search is specified, we default to defaultSearchValue
	//in order to have 'on click' display of reasons
	if (filterValue === '') {
		setSearch(defaultSearchValue);
	} else {
		setSearch(filterValue);
	}

	setPage(0);
	if (!isOpen) {
		setIsOpen(true);
	}
};

export const handleOpenListener = (
	document: Document,
	isOpen: boolean,
	handleKeyPress: (e: KeyboardEvent) => void
) => {
	// eslint-disable-next-line no-use-before-define
	if (isOpen) {
		document.addEventListener('keyup', handleKeyPress, false);
	}
	return () => {
		// eslint-disable-next-line no-use-before-define
		document.removeEventListener('keyup', handleKeyPress, false);
	};
};

/**
 * @description Resets the current state of the main component to initial values
 * @param param0 The handlers used to reset the state
 */
export const resetState = <T>({
	setValue,
	setFilteredOptions,
	setIsOpen,
	setSelectedIndex,
	setFilterString,
	setSearch,
}: BaseStateHandlers<T, string>) => {
	setValue('');
	setFilteredOptions([]);
	setIsOpen(false);
	setSelectedIndex(-1);
	setFilterString('');
	setSearch('');
};

/**
 * Close the opened Display Options
 * @param selected
 */
export const closeOpenDisplayOptions = <T>({
	selected,
	setValue,
	setFilteredOptions,
	setIsOpen,
	setSelectedIndex,
	setFilterString,
	selectedOptions,
	setSelectedOptions,
	setSearch,
}: CloseOpenDisplayOptionsProps<T, string>) => {
	/**
	 * We reset the state
	 */
	resetState({
		setValue,
		setFilteredOptions,
		setIsOpen,
		setSelectedIndex,
		setFilterString,
		setSearch,
	});

	/**
	 * If it has been selected, we check if it's
	 * already on the selected options list
	 */
	if (selected) {
		const alreadyAdded = selectedOptions.find(
			(element) =>
				element.dropdownOption.value === selected.dropdownOption.value
		);
		if (alreadyAdded) {
			/**
			 * If already added, we remove it form the selected options
			 */
			const newOptions = selectedOptions.filter((element) => {
				return (
					element.dropdownOption.value !==
					selected.dropdownOption.value
				);
			});
			setSelectedOptions(newOptions);
		} else {
			/**
			 * If not already added, we add it anew
			 */
			const newOptions = selectedOptions;
			newOptions.push(selected);
			setSelectedOptions(newOptions);
		}
	}
};

/**
 * @description Handles the blur of the component
 * @param param0 The required data for handling
 */
export const handleBlurComponentEvent = <T>({
	event,
	isOpen,
	name,
	applyCloseOpenDisplayOptions,
}: HandleBlurComponentProps<T>) => {
	const clickTarget = (event.target as HTMLElement).nodeName;
	const insideLI =
		!!(event.target as HTMLElement).closest('li') ||
		!!(event.target as HTMLElement).closest('ul');
	const clickElementName = (event.target as HTMLElement).getAttribute('name');
	const isNotInListElement =
		clickTarget !== 'LI' && clickTarget !== 'UL' && !insideLI;
	const isOnBlurClick =
		isOpen &&
		isNotInListElement &&
		clickElementName !== name &&
		clickElementName !== 'innerInput';
	if (isOnBlurClick) {
		applyCloseOpenDisplayOptions();
	}
};

export const handleKeyPress = <T>({
	event,
	filteredOptions,
	isOpen,
	selectedIndex,
	handleSelect,
	applyCloseOpenDisplayOptions,
	setSelectedIndex,
}: HandleKeyPress<T>) => {
	event.stopImmediatePropagation();
	switch (event.key) {
		case 'Enter': {
			if (isOpen && selectedIndex > -1) {
				handleSelect(filteredOptions[selectedIndex]);
			}
			break;
		}
		case 'Escape':
			applyCloseOpenDisplayOptions();
			break;
		case 'ArrowLeft':
		case 'ArrowUp':
			setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : 0);
			break;
		case 'ArrowRight':
		case 'ArrowDown':
			setSelectedIndex(
				selectedIndex < filteredOptions.length - 1
					? selectedIndex + 1
					: filteredOptions.length - 1
			);
			break;
		case 'Backspace':
			if (filteredOptions.length > 1) {
				setSelectedIndex(0);
			}
			break;
		default:
			break;
	}
};
