import {
	Gender,
	Handedness,
	Patient,
	SexAssignedAtBirth,
} from '@lh/eng-platform-subject-service-rest-client';
import { t } from 'i18n';
import * as yup from 'yup';

import { Enumeration, VLEnum } from '../../../../enums/enumeration';
import { maxCharacterLimits } from '../../../../utils/stringUtils';
import { PHONE_NUMBER_REGEX } from '../Components/PhoneNumber';

export type EditParticipantModel = Partial<Patient> & {
	id: string;
	avatarUrl?: string;
	firstName: string;
	lastName: string;
	birthDate: string;
	gender: Gender | null;
	ethnicityIds?: string;
	raceIds?: string;
	educationIds: string;
	handedness: Handedness;
	language?: string;
	externalId?: string;
	contactEmail?: string;
	contactPhone?: string;
	sexAssignedAtBirth: SexAssignedAtBirth;
};

export const getModel = (
	organizationParticipantLanguages: Enumeration<VLEnum>,
	participant?: Patient
) => {
	let sex = participant?.sexAssignedAtBirth;
	// unfortunately this is the default state in the db and we cannot change this
	if (
		participant?.sexAssignedAtBirth ===
		SexAssignedAtBirth.ToBeCollectedAtTimeOfTesting
	)
		sex = undefined;

	return {
		...participant,
		id: participant?.id,
		firstName: participant?.firstName,
		lastName: participant?.lastName,
		birthDate: participant?.birthDate,
		gender: participant?.gender || '',
		ethnicityIds: participant?.ethnicity?.map((v) => v.id).join(', '),
		raceIds: participant?.race?.map((race) => race.id).join(', '),
		educationIds: participant?.education
			?.map((education) => education.id)
			.join(', '),
		handedness: participant?.handedness,
		language: participant?.language
			? organizationParticipantLanguages.fromValue(
					participant?.language
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  )?.value
			: '',
		externalId: participant?.externalId || '',
		sexAssignedAtBirth: sex || '',
		contactEmail: participant?.contactEmail || '',
		contactPhone: participant?.contactPhone
			? participant?.contactPhone.replace(
					/(\d{3})(\d{3})(\d{4})/,
					'$1-$2-$3'
			  )
			: '',
	} as EditParticipantModel;
};

export const editParticipantSchema = yup.object().shape({
	firstName: yup
		.string()
		.max(
			maxCharacterLimits.firstname,
			t`web.shared.forms.schemas.maxCharLimit`
		)
		.required(t`web.shared.forms.schemas.required`),
	lastName: yup
		.string()
		.max(
			maxCharacterLimits.lastname,
			t`web.shared.forms.schemas.maxCharLimit`
		)
		.required(t`web.shared.forms.schemas.required`),
	birthDate: yup
		.date()
		.min(new Date('1/1/1900'), t`web.shared.forms.schemas.birthdateMin`)
		.max(new Date(), t`web.shared.forms.schemas.birthdateMax`)
		.required(t`web.shared.forms.schemas.required`),
	language: yup.string().required(t`web.shared.forms.schemas.required`),
	educationIds: yup
		.string()
		.required(t`web.participant.forms.errors.education`),
	handedness: yup
		.string()
		.required(t`web.participant.forms.errors.dominantHand`),
	sexAssignedAtBirth: yup
		.string()
		.required(t`web.participant.forms.errors.sex`),
	gender: yup.string().optional().nullable(),
	raceId: yup.string().optional().nullable(),
	ethnicityId: yup.string().optional().nullable(),
	externalId: yup
		.string()
		.optional()
		.nullable()
		.max(
			maxCharacterLimits.externalId,
			t`web.shared.forms.schemas.maxExternalId`
		),
	notes: yup
		.string()
		.max(
			maxCharacterLimits.notes,
			t`web.shared.forms.schemas.maxCharLimit` +
				` (${maxCharacterLimits.notes.toLocaleString()})`
		)
		.optional()
		.nullable(),
	contactPhone: yup
		.string()
		.optional()
		.nullable()
		.matches(
			PHONE_NUMBER_REGEX,
			t`web.shared.forms.schemas.enterValidPhone`
		),
	contactEmail: yup
		.string()
		.optional()
		.nullable()
		.email(t`web.shared.forms.schemas.enterValidEmail`),
});
