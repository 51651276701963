import { RedirectLoginResult } from '@auth0/auth0-spa-js';
import {
	Organization,
	OrganizationType,
} from '@lh/eng-platform-organization-service-rest-client';
import { auth0Login } from '@lh/eng-shared-auth';
import { useQueryClient } from '@tanstack/react-query';
import { getOrganization } from 'api/organization';
import { QueryKey } from 'api/query';
import { useCurrentUser } from 'api/user/useCurrentUser';
import {
	determineRedirect,
	getValidClinicalRole,
} from 'components/auth/login/userSessionHelper';
import { config } from 'config';
import { SessionStorageEnum } from 'enums/sessionStorageKeysEnum';
import { Auth } from 'features/auth-service';
import { ERROR } from 'logging/linusLogger';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreReset } from 'store';
import { clearSessionStorage, getSessionStorage } from 'utils/sessionStorage';
import { useOrganizationStore } from '../store/useOrganizationStore';

export function useAuthSetup() {
	const navigate = useNavigate();
	const client = useQueryClient();

	const [logged, setLogged] = useState(false);
	const [organization, setOrganization] = useState<Organization>();

	const accessDenied = window.location.pathname.includes('access-denied');
	const isDeeplinking = getSessionStorage(SessionStorageEnum.ImpersonatedOrg);

	const { data: currentUser } = useCurrentUser();

	const setOrganizationId = useOrganizationStore((state) => state.setId);
	const setOrganizationName = useOrganizationStore((state) => state.setName);
	const setOrganizationType = useOrganizationStore((state) => state.setType);
	const setOrganizationRole = useOrganizationStore((state) => state.setRole);
	const setOrganizationStatus = useOrganizationStore(
		(state) => state.setStatus
	);

	const resetAllStores = useStoreReset();

	const fetchOrganization = useCallback(async (organizationId: string) => {
		const org = await client.fetchQuery({
			queryKey: [QueryKey.Organization, organizationId],
			queryFn: ({ signal }) => getOrganization(organizationId, signal),
			staleTime: Infinity,
		});
		setOrganization(org);
	}, []);

	useEffect(() => {
		if (!accessDenied) {
			auth0Login(
				Auth,
				config.clinicalDomainUrl,
				`${window.location.pathname}${window.location.search}`
			)
				.then((response: void | RedirectLoginResult | undefined) => {
					if (response?.appState?.target) {
						navigate(response?.appState?.target, {
							replace: true,
						});
					}
				})
				.catch((err) => {
					ERROR('error with auth0 login', err);
				});
		}
	}, [accessDenied]);

	useEffect(() => {
		async function logout(redirectUrl: string) {
			resetAllStores();
			clearSessionStorage(SessionStorageEnum.ImpersonatedOrg);
			await Auth.logout(`${config.clinicalDomainUrl}/${redirectUrl}`);
		}

		if (!currentUser || accessDenied) return;
		// Manages remote sessions
		if (isDeeplinking?.organizationId) {
			const orgId: string =
				(isDeeplinking?.organizationId as string) ?? '';

			// Linus Role logout check
			const roleOfValidOrg =
				currentUser.roles.find(
					(r) => r.organizationType === OrganizationType.Linus
				) ?? {};

			fetchOrganization(orgId);

			if (organization) {
				setOrganizationId(organization.id);
				setOrganizationName(organization.name);
				setOrganizationType(organization.type);
				setOrganizationStatus(organization.status);
				setOrganizationRole(roleOfValidOrg);
				setLogged(true);
				return;
			}
		} else {
			const validClinicalRole = getValidClinicalRole(currentUser);

			if (!validClinicalRole) {
				const accessDeniedPage = determineRedirect(currentUser);

				logout(accessDeniedPage);
				return;
			}

			if (validClinicalRole.organizationId) {
				fetchOrganization(validClinicalRole.organizationId);
			}

			if (!organization || !validClinicalRole.organizationRole) {
				ERROR('No org details, unable to set store');
				return;
			}

			if (organization.type === OrganizationType.Clinical) {
				setOrganizationId(organization.id);
				setOrganizationName(organization.name);
				setOrganizationType(organization.type);
				setOrganizationRole(validClinicalRole.organizationRole);
				setOrganizationStatus(organization.status);
				setLogged(true);
			} else {
				ERROR('Org type is not clinical, unable to set store.');
				return;
			}
		}
	}, [currentUser?.id, organization, fetchOrganization, accessDenied]);

	return { logged };
}
