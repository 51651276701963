import { usePagination } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { icons } from '../../../../enums/icons';
import { PaginatedPagerInfo } from '../../../../types';
import { Icon } from '../../designSystem/Icon';

type PaginatedPagerProps = {
	pagerInfo: PaginatedPagerInfo;
};

export const PaginatedPager = ({
	pagerInfo,
}: PaginatedPagerProps): JSX.Element | null => {
	const { totalRows, currentPage, setCurrentPage, rowsPerPage } = pagerInfo;

	const { t } = useTranslation();

	const totalPages = Math.ceil(totalRows / rowsPerPage);

	const pages = usePagination({
		total: totalPages,
		page: currentPage,
		siblings: 0,
		onChange: setCurrentPage,
	});

	const canNavigate = {
		prev: currentPage > 1,
		next: currentPage < totalPages,
	};

	const navigate = {
		prev() {
			if (canNavigate.prev) {
				setCurrentPage(currentPage - 1);
			}
		},
		next() {
			if (canNavigate.next) {
				setCurrentPage(currentPage + 1);
			}
		},
		page(index: number) {
			if (index !== -1) {
				setCurrentPage(index);
			}
		},
	};

	if (!(totalPages > 1)) return null;

	return (
		<StyledContainer data-testid='paginated_table_pager'>
			<StyledIcon
				data-testid='linus_table_page_left_arrow'
				onClick={navigate.prev}
				disabled={!canNavigate.prev}
			>
				<Icon
					icon={icons.ArrowLeft}
					title={t`web.dataTable.pager.leftArrow`}
					disabled={!canNavigate.prev}
				/>
			</StyledIcon>
			<StyledList>
				{pages.range.map((page, index) => {
					const isDots = page === 'dots';
					const display = isDots ? '...' : page;
					const pageIndex = page === 'dots' ? -1 : page;
					const testId = page === 'dots' ? -1 : page;
					const key = page === 'dots' ? `dots-${index}` : page;

					return (
						<StyledItem
							data-testid={`linus_table_page_${testId}`}
							key={key}
							$active={page === currentPage}
							$isEllipsis={isDots}
							onClick={() => navigate.page(pageIndex)}
						>
							{display}
						</StyledItem>
					);
				})}
			</StyledList>
			<StyledIcon
				data-testid='linus_table_page_right_arrow'
				onClick={navigate.next}
				disabled={!canNavigate.next}
			>
				<Icon
					icon={icons.ArrowRight}
					title={t`web.dataTable.pager.rightArrow`}
					disabled={!canNavigate.next}
				/>
			</StyledIcon>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { color } }) => css`
		min-width: 0px;
		height: 40px;
		border-radius: 20px;
		border: 1px solid ${color.tableBorder};
		display: flex;
		align-items: center;
	`
);

const StyledList = styled.ul`
	list-style: none;
	height: 40px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
`;

type StyledItemProps = {
	$active: boolean;
	$isEllipsis: boolean;
};
const StyledItem = styled.li<StyledItemProps>(
	({ $active, $isEllipsis, theme: { color } }) => css`
		border: 1px solid ${color.tableBorder};
		border-right: none;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${$active ? color.pageHover : 'none'};
		color: ${$active ? color.textBlue : color.bodyText};

		&:last-of-type {
			border-right: 1px solid ${color.tableBorder};
		}

		&:hover {
			cursor: ${$isEllipsis ? 'not-allowed' : 'pointer'};
			background: ${$isEllipsis ? 'none' : color.pageHover};
		}
	`
);

type StyledIconProps = {
	disabled: boolean;
};
const StyledIcon = styled.div<StyledIconProps>(
	({ disabled, theme: { color } }) => css`
		min-width: 44px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: ${disabled ? color.disabledPagerBg : 'none'};
		opacity: ${disabled ? '0.5' : '1'};

		&:hover {
			cursor: ${disabled ? 'not-allowed' : 'pointer'};
		}

		&:first-of-type {
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
		}
		&:last-of-type {
			border-top-right-radius: 20px;
			border-bottom-right-radius: 20px;
		}

		> * {
			&:hover {
				cursor: ${disabled ? 'not-allowed' : 'pointer'};
			}
		}
	`
);
