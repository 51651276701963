import { Trans, useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

import {
	AdditionalInfoContainer,
	AnswerInfoContainer,
	CaptionFaded,
	CaptionGreen,
	CaptionRed,
	H4Med,
	IconContainer,
	SemiSpan,
	TableHeaderContainer,
} from './IadlTable.style';

type TableHeaderProps = {
	type?: 'adl' | 'iadl';
};

export function TableHeader({ type = 'iadl' }: Readonly<TableHeaderProps>) {
	const { t } = useTranslation();
	return (
		<TableHeaderContainer>
			<AnswerInfoContainer>
				<H4Med>{t('web.report.iadl.individualAnswers')}</H4Med>
				<AdditionalInfoContainer>
					<IconContainer>
						<Icon
							icon={icons.Close}
							color={theme.color.iconAlert}
							iconWidth={12}
							iconHeight={12}
						/>
						<CaptionRed>{t('web.report.iadl.onePoint')}</CaptionRed>
					</IconContainer>
					<IconContainer>
						<Icon
							icon={icons.CheckMark}
							color={theme.color.iconSuccess}
						/>
						<CaptionGreen>
							{t('web.report.iadl.zeroPoints')}
						</CaptionGreen>
					</IconContainer>
				</AdditionalInfoContainer>
				<CaptionFaded>{t(`web.report.${type}.totalInfo`)}</CaptionFaded>
			</AnswerInfoContainer>
			<div>
				<Trans
					i18nKey='web.report.iadl.tap'
					components={{
						b: <SemiSpan />,
					}}
				/>
			</div>
		</TableHeaderContainer>
	);
}
