export const getContentId = (dcrScore: number, riskLevel: boolean): string => {
	switch (true) {
		case dcrScore >= 4 && riskLevel:
			return 'CDS-DCRGreenHighRisk';
		case dcrScore >= 4 && !riskLevel:
			return 'CDS-DCRGreenLowRisk';
		case dcrScore >= 2 && riskLevel:
			return 'CDS-DCRYellowHighRisk';
		case dcrScore >= 2 && !riskLevel:
			return 'CDS-DCRYellowLowRisk';
		case dcrScore >= 0 && riskLevel:
			return 'CDS-DCRRedHighRisk';
		case dcrScore >= 0 && !riskLevel:
			return 'CDS-DCRRedLowRisk';
		default:
			return '';
	}
};

export const getDCRSectionText = (dcrScore: number): string => {
	switch (true) {
		case dcrScore >= 4:
			return 'cognitivelyUnimpaired';
		case dcrScore >= 2:
			return 'likelyCognitivelyUnimpaired';
		case dcrScore >= 0:
			return 'cognitivelyImpaired';
		default:
			return '';
	}
};

export const getLHQSectionText = (
	elevatedRisk: boolean,
	modifiableFactors: boolean
): string => {
	switch (true) {
		case elevatedRisk:
			return 'elevatedRisk';
		case !elevatedRisk && modifiableFactors:
			return 'factorsIdentified';
		case !elevatedRisk && !modifiableFactors:
			return '';
		default:
			return '';
	}
};
