import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { CCEImpression } from '../CCEImpression';
import { CDSContainer } from '../CDSContainer';
import { CDSRecommendations } from '../CDSRecommendations';
import { getAssessmentData } from '../../cognitiveEvalParseData/cognitiveEvalParseData';
import {
	AnalyticsLanguage,
	AnalyticsReportName,
} from 'components/report/BatteryComponents/BatteryHeader/BatteryHeader.analytics';
import { getLHQMetrics } from 'components/report/CognitiveEvaluation.helpers';
import { Report } from 'components/report/CognitiveEvaluation.types';
import { Metric } from 'components/report/segmentUtilities/metrics';
import { useShortPhrases } from 'components/shared/hooks';
import {
	BatteryHeaderFragment,
	BatteryType,
	SegmentType,
} from '../../../../generated/graphql';
import { TabItem } from '../../../layout/TabbedLayout/TabbedLayout';
import { calculateDCRWarning } from '../../SegmentComponents/RecallSubscore/RecallSubscore';

export type CDSOverviewProps = {
	batteryParticipant?: BatteryHeaderFragment;
	setActiveTabName?: React.Dispatch<React.SetStateAction<string>>;
	tabs?: TabItem[];
	batteryResult: Report;
	batteryType: BatteryType;
	patientReportHandler?: () => void;
	reportLanguage: string;
};

const fallBackDCRScore: Metric = {
	value: -1,
	version: '0.1',
};

const CDSOverview = ({
	batteryParticipant,
	batteryResult,
	setActiveTabName,
	tabs,
	batteryType,
	patientReportHandler,
	reportLanguage,
}: CDSOverviewProps): JSX.Element => {
	const clockShortPhrases = useShortPhrases();

	const { miniModalAssessment, lifeAndHealthAssessment } = getAssessmentData({
		batteryResult,
	});

	const { elevatedRisk, modifiableFactors } = getLHQMetrics(
		lifeAndHealthAssessment
	);

	const cdsRecommendations =
		batteryParticipant?.batteryResultById?.cdsRecommendations ?? [];

	const dcrScore =
		miniModalAssessment?.metricItems['dcr_score'] ?? fallBackDCRScore;

	const firstName = batteryParticipant?.firstName ?? '';

	const recallDelayedData = miniModalAssessment?.segmentResults.find(
		(subscoreSegment) =>
			subscoreSegment?.segmentType === SegmentType.RecallDelayed
	);
	const dcrWarning = calculateDCRWarning(
		recallDelayedData?.metricItems?.transcription_length?.value
	);

	if (miniModalAssessment) {
		miniModalAssessment.features = { clockShortPhrases };
	}

	const recommendationsRef = useRef<HTMLDivElement[] | null[]>([]);
	useEffect(() => {
		recommendationsRef.current = recommendationsRef.current.slice(
			0,
			cdsRecommendations.length
		);
	}, [cdsRecommendations]);

	const handleCardFocus = (index: number) => {
		// Need to increment by 1 because DCR card is never included in quick links
		recommendationsRef.current[index + 1]?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
		recommendationsRef.current[index + 1]?.focus({ preventScroll: true });
	};

	function getReportName() {
		if (batteryType === BatteryType.Dcr) {
			return AnalyticsReportName.DCR_PATIENT_REPORT_BHAP;
		}
		if (batteryType === BatteryType.Lhq) {
			return AnalyticsReportName.LHQ_PATIENT_REPORT_BHAP;
		}

		return AnalyticsReportName.CCE_PATIENT_REPORT_BHAP;
	}

	const handleBHAPLink = () => {
		sendEventData({
			eventType: AnalyticsAction.DownloadedReport,
			eventProperties: {
				downloadType: 'print PDF',
				language: AnalyticsLanguage[reportLanguage],
				reportName: getReportName(),
				batteryResultId: batteryResult?.batteryResultById?.id ?? '',
				source: 'Results page',
			},
		});
		patientReportHandler && patientReportHandler();
	};

	return (
		<>
			<StyledContainer>
				<StyledOverview>
					<CCEImpression
						dcrScore={dcrScore}
						dcrWarning={dcrWarning}
						elevatedRisk={elevatedRisk}
						modifiableFactors={modifiableFactors}
						tabs={tabs}
						setActiveTabName={setActiveTabName}
					/>
					<CDSRecommendations
						firstName={firstName}
						cdsRecommendations={cdsRecommendations}
						handleCardFocus={handleCardFocus}
						handleBHAPLink={handleBHAPLink}
					/>
				</StyledOverview>
			</StyledContainer>
			<CDSContainer
				cdsRecommendations={cdsRecommendations}
				ref={recommendationsRef}
				handleBHAPLink={handleBHAPLink}
			/>
		</>
	);
};

const StyledContainer = styled.div(
	({ theme: { color, boxShadow } }) => `
    width: 100%;
	background: ${color.white};
	box-shadow: ${boxShadow.standard};
	border-radius: 0px 0px 20px 20px;
	overflow: hidden;
	`
);

const StyledOverview = styled.div(
	({ theme: { spacing } }) => `
	display: grid;
    grid-auto-rows: minmax(300px, auto);
    grid-template-columns: repeat(2, 1fr);
	gap: ${spacing.xl};
	padding: ${spacing.xl};
	@media (max-width: 1245px) {
		display: flex;
		flex-direction: column;
	}
`
);

export { CDSOverview };
