import {
	OrganizationType,
	UserOrganizationsRoleStatus,
	UserStatus,
} from '@lh/eng-platform-organization-service-rest-client';

import { UserWithOrganizationData } from 'api/user/types';
import { RoleKeys } from 'enums/roles';

export type UserNavigationOutput = 'FORBIDDEN' | 'ONBOARD' | 'ACTIVE';

/**
 * These specific roles are not allowed to log into any of the portals UNLESS its coming from /remoteAuth (which is
 * the landing zone for impersonation attempts from MGMT portal)
 * */
export const isRoleInvalid = (roleKey?: string) => {
	if (!roleKey) return true;

	const nonLoginLinusRoles = [
		RoleKeys.LINUS_INTEGRATIONS_ADMIN.toString(),
		RoleKeys.LINUS_ITSEC.toString(),
		RoleKeys.LINUS_READ_ONLY.toString(),
	];

	return (
		nonLoginLinusRoles.includes(roleKey) &&
		!window.location.pathname.includes('remoteAuth')
	);
};

export const determineNavigationByUserStatus = (
	userStatus = UserStatus.Deactivated
): UserNavigationOutput => {
	if (userStatus === UserStatus.Invited) return 'ONBOARD';
	if (userStatus === UserStatus.Deactivated) return 'FORBIDDEN';
	return 'ACTIVE';
};

export function getValidClinicalRole(user: UserWithOrganizationData) {
	const primaryRole = user.roles.find((e) => e.primary);

	if (
		primaryRole &&
		primaryRole.status === UserOrganizationsRoleStatus.Active &&
		primaryRole.organizationType === OrganizationType.Clinical &&
		!isRoleInvalid(primaryRole.roleName)
	) {
		return {
			organizationId: primaryRole.organizationId,
			organizationRole: {
				roleId: primaryRole.roleId,
				roleName: primaryRole.roleName,
			},
		};
	}

	const validClinicalRole = user.roles.find(
		(role) =>
			role.status === UserOrganizationsRoleStatus.Active &&
			role.organizationType === OrganizationType.Clinical &&
			!isRoleInvalid(role.roleName)
	);

	if (validClinicalRole) {
		return {
			organizationId: validClinicalRole.organizationId,
			organizationRole: {
				roleId: validClinicalRole.roleId,
				roleName: validClinicalRole.roleName,
			},
		};
	}
}

export function determineRedirect(user: UserWithOrganizationData) {
	const activeRole = user.roles.find(
		(role) => role.status === UserOrganizationsRoleStatus.Active
	);

	if (!activeRole) return 'access-denied?ot=c&reason=deactivated';

	return activeRole.organizationType === OrganizationType.Research
		? 'access-denied?ot=r'
		: 'access-denied?ot=m';
}
