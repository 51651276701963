import styled, { css } from 'styled-components';

import { H3 } from 'components/shared/designSystem';
import { StyledCard } from 'components/shared/StyledCard';

export const Info = styled.div`
	display: flex;
	gap: 24px;
`;

export const DrawingContainer = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg} 0;
		max-width: 800px;
		margin: ${spacing.lg} 0;
	`
);

export const Title = styled(H3)(
	({ theme: { colors, spacing, weight } }) => css`
		color: ${colors.gray_10};
		font-weight: ${weight.medium};
		padding: 0 ${spacing.lg};
	`
);

export const LineDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin: ${spacing.md} 0;
	`
);

export const ImageContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const Image = styled.img(
	({ style }) => css`
		pointer-events: none;
		position: absolute;
		height: ${style?.height};
	`
);

export const DisclaimerContainer = styled.div`
	margin-bottom: 4px;
`;
