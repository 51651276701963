import { t } from 'i18n';

import { tMany } from '../../Pathway.misc';

export const data = {
	title: t('cds.medicationListModal.data.title'),
	sections: [
		{
			title: t(
				'cds.medicationListModal.data.high-anticholinergic-load.title'
			),
			content: tMany(
				'cds.medicationListModal.data.high-anticholinergic-load.content'
			),
		},
		{
			title: t(
				'cds.medicationListModal.data.low-moderate-anticholinergic-load.title'
			),
			content: tMany(
				'cds.medicationListModal.data.low-moderate-anticholinergic-load.content'
			),
		},
	],
	references: {
		title: t('cds.medicationListModal.data.references.title'),
		content: tMany('cds.medicationListModal.data.references.content'),
	},
};
