import {
	Role,
	User,
	UserOrganizationsRole,
	UserOrganizationsRoleStatus,
	UserStatus,
} from '@lh/eng-platform-organization-service-rest-client';

import { t } from 'i18n';
import { CurrentUser } from 'types';
import * as yup from 'yup';
import { maxCharacterLimits } from '../../../../utils/stringUtils';
import { phoneRegExp } from '../utils/phoneNumberFormat';

export type EditUserModel = Omit<User, 'role' | 'organization'> & {
	role: string;
	roles: UserOrganizationsRole[];
	roleName?: string;
	activate: boolean;
	deactivate: boolean;
	phoneNumber?: string;
	suffixIds?: string;
	invitedUserActions?: string;
};

export const getModel = (
	currentUser: CurrentUser,
	user?: Omit<User, 'organization'>,
	roles?: Role[]
): EditUserModel => {
	const orgRole = user?.roles.find(
		(role) => role.organizationId === currentUser.organizationId
	);
	const roleName = (roles || []).find((role) => role.id === orgRole?.roleId);

	return {
		...user,
		role: orgRole?.roleId,
		roleName: roleName,
		activate: false,
		deactivate: false,
		phoneNumber: user?.phoneNumber || '',
		suffixIds: user?.suffix?.map((v) => v.id).join(', '),
		statusInOrg:
			user?.status === UserStatus.Invited &&
			orgRole?.status !== UserOrganizationsRoleStatus.Deactivated
				? user.status
				: orgRole?.status,
	} as EditUserModel;
};

export const editUserSchema = yup.object().shape(
	{
		firstName: yup
			.string()
			.max(
				maxCharacterLimits.firstname,
				t`web.shared.forms.schemas.maxCharLimit`
			)
			.required(t`web.shared.forms.schemas.required`),
		lastName: yup
			.string()
			.max(
				maxCharacterLimits.lastname,
				t`web.shared.forms.schemas.maxCharLimit`
			)
			.required(t`web.shared.forms.schemas.required`),
		email: yup
			.string()
			.email(t`web.authErrors.invalidEmail`)
			.required(t`web.shared.forms.schemas.required`),
		role: yup.string().required(t`web.shared.forms.schemas.required`),
		roles: yup.array().required(t`web.shared.forms.schemas.required`),
		phoneNumber: yup
			.string()
			.max(20, t`web.shared.forms.schemas.invalidPhone`)
			.nullable()
			.notRequired()
			.when('phoneNumber', {
				is: (value: string) => value?.length,
				then: yup
					.string()
					.matches(
						phoneRegExp,
						t`web.shared.forms.schemas.invalidPhone`
					),
			}),
	},
	[
		// Add cyclic dependencies when making 'requires' to itself
		['phoneNumber', 'phoneNumber'],
	]
);
