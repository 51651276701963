import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';

import { t } from 'i18n';
import styled, { css, DefaultTheme } from 'styled-components';

import { TRAILS_COLORS } from 'components/report/TrailsLayout/TrailsLayout.interface';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { icons } from '../../../../enums/icons';
import {
	BatteryResult,
	BatteryResultStatus,
	BatteryType,
	ClinicalBatteryResultFragment,
	ClinicalResultTableSortField,
	MetricItem,
	SortDir,
} from '../../../../generated/graphql';
import { dateTimeFormatter } from '../../../../stringStrategy/dateFormatStringStrategy';
import { AvatarName } from '../../avatar/AvatarName';
import { Icon } from '../../designSystem/Icon';
import { getBrowserLanguage } from '../../getLanguage';
import { ViewResultButton } from '../columnComponents/ViewResultButton';
import { TableColumn } from '../DataTable';

export type TableData = {
	firstName?: string;
	lastName?: string;
	externalId?: string | null;
	avatarUrl?: string;
	batteryName?: string;
	date?: string;
	administrator?: string;
	id: string;
	notification?: boolean;
	participantId?: string;
	processing?: boolean;
	delayed?: boolean;
};

export const METRIC_ITEM_CONCERNS = 'hasConcerns';

export const PAGE_SIZE = 10;

export const showNotification = (batteryResult: BatteryResult): boolean => {
	if (!batteryResult) return false;

	if (batteryResult.battery.batteryType === BatteryType.HearingScreener) {
		const hearingScreenerConcern =
			batteryResult.assessmentResults.nodes[0].segmentResults.nodes[0].metricItems.nodes.find(
				(element: MetricItem) =>
					element.key === 'hearing_screener_class' &&
					element.value === 'Major Concern'
			);

		if (hearingScreenerConcern?.value) {
			return true;
		}
	}

	if (batteryResult.battery.batteryType === BatteryType.Trails) {
		const trailsConcern =
			batteryResult.assessmentResults.nodes[0].segmentResults.nodes[0].metricItems.nodes.find(
				(element: MetricItem) =>
					element.key === 'trails_b_duration_color' &&
					element.value.toLowerCase() === TRAILS_COLORS.Red
			);

		if (trailsConcern?.value) {
			return true;
		}
	}

	return (
		(!isProcessing(batteryResult) &&
			batteryResult.metricItems?.nodes.some(
				(mi: MetricItem) =>
					mi.key === METRIC_ITEM_CONCERNS && mi.value === 'true'
			)) ||
		false
	);
};

export const isBatteryResultProcessing = (
	batteryResultStatus?: BatteryResultStatus
): boolean => {
	if (!batteryResultStatus) return false;
	return batteryResultStatus !== BatteryResultStatus.AnalysisComplete;
};

// TODO: once all schemas are using pagination contract, we should isBatteryResultProcessing an deprecate this
export const isProcessing = (batteryResult: BatteryResult): boolean => {
	if (!batteryResult) return false;
	return batteryResult.status !== BatteryResultStatus.AnalysisComplete;
};

export const columns = (
	theme: DefaultTheme,
	dateFormat: string,
	defaultTimezone: string
): TableColumn<TableData>[] => {
	return [
		{
			propertyName: 'name',
			headerDisplay: t`web.shared.name`,
			minWidth: '199.5px',
			sortable: true,
			sortProperty: ClinicalResultTableSortField.ParticipantLastName,
			rowColumnComponent: ({ row }) => {
				return (
					<>
						<AvatarName
							firstName={row?.firstName || ''}
							lastName={row?.lastName || ''}
							patientExternalId={row?.externalId}
							avatarUrl={row?.avatarUrl}
							linkTo={`/patients/${row?.participantId}`}
						/>
					</>
				);
			},
		},
		{
			propertyName: 'batteryName',
			headerDisplay: t`web.shared.battery`,
			minWidth: '199.5px',
			sortable: false,
		},
		{
			propertyName: 'date',
			headerDisplay: t`web.shared.dateTaken`,
			minWidth: '162px',
			formatProperty: ({ value }) => {
				return dateTimeFormatter(
					value as string,
					dateFormat,
					getBrowserLanguage(),
					defaultTimezone
				);
			},
			sortable: true,
			sortProperty: ClinicalResultTableSortField.BatteryResultEndTime,
			sortDir: SortDir.Desc,
		},
		{
			propertyName: 'administrator',
			headerDisplay: t`web.shared.administrator`,
			minWidth: '199.5px',
			sortable: true,
			sortProperty: ClinicalResultTableSortField.ProctorFirstName,
		},
		{
			propertyName: 'report',
			headerDisplay: t`web.shared.report`,
			minWidth: '170px',
			sortable: false,
			rowColumnComponent: ({ row }) =>
				row?.delayed ? (
					<StyledResultsDelayed>
						<Notification
							text={t`web.shared.resultsTable.resultsDelayedTip`}
						>
							<Icon
								icon={icons.InfoOutlined}
								title={t`web.shared.notification`}
								color={theme.color.alertError}
							/>
						</Notification>
						<StyledDelayed>{t`web.shared.resultsTable.resultsDelayed`}</StyledDelayed>
					</StyledResultsDelayed>
				) : (
					<ShowIfAuthorized
						operations={[OperationToken.ViewAllAssessmentResults]}
					>
						<ViewResultButton
							disabled={row?.processing}
							text={
								row?.processing
									? t`web.shared.resultsTable.processing`
									: t`web.shared.view`
							}
							batteryResultId={row?.id}
							participantId={row?.participantId}
						/>
					</ShowIfAuthorized>
				),
		},
		{
			propertyName: 'notification',
			headerDisplay: ' ',
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ value }) => {
				return value ? (
					<StyledWrapper>
						<Notification
							text={t`web.shared.resultsTable.concerns`}
						>
							<Icon
								icon={icons.AlertSolid}
								color={theme.color.alertError}
								title={t`web.shared.notification`}
							/>
						</Notification>
					</StyledWrapper>
				) : (
					<StyledBlank>{` `}</StyledBlank>
				);
			},
		},
	];
};

export const mapData = (
	data?: ClinicalBatteryResultFragment[]
): TableData[] => {
	return (data || []).map((batteryResult) => {
		const batteryNameKey = batteryResult?.batteryDisplayKey
			? batteryResult?.batteryDisplayKey
			: '';
		return {
			firstName: batteryResult.participantFirstName,
			lastName: batteryResult.participantLastName,
			externalId: batteryResult.participantExternalId || null,
			batteryName: t(batteryNameKey),
			date: batteryResult.batteryResultEndTime || '',
			administrator: `${batteryResult.proctorLastName}, ${batteryResult.proctorFirstName}`,
			id: batteryResult.batteryResultId || '',
			notification:
				batteryResult.batteryResultHasConcerns ||
				batteryResult.batteryTrailsColor?.toLowerCase() ===
					TRAILS_COLORS.Red,
			participantId: batteryResult.participantId || '',
			processing: isBatteryResultProcessing(
				batteryResult.batteryResultStatus
			),
			delayed: batteryResult.batteryResultHasProcessingError,
		};
	});
};

export function Notification({
	children,
	text,
}: {
	children: React.ReactElement;
	text: string;
}): JSX.Element {
	return (
		<LinusTooltip
			overlay={<StyledTooltip>{text as string}</StyledTooltip>}
			placement='bottomLeft'
			tooltipIcon={children}
		/>
	);
}

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledBlank = styled.span`
	width: 24px;
`;

const StyledResultsDelayed = styled.div`
	display: flex;
	align-items: center;
`;

const StyledDelayed = styled.span(
	({ theme: { color, spacing } }) => css`
		color: ${color.deactivatedText};
		padding-left: ${spacing.xs};
	`
);

const StyledTooltip = styled.div`
	width: 264px;
	text-align: left;
	margin: 0 10px 0;
`;
