import {
	ValueListItem,
	ValueListType,
} from '@lh/eng-platform-organization-service-rest-client';

import { create } from 'zustand';

export const INITIAL_VALUE_LISTS: Record<ValueListType, ValueListItem[]> = {
	[ValueListType.Education]: [],
	[ValueListType.Ethnicity]: [],
	[ValueListType.InterfaceLocale]: [],
	[ValueListType.ParticipantLanguage]: [],
	[ValueListType.Race]: [],
	[ValueListType.RecallWordSet]: [],
	[ValueListType.Suffix]: [],
};

type Preferences = {
	dateFormat: string;
	defaultTimezone: string | null;
	defaultUserLocale: string | null;
	features: string[];
	lists: Record<ValueListType, ValueListItem[]>;
	cdsConcerns: string[];
};

interface State extends Preferences {
	setPreferences: (preferences: Partial<Preferences>) => void;
	reset: () => void;
}

export const usePreferencesStore = create<State>((set) => ({
	dateFormat: 'dd/MM/yyyy',
	defaultTimezone: null,
	defaultUserLocale: null,
	features: [],
	lists: INITIAL_VALUE_LISTS,
	cdsConcerns: [],
	setPreferences: (preferences) => set({ ...preferences }),
	reset: () =>
		set({
			dateFormat: 'dd/MM/yyyy',
			defaultTimezone: null,
			defaultUserLocale: null,
			features: [],
			lists: INITIAL_VALUE_LISTS,
			cdsConcerns: [],
		}),
}));
