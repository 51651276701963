import { t } from 'i18n';

export type PasswordStrengthCharacteristics = Array<{
	name: string;
	display: string;
	regex: RegExp;
	min: number;
}>;

const passwordStrengthValues = {
	specialCharMin: 1,
	uppercaseMin: 1,
	lowercaseMin: 1,
	numberMin: 1,
	lengthMin: 8,
};

export const passwordStrengthCharacteristics: PasswordStrengthCharacteristics =
	[
		{
			name: 'uppercase',
			display: t(`web.password.uppercase`, {
				min: passwordStrengthValues['uppercaseMin'],
			}),
			regex: /[A-Z]/,
			min: passwordStrengthValues['uppercaseMin'],
		},
		{
			name: 'specialChar',
			display: t(`web.password.specialChar`, {
				min: passwordStrengthValues['specialCharMin'],
			}),
			regex: /[$&+,~_`:#;=?[\]@|{}'<>".^*()%!/\\-]/,
			min: passwordStrengthValues['specialCharMin'],
		},
		{
			name: 'lowercase',
			display: t(`web.password.lowercase`, {
				min: passwordStrengthValues['lowercaseMin'],
			}),
			regex: /[a-z]/,
			min: passwordStrengthValues['lowercaseMin'],
		},
		{
			name: 'length',
			display: t(`web.password.length`, {
				min: passwordStrengthValues['lengthMin'],
			}),
			regex: /./,
			min: passwordStrengthValues['lengthMin'],
		},
		{
			name: 'number',
			display: t(`web.password.number`, {
				min: passwordStrengthValues['numberMin'],
			}),
			regex: /\d/,
			min: passwordStrengthValues['numberMin'],
		},
	];
