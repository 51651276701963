import styled, { css } from 'styled-components';

import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { P1 } from 'components/shared/designSystem';

export const ParamParagraph = styled(P1)`
	text-align: left;
`;

export const ThirdSection = styled.div`
	@media ${MediaQueries.desktop} {
		padding-right: 22px;
	}
`;

export const ThirdSectionWithDivider = styled.div<{ showDivider?: boolean }>(
	({ theme: { color }, showDivider }) => css`
		border-left: ${showDivider
			? `1px solid ${color.chipBorderColor}`
			: 'unset'};
		min-width: 385px;

		@media ${MediaQueries.desktop} {
			padding-left: 22px;
			padding-right: 22px;
		}

		@media ${MediaQueries.maxWidth.lg} {
			border-left: 0px;
			border-top: ${showDivider
				? `1px solid ${color.chipBorderColor}`
				: 'unset'};
			padding-left: 0em;
			padding-top: 22px;
			margin-top: 22px;
		}
	`
);

export const AttestationModalIconContainerLarge = styled.div`
	@media ${MediaQueries.maxWidth.lg} {
		display: none;
	}
`;

export const AttestationModalIconContainerMedium = styled.div`
	@media ${MediaQueries.desktop} {
		display: none;
	}
`;

export const SessionObservationsContainer = styled.div(
	({ theme: { fontSize } }) => css`
		margin-bottom: ${fontSize._8};
	`
);

export const Wrapper = styled.div`
	margin-top: 24px;
`;

export const LineDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin: ${spacing.lg} 0;
	`
);

export const SmallSectionHeader = styled.h4(
	({ theme: { fontSize, weight } }) => css`
		font-size: ${fontSize._16};
		font-weight: ${weight.semi};
		line-height: 19px;
		letter-spacing: 0.5px;
		text-align: left;
		margin-bottom: ${fontSize._8};
		display: inline-flex;
	`
);

export const Li = styled.li(
	({ theme: { fontSize, weight, spacing, color } }) => css`
		font-family: IBM Plex Sans;
		font-size: ${fontSize._16};
		font-weight: ${weight.regular};
		line-height: 19px;
		letter-spacing: 0.5px;
		text-align: left;
		list-style-type: disc;
		margin-left: 19px;
		color: ${color.bodyText};
		margin-top: ${spacing.sm};
	`
);

export const AdditionalNotesContainer = styled.div(
	({ theme: { spacing } }) => css`
		padding-top: ${spacing.sm};
	`
);

export const AdditionalNotesHeader = styled.h4(
	({ theme: { fontSize, weight, spacing, color } }) => css`
		font-family: IBM Plex Sans;
		font-size: ${fontSize._16};
		font-weight: ${weight.medium};
		line-height: 19px;
		letter-spacing: 0.5px;
		text-align: left;
		color: ${color.bodyTextSecondary};
		margin-bottom: ${spacing.sm};
	`
);

export const AdditionalNotesBody = styled.p(
	({ theme: { fontSize, weight, color } }) => css`
		font-family: IBM Plex Sans;
		font-size: ${fontSize._16};
		font-weight: ${weight.regular};
		line-height: 19px;
		letter-spacing: 0.5px;
		text-align: left;
		color: ${color.bodyTextSecondary};
	`
);

export const ReportedByTitle = styled.span(
	({ theme: { fontSize, weight, color } }) => css`
		font-family: IBM Plex Sans;
		font-size: ${fontSize._16};
		font-weight: ${weight.medium};
		line-height: 19px;
		letter-spacing: 0.5px;
		text-align: left;
		color: ${color.bodyTextSecondary};
	`
);

export const ReportByBody = styled.span(
	({ theme: { fontSize, weight, color } }) => css`
		font-family: IBM Plex Sans;
		font-size: ${fontSize._12};
		font-weight: ${weight.medium};
		line-height: ${fontSize._14};
		letter-spacing: 0.2px;
		text-align: left;
		color: ${color.bodyText};
		text-transform: uppercase;
	`
);

export const ReportByBodyContainer = styled.span(
	({ theme: { fontSize, spacing, color } }) => css`
		margin-left: ${fontSize._16};
		width: fit-content;
		border-radius: ${fontSize._16};
		gap: ${spacing.xs};
		background-color: ${color.badgeBackgroundColor};
		padding: ${spacing.xs} ${spacing.sm} ${spacing.xs} ${spacing.sm};
	`
);

export const Tooltip = styled.div(
	({ theme: { fontSize } }) => css`
		width: 500px;
		font-size: ${fontSize._16};
	`
);

export const HeaderIconContainer = styled.div(
	({ theme: { spacing } }) => css`
		margin-left: ${spacing.sm};
	`
);

export const Ul = styled.ul`
	min-width: 385px;
`;

export const ReportedByContainer = styled.div``;
