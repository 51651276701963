import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const Buttons = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xl};
		justify-content: center;
		margin-top: ${spacing.lg};
	`
);

export const Container = styled.div`
	width: 550px;
	max-width: 550px;
`;

export const Description = styled(P1)(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.sm};
	`
);
