import { Typography } from '@lh/eng-web-mosaic-common';

import { Space } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { LinusModal } from 'components/shared/LinusModal';
import { ButtonSm, Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { CareParterConfirmationModalIds, useModalStore } from 'store';
import { CarePartnerType } from '../CarePartner.types';

import {
	Center,
	ConfirmationContainer,
	Header,
	HeaderIconContainer,
} from './ConfirmationModal.style';
import { getConfirmationModalInfo } from './ConfirmationModal.utils';

export type CarePartnerConfirmationModalPayload = {
	fullNameCarePartner: string;
	fullNamePatient: string;
};

type CarePartnerConfirmationModalProps = {
	modalId: CareParterConfirmationModalIds;
};

export function CarePartnerConfirmationModal({
	modalId,
}: Readonly<CarePartnerConfirmationModalProps>) {
	const payload = useModalStore((state) => state.payload[modalId]);
	const visibleConfirmation = useModalStore(
		(state) => state.visible[modalId]
	);
	const closeModal = useModalStore((state) => state.close);

	function handleHideConfirmation() {
		closeModal(modalId);
	}

	if (!payload) return null;
	if (!visibleConfirmation) return null;

	let type = CarePartnerType.Add;
	if (modalId === 'EditCarePartnerConfirmation') type = CarePartnerType.Edit;

	return (
		<ConfirmationModal
			{...payload}
			type={type}
			onClose={handleHideConfirmation}
		/>
	);
}

type ConfirmationModalProps = CarePartnerConfirmationModalPayload & {
	type: CarePartnerType;
	onClose: () => void;
};

function ConfirmationModal({
	fullNameCarePartner,
	fullNamePatient,
	type,
	onClose,
}: Readonly<ConfirmationModalProps>) {
	const { t } = useTranslation();

	const { title, info } = getConfirmationModalInfo(
		type,
		fullNameCarePartner,
		fullNamePatient
	);

	return (
		<LinusModal hideCloseButton width='484px'>
			<ConfirmationContainer>
				<Header>
					<HeaderIconContainer>
						<Icon
							dataId='success-cta-care-partner-icon'
							color={theme.color.iconSuccess}
							icon={icons.CheckmarkSolid}
							iconHeight={30}
							iconWidth={30}
							width='40px'
							height='40px'
							title={t`web.shared.closeModal`}
						/>
						<Typography.H3
							data-id='cta-care-partner-title'
							data-testid='cta-care-partner-title'
							weight='500'
							style={{
								letterSpacing: 0.2,
							}}
						>
							{title}
						</Typography.H3>
					</HeaderIconContainer>
					<Icon
						dataId='close-cta-care-partner-icon'
						onClick={onClose}
						icon={icons.Close}
						title={t`web.shared.closeModal`}
						iconHeight={12}
						iconWidth={12}
						width='24px'
						height='24px'
					/>
				</Header>
				<P1
					data-id='info-cta-care-partner'
					data-testid='info-cta-care-partner'
				>
					{info}
				</P1>
				<Space h={theme.spacing.md} />
				<Center>
					<ButtonSm
						dataId='close-cta-care-partner'
						dataTestId='close-cta-care-partner'
						primary
						text={t`web.patients.forms.closeCTA`}
						width='160px'
						onClick={onClose}
					/>
				</Center>
			</ConfirmationContainer>
		</LinusModal>
	);
}
