import { t } from 'i18n';
import * as yup from 'yup';
import { maxCharacterLimits } from '../../../../utils/stringUtils';

export type removeBatteryModel = {
	issues: string[];
	otherIssues: string;
};

export const getModel = (): removeBatteryModel => {
	return {
		issues: [],
		otherIssues: '',
	} as removeBatteryModel;
};

export const removeBatterySchema = yup.object().shape({
	issues: yup.array().of(yup.string()).nullable(),
	otherIssues: yup
		.string()
		.max(maxCharacterLimits.notes, t`web.shared.forms.schemas.maxCharLimit`)
		.notRequired(),
});
