import styled from 'styled-components';

import { P1 } from '../../../../../../components/shared/designSystem/TextComponents';
import { LinusModalDialog } from 'components/shared/LinusModalDialog';
export interface SessionModalIconModalProps {
	onClose?: (props?: any) => void;
	titleText: string;
	contentText: string;
}

export const SessionModalIconModal = ({
	onClose,
	titleText,
	contentText,
}: SessionModalIconModalProps): JSX.Element => {
	return (
		<LinusModalDialog
			onClose={onClose}
			title={titleText}
			acceptButtonCallback={onClose}
		>
			<ModalIconModalContent>{contentText}</ModalIconModalContent>
		</LinusModalDialog>
	);
};

const ModalIconModalContent = styled(P1)`
	text-align: left;
	max-width: 650px;
`;
