import { P1 } from 'components/shared/designSystem';
import styled, { css } from 'styled-components';

export const HelperText = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.sm};
	`
);

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		margin: ${spacing.lg} 0;
	`
);

export const TitleContainer = styled(P1)(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
	`
);

export const Title = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

export const Tooltip = styled.div`
	width: 323px;
`;

export const Assessment = styled.div``;

export const LineDivider = styled.div(
	({ theme: { color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin: 12px 0;
	`
);

export const Evaluation = styled.div``;

export const PlainText = styled.div`
	margin-top: 12px;
`;

export const Buttons = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xl};
		justify-content: center;
	`
);

export const Copied = styled.div(
	({ theme: { spacing, colors } }) => css`
		display: flex;
		gap: ${spacing.sm};
		align-items: center;
		padding: ${spacing.sm};
		background-color: ${colors.green_lightest};
		margin-bottom: ${spacing.lg};
		border-radius: ${spacing.xs};
	`
);

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const Wrapper = styled.div`
	margin: 16px;
`;
