import { Trans } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { icons } from '../../../../../enums/icons';
import { Icon } from '../../../../shared/designSystem/Icon';
import { P1, P2 } from '../../../../shared/designSystem/TextComponents';
import { LinusTooltip } from '../../../../shared/LinusTooltip';

const QuestionnaireElevatedRisk = (): JSX.Element => {
	const theme = useTheme();
	return (
		<StyledContainer data-testid='questionnaireElevatedRisk'>
			<LinusTooltip
				overlay={
					<StyledToolTip>
						<StyledTooltipText>
							<Trans
								i18nKey={`web.report.lifeAndHealth.elevatedRisk.tooltip`}
								components={{ newLine: <br /> }}
							/>
						</StyledTooltipText>
					</StyledToolTip>
				}
				tooltipIcon={
					<Icon
						icon={icons.ArrowFlag}
						color={theme.color.elevatedRiskFlag}
					/>
				}
			/>
			<StyledText data-testid='questionnaireElevatedRiskText'>
				<Trans
					i18nKey={`web.report.lifeAndHealth.elevatedRisk.text`}
					components={{
						bold: <b />,
					}}
				/>
			</StyledText>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	height: 40px;
	width: 100%;
	margin: ${spacing.lg} 0 0 0;
`
);

const StyledText = styled(P1)`
	margin-left: 11px;
`;

const StyledTooltipText = styled(P2)(
	({ theme: { spacing, color } }) => `
	margin: ${spacing.sm} ${spacing.xs} 0 ${spacing.xs};
	color: ${color.white};
`
);

const StyledToolTip = styled.div`
	width: 300px;
	text-align: left;
`;

export { QuestionnaireElevatedRisk };
