import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useMemo } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';

import { parseBDSTMetrics } from './parseBDSTMetrics';

enum BdstFileNames {
	Trial1 = 'trial1',
	Trial2 = 'trial2',
	Trial3 = 'trial3',
}

const buildFilesToFetch = (segmentResultId: string): S3Segment[] => {
	return [
		{
			filename: 'backwards_digit_span_audio_trial1.mp4',
			segmentResultId,
			type: BdstFileNames.Trial1,
		},
		{
			filename: 'backwards_digit_span_audio_trial1.webm',
			segmentResultId,
			type: BdstFileNames.Trial1,
		},
		{
			filename: 'backwards_digit_span_audio_trial2.mp4',
			segmentResultId,
			type: BdstFileNames.Trial2,
		},
		{
			filename: 'backwards_digit_span_audio_trial2.webm',
			segmentResultId,
			type: BdstFileNames.Trial2,
		},
		{
			filename: 'backwards_digit_span_audio_trial3.mp4',
			segmentResultId,
			type: BdstFileNames.Trial3,
		},
		{
			filename: 'backwards_digit_span_audio_trial3.webm',
			segmentResultId,
			type: BdstFileNames.Trial3,
		},
	];
};

export function useBDSTData(segmentResult: DeepSegmentResult) {
	const { breakdownScores, performance } = useMemo(() => {
		return parseBDSTMetrics(segmentResult.metricItems);
	}, [segmentResult.metricItems.length]);

	const filesToFetch = useMemo(() => {
		return buildFilesToFetch(segmentResult.id);
	}, [segmentResult.id]);

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});
	const recordings = {
		trialOne: '',
		trialTwo: '',
		trialThree: '',
	};

	audioFiles.forEach((file) => {
		if (file.type === BdstFileNames.Trial1) {
			recordings.trialOne = file.responseUrl;
		}

		if (file.type === BdstFileNames.Trial2) {
			recordings.trialTwo = file.responseUrl;
		}

		if (file.type === BdstFileNames.Trial3) {
			recordings.trialThree = file.responseUrl;
		}
	});

	return {
		breakdownScores,
		performance,
		recordings,
	};
}
