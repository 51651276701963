import { useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

import { UserWithOrganizationData } from './types';

export async function fetchCurrentUser(
	signal?: AbortSignal
): Promise<UserWithOrganizationData> {
	const service = await getOrganizationService();
	const { data: user } = await service.getLinusPrincipal();

	const { data } = await service.getUserById(
		{
			organizationId: user.defaultOrganizationId,
			userId: user.id,
		},
		{ signal }
	);
	return {
		...data,
		organizationId: user.defaultOrganizationId,
		organizationPermissions: user.organizationPermissions,
	};
}

export function useCurrentUser() {
	return useQuery({
		meta: {
			errorMessage: 'Error fetching current user',
		},
		queryKey: [QueryKey.CurrentUser],
		queryFn: ({ signal }) => fetchCurrentUser(signal),
		staleTime: Infinity,
	});
}
