import { ReactElement } from 'react';
import Tooltip from 'rc-tooltip';
import styled, { css, useTheme } from 'styled-components';
import { icons } from '../../enums/icons';
import { Icon } from './designSystem/Icon';

export type LinusTooltipProps = React.ComponentProps<typeof Tooltip> & {
	textAlign?: string;
	tooltipIcon?: ReactElement;
};
export type OnPopupAlign = NonNullable<LinusTooltipProps['onPopupAlign']>;

const LinusTooltip = ({
	placement,
	textAlign = '',
	trigger,
	overlay,
	overlayStyle,
	tooltipIcon,
	onPopupAlign,
	align,
}: LinusTooltipProps): JSX.Element => {
	const { color } = useTheme();

	return (
		<Tooltip
			placement={placement ?? 'right'}
			trigger={trigger ?? 'hover'}
			onPopupAlign={onPopupAlign}
			destroyTooltipOnHide={true}
			align={align}
			overlayStyle={
				overlayStyle ?? {
					...defaultOverlayStyle,
					backgroundColor: color.formText,
					color: color.white,
				}
			}
			overlay={
				//@ts-expect-error Inconsistency between types due to React 18
				<StyledTooltip $textAlign={textAlign}>{overlay}</StyledTooltip>
			}
		>
			{tooltipIcon ?? (
				<StyledIcon>
					<Icon
						icon={icons.InfoOutlined}
						iconWidth={40}
						iconHeight={40}
					/>
				</StyledIcon>
			)}
		</Tooltip>
	);
};

const defaultOverlayStyle: React.CSSProperties = {
	position: 'fixed',
	zIndex: 1000,
	padding: 8,
	borderRadius: '5px',
};

type StyledTooltipProps = {
	$textAlign: string;
};

const StyledTooltip = styled.div<StyledTooltipProps>(
	({ $textAlign }) => css`
		min-width: 100px;
		opacity: 1;
		z-index: 10;
		text-align: ${$textAlign ?? 'unset'};
	`
);

const StyledIcon = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
        margin: ${spacing.sm};
        width: 15px;
    `
);

export { LinusTooltip };
