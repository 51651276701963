import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { ViewDetailsButton } from 'components/shared/ViewDetailsButton';
import { Caption } from 'components/shared/designSystem';

import { getFicAdditionalText, getFicConainerType } from './Fic.utils';
import { FicDetailsModal } from './FicDetailsModal';
import {
	Container,
	Content,
	Info,
	MedP2,
	ScoreBox,
	ScoreValue,
	Title,
} from './MemoryImpairmentProbability.style';
import { getAdditionalText, getContainerType } from './Mip.utils';
import { MipDetailsModal } from './MipDetailsModal';

export type ImpairmentProps = {
	score: number;
	rcr?: boolean;
	type?: 'mip' | 'fic';
};

export function Impairment({
	score,
	rcr,
	type = 'mip',
}: Readonly<ImpairmentProps>) {
	const { t } = useTranslation();

	const [showDetails, setShowDetails] = useState(false);

	const { scoreBgColor, text, textColor } =
		type === 'mip'
			? getContainerType(score)
			: getFicConainerType(score, rcr);

	function handleDetailsShow() {
		sendEventData({
			eventType:
				type === 'mip'
					? AnalyticsAction.ViewedMipDetails
					: AnalyticsAction.ViewedFicDetails,
			eventProperties: {
				source: 'web_report',
			},
		});
		setShowDetails(true);
	}

	function handleMipDetailsHide() {
		setShowDetails(false);
	}

	const titleText =
		type === 'mip'
			? t('web.report.mip.probability')
			: t('web.report.fic.title');

	return (
		<Container>
			<Info>
				<Title>{titleText}</Title>
				<ViewDetailsButton onClick={handleDetailsShow} />
			</Info>
			<Content>
				<ScoreBox backgroundColor={scoreBgColor}>
					<Score
						score={score}
						type={type}
						textColor={textColor}
						rcr={rcr}
					/>
				</ScoreBox>
				<MedP2>{text}</MedP2>
			</Content>
			{showDetails &&
				(type === 'mip' ? (
					<MipDetailsModal onClose={handleMipDetailsHide} />
				) : (
					<FicDetailsModal onClose={handleMipDetailsHide} />
				))}
		</Container>
	);
}

type ScoreProps = {
	score: number;
	textColor: string;
	rcr?: boolean;
	type?: 'mip' | 'fic';
};

function Score({
	score,
	textColor,
	rcr = false,
	type = 'mip',
}: Readonly<ScoreProps>) {
	const isRcr = score < 0 || rcr;

	const additionalText =
		type === 'mip' ? `${score}%` : getFicAdditionalText(score);

	return !isRcr ? (
		<>
			<ScoreValue scoreColor={textColor}>{additionalText}</ScoreValue>
			{type === 'mip' && (
				<Caption color={textColor}>{getAdditionalText(score)}</Caption>
			)}
		</>
	) : (
		<ScoreValue scoreColor={textColor}>--</ScoreValue>
	);
}
