import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const Wrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.xxs};
	`
);

export const Threshold = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ThresholdDescription = styled(P1)(
	({ theme: { spacing } }) => css`
		margin-left: ${spacing.sm};
	`
);
export const SolidDot = styled.div<{ color: string; size: number }>(
	({ color, size, theme: { spacing } }) => css`
		align-self: flex-start;
		margin-top: ${spacing.xs};
		min-width: ${size}px;
		min-height: ${size}px;
		border-radius: ${size}px;
		background-color: ${color};
	`
);
