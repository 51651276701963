import { Skeleton } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
	SyntheticEvent,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { hasOperation } from '../../../../hasOperation';
import { UserContext } from '../../../context/UserContext';
import { Icon } from '../../designSystem/Icon';
import { TableColumn, TableColumnOption } from '../DataTable';
import { TableData } from '../schemas/patientsSchema';

interface FormattedColumnOptions<T> extends TableColumnOption<T> {
	requirementMet: boolean;
}

export const Kebab = <T,>({
	column,
	row,
}: {
	column: TableColumn<T>;
	row?: T;
}): JSX.Element | null => {
	const theme = useTheme();
	const { currentUser } = useContext(UserContext);
	const tableRow = row as T & { id: string };

	const [isBlue, setIsBlue] = useState(false);
	const [isKebabOpen, setIsKebabOpen] = useState(false);
	const [posX, setPosX] = useState(0);
	const [posY, setPosY] = useState(0);
	const { t } = useTranslation();

	const onBlurEvent = (e: MouseEvent) => {
		const clickTarget = (e.target as HTMLElement).nodeName;
		if (isKebabOpen && clickTarget !== 'LI') {
			setIsKebabOpen(false);
			document.removeEventListener('mousedown', onBlurEvent);
		}
	};

	useEffect(() => {
		if (isKebabOpen) {
			document.addEventListener('mousedown', onBlurEvent);
			document.addEventListener('scroll', closeKebab, true);
		}
		return () => {
			document.removeEventListener('mousedown', onBlurEvent);
			document.removeEventListener('scroll', closeKebab, true);
		};
		// onBlurEvent is a function and thus wont change and wont cause useEffect
		// to fire, plus it's giving a "used before declared error"
	}, [isKebabOpen]);

	useEffect(() => {
		closeKebab();
	}, [row]);

	const handleClick = (e: SyntheticEvent) => {
		//Get the box positioning of the clicked target element
		const target = e.target as HTMLElement;
		const pos = target.getClientRects()[0];
		setPosX(pos.left + pos.width / 2 + 16);
		setPosY(pos.top + pos.height / 2 + 16);

		setIsKebabOpen(true);
	};

	const toggleKebab = () => {
		setIsKebabOpen(!isKebabOpen);
	};

	const closeKebab = () => {
		// Close kebab menu on page change.
		setIsKebabOpen(false);
	};

	const columnOptions = useMemo(() => {
		let formattedOptions: FormattedColumnOptions<T>[] | null = [];

		if (column.options) {
			column.options.forEach((option) => {
				const formattedRow = row as TableData;
				if (formattedRow.assignmentStatus === null) {
					formattedOptions = null;
					return;
				}
				const canPerformAction = hasOperation(
					currentUser,
					option.operations
				);
				const meetsRequirement = option.requirement
					? option.requirement({
							status: formattedRow.assignmentStatus,
					  })
					: true;

				if (canPerformAction && meetsRequirement && formattedOptions) {
					formattedOptions.push({
						...option,
						requirementMet: true,
					});
				}
			});
		}

		return formattedOptions;
	}, [column?.options]);

	if (columnOptions === null)
		return (
			<SkeletonContainer>
				<Skeleton circle h={24} w={24} />
			</SkeletonContainer>
		);

	if (columnOptions.length === 0) {
		return null;
	}

	const showBottomHR = (idx: number) => {
		return idx > columnOptions.length - 1 || columnOptions.length === 0;
	};

	return (
		<StyledWrapper data-testid='kebab_menu_icon'>
			<Icon
				icon={icons.Kebab}
				onMouseEnter={() => setIsBlue(!isBlue)}
				onMouseLeave={() => setIsBlue(!isBlue)}
				onClick={handleClick}
				color={
					isBlue || isKebabOpen ? theme.color.kebabIcon : undefined
				}
				title={t`web.dataTable.kebab.kebabIcon`}
				width='100%'
				height='100%'
			/>
			<AnimatePresence>
				{isKebabOpen && (
					<StyledList
						posX={posX}
						posY={posY}
						initial='initial'
						animate='in'
						exit='out'
						variants={kebabVariants}
						transition={kebabTransition}
					>
						{columnOptions.map((option, idx) => {
							if (option.requirementMet) {
								return (
									<React.Fragment key={option.key}>
										<StyledMenuLink
											data-id={option.value}
											onClick={() => {
												option.callback(tableRow);
												toggleKebab();
											}}
										>
											{option.value}
										</StyledMenuLink>
										{showBottomHR(idx) && <StyledHr />}
									</React.Fragment>
								);
							}
						})}
					</StyledList>
				)}
			</AnimatePresence>
		</StyledWrapper>
	);
};

const StyledWrapper = styled.span`
	position: relative;
	height: 64px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

type StyledListProps = {
	posX: number;
	posY: number;
};
const StyledList = styled(motion.ul)<StyledListProps>(
	({ posX, posY, theme: { color, boxShadow, spacing } }) => `
	position: fixed;
	top: ${posY}px;
	left: ${posX - 213}px;
	z-index: 3;
	width: 213px;
	background: white;
	padding: ${spacing.sm};
	border-radius: 8px 0 8px 8px;
	box-shadow: ${boxShadow.standard};
	border: 1px solid ${color.kebabMenuBorder}
`
);
const StyledHr = styled.div(
	({ theme: { color, spacing } }) => `
	width: 100%;
	height: 1px;
	background: ${color.tableBorder};
	border-radius: 2px;
	margin: ${spacing.sm} 0;
	`
);
const StyledMenuLink = styled.li(
	({ theme: { color, spacing } }) => `
	padding: ${spacing.sm};
	
	&:hover {
		cursor: pointer;
		background: ${color.kebabLinkHover};
	}
	`
);

//Framer Motion Animations
const kebabVariants = {
	initial: {
		opacity: 0,
		scale: 0.8,
		zIndex: 0,
	},
	in: {
		opacity: 1,
		scale: 1,
		zIndex: 3,
	},
	out: {
		opacity: 0,
		scale: 0.8,
		zIndex: 0,
	},
};
const kebabTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.2,
};

const SkeletonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
