import { Trans, useTranslation } from 'react-i18next';
import { LinusModalDialog } from '../../shared/LinusModalDialog';

type ChangeStatusSuccessModalProps = {
	firstname: string;
	lastname: string;
	onCancelModal: () => void;
	statusAction: string;
};

const ChangeStatusSuccessModal = ({
	firstname,
	lastname,
	onCancelModal,
	statusAction,
}: ChangeStatusSuccessModalProps): JSX.Element => {
	const { t } = useTranslation();

	let title;
	let body;
	switch (statusAction) {
		case 'deactivateUser': {
			title = t`web.team.changeStatusSuccessModal.deactivateUser.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusSuccessModal.deactivateUser.hasBeenDeactivated'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			break;
		}
		case 'cancelInvitation': {
			title = t`web.team.changeStatusSuccessModal.cancelInvitation.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusSuccessModal.cancelInvitation.hasBeenCanceled'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			break;
		}
		case 'resendInvitation': {
			title = t`web.team.changeStatusSuccessModal.resendInvitation.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusSuccessModal.resendInvitation.hasBeenResent'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			break;
		}
		case 'activate': {
			title = t`web.team.changeStatusSuccessModal.activateUser.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={
							'web.team.changeStatusSuccessModal.activateUser.hasBeenActivated'
						}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
			break;
		}
		default: {
			title = t`web.team.changeStatusSuccessModal.default.title`;
			body = () => (
				<div>
					<Trans
						i18nKey={'web.team.changesSavedModal.hasBeenSaved'}
						values={{
							firstName: firstname,
							lastName: lastname,
						}}
						components={[<strong />]}
					/>
				</div>
			);
		}
	}

	return (
		<LinusModalDialog
			onClose={onCancelModal}
			title={title}
			declineButtonCallback={onCancelModal}
			declineButtonText={t`web.team.sharedModal.closeButton`}
			width={'500px'}
		>
			{body()}
		</LinusModalDialog>
	);
};

export { ChangeStatusSuccessModal };
