import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { ERROR } from 'logging/linusLogger';
import { dctFill } from '../components';
import '../drawstack.less';
import { single_player } from '../gen_html.js';
import { dctTest } from '../model';
import { dctSketchPlayer } from '../player';
import '../timebar.less';
import '../vars.less';

type DrawingWidgetProps = {
	rawData: Record<string, unknown>;
	section_name: string;
	size?: AvailableDrawSize;
	overlay?: React.ReactElement;
};

type AvailableDrawSize = 'xxl' | 'xl' | 'l' | 'xm' | 'm' | 's' | 'xs';

export const DrawingWidget = ({
	rawData,
	section_name,
	size = 'xm',
	overlay,
}: DrawingWidgetProps): JSX.Element => {
	const ref = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (ref.current) {
			const element = new dctTest({ doc_data: { test_data: rawData } });
			dctFill(
				ref.current,
				single_player(section_name.toLowerCase(), element, {
					size,
				})
			);
			try {
				new dctSketchPlayer();
			} catch (e) {
				ERROR('Error trying to create a new sketch player: ', e);
			}
		}
	}, [ref.current]);

	return (
		<MainDrawingContainer data-testid='main-drawing-container'>
			<AnimationContainer ref={ref} />
			{overlay}
		</MainDrawingContainer>
	);
};

export const NORMALIZED_OVERLAY_STYLES: Record<
	AvailableDrawSize,
	React.CSSProperties
> = {
	xs: { height: '192px' },
	s: { height: '256px' },
	m: { height: '320px' },
	xm: { height: '380px' },
	l: { height: '480px' },
	xl: { height: '512px' },
	xxl: { height: '800px' },
};

const MainDrawingContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
`;

const AnimationContainer = styled.div``;
