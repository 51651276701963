import styled, { css } from 'styled-components';

import { Caption, H3, P1 } from 'components/shared/designSystem';
import { StyledCard } from 'components/shared/StyledCard';

export const PrintOnlyLogo = styled.div(
	({ theme: { spacing } }) => css`
		margin-right: ${spacing.lg};
		display: none;
		justify-self: right;

		@media print {
			display: block;
		}
	`
);

export const Container = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
		/* To account for padding.. */
		width: calc(100% - 70px);
		margin-bottom: ${spacing.xl};
		padding: ${spacing.xl};
		/* At 500px, it starts deforming */
		min-width: 500px;
		margin-left: ${spacing.xxs};
		margin-right: ${spacing.xxs};
	`
);

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	/* 
		Less than 1200px and we get deformed UI due long titles
		and PDF download buttons.
	*/
	@media (max-width: 1245px) {
		flex-direction: column;
		align-items: baseline;
	}
`;

export const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.div(
	({ theme: { color, spacing } }) => css`
		display: flex;
		align-items: center;
		height: ${spacing.xxl};
		border-left: 4px solid ${color.reportTitleBar};
	`
);

export const Header = styled(H3)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		padding-left: ${spacing.sm};
	`
);

export const PDFContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		padding-right: ${spacing.md};
		justify-content: space-between;
		column-gap: ${spacing.lg};

		@media print {
			display: none;
		}

		/* 
			Less than 1200px and we get deformed UI due long titles
			and PDF download buttons.
		*/
		@media (max-width: 1245px) {
			margin-top: ${spacing.lg};
			margin-bottom: ${spacing.lg};
			width: 100%;
			justify-content: flex-start;
		}

		/*
			Less than 800px and the buttons begin crumpling. The best
			is to account for that and let the UI calculate where the buttons
			should go according to the available spacing.
		*/
		@media (max-width: 800px) {
			display: inline-block;
			.loading-button {
				display: inline-flex;
				margin-right: ${spacing.lg};
				margin-top: 12px;
			}
		}
	`
);

export const PatientContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xl};
	`
);

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 200px;
`;

export const TextTitle = styled(Caption)(
	({ theme: { spacing, color, weight } }) => css`
		color: ${color.bodyTextSecondary};
		margin-bottom: ${spacing.xs};
		font-weight: ${weight.medium};
		text-transform: uppercase;
		line-height: 14px;
	`
);

export const TextContent = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const LineDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin: ${spacing.lg} 0;
	`
);
