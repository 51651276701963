import styled, { css } from 'styled-components';

import { TableColumn } from './DataTable';
import { RowCell } from './RowCell';

type TableRowProps<T> = {
	columns: TableColumn<T>[];
	dataRow: T;
	deviantWidth?: string;
};
const Row = <T,>({
	columns,
	dataRow,
	deviantWidth,
}: TableRowProps<T>): JSX.Element | null => {
	const cells = columns.map((column, idx) => {
		let dataValue = dataRow[column.propertyName as keyof T];
		let component;
		if (column.formatProperty) {
			dataValue = column.formatProperty({ column, value: dataValue });
		}
		if (column.rowColumnComponent) {
			component = column.rowColumnComponent({
				column: column,
				value: dataValue,
				row: dataRow,
			});
		}

		return (
			<RowCell
				width={column.width || ''}
				minWidth={column.minWidth}
				minHeight={column.minHeight}
				text={component || dataValue}
				key={idx}
			/>
		);
	});

	return (
		<StyledRow data-testid='data_table_row' deviantWidth={deviantWidth}>
			{cells}
		</StyledRow>
	);
};

export { Row };

type StyledRowProps = {
	deviantWidth?: string;
};
const StyledRow = styled.div<StyledRowProps>(
	({ deviantWidth, theme: { color, boxShadow, spacing } }) => css`
		min-width: ${deviantWidth ? deviantWidth : '1080px'};
		display: flex;
		border-collapse: collapse;
		transition: 0.15s ease all;
		min-height: ${spacing.xxxxxl};
		position: relative;

		&:nth-child(even) {
			background-color: ${color.cellHoverBg};
		}
		&::before {
			content: '';
			width: 4px;
			height: 100%;
			background: ${color.rowHoverEdgeColor};
			border-radius: 2px 0 0 2px;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			z-index: 10;
			transition: 0.15s;
		}
		&:hover {
			box-shadow: ${boxShadow.standard};

			&::before {
				opacity: 1;
			}
		}
	`
);
