import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { P1 } from '../../shared/designSystem/TextComponents';
import { Role } from '../../../generated/graphql';

export type UserRoleDefinitionsProps = {
	roles?: Omit<Role, 'operations'>[];
};

export const UserRoleDefinitions = ({
	roles,
}: UserRoleDefinitionsProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledContainer>
			<StyledTitleWrapper>
				<StyledTitle>{t`web.roles.userRoleDefinitionsLabel`}</StyledTitle>
			</StyledTitleWrapper>
			{roles?.map((role) => (
				<StyledDefinition
					key={role.id}
					data-testid='user-role-definition'
				>
					<Trans
						i18nKey={t(role.descriptionKey)}
						components={{ b: <StyledTitle /> }}
					/>
				</StyledDefinition>
			))}
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { color, spacing } }) => `
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background: ${color.formDisabledBg};
		border-radius: 6px;
		padding: ${spacing.md} ${spacing.md} ${spacing.md} ${spacing.xxl};
	`
);

const StyledTitle = styled(P1)(
	({ theme: { weight } }) => `
		font-weight: ${weight.medium};
		display: inline-block;
	`
);

const StyledTitleWrapper = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-bottom: ${spacing.sm};
	`
);

const StyledDefinition = styled(P1)(
	({ theme: { spacing } }) => `
		padding-top: ${spacing.sm};
	`
);
