import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type CDSBackToFullReportLinkProps = {
	participantId: string;
	reportId: string;
};

export const CDSBackToFullReportLink = ({
	participantId,
	reportId,
}: CDSBackToFullReportLinkProps): JSX.Element => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onClickReportLink = () => {
		navigate(`/results/${participantId}/${reportId}`);
	};

	return (
		<StyledLinkContainer>
			<StyledLink onClick={onClickReportLink}>
				{`< ${t('cds.fullArticle.backToFullReportBtn')}`}
			</StyledLink>
		</StyledLinkContainer>
	);
};

const StyledLink = styled.a(
	({ theme: { color, weight, fontSize } }) => `
	color: ${color.userNavHoverText};
	font-weight: ${weight.medium};
	font-size: ${fontSize._14};
	line-height: 17px;
	
	:hover, :visited {
		color: ${color.userNavHoverText};
	}
`
);

const StyledLinkContainer = styled.div(
	({ theme: { spacing } }) => `
		margin-bottom: ${spacing.md};
	`
);
