import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { useQuestionnaireQuery } from 'api/questionnaire';
import {
	QnrRowData,
	getQuestionnaireScore,
	parseQuestionnaireData,
} from 'components/report/CustomQuestionnaire.utils';
import { Questionnaire } from 'enums/questionnaire';
import { LoadingLayout, RCRLayout } from '../../shared/ReportLoader';
import { Report } from '../CognitiveEvaluation.types';

import { parseQuestions } from './FastReport.utils';
import { FastLayout } from './Layout';

interface FastReportProps {
	data?: Report;
	segmentResult?: DeepSegmentResult;
	mockedReportData?: FastReportData;
}

type FastReportData = {
	questions: QnrRowData[];
	score: {
		value: string;
		tagText: string;
	};
};

export function FastReport({
	data,
	segmentResult,
	mockedReportData,
}: Readonly<FastReportProps>) {
	const { segmentResultId } = useMemo(() => {
		const { segmentResultId } = parseQuestionnaireData(
			Questionnaire.FAST,
			data,
			segmentResult
		);

		return { segmentResultId };
	}, [data]);

	const { data: qnrData, isLoading: qnrLoading } = useQuestionnaireQuery(
		data?.batteryResultById?.id ?? '',
		segmentResultId ?? ''
	);

	const [reportData, setReportData] = useState<FastReportData | null>(
		mockedReportData ?? null
	);

	useEffect(() => {
		if (qnrData) {
			const score = getQuestionnaireScore(qnrData);
			const { questions, translations, textScore } =
				parseQuestions(qnrData);

			setReportData({
				questions: questions,
				score: {
					value: textScore?.toString() ?? '',
					tagText: translations[score],
				},
			});
		}
	}, [qnrData]);

	if (!mockedReportData && qnrLoading) {
		return (
			<LoadingLayout
				title='web.report.fastReport.fastReport'
				tooltipText='web.report.fastReport.fastTooltip'
			/>
		);
	}

	if (!reportData) {
		return (
			<RCRLayout
				description='web.report.unknown'
				title='web.report.fastReport.fastReport'
				tooltipText='web.report.fastReport.fastTooltip'
			/>
		);
	}

	return (
		<FastLayout score={reportData.score} tableData={reportData.questions} />
	);
}
