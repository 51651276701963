import { useLayoutEffect, useRef } from 'react';
import { dctTest } from './model';
import { single_player } from './gen_html.js';
import { dctSketchPlayer } from './player';
import { dctFill } from './components';
import './vars.less';
import './drawstack.less';
import './timebar.less';
import styled from 'styled-components';
import {
	ClockConcerns,
	ClockConcernsPropType,
} from '../ClockConcerns/ClockConcerns';

type ClockAnimationProps = {
	rawData: Record<string, unknown>;
	section_name: string;
	concern?: ClockConcernsPropType | undefined;
};

const toKey = (val: string): string => {
	return val.toLowerCase();
};

const ClockAnimation = ({
	rawData,
	section_name,
	concern,
}: ClockAnimationProps): JSX.Element => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		if (ref.current) {
			dctFill(
				ref.current,
				single_player(
					toKey(section_name),
					new dctTest({ doc_data: { test_data: rawData } }),
					{ size: 'xm' }
				)
			);
			try {
				new dctSketchPlayer();
			} catch (e) {
				console.error(e);
			}
		}
		// FIXME fix me or explain why please
	}, [ref.current]);

	if (!concern || !concern.concerns || !concern.concerns.length) {
		return <div ref={ref} />;
	}
	return (
		<MainClockContainer>
			<ClockAnimationContainer ref={ref} />
			<ClockConcerns {...concern} />
		</MainClockContainer>
	);
};

export { ClockAnimation };

const MainClockContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	padding-bottom: 90px;
`;

const ClockAnimationContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;

	.dct_player_timebar.timebar {
		position: absolute;
		width: 100%;
		margin: 0;
		left: 0;
	}

	.dct_player_controlbar.controlbar {
		width: 90%;
		padding-top: 70px;
		margin-left: 5%;
		margin-right: 5%;
		position: absolute;
		left: 0;
	}
`;
