import { SplitFactory } from '@splitsoftware/splitio-react';
import SplitIO from '@splitsoftware/splitio/types/splitio';
import React from 'react';

import { Memoize } from 'helpers/Memoize';
import { config } from '../../config';

import { FeatureFlagsContext } from './useFeatureFlagsContext';

interface ProviderProps {
	client: SplitIO.IBrowserClient | null;
	children: React.ReactNode;
}

function Provider({ children, client }: ProviderProps) {
	return (
		<FeatureFlagsContext.Provider value={client}>
			<Memoize>{children}</Memoize>
		</FeatureFlagsContext.Provider>
	);
}

interface FeaturesProviderProps {
	children: React.ReactNode;
	mocked?: boolean;
}

export function FeatureFlagsProvider({
	children,
	mocked = false,
}: FeaturesProviderProps) {
	const splitConfig = {
		core: {
			authorizationKey: config.splitIoAuthKey,
			key: 'default',
		},
	};

	return (
		<SplitFactory config={splitConfig} updateOnSdkTimedout={true}>
			{({ isReady, client }) => {
				if (mocked) {
					return <Provider client={client}>{children}</Provider>;
				} else {
					if (!isReady || !client) return null;
					return <Provider client={client}>{children}</Provider>;
				}
			}}
		</SplitFactory>
	);
}
