import { Auth } from '@lh/eng-shared-auth';

import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ERROR } from 'logging/linusLogger';
import { fetchUtil, prefix } from 's3ApiFetch';
import { removePathFromBase64 } from './TrailsLayout.utils';

export function useFetchTrailsRawData({
	rawDataURL,
	auth,
}: {
	rawDataURL: string;
	auth: Auth;
}) {
	const [rawOverlayData, setRawOverlayData] = useState<Record<string, any>>(
		{}
	);

	const envPrefix = prefix();
	const fetchData = useCallback(fetchUtil, []);

	useEffect(() => {
		const abortController = new AbortController();
		const url = `${envPrefix}s3MetricFiles/${rawDataURL
			.split('/')
			.slice(6)
			.join('/')}/trails-B.json`;
		fetchData({
			url,
			signal: abortController.signal,
			onSuccess: (resp) => {
				setRawOverlayData({ trails: resp.data });
			},
			auth,
		}).catch((e) =>
			ERROR(
				`Error trying to fetch trails raw data for url ${rawDataURL}, error: `,
				e
			)
		);

		return () => {
			abortController.abort();
		};
	}, [fetchData, envPrefix, rawDataURL]);

	return rawOverlayData;
}

export function useFetchTrailsImage({
	rawDataURL,
	auth,
}: {
	rawDataURL: string;
	auth: Auth;
}) {
	const envPrefix = prefix();
	const fetchData = useCallback(fetchUtil, []);

	const [image, setImage] = useState<string>();

	useEffect(() => {
		const abortController = new AbortController();
		const url = `${envPrefix}s3MetricFiles/${rawDataURL
			.split('/')
			.slice(6)
			.join('/')}/trails-B-drawing.svg`;
		const reader = new FileReader();
		fetchData({
			url,
			signal: abortController.signal,
			onSuccess: (resp: AxiosResponse<Blob, any>) => {
				reader.readAsDataURL(resp.data);
				reader.onloadend = () => {
					if (!abortController.signal.aborted) {
						setImage(
							`${reader.result}`.split('base64,').pop() ?? ''
						);
					}
				};
			},
			auth,
			responseType: 'blob',
		}).catch((e) => {
			ERROR(
				`Error trying to fetch trails image for url ${rawDataURL}, error: `,
				e
			);
		});

		return () => {
			abortController.abort();
		};
	}, [fetchData, envPrefix, rawDataURL]);

	return image;
}

/**
 * Hook that returns the trails svg without the svg paths, i.e.
 * the trails circles and numbers without the stroke data.
 * Ideally this would come directly from S3
 * instead of us needing to modify an existing svg.
 */
export function useFetchOverlayImage({
	rawDataURL,
	auth,
	trailsRawData,
}: {
	rawDataURL: string;
	auth: Auth;
	trailsRawData: Record<string, any>;
}) {
	const trailsOverlayRef = useRef<HTMLImageElement>(null);

	const trailsBase64 = useFetchTrailsImage({
		auth,
		rawDataURL,
	});

	useEffect(() => {
		if (trailsBase64 && trailsOverlayRef.current) {
			const removedPath64Trails = removePathFromBase64(trailsBase64);
			trailsOverlayRef.current.src =
				'data:image/svg+xml;base64,' + removedPath64Trails;
		}
		// please keep trailsRawData as a dependency as it should retrigger a ref rendering
	}, [trailsBase64, trailsOverlayRef?.current, trailsRawData]);

	return trailsOverlayRef;
}
