import { useContext, useMemo } from 'react';

import { UserContext } from '../../context/UserContext';

export function useOrganizationFeatures() {
	const { currentUser } = useContext(UserContext);

	const features = useMemo(() => {
		return currentUser.organizationFeatures;
	}, [currentUser]);

	return features || [];
}
