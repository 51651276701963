import { t } from 'i18next';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';
import { icons } from 'enums/icons';
import { AnalyticsAction, sendEventData } from 'analytics';
import { URLS } from 'components/shared/constants';

type PrivacyPolicyLinkProps = {
	privacyPolicyUrl?: string;
	text?: string;
};

const PrivacyPolicyLink = ({
	privacyPolicyUrl = URLS.privacyPolicy,
	text = t`web.shared.privacyPolicyLink` as string,
}: PrivacyPolicyLinkProps): JSX.Element => {
	return (
		<ExternalLinkWithIcon
			text={text}
			href={privacyPolicyUrl}
			icon={icons.ExternalLink}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedPrivacyPolicy,
				})
			}
		/>
	);
};

export { PrivacyPolicyLink };
