import { t } from 'i18n';
import * as yup from 'yup';

import { Gender, Handedness } from '../../../../generated/graphql';
import { maxCharacterLimits } from '../../../../utils/stringUtils';
import { PHONE_NUMBER_REGEX } from '../Components/PhoneNumber';

export type AddParticipantModel = {
	avatarUrl?: string;
	firstName: string;
	lastName: string;
	birthDate: string;
	gender?: Gender;
	ethnicityIds?: string;
	raceIds?: string;
	educationIds: string;
	handedness?: Handedness;
	language: string;
	externalId?: string;
	contactEmail?: string;
	contactPhone?: string;
	sexAssignedAtBirth: string;
};

const birthDateSchema = yup
	.date()
	.min(new Date('1/1/1900'), t`web.shared.forms.schemas.birthdateMin`)
	.max(new Date(), t`web.shared.forms.schemas.birthdateMax`)
	.required(t`web.shared.forms.schemas.required`);

export const addParticipantSchema = yup.object().shape({
	firstName: yup
		.string()
		.max(
			maxCharacterLimits.firstname,
			t`web.shared.forms.schemas.maxCharLimit`
		)
		.required(t`web.shared.forms.schemas.required`),
	lastName: yup
		.string()
		.max(
			maxCharacterLimits.lastname,
			t`web.shared.forms.schemas.maxCharLimit`
		)
		.required(t`web.shared.forms.schemas.required`),
	birthDate: birthDateSchema,
	educationIds: yup
		.string()
		.required(t`web.participant.forms.errors.education`),
	handedness: yup
		.string()
		.required(t`web.participant.forms.errors.dominantHand`),
	sexAssignedAtBirth: yup
		.string()
		.required(t`web.participant.forms.errors.sex`),
	gender: yup.string().optional(),
	raceId: yup.string().optional().nullable(),
	externalId: yup
		.string()
		.optional()
		.nullable()
		.max(
			maxCharacterLimits.externalId,
			t`web.shared.forms.schemas.maxExternalId`
		),
});

export const addPatientSchema = addParticipantSchema.concat(
	yup.object().shape({
		notes: yup
			.string()
			.max(
				maxCharacterLimits.notes,
				t`web.shared.forms.schemas.maxCharLimit` +
					` (${maxCharacterLimits.notes.toLocaleString()})`
			)
			.optional()
			.nullable(),
		language: yup.string().required(t`web.shared.forms.schemas.required`),
		contactPhone: yup
			.string()
			.optional()
			.nullable()
			.matches(
				PHONE_NUMBER_REGEX,
				t`web.shared.forms.schemas.enterValidPhone`
			),
		contactEmail: yup
			.string()
			.optional()
			.nullable()
			.email(t`web.shared.forms.schemas.enterValidEmail`),
	})
);

export const addPatientSchemaWithFormats = function (dateFormat: string) {
	return addPatientSchema.concat(
		yup.object().shape({
			birthDate: birthDateSchema.required(
				t(`web.shared.forms.schemas.invalidDateOfBirth`, {
					format: dateFormat,
				})
			),
		})
	);
};

export const addResidentSchema = addParticipantSchema.concat(
	yup.object().shape({})
);

export const nullOParticipant: AddParticipantModel = {
	firstName: '',
	lastName: '',
	birthDate: '',
	gender: undefined,
	ethnicityIds: undefined,
	raceIds: undefined,
	educationIds: '',
	handedness: undefined,
	language: '',
	sexAssignedAtBirth: '',
};

export type AddBatteryForResidentModel = {
	battery: string;
	provider: string;
};

export const addBatteryForResidentSchema = yup.object().shape({
	battery: yup.string().required(t`web.shared.forms.schemas.required`),
	provider: yup.string().required(t`web.shared.forms.schemas.required`),
});

export const nullOBatteryForResident: AddBatteryForResidentModel = {
	battery: '',
	provider: '',
};
