import {
	Configuration,
	DefaultApi,
} from '@lh/eng-prd-remote-assessments-service-rest-client';

import { config } from 'config';
import { Auth } from 'features/auth-service';

export async function getRemoteAssessmentsService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new DefaultApi(
			new Configuration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
