import { t } from 'i18n';
import * as yup from 'yup';

export type ForgotPasswordModel = {
	username: string;
};

export const getModel = (): ForgotPasswordModel => {
	return { username: '' };
};

export const forgotPasswordSchema = yup.object().shape({
	username: yup
		.string()
		.email(t`web.authErrors.invalidEmail`)
		.required(t`web.authErrors.emailRequired`),
});
