import { LoadingDots } from 'components/shared/LoadingDots';
import { Memoize } from 'helpers/Memoize';
import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { routeEnum as _routeEnum, RouteEnum } from '../../../enums/routeEnum';
import { hasOperation } from '../../../hasOperation';
import { UserContext } from '../../context/UserContext';
import { FileNotFound } from '../../FileNotFound';

type AuthenticatedRouteProps = {
	children: ReactNode;
};

export const RoleBasedRoute = ({
	children,
}: AuthenticatedRouteProps): JSX.Element => {
	const { pathname } = useLocation();

	// this is required to get the org specific string values in the menu
	const { currentUser } = useContext(UserContext);
	const enumeration = _routeEnum(currentUser);

	// This will need to be more robust when we begin to have nested routes
	const routeInfo = enumeration.find((route: RouteEnum) =>
		pathname.toLowerCase().includes(route.path)
	);

	if (!routeInfo) {
		return <FileNotFound />;
	}

	if (currentUser?.id && hasOperation(currentUser, routeInfo.operations)) {
		return <Memoize>{children}</Memoize>;
	}

	if (!hasOperation(currentUser, routeInfo.operations)) {
		return <LoadingDots />;
	}

	return (
		<Navigate
			to={`/auth/login?redirect=${routeInfo.path}`}
			state={{ from: pathname }}
			replace
		/>
	);
};
