import { SegmentType } from '@lh/eng-platform-battery-service-rest-client';

import { Report } from 'components/report/CognitiveEvaluation.types';
import { parseSegmentResult } from 'components/report/Report.utils';

import { PhonemicLayout } from './PhonemicLayout';

type PhonemicReportProps = {
	batteryResult: Report;
};

export function PhonemicReport({
	batteryResult,
}: Readonly<PhonemicReportProps>) {
	const segmentResult = parseSegmentResult(
		SegmentType.PhonemicFluency,
		batteryResult
	);

	return <PhonemicLayout segmentResult={segmentResult} />;
}
