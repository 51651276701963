import { t } from 'i18n';
import { useState } from 'react';
import ProgressBar from 'react-customizable-progressbar';
import { Trans, useTranslation } from 'react-i18next';
import { DefaultTheme, useTheme } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { MetricHash } from 'components/report/segmentUtilities/metrics';
import { LoadingDots } from 'components/shared/LoadingDots';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../../shared/designSystem/Icon';
import { H4 } from '../../../shared/designSystem/TextComponents';
import { useCDS } from '../../../shared/hooks/useCDS';
import { LinusModal } from '../../../shared/LinusModal';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { DCTClassificationString } from '../../AssessmentComponents/MiniModal/MiniModalReport';
import { FormattedSegmentResults } from '../../cognitiveEvalParseData/cognitiveEvalParseData';
import { getConcerningScores } from '../../segmentUtilities/barChart';
import { Concerns } from '../Concerns/Concerns';

import { DctClockCompositeScales } from './DctClockCompositeScales';
import {
	LoadingContainer,
	StyledSubscoreContainer,
	NoScoreContainer,
	StyledBoxContainer,
	StyledIcon,
	StyledLinkWrapper,
	StyledProgressBar,
	StyledProgressScore,
	StyledScoreText,
	StyledScoreTitle,
	StyledScoringInfo,
	StyledTextContainer,
	StyledTooltipText,
	StyledTooltipTextContainer,
} from './DctClockSubscore.style';

export type DctScoreClassification =
	| 'outside'
	| 'indeterminate'
	| 'inside'
	| 'unanalyzable';

export type DctClockSubscoreProps = {
	dctClockData?: FormattedSegmentResults;
};

const dctScoreMap = (theme: DefaultTheme) => ({
	inside: {
		dctScoreColor: theme.color.graphGreen,
		// eslint-disable-next-line react/jsx-no-undef
		scoreIcon: icons.ArrowIncrease,
		scoreText: t('web.report.DCTClock.subscore.info.inside'),
		scoreTextColor: theme.color.graphGreenDarkest,
	},
	indeterminate: {
		dctScoreColor: theme.color.graphYellow,
		scoreIcon: icons.Indeterminate,
		scoreText: t('web.report.DCTClock.subscore.info.indeterminate'),
		scoreTextColor: theme.color.graphYellowDarker,
	},
	outside: {
		dctScoreColor: theme.color.graphOrange,
		scoreIcon: icons.ArrowDecrease,
		scoreText: t('web.report.DCTClock.subscore.info.outside'),
		scoreTextColor: theme.color.graphOrangeDarker,
	},
	unanalyzable: {
		dctScoreColor: '',
		scoreIcon: '',
		scoreText: DCTClassificationString.UNANALYZABLE,
		scoreTextColor: '',
	},
});

export const DctClockSubscore = ({
	dctClockData,
}: DctClockSubscoreProps): JSX.Element | null => {
	const cdsEnabled = useCDS();
	const theme = useTheme();
	const { t } = useTranslation();

	const [showCompositeScales, setShowCompositeScales] = useState(false);

	if (!dctClockData) {
		return (
			<LoadingContainer>
				<LoadingDots />
			</LoadingContainer>
		);
	}

	const metricData: MetricHash | null = dctClockData.metricItems || null;
	const concerns = metricData ? getConcerningScores(metricData) : [];
	const score = metricData
		? Math.floor(Number(metricData['DCTScore']?.value))
		: -1;

	const classification = metricData['DCTClassificationString']
		? (metricData['DCTClassificationString']
				.value as DctScoreClassification)
		: null;

	if (!classification) {
		return (
			<LoadingContainer>
				<LoadingDots />
			</LoadingContainer>
		);
	}

	const { dctScoreColor, scoreIcon, scoreText, scoreTextColor } =
		dctScoreMap(theme)[classification];

	function handleCompositeScalesModal() {
		setShowCompositeScales(!showCompositeScales);
	}

	function handleCompositeScalesLink() {
		sendEventData({ eventType: AnalyticsAction.ClickedScales });
		setShowCompositeScales(true);
	}

	if (classification === DCTClassificationString.UNANALYZABLE) {
		return (
			<StyledSubscoreContainer>
				<H4>{t`web.report.DCTClock.subscore.title`}</H4>
				<StyledBoxContainer>
					<NoScoreContainer>{'--'}</NoScoreContainer>
					<StyledTextContainer>
						{t`web.dcrReport.subscores.DCTClock.requiresClinicianReview`}
					</StyledTextContainer>
				</StyledBoxContainer>
			</StyledSubscoreContainer>
		);
	}

	const translatedClassification = t`web.report.DCTClock.subscore.info.classification.${classification}`;

	return (
		<StyledSubscoreContainer>
			<H4>{t`web.report.DCTClock.subscore.title`}</H4>
			<StyledProgressBar>
				<ProgressBar
					radius={65}
					progress={score}
					strokeWidth={6}
					strokeColor={dctScoreColor}
					trackStrokeWidth={4}
					pointerRadius={8}
					pointerFillColor={dctScoreColor}
					pointerStrokeWidth={4}
					pointerStrokeColor={dctScoreColor}
					initialAnimation={false}
				/>
				<StyledProgressScore data-testid='dct-clock-subscore-display'>
					<Trans
						i18nKey={
							'web.report.DCTClock.subscore.progressBar.score'
						}
						values={{ score }}
					/>
				</StyledProgressScore>
			</StyledProgressBar>
			<StyledScoringInfo>
				<StyledScoreTitle>
					{t`web.report.DCTClock.subscore.info.titleWithTrademark`}
				</StyledScoreTitle>
				<LinusTooltip
					overlay={
						<StyledTooltipTextContainer>
							<StyledTooltipText>
								{t`web.report.DCTClock.subscore.info.tooltip.0`}
							</StyledTooltipText>
							<StyledTooltipText>
								<Trans
									i18nKey={
										'web.report.DCTClock.subscore.info.tooltip.1'
									}
									components={[<strong />]}
								/>
							</StyledTooltipText>
							<StyledTooltipText>
								<Trans
									i18nKey={
										'web.report.DCTClock.subscore.info.tooltip.2'
									}
									components={[<strong />]}
								/>
							</StyledTooltipText>
							<StyledTooltipText>
								<Trans
									i18nKey={
										'web.report.DCTClock.subscore.info.tooltip.3'
									}
									components={[<strong />]}
								/>
							</StyledTooltipText>
						</StyledTooltipTextContainer>
					}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
				<StyledIcon classification={classification}>
					<Icon
						icon={scoreIcon}
						color={dctScoreColor}
						title={translatedClassification}
					/>
				</StyledIcon>
				<StyledScoreText scoreTextColor={scoreTextColor}>
					{scoreText}
				</StyledScoreText>
			</StyledScoringInfo>
			{!cdsEnabled && concerns.length > 0 && (
				<Concerns concerns={concerns} />
			)}
			<StyledLinkWrapper
				data-testid='compositeScalesButton'
				onClick={handleCompositeScalesLink}
			>
				{t`web.report.DCTClock.subscore.info.compositeScalesButton.text`}
			</StyledLinkWrapper>
			{showCompositeScales && (
				<LinusModal
					hideCloseButton
					onClose={handleCompositeScalesModal}
				>
					<DctClockCompositeScales
						metricData={metricData}
						onClose={handleCompositeScalesModal}
						title={t`web.report.DCTClock.subscore.info.compositeScalesModal.title`}
					/>
				</LinusModal>
			)}
		</StyledSubscoreContainer>
	);
};
