import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import { truncateString } from '../../../utils/stringUtils';

import { Avatar } from './Avatar';
import { FeatureFlagGate, FeatureFlags } from 'features/feature-flags';

interface AvatarNameProps {
	firstName: string;
	lastName: string;
	patientExternalId?: string | null;
	avatarUrl?: string;
	linkTo?: string;
}

const AvatarName = ({
	firstName,
	lastName,
	patientExternalId,
	avatarUrl,
	linkTo,
}: AvatarNameProps): JSX.Element => {
	const name = truncateString(`${lastName}, ${firstName}`, 20);

	return (
		<Container>
			<Avatar
				imageSrc={avatarUrl}
				firstName={firstName}
				lastName={lastName}
			/>
			<Wrapper>
				{linkTo ? (
					<Link data-id='avatar-link' to={linkTo}>
						{name}
					</Link>
				) : (
					<span data-id='avatar-name'>{name}</span>
				)}
				<FeatureFlagGate flag={FeatureFlags.BulkPatientImport}>
					{patientExternalId && (
						<StyledId data-id='patient-external-id'>{`ID: ${patientExternalId}`}</StyledId>
					)}
				</FeatureFlagGate>
			</Wrapper>
		</Container>
	);
};

export { AvatarName };

const Container = styled.span`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const Wrapper = styled.div`
	flex-direction: column;
	padding: 0 0 0 8px;
`;

const StyledId = styled.div(
	({ theme: { color, fontSize } }) => css`
		color: ${color.bodyTextSecondary};
		font-size: ${fontSize._12};
	`
);
