import { FormattedAssessmentResults } from './cognitiveEvalParseData/cognitiveEvalParseData';
import { theme } from 'components/providers/StyleProvider/theme';
import { BatteryType, SegmentType } from 'generated/graphql';

export const getLHQMetrics = (
	lifeAndHealthAssessment: FormattedAssessmentResults | undefined
): { elevatedRisk: boolean; modifiableFactors: boolean } => {
	const lhqSegment = lifeAndHealthAssessment?.segmentResults.find(
		(segment) =>
			segment.segmentType === SegmentType.Lhq32 ||
			segment.segmentType === SegmentType.Lhq15
	);
	const elevatedRisk =
		'lhqElevatedRisk' in (lhqSegment?.metricItems ?? {}) &&
		lhqSegment?.metricItems.lhqElevatedRisk.value === 'true';
	const modifiableFactors =
		'lhq32_modifiable_factors' in (lhqSegment?.metricItems ?? {}) &&
		lhqSegment?.metricItems.lhq32_modifiable_factors.value === 'true';
	return { elevatedRisk, modifiableFactors };
};
export function getHeaderMarginBottom(batteryType: BatteryType) {
	const batteryTypesWithXlMargin: BatteryType[] = [
		BatteryType.CognitiveEvaluation,
		BatteryType.Dcr,
		BatteryType.Default,
		BatteryType.Lhq,
	];

	if (batteryTypesWithXlMargin.includes(batteryType)) {
		return theme.spacing.xl;
	}

	return '0px';
}
