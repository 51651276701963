import { useField } from 'formik';
import {
	DropdownOption,
	ITypeAheadSearch,
	LinusInputChildrenProps,
} from '../../../../types';
import { useEffect, useState } from 'react';
import { Reason } from '@lh/eng-platform-organization-service-rest-client';
import { definedProps } from 'components/shared/Forms/Components/definedProps';
import { SearchFunctionProps, TDropdownElement } from './interface';
import { TypeAheadSearchBar } from './typeAheadSearchBar';
import { AnimatePresence } from 'framer-motion';
import { TypeAheadResultsList } from './resultsList';
import { SelectedOptionsElementsContainer } from './selectedOptions/SelectedOptions';
import {
	closeOpenDisplayOptions,
	handleBlurComponentEvent,
	handleKeyPress,
	handleOpenListener,
	onChange,
	onHandleClick,
} from './TypeAheadSearch.utils';

const TypeAheadSearch = <T,>(
	props: LinusInputChildrenProps<T>
): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, error, helpers] = useField(props.name);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const { setValue } = helpers;
	const [filterString, setFilterString] = useState('');
	const [selectedOption, setSelectedOption] = useState<
		DropdownOption | undefined
	>(undefined);
	const [filteredOptions, setFilteredOptions] = useState<
		TDropdownElement<Reason>[]
	>([]);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState<string>('');
	const componentProps = definedProps(props);
	const display =
		componentProps.preferDisplayLength === 'long'
			? 'longDisplay'
			: 'display';
	const [selectedOptions, setSelectedOptions] = useState<
		TDropdownElement<Reason>[]
	>([]);

	const searchData = props.search as ITypeAheadSearch<
		SearchFunctionProps,
		TDropdownElement<Reason>[]
	>;

	useEffect(() => {
		return handleOpenListener(document, false, (event) =>
			applyHandleKeyPress(event)
		);
	}, []);

	useEffect(() => {
		searchData.onChange(selectedOptions);
	}, [selectedOptions]);

	const applyCloseOpenDisplayOptions = (
		selected?: TDropdownElement<Reason>
	) => {
		closeOpenDisplayOptions({
			selected,
			setValue,
			setFilteredOptions,
			setIsOpen,
			setSelectedIndex,
			setFilterString,
			selectedOptions,
			setSelectedOptions,
			setSearch,
		});
	};
	const applyBlurComponentEvent = (event: MouseEvent) =>
		handleBlurComponentEvent({
			event,
			isOpen,
			name: props.name,
			applyCloseOpenDisplayOptions,
		});

	const applyHandleKeyPress = (event: KeyboardEvent) => {
		handleKeyPress({
			event,
			filteredOptions,
			isOpen,
			selectedIndex,
			handleSelect,
			applyCloseOpenDisplayOptions,
			setSelectedIndex,
		});
	};

	const handleSelect = function (option: TDropdownElement<Reason>) {
		if (option.dropdownOption.value === selectedOption?.value) {
			setSelectedOption(undefined);
		} else {
			applyCloseOpenDisplayOptions(option);
		}
	};

	useEffect(() => {
		props.search.onChange(selectedOptions);
	}, [filteredOptions]);

	useEffect(() => {
		if (isOpen) {
			document.addEventListener('mousedown', applyBlurComponentEvent);
		}
		return () =>
			document.removeEventListener('mousedown', applyBlurComponentEvent);
	}, [isOpen, props.name]);

	const applyOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange({
			event: e,
			setFilterString,
			setSearch,
			setPage,
			setFilteredOptions,
			isOpen,
			setIsOpen,
		});
	};

	const applyOnHandleClick = () => {
		onHandleClick({
			disabled: props.disabled,
			setIsOpen,
			setSearch,
			setPage,
			filterValue: search,
			isOpen,
		});
	};

	const placeholder = !componentProps.value
		? ((componentProps.placeholder || '') as string)
		: '';
	// Display value in input
	const displayValue = () => {
		let returnFilterString: string | undefined = '';
		if (isOpen) {
			returnFilterString = filterString;
		} else {
			returnFilterString = placeholder || '';
		}
		return returnFilterString;
	};

	return (
		<>
			<TypeAheadSearchBar
				name={props.name}
				disabled={props.disabled}
				expand={false}
				error={error}
				onChange={applyOnChange}
				onBlur={field.onBlur}
				onClick={applyOnHandleClick}
				displayValue={displayValue()}
				isOpen={isOpen}
				placeHolder={props.label}
			/>
			<AnimatePresence>
				{isOpen && (
					<TypeAheadResultsList
						key={'TypeAheadResultsList'}
						dataId={`${props['data-id'] || 'list_'}_list`}
						dropUpSpace={'8px'}
						search={search}
						setPage={(newNumber) => setPage(newNumber)}
						page={page}
						filteredOptions={filteredOptions}
						setFilteredOptions={setFilteredOptions}
						display={display}
						handleSelect={handleSelect}
						selectedOptions={selectedOptions}
						searchFunction={searchData.searchFunction}
					/>
				)}
				<SelectedOptionsElementsContainer
					key={'SelectedOptionsElementsContainer'}
					selectedOptions={selectedOptions}
					handleSelect={handleSelect}
				/>
			</AnimatePresence>
		</>
	);
};

export { TypeAheadSearch };
