import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const Heading = styled(P1)(
	({ theme: { spacing, weight } }) => css`
		margin-top: ${spacing.lg};
		margin-bottom: 12px;
		font-weight: ${weight.medium};
	`
);

export const ButtonsContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: flex-end;
		margin-top: ${spacing.lg};
	`
);
