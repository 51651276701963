import { GradeMarks } from '@lh/qnr-svc-rest-client';

import { CellContext } from '@tanstack/react-table/';
import { t } from 'i18next';
import { css, styled } from 'styled-components';

import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { P1 } from 'components/shared/designSystem';
import { CheckmarkIcon } from 'components/table/icons/Checkmark';
import { CloseIcon } from 'components/table/icons/Close';

export const GDS_TABLE_COLUMNS = [
	{
		accessorKey: 'question',
		header: () => (
			<P1Med>
				{t(
					'web.report.gds.individualAnswersCard.questionColumnLabel'
				).toUpperCase()}
			</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const value = props.getValue<QnrRowData['question']>();
			const rows = props.table.getRowModel().rows;
			if (props.row.index === rows.length - 1)
				return <TotalLabel>{value}</TotalLabel>;

			return (
				<QuestionTableContainer>
					<SpanSemi>Q{props.row.index + 1}: </SpanSemi>
					{value}
				</QuestionTableContainer>
			);
		},
	},
	{
		accessorKey: 'answers',
		header: () => (
			<P1Med>
				{t(
					'web.report.gds.individualAnswersCard.selectionsColumnLabel'
				).toUpperCase()}
			</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const value = props.getValue<QnrRowData['answers']>();
			if ('totalScore' in value)
				return (
					<TotalScoreWrapper>
						<StyledContentContainer>
							<StyledIconContainer $positivelyAnswered={false}>
								<CloseIcon size='24px' />
							</StyledIconContainer>
							<P1Semi>{value.totalScore}</P1Semi>
						</StyledContentContainer>
					</TotalScoreWrapper>
				);
			// Icon Display
			const displayIcon = GRADE_ICONS[value.grade];

			return (
				<StyledContentContainer>
					<StyledIconContainer
						$positivelyAnswered={
							value.grade === GradeMarks.GREEN_CHECK
						}
					>
						{displayIcon}
					</StyledIconContainer>
					<P1>{value.answerText}</P1>
				</StyledContentContainer>
			);
		},
	},
];

const GRADE_ICONS = {
	[GradeMarks.RED_X]: <CloseIcon size='24px' />,
	[GradeMarks.GREEN_CHECK]: <CheckmarkIcon size='30px' />,
	[GradeMarks.NOT_GRADED]: null,
};

const StyledContentContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		padding-left: ${spacing.xs};
		padding-top: ${spacing.xs};
		padding-bottom: ${spacing.xs};
	`
);

const StyledIconContainer = styled.div<{ $positivelyAnswered: boolean }>(
	({ $positivelyAnswered }) => css`
		top: ${$positivelyAnswered && '-6px'};
		margin-right: 20px;
		position: relative;
		width: 17px;
		height: ${$positivelyAnswered && '17px'};
	`
);

const SpanSemi = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

const QuestionTableContainer = styled(P1)`
	margin: 10px 0;
`;

const TotalLabel = styled(P1)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		display: flex;
		justify-content: flex-end;
		margin: ${spacing.md} 0;
	`
);

const P1Semi = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.semi};
	`
);

const TotalScoreWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin: ${spacing.md} 0;
	`
);
