import {
	GetPaginatedSubjectsSortField,
	OperationToken,
} from '@lh/eng-platform-organization-service-rest-client';

import { Skeleton } from '@mantine/core';
import styled from 'styled-components';

import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { t } from 'i18n';
import { icons } from '../../../../enums/icons';
import { assignmentStatus as assignmentEnum } from '../../../../enums/participantEnums/assignmentStatus';
import { AssignmentStatus, SortDir } from '../../../../generated/graphql';
import {
	dateTimeFormatter,
	utcDateFormatter,
} from '../../../../stringStrategy/dateFormatStringStrategy';
import { AvatarName } from '../../avatar/AvatarName';
import { ButtonSm } from '../../designSystem/ButtonSm';
import { getBrowserLanguage } from '../../getLanguage';
import { TableColumn, TableColumnOption } from '../DataTable';
import { Kebab } from '../columnComponents/Kebab';

export type TableData = {
	id: string;
	avatarUrl?: string;
	firstName: string;
	lastName: string;
	patientExternalId: string | null;
	batteryResults?: [AssignmentStatus];
	birthDate: string;
	lastCompletedBattery?: string | null;
	assignmentStatus?: string | null;
	// Need unique battery ID to pass to callback within Kebab
	currentAssignmentId?: string;
};

export type AddBatteryProps = {
	id: string;
	firstName: string;
	lastName: string;
	newPatient?: boolean;
};

export type RemoveBatteryProps = {
	id: string;
	firstName: string;
	lastName: string;
	currentAssignmentId?: string;
};

const hasCurrentAssignment = (status: string | undefined): boolean => {
	return (
		!!status &&
		status !== AssignmentStatus.Deleted &&
		status !== AssignmentStatus.Complete
	);
};

const columns = (
	dateFormat: string,
	defaultTimezone: string,
	columnOptions?: TableColumnOption<TableData>[],
	addBatteryCallback?: (x: AddBatteryProps) => void
): TableColumn<TableData>[] => {
	return [
		{
			propertyName: 'name',
			headerDisplay: t('web.patients.name'),
			sortable: true,
			sortProperty: GetPaginatedSubjectsSortField.FullName,
			minWidth: '290px',
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as TableData;
				return (
					<AvatarName
						firstName={rowDataType.firstName}
						lastName={rowDataType.lastName}
						patientExternalId={rowDataType.patientExternalId}
						avatarUrl={rowDataType.avatarUrl}
						linkTo={`/patients/${rowDataType.id}`}
					/>
				);
			},
			sortDir: SortDir.Asc,
		},
		{
			propertyName: 'birthDate',
			headerDisplay: t('web.patients.dateOfBirth'),
			sortable: true,
			sortProperty: GetPaginatedSubjectsSortField.BirthDate,
			minWidth: '186px',
			formatProperty: ({ value }) => {
				return utcDateFormatter(value as string, dateFormat);
			},
		},
		{
			propertyName: 'lastCompletedBattery',
			headerDisplay: t('web.patients.lastCompletedBattery'),
			sortable: true,
			sortProperty: GetPaginatedSubjectsSortField.EndTime,
			minWidth: '324px',
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as TableData;
				if (rowDataType.lastCompletedBattery === null)
					return <Skeleton radius='sm' w='100%' h={24} />;
				const lastCompletedBattery =
					rowDataType.lastCompletedBattery || '';
				const formattedDate = dateTimeFormatter(
					lastCompletedBattery,
					dateFormat || 'MM/dd/yyyy',
					getBrowserLanguage(),
					defaultTimezone
				);
				return !lastCompletedBattery ? (
					<>{t(`web.shared.none`)}</>
				) : (
					<>{formattedDate}</>
				);
			},
		},
		{
			propertyName: 'assignmentStatus',
			headerDisplay: t('web.patients.batteryStatus'),
			sortable: true,
			sortProperty: GetPaginatedSubjectsSortField.AssignmentStatus,
			minWidth: '192px',
			minHeight: '72px',
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as TableData;
				if (rowDataType.assignmentStatus === null)
					return <Skeleton radius='xl' w='100%' h={42} />;
				return !hasCurrentAssignment(rowDataType.assignmentStatus) ? (
					<ShowIfAuthorized
						operations={[OperationToken.AssignBattery]}
					>
						<ButtonSm
							onClick={() => {
								return (
									addBatteryCallback &&
									addBatteryCallback({
										firstName: rowDataType.firstName,
										lastName: rowDataType.lastName,
										id: rowDataType.id,
										newPatient: false,
									})
								);
							}}
							text={t('web.patients.forms.addBattery')}
							IconLeft={icons.AddOutlined}
						/>
					</ShowIfAuthorized>
				) : (
					<StyledBatteryStatus
						$isIncomplete={
							rowDataType.assignmentStatus ===
							AssignmentStatus.Started
						}
					>
						{t(
							assignmentEnum.fromValue(
								rowDataType?.assignmentStatus
							)?.display || ''
						)}
					</StyledBatteryStatus>
				);
			},
		},
		{
			propertyName: '',
			sortable: false,
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ row, column }) => {
				return <Kebab row={row} column={column} />;
			},
			options: columnOptions,
		},
	];
};

type StyledBatteryStatusProps = {
	$isIncomplete: boolean;
};

const StyledBatteryStatus = styled.div<StyledBatteryStatusProps>(
	({ theme: { color }, $isIncomplete }) => `
	color: ${$isIncomplete && color.textAlert}};
	`
);

export { columns };
