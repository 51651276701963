import { Group, Stack } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import { useRef } from 'react';
import { t } from 'i18n';

import { theme } from 'components/providers/StyleProvider/theme';
import { Icon } from 'components/shared/designSystem';
import { P1 } from 'components/shared/designSystem/TextComponents';
import { icons } from 'enums/icons';

import { ExternalLink, List } from '../../../Pathway.misc';
import { Accordion } from '../../../../../../shared/Accordion';
import { Reference } from '../ReferencesModal.types';

interface Props {
	opened?: boolean;
	reference: Reference;
}

export function ReferenceItem({ opened, reference }: Props) {
	const { title, articles, content } = reference;

	const ref = useRef<HTMLDivElement>(null);

	/**
	 * Scroll into view delayed if opened
	 */
	useTimeout(() => opened && scrollIntoView(), 200, { autoInvoke: true });

	function scrollIntoView() {
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	}

	return (
		<Accordion
			ref={ref}
			opened={opened}
			title={
				<Stack gap={0} align='flex-start' data-testid='reference-item'>
					<P1
						data-testid='title'
						style={{
							color: theme.colors.gray_10,
							fontWeight: theme.weight.medium,
						}}
					>
						{title}
					</P1>
					{articles.map((article) => (
						<P1 key={article.title} data-testid='external-link'>
							<ExternalLink
								to={article.url}
								isZendeskUrl={article.isZendeskUrl}
								fromReferencesModal
							>
								<Group gap={4} wrap='nowrap'>
									<Icon
										icon={icons.ExternalLink}
										color={theme.colors.purple_dark}
									/>
									{article.title}
								</Group>
							</ExternalLink>
						</P1>
					))}
				</Stack>
			}
		>
			<Stack gap={16} data-testid='reference-item'>
				<P1
					style={{
						color: theme.colors.gray_10,
						fontWeight: theme.weight.medium,
					}}
				>
					{t('cds.referencesModal.additionalReferences')}
				</P1>
				<P1 style={{ color: theme.colors.gray_30 }}>
					<List type='ordered' listStyleType='decimal' gap={8}>
						{content.map((value) => (
							<List.Item key={value}>{value}</List.Item>
						))}
					</List>
				</P1>
			</Stack>
		</Accordion>
	);
}
