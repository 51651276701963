import React from 'react';
import styled, { css } from 'styled-components';
import { H2, P1 } from './designSystem/TextComponents';
import Modal from 'styled-react-modal';
import { icons } from '../../enums/icons';
import { Icon } from './designSystem/Icon';
import { EnumItem } from '../../enums/enumeration';
import { useTranslation } from 'react-i18next';
import { fromStyledToCSS } from 'utils/stringUtils';

export interface ModalProps {
	children: React.ReactNode;
	onClose?: () => void;
	title?: string;
	titleIcon?: EnumItem;
	titleIconColor?: string;
	subTitle?: string;
	hideCloseButton?: boolean;
	width?: string;
	dataId?: string;
	isThirdPartyManaged?: boolean;
	customScroll?: boolean;
	style?: React.CSSProperties;
}

const LinusModal = ({
	children,
	onClose,
	title,
	titleIcon,
	titleIconColor,
	subTitle,
	hideCloseButton,
	width,
	dataId,
	isThirdPartyManaged,
	customScroll,
	style,
}: ModalProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledModal
			isOpen={true}
			onBackgroundClick={onClose}
			onEscapeKeydown={onClose}
			theme={{
				overridingStyles: style,
			}}
		>
			<StyledModalWrapper
				$customScroll={customScroll}
				width={width}
				data-id={
					dataId
						? dataId
						: title?.toLowerCase().replace(/\s/g, '_') + '_modal'
				}
			>
				<StyledHeaderSection>
					<StyledTitle
						data-id={
							dataId
								? dataId + '-linus-modal-title'
								: 'linus-modal-title'
						}
					>
						{!hideCloseButton && (
							<StyledIconWrapper onClick={onClose}>
								<Icon
									dataId={
										dataId
											? dataId + '-linus-modal-close-icon'
											: 'linus-modal-close-icon'
									}
									icon={icons.Close}
									title={t`web.shared.closeModal`}
								/>
							</StyledIconWrapper>
						)}
						<StyledTextRow
							data-id={
								dataId
									? dataId + '-linus-modal-text-row'
									: 'linus-modal-text-row'
							}
						>
							{titleIcon && (
								<StyledTitleIcon>
									<Icon
										dataId={
											dataId
												? dataId +
												  '-linus-modal-title-icon'
												: 'linus-modal-title-icon'
										}
										icon={titleIcon}
										color={titleIconColor}
										iconWidth={40}
										iconHeight={40}
									/>
								</StyledTitleIcon>
							)}
							{title && (
								<StyledH2
									data-id={
										dataId
											? dataId + '-linus-modal-text'
											: 'linus-modal-text'
									}
								>
									{title}
								</StyledH2>
							)}
							{isThirdPartyManaged && (
								<SSOTag>{t`web.shared.ssoUser`}</SSOTag>
							)}
						</StyledTextRow>
					</StyledTitle>
					{subTitle && (
						<StyledSubTitle
							data-id={dataId ? dataId + '-subtitle' : 'subtitle'}
						>
							{subTitle}
						</StyledSubTitle>
					)}
				</StyledHeaderSection>
				{children}
			</StyledModalWrapper>
		</StyledModal>
	);
};

export { LinusModal };

type ModalStyleProps = {
	theme: {
		overridingStyles: React.CSSProperties;
	};
};

const StyledModal = Modal.styled((props: ModalStyleProps) => {
	const overridingStyles = fromStyledToCSS(
		props?.theme?.overridingStyles || {}
	);
	return `
			max-width: 100%;
			max-height: 95vh;
			overflow-y: auto;
			background-color: white;
			border-radius: 10px 10px 10px 10px;
			padding: 32px;
			box-sizing: border-box;
			position:relative;
			display: flex;
			flex-direction: column;
			${overridingStyles}
		`;
});

type StyledModalWrapperProps = {
	$customScroll?: boolean;
	width?: string;
};

const StyledModalWrapper = styled.div<StyledModalWrapperProps>(
	({ $customScroll, width }) => css`
		width: ${width ?? '100%'};
		display: flex;
		flex-direction: column;
		overflow-y: ${$customScroll ? 'hidden' : 'visible'};
	`
);
const StyledH2 = styled(H2)(
	({ theme: { weight } }) => `
	position: relative;
	font-weight: ${weight.medium};
`
);
const StyledIconWrapper = styled.div(
	({ theme: { spacing } }) => `
	position: absolute;
	top: ${spacing.md};
	right: ${spacing.md};
	width: ${spacing.xl};
	height: ${spacing.lg};
	&:hover {
		cursor: pointer;
	}
`
);

const StyledHeaderSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
`;

const StyledTitle = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledTextRow = styled.div`
	display: flex;
	position: relative;
	margin-right: 50px;
`;

const StyledSubTitle = styled(P1)(
	({ theme: { spacing } }) => `
	padding-top: ${spacing.sm};
	margin: 0 0 0 0;
`
);

const StyledTitleIcon = styled.div(
	({ theme: { spacing } }) => `
  padding-right:${spacing.md}
`
);

export const SSOTag = styled.span(
	({ theme: { color, fontSize, spacing, weight } }) => css`
		background-color: ${color.menuHoverBg};
		padding: ${spacing.xs} ${spacing.sm};
		border-radius: 30px;
		width: auto;
		height: 22px;
		align-self: center;
		font-weight: ${weight.medium};
		font-size: ${fontSize._12};
		line-height: 14px;
		margin-left: ${spacing.md};
	`
);
