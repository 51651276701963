import { config } from './config';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = (): void => {
	try {
		if (config.nodeEnv !== 'ci') {
			Sentry.init({
				dsn: config.sentryDSN,
				integrations: [new BrowserTracing()],
				environment: config.applicationEnv,
				// We recommend adjusting this value in production
				tracesSampleRate: 1.0,
			});
		}
	} catch (e) {
		throw new Error('Error instantiating Sentry SDK');
	}
};
