import styled from 'styled-components';
import { H2 } from '../../../shared/designSystem/TextComponents';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../../shared/designSystem/Icon';
import { useTranslation } from 'react-i18next';

type PageHeaderProps = {
	title: string;
};

export const PrintPageHeader = ({ title }: PageHeaderProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<StyledSpacer>
			<StyledWrapper>
				<H2>{title}</H2>
				<Icon
					icon={icons.Logo}
					title={t`web.dcrReport.print.pageHeader.logo.title`}
				/>
			</StyledWrapper>
		</StyledSpacer>
	);
};

const StyledSpacer = styled.div(
	({ theme: { spacing } }) => `
    margin-top: ${spacing.xl};
    display: none;
    @media print {
      display: block;
	    break-before: page;
    }
`
);
const StyledWrapper = styled.div(
	({ theme: { spacing } }) => `
		margin-left: ${spacing.lg};
		margin-right: ${spacing.lg};
		display: flex;
		justify-content: space-between;
		vertical-align: center;
		height: 70px;
	`
);
