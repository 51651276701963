import { Typography } from '@lh/eng-web-mosaic-common';

import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { theme } from 'components/providers/StyleProvider/theme';

interface BreadcrumbProps {
	breadcrumbs: Breadcrumb[];
}

type Breadcrumb = {
	text: string;
	link?: string;
	onLinkClick?: () => void;
};

export function Breadcrumb({ breadcrumbs }: Readonly<BreadcrumbProps>) {
	return (
		<BreadcrumbContainer>
			{breadcrumbs.map(({ text, link, onLinkClick }, i) => {
				return (
					<React.Fragment key={text}>
						{link ? (
							<StyledLink
								data-testid='breadcrumb-link-text'
								to={link}
								onClick={onLinkClick}
							>
								{text}
							</StyledLink>
						) : (
							<Typography.P1
								color={theme.color.bodyTextSecondary}
							>
								{text}
							</Typography.P1>
						)}
						{i !== breadcrumbs.length - 1 && (
							<Typography.P1
								weight='500'
								color={theme.color.bodyTextSecondary}
							>
								&nbsp;/&nbsp;
							</Typography.P1>
						)}
					</React.Fragment>
				);
			})}
		</BreadcrumbContainer>
	);
}

const BreadcrumbContainer = styled.div`
	display: flex;
	align-items: center;
`;

const StyledLink = styled(Link)(
	({ theme: { colors, weight } }) => css`
		color: ${colors.purple_dark};
		font-weight: ${weight.medium};
	`
);
