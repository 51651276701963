import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useTranslation } from 'react-i18next';

import { FluencyLayout } from '../FluencyLayout';
import { useSemanticFluencyData } from './useSemanticFluencyData';

type SemanticLayoutProps = {
	segmentResult: DeepSegmentResult;
};

export function SemanticLayout({
	segmentResult,
}: Readonly<SemanticLayoutProps>) {
	const data = useSemanticFluencyData(segmentResult);
	const { t } = useTranslation();

	return (
		<FluencyLayout
			{...data}
			title={t('web.report.fluency.semantic.title')}
			tooltipText={t('web.report.fluency.semantic.description', {
				returnObjects: true,
			})}
		/>
	);
}
