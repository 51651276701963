import styled, { css } from 'styled-components';

import { H2 } from 'components/shared/designSystem';

export const ScoreInterpretationContainer = styled.div(
	({ theme: { spacing, colors } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.sm};
		padding: ${spacing.md};
		/* translates to opacity of 20% over white backgrounds */
		background-color: ${colors.gray_70}33;
		width: 400px;
		border-radius: 8px;
	`
);

export const ScoreContainer = styled.div(
	({ theme: { spacing, colors } }) => css`
		padding: ${spacing.xs} 12px;
		background-color: ${colors.gray_70};
		border-radius: 8px;
	`
);

export const H2Med = styled(H2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);
