import { Subject } from '@lh/eng-platform-subject-service-rest-client';

import { Trans } from 'react-i18next';

import { P1 } from 'components/shared/designSystem';
import i18n from 'i18n';
import { RELATIONSHIP_TO_PATIENT } from '../../../patients/CarePartner/CarePartner.consts';
import { CarePartnerType } from '../../../patients/CarePartner/CarePartner.types';

import { EMPTY_INPUTS } from './ControllModal.consts';
import { InitialValues, InputProps } from './ControllModal.types';

export const INPUT_RAW_DATA: InputProps<string>[] = [
	{
		name: 'firstName',
		label: i18n.t('web.patients.forms.firstNameLabel'),
	},
	{
		name: 'lastName',
		label: i18n.t('web.patients.forms.lastNameLabel'),
	},
	{
		name: 'contactEmail',
		label: i18n.t('web.patients.carePartner.form.email'),
		type: 'email',
	},
	{
		name: 'contactPhone',
		label: i18n.t('web.patients.carePartner.form.phone'),
		type: 'contactPhone',
	},
	{
		name: 'patientRelationship',
		label: i18n.t('web.patients.carePartner.form.relationship.title'),
		type: 'select',
		dropdownOptions: RELATIONSHIP_TO_PATIENT,
		dropUpSpace: '8px',
	},
];

export function getModalInfo(type: CarePartnerType, patient?: Subject) {
	if (type === CarePartnerType.Add) {
		const firstName = patient?.firstName ?? '';
		const lastName = patient?.lastName ?? '';

		return {
			title: i18n.t('web.patients.carePartner.add.cta'),
			info: (
				<P1
					data-id='cta-care-partner-modal-info'
					data-testid='cta-care-partner-modal-info'
				>
					<Trans
						i18nKey='web.patients.carePartner.add.info'
						components={{
							b: <b />,
						}}
						values={{
							fullNamePatient: `${firstName} ${lastName}`,
						}}
					/>
				</P1>
			),
			ctaText: i18n.t('web.patients.carePartner.add.cta'),
		};
	}

	return {
		title: i18n.t('web.patients.carePartner.edit.cta'),
		info: (
			<P1
				data-id='care-partner-modal-info'
				data-testid='care-partner-modal-info'
			>
				{i18n.t('web.patients.carePartner.edit.info')}
			</P1>
		),
		ctaText: i18n.t('web.patients.forms.saveCTA'),
	};
}

export function getInitialValues(
	patientInfo: Subject,
	partnerInfo?: Subject
): InitialValues {
	if (!partnerInfo) return EMPTY_INPUTS;

	const relationship = patientInfo.relationships.find(
		(relationship) => relationship.relatedSubjectId === partnerInfo.id
	);

	return {
		...partnerInfo,
		firstName: partnerInfo.firstName ?? '',
		lastName: partnerInfo.lastName ?? '',
		contactEmail: partnerInfo.contactEmail ?? '',
		contactPhone: partnerInfo.contactPhone ?? '',

		// validation schema will take care of the empty case
		patientRelationship: relationship?.type ?? '',
	};
}
