import { EnumItem } from '../../../../enums/enumeration';
import styled from 'styled-components';
import { Icon } from '../../../shared/designSystem/Icon';
import { Caption, P2 } from '../../../shared/designSystem/TextComponents';
import { useTranslation } from 'react-i18next';
import { icons, Icons } from '../../../../enums/icons';

type PillarToolTipProps = {
	pillar: EnumItem;
};

export const PillarToolTip = ({ pillar }: PillarToolTipProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledIconKeyContainer>
			<StyledIconTitleWrapper>
				<Icon icon={icons[`${pillar.key}LHQ` as keyof Icons]} />
				<StyledTitle>{t(pillar.display || '')}</StyledTitle>
			</StyledIconTitleWrapper>
			<StyledDescription>
				{t(`web.report.basicActionPlan.${pillar.key}.tooltip`)}
			</StyledDescription>
		</StyledIconKeyContainer>
	);
};

const StyledIconKeyContainer = styled.div`
	display: inline-block;
`;

const StyledIconTitleWrapper = styled.div`
	display: flex;
	height: 32px;
	align-items: center;
`;

const StyledTitle = styled(Caption)(
	({ theme: { weight, color, spacing } }) => `
		font-weight: ${weight.medium};
		line-height: 13.5px;
		letter-spacing: 0.2px;
		text-transform: uppercase;
		color: ${color.headerTextSecondary};
		padding-left: ${spacing.xxs}
	`
);

const StyledDescription = styled(P2)(
	({ theme: { color, spacing } }) => `
		color: ${color.bodyTextSecondary};
		padding-top: ${spacing.xs}
	`
);
