import { config } from '../config';

export const AuthProvider = {
	isAuth0: config.preferredUserProvider === 'auth0',
	isCognito: config.preferredUserProvider === 'cognito',
	getClientId() {
		if (this.isCognito) {
			return;
		}

		return config.auth0ClientId;
	},
};
