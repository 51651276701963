import {
	GetPaginatedSubjectsFilterField,
	GetPaginatedSubjectsFilterOperator,
	GetPaginatedSubjectsLogicalOperator,
	GetPaginatedSubjectsSortField,
	GetPaginatedSubjectsSortOrder,
} from '@lh/eng-platform-organization-service-rest-client';

import { Sort } from './Patients.types';

export function getParams(search: string, sort: Sort | null, page: number) {
	let filterField: GetPaginatedSubjectsFilterField[] = [];
	let filterOperator: GetPaginatedSubjectsFilterOperator[] = [];
	let filterValue: string[] = [];
	let logicalOperator: GetPaginatedSubjectsLogicalOperator[] = [];
	let sortField = [GetPaginatedSubjectsSortField.CreatedAt];
	let sortOrder = [GetPaginatedSubjectsSortOrder.Desc];

	if (search.length) {
		// Doubled search because it can be either fullName or externalId
		filterField = [
			GetPaginatedSubjectsFilterField.FullName,
			GetPaginatedSubjectsFilterField.ExternalId,
		];
		filterOperator = [
			GetPaginatedSubjectsFilterOperator.Ilike,
			GetPaginatedSubjectsFilterOperator.Ilike,
		];
		filterValue = [search, search];
		logicalOperator = [GetPaginatedSubjectsLogicalOperator.Or];
	}

	if (sort) {
		sortField = sort.field;
		sortOrder = sort.order;
	}

	return {
		sortField,
		sortOrder,
		filterField,
		filterOperator,
		filterValue,
		logicalOperator,
		page: page - 1,
	};
}
