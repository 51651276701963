import { theme } from '@lh/eng-web-mosaic-common';

import { Flex, Grid, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import {
	AdjustedScore,
	AudioPlaybackCard,
	ReportInfoPanel,
	ScoringDetailsCta,
} from 'components/mosaic';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';

import { CutoffsScoringDetailsModal } from '../../CutoffsScoringDetailsModal';

import { FluencyBreakdown } from './FluencyBreakdown';
import {
	CaptionFaded,
	FluencyLayoutContainer,
	Header,
	Section,
} from './FluencyLayout.style';
import {
	FluencyAudioRecording,
	FluencyPerformance,
	FluencyScoreBreakdown,
} from './FluencyLayout.types';
import { FluencyScore } from './FluencyScore';

export type FluencyLayoutProps = {
	breakdown: FluencyScoreBreakdown;
	isEnglish: boolean;
	recording: FluencyAudioRecording;
	performance: FluencyPerformance;
	title: string;
	tooltipText: string;
};

export function FluencyLayout({
	breakdown,
	isEnglish,
	performance,
	recording,
	title,
	tooltipText,
}: Readonly<FluencyLayoutProps>) {
	const [opened, { open: openModal, close: closeModal }] =
		useDisclosure(false);
	const { t } = useTranslation();

	const hidePercentile = useFeatureFlag(FeatureFlags.HideFluencyPercentile);
	const showInterpretation = hidePercentile ? isEnglish : true;

	const requiresReview = performance.score.value === null;
	const scoreValue = performance.score.value ?? '--';

	return (
		<FluencyLayoutContainer data-testid='fluency-layout'>
			<ReportInfoPanel title={title} tooltipText={tooltipText}>
				<Flex gap={theme.spacing.md}>
					<Stack data-testid='fluency-score' gap={12}>
						<FluencyScore
							performance={performance}
							requiresReview={requiresReview}
							scoreFor={title}
							scoreValue={scoreValue}
							showInterpretation={showInterpretation}
						/>
						{performance.score.adjusted && <AdjustedScore />}
						{performance.cutoff && (
							<ScoringDetailsCta onClick={openModal} />
						)}
					</Stack>
					<FluencyBreakdown
						breakdown={breakdown}
						requiresReview={requiresReview}
					/>
				</Flex>
			</ReportInfoPanel>
			<Section data-testid='recordings'>
				<Header>{t('web.shared.recordings')}</Header>
				<Grid gutter={24}>
					<Grid.Col span={6}>
						<AudioPlaybackCard
							audioSource={recording.url}
							cardTitle={title}
						>
							{`${t('web.shared.prompt')}: ${recording.prompt}`}
						</AudioPlaybackCard>
					</Grid.Col>
				</Grid>
			</Section>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
			{performance.cutoff && (
				<CutoffsScoringDetailsModal
					ageRange={performance.cutoff.ageRange}
					cutoff={performance.cutoff.value}
					open={opened}
					onClose={closeModal}
				/>
			)}
		</FluencyLayoutContainer>
	);
}
