import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { OrganizationStatus } from '@lh/eng-platform-organization-service-rest-client';

import { Organization } from 'components/context/useSwitchOrganization';
import { theme } from 'components/providers/StyleProvider/theme';
import { Icon } from 'components/shared/designSystem/Icon';
import { P1 } from 'components/shared/designSystem/TextComponents';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { icons } from 'enums/icons';

type SingleOrganizationProps = {
	organization: Organization;
	onSelect: (organization: Organization) => void;
	onSetDefault: (orgId: string) => void;
	bottomSeparator?: boolean;
};

type OrganizationContainerProps = {
	$bottomSeparator?: boolean;
};

function SingleOrganization({
	organization,
	onSelect,
	onSetDefault,
	bottomSeparator,
}: SingleOrganizationProps) {
	const { color } = useTheme();
	const { t } = useTranslation();

	const handleSwitchOrganization = () => onSelect(organization);
	const isTestOrg = organization.status === OrganizationStatus.Test;

	return (
		<OrganizationContainer
			key={organization.id}
			$bottomSeparator={bottomSeparator}
		>
			<OrganizationContent onClick={handleSwitchOrganization}>
				<NameText data-testid='name-container'>
					<StarContainer
						isDefault={organization.isDefault}
						color={color}
						onClick={() => onSetDefault(organization.id)}
					/>
					<StyledOrganizationNameContainer>
						<div data-testid='organization-name'>
							{organization.name}
						</div>
						{isTestOrg ? (
							<StyledOrganizationLabels data-testid='organizations-label'>
								{isTestOrg ? <TestLabel /> : null}
							</StyledOrganizationLabels>
						) : null}
					</StyledOrganizationNameContainer>
				</NameText>
				<ViewButton>
					{t`research.switchOrganizations.view`}
					<Icon
						icon={icons.TraditionalArrowRight}
						color={color.textBlue}
					/>
				</ViewButton>
			</OrganizationContent>
		</OrganizationContainer>
	);
}

export default SingleOrganization;

function TestLabel() {
	const { color } = useTheme();
	const { t } = useTranslation();

	return (
		<StyledTestLabelContainer data-testid='test-label-container'>
			<Icon icon={icons.TestGears} color={color.activeText} />
			<StyledTstLbl>{t`web.switchOrgModal.testLabel`}</StyledTstLbl>
		</StyledTestLabelContainer>
	);
}

function StarContainer({
	isDefault,
	color,
	onClick,
}: {
	isDefault?: boolean;
	color: Record<string, string>;
	onClick: () => void;
}) {
	const { t } = useTranslation();

	function handleOnClick(e: any) {
		e.stopPropagation();
		onClick();
	}

	if (isDefault) {
		return (
			<Icon
				icon={icons.Star}
				color={theme.color.iconYellow}
				width={24}
				height={24}
			/>
		);
	}
	return (
		<LinusTooltip
			placement='bottomLeft'
			overlayStyle={{
				position: 'fixed',
				zIndex: 1000,
				padding: '15px 5px',
				borderRadius: '6px',
				backgroundColor: color.formText,
				color: color.white,
			}}
			align={{
				offset: [-10, 10],
			}}
			overlay={
				<StyledTooltip>
					{t`web.switchOrgModal.makeDefault`}
				</StyledTooltip>
			}
			tooltipIcon={
				<Icon
					onClick={handleOnClick}
					data-testid='organization-default-icon'
					icon={icons.StarOutlined}
					color={theme.color.iconBadge}
					width={24}
					height={24}
				/>
			}
		/>
	);
}

const StyledOrganizationNameContainer = styled.div(
	() => css`
		margin-left: 14px;
	`
);

const OrganizationContainer = styled.div<OrganizationContainerProps>(
	({ $bottomSeparator, theme: { color } }) => css`
		padding: 6px 0 6px 0;
		border-bottom: 1px solid
			${$bottomSeparator ? color.strongSeparator : color.infoSeparator};
	`
);

const OrganizationContent = styled.div(
	({ theme: { color } }) => css`
		display: flex;
		justify-content: space-between;
		padding: 18px 14px 18px 14px;
		&:hover {
			background-color: ${color.kebabLinkHover};
			cursor: pointer;
		}
	`
);

const StyledOrganizationLabels = styled.div(
	() => css`
		display: flex;
	`
);

const StyledTestLabelContainer = styled.div(
	({ theme: { spacing, fontSize, color, borderRadius } }) => css`
		width: fit-content;
		display: inline-flex;
		background: ${color.testOrgLblBckgroundColor};
		padding: ${spacing.xs};
		padding-left: ${spacing.sm};
		padding-right: ${spacing.sm};
		border-radius: ${borderRadius.btn};
		margin-top: ${spacing.xs};
		svg {
			width: ${fontSize._14};
			height: ${fontSize._14};
		}
	`
);

const StyledTstLbl = styled.div(
	({ theme: { spacing, fontSize, weight, color } }) => css`
		font-size: ${fontSize._12};
		margin-left: ${spacing.xs};
		font-weight: ${weight.medium};
		color: ${color.testOrgLblTxtColor};
		letter-spacing: 0.2px;
		text-transform: uppercase;
	`
);

const NameText = styled(P1)`
	display: flex;
	align-items: center;
`;

const ViewButton = styled.div(
	({ theme: { spacing, fontSize, weight, color } }) => css`
		display: flex;
		cursor: pointer;
		font-size: ${fontSize._14};
		font-weight: ${weight.semi};
		color: ${color.textBlue};
		display: flex;
		justify-content: center;
		align-items: center;

		div {
			height: fit-content;
		}

		svg {
			margin-left: ${spacing.sm};
			width: 24px;
			height: 24px;
		}
	`
);

const StyledTooltip = styled.div(
	({ theme: { fontSize, color } }) => css`
		font-size: ${fontSize._14};
		line-height: 17px;
		padding: 0 10px;
		&::before {
			content: '';
			position: absolute;
			top: -7px;
			height: 14px;
			width: 14px;
			border-top-left-radius: 3px;
			transform: rotate(45deg);
			background-color: ${color.formText};
		}
	`
);
