import { Flex } from '@mantine/core';

import { theme } from 'components/providers/StyleProvider/theme';
import { Caption } from 'components/shared/designSystem/TextComponents';

interface StyleProps {
	striped?: boolean;
	withRowBorders?: boolean;
}

interface Props<C, D> extends StyleProps {
	columns: C[];
	data: D[];
	renderColumn(column: C, index: number): React.ReactNode;
	renderRow(row: D, index: number): React.ReactNode;
}

export function Table<C, D>({
	columns,
	data,
	renderColumn,
	renderRow,
	// style
	striped = true,
	withRowBorders,
}: Props<C, D>) {
	return (
		<Flex
			className='pathway-table'
			style={{
				width: '100%',
				overflowX: 'auto',

				...(striped && {
					'tr:nth-of-type(odd)': {
						backgroundColor: theme.colors.gray_90,
					},
				}),
				...(withRowBorders && {
					'tr:not(:last-of-type) td': {
						borderBottom: `1px solid ${theme.colors.gray_60}`,
					},
				}),
			}}
		>
			<table width='100%'>
				<thead>
					<tr
						style={{
							backgroundColor: theme.colors.blue_ultraLight,
						}}
					>
						{columns.map(renderColumn)}
					</tr>
				</thead>
				<tbody>{data.map(renderRow)}</tbody>
			</table>
		</Flex>
	);
}

function Row({ children }: { children: React.ReactNode }) {
	return <tr className='pathway-table-row'>{children}</tr>;
}

function Column({ children }: { children: React.ReactNode }) {
	return (
		<th
			className='pathway-table-column'
			style={{
				padding: 8,
				borderBottom: `1px solid ${theme.colors.gray_60}`,
			}}
		>
			<Caption
				style={{
					color: theme.colors.gray_10,
					fontWeight: theme.weight.medium,
				}}
			>
				<div>{children}</div>
			</Caption>
		</th>
	);
}

function Cell({
	children,
	...props
}: { children: React.ReactNode } & React.DetailedHTMLProps<
	React.TdHTMLAttributes<HTMLTableCellElement>,
	HTMLTableCellElement
>) {
	return (
		<td
			className='pathway-table-cell'
			style={{ padding: 8, verticalAlign: 'top' }}
			{...props}
		>
			{children}
		</td>
	);
}

Table.Row = Row;
Table.Column = Column;
Table.Cell = Cell;
