import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { Divider } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { StyledCard } from 'components/shared/StyledCard';
import { Icon } from 'components/shared/designSystem';
import { Caption, H4, P1 } from 'components/shared/designSystem/TextComponents';
import { CheckmarkIcon } from 'components/table/icons/Checkmark';
import { icons } from 'enums/icons';

import { GDS_TABLE_COLUMNS } from './IndividualAnswersCard.table';

type IndividualAnswersCardProps = {
	resultData: QnrRowData[];
};

export const IndividualAnswersCard = ({
	resultData,
}: IndividualAnswersCardProps) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<PaddedStyledCard>
			<StyledIAHeadingContainer>
				<StyledIAHeading>
					<H4Med>
						{t('web.report.gds.individualAnswersCard.title')}
					</H4Med>
					<StyledMiniLegend>
						<StyledScoreLegend color={theme.color.textAlert}>
							<Icon
								icon={icons.Close}
								color={theme.color.textAlert}
								iconWidth={12}
								iconHeight={12}
							/>
							&nbsp;
							{t(
								'web.report.gds.individualAnswersCard.scoreOnePoint'
							)}
						</StyledScoreLegend>
						<StyledScoreLegend
							color={theme.color.textSuccessContrast}
						>
							<CheckmarkIcon size={'24px'} />
							&nbsp;
							{t(
								'web.report.gds.individualAnswersCard.scoreZeroPoints'
							)}
						</StyledScoreLegend>
						<StyledNavigationNote>
							{t(
								'web.report.gds.individualAnswersCard.totalScoreNavigationNote'
							)}
						</StyledNavigationNote>
					</StyledMiniLegend>
				</StyledIAHeading>
				<P1>
					<Trans
						i18nKey='web.report.gds.individualAnswersCard.subtitle'
						components={{ b: <Medium /> }}
					/>
				</P1>
			</StyledIAHeadingContainer>
			<div>
				<StyledTable
					verticalSpacing={6}
					horizontalSpacing={theme.spacing.sm}
					columns={GDS_TABLE_COLUMNS}
					data={resultData}
				/>
				<Divider color={theme.color.infoSeparator} />
			</div>
			<StyledEndLabel>
				{t('web.report.gds.individualAnswersCard.endOfQuestionnaire')}
			</StyledEndLabel>
		</PaddedStyledCard>
	);
};

const PaddedStyledCard = styled(StyledCard)(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: column;
			gap: ${spacing.md};
			padding: ${spacing.lg};
		`
);

const StyledIAHeadingContainer = styled(Caption)(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

const StyledIAHeading = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: row;
		gap: ${spacing.sm};
		align-items: center;
		flex-wrap: wrap;
	`
);

const StyledMiniLegend = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: ${spacing.sm} ${spacing.md};
			flex-wrap: wrap;
		`
);

const StyledScoreLegend = styled(Caption)<{ color: string }>(
	({ color }) =>
		css`
			display: flex;
			flex-direction: row;
			align-items: center;
			color: ${color};
		`
);

const StyledNavigationNote = styled(Caption)(
	({ theme: { color } }) =>
		css`
			color: ${color.bodyTextSecondary};
		`
);

const Medium = styled.span(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

const StyledEndLabel = styled(P1)(
	({ theme: { color } }) => css`
		display: flex;
		flex-direction: row;
		justify-content: center;
		color: ${color.bodyTextSecondary};
	`
);

const H4Med = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

const StyledTable = styled(SimpleTable<QnrRowData>)(
	({ theme }) => css`
		tbody > tr:last-child {
			background-color: white;
			border-top: 1px solid ${theme.colors.gray_60};
		}
	`
);
