import {
	DefaultApiGetUsersRequest,
	User,
} from '@lh/eng-platform-organization-service-rest-client';

import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getUsers(
	searchInput: DefaultApiGetUsersRequest,
	client: QueryClient,
	signal?: AbortSignal
) {
	if (!searchInput.organizationId) {
		throw new Error(
			"useGetPaginatedUsersQuery: organizationId can't be null"
		);
	}

	const service = await getOrganizationService();
	const { data } = await service.getUsers(searchInput, { signal });

	data?.results.forEach((user) => {
		client.setQueryData<User>(
			[QueryKey.User, searchInput.organizationId, user.id],
			user
		);
	});

	return data;
}

export function useUsers(searchInput: DefaultApiGetUsersRequest) {
	const client = useQueryClient();

	return useQuery({
		enabled: !!searchInput.organizationId,
		meta: {
			errorMessage: `Error fetching paginated users for organizationId: ${searchInput.organizationId}`,
		},
		queryKey: [
			QueryKey.Users,
			searchInput.organizationId,
			searchInput.page,
			searchInput.pageSize,
			searchInput.sortOrder,
			searchInput.sortField,
			searchInput.filterField,
			searchInput.filterValue,
			searchInput.filterOperator,
		],
		queryFn: ({ signal }) => getUsers(searchInput, client, signal),
		staleTime: Infinity,
	});
}
