import { Box, Divider, Group, Stack, UnstyledButton } from '@mantine/core';
import { t } from 'i18n';
import { Trans } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import { theme } from 'components/providers/StyleProvider/theme';
import { H4, P1, P2 } from 'components/shared/designSystem';

interface Props {
	children: React.ReactNode;
	onClick: {
		overview(): void;
		references(): void;
	};
	onEndReached?: () => void;
}

export function PathwayWidget({ children, onClick, onEndReached }: Props) {
	function handleInViewChange(inView: boolean) {
		if (inView) {
			onEndReached?.();
		}
	}

	return (
		<Container>
			<Header>
				<Group gap={4} justify='space-between' wrap='nowrap'>
					<Title />
					<Overview onClick={onClick.overview} />
				</Group>
				<Subtitle />
			</Header>
			<Divider color={theme.colors.gray_60} />
			<Content>{children}</Content>
			<References onClick={onClick.references} />
			<InView onChange={handleInViewChange}>
				<Disclaimer />
			</InView>
		</Container>
	);
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<Stack
			data-testid='pathway-widget'
			style={{
				gap: 16,
				padding: 24,
				borderRadius: 10,
				boxShadow: theme.boxShadow.standard,
				backgroundColor: theme.color.white,
			}}
		>
			{children}
		</Stack>
	);
}

function Header({ children }: { children: React.ReactNode }) {
	return <Stack gap={4}>{children}</Stack>;
}

function Title() {
	return (
		<H4 style={{ fontWeight: theme.weight.medium }}>
			{t('cds.patientDetails.recommendedNextSteps')}
		</H4>
	);
}

function Overview({ onClick }: { onClick(): void }) {
	return (
		<UnstyledButton ff={theme.baseFont} onClick={onClick}>
			<P1
				style={{
					color: theme.colors.blue,
					fontWeight: theme.weight.medium,
				}}
			>
				{t('cds.patientDetails.viewPathway')}
			</P1>
		</UnstyledButton>
	);
}

function Subtitle() {
	return <P1>{t('cds.patientDetails.subtitle')}</P1>;
}

function Content({ children }: { children: React.ReactNode }) {
	return (
		<Box
			style={{
				position: 'relative',
				overflowX: 'auto',
				padding: '0 4px',
			}}
		>
			{children}
		</Box>
	);
}

function References({ onClick }: { onClick(): void }) {
	return (
		<Box>
			<UnstyledButton ff={theme.baseFont} onClick={onClick}>
				<span
					style={{
						color: theme.colors.blue,
						textDecoration: 'underline',
					}}
				>
					{t('cds.patientDetails.references')}
				</span>
			</UnstyledButton>
		</Box>
	);
}

function Disclaimer() {
	return (
		<Box
			style={{
				padding: '4px 8px',
				borderRadius: 4,
				backgroundColor: theme.colors.gray_80,
			}}
		>
			<P2>
				<Trans
					i18nKey={'cds.patientDetails.disclaimer'}
					components={{
						b: (
							<b
								style={{
									fontWeight: theme.weight.medium,
								}}
							/>
						),
					}}
				/>
			</P2>
		</Box>
	);
}
