import { SexAssignedAtBirth } from 'generated/graphql';
import { generateTranslationEnum } from 'utils/enumTranslationUtils';

type PatientSex = Omit<
	typeof SexAssignedAtBirth,
	'Other' | 'ToBeCollectedAtTimeOfTesting'
>;

const patientSex: PatientSex = {
	Female: SexAssignedAtBirth.Female,
	Male: SexAssignedAtBirth.Male,
	Complex: SexAssignedAtBirth.Complex,
	Unknown: SexAssignedAtBirth.Unknown,
};

export const sex = generateTranslationEnum<PatientSex>(
	patientSex,
	'web.enums.sex'
);

sex.Female.index = 1;
sex.Male.index = 2;
sex.Complex.index = 3;
sex.Unknown.index = 4;
