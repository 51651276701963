import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Header } from '../shared/Header';
import { StyledCard } from '../shared/StyledCard';
import { H2, P1 } from '../shared/designSystem/TextComponents';
import {
	AboutAssessmentsLink,
	PrivacyPolicyLink,
	RegulatoryLink,
	TermsOfUseLink,
} from './links';

const AboutPage = () => {
	const { t } = useTranslation();
	return (
		<div>
			<Header />
			<StyledPage>
				<StyledHeaderContainer>
					<StyledTitle>{t(`web.enums.routes.about`)}</StyledTitle>
				</StyledHeaderContainer>
				<StyledLinkContainer>
					<StyledVersion>{`${t(`research.menu.version`)} # ${
						process.env.APPLICATION_VERSION
					}`}</StyledVersion>
					<StyledSeparator />
					<AboutAssessmentsLink />
					<StyledSeparator />
					<PrivacyPolicyLink />
					<StyledSeparator />
					<TermsOfUseLink />
					<StyledSeparator />
					<RegulatoryLink />
					<StyledSeparator />
				</StyledLinkContainer>
			</StyledPage>
		</div>
	);
};

const StyledPage = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.md};
		box-sizing: border-box;
	`
);

const StyledLinkContainer = styled.div(
	({ theme: { spacing } }) => css`
		padding: ${spacing.md};
	`
);

const StyledHeaderContainer = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: ${spacing.md} 0;
	`
);
const StyledTitle = styled(H2)(
	({
		theme: {
			spacing,
			color: { headerText },
		},
	}) => `
	display: flex;
	align-items: center;
	padding: 0 0 0 ${spacing.md};
	color: ${headerText};
	`
);

const StyledVersion = styled(P1)(
	({ theme: { color } }) => `
    margin: 0 0 0 0;
    weight: 400;
    color: ${color.footerCopyright};
`
);

const StyledSeparator = styled.div(
	({ theme: { color } }) => `
	position: relative;
	width: 100%;
	border-bottom: 1px solid ${color.infoSeparator};
	margin-top: 25px;
	margin-bottom: 25px;
`
);

export { AboutPage };
