import { Box, Center, Group, Stack } from '@mantine/core';
import { t } from 'i18n';

import { PatientScores } from 'components/patients/PatientDetails/PatientDetails_V2/PatientDetails.fixtures';
import { theme } from 'components/providers/StyleProvider/theme';
import { LinusModal } from 'components/shared/LinusModal';
import { ButtonSm } from 'components/shared/designSystem';
import { Icon } from 'components/shared/designSystem/Icon';
import { H3, P1 } from 'components/shared/designSystem/TextComponents';
import { icons } from 'enums/icons';

import { concernsMap } from '../../Concerns/Concerns.data';
import { List, renderTags } from '../../Pathway.misc';
import { PathwayConcernID } from '../../Pathway.types';
import { voidListener } from '../../../../../../utils/valueListUtils';

interface Props {
	id: PathwayConcernID;
	firstName?: string;
	scores?: PatientScores;
	onClick: {
		references(): void;
	};
	onClose(): void;
}

export function ConcernDetailsModal({
	id,
	firstName,
	scores,
	onClick,
	onClose,
}: Props) {
	const concern = concernsMap[id];

	function createType(score: string | number) {
		if (score && typeof score === 'number') {
			return score < 0 ? 'below' : 'above';
		}
		return '-';
	}

	return (
		<LinusModal width='760px' onClose={onClose}>
			<Stack gap={16} data-testid='concern-details-modal'>
				<Stack gap={8}>
					<Title>{concern.title}</Title>
					<Rationale>{concern.rationale}</Rationale>
				</Stack>
				{concern.details.highlight && (
					<Highlight
						value={t(concern.details.highlight, {
							commandClockScore: scores?.commandPlacement,
							copyClockScore: scores?.copyPlacement,
							dctClockScore: scores?.dctClockScore,
							immediateRecallScore: scores?.immediateRecall,
							delayedRecallScore: scores?.delayedRecall,
							dcrScore: scores?.DCR,
							drawingSizeFirst: scores?.copyDraw,
							drawingSizeLast: scores?.commandDraw,
							typeDrawingFirst: createType(
								scores?.commandDraw || ''
							),
							typeDrawingLast: createType(scores?.copyDraw || ''),
							commandMotor: scores?.commandMotor,
							copyMotor: scores?.copyMotor,
							commandOscillatory: scores?.commandOscillatory,
							copyOscillatory: scores?.copyOscillatory,
							typeCommand: createType(
								scores?.commandOscillatory || ''
							),
							typeCopy: createType(scores?.copyOscillatory || ''),
						})}
						firstName={firstName}
					/>
				)}
				<Details content={concern.details.content} onClick={onClick} />
				<Center>
					<ButtonSm
						text={t('cds.concerns.close')}
						width='158px'
						onClick={onClose}
					/>
				</Center>
			</Stack>
		</LinusModal>
	);
}

function Title({ children }: { children: React.ReactNode }) {
	return (
		<Group gap={8} wrap='nowrap'>
			<Icon
				icon={icons.ArrowFlag}
				iconWidth={40}
				iconHeight={40}
				color={theme.colors.orange}
			/>
			<H3 style={{ fontWeight: theme.weight.medium }}>{children}</H3>
		</Group>
	);
}

function Rationale({ children }: { children: React.ReactNode }) {
	return <P1 style={{ color: theme.colors.gray_30 }}>{children}</P1>;
}

function Highlight({
	value,
	firstName,
}: {
	value: string;
	firstName?: string;
}) {
	return (
		<Box
			data-testid='highlight'
			style={{
				padding: 8,
				borderRadius: 8,
				backgroundColor: theme.colors.gray_80,
			}}
		>
			<P1>
				{renderTags(
					t('cds.concerns.relevantScores', {
						patientName: firstName ?? '{{patientName}}',
						value: value,
					})
				)}
			</P1>
		</Box>
	);
}

function Details({
	content,
	onClick,
}: {
	content: string[];
	onClick: {
		references(): void;
	};
}) {
	const tagComponents = {
		button: (
			<span
				style={{
					cursor: 'pointer',
					color: theme.colors.blue,
				}}
				onClick={onClick.references}
				onKeyDown={voidListener}
			/>
		),
	};

	return (
		<P1>
			<List gap={4}>
				{content.map((value) => (
					<List.Item key={value}>
						{renderTags(value, tagComponents)}
					</List.Item>
				))}
			</List>
		</P1>
	);
}
