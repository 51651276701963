import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import styled, { useTheme } from 'styled-components';

interface CheckboxProps {
	checked: boolean;
	size: string;
}

export const CheckBox = ({ checked, size }: CheckboxProps) => {
	const theme = useTheme();
	return (
		<ContainerDiv
			className={'tbl-chckbox'}
			data-testid={checked ? 'tbl-checked' : 'tbl-unckecked'}
		>
			<Icon
				icon={checked ? icons.CheckboxCheck : icons.CheckboxEmpty}
				width={size}
				height={size}
				color={theme.colors.gray_50}
			/>
		</ContainerDiv>
	);
};

const ContainerDiv = styled.div`
	margin: 2px 5px 0px 0px;
`;
