import { Patient } from '@lh/eng-platform-subject-service-rest-client';

import { t } from 'i18n';
import { useContext } from 'react';

import { usePatientQuery } from 'api/patient';
import { UserContext } from 'components/context';
import { useGetBatteryResults } from 'components/shared/hooks';
import { gender as genderEnum } from 'enums/participantEnums/gender';
import { handedness as handednessEnum } from 'enums/participantEnums/handedness';
import { sex as sexEnum } from 'enums/participantEnums/sex';
import { utcDateFormatter } from 'stringStrategy/dateFormatStringStrategy';
import { CurrentUser } from 'types';

import { BatteryResults, Data, PatientData } from './PatientDetails.fixtures';

export function useFormattedData(
	mockData: Data | undefined,
	patientData: Patient | undefined
) {
	const { currentUser } = useContext(UserContext);
	return modifyPatient(patientData, currentUser, mockData);
}

export function modifyPatient(
	patientData: Patient | undefined,
	currentUser: CurrentUser,
	mockData?: Data | undefined
) {
	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';

	let dateOfBirth = '';
	let education = '';
	let ethnicity = '';
	let firstName = '';
	let gender = '';
	let handedness = '';
	let id = '';
	let language = '';
	let lastName = '';
	let notes = t('web.patients.info.notesDefaultValue') || '';
	let race = '';
	let sexAssignedAtBirth = '';
	let externalId = '';
	let contactEmail = '';
	let contactPhone = '';

	if (patientData) {
		if (patientData.id) {
			id = patientData.id;
		}

		if (patientData.birthDate) {
			const formattedBirthDate = utcDateFormatter(
				patientData.birthDate,
				dateFormat
			);
			dateOfBirth = formattedBirthDate + ` (${patientData?.age} YO)`;
		}

		if (patientData?.education?.length) {
			education = patientData.education[0].displayKey
				? t(patientData.education[0].displayKey)
				: patientData.education[0].display ?? '';
		}

		if (patientData.ethnicity) {
			patientData.ethnicity.forEach((x, i) => {
				const text = x.displayKey ? t(x.displayKey) : x.display || '-';
				const ethnicityList = patientData?.ethnicity || [];
				ethnicity += `${text}${
					i < ethnicityList.length - 1 ? ', ' : ''
				}`;
			});
		}

		if (patientData.externalId) {
			externalId = patientData.externalId;
		}

		if (patientData.firstName) {
			firstName = patientData.firstName;
		}

		if (patientData.gender) {
			const formattedGender = genderEnum.fromValue(patientData.gender);
			if (formattedGender?.display) {
				gender = formattedGender.display;
			}
		}
		if (patientData.sexAssignedAtBirth) {
			const formattedSex = sexEnum.fromValue(
				patientData.sexAssignedAtBirth
			);
			if (formattedSex?.display) {
				sexAssignedAtBirth = formattedSex.display;
			}
		}

		if (patientData.handedness) {
			const formattedHandedness = handednessEnum.fromValue(
				patientData.handedness
			);
			if (formattedHandedness?.display) {
				handedness = formattedHandedness.display;
			}
		}

		if (
			patientData.language &&
			currentUser?.organizationParticipantLanguages?.fromValue
		) {
			const formattedLanguage =
				currentUser.organizationParticipantLanguages.fromValue(
					patientData.language
				);
			if (formattedLanguage?.display) {
				language = t(formattedLanguage.display);
			}
		}

		if (patientData.lastName) {
			lastName = patientData.lastName;
		}

		if (patientData.notes) {
			notes = patientData.notes;
		}

		const races = patientData.race;
		if (races && races.length > 0) {
			races.forEach((x, i) => {
				const text = x.displayKey ? t(x.displayKey) : x.display || '-';
				race += `${text}${i < races.length - 1 ? ', ' : ''}`;
			});
		}

		if (patientData.contactEmail) {
			contactEmail = patientData.contactEmail;
		}

		if (patientData.contactPhone) {
			contactPhone = patientData.contactPhone;
		}
	}

	if (mockData) {
		return {
			mostRecent: mockData.mostRecent,
			notes: mockData.notes,
			organization: mockData.organization,
			patient: mockData.patient,
			id: '1234',
		};
	}

	return {
		notes,
		organization: {
			name: currentUser.organizationName,
		},
		patient: {
			id,
			externalId,
			firstName,
			lastName,
			dateOfBirth,
			gender,
			education,
			handedness,
			language,
			ethnicity,
			race,
			sexAssignedAtBirth,
			contactEmail,
			contactPhone,
		},
	};
}

export function useBatteryResults(
	mockBatteryResults: BatteryResults | undefined,
	patientId: string
) {
	const { currentUser } = useContext(UserContext);

	const dateFormat = currentUser.organizationDateFormat || 'MM/dd/yyyy';
	const defaultTimezone = currentUser.organizationDefaultTimezone;

	const { data, loading } = useGetBatteryResults(patientId);

	if (mockBatteryResults) {
		return {
			data: mockBatteryResults.data,
			loading: mockBatteryResults.loading,
			dateFormat: mockBatteryResults.dateFormat,
			defaultTimezone: mockBatteryResults.defaultTimezone,
		};
	}

	return { data, loading, dateFormat, defaultTimezone };
}

export function usePatient(
	mockPatient: PatientData | undefined,
	patientId: string
) {
	const { currentUser } = useContext(UserContext);
	const {
		data: patientData,
		isLoading,
		refetch: refetchPatient,
	} = usePatientQuery(patientId, currentUser.organizationId);

	if (mockPatient) {
		return {
			patientData: mockPatient.patientData,
			patientDataLoading: mockPatient.patientDataLoading,
			refetchPatient,
		};
	}

	return { patientData: patientData, isLoading, refetchPatient };
}
