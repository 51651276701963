import { Subject } from '@lh/eng-platform-subject-service-rest-client';

import { useTranslation } from 'react-i18next';

import { InfoContent } from 'components/shared/Forms/Components/InfoContent';
import { RELATIONSHIP_TO_PATIENT_MAP } from '../../CarePartner.consts';

interface ContentProps {
	partnerInfo: Subject;
	patientInfo: Subject;
}

export function Content({ partnerInfo, patientInfo }: Readonly<ContentProps>) {
	const { t } = useTranslation();

	const content = [
		{
			info: partnerInfo.firstName || '',
			subtitle: t('web.patients.forms.firstNameLabel').toUpperCase(),
		},
		{
			info: partnerInfo.lastName || '',
			subtitle: t('web.patients.forms.lastNameLabel').toUpperCase(),
		},
		{
			info: partnerInfo.contactEmail || '',
			subtitle: t('web.patients.carePartner.form.email').toUpperCase(),
		},
		{
			info: partnerInfo.contactPhone || '',
			subtitle: t('web.patients.carePartner.form.phone').toUpperCase(),
		},
		{
			info: parseRelationship(patientInfo, partnerInfo.id),
			subtitle: t(
				'web.patients.carePartner.form.relationship.title'
			).toUpperCase(),
		},
	];
	return <InfoContent infoContent={content} />;
}

function parseRelationship(patientInfo: Subject, partnerId: string): string {
	const relationship = patientInfo.relationships.find(
		(relationship) => relationship.relatedSubjectId === partnerId
	);
	if (!relationship) return '';

	return RELATIONSHIP_TO_PATIENT_MAP[relationship.type];
}
