import { Icon } from 'components/shared/designSystem';
import React from 'react';
import styled, { useTheme, css } from 'styled-components';
import { IconEnumType } from 'types';

type ExternalLinkProps = {
	text: string | number;
	href: string;
	target?: string;
	icon?: IconEnumType;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/**
 * This component will be used to render external links which will
 * leads you to some website.
 */
export const ExternalLinkWithIcon = ({
	text,
	href,
	onClick,
	icon,
	target = '_blank',
}: ExternalLinkProps): JSX.Element => {
	const theme = useTheme();
	return (
		<StyledLink
			onClick={onClick}
			href={href}
			hasUnderline={false}
			target={target}
		>
			<LinkText>{text}</LinkText>
			{icon && <Icon icon={icon} color={theme.color.linkArrow} />}
		</StyledLink>
	);
};

type StyledLinkPropsProps = {
	hasUnderline: boolean;
};

const StyledLink = styled.a<StyledLinkPropsProps>(
	({ hasUnderline }) => `
	text-decoration: ${hasUnderline && 'underline'};
	display: flex;
`
);

const LinkText = styled.div(
	({ theme: { color } }) => css`
		margin-right: 12px;
		color: ${color.linkArrowText};
	`
);
