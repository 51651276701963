import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { P2 } from 'components/shared/designSystem';

type CanArchive = {
	canArchive: boolean | null;
};

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 667px;
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ButtonRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: center;
		margin-top: ${spacing.xl};
	`
);

export const ButtonWrapper = styled.div`
	margin: 0 25px 0 0;
`;

export const Loading = styled.div`
	margin: 27.5px 0;
`;

export const Archive = styled.div(
	({ theme: { spacing } }) => css`
		margin-top: ${spacing.lg};
		margin-left: ${spacing.xs};
	`
);

export const ArchiveTitle = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		margin-left: ${spacing.xs};
		gap: ${spacing.sm};
	`
);

export const ArchiveDescription = styled(P2)(
	({ theme: { color, spacing } }) => css`
		margin-top: ${spacing.xs};
		color: ${color.textDisabled};
		font-style: italic;
	`
);

export const Separator = styled.div(
	({ theme: { color, spacing } }) => css`
		width: 100%;
		border-bottom: 1px solid ${color.infoSeparator};
		margin-top: ${spacing.md};
	`
);

export const Link = styled.a<CanArchive>(
	({ canArchive, theme: { color, weight } }) => css`
		color: ${canArchive ? color.textAlert : color.textDisabled};
		font-weight: ${weight.semi};
		pointer-events: ${canArchive ? 'unset' : 'none'};
		:hover {
			text-decoration: ${canArchive ? 'underline' : 'none'};
			color: ${canArchive ? color.textAlert : color.textDisabled};
		}
	`
);

export const AlertContainer = styled.div`
	width: 667px;
	margin-top: 8px;
	margin-bottom: 16px;
`;
