import { useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getBattery(
	batteryResultId: string,
	withMetrics?: boolean
) {
	if (!batteryResultId) {
		throw new Error("useGetBatteryQuery: batteryResultId can't be null");
	}

	const service = await getBatteryService();
	const { data } = await service.getDeepBatteryResult({
		batteryResultId,
		withMetrics,
	});
	return data;
}

export function useBatteryQuery(
	batteryResultId: string,
	withMetrics?: boolean
) {
	return useQuery({
		enabled: !!batteryResultId,
		meta: {
			errorMessage: `Error fetching battery ${batteryResultId}`,
		},
		queryKey: [QueryKey.Battery, batteryResultId],
		queryFn: () => getBattery(batteryResultId, withMetrics),
		staleTime: Infinity,
	});
}
