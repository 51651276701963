import { Reason } from '@lh/eng-platform-organization-service-rest-client';

import { useTranslation } from 'react-i18next';
import {
	AdditionalNotesBody,
	AdditionalNotesContainer,
	AdditionalNotesHeader,
	Li,
	SessionObservationsContainer,
	SmallSectionHeader,
	ThirdSectionWithDivider,
	Ul,
} from '../TestAdministratorNotes.style';

interface ReasonsForTestingProps {
	data?: Reason[];
	note?: string;
	showDivider?: boolean;
}

export const ReasonsForTesting = ({
	data,
	note,
	showDivider,
}: ReasonsForTestingProps) => {
	const { t } = useTranslation();
	return (
		<ThirdSectionWithDivider
			data-testid='reasons-for-testing-base-container'
			showDivider={showDivider}
		>
			<SessionObservationsContainer>
				<SmallSectionHeader data-testid='reasons-for-testing-header'>
					{t('web.reasonsForTesting.title')}
				</SmallSectionHeader>
				{data && data.length > 0 && (
					<Ul data-testid='reasons-for-testing-issues'>
						{data.map((element) => (
							<Li
								key={element.diagnosisCode}
							>{`${element.diagnosisCode}: ${element.diagnosisDescription}`}</Li>
						))}
					</Ul>
				)}
			</SessionObservationsContainer>
			{note && note?.length > 0 && (
				<AdditionalNotesContainer>
					<AdditionalNotesHeader data-testid='reasons-for-testing-other-reasons-title'>
						{t('web.reasonsForTesting.other')}
					</AdditionalNotesHeader>
					<AdditionalNotesBody data-testid='reasons-for-testing-other-reasons-body'>
						{note}
					</AdditionalNotesBody>
				</AdditionalNotesContainer>
			)}
		</ThirdSectionWithDivider>
	);
};
