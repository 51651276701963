import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { Caption } from 'components/shared/designSystem';
import { IndividualAnswersCard } from '../IndividualAnswersCard';
import { ResultsSummaryCard } from '../ResultsSummaryCard';

export type GDSLayoutProps = {
	score: {
		total: number | string;
		interpretation: string;
	};
	resultData: QnrRowData[];
};

export const GDSLayout = ({ score, resultData }: GDSLayoutProps) => {
	const { t } = useTranslation();

	return (
		<StyledLayoutContainer>
			<ResultsSummaryCard
				score={score.total}
				interpretation={score.interpretation}
			/>
			<IndividualAnswersCard resultData={resultData} />
			<FadedCaption>{t('web.report.gds.footer')}</FadedCaption>
		</StyledLayoutContainer>
	);
};

const StyledLayoutContainer = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: column;
			gap: ${spacing.lg};
		`
);

const FadedCaption = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
