import { Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import {
	AudioPlaybackCard,
	ReportInfoPanel,
	AdjustedScore,
	ScoringDetailsCta,
} from 'components/mosaic';

import { CutoffsScoringDetailsModal } from '../../../../CutoffsScoringDetailsModal';

import {
	DelayedRecallPerformance,
	DelayedRecallTrial,
} from '../DelayedRecall.types';
import { DelayedRecallScore } from '../DelayedRecallScore';

import {
	CaptionFaded,
	Container,
	Grid,
	Header,
	Section,
} from './DelayedRecallLayout.style';

export type DelayedRecallLayoutProps = {
	performance: DelayedRecallPerformance;
	requiresReview: boolean;
	trial: DelayedRecallTrial;
};

export function DelayedRecallLayout({
	performance,
	requiresReview,
	trial,
}: Readonly<DelayedRecallLayoutProps>) {
	const [opened, { open: openModal, close: closeModal }] =
		useDisclosure(false);
	const { t } = useTranslation();

	return (
		<Container>
			<ReportInfoPanel
				title={t('web.report.pvltDelayedReport.delayedRecall')}
				tooltipText={
					t('web.report.pvltDelayedReport.description', {
						returnObjects: true,
					}) as string[]
				}
			>
				<Stack gap={12}>
					<DelayedRecallScore
						performance={performance}
						requiresReview={requiresReview}
					/>
					{performance.score.adjusted && <AdjustedScore />}
					{performance.cutoff && (
						<ScoringDetailsCta onClick={openModal} />
					)}
				</Stack>
			</ReportInfoPanel>
			<Section>
				<Header>{t('web.shared.recordings')}</Header>
				<Grid>
					<AudioPlaybackCard
						audioSource={trial.url}
						cardTitle={t(
							'web.report.pvltDelayedReport.delayedRecall'
						)}
					>
						{`${t('web.shared.prompt')}: ${trial.prompt}`}
					</AudioPlaybackCard>
				</Grid>
			</Section>
			<CaptionFaded>{t('web.report.footer.info')}</CaptionFaded>
			{performance.cutoff && (
				<CutoffsScoringDetailsModal
					ageRange={performance.cutoff.ageRange}
					cutoff={performance.cutoff.value}
					open={opened}
					onClose={closeModal}
				/>
			)}
		</Container>
	);
}
