import styled, { css } from 'styled-components';

import { Caption, H3, H4, P1, P2 } from 'components/shared/designSystem';
import { StyledCard } from 'components/shared/StyledCard';

export const Wrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		max-width: 437px;
		padding: ${spacing.lg} 0;
	`
);

export const Title = styled(H3)(
	({ theme: { colors, spacing, weight } }) => css`
		color: ${colors.gray_10};
		font-weight: ${weight.medium};
		padding: 0 ${spacing.lg};
	`
);

export const LineDivider = styled.div(
	({ theme: { spacing, color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
		margin: ${spacing.md} 0;
	`
);

export const Content = styled.div(
	({ theme: { spacing } }) => css`
		padding: 0 ${spacing.lg};
	`
);

export const ErrorsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Quartile = styled(Caption)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.bold};
		margin-top: 8px;
		margin-left: 68px;
	`
);

export const ErrorsContainer = styled.div`
	width: 100%;
	flex-direction: row;
	display: flex;
`;

export const TotalMistakesContainer = styled.div(
	({ theme: { spacing } }) => css`
		width: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: ${spacing.sm};
		padding: 4px;
	`
);

export const TotalMistakes = styled(H4)<{ textColor: string }>(
	({ textColor, theme: { weight } }) => css`
		color: ${textColor};
		font-weight: ${weight.medium};
	`
);

export const Description = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-left: 12px;
`;

export const DescriptionText = styled(P1)(
	({ theme: { color, weight } }) => css`
		color: ${color.graphTextPrimary};
		font-weight: ${weight.regular};
	`
);

export const AgeUnavailable = styled(P2)`
	margin-top: 12px;
`;
