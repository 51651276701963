const DEV_ENVIRONMENT = 'dev';
const REGION_ENVIRONMENTS = ['qa', 'staging'];

function isLocal(host: string) {
	return host.includes('localhost');
}

function isEnvironment(host: string, env: string) {
	return host.includes(env);
}

export function parseEnvForAttributes(host: string) {
	let tokens = host.split('.');
	const application = tokens.shift();

	if (!application) {
		return;
	}

	tokens.pop();
	tokens.pop();

	if (isLocal(host)) {
		return {
			application: 'clinical',
			region: 'us',
		};
	}

	if (isEnvironment(host, DEV_ENVIRONMENT)) {
		return {
			application,
			region: 'us',
		};
	}

	for (const env of REGION_ENVIRONMENTS) {
		if (!isEnvironment(host, env)) {
			continue;
		}

		tokens = tokens.filter((token) => token !== env);
		const region = tokens.pop() ?? 'us';

		return {
			application,
			region,
		};
	}

	const region = tokens.pop() ?? 'us';
	return {
		application,
		region,
	};
}
