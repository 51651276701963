import { useCallback, useEffect } from 'react';
import { fetchUtil, prefix } from '../../../s3ApiFetch';
import { Auth } from '@lh/eng-shared-auth';
import { questionParser } from '@lh/eng-lhq-questions-common';
import { SegmentType } from 'generated/graphql';

interface UseGetQuestionnaireAnswersProps {
	segmentId: string;
	setAnswers: React.Dispatch<React.SetStateAction<{ results: never[] }>>;
	setIsLoading?: (isLoading: boolean) => void;
	auth: Auth;
	segmentType?: SegmentType;
}

export const useGetQuestionnaireAnswers = ({
	segmentId,
	setAnswers,
	setIsLoading,
	auth,
	segmentType,
}: UseGetQuestionnaireAnswersProps): void => {
	const envPrefix = prefix();
	const fetchData = useCallback(fetchUtil, []);
	useEffect(() => {
		if (!segmentId) return;
		const abortController = new AbortController();
		const url = `${envPrefix}s3MetricFiles/${segmentId}/questionnaire.json`;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const responseHandler = (response: any) => {
			if (segmentType === SegmentType.Lhq15) {
				setAnswers(questionParser(response.data) as any);
			} else {
				setAnswers(response.data);
			}
		};
		fetchData({
			url,
			signal: abortController.signal,
			onSuccess: responseHandler,
			auth,
		})
			.catch(console.error)
			.finally(() => setIsLoading && setIsLoading(false));
		return () => {
			abortController.abort();
		};
	}, [fetchData, envPrefix, segmentId, setAnswers, setIsLoading]);
};
