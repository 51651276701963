import styled, { css } from 'styled-components';

import { Caption, H4, P2 } from 'components/shared/designSystem';

export const FluencyLayoutContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
	`
);

export const Header = styled(H4)(
	({ theme: { spacing, weight } }) => css`
		font-weight: ${weight.medium};
		margin-left: ${spacing.lg};
	`
);

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const CaptionFaded = styled(Caption)(
	({ theme: { color, spacing } }) => css`
		color: ${color.bodyTextSecondary};
		margin-bottom: ${spacing.sm};
	`
);

export const ScoreBreakdownContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
		width: 100%;
	`
);

export const BreakdownTitle = styled(P2)(
	({ theme: { color, weight } }) => css`
		font-weight: ${weight.medium};
		color: ${color.bodyTextSecondary};
	`
);
