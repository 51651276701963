import { Assignment } from '@lh/eng-platform-battery-service-rest-client';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function deleteAssignment(assignmentId: string) {
	const service = await getBatteryService();
	const { data } = await service.deleteAssignment({ assignmentId });
	return data;
}

export function useDeleteAssignmentMutation() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error deleting assignment',
		},
		mutationFn: deleteAssignment,
		onSuccess: async (_, variables) => {
			// TODO: Should be consumed when porting fully to REST
			// client.setQueryData(
			//     [QueryKey.Subjects, organizationId],
			//     (data: InfiniteData<PaginatedSubjects>) => {
			//         if (!data) {
			//             return;
			//         }

			//         return {
			//             ...data,
			//             pages: data.pages.map((page) => ({
			//                 ...page,
			//                 results: page.results.map((subject) => {
			//                     if (subject.id !== variables.subjectId) {
			//                         return subject;
			//                     }

			//                     return {
			//                         ...subject,
			//                         assignment: undefined
			//                     };
			//                 })
			//             }))
			//         };
			//     }
			// );
			client.setQueryData<Assignment | null>(
				[QueryKey.Assignment, variables],
				null
			);
		},
	});
}
