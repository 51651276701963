import { SubjectRelationshipType } from '@lh/eng-platform-subject-service-rest-client';

import i18n from 'i18n';

export const RELATIONSHIP_TO_PATIENT = [
	{
		display: i18n.t('web.patients.carePartner.form.relationship.spouse'),
		value: SubjectRelationshipType.Spouse,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.partner'),
		value: SubjectRelationshipType.DomesticPartner,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.child'),
		value: SubjectRelationshipType.Child,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.sibling'),
		value: SubjectRelationshipType.Sibling,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.parent'),
		value: SubjectRelationshipType.Parent,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.nextKin'),
		value: SubjectRelationshipType.NextOfKin,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.family'),
		value: SubjectRelationshipType.FamilyMember,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.friend'),
		value: SubjectRelationshipType.Friend,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.neighbor'),
		value: SubjectRelationshipType.Neighbor,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.hired'),
		value: SubjectRelationshipType.HiredCarePartner,
	},
	{
		display: i18n.t('web.patients.carePartner.form.relationship.other'),
		value: SubjectRelationshipType.Other,
	},
];

export const RELATIONSHIP_TO_PATIENT_MAP: Record<string, string> = {
	[SubjectRelationshipType.Child]: i18n.t(
		'web.patients.carePartner.form.relationship.child'
	),
	[SubjectRelationshipType.DomesticPartner]: i18n.t(
		'web.patients.carePartner.form.relationship.partner'
	),
	[SubjectRelationshipType.FamilyMember]: i18n.t(
		'web.patients.carePartner.form.relationship.family'
	),
	[SubjectRelationshipType.Friend]: i18n.t(
		'web.patients.carePartner.form.relationship.friend'
	),
	[SubjectRelationshipType.HiredCarePartner]: i18n.t(
		'web.patients.carePartner.form.relationship.hired'
	),
	[SubjectRelationshipType.Neighbor]: i18n.t(
		'web.patients.carePartner.form.relationship.neighbor'
	),
	[SubjectRelationshipType.NextOfKin]: i18n.t(
		'web.patients.carePartner.form.relationship.nextKin'
	),
	[SubjectRelationshipType.Other]: i18n.t(
		'web.patients.carePartner.form.relationship.other'
	),
	[SubjectRelationshipType.Parent]: i18n.t(
		'web.patients.carePartner.form.relationship.parent'
	),
	[SubjectRelationshipType.Sibling]: i18n.t(
		'web.patients.carePartner.form.relationship.sibling'
	),
	[SubjectRelationshipType.Spouse]: i18n.t(
		'web.patients.carePartner.form.relationship.spouse'
	),
};
