import { Center } from '@mantine/core';
import styled, { css } from 'styled-components';

import { H4 } from 'components/shared/designSystem/TextComponents';

export const Container = styled(Center)`
	display: flex;
	min-height: 254px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	max-width: 478px;
	gap: 16px;
`;

export const Title = styled(H4)(
	({ theme: { color } }) => css`
		color: ${color.textBlue};
	`
);
