import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { HeaderIconContainer, Tooltip } from '../TestAdministratorNotes.style';

interface HeaderIconProps {
	text?: string;
	tooltipPlacement?: string;
	onClick?: (event: React.MouseEvent) => void;
}

export const HeaderIcon = ({
	text,
	tooltipPlacement,
	onClick,
}: HeaderIconProps) => {
	const IconElement = (
		<Icon icon={icons.InfoOutlined} iconWidth={24} iconHeight={24} />
	);

	if (!text) {
		return (
			<HeaderIconContainer
				data-testid='styled-header-icon-container'
				onClick={onClick}
			>
				{IconElement}
			</HeaderIconContainer>
		);
	}

	return (
		<HeaderIconContainer
			data-testid='styled-header-icon-container'
			onClick={onClick}
		>
			<LinusTooltip
				placement={tooltipPlacement}
				overlay={<Tooltip>{text}</Tooltip>}
				tooltipIcon={IconElement}
			/>
		</HeaderIconContainer>
	);
};
