import { ScoreType, ScoreVariant } from '../../types';
import { LabelText, ScoreTagContainer, ScoreText } from './ScoreTag.style';

const TYPE_TO_VARIANT: Record<ScoreType, ScoreVariant> = {
	'not-impaired': 'success',
	impaired: 'danger',
	unanalyzable: 'neutral',
	unknown: 'neutral',
};

type ScoreTagProps = {
	composed?: boolean;
	label?: string;
	value: string;
	type: ScoreType;
};

export function ScoreTag({
	composed = false,
	label,
	value,
	type,
}: Readonly<ScoreTagProps>) {
	const variant = TYPE_TO_VARIANT[type];

	return (
		<ScoreTagContainer $composed={composed} $variant={variant}>
			{label && <LabelText>{label}</LabelText>}
			<ScoreText>{value}</ScoreText>
		</ScoreTagContainer>
	);
}
