import { ErrorMessage, useField } from 'formik';
import styled, { css } from 'styled-components';
import { FormElementProps } from '../../../../types';
import { P2 } from '../../designSystem/TextComponents';
import { CheckboxLabel } from './CheckboxLabel';
import { Input } from './Input';
import { Label } from './Label';

export const LinusInput = <T,>(props: FormElementProps<T>): JSX.Element => {
	const [field, meta] = useField(props.name);
	const { err, isTouched, testId, ...inputProps } = props;

	const showLabel = props.showLabel === false ? props.showLabel : true;
	return (
		<StyledContainer {...props} data-testid={testId} style={props.styles}>
			<InputContainer {...props}>
				{props.type === 'checkbox' || props.type === 'radio' ? (
					<CheckboxLabel>
						<Input {...inputProps} error={meta.error} />
						{props.label || props.placeholder}
					</CheckboxLabel>
				) : (
					<>
						{showLabel && (
							<Label {...inputProps} value={field.value} />
						)}
						<Input
							{...inputProps}
							value={field.value}
							error={meta.touched && meta.error}
						/>
					</>
				)}
			</InputContainer>
			<ErrorMessage name={field.name}>
				{(msg) => (
					<StyledErrorText data-id={`input-error-${props.name}`}>
						{msg}
					</StyledErrorText>
				)}
			</ErrorMessage>
			{props.helperText && <HelperText>{props.helperText}</HelperText>}
		</StyledContainer>
	);
};

const StyledContainer = styled.div<{
	err?: boolean;
	width?: string;
	height?: string;
	isTouched?: boolean;
	helperText?: string;
}>(
	({ theme: { spacing }, err, isTouched, width, helperText }) => css`
		display: flex;
		flex-direction: column;
		margin: ${spacing.md} 0;
		margin-bottom: ${err && isTouched ? 0 : helperText ? 0 : spacing.md};
		width: ${width ?? '317px'};
	`
);
const InputContainer = styled.div<{
	height?: string;
	err?: boolean;
	isTouched?: boolean;
}>(
	({ height }) => css`
		position: relative;
		height: ${height ?? '48px'};
		top: 0;
	`
);

const StyledErrorText = styled(P2)(
	({ theme: { spacing, color } }) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: ${color.formError};
		padding: ${spacing.xs} 0 0 ${spacing.lg};
		font-style: italic;
	`
);

const HelperText = styled(P2)(
	({ theme: { spacing, color } }) => css`
		color: ${color.graphTextSecondary};
		padding: ${spacing.xs} 0 0 ${spacing.lg};
		font-style: italic;
	`
);
