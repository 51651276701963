import {
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import { Report } from './CognitiveEvaluation.types';

export function parseSegmentResult(
	segmentType: SegmentType,
	batteryResult?: Report
) {
	let parsedSegmentResult: DeepSegmentResult = {
		id: '',
		assessmentResultId: '',
		segmentId: '',
		hasProcessingError: false,
		isExpectingMetrics: false,
		isExpectingResults: false,
		hasReceivedResults: false,
		metricItems: [],
		segment: {
			id: '',
			displayKey: '',
			isExpectingMetrics: false,
			isExpectingResults: false,
			name: '',
			audienceType: '',
			metadata: {
				category: undefined,
				qnrId: '',
				qnrVersion: '',
			},
			segmentType: segmentType,
		},
	};

	if (batteryResult?.batteryResultById) {
		for (const assessmentResult of batteryResult.batteryResultById
			.assessmentResults) {
			for (const segmentResult of assessmentResult.segmentResults) {
				if (segmentResult.segment.segmentType === segmentType) {
					parsedSegmentResult = segmentResult;
					break;
				}
			}
		}
	}

	return parsedSegmentResult;
}
