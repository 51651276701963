import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getArtifacts(
	batteryResultId: string,
	fileName: string,
	signal?: AbortSignal
) {
	if (!batteryResultId) {
		throw new Error("useGetArtifactsQuery: batteryResultId can't be null");
	}

	if (!fileName) {
		throw new Error("useGetArtifactsQuery: fileName can't be null");
	}

	const service = await getBatteryService();
	const { data } = await service.getBatteryResultArtifact(
		{ batteryResultId, fileName },
		{ signal }
	);
	return data;
}

export function useArtifactsQuery(batteryResultId: string, fileName: string) {
	return useQuery({
		enabled: !!batteryResultId && !!fileName,
		meta: {
			errorMessage: 'Error fetching battery artifacts',
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.Artifacts, batteryResultId, fileName],
		queryFn: ({ signal }) =>
			getArtifacts(batteryResultId, fileName, signal),
		staleTime: Infinity,
	});
}
