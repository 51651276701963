import { SegmentType } from '@lh/eng-platform-battery-service-rest-client';

import { Report } from 'components/report/CognitiveEvaluation.types';
import { parseSegmentResult } from 'components/report/Report.utils';

import { SemanticLayout } from './SemanticLayout';

type SematicReportProps = {
	batteryResult: Report;
};

export function SemanticReport({
	batteryResult,
}: Readonly<SematicReportProps>) {
	const segmentResult = parseSegmentResult(
		SegmentType.SemanticFluency,
		batteryResult
	);

	return <SemanticLayout segmentResult={segmentResult} />;
}
