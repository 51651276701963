import { theme } from 'components/providers/StyleProvider/theme';

import {
	SolidDot,
	Threshold,
	ThresholdDescription,
	Wrapper,
} from './DotThresholds.styles';

export type DotThresholdsProps = {
	readonly greenText: string;
	readonly yellowText: string;
	readonly redText: string;
};

const DOT_SIZE = 12;

export function DotThresholds({
	greenText,
	yellowText,
	redText,
}: DotThresholdsProps) {
	const dots = [
		{
			color: theme.colors.green_light,
			text: greenText,
			testId: 'green-dot',
		},
		{
			color: theme.colors.yellow_light,
			text: yellowText,
			testId: 'yellow-dot',
		},
		{
			color: theme.colors.orange_light,
			text: redText,
			testId: 'red-dot',
		},
	];

	return (
		<Wrapper>
			{dots.map((dot) => (
				<Threshold key={dot.testId}>
					<SolidDot
						data-testid={dot.testId}
						size={DOT_SIZE}
						color={dot.color}
					/>
					<ThresholdDescription>{dot.text}</ThresholdDescription>
				</Threshold>
			))}
		</Wrapper>
	);
}
