import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { Caption, P2 } from 'components/shared/designSystem';

export const CaptionFaded = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);

export const BdstLayoutContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
	`
);

export const P2Med = styled(P2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const RecordingsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const AudioWidgetWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
	`
);

export const AudioContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: grid;
		grid-gap: ${spacing.lg};
		grid-template-columns: 1fr 1fr;
	`
);
