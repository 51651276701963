import { Trans, useTranslation } from 'react-i18next';
import semver from 'semver';
import styled, { useTheme } from 'styled-components';

import { ScoreRangeContent } from './ScoringRowContent/ScoreRangeContent';

type MiniModalScoringProps = {
	version: string;
};

export const MiniModalScoring = ({
	version,
}: MiniModalScoringProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();

	const contentVersion =
		semver.valid(version) && semver.lt(version, '1.3.1')
			? '1.0.0'
			: '1.1.0';

	return (
		<StyledScoringContainer>
			<ScoreRangeContent
				minScore={4}
				maxScore={5}
				scoreColor={theme.color.alertSuccess}
			>
				<Trans
					i18nKey={t(
						`web.dcrReport.overview.scoringDescription.cognitivelyUnimpaired.text_${contentVersion}`
					)}
				/>
			</ScoreRangeContent>
			<ScoreRangeContent
				minScore={2}
				maxScore={3}
				scoreColor={theme.color.alertWarningText}
			>
				<Trans
					i18nKey={t(
						`web.dcrReport.overview.scoringDescription.likelyCognitivelyUnimpaired.text_${contentVersion}`
					)}
				/>
			</ScoreRangeContent>
			<ScoreRangeContent
				minScore={0}
				maxScore={1}
				scoreColor={theme.color.alertError}
			>
				<Trans
					i18nKey={t(
						`web.dcrReport.overview.scoringDescription.cognitivelyImpaired.text_${contentVersion}`
					)}
				/>
			</ScoreRangeContent>
		</StyledScoringContainer>
	);
};

const StyledScoringContainer = styled.div(
	({ theme: { spacing } }) => `
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);
