import { Typography } from '@lh/eng-web-mosaic-common';
import { Flex, List, Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { SimpleModal } from 'components/mosaic';
import { ButtonSm } from 'components/shared/designSystem';

import { BadDot, GoodDot } from './CutoffsScoringDetailsModal.style';

type CutoffsScoringDetailsModalProps = {
	ageRange: string | undefined;
	cutoff: string | undefined;
	open: boolean;
	onClose: () => void;
};

export function CutoffsScoringDetailsModal({
	ageRange,
	cutoff,
	open,
	onClose,
}: Readonly<CutoffsScoringDetailsModalProps>) {
	const { t } = useTranslation();

	return (
		<SimpleModal
			open={open}
			title={t('web.report.scoringDetails.title')}
			onClose={onClose}
		>
			<Stack gap={12}>
				<Typography.P1 weight='500'>
					{t('web.report.scoringDetails.description', {
						value: ageRange,
					})}
				</Typography.P1>
				<List>
					<List.Item icon={<GoodDot />}>
						<Typography.P1>
							{t('web.report.scoringDetails.good', {
								value: cutoff,
							})}
						</Typography.P1>
					</List.Item>
					<List.Item icon={<BadDot />}>
						<Typography.P1>
							{t('web.report.scoringDetails.bad', {
								value: cutoff,
							})}
						</Typography.P1>
					</List.Item>
				</List>
			</Stack>
			<Flex justify='end'>
				<ButtonSm
					primary
					text={t('web.patients.forms.closeCTA')}
					width='160px'
					onClick={onClose}
				/>
			</Flex>
		</SimpleModal>
	);
}
