import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import {
	ButtonSmText,
	Caption,
	H3,
	P2,
} from '../../../shared/designSystem/TextComponents';
import { Icon } from '../../../shared/designSystem/Icon';
import { icons } from '../../../../enums/icons';
import { CdsRecommendation } from '../../../../generated/graphql';
import { generateCardLinkReplacements } from '../CDSCard/CDSCardLink';
import { CDSContext } from '../../../context/CDSContext';

type CDSRecommendationsProps = {
	firstName: string;
	cdsRecommendations: CdsRecommendation[];
	handleCardFocus: (index: number) => void;
	handleBHAPLink: (contentId: string) => void;
};

export const dcrContentIds = [
	'CDS-DCRRedHighRisk',
	'CDS-DCRRedLowRisk',
	'CDS-DCRYellowHighRisk',
	'CDS-DCRYellowLowRisk',
	'CDS-DCRGreenHighRisk',
	'CDS-DCRGreenLowRisk',
];

export const findDCRContent = (
	recommendations: CdsRecommendation[]
): {
	dcrRecommendation: CdsRecommendation | null;
	nonDCRRecommendations: CdsRecommendation[];
} => {
	const dcrRecommendation =
		recommendations.find((recommendation) => {
			return dcrContentIds.includes(recommendation.contentId);
		}) || null;

	const nonDCRUniqueRecommendations = recommendations
		.filter((recommendation) => {
			return !dcrContentIds.includes(recommendation.contentId);
		})
		// Removes duplicates
		.filter((value, idx, arr) => {
			return (
				idx ===
				arr.findIndex(
					(recommendation) =>
						recommendation.contentId === value.contentId
				)
			);
		});
	return {
		dcrRecommendation,
		nonDCRRecommendations: nonDCRUniqueRecommendations,
	};
};

const CDSRecommendations = ({
	firstName,
	cdsRecommendations,
	handleCardFocus,
	handleBHAPLink,
}: CDSRecommendationsProps): JSX.Element | null => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const params = useParams();
	const { contentVersion } = useContext(CDSContext);
	const participantId = params.participantId || '';
	const reportId = params.reportId || '';

	const { dcrRecommendation, nonDCRRecommendations } =
		findDCRContent(cdsRecommendations);

	if (!dcrRecommendation?.contentId) return null;

	const { contentId: dcrContentId } = dcrRecommendation;

	const prefix = (contentId: string) => `cds.recommendations.${contentId}`;

	const handleArticleLink = () => {
		const articleLink = `/results/${participantId}/${reportId}/${dcrContentId}`;

		navigate(articleLink);
	};

	const cdsCardLinkReplacements = generateCardLinkReplacements(
		dcrContentId,
		handleBHAPLink
	);

	return (
		<StyledContainer data-testid='cdsRecommendations'>
			<StyledHeader>
				<StyledHeaderText>
					<Trans
						i18nKey='cds.recommendations.panelHeader'
						values={{ firstName }}
					/>
				</StyledHeaderText>
				<StyledBadge>
					<Icon
						icon={icons.Badge}
						width={20}
						height={20}
						color={theme.color.iconBadge}
					/>
					<StyledBadgeText>
						{cdsRecommendations.length}
					</StyledBadgeText>
				</StyledBadge>
			</StyledHeader>
			<StyledSummary
				data-testid={`CDSRecommendation_${dcrRecommendation.contentId}`}
				hasLinks={nonDCRRecommendations.length > 0}
			>
				<StyledRecommendations>
					<Trans
						i18nKey={`${prefix(
							dcrRecommendation.contentId
						)}.recommendations_${contentVersion}`}
						components={{
							unorderedList: <ul />,
							listItem: <StyledListItem />,
							b: <b />,
							...cdsCardLinkReplacements,
						}}
					/>
				</StyledRecommendations>
				<StyledViewFullArticleLink>
					<Icon
						icon={icons.ExternalLink}
						color={theme.color.cdsExternalLinkIcon}
						width={20}
						height={20}
					/>
					<StyledViewFullArticleText onClick={handleArticleLink}>
						{t('cds.recommendations.viewArticle')}
					</StyledViewFullArticleText>
				</StyledViewFullArticleLink>
			</StyledSummary>
			{nonDCRRecommendations.map(
				(recommendation: CdsRecommendation, index: number) => (
					<StyledRecommendation
						key={recommendation.contentId}
						data-testid={`CDSRecommendation_${recommendation.contentId}`}
						onClick={() => {
							handleCardFocus(index);
						}}
					>
						<StyledLink>
							<StyledLinkTitle>
								{t(
									`${prefix(
										recommendation.contentId
									)}.linkTitle_${contentVersion}`
								)}
							</StyledLinkTitle>
							<Icon
								icon={icons.TraditionalArrowRight}
								iconWidth={24}
								iconHeight={24}
								color={theme.color.cdsPanelTitleLink}
							/>
						</StyledLink>
					</StyledRecommendation>
				)
			)}
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { color } }) => `
	display: flex;
	flex-direction: column;
	padding: 0px;
	height: fit-content;
	background: ${color.white};
	border: 1px solid ${color.cdsPanelBorder};
	box-shadow: 0px 2px 10px rgba(23, 24, 32, 0.1);
	border-radius: 10px;
	overflow: hidden;
`
);

const StyledHeader = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: ${spacing.lg} 0 ${spacing.md} ${spacing.md};
`
);

const StyledHeaderText = styled(H3)``;

const StyledBadge = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: 10px;
	margin-top: 5px;
	position: relative;
`;

const StyledBadgeText = styled(Caption)`
	position: absolute;
	color: white;
	top: 2px;
`;

type StyledSummaryProps = {
	hasLinks: boolean;
};

const StyledSummary = styled.div<StyledSummaryProps>(
	({ theme: { spacing, color }, hasLinks }) => `
	width: 100%;
	height: 100%
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: ${spacing.md};
	background: #f9f9f9;
	border-top: 1px solid ${color.cdsPanelBorder};
	border-bottom: ${hasLinks && `1px solid ${color.cdsPanelBorder}`};
`
);

const StyledRecommendations = styled(P2)(
	({ theme: { spacing, color } }) => `
	padding-left: ${spacing.sm};
	margin-top: 12px;
	margin-bottom: auto;
	color: ${color.cdsPanelRecommendations};
`
);

const StyledListItem = styled.li(
	({ theme: { spacing } }) => `
	margin-top: 6px;
	margin-left:${spacing.md};
  	list-style: disc;
`
);

const StyledRecommendation = styled.div(
	({ theme: { color } }) => `
	display: flex;
	flex-direction: column;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		left: 15px;
		bottom: 0;
		width: 472px;
		border-bottom: 1px solid ${color.cdsPanelBorder};
	}
	&:last-child:after {
		border: 0;
	}
`
);

const StyledLink = styled.div(
	({ theme: { spacing, color } }) => `
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	justify-content: space-between;
	padding: 0 ${spacing.lg} 0 ${spacing.lg};
	height: 63px;
	&:hover {
		background: ${color.cdsPanelTitleLinkHover};
		cursor: pointer;
	}
`
);

const StyledLinkTitle = styled.div(
	({ theme: { color, weight, fontSize } }) => `
	font-weight: ${weight.medium};
	font-size: ${fontSize._12};
	line-height: 13.5px;
	letter-height: 0.2px;
	color: ${color.cdsPanelTitleLink};
`
);

export { CDSRecommendations };

const StyledViewFullArticleLink = styled.a(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	margin-top: ${spacing.md};
	@media print {
		display: none;
	}
`
);

const StyledViewFullArticleText = styled(ButtonSmText)(
	({ theme: { color } }) => `
	margin-top: 4px;
	color: ${color.cdsExternalLink} !important;
`
);
