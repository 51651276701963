import { QnrService } from '@lh/qnr-svc-rest-client';

import { config } from 'config';
import { Auth } from 'features/auth-service';

export async function getQuestionnaireService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new QnrService({
			BASE: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
			HEADERS: {
				Authorization: `Bearer ${jwt.token}`,
			},
		}).default;
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
