import styled, { css } from 'styled-components';

import { H4, P1 } from 'components/shared/designSystem';

export const HeaderContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const HeaderSelection = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.sm};
	`
);

export const H4Med = styled(H4)(
	({ theme: { color, weight } }) => css`
		font-weight: ${weight.medium};
		color: ${color.graphTextPrimary};
	`
);

export const SelectionBox = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
	`
);

export const Box = styled.div(
	({ theme: { colors } }) => css`
		width: 78px;
		height: 19px;
		border: 2px solid ${colors.gray_60};
		background-color: ${colors.gray_80};
		border-radius: 4px;
	`
);

export const P1Faded = styled(P1)(
	({ theme: { color } }) => css`
		color: ${color.bodyTextSecondary};
	`
);
export const P1Selected = styled(P1)(
	({ theme: { color, weight } }) => css`
		color: ${color.graphTextPrimary};
		font-weight: ${weight.semi};
	`
);

export const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const FastTableContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const TableContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const QuestionContainer = styled.div<{ $selected?: boolean }>(
	({ theme: { colors, spacing }, $selected }) => css`
		padding: ${spacing.sm} 12px;
		background-color: ${$selected ? colors.gray_80 : 'transparent'};
		border: ${$selected
			? `2px solid ${colors.gray_60}`
			: `1px solid ${colors.gray_60}`};
		border-radius: 4px;
	`
);

export const EndContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const EndOfAssessment = styled(P1Faded)`
	text-align: center;
`;
