import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Field, useField } from 'formik';
import { BasicOption, LinusInputChildrenProps } from '../../../../types';
import { definedProps } from './definedProps';
import { Chip } from '../../Chip';
import { useTranslation } from 'react-i18next';
import { P1 } from '../../designSystem/TextComponents';
import { voidListener } from '../../../../utils/valueListUtils';

const ChipSelect = <T,>(props: LinusInputChildrenProps<T>): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, _, helpers] = useField(props.name);
	const { setValue } = helpers;
	const { t } = useTranslation();
	const value = props.value || [];
	const definedProps_ = definedProps(props);
	const availableOptions = props.options || [];
	const customLineBreak = props.customLineBreak || [];

	const handleClick = (item: string, e?: SyntheticEvent) => {
		e?.stopPropagation();
		const index = value.findIndex((x: string) => x === item);
		if (index >= 0) {
			value.splice(index, 1);
		} else {
			value.push(item);
		}
		setValue(value);
	};

	const renderOptions = (customLineBreak: number[]) => {
		let tempCount = 0;
		const linebreakArr = [...customLineBreak];
		return availableOptions.map(
			({ value: issue, display }: BasicOption) => {
				tempCount += 1;
				const isActive = value.includes(issue);
				const lineBreak = linebreakArr && linebreakArr[0] === tempCount;
				if (lineBreak) {
					linebreakArr.shift();
					tempCount = 0;
				}
				return (
					<React.Fragment key={issue}>
						<div
							onClick={(e: SyntheticEvent) =>
								handleClick(issue, e)
							}
							onKeyDown={voidListener}
						>
							<Chip labelTag={display} isActive={isActive} />
						</div>
						{lineBreak && <StyledLineBreak />}
					</React.Fragment>
				);
			}
		);
	};

	return (
		<StyledChipSelectContainer>
			{props.headerTitle && (
				<StyledChipSelectHeader>
					{t(props.headerTitle)}
				</StyledChipSelectHeader>
			)}
			<StyledSelectField
				onChange={field.onChange}
				width={props.width}
				{...definedProps_}
				as='div'
			>
				{renderOptions(customLineBreak)}
			</StyledSelectField>
		</StyledChipSelectContainer>
	);
};

export { ChipSelect };

const StyledChipSelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`;

const StyledChipSelectHeader = styled(P1)(
	({ theme: { weight, color } }) => `
	color: ${color.headerText};
	font-weight: ${weight.medium};
	margin-bottom: 16px;
`
);

const StyledLineBreak = styled.div`
	flex-basis: 100%;
	height: 0;
`;

type StyledSelectFieldProps = {
	width?: boolean;
};

const StyledSelectField = styled(Field)<StyledSelectFieldProps>(
	({ width }) => `
	display: flex;
	flex-wrap: wrap;
	position: relative;
	min-width: ${width || '100%'};
	align-content: start;
	align-self: center;
`
);
