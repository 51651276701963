import { UserWithOrganizationData } from 'api/user/types';
import { createContext } from 'react';

export type UserOnlyContextProps = {
	user: UserWithOrganizationData | null;
	refetchUser: () => void;
};

export const UserOnlyContext = createContext<UserOnlyContextProps>({
	user: null,
	refetchUser: () => undefined,
});
