import styled from 'styled-components';
import { AddBatteryProps } from '../shared/DataTable/schemas/patientsSchema';
import { icons } from '../../enums/icons';
import { Icon } from '../shared/designSystem/Icon';
import { Trans, useTranslation } from 'react-i18next';

export type AddBatteryForNewPatientHeaderProps = {
	patient?: AddBatteryProps;
};

const AddBatteryForNewPatientHeader = ({
	patient,
}: AddBatteryForNewPatientHeaderProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<StyledHeader>
			<StyledCenter>
				<Icon
					dataId='add-battery-for-new-patient-icon'
					icon={icons.AddUserOutlineSparkle}
					title={t`web.patients.forms.patientAdded`}
				/>
			</StyledCenter>
			<StyledColumn>
				<StyledTitle data-id='add-battery-for-new-patient-title'>{t`web.patients.forms.patientAdded`}</StyledTitle>
				<StyledParagraph data-id='add-battery-for-new-patient-create-success'>
					<Trans
						i18nKey='web.patients.forms.createSuccess'
						values={{
							entity: `${patient?.firstName} ${patient?.lastName}`,
						}}
						components={[<strong />]}
					/>
				</StyledParagraph>
				<StyledParagraph data-id='add-battery-for-new-patient-finish-assignment'>
					{t`web.patients.forms.finishAssignment`}
				</StyledParagraph>
			</StyledColumn>
		</StyledHeader>
	);
};

export { AddBatteryForNewPatientHeader };

const StyledHeader = styled.div`
	width: 100%;
`;

const StyledCenter = styled.div`
	padding: 10px;
	display: flex;
	justify-content: space-around;
`;

const StyledColumn = styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;

const StyledTitle = styled.h2(
	({ theme: { color } }) => `
	display: flex;
	align-items: center;
	padding: 0;
  color: ${color.label};
  font-size: 32px;
  line-height: 44px;
  align-self: center;
  font-weight: 500;
	`
);

const StyledParagraph = styled.p`
	margin: 10px 0;
	width: 548px;
`;
