import styled, { css } from 'styled-components';

import { StyledCard } from '../../../shared/StyledCard';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
		margin-bottom: ${spacing.lg};
	`
);

export const Card = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		padding: ${spacing.lg};
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const ScoreContainer = styled.div<{ loading: boolean }>(
	({ loading, theme }) => css`
		flex-direction: row;
		display: ${loading ? 'unset' : 'flex'};
		background-color: ${theme.colors.gray_90};
		padding: 16px;
		border-radius: 8px;
		align-items: center;
	`
);
