import { FontWeight as MosaicFontWeight } from '@lh/eng-web-mosaic-common/dist/components/atoms/Typography/Typography.types';

import React from 'react';

import { AstNode } from './parseRichText';

export type FontWeight = MosaicFontWeight;
type FormattingOptions = {
	fontWeight?: FontWeight;
};

export function astNodeToElements(
	node: AstNode | AstNode[],
	options: FormattingOptions
): JSX.Element {
	if (Array.isArray(node)) {
		return (
			<React.Fragment>
				{node.map((_node) => astNodeToElements(_node, options))}
			</React.Fragment>
		);
	}

	if (typeof node.content === 'string') {
		let element = <>{node.content}</>;

		if (node.format === 'bold') {
			const { fontWeight = '700' } = options;
			element = <b style={{ fontWeight }}>{node.content}</b>;
		}

		return <span key={node.id}>{element}</span>;
	}

	return (
		<React.Fragment key={node.id}>
			{astNodeToElements(node.content, options)}
		</React.Fragment>
	);
}
