import { User } from '@lh/eng-platform-organization-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';

import { getAssignment } from 'api/assignment';
import { getBattery, getBatteryIssue } from 'api/battery';
import { QueryKey } from 'api/query';
import { getUser } from 'api/user';
import { UserContext } from 'components/context';
import {
	BatteryAssignment,
	BatteryIssue,
	Report,
} from 'components/report/CognitiveEvaluation.types';
import {
	BatteryHeaderFragment,
	Battery as GQLBattery,
	useGetReportForParticipantLazyQuery,
} from 'generated/graphql';
import { ERROR } from 'logging/linusLogger';

type AssignmentProps = {
	assignmentId: string;
	orgId: string;
};

type BatteryProps = {
	batteryResultId: string;
};

type IssueProps = {
	batteryResultId: string;
	interpretingUser: User;
};

type Props = {
	batteryResultId: string;
	orgId: string;
	participantId: string;
};

export function useGetReport({ batteryResultId, orgId, participantId }: Props) {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();

	const [getReportForParticipant, { loading: loadingParticipant }] =
		useGetReportForParticipantLazyQuery();

	const [assignment, setAssignment] = useState<BatteryAssignment | undefined>(
		undefined
	);
	const [battery, setBattery] = useState<Report | undefined>(undefined);
	const [issue, setIssue] = useState<BatteryIssue | undefined>(undefined);
	const [participant, setParticipant] = useState<
		BatteryHeaderFragment | undefined
	>(undefined);
	const [loadingAssignment, setLoadingAssignment] = useState(false);
	const [loadingBattery, setLoadingBattery] = useState(false);
	const [loadingIssue, setLoadingIssue] = useState(false);

	useEffect(() => {
		const assignmentId = battery?.batteryResultById?.assignment?.id;
		if (!assignment && assignmentId && orgId) {
			fetchAssignment({ assignmentId, orgId });
		}
	}, [assignment, battery, orgId]);

	useEffect(() => {
		if (batteryResultId && orgId && participantId) {
			fetchBattery({ batteryResultId });
			fetchPatient({ batteryResultId, orgId, participantId });
		}
	}, [batteryResultId, orgId, participantId]);

	useEffect(() => {
		if (!!assignment?.interpretingUser && !issue && batteryResultId) {
			fetchIssue({
				batteryResultId,
				interpretingUser: assignment.interpretingUser,
			});
		}
	}, [assignment, batteryResultId, issue]);

	const fetchAssignment = useCallback(
		async ({ assignmentId, orgId }: AssignmentProps) => {
			setLoadingAssignment(true);
			try {
				const assignment = await client.fetchQuery({
					queryKey: [QueryKey.Assignment, assignmentId],
					queryFn: () => getAssignment(assignmentId),
					staleTime: Infinity,
				});

				if (assignment?.interpretingUserId) {
					const interpretingUserId = assignment.interpretingUserId;
					const user = await client.fetchQuery({
						queryKey: [QueryKey.User, orgId, interpretingUserId],
						queryFn: ({ signal }) =>
							getUser(orgId, interpretingUserId, signal),
						staleTime: Infinity,
					});

					if (user) {
						setAssignment({
							...assignment,
							interpretingUser: { ...user },
						});
					}
				}
			} catch (error) {
				ERROR(
					`Error while calling useGetReport / getAssignment: ${JSON.stringify(
						error,
						null,
						2
					)}`
				);
			} finally {
				setLoadingAssignment(false);
			}
		},
		[orgId, currentUser.id]
	);

	const fetchBattery = useCallback(
		async ({ batteryResultId }: BatteryProps) => {
			setLoadingBattery(true);
			try {
				let assignment: Partial<BatteryAssignment | undefined> =
					undefined;
				const deepBatteryResult = await client.fetchQuery({
					queryKey: [QueryKey.Battery, batteryResultId],
					queryFn: () => getBattery(batteryResultId, true),
					staleTime: Infinity,
				});

				if (deepBatteryResult?.assignmentId) {
					assignment = {
						id: deepBatteryResult.assignmentId,
					};
				}

				if (deepBatteryResult) {
					setBattery({
						batteryResultById: {
							assignment,
							assessmentResults:
								deepBatteryResult.assessmentResults,
							battery: deepBatteryResult.battery as GQLBattery,
							endTime: deepBatteryResult.endTime,
							id: deepBatteryResult.id,
							metricItems: deepBatteryResult.metricItems,
							status: deepBatteryResult.status,
							startTime: deepBatteryResult.startTime,
							rawDataUrl: deepBatteryResult.rawDataUrl,
						},
					});
				}
			} catch (error) {
				ERROR(
					`Error while calling useGetReport / getBattery: ${JSON.stringify(
						error,
						null,
						2
					)}`
				);
			} finally {
				setLoadingBattery(false);
			}
		},
		[batteryResultId]
	);

	const fetchIssue = useCallback(
		async ({ batteryResultId, interpretingUser }: IssueProps) => {
			setLoadingIssue(true);
			try {
				const issue = await client.fetchQuery({
					queryKey: [QueryKey.BatteryIssue, batteryResultId],
					queryFn: () => getBatteryIssue(batteryResultId),
					staleTime: Infinity,
				});

				if (interpretingUser && issue) {
					setIssue({
						...issue,
						reporter: {
							...interpretingUser,
						},
					});
				}
			} catch (error) {
				ERROR(
					`Error while calling useGetReport / getIssue: ${JSON.stringify(
						error,
						null,
						2
					)}`
				);
			} finally {
				setLoadingIssue(false);
			}
		},
		[batteryResultId]
	);

	const fetchPatient = useCallback(
		async ({ batteryResultId, orgId, participantId }: Props) => {
			try {
				const reportForParticipantData = (
					await getReportForParticipant({
						variables: {
							batteryResultId,
							orgId,
							participantId,
						},
					})
				).data;

				if (reportForParticipantData) {
					setParticipant({
						...reportForParticipantData.participant,
						batteryResultById: {
							cdsRecommendations:
								reportForParticipantData.participant
									?.batteryResultById?.cdsRecommendations ||
								[],
						},
					});
				}
			} catch (error) {
				ERROR(
					`Error while calling useGetReport / getParticipant: ${JSON.stringify(
						error,
						null,
						2
					)}`
				);
			}
		},
		[batteryResultId, orgId, participantId]
	);

	const data = { assignment, battery, issue, participant };
	const loading = {
		loadingAssignment,
		loadingBattery,
		loadingIssue,
		loadingParticipant,
	};
	return {
		data,
		loading,
	};
}
