import { AnalyticsAction, sendEventData } from 'analytics';
import { t } from 'i18n';
import { ExternalLink } from './ExternalLink';
import { URLS } from './constants';

type PrivacyPolicyLinkProps = {
	privacyPolicyUrl?: string;
	text?: string;
	underline?: boolean;
};

export const PrivacyPolicyLink = ({
	privacyPolicyUrl = URLS.privacyPolicy,
	text = t`web.shared.privacyPolicyLink` as string,
	underline = true,
}: PrivacyPolicyLinkProps): JSX.Element => {
	return (
		<ExternalLink
			text={text}
			href={privacyPolicyUrl}
			onClick={() =>
				sendEventData({
					eventType: AnalyticsAction.ViewedPrivacyPolicy,
				})
			}
			underline={underline}
		/>
	);
};
