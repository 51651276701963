import { create } from 'zustand';

import { MessageEnumItem } from 'enums/messageEnum';

export type State = {
	errorMessage: MessageEnumItem | undefined;
	setErrorMessage: (errorString: MessageEnumItem | undefined) => void;
};

export const useErrorMessageStore = create<State>()((set) => ({
	errorMessage: undefined,
	setErrorMessage: async (errorMessage) => set(() => ({ errorMessage })),
}));
