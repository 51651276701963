import { DefaultApiGetReasonsByOrganizationRequest } from '@lh/eng-platform-organization-service-rest-client';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

export async function getReasons(
	searchInput: DefaultApiGetReasonsByOrganizationRequest
) {
	if (!searchInput.organizationId) {
		throw new Error("useGetReasonsQuery: organizationId can't be null");
	}

	const service = await getOrganizationService();
	const { data } = await service.getReasonsByOrganization(searchInput);
	return data;
}

export function useReasonsQuery(
	searchInput: DefaultApiGetReasonsByOrganizationRequest
) {
	return useQuery({
		enabled: !!searchInput.organizationId,
		meta: {
			errorMessage: `Error fetching reasons for organization ${searchInput.organizationId}`,
		},
		placeholderData: keepPreviousData,
		queryKey: [
			QueryKey.Reasons,
			searchInput.organizationId,
			searchInput.filterValue,
		],
		queryFn: () => getReasons(searchInput),
		staleTime: Infinity,
	});
}
