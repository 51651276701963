import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Report } from 'components/report/CognitiveEvaluation.types';
import { LoadingDots } from 'components/shared/LoadingDots';
import { useShortPhrases } from 'components/shared/hooks';
import { SegmentType } from '../../../../generated/graphql';
import { Caption, H4 } from '../../../shared/designSystem/TextComponents';
import { DctClockCompositeScales } from '../../SegmentComponents/DctClockSubscore/DctClockCompositeScales';
import { DctScoreClassification } from '../../SegmentComponents/DctClockSubscore/DctClockSubscore';
import { getAssessmentData } from '../../cognitiveEvalParseData/cognitiveEvalParseData';

import { MiniModalOverview } from './MiniModalOverview/MiniModalOverview';
import { MiniModalRecordings } from './MiniModalRecordings/MiniModalRecordings';
import { MiniModalSubscores } from './MiniModalSubscores/MiniModalSubscores';

type MiniModalReportProps = {
	batteryResult: Report;
	copyClockLoaded?: () => void;
	commandClockLoaded?: () => void;
};

export const DCTClassificationString = {
	UNANALYZABLE: 'unanalyzable',
};

export function MiniModalReport({
	batteryResult,
	copyClockLoaded,
	commandClockLoaded,
}: MiniModalReportProps) {
	const { t } = useTranslation();

	const clockShortPhrases = useShortPhrases();

	const { miniModalAssessment: miniModalData } = getAssessmentData({
		batteryResult,
	});

	if (!miniModalData) {
		return (
			<LoadingContainer>
				<LoadingDots />
			</LoadingContainer>
		);
	}

	const miniModalClassification =
		(miniModalData.metricItems['dcr_classification']?.value as string) ||
		'';
	const dcrScore = miniModalData?.metricItems['dcr_score'] ?? {
		value: -1,
		algorithmVersion: '0.1',
	};

	const mmsePrediction = miniModalData?.metricItems['mmse_prediction'];
	const ficPrediction =
		miniModalData?.metricItems['functional_impairment_probability'];
	const memoryImpairmentProbability =
		miniModalData?.metricItems['mis_prediction'];

	const miniModalSubscoreSegments = miniModalData.segmentResults || [];
	const dctClockData =
		miniModalSubscoreSegments.length > 0
			? miniModalSubscoreSegments.find(
					(subscoreSegment) =>
						subscoreSegment?.segmentType === SegmentType.Clock
			  )
			: undefined;

	if (miniModalData) {
		miniModalData.features = { clockShortPhrases };
	}

	if (!dctClockData) {
		return (
			<LoadingContainer>
				<LoadingDots />
			</LoadingContainer>
		);
	}

	const dctClockMetricData = dctClockData.metricItems;
	const classification = dctClockMetricData['DCTClassificationString']
		? (dctClockMetricData['DCTClassificationString']
				.value as DctScoreClassification)
		: null;

	return (
		<StyledContainer>
			<StyledSection>
				<MiniModalOverview
					mmsePrediction={mmsePrediction}
					ficPrediction={ficPrediction}
					memoryImpairmentProbability={memoryImpairmentProbability}
					score={dcrScore}
					classification={miniModalClassification}
					miniModalSubscoreSegments={miniModalSubscoreSegments}
				/>
			</StyledSection>
			<StyledSection>
				<MiniModalSubscores
					miniModalSubscoreSegments={miniModalSubscoreSegments}
				/>
			</StyledSection>
			<MiniModalRecordings
				miniModalRecordingSegments={miniModalSubscoreSegments}
				copyClockLoaded={copyClockLoaded}
				commandClockLoaded={commandClockLoaded}
				clockConcernsEnabled={
					miniModalData?.features?.clockShortPhrases
				}
			/>
			{!!classification &&
				classification !== DCTClassificationString.UNANALYZABLE && (
					<StyledPrintOnlyWrapper>
						<StyledH4>{t`web.dcrReport.print.clockDrawingCompositeScales.title`}</StyledH4>
						<DctClockCompositeScales
							metricData={dctClockMetricData}
						/>
					</StyledPrintOnlyWrapper>
				)}
			<FooterContainer>
				<Footer>{t`web.report.DCTClock.footerWithTrademark`}</Footer>
			</FooterContainer>
		</StyledContainer>
	);
}

const LoadingContainer = styled.div`
	height: 40vh;
`;

const StyledContainer = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;

		@media screen {
			margin-top: ${spacing.xl};
		}
	`
);

const StyledPrintOnlyWrapper = styled.div`
	display: none;

	@media print {
		display: block;
	}
`;

const StyledH4 = styled(H4)(
	({ theme: { spacing } }) => css`
		margin-left: ${spacing.lg};
	`
);

const StyledSection = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.xl};
	`
);

const FooterContainer = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.sm};
	`
);

const Footer = styled(Caption)(
	({ theme: { color } }) => css`
		color: ${color.graphTextSecondary};
	`
);
