import { Patient, Subject } from '@lh/eng-platform-subject-service-rest-client';

import { Space } from '@mantine/core';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { LinusInput } from 'components/shared/Forms/Components/LinusInput';
import { PHONE_NUMBER_REGEX } from 'components/shared/Forms/Components/PhoneNumber';
import { InfoMessage } from 'components/shared/InfoMessage';
import { LinusModal } from 'components/shared/LinusModal';
import { ButtonLg } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { MessageEnumItem } from 'enums/messageEnum';
import i18n from 'i18n';
import { CareParterModalIds, useModalStore } from 'store';
import { groupIntoN } from 'utils/arrays';
import { CarePartnerType, PartnerInfo } from '../CarePartner.types';
import { useErrorMessageStore } from '../useErrorMessageStore';

import {
	Content,
	FormContainer,
	InputContainer,
	Row,
	StyledButtonAndErrorRow,
	StyledButtonRow,
	StyledInfoMessage,
	ValidationError,
} from './ControllModal.style';
import { InitialValues } from './ControllModal.types';
import {
	INPUT_RAW_DATA,
	getInitialValues,
	getModalInfo,
} from './ControllModal.utils';

export type CarePartnerModalPayload = {
	partnerInfo?: Subject;
	patientInfo: Patient;
	onSubmit: (carePartnerInfo: PartnerInfo) => Promise<void>;
};

type CarePartnerModalProps = {
	modalId: CareParterModalIds;
};

export function CarePartnerModal({ modalId }: Readonly<CarePartnerModalProps>) {
	const payload = useModalStore((state) => state.payload[modalId]);
	const visibleCarePartner = useModalStore((state) => state.visible[modalId]);
	const closeModal = useModalStore((state) => state.close);

	const { errorMessage, setErrorMessage } = useErrorMessageStore();

	function handleHideCareModal() {
		setErrorMessage(undefined);
		closeModal(modalId);
	}

	if (!payload) return null;
	if (!visibleCarePartner) return null;

	let type = CarePartnerType.Add;
	if (modalId === 'EditCarePartner') type = CarePartnerType.Edit;

	return (
		<ContollModal
			{...payload}
			type={type}
			errorMessage={errorMessage}
			onCancel={handleHideCareModal}
		/>
	);
}

type ControllModalProps = CarePartnerModalPayload & {
	errorMessage?: MessageEnumItem;
	type: CarePartnerType;
	onCancel: () => void;
};

function ContollModal({
	errorMessage,
	partnerInfo,
	patientInfo,
	type,
	onCancel,
	onSubmit,
}: Readonly<ControllModalProps>) {
	const { t } = useTranslation();

	const { ctaText, info, title } = getModalInfo(type, patientInfo);
	const initialValues = getInitialValues(patientInfo, partnerInfo);

	return (
		<LinusModal
			title={title}
			titleIcon={icons.CareGiver}
			width='770px'
			onClose={onCancel}
			dataId='care-partner-modal'
			data-id='care-partner-modal'
			data-testid='care-partner-modal'
		>
			<Space h='8px' />
			<Content data-testid='care-partner-modal-info'>
				{info}
				<Formik
					initialValues={initialValues}
					validationSchema={validateCarePartnerSchema}
					onSubmit={async (carePartnerInfo, formikHelpers) => {
						await onSubmit(carePartnerInfo);
						formikHelpers.setSubmitting(false);
					}}
				>
					{(formikProps) => {
						return (
							<FormContainer>
								<InputContainer>
									{groupIntoN(INPUT_RAW_DATA, 2).map(
										(row) => (
											<Row key={`${row[0].name}-row`}>
												{row.map((input) => {
													return (
														<LinusInput
															key={input.name}
															name={input.name}
															label={input.label}
															width='calc(50% - 16px)'
															type={input.type}
															dropdownOptions={
																input.dropdownOptions
															}
															dropUpSpace={
																input.dropUpSpace
															}
														/>
													);
												})}
											</Row>
										)
									)}
								</InputContainer>
								<ValidationErrors {...formikProps} />
								<StyledButtonAndErrorRow>
									{errorMessage && (
										<StyledInfoMessage>
											<InfoMessage
												messageEnum={errorMessage}
												showIf={!!errorMessage}
											/>
										</StyledInfoMessage>
									)}
									<StyledButtonRow>
										<ButtonLg
											dataId='cancel-cta-care-partner'
											dataTestId='cancel-cta-care-partner'
											onClick={onCancel}
											text={t`web.patients.forms.cancelCTA`}
											width='200px'
										/>
										<ButtonLg
											dataId='submit-cta-care-partner'
											dataTestId='submit-cta-care-partner'
											primary
											disabled={formikProps.isSubmitting}
											loading={formikProps.isSubmitting}
											text={ctaText}
											type='submit'
											width='200px'
										/>
									</StyledButtonRow>
								</StyledButtonAndErrorRow>
							</FormContainer>
						);
					}}
				</Formik>
			</Content>
		</LinusModal>
	);
}

function ValidationErrors({ errors, touched }: FormikProps<InitialValues>) {
	const { t } = useTranslation();

	if (
		(errors.firstName && touched['firstName']) ||
		(errors.lastName && touched['lastName'])
	)
		return (
			<ValidationError
				data-id='care-partner-form-errors-all-fields'
				data-testid='care-partner-form-errors-all-fields'
			>
				{t('web.patients.carePartner.form.errors.allFields')}
			</ValidationError>
		);

	if (
		errors.contactEmail &&
		errors.contactPhone &&
		touched['contactEmail'] &&
		touched['contactPhone']
	)
		return (
			<ValidationError>
				{t('web.patients.carePartner.form.errors.emailOrPhone')}
			</ValidationError>
		);

	return null;
}

const validateCarePartnerSchema = yup.object().shape(
	{
		firstName: yup
			.string()
			.required(i18n.t('web.patients.carePartner.form.errors.firstName')),
		lastName: yup
			.string()
			.required(i18n.t('web.patients.carePartner.form.errors.lastName')),
		contactPhone: yup
			.string()
			.optional()
			.matches(
				PHONE_NUMBER_REGEX,
				i18n.t('web.patients.carePartner.form.errors.phone')
			)
			.when('contactEmail', {
				is: (value: string) => !value?.length,
				then: yup
					.string()
					.required(
						i18n.t('web.patients.carePartner.form.errors.phone')
					),
			}),
		contactEmail: yup
			.string()
			.optional()
			.email(i18n.t('web.patients.carePartner.form.errors.email'))
			.when('contactPhone', {
				is: (value: string) => !value?.length,
				then: yup
					.string()
					.required(
						i18n.t('web.patients.carePartner.form.errors.email')
					),
			}),
		patientRelationship: yup
			.string()
			.required(
				i18n.t('web.patients.carePartner.form.errors.relationship')
			),
	},
	[
		['contactPhone', 'contactEmail'], // this is disabling dependency ordering for these fields b/c they depend on each other - https://github.com/jquense/yup/issues/176#issuecomment-369925782
	]
);
