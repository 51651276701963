import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { Accordion } from 'components/shared/Accordion';
import { ButtonSm, H2, P1, P2 } from 'components/shared/designSystem';
import { LinusModal } from 'components/shared/LinusModal';

import { LegendRow } from '../../sharedComponents';

type ScoringDetailsModalProps = { onClose: () => void };

export const ScoringDetailsModal = ({ onClose }: ScoringDetailsModalProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const descriptionAccordionRef = useRef(null);
	const referencesAccordionRef = useRef(null);

	return (
		<LinusModal style={{ width: '826px' }} onClose={onClose}>
			<StyledContents>
				<H2>{t('web.report.gds.scoringDetailsModal.title')}</H2>
				<StyledScrollArea>
					<div>
						<div>
							{t(
								'web.report.gds.scoringDetailsModal.instructions'
							)}
						</div>
						<StyledQuestionList>
							<li>
								{t(
									'web.report.gds.scoringDetailsModal.noQuestions'
								)}
							</li>
							<li>
								{t(
									'web.report.gds.scoringDetailsModal.yesQuestions'
								)}
							</li>
						</StyledQuestionList>
					</div>
					<Divider />
					<StyledRangesContainer>
						<div>
							<P1>
								{t(
									'web.report.gds.scoringDetailsModal.interpretationRangesHeading'
								)}
							</P1>
							<P2>
								{t(
									'web.report.gds.scoringDetailsModal.interpretationRangesSubtitle'
								)}
							</P2>
						</div>
						<div>
							<LegendRow
								color={theme.color.noIndication}
								range={t(
									'web.report.gds.resultsSummaryCard.noneRange'
								)}
								label={t(
									'web.report.gds.resultsSummaryCard.noneLabel'
								)}
							/>
							<LegendRow
								color={theme.color.mildIndication}
								range={t(
									'web.report.gds.resultsSummaryCard.mildRange'
								)}
								label={t(
									'web.report.gds.resultsSummaryCard.mildLabel'
								)}
							/>
							<LegendRow
								color={theme.color.moderateIndication}
								range={t(
									'web.report.gds.resultsSummaryCard.moderateRange'
								)}
								label={t(
									'web.report.gds.resultsSummaryCard.moderateLabel'
								)}
							/>
							<LegendRow
								color={theme.color.severeIndication}
								range={t(
									'web.report.gds.resultsSummaryCard.severeRange'
								)}
								label={t(
									'web.report.gds.resultsSummaryCard.severeLabel'
								)}
							/>
						</div>
					</StyledRangesContainer>
					<Accordion
						ref={descriptionAccordionRef}
						shouldScroll={true}
						title={
							<P1Med>
								{t(
									'web.report.gds.scoringDetailsModal.extendedDescriptionLabel'
								)}
							</P1Med>
						}
					>
						<StyledTextContent>
							{t(
								'web.report.gds.scoringDetailsModal.long.extendedDescriptionP1'
							)}
						</StyledTextContent>
						<StyledTextContent>
							{t(
								'web.report.gds.scoringDetailsModal.long.extendedDescriptionP2'
							)}
						</StyledTextContent>
						<StyledTextContent>
							{t(
								'web.report.gds.scoringDetailsModal.long.extendedDescriptionP3'
							)}
						</StyledTextContent>
					</Accordion>
					<Accordion
						ref={referencesAccordionRef}
						shouldScroll={true}
						title={
							<P1Med>
								{t(
									'web.report.gds.scoringDetailsModal.referencesLabel'
								)}
							</P1Med>
						}
					>
						<ol>
							<li>
								<StyledTextContent>
									{t(
										'web.report.gds.scoringDetailsModal.long.reference1'
									)}
								</StyledTextContent>
							</li>
							<li>
								<StyledTextContent>
									{t(
										'web.report.gds.scoringDetailsModal.long.reference2'
									)}
								</StyledTextContent>
							</li>
							<li>
								<StyledTextContent>
									{t(
										'web.report.gds.scoringDetailsModal.long.reference3'
									)}
								</StyledTextContent>
							</li>
							<li>
								<StyledTextContent>
									{t(
										'web.report.gds.scoringDetailsModal.long.reference4'
									)}
								</StyledTextContent>
							</li>
						</ol>
					</Accordion>
				</StyledScrollArea>
				<StyledButtonContainer>
					<ButtonSm
						onClick={onClose}
						text={t('web.report.gds.scoringDetailsModal.close')}
					/>
				</StyledButtonContainer>
			</StyledContents>
		</LinusModal>
	);
};

const StyledContents = styled.div(
	({ theme: { spacing } }) =>
		css`
			display: flex;
			flex-direction: column;
			gap: ${spacing.sm};
			overflow-y: hidden;
		`
);

const StyledScrollArea = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
		overflow-y: auto;
	`
);

const StyledQuestionList = styled.div(
	({ theme: { spacing } }) =>
		css`
			list-style-type: disc;
			padding-left: ${spacing.sm};
		`
);

const Divider = styled.div(
	({ theme: { color } }) =>
		css`
			background-color: ${color.infoSeparator};
			height: 1px;
		`
);

const StyledRangesContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		padding: ${spacing.sm} 0;
		gap: ${spacing.sm};
	`
);

const StyledButtonContainer = styled.div(
	() =>
		css`
			display: flex;
			flex-direction: row;
			justify-content: center;
		`
);

const StyledTextContent = styled(P1)(
	({ theme: { colors } }) => css`
		color: ${colors.gray_30};
	`
);

const P1Med = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);
