import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { icons } from '../../../../enums/icons';
import { StyledCard } from '../../StyledCard';
import { Icon } from '../../designSystem/Icon';

export interface LinusSearchBarProps<T> {
	placeholder?: string;
	setIsFilteringData: React.Dispatch<React.SetStateAction<boolean>>;
	searchableList: T[];
	onChange: (items: T[]) => void;
	filterFunction: (filter: string, candidate: T) => boolean;
	searchComponentMargin?: string;
	searchComponentWidth?: string;
}

export const LinusSearchBar = <T,>({
	placeholder = '',
	setIsFilteringData,
	searchableList,
	filterFunction,
	onChange,
	searchComponentMargin,
	searchComponentWidth,
}: LinusSearchBarProps<T>): JSX.Element => {
	const { t } = useTranslation();
	const [searchText, setSearchText] = useState('');
	const [displayClearButton, setDisplayClearButton] = useState(false);

	useEffect(() => {
		onChange(searchableList);
	}, [searchableList]);

	const onChangeHandler: ChangeEventHandler<HTMLInputElement> = async (
		event
	) => {
		const input = event.target.value;
		const isFiltering = input.length > 0;

		setIsFilteringData(isFiltering);
		setDisplayClearButton(isFiltering);
		setSearchText(input);

		const filteredList = searchableList.filter((candidate) => {
			return filterFunction(input, candidate);
		});

		onChange(filteredList);
	};

	// Default to the width of the placeholder
	if (!searchComponentWidth) {
		searchComponentWidth = placeholder ? `${placeholder.length}ch` : '';
	}

	const onClearHandler = async () => {
		setIsFilteringData(false);
		setDisplayClearButton(false);
		setSearchText('');
		onChange(searchableList);
	};

	return (
		<>
			<StyledContainer>
				<StyledSearchComponent
					$margin={searchComponentMargin}
					width={searchComponentWidth}
				>
					<Icon
						icon={icons.MagnifyingGlass}
						width={24}
						height={24}
						title={t`web.shared.search.searchIconTitle`}
					/>
					<StyledInput
						value={searchText}
						onChange={onChangeHandler}
						data-testid={'Search Bar'}
						placeholder={placeholder}
						width={searchComponentWidth}
					/>
					<Icon
						icon={icons.Close}
						width={11}
						height={11}
						title={t`web.shared.search.clearSearchButtonTitle`}
						onClick={onClearHandler}
						visible={displayClearButton}
					/>
				</StyledSearchComponent>
			</StyledContainer>
		</>
	);
};

const StyledContainer = styled(StyledCard)(
	() => `
	display: flex;
	box-shadow: none;
	box-sizing: border-box;
`
);

type StyledSearchComponentProps = {
	$margin?: string;
	width?: string;
};

const StyledSearchComponent = styled(StyledCard)<StyledSearchComponentProps>(
	({ $margin, width, theme: { borderRadius, color, spacing, fontSize } }) => `
	display: flex;
	align-items: center;
	padding: 0 ${spacing.md};
	position: relative;
	flex: flex-shrink;
	font-size: ${fontSize._16};
	height: 48px;
  	width: ${width || ''};
  	margin: ${$margin || ''};
	color: ${color.formText};
	border-radius: ${borderRadius.searchBox};
	box-shadow: none;

	border: 1px solid ${color.inputBorder};

	&::placeholder {
		color: ${color.formText};
	}

	&:hover {
		border: 1px solid ${color.inputHover};
	}

	&:focus-within {
		border: 1px solid ${color.inputFocus};
		outline: none;
	}
`
);

const StyledInput = styled.input(
	({ width, theme: { color, spacing, fontSize } }) => `
	display: flex;
	padding: 0 ${spacing.sm};
	box-sizing: border-box;
	position: relative;
  width: ${width || ''};
	font-size: ${fontSize._16};
	color: ${color.formText};

	&::placeholder {
		color: ${color.formText};
	}

	&:hover {
		border: none;
	}

	&:focus {
		outline: none;
	}
	`
);
