import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';
import {
	GradeMarks,
	LocalizationSpec,
	Primitive,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import { getConfig } from 'config';
import { locale as i18nLocale } from 'enums/locale';

import { Report } from './CognitiveEvaluation.types';

export function getQuestionnaireScore(
	qnrData: QuestionnaireSpecWithScoringResult
) {
	if (!qnrData) return 0;

	if (qnrData.scores.total) {
		return Number(qnrData.scores.total.score);
	}

	if (qnrData.scores.indication) {
		return qnrData.scores.indication.score;
	}

	return -1;
}

function localizationArrayToObject(item: LocalizationSpec) {
	const qnrTranslations: Record<string, string> = {};

	for (const i of item.translations) {
		qnrTranslations[i.key] = i.translation;
	}

	return qnrTranslations;
}

export function getQuestionnaireTranslations(
	qnrData: QuestionnaireSpecWithScoringResult
) {
	const { locale } = getConfig();

	let defaultLocaleTranslations: Record<string, string> = {};
	let localizationTranslations: Record<string, string> = {};

	if (qnrData) {
		for (const item of qnrData.localizations) {
			if (item.locale.replace('_', '-') === i18nLocale.EnUs.value) {
				defaultLocaleTranslations = localizationArrayToObject(item);
			}

			if (
				item.locale.replace('_', '-').toLowerCase() ===
				locale.value.toLowerCase()
			) {
				localizationTranslations = localizationArrayToObject(item);
				break;
			}
		}

		if (Object.values(localizationTranslations).length > 0) {
			return localizationTranslations;
		}
	}

	return defaultLocaleTranslations;
}

export type QnrRowData = {
	question: string;
	answers:
		| {
				answerText: string;
				grade: GradeMarks;
				value: Primitive;
		  }
		| {
				totalScore: number | string;
		  };
};

export function parseQuestionnaireData(
	qnrId: string,
	data?: Report,
	segmentResult?: DeepSegmentResult
) {
	let result: DeepSegmentResult | null = null;

	if (segmentResult) {
		return {
			segmentResultId: segmentResult.id,
		};
	}

	if (data?.batteryResultById) {
		for (const assessment of data.batteryResultById.assessmentResults) {
			for (const segment of assessment.segmentResults) {
				if (segment.segment.metadata?.qnrId === qnrId) {
					result = segment;
				}
			}
		}
		if (result) {
			return {
				segmentResultId: result.id,
			};
		}
	}

	return { segmentResultId: '' };
}
