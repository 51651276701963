import { t } from 'i18n';
import styled from 'styled-components';
import { ButtonSm } from './designSystem/ButtonSm';
import { LinusModal, ModalProps } from './LinusModal';

interface LinusModalDialogProps extends ModalProps {
	acceptButtonCallback?: () => void;
	acceptButtonText?: string;
	declineButtonCallback?: () => void;
	declineButtonText?: string;
	dataId?: string;
}

const LinusModalDialog = ({
	dataId,
	children,
	onClose,
	title,
	titleIcon,
	subTitle,
	hideCloseButton,
	acceptButtonCallback,
	acceptButtonText = t('web.shared.acceptButton'),
	declineButtonCallback,
	declineButtonText = t('web.shared.cancelButton'),
	width,
	titleIconColor,
}: LinusModalDialogProps): JSX.Element => {
	if (!acceptButtonCallback && !declineButtonCallback && hideCloseButton) {
		throw new Error(
			'You must provide someway to exit modal, either a button or the x button in the corner'
		);
	}
	const centerButton = !acceptButtonCallback || !declineButtonCallback;
	return (
		<LinusModal
			dataId={dataId}
			onClose={onClose}
			title={title}
			titleIcon={titleIcon}
			titleIconColor={titleIconColor}
			subTitle={subTitle}
			hideCloseButton={hideCloseButton}
			width={width}
		>
			<StyledChild>{children}</StyledChild>
			<StyledActionRow centerButton={centerButton}>
				{declineButtonCallback && (
					<ButtonSm
						text={declineButtonText}
						onClick={declineButtonCallback}
						width='175px'
						dataId='decline-button'
					/>
				)}
				{acceptButtonCallback && (
					<StyledButtonSm>
						<ButtonSm
							text={acceptButtonText}
							onClick={acceptButtonCallback}
							primary={true}
							width='175px'
							dataId='accept-button'
						/>
					</StyledButtonSm>
				)}
			</StyledActionRow>
		</LinusModal>
	);
};

export { LinusModalDialog };

const StyledActionRow = styled.div<{ centerButton: boolean }>(
	({ centerButton, theme: { spacing } }) => `
	display: flex;
	justify-content: ${centerButton ? 'center' : 'space-around'};
	padding-top:${spacing.md};
	width:100%;
`
);

const StyledChild = styled.div(
	({ theme: { spacing } }) => `
	padding:${spacing.md} 0;
`
);

const StyledButtonSm = styled.div(
	({ theme: { spacing } }) => `
	margin-left: ${spacing.sm};
`
);
