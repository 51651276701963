import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

import { usePolling } from 'components/shared/hooks/usePolling';
import { icons } from 'enums/icons';
import { ERROR } from 'logging/linusLogger';

import { Icon } from '../Icon';

export type LoadingButtonProps<T> = {
	text: string;
	loadingText?: string;
	type?: 'submit' | 'reset' | 'button';
	disabled?: boolean;
	width?: number;
	interval?: number;
	maxAttempts?: number;
	onGetData: () => Promise<T>;
	onPollFailure?: (error?: any) => never;
	onPollSuccess?: (response: any) => Promise<void>;
};

export const LoadingButton = <T,>({
	text,
	loadingText,
	type = 'button',
	disabled,
	width,
	interval,
	maxAttempts,
	onGetData,
	onPollSuccess,
	onPollFailure,
}: LoadingButtonProps<T>) => {
	const { t } = useTranslation();
	const [isPolling, startPolling] = usePolling({
		interval,
		retryCount: maxAttempts,
		onGetData,
		onSuccess: (response) => {
			onPollSuccess && onPollSuccess(response);
		},
		onFailure: (error) => {
			ERROR('Some error occurred while polling', error);
			onPollFailure && onPollFailure(error);
		},
	});

	const showLoadingText = loadingText
		? loadingText
		: `${t('web.shared.downloading')}...`;
	const displayText = isPolling ? showLoadingText : text;
	return (
		<Button
			onClick={startPolling}
			type={type || 'button'}
			disabled={disabled || isPolling}
			width={width}
			className='loading-button'
		>
			{isPolling ? (
				<AnimatedIcon>
					<Icon icon={icons.LoaderWhite} />
				</AnimatedIcon>
			) : (
				<Icon icon={icons.Download} />
			)}
			{displayText}
		</Button>
	);
};

const rotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const AnimatedIcon = styled.div`
	animation: ${rotateAnimation} 1s linear infinite;
`;

type LoadingButtonStyleProps = {
	width?: number;
};

const Button = styled.button<LoadingButtonStyleProps>(
	({
		disabled,
		width,
		theme: { fontSize, weight, color, spacing, boxShadow },
	}) => `
    display: flex;
	align-items: center;
	justify-content: space-evenly;
    gap: ${spacing.sm};
	transition: 0.10s ease all;
    border-radius: 30px;
	min-width: 170px;
    width: ${width + 'px' || 'auto'};
    height: 40px;
    cursor: ${disabled ? 'not-allowed' : 'auto'};
    background: ${color.primaryButtonBg};
    padding: ${spacing.xs} ${spacing.md};
    border: none;
    color: ${color.white};
    &:hover {
		background: ${disabled ? color.primaryButtonBg : color.primaryButtonHover};
		cursor: ${disabled ? 'not-allowed' : 'pointer'};
		box-shadow:${disabled ? 'none' : boxShadow.button};
	}

	&:focus {
		outline: none;
		background: ${color.primaryButtonHover};
		border:${' 2px solid ' + color.primaryButtonFocusBorder};
		color: ${color.primaryButtonFocusText};
	}
	
	font-size: ${fontSize._14};
	line-height: 18px;
	letter-spacing: 0.25px;
	font-weight: ${weight.regular};
	padding: 0 ${spacing.md};
    `
);
