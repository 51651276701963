import { useContext } from 'react';
import { FeatureType } from '../../../generated/graphql';
import { UserContext } from '../../context/UserContext';

export const useSessionIssue = (): boolean => {
	const { currentUser } = useContext(UserContext);
	return currentUser.organizationFeatures?.some(
		(feature) => feature === FeatureType.RegisterAssignmentIssue
	);
};
