import { Divider } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { ScoreInterpretation } from '../ScoreInterpretation';

import {
	Header,
	ScoreInterpretationWrapper,
	SummaryCardContainer,
	Title,
	Tooltip,
} from './SummaryCard.style';

type SummaryCardProps = {
	score: number;
	type?: 'iadl' | 'adl';
};

export function SummaryCard({
	score,
	type = 'iadl',
}: Readonly<SummaryCardProps>) {
	const { t } = useTranslation();

	const maxScore = type === 'iadl' ? 10 : 7;
	return (
		<SummaryCardContainer>
			<Header>
				<Title>{t(`web.report.${type}.iadlReport`)}</Title>
				<LinusTooltip
					overlay={
						<Tooltip>{t(`web.report.${type}.tooltip`)}</Tooltip>
					}
					tooltipIcon={
						<Icon
							icon={icons.InfoOutlined}
							viewBox='0 0 20 20'
							iconWidth={24}
							iconHeight={24}
						/>
					}
				/>
			</Header>
			<Divider color={theme.colors.gray_60} />
			<ScoreInterpretationWrapper>
				<ScoreInterpretation
					score={`${score}/${maxScore}`}
					text={
						<Trans
							i18nKey={`web.report.${type}.assistance`}
							values={{
								score,
								maxScore,
							}}
						/>
					}
				/>
			</ScoreInterpretationWrapper>
		</SummaryCardContainer>
	);
}
