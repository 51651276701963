import styled from 'styled-components';

import { Flex } from '@mantine/core';

import { theme } from 'components/providers/StyleProvider/theme';
import { Caption, H4 } from 'components/shared/designSystem/TextComponents';
import { LeftNotch } from 'components/shared/designSystem/Icons/svgs/Svgs';
import { t } from 'i18n';

import { TEXT } from '../Pathway.i18n';

interface Props {
	type: 'immediateRecall' | 'delayedRecall';
	name: string;
	score: number | null;
}

export function PersonalizedTag({ type, name, score }: Props) {
	const textMap: Record<typeof type, string> = {
		immediateRecall: t(TEXT.personalizedTag.immediateRecall),
		delayedRecall: t(TEXT.personalizedTag.delayedRecall),
	};

	const data = {
		text: t(textMap[type], { name }),
		scoreTag: t(TEXT.personalizedTag.score, {
			value: score,
			maxValue: 3,
		}),
	};

	return (
		<Container>
			<Text value={data.text} />
			<ScoreTag value={data.scoreTag} />
		</Container>
	);
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<Flex
			data-testid='personalised-tag'
			className='pathway-block__personalized-tag'
			style={{
				width: '100%',
				position: 'relative',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: 8,
				padding: 8,
				borderRadius: 6,
				backgroundColor: theme.colors.white,
				border: `1px solid ${theme.colors.gray_30}`,
			}}
		>
			{children}
		</Flex>
	);
}

function Text({ value }: { value: string }) {
	return (
		<Caption
			style={{
				textTransform: 'uppercase',
				color: theme.colors.blue_darkest,
				fontWeight: theme.weight.medium,
			}}
		>
			{value}
		</Caption>
	);
}

function ScoreTag({ value }: { value: string }) {
	return (
		<Flex
			style={{
				padding: 4,
				borderRadius: 8,
				backgroundColor: theme.colors.gray_80,
			}}
		>
			<H4 style={{ fontWeight: theme.weight.medium }}>{value}</H4>
		</Flex>
	);
}

function Tooltip({ children }: { children: React.ReactNode }) {
	const width = { component: 220, arrow: 8, offset: 8.5 };

	return (
		<Flex
			className='pathway-block__personalized-tag__tooltip'
			style={{
				[`@media (min-width: 960px)`]: {
					position: 'absolute',
					top: '-30%',
					right: -(width.component + width.arrow + width.offset),
					maxWidth: width.component,
				},
			}}
		>
			<ToolTipWrapper>
				<LeftNotch />
			</ToolTipWrapper>
			{children}
		</Flex>
	);
}

const ToolTipWrapper = styled.div`
	@media only screen and (max-width: 960px) {
		display: none;
	}
	@media only screen and (min-width: 960px) {
		align-items: center;
		display: flex;
		height: 100%;
		left: -8px;
		position: absolute;
		z-index: 1;
	}
`;

PersonalizedTag.Tooltip = Tooltip;
