import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { H3, P2, Subtitle } from 'components/shared/designSystem';

export const Wrapper = styled(motion.div)(
	({ theme: { color } }) => css`
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		position: fixed;
		width: 248px;
		height: 100vh;
		overflow: auto;
		right: 0;
		top: 0;
		background: ${color.white};
	`
);

export const HeaderRow = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	text-align: center;
`;
export const StyledAvatar = styled.div(
	({ theme: { spacing } }) => css`
		width: 160px;
		height: 160px;
		border-radius: 50%;
		padding: 0;
		margin: 0 0 ${spacing.md} 0;
	`
);
export const StyledH3 = styled(H3)(
	({ theme: { spacing } }) => css`
		margin: 0 0 ${spacing.sm};
	`
);

export const List = styled.ul`
	width: 100%;
	position: relative;
`;
export const Item = styled.li(
	({ theme: { spacing } }) => css`
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0 0 ${spacing.lg} 0;
	`
);
export const Label = styled(P2)(
	({ theme: { color, spacing } }) => `
	color: ${color.textBlue};margin: 0 0 ${spacing.xs} 0;
	`
);
export const Text = styled(Subtitle)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
		line-height: 22px;
		margin: 0;
	`
);

export const Separator = styled.div(
	({ theme: { color } }) => css`
		position: relative;
		width: 100%;
		border-bottom: 1px solid ${color.infoSeparator};
		margin-top: 25px;
		margin-bottom: 25px;
	`
);

export const Link = styled.a`
	line-height: 19px;
	letter-spacing: 0.5px;
`;

export const participantInfoVariants = {
	initial: {
		opacity: 0.25,
		right: '-100px',
	},
	in: {
		opacity: 1,
		right: '0px',
	},
	out: {
		opacity: 0.25,
		right: '-100px',
	},
};

export const participantInfoTransition = {
	type: 'spring',
	ease: 'anticipate',
	duration: 0.5,
};
