import { useTranslation } from 'react-i18next';

import { IssueType } from 'generated/graphql';
import { HeaderIcon } from '../HeaderIcon/HeaderIcon';
import { ModalIconComponent } from '../ModalIcon/ModalIconComponent';
import {
	AdditionalNotesBody,
	AdditionalNotesContainer,
	AdditionalNotesHeader,
	AttestationModalIconContainerLarge,
	AttestationModalIconContainerMedium,
	Li,
	ReportByBody,
	ReportByBodyContainer,
	ReportedByContainer,
	ReportedByTitle,
	SessionObservationsContainer,
	SmallSectionHeader,
	ThirdSection,
	Ul,
} from '../TestAdministratorNotes.style';

import { SessionObservationsProps } from './SessionObservations.interface';

const OPTIONS_TO_TRANS_KEYS = {
	[IssueType.Declined]: 'battery.adminQnr.declined',
	[IssueType.Device]: 'battery.adminQnr.device',
	[IssueType.Fatigue]: 'battery.adminQnr.alertness',
	[IssueType.Hearing]: 'battery.adminQnr.hearing',
	[IssueType.Interrupt]: 'battery.adminQnr.interruption',
	[IssueType.Motor]: 'battery.adminQnr.drawing',
	[IssueType.NoResponse]: 'battery.adminQnr.gaveUp',
	[IssueType.TechStruggle]: 'battery.adminQnr.technology',
	[IssueType.Vision]: 'battery.adminQnr.vision',
	[IssueType.PrimaryLang]: 'battery.adminQnr.language',
};

export const SessionObservations = ({
	issues,
	additionalNotes,
	reporter,
}: SessionObservationsProps) => {
	const { t } = useTranslation();

	const reporterName = `${reporter?.firstName} ${reporter?.lastName}`;

	return (
		<ThirdSection data-testid='session-observations-base-container'>
			<SessionObservationsContainer>
				<SmallSectionHeader data-testid='session-observations-title'>
					{t('web.report.testAdminstratorNotes.sessionObservations')}
					<AttestationModalIconContainerLarge>
						<HeaderIcon
							text={t(
								'web.report.testAdminstratorNotes.patientObservations'
							)}
						/>
					</AttestationModalIconContainerLarge>
					<AttestationModalIconContainerMedium>
						<ModalIconComponent
							titleText={t(
								'web.report.testAdminstratorNotes.sessionObservations'
							)}
							contentText={t(
								'web.report.testAdminstratorNotes.patientObservations'
							)}
						/>
					</AttestationModalIconContainerMedium>
				</SmallSectionHeader>
				{issues && (
					<Ul data-testid='session-observations-issues-container'>
						{issues.map((option) => {
							return (
								<Li key={option}>
									{t(OPTIONS_TO_TRANS_KEYS[option])}
								</Li>
							);
						})}
					</Ul>
				)}
			</SessionObservationsContainer>
			{additionalNotes && additionalNotes?.length > 0 && (
				<AdditionalNotesContainer data-testid='session-observations-additional-notes-container'>
					<AdditionalNotesHeader data-testid='session-observations-additional-notes-header'>
						{t('web.report.testAdminstratorNotes.additionalNotes')}
					</AdditionalNotesHeader>

					<AdditionalNotesBody data-testid='session-observations-additional-notes-body'>
						{additionalNotes}
					</AdditionalNotesBody>
				</AdditionalNotesContainer>
			)}
			<ReportedByContainer data-testid='session-observations-reported-by-container'>
				<ReportedByTitle data-testid='session-observations-reported-by-title'>
					{t('web.report.testAdminstratorNotes.reportedBy')}
				</ReportedByTitle>
				<ReportByBodyContainer>
					<ReportByBody data-testid='session-observations-reported-by-body'>
						{reporterName}
					</ReportByBody>
				</ReportByBodyContainer>
			</ReportedByContainer>
		</ThirdSection>
	);
};
