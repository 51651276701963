import { FeatureType } from '@lh/eng-platform-organization-service-rest-client';

import {
	Box,
	Center,
	Collapse,
	Flex,
	Group,
	SimpleGrid,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useMemo, useState } from 'react';

import { AnalyticsAction, sendEventData } from 'analytics';
import { getOptionalFields } from 'api/organization';
import { QueryKey } from 'api/query';
import { UserContext } from 'components/context';
import { AddCarePartner } from 'components/patients/CarePartner/Add';
import { CarePartnerConfirmationModal } from 'components/patients/CarePartner/ConfirmationModal';
import { CarePartnerModal } from 'components/patients/CarePartner/ControllModal';
import { modifyPatient } from 'components/patients/PatientDetails/PatientDetails_V2/PatientDetails.controller';
import { theme } from 'components/providers/StyleProvider/theme';
import { EditPen } from 'components/shared/Buttons/EditPen';
import { LoadingDots } from 'components/shared/LoadingDots';
import {
	ButtonSmText,
	Caption,
	H1,
	H3,
	P1,
} from 'components/shared/designSystem';
import { OptionalFields } from 'generated/graphql';
import { t } from 'i18n';
import { ERROR } from 'logging/linusLogger';

import { TEXT } from './PatientProfileWidget.i18n';
import { Details, Props, getDetails } from './PatientProfileWidget.utils';

export function PatientProfileWidget({ patient, onEdit }: Readonly<Props>) {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();

	const [optionalFields, setOptionalFields] = useState<OptionalFields[]>([]);
	const [details, setDetails] = useState<Details[] | null>(null);

	const { notes, patient: modifiedPatient } = useMemo(() => {
		return modifyPatient(patient, currentUser);
	}, [patient]);

	useEffect(() => {
		async function createDetails() {
			try {
				const optionalFields = await client.fetchQuery({
					queryKey: [
						QueryKey.OptionalFields,
						currentUser.organizationId,
					],
					queryFn: () =>
						getOptionalFields(currentUser.organizationId),
					staleTime: Infinity,
				});
				if (optionalFields?.length > 0) {
					setOptionalFields(optionalFields);
				}
				setDetails(getDetails(modifiedPatient, currentUser));
			} catch (err) {
				ERROR('Error Creating Services', err);
			}
		}
		createDetails();
	}, [modifiedPatient, currentUser]);

	const fullName =
		`${modifiedPatient.firstName} ${modifiedPatient.lastName}`.trim();

	if (!details) return <LoadingDots />;

	const showCarePartner =
		currentUser.organizationFeatures.includes(
			FeatureType.EnableCarePartner
		) && patient?.relationships?.length === 0;

	return (
		<>
			<Container>
				<WidgetHeader>
					<Name value={fullName} onEdit={onEdit} />
					{showCarePartner && <AddCarePartner patient={patient} />}
				</WidgetHeader>
				<Content>
					<Information>
						<Initials
							firstName={modifiedPatient.firstName}
							lastName={modifiedPatient.lastName}
						/>
						<DetailsGrid>
							{details.map(
								(item) =>
									(item.isRequired ||
										(item.fieldName &&
											optionalFields.includes(
												item.fieldName
											))) && (
										<DetailsItem
											key={item.label}
											label={item.label}
											value={item.value}
										/>
									)
							)}
						</DetailsGrid>
					</Information>
					{optionalFields.includes(OptionalFields.Notes) && (
						<Notes>{notes}</Notes>
					)}
				</Content>
			</Container>
			<CarePartnerModal modalId='AddCarePartner' />
			<CarePartnerConfirmationModal modalId='AddCarePartnerConfirmation' />
		</>
	);
}

function Container({ children }: { children: React.ReactNode }) {
	return (
		<Stack
			data-testid='patient-profile-widget'
			style={{
				gap: 24,
				padding: 16,
				borderRadius: 20,
				boxShadow: theme.boxShadow.standard,
				backgroundColor: theme.colors.white,
			}}
		>
			{children}
		</Stack>
	);
}

function WidgetHeader({ children }: Readonly<{ children: React.ReactNode }>) {
	return <Group justify='space-between'>{children}</Group>;
}

function Name({ value, onEdit }: { value: string; onEdit(): void }) {
	return (
		<Group gap={8} ml={8} wrap='nowrap'>
			<H3
				data-testid='patient-profile-widget-name'
				style={{
					color: theme.colors.gray_30,
					fontWeight: theme.weight.medium,
				}}
			>
				{t(TEXT.name.patient, { name: value })}
			</H3>
			<EditPen
				onClick={onEdit}
				text={t(TEXT.name.edit)}
				dataTestId='patient-details'
			/>
		</Group>
	);
}

function Content({ children }: { children: React.ReactNode }) {
	return <Stack gap={8}>{children}</Stack>;
}

function Information({ children }: { children: React.ReactNode }) {
	return <Flex gap={24}>{children}</Flex>;
}

function Initials({
	firstName,
	lastName,
}: {
	firstName: string;
	lastName: string;
}) {
	function getInitial(value: string) {
		return value.toUpperCase().split('')[0];
	}

	const initials = `${getInitial(firstName)}${getInitial(lastName)}`;

	return (
		<Center
			style={{
				width: 80,
				minWidth: 80,
				height: 80,
				borderRadius: '50%',
				backgroundColor: theme.colors.teal_lighter,
			}}
		>
			<H1 style={{ fontWeight: theme.weight.light }}>{initials}</H1>
		</Center>
	);
}

function DetailsGrid({ children }: { children: React.ReactNode }) {
	return (
		<SimpleGrid
			w='100%'
			cols={{ base: 1, xs: 2, sm: 3, md: 4, lg: 5 }}
			spacing={24}
			verticalSpacing={16}
		>
			{children}
		</SimpleGrid>
	);
}

function DetailsItem({ label, value }: { label: string; value: string }) {
	return (
		<Stack gap={4}>
			<Caption
				data-testid={
					'patient-profile-widget-details-label-' +
					label.trim().toLowerCase().replace(/\W/g, '-')
				}
				style={{
					textTransform: 'uppercase',
					color: theme.colors.gray_30,
					fontWeight: theme.weight.medium,
				}}
			>
				{label}
			</Caption>
			<P1
				data-testid={
					'patient-profile-widget-details-item-' +
					label.trim().toLowerCase().replace(/\W/g, '-')
				}
				style={{
					fontWeight: theme.weight.medium,
					wordBreak: 'break-word',
				}}
			>
				{value}
			</P1>
		</Stack>
	);
}

function Notes({ children }: { children: React.ReactNode }) {
	const [opened, { toggle }] = useDisclosure(false);

	function handleNotesClick() {
		if (!opened) {
			sendEventData({ eventType: AnalyticsAction.ClickedNotes });
		}
		toggle();
	}

	return (
		<Stack gap={8}>
			<Box>
				<UnstyledButton onClick={handleNotesClick}>
					<ButtonSmText
						style={{
							padding: 0,
							color: theme.colors.blue,
							fontWeight: theme.weight.semi,
						}}
					>
						{t(opened ? TEXT.notes.hide : TEXT.notes.show)}
					</ButtonSmText>
				</UnstyledButton>
			</Box>
			<Collapse in={opened}>
				<Box
					style={{
						padding: 8,
						maxHeight: 92,
						overflowY: 'auto',
						backgroundColor: theme.colors.gray_90,
						border: `1px solid ${theme.colors.gray_60}`,
						borderRadius: 4,
						whiteSpace: 'pre-line',
					}}
				>
					<P1 style={{ fontWeight: theme.weight.medium }}>
						{children}
					</P1>
				</Box>
			</Collapse>
		</Stack>
	);
}
