import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useMemo } from 'react';

import { S3Segment, useFetchS3Segments } from 'utils/fetchS3Segments';

import { parseSemanticFluencyMetrics } from './parseSemanticFluencyMetrics';

enum SemanticFluencyFileType {
	Audio = 'semantic-fluency-audio',
}

function buildFilesToFetch(segmentResultId: string): S3Segment[] {
	return [
		{
			filename: 'semantic_fluency_audio.mp4',
			segmentResultId,
			type: SemanticFluencyFileType.Audio,
		},
		{
			filename: 'semantic_fluency_audio.webm',
			segmentResultId,
			type: SemanticFluencyFileType.Audio,
		},
	];
}

export function useSemanticFluencyData(segmentResult: DeepSegmentResult) {
	const { data, filesToFetch } = useMemo(() => {
		const data = parseSemanticFluencyMetrics(segmentResult.metricItems);
		const filesToFetch = buildFilesToFetch(segmentResult.id);

		return { data, filesToFetch };
	}, [segmentResult]);

	const audioFiles = useFetchS3Segments({
		segments: filesToFetch,
		blobType: 'audio/mp4',
	});
	audioFiles.forEach((file) => {
		if (file.type === SemanticFluencyFileType.Audio) {
			data.recording.url = file.responseUrl;
		}
	});

	return data;
}
