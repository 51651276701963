import { t } from 'i18n';
import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../metrics';

export const getDrawingEfficiencyDataSet = (dataMap: MetricHash): DataSet => {
	// Parse the map and get values of interest in chart-friendly data structure
	const drawingEfficiencyMap = {
		commandClock: [
			{
				x: drawingEfficiencyStrings.strokeCountConformity,
				y: dataMap?.COMStrokeCountConformity_s?.value,
			},
			{
				x: drawingEfficiencyStrings.totalTime,
				y: dataMap?.COMTotalTime_s?.value,
			},
			{
				x: drawingEfficiencyStrings.inkLength,
				y: dataMap?.COMInkLength_s?.value,
			},
			{
				x: drawingEfficiencyStrings.drawingSize,
				y: dataMap?.COMDrawingSize_s?.value,
			},
			{
				x: drawingEfficiencyStrings.drawingProcessEfficiency,
				y: dataMap?.COMDrawingProcessEfficiency_s?.value,
			},
			{
				x: drawingEfficiencyStrings.noise,
				y: dataMap?.COMNoise_s?.value,
			},
		],
		copyClock: [
			{
				x: drawingEfficiencyStrings.strokeCountConformity,
				y: dataMap?.COPStrokeCountConformity_s?.value,
			},
			{
				x: drawingEfficiencyStrings.totalTime,
				y: dataMap?.COPTotalTime_s?.value,
			},
			{
				x: drawingEfficiencyStrings.inkLength,
				y: dataMap?.COPInkLength_s?.value,
			},
			{
				x: drawingEfficiencyStrings.drawingSize,
				y: dataMap?.COPDrawingSize_s?.value,
			},
			{
				x: drawingEfficiencyStrings.drawingProcessEfficiency,
				y: dataMap?.COPDrawingProcessEfficiency_s?.value,
			},
			{
				x: drawingEfficiencyStrings.noise,
				y: dataMap?.COPNoise_s?.value,
			},
		],
	};

	return drawingEfficiencyMap as DataSet;
};

export const drawingEfficiencyTooltipKeys = [
	'web.dcrReport.tooltips.strokeCountConformity',
	'web.dcrReport.tooltips.totalTime',
	'web.dcrReport.tooltips.inkLength',
	'web.dcrReport.tooltips.drawingSize',
	'web.dcrReport.tooltips.drawingProcessEfficiency',
	'web.dcrReport.tooltips.noise',
];

export const drawingEfficiencyStrings = {
	strokeCountConformity: t('web.report.segmentUtils.strokeCountConformity'),
	totalTime: t('web.report.segmentUtils.totalTime'),
	inkLength: t('web.report.segmentUtils.inkLength'),
	drawingSize: t('web.report.segmentUtils.drawingSize'),
	drawingProcessEfficiency: t(
		'web.report.segmentUtils.drawingProcessEfficiency'
	),
	noise: t('web.report.segmentUtils.noise'),
};
