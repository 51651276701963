import { FeatureType } from '@lh/eng-platform-organization-service-rest-client';
import { Patient } from '@lh/eng-platform-subject-service-rest-client';

import { useContext } from 'react';

import { UserContext } from 'components/context';
import { CarePartnerEditCard } from 'components/patients/CarePartner';
import { PatientProfileWidget } from 'components/report/CDS/Patient';

import {
	CarePartnerContainer,
	FullStyledCard,
	PatientDetailsContainer,
	PatientProfileHeader,
} from './PatientDetails.style';

type ProfileHeaderProps = {
	patient?: Patient;
	onPatientEdit: () => void;
};

export function ProfileHeader({
	patient,
	onPatientEdit,
}: Readonly<ProfileHeaderProps>) {
	const { currentUser } = useContext(UserContext);

	if (!patient) return null;

	const showCarePartner =
		currentUser.organizationFeatures.includes(
			FeatureType.EnableCarePartner
		) && patient.relationships?.length > 0;

	return (
		<PatientProfileHeader>
			<PatientDetailsContainer>
				<FullStyledCard>
					<PatientProfileWidget
						patient={patient}
						onEdit={onPatientEdit}
					/>
				</FullStyledCard>
			</PatientDetailsContainer>
			{showCarePartner && (
				<CarePartnerContainer>
					<CarePartnerEditCard
						carePartnerId={
							patient.relationships[
								patient.relationships.length - 1
							].relatedSubjectId
						}
						patientId={patient.id}
					/>
				</CarePartnerContainer>
			)}
		</PatientProfileHeader>
	);
}
