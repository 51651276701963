import { Auth, AuthFactory, AuthProviderType } from '@lh/eng-shared-auth';

import { config } from '../../config';

import { AuthStorage } from './AuthStorage';

function createAuthModule(sso: boolean) {
	const baseUrl = `${config.apiProtocol}://${config.apiHost}:${config.apiPort}/${config.apiDomain}`;

	return new AuthFactory(
		{
			type: AuthProviderType.Auth0,
			clientId: config.auth0ClientId,
			domain: config.auth0Domain,
			cookieDomain: config.cookieDomain,
			baseUrl,
			sso,
		},
		new AuthStorage()
	).build();
}

let module: Auth = createAuthModule(true);

export function updateAuthModule(sso: boolean) {
	module = createAuthModule(sso);
}

export { module as Auth };
