import styled from 'styled-components';

import { Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { Accordion } from 'components/shared/Accordion';

import { Attestation } from './Attestation/Attestation';
import { ReasonsForTesting } from './ReasonsForTesting/ReasonsForTesting';
import { SessionObservations } from './SessionObservations/SessionObservations';
import { TestAdministratorNotesProps } from './TestAdministratorNotes.interface';
import { Wrapper } from './TestAdministratorNotes.style';

export const TestAdministratorNotes = ({
	attestation,
	reasonsForTesting,
	sessionObservationsProps,
	showReasonsForTesting,
	showSessionObservations,
	isTest = false,
}: TestAdministratorNotesProps) => {
	const { t } = useTranslation();

	function handleClick() {
		sendEventData({
			eventType: AnalyticsAction.ClickedTanotes,
		});
	}

	return (
		<Wrapper>
			<Accordion
				opened={isTest}
				onClick={handleClick}
				title={
					<div
						data-testid='test-admin-notes-title-container'
						style={{ fontWeight: 500 }}
					>
						{t(
							'web.report.testAdminstratorNotes.testAdministratorNotes'
						)}
					</div>
				}
			>
				<Content data-testid='test-admin-content'>
					{showSessionObservations && (
						<SessionObservations {...sessionObservationsProps} />
					)}
					{showReasonsForTesting && (
						<ReasonsForTesting
							showDivider={showSessionObservations}
							data={reasonsForTesting?.data}
							note={reasonsForTesting?.note}
						/>
					)}
					{attestation && attestation.length > 0 && (
						<Attestation
							showDivider={
								showReasonsForTesting || showSessionObservations
							}
							attestation={attestation}
						/>
					)}
				</Content>
			</Accordion>
		</Wrapper>
	);
};

const Content = styled(Stack)`
	flex-direction: column;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
	}
`;
