import { GradeMarks } from '@lh/qnr-svc-rest-client';

import { CellContext } from '@tanstack/react-table';

import { theme } from 'components/providers/StyleProvider/theme';
import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import i18n from 'i18n';

import {
	GradeIconContainer,
	IconAnswerContainer,
	IconScoreContainer,
	P1Med,
	P1Semi,
	QuestionsContainer,
	SpanSemi,
	TotalLabel,
} from './IadlTable.style';

const GRADE_ICONS = {
	[GradeMarks.RED_X]: <CloseIcon />,
	[GradeMarks.GREEN_CHECK]: (
		<Icon
			icon={icons.CheckMark}
			color={theme.color.iconSuccess}
			iconHeight={24}
			iconWidth={24}
		/>
	),
	[GradeMarks.NOT_GRADED]: null,
};

export const IADL_TABLE_COLUMNS = [
	{
		accessorKey: 'question',
		header: () => (
			<P1Med>{i18n.t('web.report.iadl.question').toUpperCase()}</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const value = props.getValue<QnrRowData['question']>();
			const rows = props.table.getRowModel().rows;
			if (props.row.index === rows.length - 1)
				return <TotalLabel>{value}</TotalLabel>;

			return (
				<QuestionsContainer>
					<SpanSemi>Q{props.row.index + 1}:</SpanSemi>&nbsp;
					{value}
				</QuestionsContainer>
			);
		},
	},
	{
		accessorKey: 'answers',
		header: () => (
			<P1Med>{i18n.t('web.report.iadl.selections').toUpperCase()}</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const value = props.getValue<QnrRowData['answers']>();

			if ('totalScore' in value)
				return (
					<IconScoreContainer>
						<CloseIcon />
						<P1Semi>{value.totalScore}</P1Semi>
					</IconScoreContainer>
				);

			const gradeIcon = GRADE_ICONS[value.grade];

			return (
				<IconAnswerContainer>
					<GradeIconContainer>{gradeIcon}</GradeIconContainer>
					<P1>{value.answerText}</P1>
				</IconAnswerContainer>
			);
		},
	},
];

function CloseIcon() {
	return (
		<GradeIconContainer>
			<Icon
				icon={icons.Close}
				color={theme.color.iconAlert}
				iconWidth={16}
				iconHeight={16}
			/>
		</GradeIconContainer>
	);
}
