import styled, { useTheme } from 'styled-components';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryContainer,
	VictoryGroup,
} from 'victory';

import { useCDS } from '../../shared/hooks/useCDS';
import { BarCapLabel } from './BarCapLabel';
import { ChartConcerns } from './ChartConcerns';
import { TickLabelWithTooltips } from './TickLabelWithTooltip';

export type DataSet = {
	commandClock: DataScore[];
	copyClock: DataScore[];
};

export type DataScore = {
	x: string;
	y: number;
};

type CompositeScalesProps = {
	chartTitle?: string;
	subtitle?: string;
	dataSet: DataSet;
	tooltipKeys?: string[];
	width: number;
	height: number;
	barWidth: number;
	barDistance: number;
	xAxisRange: [number, number];
	yAxisRange: [number, number];
	squeezeEdgesX?: [number, number];
	squeezeEdgesY?: [number, number];
	slideDataX?: number;
	slideDataY?: number;
	slideBottomAxisY?: number;
	customYAxisTickValues?: number[];
	makeResponsive?: boolean;
	concerns?: string[];
};

export const CompositeScales = (props: CompositeScalesProps): JSX.Element => {
	const theme = useTheme();
	const cdsEnabled = useCDS();
	const {
		chartTitle,
		subtitle,
		dataSet,
		tooltipKeys,
		width,
		height,
		barWidth,
		barDistance,
		xAxisRange,
		yAxisRange,
		squeezeEdgesX = [0, 0],
		squeezeEdgesY = [0, 0],
		slideDataX = 0,
		slideDataY = 0,
		customYAxisTickValues = undefined,
		makeResponsive = false,

		concerns,
	} = props;
	const { color, weight } = theme;
	const capColors: string[] = [color.graphBlue, color.graphGreen];
	const LABEl_OFFSET = 4;

	return (
		<StyledContainer>
			{chartTitle && <StyledSpan>{chartTitle}</StyledSpan>}
			{!cdsEnabled && concerns && <ChartConcerns concerns={concerns} />}
			<StyledWrapper>
				{subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
				<VictoryChart
					containerComponent={
						<StyledVictoryContainer responsive={makeResponsive} />
					}
					width={width}
					height={height}
					style={{
						background: {
							fill: 'none',
							padding: '0',
						},
					}}
					domainPadding={{ x: squeezeEdgesX, y: squeezeEdgesY }}
					domain={{ x: xAxisRange, y: yAxisRange }}
				>
					<VictoryAxis
						// The vertical y-axis at the left side of the graph
						dependentAxis={true}
						tickValues={customYAxisTickValues}
						style={{
							grid: {
								stroke: color.graphGrid,
								strokeWidth: 1,
								transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
							},
							tickLabels: { fill: color.graphTextPrimary },
							ticks: { stroke: 'transparent' },
							axis: {
								stroke: 'transparent',
							},
						}}
						offsetX={24}
					/>
					<VictoryGroup
						offset={barDistance}
						colorScale={'qualitative'}
					>
						<VictoryBar
							barWidth={barWidth}
							cornerRadius={4}
							style={{
								data: {
									fill: color.graphBlue,
									opacity: 0.2,
									transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
								},
								labels: {
									fontFamily: 'IBM Plex Sans',
									fontSize: 14,
									fill: color.graphTextSecondary,
								},
							}}
							data={dataSet.commandClock}
							labels={(datum) => datum.datum._y}
							labelComponent={
								<BarCapLabel
									width={barWidth}
									colors={capColors}
									translateX={slideDataX}
								/>
							}
						/>

						<VictoryBar
							barWidth={barWidth}
							cornerRadius={4}
							style={{
								data: {
									fill: color.graphGreen,
									opacity: 0.2,
									transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
								},
								labels: {
									fontFamily: 'IBM Plex Sans',
									fontSize: 14,
									fill: color.graphTextSecondary,
								},
							}}
							data={dataSet.copyClock}
							labels={(datum) => datum.datum._y}
							labelComponent={
								<BarCapLabel
									width={barWidth}
									colors={capColors}
									translateX={slideDataX}
								/>
							}
						/>
					</VictoryGroup>
					<VictoryAxis
						// The x-axis at 0
						style={{
							axis: {
								stroke: 'black',
								transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
							},
							ticks: {
								stroke: 'transparent',
							},
							tickLabels: {
								fill: 'transparent',
							},
						}}
					/>
					<VictoryAxis
						// This axis represents the labels positioned under the graph
						offsetY={40}
						offsetX={130}
						tickValues={[1, 2, 3]}
						tickLabelComponent={
							<TickLabelWithTooltips
								slideDataX={slideDataX - LABEl_OFFSET}
								tooltipKeys={tooltipKeys}
							/>
						}
						style={{
							axis: {
								stroke: 'transparent',
								transform: `translateX(${slideDataX}px) translateY(${slideDataY}px)`,
							},
							ticks: {
								stroke: 'transparent',
							},
							tickLabels: {
								transform: `translate(${slideDataX})`,
								fontFamily: 'IBM Plex Sans',
								fontSize: 14,
								fontWeight: weight.medium,
							},
						}}
					/>
				</VictoryChart>
			</StyledWrapper>
		</StyledContainer>
	);
};

const StyledVictoryContainer = styled(VictoryContainer)`
	@media print {
		height: auto !important;
	}
`;

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
	padding: 0 0 ${spacing.md} 0;
	overflow-x: auto;
	@media print {
    break-inside: avoid;
	}

`
);

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	@media print {
		break-inside: avoid;
	}
`;

const StyledSpan = styled.span(
	({ theme: { fontSize, weight, spacing } }) => `
	font-size: ${fontSize._16};
  font-weight: ${weight.semi};
  @media print {
    padding-top: ${spacing.md}
  }
`
);

const StyledSubtitle = styled.span(
	({ theme: { fontSize } }) => `
	font-size: ${fontSize._14};
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	padding-left: 11.5px;
`
);
