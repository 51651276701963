import { t } from 'i18n';
import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../metrics';

export const getMotorDataSet = (dataMap: MetricHash): DataSet => {
	// Parse the map and get values of interest in chart-friendly data structure
	const motorMap = {
		commandClock: [
			{
				x: motorStrings.percentInkTime,
				y: dataMap?.COMPercentInkTime_s?.value,
			},
			{
				x: motorStrings.averageSpeed,
				y: dataMap?.COMAverageSpeed_s?.value,
			},
			{ x: motorStrings.maxSpeed, y: dataMap?.COMMaxSpeed_s?.value },
			{
				x: motorStrings.initiationSpeed,
				y: dataMap?.COMInitiationSpeed_s?.value,
			},
			{
				x: motorStrings.terminationSpeed,
				y: dataMap?.COMTerminationSpeed_s?.value,
			},
			{
				x: motorStrings.oscillatoryMotion,
				y: dataMap?.COMOscillatoryMotion_s?.value,
			},
		],
		copyClock: [
			{
				x: motorStrings.percentInkTime,
				y: dataMap?.COPPercentInkTime_s?.value,
			},
			{
				x: motorStrings.averageSpeed,
				y: dataMap?.COPAverageSpeed_s?.value,
			},
			{ x: motorStrings.maxSpeed, y: dataMap?.COPMaxSpeed_s?.value },
			{
				x: motorStrings.initiationSpeed,
				y: dataMap?.COPInitiationSpeed_s?.value,
			},
			{
				x: motorStrings.terminationSpeed,
				y: dataMap?.COPTerminationSpeed_s?.value,
			},
			{
				x: motorStrings.oscillatoryMotion,
				y: dataMap?.COPOscillatoryMotion_s?.value,
			},
		],
	};

	return motorMap as DataSet;
};

export const motorTooltipkeys = [
	'web.dcrReport.tooltips.percentInkTime',
	'web.dcrReport.tooltips.averageSpeed',
	'web.dcrReport.tooltips.maxSpeed',
	'web.dcrReport.tooltips.initiationSpeed',
	'web.dcrReport.tooltips.terminationSpeed',
	'web.dcrReport.tooltips.oscillatoryMotion',
];

export const motorStrings = {
	percentInkTime: t('web.report.segmentUtils.percentInkTime'),
	averageSpeed: t('web.report.segmentUtils.averageSpeed'),
	maxSpeed: t('web.report.segmentUtils.maxSpeed'),
	initiationSpeed: t('web.report.segmentUtils.initiationSpeed'),
	terminationSpeed: t('web.report.segmentUtils.terminationSpeed'),
	oscillatoryMotion: t('web.report.segmentUtils.oscillatoryMotion'),
};
