/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { ResponseType } from 'axios';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { QueryKey } from 'api/query';
import { getBatteryService } from 'api/getBatteryService';

export type ArtifactResponse = {
	assetData: string;
	assetCleanup: () => void;
};

enum ArtifactResponseType {
	BLOB = 'blob',
	JSON = 'json',
}

type GetArtifactResponse = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	abortController: AbortController;
};

type GetSegmentResultArtifactRequest = {
	segmentResultId: string;
	fileName: string;
};

export async function fetchSegmentResultArtifact(
	requestOptions: GetSegmentResultArtifactRequest
): Promise<string> {
	const service = await getBatteryService();
	const { data } = await service.getSegmentResultArtifact(requestOptions);
	return data;
}

export const getJsonArtifact = async (
	presignedURL: string
): Promise<ArtifactResponse> => {
	return getArtifact(presignedURL, ArtifactResponseType.JSON).then(
		(assetResponse) => {
			const assetData = JSON.stringify(assetResponse.data);
			return {
				assetData,
				assetCleanup: () => {
					assetResponse.abortController.abort();
				},
			};
		}
	);
};

const getArtifact = async (
	presignedURL: string,
	responseType: string
): Promise<GetArtifactResponse> => {
	const abortController = new AbortController();
	return axios
		.get(presignedURL, {
			responseType: responseType as ResponseType,
			signal: abortController.signal,
		})
		.then((axiosResponse) => {
			return {
				data: axiosResponse.data,
				abortController: abortController,
			};
		});
};

export const useGetLhqReportArtifact = () => {
	const queryClient = useQueryClient();

	const getFetchArtifactHandler = useCallback(() => {
		const fetchArtifact: (
			presignedUrl: string
		) => Promise<ArtifactResponse> = getJsonArtifact;

		return fetchArtifact;
	}, []);

	const getReportArtifact = useCallback(
		async ({ segmentResultId, fileName }: any) => {
			try {
				const presignedUrl = await queryClient.fetchQuery({
					queryKey: [
						QueryKey.SegmentArtifact,
						segmentResultId,
						fileName,
					],
					queryFn: () =>
						fetchSegmentResultArtifact({
							segmentResultId,
							fileName,
						}),
				});
				const artifactFetcher = getFetchArtifactHandler();
				const response = await artifactFetcher(presignedUrl);
				return response;
			} catch (error) {
				console.error(error);
			}
		},
		[getFetchArtifactHandler, queryClient]
	);

	return { getReportArtifact };
};
