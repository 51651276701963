import { Trans, useTranslation } from 'react-i18next';

import { HeaderIcon } from '../HeaderIcon/HeaderIcon';
import { ModalIconComponent } from '../ModalIcon/ModalIconComponent';
import {
	AttestationModalIconContainerLarge,
	AttestationModalIconContainerMedium,
	ParamParagraph,
	SessionObservationsContainer,
	SmallSectionHeader,
	ThirdSectionWithDivider,
} from '../TestAdministratorNotes.style';

export interface AttestationProps {
	attestation: string;
	showDivider?: boolean;
}

export const Attestation = ({ attestation, showDivider }: AttestationProps) => {
	const { t } = useTranslation();

	return (
		<ThirdSectionWithDivider
			showDivider={showDivider}
			data-testid='attestation-base-container'
		>
			<SessionObservationsContainer>
				<SmallSectionHeader data-testid='attestation-header'>
					{t('web.report.testAdminstratorNotes.attestation.title')}
					<AttestationModalIconContainerLarge>
						<HeaderIcon
							tooltipPlacement='bottom'
							text={t(
								'web.report.testAdminstratorNotes.attestation.helperText'
							)}
						/>
					</AttestationModalIconContainerLarge>
					<AttestationModalIconContainerMedium>
						<ModalIconComponent
							titleText={t(
								'web.report.testAdminstratorNotes.attestation.title'
							)}
							contentText={t(
								'web.report.testAdminstratorNotes.attestation.helperText'
							)}
						/>
					</AttestationModalIconContainerMedium>
				</SmallSectionHeader>
			</SessionObservationsContainer>
			<ParamParagraph data-testid='attestation-body'>
				<Trans
					components={{
						b: <b />,
					}}
				>
					{attestation}
				</Trans>
			</ParamParagraph>
		</ThirdSectionWithDivider>
	);
};
