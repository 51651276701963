import { useTranslation } from 'react-i18next';

import { SimpleTable } from '@lh/eng-web-mosaic-common';
import { theme } from 'components/providers/StyleProvider/theme';
import { Caption, Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

import {
	buildTableData,
	COLUMNS,
	DelayedRecognitionData,
} from './DelayedRecognitionTable.consts';
import {
	Card,
	FooterText,
	IconWrapper,
	LineDivider,
	Selected,
	TableHeader,
	TableHeaderWrapper,
} from './DelayedRecognitionTable.style';
import { AnswerResponse } from '../DelayedRecognition.types';
import { useMemo } from 'react';

type DelayedRecognitionTableProps = {
	answers?: AnswerResponse[];
};

export function DelayedRecognitionTable({
	answers,
}: Readonly<DelayedRecognitionTableProps>) {
	const { t } = useTranslation();

	const tableData = useMemo(() => {
		if (answers) {
			return buildTableData(answers);
		}
	}, [answers?.length]);

	if (!tableData) {
		return <></>;
	}

	return (
		<Card>
			<TableHeaderWrapper>
				<TableHeader>
					{t('web.report.pvltDelayedRecognition.individualAnswers')}
				</TableHeader>
				<IconWrapper>
					<Icon icon={icons.RedClose} />
					<Caption style={{ color: theme.colors.orange_darker }}>
						{t('web.report.pvltDelayedRecognition.incorrect')}
					</Caption>
				</IconWrapper>
				<IconWrapper>
					<Icon icon={icons.GreenCheckmark} />
					<Caption style={{ color: theme.colors.green_darkest }}>
						{t('web.report.pvltDelayedRecognition.correct')}
					</Caption>
				</IconWrapper>
				<IconWrapper>
					<Selected />
					<Caption style={{ color: theme.colors.gray_30 }}>
						{t('web.report.pvltDelayedRecognition.selection')}
					</Caption>
				</IconWrapper>
			</TableHeaderWrapper>
			<SimpleTable<DelayedRecognitionData>
				columns={COLUMNS}
				data={tableData}
			/>
			<LineDivider />
			<FooterText>
				{t('web.report.pvltDelayedRecognition.endOfQuestionnaire')}
			</FooterText>
		</Card>
	);
}
