import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { TRAILS_COLORS } from 'components/report/TrailsLayout/TrailsLayout.interface';
import { StyledLinkButton } from 'components/shared/StyledLinkButton';
import { hasIncompleteData } from 'utils/reportText';
import { ScoringDetailsModal } from '../ScoringDetailsModal';

import {
	AgeUnavailable,
	Content,
	Description,
	DescriptionText,
	ErrorsContainer,
	ErrorsWrapper,
	LineDivider,
	Quartile,
	Title,
	TotalMistakes,
	TotalMistakesContainer,
	Wrapper,
} from './Errors.styles';

function getErrorColor(totalMistakesColor: TRAILS_COLORS) {
	if (totalMistakesColor === TRAILS_COLORS.Green) {
		return {
			backgroundColor: theme.colors.green_lightest,
			color: theme.colors.green_darkest,
		};
	}

	if (totalMistakesColor === TRAILS_COLORS.Yellow) {
		return {
			backgroundColor: theme.colors.yellow_lightest,
			color: theme.colors.yellow_darkest,
		};
	}

	if (totalMistakesColor === TRAILS_COLORS.Red) {
		return {
			backgroundColor: theme.colors.orange_lightest,
			color: theme.colors.orange_darkest,
		};
	}

	return {
		backgroundColor: theme.colors.gray_60,
		color: theme.color.graphTextPrimary,
	};
}

export type TrailsErrorsProps = {
	readonly ageRange: string;
	readonly totalMistakes: number;
	readonly totalMistakesColor: TRAILS_COLORS;
	readonly thresholds: {
		green: string;
		yellow: string;
		red: string;
	};
	readonly errorsQuartile: string;
};

export function Errors({
	ageRange,
	totalMistakes,
	totalMistakesColor,
	errorsQuartile,
	thresholds,
}: TrailsErrorsProps) {
	const { t } = useTranslation();

	const [showScoringDetails, setShowScoringDetails] = useState(false);

	function handleScoringDetailsOpen() {
		setShowScoringDetails(true);
	}

	function handleScoringDetailsClose() {
		setShowScoringDetails(false);
	}

	const hasCompleteInfo = !hasIncompleteData([
		thresholds.green,
		thresholds.yellow,
		thresholds.red,
	]);

	const { backgroundColor, color } = getErrorColor(totalMistakesColor);

	return (
		<Wrapper>
			<Title data-testid='errors-title'>
				{t('web.report.trailsReport.errors.title')}
			</Title>
			<LineDivider />
			<Content>
				<ErrorsWrapper>
					<ErrorsContainer>
						<TotalMistakesContainer
							data-testid='total-mistakes'
							style={{
								backgroundColor,
							}}
						>
							<TotalMistakes textColor={color}>
								{totalMistakes}
							</TotalMistakes>
						</TotalMistakesContainer>
						<Description>
							<DescriptionText>
								{t(
									'web.report.trailsReport.errors.description'
								)}
							</DescriptionText>
						</Description>
					</ErrorsContainer>
					{errorsQuartile !== 'NotAvailable' && (
						<Quartile>{errorsQuartile}</Quartile>
					)}
				</ErrorsWrapper>
				{hasCompleteInfo && (
					<StyledLinkButton
						type='button'
						role='button'
						onClick={handleScoringDetailsOpen}
						style={{
							fontSize: 14,
							marginTop: 12,
						}}
					>
						<Trans i18nKey='web.shared.viewScoringDetails' />
					</StyledLinkButton>
				)}
				{hasCompleteInfo &&
					showScoringDetails &&
					thresholds.green !== 'NotAvailable' && (
						<ScoringDetailsModal
							title={`${t(
								'web.report.trailsReport.timeToComplete.scoringDetails'
							)}: ${t('web.report.trailsReport.errors.title')}`}
							heading={`${t(
								'web.report.trailsReport.timeToComplete.normativeRanges',
								{
									ageRange,
								}
							)}`}
							greenText={`${t(
								'web.report.trailsReport.errors.greenRange',
								{
									greenThreshold: thresholds.green,
								}
							)}`}
							yellowText={`
						${t('web.report.trailsReport.errors.yellowRange', {
							yellowThreshold: thresholds.yellow,
						})}`}
							redText={`${t(
								'web.report.trailsReport.errors.redRange',
								{
									redThreshold: thresholds.red,
								}
							)}`}
							onClose={handleScoringDetailsClose}
						/>
					)}
				{!hasCompleteInfo && (
					<AgeUnavailable>
						{t('web.report.trailsReport.errors.ageUnavailable')}
					</AgeUnavailable>
				)}
			</Content>
		</Wrapper>
	);
}
