import { useTranslation } from 'react-i18next';

import {
	Box,
	H4Med,
	HeaderContainer,
	HeaderSelection,
	P1Faded,
	SelectionBox,
} from './FastTable.style';

export function Header() {
	const { t } = useTranslation();
	return (
		<HeaderContainer>
			<HeaderSelection>
				<H4Med>
					{t('web.report.fastReport.table.individualAnswers')}
				</H4Med>
				<SelectionBox>
					<Box />
					<P1Faded>
						{t('web.report.fastReport.table.selectionMade')}
					</P1Faded>
				</SelectionBox>
			</HeaderSelection>
			<P1Faded>{t('web.report.fastReport.table.choose')}</P1Faded>
		</HeaderContainer>
	);
}
