import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Caption, P1 } from 'components/shared/designSystem';

import { ActionPlanPillarFocusArea } from '../ActionPlanPillarFocusArea';

type ActionPlanPillarTitleProps = {
	title: string;
	subtitle?: string;
	focusArea?: boolean;
	isBold?: boolean;
	fontSize?: string;
	subtitleFontSize?: string;
	reverse?: boolean;
};

export const ActionPlanPillarTitle = ({
	title,
	focusArea,
	subtitle = '',
	isBold,
	fontSize,
	subtitleFontSize,
	reverse,
}: ActionPlanPillarTitleProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<StyledTitle reverse={reverse}>
			<StyledTitleWithSubtitleWrapper>
				<StyledTitleText
					data-testid='pillarDetailsTitle'
					isBold={isBold}
					fontSize={fontSize}
					reverse={reverse}
					subtitleFontSize={subtitleFontSize}
				>
					{t(`${title}`)}
				</StyledTitleText>
				{subtitle && (
					<StyledSubtitleText
						data-testid='pillarDetailsSubtitle'
						isBold={isBold}
						fontSize={fontSize}
						subtitleFontSize={subtitleFontSize}
					>
						{t(`${subtitle}`)}
					</StyledSubtitleText>
				)}
				{focusArea && (
					<FocusAreaContainer reverse={!!reverse}>
						<ActionPlanPillarFocusArea />
					</FocusAreaContainer>
				)}
			</StyledTitleWithSubtitleWrapper>
		</StyledTitle>
	);
};

type StyledTitleTextProps = {
	isBold?: boolean;
	fontSize?: string;
	reverse?: boolean;
	subtitleFontSize?: string;
};

const StyledTitleText = styled(P1)<StyledTitleTextProps>(
	({ isBold, fontSize, reverse, theme: { weight, fontSize: fs } }) => css`
		font-weight: ${isBold ? weight.medium : weight.regular};
		font-size: ${fontSize ? fontSize : fs._12};
		margin: 0;
		line-height: normal;
		min-width: ${reverse ? '100%' : '225px'};
		text-align: ${reverse ? 'right' : 'left'};

		@media only screen and (max-width: 1200px) {
			max-width: 155px;
		}
	`
);
const StyledSubtitleText = styled(Caption)<StyledTitleTextProps>(
	({ subtitleFontSize, theme: { fontSize } }) => `
		margin: 0;
		letter-spacing: 0.25px;
		font-size: ${subtitleFontSize ? subtitleFontSize : fontSize._8};
		line-height:normal;
	`
);
type StyledTitleProps = { reverse?: boolean };
const StyledTitleWithSubtitleWrapper = styled.div(
	({ theme: { spacing } }) => `
		line-height: ${spacing.sm};
		display: flex;
		flex-direction: column;
		align-items: 'start';
	`
);
const StyledTitle = styled.div<StyledTitleProps>(
	({ reverse, theme: { spacing } }) => `
	display: flex;
	align-items: ${reverse ? 'left' : 'right'};
	flex-direction: ${reverse ? 'row-reverse' : 'row'};
	gap: ${spacing.sm};
	margin: 0 ${reverse ? '15px' : '0'} 0 ${reverse ? '0' : '15px'};
	position: relative;
`
);
type FocusAreaProps = { reverse: boolean };
const FocusAreaContainer = styled.div<FocusAreaProps>(
	({ reverse }) => `
	${reverse ? 'right: 0;' : 'left: 0;'}
	top: 0;
    width: fit-content;
    align-self: ${reverse ? 'flex-end' : 'flex-start'};
	position: relative;
`
);
