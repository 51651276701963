import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MiniModalReport } from '../AssessmentComponents/MiniModal/MiniModalReport';
import { CDSOverview } from '../CDS/CDSOverview';
import { Report } from '../CognitiveEvaluation.types';
import { cognitiveEvaluationAccessibleTabs } from './cognitiveEvaluationAccessibleTabs';
import { BatteryHeaderFragment } from '../../../generated/graphql';
import { TabbedLayout } from '../../layout/TabbedLayout';
import { TabItem } from '../../layout/TabbedLayout/TabbedLayout';
import { LifeAndHealthReport } from '../SegmentComponents/LifeAndHealth/LifeAndHealthReport';
import { CDSVersions, useCDS } from '../../shared/hooks/useCDS';

type CognitiveEvaluationTabbedLayoutProps = {
	batteryResult: Report;
	batteryParticipant?: BatteryHeaderFragment;
	previousComponentRef?: RefObject<HTMLDivElement>;
	patientReportHandler?: () => void;
	isContentLoading?: boolean;
	setIsContentLoading?: (isLoading: boolean) => void;
};
export const CognitiveEvaluationTabbedLayout = ({
	batteryResult,
	batteryParticipant,
	patientReportHandler,
	previousComponentRef,
	isContentLoading,
	setIsContentLoading,
}: CognitiveEvaluationTabbedLayoutProps): JSX.Element => {
	const { t } = useTranslation();
	const cds = useCDS();

	const batteryType = batteryResult?.batteryResultById?.battery
		?.batteryType as string;

	const cdsRecommendations =
		batteryParticipant?.batteryResultById?.cdsRecommendations ?? [];

	const batteryTabs: TabItem[] = [
		{
			name: t`cds.fullArticle.recommendationsLabel`,
			Component: CDSOverview,
			isInitLoading: false,
			props: {
				batteryParticipant,
				batteryResult,
				batteryType,
				patientReportHandler,
				reportLanguage: batteryParticipant?.language,
				setIsLoading: (isLoading: boolean) =>
					onSetIsContentLoading(`Recommendations`, isLoading),
			},
			requiresCDS: true,
			requiresActiveTabSetter: true,
		},
		{
			name: t`web.report.cognitiveEvaluation.batteryTabs.dcr`,
			Component: MiniModalReport,
			isInitLoading: false,
			props: {
				batteryResult,
				setIsLoading: (isLoading: boolean) =>
					onSetIsContentLoading(
						t`web.report.cognitiveEvaluation.batteryTabs.dcr`,
						isLoading
					),
			},
			requiresCDS: false,
		},
		{
			name: t`web.report.cognitiveEvaluation.batteryTabs.lifeHealth`,
			Component: LifeAndHealthReport,
			isInitLoading: true,
			props: {
				batteryResult,
				isLoading: isContentLoading,
				offsetComponentRef: previousComponentRef,
				setIsLoading: (isLoading: boolean) =>
					onSetIsContentLoading(
						t`web.report.cognitiveEvaluation.batteryTabs.lifeHealth`,
						isLoading
					),
			},
			requiresCDS: false,
		},
	];

	const showCDSTab =
		cds !== CDSVersions.V2 &&
		!!(cds === CDSVersions.V1 && cdsRecommendations?.length);

	const accessibleTabs = cognitiveEvaluationAccessibleTabs(
		batteryTabs,
		batteryType,
		showCDSTab
	);

	// Only persisting data needed for tabs
	const formattedAccessibleTabs = accessibleTabs.map((tab) => ({
		name: tab.name,
		Component: tab.Component,
		props: tab.props,
		requiresActiveTabSetter: tab.requiresActiveTabSetter,
	}));

	const [activeTabName, setActiveTabName] = useState(
		formattedAccessibleTabs[0].name
	);

	const getActiveTab = (name: string) =>
		batteryTabs.find((tab) => tab.name === name) as TabItem;

	const onSetActiveTabName = (name: string) => {
		setActiveTabName(name);
		if (!setIsContentLoading) return;
		const activeTab = getActiveTab(name);
		setIsContentLoading(!!activeTab.isInitLoading);
	};

	const onSetIsContentLoading = (tabName: string, isLoading: boolean) => {
		if (!setIsContentLoading || tabName !== activeTabName) return;
		setIsContentLoading(isLoading);
	};

	return (
		<TabbedLayout
			tabs={formattedAccessibleTabs}
			activeTabName={activeTabName}
			setActiveTabName={onSetActiveTabName}
			offsetContainerRef={previousComponentRef}
		/>
	);
};
