import styled, { css } from 'styled-components';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
		padding: ${spacing.md} ${spacing.lg};
	`
);

export const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
	`
);

export const Content = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.sm};
	`
);
