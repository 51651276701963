import styled from 'styled-components';

import { StyledCard } from '../../../shared/StyledCard';
import { P1, P2 } from '../../../shared/designSystem';

export const Card = styled(StyledCard)`
	padding: ${({ theme }) => theme.spacing.lg};
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

export const Header = styled(P1)`
	font-weight: 600;
`;

export const Content = styled(P2)`
	font-weight: 500;
`;
