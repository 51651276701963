import { ValueListItem } from '@lh/eng-platform-organization-service-rest-client';

import { t } from 'i18n';
import { DropdownOption } from '../types';

export type EnumItem = {
	key: string;
	value: string;
	display?: string;
	toString: () => string;
	index?: number;
	longDisplay?: string;
	secondLine?: string;
	region?: EnumItem;
};

export type EnumItemRest = {
	key: string;
	value: string;
	display?: string;
	toString: () => string;
	index?: number;
	longDisplay?: string | null;
	secondLine?: string;
	region?: EnumItemRest;
};

type Enum<T> = {
	-readonly [Property in keyof T]: EnumItem;
};

export type Enumeration<T> = Enum<T> & {
	fromValue: (target?: string) => EnumItem | undefined;
	fromDisplay: (target?: string) => EnumItem | undefined;
	toOptions: (props?: {
		filter?: (item: EnumItem) => boolean;
		region?: string;
	}) => DropdownOption[];
	toValues: () => string[];
	toKeys: () => string[];
	toEnumItems: () => EnumItem[];
};

export type VLEnum = Record<string, EnumItem>;
export type VLEnumRest = Record<string, EnumItemRest>;

export const vlEnumeration = (
	input: ValueListItem[],
	transform?: (v: ValueListItem) => EnumItemRest
): Enumeration<VLEnumRest> => {
	const items = input.reduce((acc, vli) => {
		const newItem = transform
			? transform(vli)
			: {
					key: vli.display || vli.displayKey || vli.id,
					value: vli.id,
					display: vli.displayKey || vli.display || vli.id,
					longDisplay: vli.longDisplayKey || vli.longDisplay,
					displayKey: vli.displayKey || vli.display || vli.id,
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  };
		acc[newItem.key as keyof VLEnumRest] = newItem;
		return acc;
	}, {} as VLEnumRest);
	return enumeration<VLEnumRest>(items);
};

export const enumeration = <T extends Record<any, any>>(
	input: T
): Enumeration<T> => {
	const result = Object.entries(input).reduce(
		(acc: Enum<T>, [key, value], index: number) => {
			const newItem =
				typeof value === 'object'
					? {
							...value,
							key,
							index,
							value: value.value,
							display: value.displayKey
								? t(value.displayKey)
								: value.display,
							longDisplay: value.longDisplayKey
								? t(value.longDisplayKey)
								: value.longDisplay,
							secondLine: value.secondLineKey || value.secondLine,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  }
					: {
							key,
							value,
							display: value,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  };
			acc[key as keyof Enum<T>] = newItem;
			return acc;
		},
		{} as Enum<T>
	);

	return {
		fromValue: (target?: string) => {
			if (!target) {
				return undefined;
			}
			return (Object.values(result) as EnumItem[]).find(
				(value: EnumItem) => value.value === target
			);
		},
		fromDisplay: (target?: string) => {
			if (!target) {
				return undefined;
			}
			return (Object.values(result) as EnumItem[]).find(
				(value: EnumItem) => value.display === target
			);
		},
		toOptions: (props): DropdownOption[] => {
			let filter = props?.filter;
			const region = props?.region;
			if (!filter) {
				filter = () => true;
			}

			const enumValues = Object.values(result) as EnumItem[];
			// Sort the enum values based on index
			if (enumValues.some((enumValuesObj) => enumValuesObj?.index)) {
				enumValues.sort((a: EnumItem, b: EnumItem) =>
					a?.index && b?.index ? a?.index - b?.index : 0
				);
			}

			const customFilter = props?.filter
				? enumValues.filter(props.filter)
				: enumValues;
			return customFilter
				.filter((value) => {
					return !value.region || value.region.value === region;
				})
				.map((item) => ({
					display: item.display || item.key,
					value: item.value,
					longDisplay: item.longDisplay,
					secondLine: item.secondLine,
				}));
		},
		toValues: () =>
			(Object.values(result) as EnumItem[]).map(
				(item: EnumItem) => item.value
			) as string[],
		toKeys: () =>
			(Object.values(result) as EnumItem[]).map(
				(item: EnumItem) => item.key
			) as string[],
		toEnumItems: () => Object.values(result) as EnumItem[],
		...result,
	};
};
