import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Text } from './Text';
import { Label } from './Label';
import { StyledLinkButton } from '../../StyledLinkButton';
import { useField, ErrorMessage } from 'formik';
import { FormElementProps } from '../../../../types';
import { useTranslation } from 'react-i18next';
import { P2 } from '../../designSystem/TextComponents';
import { icons } from '../../../../enums/icons';
import { Icon } from '../../designSystem/Icon';

export const LinusPasswordInput = <T,>({
	name = 'password',
	label,
	width,
}: FormElementProps<T>): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);
	const [field, meta] = useField(name);
	const { t } = useTranslation();
	const theme = useTheme();
	const showPassIcon = showPassword
		? icons.EyeOpenOutlined
		: icons.EyeClosedOutlined;
	const showPassIconTitle = showPassword
		? t`web.shared.forms.passwordInput.eyeOpenIcon`
		: t`web.shared.forms.passwordInput.eyeClosedIcon`;
	const showPassText = showPassword
		? t`web.shared.forms.passwordInput.hide`
		: t`web.shared.forms.passwordInput.show`;

	const handleClick = () => {
		setShowPassword(!showPassword);
	};

	return (
		<StyledContainer width={width}>
			<Label name={name} value={field.value} placeholder={label} />
			<Text
				data-id={`input-text-${name}`}
				type={showPassword ? 'text' : 'password'}
				name={name}
				placeholder={label}
				value={field.value}
				error={meta.touched && meta.error}
			/>
			<StyledShowPasswordButton
				type='button'
				role='link'
				onClick={handleClick}
			>
				{null}
			</StyledShowPasswordButton>
			<StyledPasswordHintsContainer>
				<ErrorMessage name={field.name}>
					{(msg) => (
						<StyledErrorText data-id={`input-error-${name}`}>
							{msg}
						</StyledErrorText>
					)}
				</ErrorMessage>
				<StyledShowPassContainer onClick={handleClick}>
					<Icon
						icon={showPassIcon}
						title={showPassIconTitle}
						color={theme.color.textBlue}
					/>
					<StyledShowPassStatusText>
						{showPassText}
					</StyledShowPassStatusText>
				</StyledShowPassContainer>
			</StyledPasswordHintsContainer>
		</StyledContainer>
	);
};

const StyledContainer = styled.div<{ width?: string }>(
	({ theme: { spacing }, width }) => `
	position: relative;
	margin: ${spacing.md} 0;
	width: ${width ? width : '317px'};
	height: ${spacing.xxxl};
	margin-bottom: ${spacing.lg};
	`
);

const StyledShowPasswordButton = styled(StyledLinkButton)`
	position: absolute;
	right: 32px;
	top: 21px;
	padding: 0;
`;

const StyledErrorText = styled(P2)(
	({ theme: { spacing, color } }) => `
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: ${color.formError};
	padding: ${spacing.xs} 0 0 ${spacing.md};
	font-style: italic;
	width: 100%;
	`
);

const StyledPasswordHintsContainer = styled.div`
	display: inline-flex;
	width: 100%;
	justify-content: space-between;
	margin-top: 0.2em;
`;

const StyledShowPassContainer = styled.div`
	display: inline-flex;
	cursor: pointer;
	width: 100%;
	justify-content: flex-end;
`;

const StyledShowPassStatusText = styled.span(
	({ theme: { color, fontSize } }) => `
	margin-left: 0.5em;
	font-size: ${fontSize._14};
	color: ${color.textBlue};
    justify-content: center;
    align-items: center;
    display: flex;
`
);
