import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import { Patient, Subject } from '@lh/eng-platform-subject-service-rest-client';
import { Typography } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { EditPen } from 'components/shared/Buttons/EditPen';
import { StyledCard } from 'components/shared/StyledCard';
import { CarePartnerConfirmationModal } from '../../ConfirmationModal';
import { CarePartnerModal } from '../../ControllModal';

import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { Container, Header } from './CardUi.style';
import { Content } from './Content';

export interface CarePartnerCardUiProps {
	partnerInfo: Subject;
	patientInfo: Patient;
	onEdit: () => void;
}

export function CarePartnerCardUi({
	partnerInfo,
	patientInfo,
	onEdit,
}: Readonly<CarePartnerCardUiProps>) {
	const { t } = useTranslation();

	return (
		<StyledCard>
			<Container>
				<Header>
					<Typography.H3
						data-id='care-partner-title'
						color={theme.color.bodyTextSecondary}
						weight='500'
						style={{
							letterSpacing: 0.2,
						}}
					>
						{t('web.patients.carePartner.title')}
					</Typography.H3>
					<ShowIfAuthorized
						operations={[OperationToken.EditParticipant]}
					>
						<EditPen
							dataTestId='care-partner'
							onClick={onEdit}
							text={t('web.patients.carePartner.edit.editCTA')}
						/>
					</ShowIfAuthorized>
				</Header>
				<Content partnerInfo={partnerInfo} patientInfo={patientInfo} />
			</Container>
			<CarePartnerModal modalId='EditCarePartner' />
			<CarePartnerConfirmationModal modalId='EditCarePartnerConfirmation' />
		</StyledCard>
	);
}
