import { DefaultApiSaveTestingReasonRequest } from '@lh/eng-platform-battery-service-rest-client';

import { useMutation } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';

export async function saveTestingReason(
	searchInput: DefaultApiSaveTestingReasonRequest
) {
	const service = await getBatteryService();
	const { data } = await service.saveTestingReason(searchInput);
	return data;
}
export function useSaveTestingReasonMutation() {
	return useMutation({
		meta: {
			errorMessage: 'Error saving testing reason',
		},
		mutationFn: saveTestingReason,
	});
}
