import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { Caption, H4, Icon, P1 } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import {
	Divider,
	IconContainer,
	SectionCard,
} from '../OrientationQuestionaire.style';

import { TableHeadingsWrapper } from './Table.style';
import { ORIENTATION_TABLE_COLUMNS } from './Table.utils';

type TableProps = {
	elements: QnrRowData[];
};

export function Table({ elements }: Readonly<TableProps>) {
	const { t } = useTranslation();
	return (
		<SectionCard>
			<TableHeadings />
			<div>
				<SimpleTable<QnrRowData>
					borderColor={theme.colors.gray_60}
					columns={ORIENTATION_TABLE_COLUMNS}
					data={elements}
					striped='even'
					horizontalSpacing={theme.spacing.sm}
					verticalSpacing={theme.spacing.md}
				/>
				<Divider />
			</div>
			<P1
				style={{
					color: theme.color.graphTextSecondary,
					textAlign: 'center',
				}}
			>
				{t('web.report.orientationQuestionnaireReport.footer')}
			</P1>
		</SectionCard>
	);
}

function TableHeadings() {
	const { t } = useTranslation();

	return (
		<TableHeadingsWrapper>
			<div
				style={{
					display: 'flex',
					gap: 8,
					alignItems: 'center',
					flexWrap: 'wrap',
				}}
			>
				<H4
					style={{
						fontWeight: 500,
					}}
				>
					{t(
						'web.report.orientationQuestionnaireReport.answersTitle'
					)}
				</H4>
				<div
					style={{
						display: 'flex',
						gap: 16,
						rowGap: 4,
						flexWrap: 'wrap',
					}}
				>
					<div
						style={{
							display: 'flex',
							gap: 2,
						}}
					>
						<IconContainer size={theme.spacing.md}>
							<Icon
								tabIndex={-1}
								icon={icons.Close}
								color={theme.color.iconAlert}
								iconWidth={8}
								iconHeight={8}
							/>
						</IconContainer>
						<Caption
							style={{
								color: theme.color.textAlert,
							}}
						>
							{t(
								'web.report.orientationQuestionnaireReport.answerGuideIncorrect'
							)}
						</Caption>
					</div>
					<div
						style={{
							display: 'flex',
							gap: 2,
						}}
					>
						<IconContainer size={theme.spacing.md}>
							<Icon
								icon={icons.CheckMark}
								color={theme.color.iconSuccess}
								tabIndex={-1}
							/>
						</IconContainer>
						<Caption
							style={{
								color: theme.colors.green_darkest,
							}}
						>
							{t(
								'web.report.orientationQuestionnaireReport.answerGuideCorrect'
							)}
						</Caption>
					</div>
					<Caption
						style={{
							color: theme.color.graphTextSecondary,
						}}
					>
						{t(
							'web.report.orientationQuestionnaireReport.answerGuideNote'
						)}
					</Caption>
				</div>
			</div>
			<P1>{t('web.report.orientationQuestionnaireReport.subText')}</P1>
		</TableHeadingsWrapper>
	);
}
