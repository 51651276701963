import { DateInput as MantineDateInput, DateValue } from '@mantine/dates';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-mx';
import { EnumItem } from 'enums/enumeration';
import { useField, useFormikContext } from 'formik';
import i18n from 'i18n';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { validateDate } from 'utils/validationHelpers';
import { utcDateFormatter } from '../../../../stringStrategy/dateFormatStringStrategy';
import { LinusInputChildrenProps } from '../../../../types';
import { UserContext } from '../../../context/UserContext';

export const fuzzyDateParser = (
	value: string,
	dateFormat: string,
	userLocale: EnumItem
): Date => {
	// Lazy format allows us to pass in values like 1/2/22 instead of writing 01/02/2022
	const lazyFormat = dateFormat
		.replace('M', '')
		.replace('d', '')
		.replace('yy', '');

	const formattedDate = DateTime.fromFormat(value, lazyFormat).setLocale(
		userLocale.value
	);
	return formattedDate.toJSDate();
};

const DateInput = <T,>({
	name,
	label,
	value,
	clearable,
	error,
}: LinusInputChildrenProps<T>): JSX.Element => {
	const { setFieldValue, setFieldTouched } = useFormikContext();
	const [field] = useField(name);
	const theme = useTheme();

	const { currentUser } = useContext(UserContext);

	// Fix for formik bug
	// we need to manually detect when the dropdown was closen and when the date was selected
	// in order to tell formik when to validate, because it's value is one state behind
	const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
	const [isDateSelected, setIsDateSelected] = useState<boolean>(false);

	const dateFormat =
		currentUser?.organizationDateFormat.toUpperCase() || 'MM/DD/YYYY';

	const parsedLocale = (() => {
		switch (i18n.resolvedLanguage) {
			case 'en_GB':
				return 'en-gb';
			case 'es_MX':
				return 'es-mx';
			default:
				return 'en';
		}
	})();

	// WEB-2462: We have to hardcode this due to the javascript Date constructor
	// only accepting certain formats depending on browser and version.
	const dateValue = value
		? new Date(utcDateFormatter(value, 'MM/dd/yyyy'))
		: undefined;

	//We validate if it's a valid date to not show INVALID DATE error directly on the input.
	const formattedDate = validateDate(dateValue) ? dateValue : undefined;

	const handleChange = (selectedDate: DateValue): void => {
		setIsDateSelected(!!selectedDate);
		if (selectedDate) {
			setFieldValue(
				name,
				DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd')
			);
		}
	};

	const handleTouched = () => {
		setIsFieldTouched(true);
	};

	useEffect(() => {
		isFieldTouched &&
			!isDateSelected &&
			setFieldTouched(field.name, true, true);
	}, [isFieldTouched, isDateSelected]);

	return (
		<MantineDateInput
			locale={parsedLocale}
			valueFormat={dateFormat}
			placeholder={label}
			name={field.name}
			error={error}
			minDate={new Date(1900, 1, 1)}
			maxDate={new Date()}
			clearable={clearable}
			value={formattedDate}
			onChange={handleChange}
			onBlur={handleTouched}
			dateParser={(value: string) =>
				// Determines how we intepret the date that renders to the calendar UI
				fuzzyDateParser(
					value,
					currentUser?.organizationDateFormat,
					currentUser.organizationDefaultUserLocale
				)
			}
			styles={{
				input: {
					height: '48px',
					paddingLeft: '23px',
					paddingTop: formattedDate && '14px',
					fontSize: '16px',
					borderRadius: '6px',
					color: theme.color.formText,
					'--_input-placeholder-color': `${theme.color.formText}`,
				},
				// we need to hide the default mantine error because we
				// handle the error text in the generic input component
				error: {
					display: 'none',
				},
				day: {
					'&[data-weekend]&:not([data-outside])': {
						color: theme.color.formText,
						'&[data-selected]': {
							color: '#fff',
						},
					},
				},
			}}
		/>
	);
};

export { DateInput };
