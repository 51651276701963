import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { Memoize } from 'helpers/Memoize';

import { GlobalStyle } from './GlobalStyle';
import { theme } from './theme';

interface StyleProviderProps {
	children: ReactNode;
}

export const StyleProvider = ({
	children,
}: StyleProviderProps): JSX.Element => {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Memoize>{children}</Memoize>
		</ThemeProvider>
	);
};
