import { CDSVersions, useCDS } from 'components/shared/hooks';

import { PatientDetails_V1 } from './PatientDetails_V1';
import { PatientDetails_V2 } from './PatientDetails_V2';

export function PatientDetails() {
	const cds = useCDS();

	if (cds === CDSVersions.V2) {
		return <PatientDetails_V2 />;
	}

	return <PatientDetails_V1 />;
}
