import { theme } from 'components/providers/StyleProvider/theme';
import { t } from 'i18next';

const conditions: Record<
	string,
	{ scoreBgColor: string; text: string; textColor: string }
> = {
	'<0': {
		scoreBgColor: theme.colors.gray_80,
		text: t('web.report.mip.score.grey'),
		textColor: theme.color.graphTextPrimary,
	},
	'0-50': {
		scoreBgColor: theme.colors.green_lightest,
		text: t('web.report.mip.score.green'),
		textColor: theme.colors.green_darkest,
	},
	'51-80': {
		scoreBgColor: theme.colors.yellow_lightest,
		text: t('web.report.mip.score.yellow'),
		textColor: theme.colors.yellow_darkest,
	},
	'81+': {
		scoreBgColor: theme.colors.orange_lightest,
		text: t('web.report.mip.score.red'),
		textColor: theme.colors.orange_darkest,
	},
};

export const getContainerType = (score: number) => {
	if (score < 0) return conditions['<0'];
	if (score <= 50) return conditions['0-50'];
	if (score <= 80) return conditions['51-80'];

	return conditions['81+'];
};

export const MIP_SCORES = {
	green: '0-50%:',
	yellow: '51-80%:',
	red: '81-100%:',
};

export function getAdditionalText(score: number) {
	switch (score) {
		case 0:
			return '(0-3)';
		case 1:
			return '(0-4)';
		case 2:
			return '(0-5)';
		case 98:
			return '(95-100)';
		case 99:
			return '(96-100)';
		case 100:
			return '(97-100)';
		default:
			return '(± 3)';
	}
}
