import { Form } from 'formik';
import styled, { css } from 'styled-components';

import { P1 } from 'components/shared/designSystem';

export const Content = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const FormContainer = styled(Form)(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledButtonAndErrorRow = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const StyledInfoMessage = styled.div(
	({ theme: { spacing } }) => css`
		padding-bottom: ${spacing.md};
	`
);

export const StyledButtonRow = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		justify-content: center;
		gap: ${spacing.xl};
	`
);

export const ValidationError = styled(P1)(
	({ theme: { color, spacing } }) => css`
		color: ${color.textAlert};
		margin-bottom: ${spacing.md};
	`
);
