import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { TabItem } from '../../../layout/TabbedLayout/TabbedLayout';
import { Icon } from '../../../shared/designSystem/Icon';
import { H3, H4, P2 } from '../../../shared/designSystem/TextComponents';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { Metric } from '../../segmentUtilities/metrics';
import { CCEImpressionSection } from '../CCEImpressionSection';
import { CDSContext } from '../../../context/CDSContext';
import {
	getContentId,
	getDCRSectionText,
	getLHQSectionText,
} from './CCEImpression.logic';

type CCEImpressionProps = {
	dcrScore: Metric;
	dcrWarning: boolean;
	elevatedRisk: boolean;
	modifiableFactors: boolean;
	tabs?: TabItem[];
	setActiveTabName?: React.Dispatch<React.SetStateAction<string>>;
};

const CCEImpression = ({
	dcrScore,
	elevatedRisk,
	dcrWarning,
	modifiableFactors,
	tabs,
	setActiveTabName,
}: CCEImpressionProps): JSX.Element | null => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { contentVersion } = useContext(CDSContext);
	if (!tabs || !setActiveTabName) return null;
	const prefix = (contentId?: string): string =>
		`cds.impression${contentId ? `.${contentId}` : ''}`;

	const lowDCRBadge = Number(dcrScore.value) < 2;
	const cceContentId = getContentId(Number(dcrScore.value), elevatedRisk);
	const dcrSectionText = getDCRSectionText(Number(dcrScore.value));
	const lhqSectionText = getLHQSectionText(elevatedRisk, modifiableFactors);

	const getTab = (name: string): string =>
		t(`web.report.cognitiveEvaluation.batteryTabs.${name}`);

	const dcrTab = tabs.find((tab) => tab.name === getTab('dcr'));
	const lhqTab = tabs.find((tab) => tab.name === getTab('lifeHealth'));
	return (
		<StyledContainer data-testid='cceImpression'>
			<StyledTitle>{t(`${prefix()}.title`)}</StyledTitle>
			<StyledHeading data-testid='cceImpression_header'>
				<Trans
					i18nKey={`${prefix(cceContentId)}.text_${contentVersion}`}
					components={{
						bold: <b />,
						b: <b />,
					}}
				/>
			</StyledHeading>
			<StyledSection data-testid='cceImpression_DCR'>
				<CCEImpressionSection
					header={t(`${prefix()}.dcr.header`)}
					dcrScore={Number(dcrScore.value)}
					sectionText={
						<Trans
							i18nKey={`${prefix()}.dcr.${dcrSectionText}_${contentVersion}`}
							components={{
								bold: <b />,
							}}
						/>
					}
					score={
						<Trans
							i18nKey={`${prefix()}.dcr.score`}
							values={{ dcrScore: String(dcrScore.value) }}
						/>
					}
					linkText={t(`${prefix()}.link`)}
					resultsLink={() => {
						setActiveTabName(dcrTab?.name || tabs[0]?.name);
					}}
					dcrWarning={
						dcrWarning ? (
							<StyledWarning data-testid='cceImpression_DCRWarning'>
								<Icon
									icon={icons.WarningSolid}
									iconWidth={24}
									iconHeight={24}
									color={theme.color.alertWarning}
								/>
								<StyledWarningText>
									{t(`${prefix()}.dcr.warning`)}
								</StyledWarningText>
							</StyledWarning>
						) : null
					}
					badge={
						lowDCRBadge ? (
							<Icon
								icon={icons.ArrowFlag}
								width={20}
								height={20}
								color={theme.color.cdsWarningIcon}
							/>
						) : null
					}
					tooltipKey={`${prefix()}.dcr.tooltip`}
				/>
			</StyledSection>
			<StyledSection data-testid='cceImpression_LHQ'>
				<CCEImpressionSection
					header={t(`${prefix()}.lhq.header`)}
					sectionText={
						lhqSectionText ? (
							<Trans
								i18nKey={`${prefix()}.lhq.${lhqSectionText}`}
								components={{
									bold: <b />,
								}}
							/>
						) : null
					}
					linkText={t(`${prefix()}.link`)}
					resultsLink={() => {
						setActiveTabName(lhqTab?.name || tabs[0]?.name);
					}}
					badge={
						elevatedRisk ? (
							<LinusTooltip
								overlay={
									<StyledToolTip>
										<StyledTooltipText>
											<Trans
												i18nKey={`${prefix()}.lhq.elevatedRiskTooltip`}
												components={{
													newLine: <br />,
												}}
											/>
										</StyledTooltipText>
									</StyledToolTip>
								}
								tooltipIcon={
									<Icon
										icon={icons.ArrowFlag}
										width={20}
										height={20}
										color={theme.color.cdsWarningIcon}
									/>
								}
							/>
						) : null
					}
					tooltipKey={`${prefix()}.lhq.tooltip`}
				/>
			</StyledSection>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { color, spacing, boxShadow } }) => `
	display: flex;
	flex-direction: column;
    padding: ${spacing.lg} ${spacing.md};
	height: fit-content;
	background: ${color.white};
	border: 1px solid ${color.cdsPanelBorder};
	box-shadow: ${boxShadow.standard};
	border-radius: 10px;
	overflow: hidden;
`
);

const StyledTitle = styled(H3)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const StyledHeading = styled(H4)(
	({ theme: { color, spacing } }) => `
	margin: 12px 0 ${spacing.lg} 0;
	color: ${color.headerTextSecondary};
`
);

const StyledSection = styled.div(
	({ theme: { color, spacing } }) => `
	position: relative;
    padding: ${spacing.lg} 0 ${spacing.lg} 0;
	&:after {
		content: '';
		position: absolute;
        top: 0;
		width: 472px;
		border-top: 1px solid ${color.cdsPanelBorder};
	}
    &:last-child {
        padding-bottom: 0;
    }
`
);

const StyledWarning = styled.div(
	({ theme: { spacing } }) => `
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: ${spacing.md};
`
);

const StyledWarningText = styled(P2)(
	({ theme: { color } }) => `
	color: ${color.alertWarningTextDark};
	margin-left: 11px;
`
);

const StyledTooltipText = styled(P2)(
	({ theme: { color } }) => `
    padding: 5px;
	color: ${color.white};
`
);

const StyledToolTip = styled.div`
	width: 304px;
	height: auto;
	text-align: left;
`;

export { CCEImpression };
