import { Trans } from 'react-i18next';

import i18n from 'i18n';
import { CarePartnerType } from '../CarePartner.types';

export function getConfirmationModalInfo(
	type: CarePartnerType,
	fullNameCarePartner: string,
	fullNamePatient: string
) {
	if (type === CarePartnerType.Add) {
		return {
			title: i18n.t('web.patients.carePartner.add.success.title'),
			info: (
				<Trans
					i18nKey='web.patients.carePartner.add.success.info'
					components={{
						b: <b />,
					}}
					values={{
						fullNameCarePartner,
						fullNamePatient,
					}}
				/>
			),
		};
	}
	return {
		title: i18n.t('web.patients.carePartner.edit.success.title'),
		info: (
			<Trans
				i18nKey='web.patients.carePartner.edit.success.info'
				components={{
					b: <b />,
				}}
				values={{
					fullNamePatient,
				}}
			/>
		),
	};
}
