import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getBatteryService } from 'api/getBatteryService';
import { QueryKey } from 'api/query';

export async function getBatteries(
	organizationId: string | null,
	signal?: AbortSignal
) {
	if (!organizationId) {
		throw new Error("useGetBatteriesQuery: organizationId can't be null");
	}

	const service = await getBatteryService();
	const { data } = await service.getBatteries({ organizationId }, { signal });
	return data;
}

export function useBatteriesQuery(organizationId: string | null) {
	return useQuery({
		enabled: !!organizationId,
		meta: {
			errorMessage: 'Error fetching batteries',
		},
		placeholderData: keepPreviousData,
		queryKey: [QueryKey.Batteries, organizationId],
		queryFn: ({ signal }) => getBatteries(organizationId, signal),
		staleTime: Infinity,
	});
}
