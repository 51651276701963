import { List } from './Pathway.misc';

export type ListNode = {
	items: ListItems;
	options?: ListOptions;
};
type ListItems = (ListItem | ListNode)[];
type ListItem = string;
type ListOptions = Partial<{
	type: ListType;
	listStyleType: ListStyleType;
	gap: number;
}>;
type ListType = 'ordered' | 'unordered';
type ListStyleType =
	| 'decimal'
	| 'disc'
	| 'circle'
	| 'square'
	| 'upper-roman'
	| 'lower-alpha';

export function buildList(
	items: ListItems,
	options: ListOptions = {}
): ListNode {
	const { type = 'unordered', listStyleType = 'disc' } = options;

	return { items, options: { type, listStyleType } };
}

export function iterateList(
	items: ListItems,
	render: {
		list(list: ListNode, index: number): React.ReactNode;
		item(item: ListItem, index: number): React.ReactNode;
	}
) {
	return items.map((item, index) => {
		if (typeof item === 'object') {
			return render.list(item, index);
		} else {
			return render.item(item, index);
		}
	});
}

export function RenderList({
	list,
	renderItem,
}: {
	list: ListNode;
	renderItem(item: ListItem): React.ReactNode;
}) {
	const { items, options } = list;

	return (
		<List
			type={options?.type}
			listStyleType={options?.listStyleType}
			gap={options?.gap}
		>
			{iterateList(items, {
				list: (list, index) => (
					<RenderList
						key={`list-${index}`}
						list={list}
						renderItem={renderItem}
					/>
				),
				item: (item, index) => (
					<List.Item key={`item-${item}-${index}`}>
						{renderItem(item)}
					</List.Item>
				),
			})}
		</List>
	);
}
