import { ForwardedRef, forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { CdsRecommendation } from '../../../../generated/graphql';
import { theme } from '../../../providers/StyleProvider/theme';
import { Icon } from '../../../shared/designSystem/Icon';
import { H4, P2 } from '../../../shared/designSystem/TextComponents';
import { LinusTooltip } from '../../../shared/LinusTooltip';
import { CDSCard } from '../CDSCard';

type CDSContainerProps = {
	cdsRecommendations: CdsRecommendation[];
	handleBHAPLink: (contentId: string) => void;
};

export const CDSContainer = forwardRef(
	(
		{ cdsRecommendations, handleBHAPLink }: CDSContainerProps,
		forwardedRef: ForwardedRef<HTMLDivElement[] | null[]>
	): JSX.Element | null => {
		const { t } = useTranslation();

		if (cdsRecommendations.length < 1) {
			return null;
		}

		const subtitleKey =
			cdsRecommendations.length <= 1
				? 'cds.sectionSubtitle_one'
				: 'cds.sectionSubtitle_other';

		return (
			<StyledContainer data-testid='cdsContainer'>
				<StyledHeader>
					<StyledTitle>{t('cds.sectionTitle')}</StyledTitle>
					<LinusTooltip
						overlay={
							<StyledToolTip>
								<StyledTooltipText>
									<Trans i18nKey={'cds.tooltips.section'} />
								</StyledTooltipText>
							</StyledToolTip>
						}
						tooltipIcon={
							<StyledInfoIcon>
								<Icon
									icon={icons.InfoOutlined}
									color={theme.color.cdsHeaderInfoIcon}
									width={18}
									height={18}
								/>
							</StyledInfoIcon>
						}
					/>
				</StyledHeader>
				<StyledSubtitle>
					{t(subtitleKey, {
						count: cdsRecommendations.length,
					})}
				</StyledSubtitle>
				<StyledCardWrapper>
					{cdsRecommendations.map((recommendation, idx) => {
						return (
							<CDSCard
								key={recommendation.contentId}
								contentId={recommendation.contentId}
								ref={(element) => {
									if (
										forwardedRef != null &&
										typeof forwardedRef !== 'function' &&
										forwardedRef.current
									) {
										forwardedRef.current[idx] = element;
									}
								}}
								handleBHAPLink={handleBHAPLink}
							/>
						);
					})}
				</StyledCardWrapper>
			</StyledContainer>
		);
	}
);

const StyledContainer = styled.div(
	({ theme: { spacing } }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${spacing.xl};
`
);

const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledTitle = styled(H4)(
	({ theme: { fontSize, weight } }) => `
	font-size: ${fontSize._32};
	font-weight: ${weight.light};
`
);

const StyledSubtitle = styled.div(
	({ theme: { fontSize, color, spacing, weight } }) => `
    color: ${color.headerTextSecondary};
	  font-size: ${fontSize._14};
    align-items: flex-start;
	  margin-top: ${spacing.xs};
    margin-bottom: 0;
	  font-weight: ${weight.light};
`
);

const StyledTooltipText = styled(P2)(
	({ theme: { color, weight } }) => `
    color: ${color.white};
    font-weight: ${weight.light};
`
);

const StyledToolTip = styled.div`
	text-align: left;
	width: 284px;
`;

const StyledInfoIcon = styled.div`
	position: relative;
	margin-left: 11px;
`;

const StyledCardWrapper = styled.div(
	({ theme: { spacing } }) => css`
		height: auto;
		width: 100%;
		margin-top: ${spacing.xl};
		display: grid;
		grid-auto-rows: minmax(300px, auto);
		grid-template-columns: repeat(2, 1fr);
		gap: ${spacing.xl};
		@media (max-width: 1245px) {
			display: flex;
			flex-direction: column;
		}
	`
);
