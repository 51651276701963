import React from 'react';
import styled from 'styled-components';

type ExternalLinkProps = {
	text: string | number;
	href: string;
	target?: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	underline?: boolean;
};

/**
 * This component will be used to render external links which will
 * leads you to some website.
 */
const ExternalLink = ({
	text,
	href,
	onClick,
	target = '_blank',
	underline = true,
}: ExternalLinkProps): JSX.Element => {
	return (
		<StyledLink
			onClick={onClick}
			href={href}
			target={target}
			hasUnderline={underline}
		>
			{text}
		</StyledLink>
	);
};

export { ExternalLink };

type StyledLinkPropsProps = {
	hasUnderline: boolean;
};

const StyledLink = styled.a<StyledLinkPropsProps>(
	({ hasUnderline }) => `
	text-decoration: ${hasUnderline && 'underline'};
`
);
