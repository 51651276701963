import { useTranslation } from 'react-i18next';

import { StyledLink } from 'components/shared/StyledLink';

export function AboutAssessmentsLink() {
	const { t } = useTranslation();

	return (
		<StyledLink to='/about/assessments'>
			{t('web.shared.aboutAssessments.title')}
		</StyledLink>
	);
}
