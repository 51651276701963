import { Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import { InterpretedScore, ScoreTag, ScoreType } from 'components/mosaic';
import { usePercentileText } from 'components/report/usePercentileText';

import { FluencyPerformance } from './FluencyLayout.types';

const IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY: Record<number, string> = {
	0: 'web.report.fluency.performance.good',
	1: 'web.report.fluency.performance.bad',
};
const IMPAIRED_TO_SCORE_TYPE: Record<number, ScoreType> = {
	0: 'not-impaired',
	1: 'impaired',
};

type FluencyScoreProps = {
	performance: FluencyPerformance;
	requiresReview: boolean;
	scoreFor: string;
	scoreValue: string;
	showInterpretation: boolean;
};

export function FluencyScore({
	performance,
	requiresReview,
	scoreFor,
	scoreValue,
	showInterpretation,
}: Readonly<FluencyScoreProps>) {
	const { t } = useTranslation();

	const percentileText = usePercentileText(performance.percentile);
	const percentileInterpretation = percentileText
		? t('web.report.fluency.performance.value', {
				value: percentileText,
		  })
		: null;

	if (requiresReview) {
		return (
			<Stack maw={445}>
				<InterpretedScore
					interpretationDescription={t('web.report.fluency.rcr')}
					scoreLabel={t('web.report.fluency.scoreLabel')}
					scoreType='unanalyzable'
					scoreValue={scoreValue}
				/>
			</Stack>
		);
	}

	if (performance.impaired > -1) {
		const impairmentInterpretation = t(
			IMPAIRED_TO_IMPAIRMENT_INTERPRETATION_KEY[performance.impaired],
			{ type: scoreFor }
		);
		const scoreType = IMPAIRED_TO_SCORE_TYPE[performance.impaired];

		return (
			<Stack maw={445}>
				{showInterpretation ? (
					<InterpretedScore
						interpretationCaption={percentileInterpretation}
						interpretationDescription={impairmentInterpretation}
						scoreLabel={t('web.report.fluency.scoreLabel')}
						scoreType={scoreType}
						scoreValue={scoreValue}
					/>
				) : (
					<ScoreTag
						label={t('web.report.fluency.scoreLabel')}
						type={scoreType}
						value={scoreValue}
					/>
				)}
			</Stack>
		);
	}

	return (
		<Stack maw={445}>
			{!showInterpretation ? (
				<ScoreTag
					label={t('web.report.fluency.scoreLabel')}
					type='unknown'
					value={scoreValue}
				/>
			) : percentileInterpretation ? (
				<InterpretedScore
					interpretationDescription={percentileInterpretation}
					scoreLabel={t('web.report.fluency.scoreLabel')}
					scoreType='unknown'
					scoreValue={scoreValue}
				/>
			) : (
				<ScoreTag
					label={t('web.report.fluency.scoreLabel')}
					type='unknown'
					value={scoreValue}
				/>
			)}
		</Stack>
	);
}
