import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserStatus } from '@lh/eng-platform-organization-service-rest-client';

import {
	AnalyticsAction,
	sendEventData,
	updateAmplitudeWithProps,
} from 'analytics';
import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { config } from '../../../config';
import { routeEnum as _routeEnum, RouteEnum } from '../../../enums/routeEnum';
import { UserContext } from '../../context/UserContext';
import { loginLandingPage } from './loginLandingPage';

import { CurrentUser } from '../../../types';

import { isEmpty } from 'lodash';

const LoginForm = ({ pathname }: { pathname?: string }): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();

	const { currentUser } = useContext(UserContext);

	const isAuth0Universal = useFeatureFlag(FeatureFlags.Auth0Universal);

	const redirect = (user: CurrentUser) => {
		const enumeration = _routeEnum(user, isAuth0Universal);
		// Here location.state has no shape ( it's defined by the navigation ).  We are casting
		// it to what we hope to find in it.  Then trying to access that item.  If it's not there
		// then we check to see if the pathname was passed in to the form.  This is what will most often
		// happens when a user is loggedout and tries to access a page
		let path = (location.state as { from: string })?.from || pathname;
		// If path does not exist at this point, then we default to the login landing page for the specific org.
		path = path || loginLandingPage(user);
		// Check the route to see if it is available to this user or not.
		const isRouteFound = enumeration.find((route: RouteEnum) => {
			return path?.toLowerCase().includes(route.path);
		});
		if (
			user.userStatus === UserStatus.Invited &&
			config.preferredUserProvider === 'auth0'
		) {
			const path = `/auth/finish-signup`;
			navigate(path);
		} else {
			navigate(isRouteFound ? path : '/', { replace: true });
		}
	};

	useEffect(() => {
		if (isEmpty(currentUser)) return;

		updateAmplitudeWithProps(currentUser);
		sendEventData({
			eventType: AnalyticsAction.StartedSession,
		});
		redirect(currentUser);
	}, [currentUser, currentUser.id]);

	return <></>;
};

export { LoginForm };
