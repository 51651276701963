import React, { RefObject, useState } from 'react';
import styled, { css } from 'styled-components';
import { AnalyticsAction, sendEventData } from 'analytics';

export type TabItem = {
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Component?: React.FC<any>;
	isInitLoading?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props?: Record<string, any>;
	// Allows components to change the active tab from within
	requiresActiveTabSetter?: boolean;
	requiresCDS?: boolean;
};
export type TabbedLayoutProps = {
	tabs: TabItem[];
	offsetContainerRef?: RefObject<HTMLDivElement>;
	activeTabName?: string;
	setActiveTabName?: (name: string) => void;
};

const TabbedLayout = ({
	tabs,
	offsetContainerRef,
	activeTabName,
	setActiveTabName,
}: TabbedLayoutProps): JSX.Element | null => {
	const [_activeTabName, _setActiveTabName] = useState(tabs[0].name);

	activeTabName = activeTabName || _activeTabName;
	setActiveTabName = setActiveTabName || _setActiveTabName;

	const activeTab = tabs.find((tab) => tab.name === activeTabName) as TabItem;
	const { Component, props, requiresActiveTabSetter } = activeTab;
	const isActiveCallback = requiresActiveTabSetter
		? { setActiveTabName }
		: {};

	if (!Component) return null;

	return (
		<StyledContainer>
			<StyledTabMenu>
				<StyledList>
					{tabs.map(({ name }) => {
						const isTabActive = name === activeTabName;
						return (
							<StyledTab
								active={isTabActive}
								key={name}
								data-testid={name}
								onClick={() => {
									sendEventData({
										eventType:
											AnalyticsAction.ClickedCceTab,
										eventProperties: {
											name: name,
										},
									});

									// Scroll to start of the tab
									if (offsetContainerRef) {
										scrollToRefByOffset(offsetContainerRef);
									}

									setActiveTabName && setActiveTabName(name);
								}}
							>
								<StyledSpan active={isTabActive}>
									{name}
								</StyledSpan>
							</StyledTab>
						);
					})}
				</StyledList>
			</StyledTabMenu>
			<StyledWrapper>
				<Component {...props} {...isActiveCallback} tabs={tabs} />
			</StyledWrapper>
		</StyledContainer>
	);
};

export const scrollToRefByOffset = (offsetRef?: RefObject<HTMLDivElement>) => {
	if (offsetRef) {
		offsetRef.current?.parentElement?.parentElement?.scrollTo({
			top: offsetRef.current?.clientHeight || 0,
			behavior: 'smooth',
		});
	}
};

export { TabbedLayout };

const StyledContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: none;
`;
const StyledTabMenu = styled.div(
	({ theme: { color } }) => `
	background: ${color.body};
	width: 100%;
	height: 57px;
	color: ${color.bodyText};
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid ${color.menuBorder};
	position: sticky;
	top: 170px;
	z-index: 3;
`
);
const StyledList = styled.ul`
	overflow: auto;
	list-style: none;
	display: flex;
	align-items: center;
`;
type StyledTabProps = {
	active: boolean;
};
const StyledTab = styled.li<StyledTabProps>(
	({ active, theme: { spacing, color, colors } }) => `
	height: 100%;
	min-width: 160px;
	text-align: center;
	padding: ${spacing.md} ${spacing.sm};
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 8px 8px 0 0;
	border: 1px solid ${color.menuBorder};
	border-bottom:  ${
		active ? `3px solid ${color.menuBorderActive}` : color.menuBorder
	};
	background: ${active ? colors.blue_ultraLight : color.white};
	&:hover {
		background: ${color.cellHoverBg};
		cursor: pointer;
	};
	`
);
const StyledSpan = styled.span<StyledTabProps>(
	({ active, theme: { color, weight } }) => `
	font-weight: ${active ? weight.semi : weight.medium};
	padding: 0;
	margin: 0;
	color: ${active ? color.bodyText : color.menuText};
	`
);
const StyledWrapper = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;
		height: 100%;
		z-index: 1;
		margin-top: ${spacing.xl};
		overflow-y: hidden;
	`
);
