import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useZendeskUrlQuery } from 'api/user';
import { UserContext } from 'components/context';
import { getBrowserLanguage } from '../shared/getLanguage';

enum ZendeskArticleKeys {
	instructionsForUse = '4414840896539',
}

type ZendeskArticleProps = {
	article: ZendeskArticleKeys;
};

const ZendeskArticle = ({ article }: ZendeskArticleProps): JSX.Element => {
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();
	const locale = getBrowserLanguage();

	const zendesk = useZendeskUrlQuery({
		article,
		locale,
		organizationId: currentUser.organizationId,
		userId: currentUser.id,
	});

	useEffect(() => {
		if (zendesk.data) {
			const zendeskRedirectUrl = zendesk.data;

			if (zendeskRedirectUrl) {
				window.open(zendeskRedirectUrl);
			}
			navigate('/regulatory-label'); //Remains on the regulatory label page
		}
	}, [zendesk, navigate]);
	return <></>; //TODO: we should probably have a loading spinner here or something
};
export { ZendeskArticle, ZendeskArticleKeys };
