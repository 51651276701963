import { UserStatus } from '@lh/eng-platform-organization-service-rest-client';

import { Form, Formik, FormikHelpers } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useUpdateUser } from 'api/user';
import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { UserOnlyContext } from 'components/providers/UserProvider/UserOnlyContext';
import { ERROR } from 'logging/linusLogger';
import { icons } from '../../../enums/icons';
import { MessageEnumItem, messageEnum } from '../../../enums/messageEnum';
import { UserContext } from '../../context/UserContext';
import { LinusInput } from '../../shared/Forms/Components/LinusInput';
import {
	CreateInvitedAccount,
	createInvitedAccountSchema,
} from '../../shared/Forms/Schemas/createInvitedAccountSchema';
import { InfoMessage } from '../../shared/InfoMessage';
import { PrivacyPolicyLink } from '../../shared/PrivacyPolicyLink';
import { TermsOfUseLink } from '../../shared/TermsOfUseLink';
import { ButtonLg } from '../../shared/designSystem/ButtonLg';
import { Icon } from '../../shared/designSystem/Icon';
import { H2 } from '../../shared/designSystem/TextComponents';

import { getExpiredInvitationMessageEnum } from './CreateAccountForm.helpers';

interface FromValues {
	firstName: string;
	lastName: string;
	email: string;
}

const CreateAccountFormAuth0 = (): JSX.Element => {
	const theme = useTheme();
	const { currentUser } = useContext(UserContext);
	const { refetchUser } = useContext(UserOnlyContext);

	const navigate = useNavigate();
	const location = useLocation();

	const initialFormValues = useRef<FromValues>({
		email: '',
		firstName: '',
		lastName: '',
	});

	const { t } = useTranslation();

	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();

	const {
		mutateAsync: updateUser,
		error: updateUserError,
		isPending,
	} = useUpdateUser(currentUser.organizationId);

	const messages = {
		finishSignUpError: t`web.auth.shared.messages.finishSignUpError`,
	};

	const params = new URLSearchParams(location.search);

	const payloadHex = params.has('payload')
		? params.get('payload')
		: undefined;

	const payload = payloadHex
		? JSON.parse(Buffer.from(payloadHex, 'hex').toString())
		: undefined;

	const orgNameDisplay = currentUser.organizationName?.length
		? currentUser.organizationName
		: payload?.orgName;

	if (payload && currentUser.id === '') {
		initialFormValues.current.email = payload.email;
		initialFormValues.current.firstName = payload.firstName;
		initialFormValues.current.lastName = payload.lastName;
	} else {
		initialFormValues.current.email = currentUser.email;
		initialFormValues.current.firstName = currentUser.firstName;
		initialFormValues.current.lastName = currentUser.lastName;
	}

	useEffect(() => {
		if (currentUser.id === '' && !params.get('payload')) {
			navigate('/auth/login', { replace: true });
		}
	}, []);

	if (updateUserError && !serverSideMessage) {
		setServerSideMessage(
			payload
				? getExpiredInvitationMessageEnum(theme)
				: messageEnum.error(
						messages.finishSignUpError,
						icons.AlertShieldSolid
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  )
		);
	}

	const onSubmit = async (
		values: CreateInvitedAccount,
		{ setSubmitting }: FormikHelpers<CreateInvitedAccount>
	) => {
		// Resetting the error state on submit click
		setServerSideMessage(undefined);
		try {
			const user = await updateUser({
				userUpdate: {
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					status: UserStatus.Active,
				},
				organizationId: currentUser.organizationId,
				userId: currentUser.id,
			});

			if (user) {
				refetchUser();
			}
		} catch (error) {
			ERROR(
				`Error updating user from CreateAccountForm.auth0, userId: ${currentUser.id}, orgId: ${currentUser.organizationId}: `,
				error
			);
		}
		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={{
				firstName: initialFormValues.current.firstName,
				lastName: initialFormValues.current.lastName,
				email: initialFormValues.current.email,
			}}
			validationSchema={createInvitedAccountSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, isValid }) => {
				return (
					<StyledContainer>
						<StyledMessageContainer>
							{!serverSideMessage && (
								<>
									<Icon
										icon={icons.InviteOutlined}
										iconWidth={40}
										iconHeight={40}
										color={theme.color.iconInvite}
										title={t`web.auth.forms.inviteMail`}
									/>
									<StyledInviteMessage>
										<Trans
											i18nKey='web.auth.shared.fragments.invitedToJoin'
											values={{
												orgName: orgNameDisplay || '',
											}}
											components={[<strong />]}
										/>
									</StyledInviteMessage>
								</>
							)}
						</StyledMessageContainer>
						<H2>{t`web.auth.shared.fragments.confirmInformation`}</H2>
						<StyledForm>
							<StyledRow>
								<LinusInput
									width='360px'
									name='firstName'
									data-id='new-first-name-auth0'
									label={t`web.shared.firstName`}
								/>
								<LinusInput
									width='360px'
									name='lastName'
									data-id='new-last-name-auth0'
									label={t`web.shared.lastName`}
								/>
							</StyledRow>
							<StyledRow>
								<LinusInput
									data-id='new-email-auth0'
									name='email'
									type='email'
									width='100%'
									disabled
									label={t`web.shared.email`}
								/>
							</StyledRow>
							<InfoMessage
								showIf={!!serverSideMessage}
								messageEnum={serverSideMessage}
							/>
							<StyledAgreementContainer>
								<StyledAgreementText>
									<Trans
										i18nKey='web.auth.shared.fragments.continueToAgree'
										components={[
											<PrivacyPolicyLink
												text={t`web.shared.LHPP`}
												underline={false}
											/>,
											<TermsOfUseLink
												underline={false}
											/>,
										]}
									/>
								</StyledAgreementText>
							</StyledAgreementContainer>
							<StyledSubmitButtonWrapper>
								<ButtonLg
									dataId='createAccountBtn'
									disabled={!isValid || isSubmitting}
									text={t`web.auth.forms.continue`}
									type='submit'
									width='360px'
									primary={true}
									loading={isPending}
								/>
							</StyledSubmitButtonWrapper>
						</StyledForm>
					</StyledContainer>
				);
			}}
		</Formik>
	);
};

export { CreateAccountFormAuth0 };

const StyledContainer = styled.div`
	width: 100%;
	height: 760px;
	padding: 56px 120px;
	transition: 0.4s ease all;
	text-align: center;
	justify-content: space-between;
`;

const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media ${MediaQueries.maxWidth.lg} {
		gap: 32px;
	}
`;
const StyledMessageContainer = styled.div(
	({ theme: { spacing } }) => `
        display: flex;
        align-items: center;
		margin: ${spacing.xl} 0;
    `
);

const StyledForm = styled(Form)(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);
const StyledAgreementContainer = styled.div`
	display: flex;
`;

const StyledAgreementText = styled.span(
	({ theme: { spacing } }) => `
        padding: ${spacing.md} 0;
    `
);

const StyledSubmitButtonWrapper = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 ${spacing.md} 0;
	display: flex;
	justify-content: center;
	`
);

const StyledInviteMessage = styled.span(
	({ theme: { spacing } }) => `
	text-align: center;
	margin: 0 0 0 ${spacing.md};
	`
);
