import { resources } from '@lh/i18n-common';
import { FallbackLngObjList } from 'i18n';
import { coerceToBoolean } from '../typeGuard';

const fallbackLng = 'en_US';
interface IHash {
	[details: string]: string[];
}
const fallbacks: IHash = {
	default: [fallbackLng],
	en: [fallbackLng],
	es: ['es_MX'],
};

const resolveFallbackToDefaultLng = function () {
	let fallbackValue = coerceToBoolean(
		process.env.I18N_FALLBACK_TO_DEFAULT_LNG
	);
	if (
		process.env.I18N_FALLBACK_TO_DEFAULT_LNG === undefined ||
		process.env.I18N_FALLBACK_TO_DEFAULT_LNG === null
	) {
		fallbackValue = true; //?
	}
	const hasExternalConfig =
		!!window.externalConfig && window.externalConfig?.apiHost;
	if (hasExternalConfig) {
		const externalConfig = window.externalConfig;
		fallbackValue = coerceToBoolean(externalConfig.fallbackToDefaultLng);
		if (
			externalConfig.fallbackToDefaultLng === undefined ||
			externalConfig.fallbackToDefaultLng === null
		) {
			fallbackValue = true;
		}
	}
	return fallbackValue;
};

const fallbackToDefaultLng = resolveFallbackToDefaultLng();

export const fallbackLngResolver = (code: string): FallbackLngObjList => {
	// this is not a truly recursive fallback, but:
	// if `es-ES` is sent in, then `es` will be used
	// and therefore `es`'s fallback will be activated.
	// that is, if the specific region-locale is not defined here
	// but the common region is, then the common region will be chosen
	// otherwise we get the special default
	// this function runs every time i18n needs to resolve the language,
	// so we can potentially avoid generating the extra fallbacks by checking what
	// the user is actually requesting, first.
	if (
		!!code &&
		code.length > 0 &&
		!fallbacks[code] &&
		code.match(/[a-z]{2}-/) !== null
	) {
		// injects the `-` based key to fallback to the `_` version
		for (const key in resources) {
			if (key.match(/[a-z]{2}_/)) {
				fallbacks[key.replace('_', '-')] = [key];
			}
		}
	}

	if (fallbackToDefaultLng) {
		for (const key in fallbacks) {
			if (!fallbacks[key].includes(fallbackLng)) {
				fallbacks[key].push(fallbackLng);
			}
		}
	}
	return fallbacks as FallbackLngObjList;
};
