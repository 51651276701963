import styled, { css } from 'styled-components';

import titleize from 'titleize';

import { SortOrder } from '../../../types';

import { TableColumn } from './DataTable';
import { HeaderCell } from './HeaderCell';

type HeaderProps<T> = {
	columns: TableColumn<T>[];
	onSortData: React.Dispatch<React.SetStateAction<SortOrder | undefined>>;
	deviantWidth?: string;
	returnToDefault?: boolean;
};
const Header = <T,>({
	columns,
	onSortData,
	deviantWidth,
	returnToDefault = false,
}: HeaderProps<T>): JSX.Element => {
	return (
		<StyledHeader data-id='data_table_header' deviantWidth={deviantWidth}>
			{columns.map((column, idx) => {
				let value;
				if (column.headerColumnComponent) {
					value = column.headerColumnComponent({ column });
				} else if (column.headerDisplay) {
					value = column.headerDisplay;
				} else {
					value = titleize(column.propertyName);
				}
				return (
					<HeaderCell
						value={value}
						column={column}
						key={idx}
						onSortData={onSortData}
						returnToDefault={returnToDefault}
					/>
				);
			})}
		</StyledHeader>
	);
};

export { Header };

type StyledHeaderProps = {
	deviantWidth?: string;
};
const StyledHeader = styled.div<StyledHeaderProps>(
	({ deviantWidth, theme: { color, fontSize, weight } }) => css`
		min-width: ${deviantWidth ? deviantWidth : '1080px'};
		display: flex;
		border-bottom: 1px solid ${color.tableBorder};
		font-size: ${fontSize._18};
		font-weight: ${weight.bold};
		color: ${color.bodyText};
		border-left: none;
	`
);
