import styled, { css } from 'styled-components';

import React from 'react';

type ProgressBarProps = {
	progressBarRef?: React.RefObject<HTMLInputElement>;
	defaultValue?: string | number;
	min?: string | number;
	max?: string | number;
	onChange?: () => void;
};

export const ProgressBar = ({
	progressBarRef,
	defaultValue = 0,
	min = 0,
	max,
	onChange,
}: ProgressBarProps) => {
	return (
		<ProgressInputRange
			type='range'
			defaultValue={defaultValue}
			ref={progressBarRef}
			onChange={onChange}
			min={min}
			max={max}
		/>
	);
};

const ProgressInputRange = styled.input(
	({ theme: { spacing, color } }) => css`
		--seek-before-width: 0;

		appearance: none;
		background: ${color.menuBorder};
		border-radius: 10px;
		position: relative;
		width: 98%;
		height: 6px;
		outline: none;
		cursor: pointer;
		margin-bottom: ${spacing.xs};

		/* progress bar - safari */
		::-webkit-slider-runnable-track {
			background: ${color.menuBorder};
			border-radius: 10px;
			position: relative;
			width: 100%;
			height: 6px;
			outline: none;
		}

		/* progress bar - chrome and safari */
		::before {
			content: '';
			height: 6px;
			width: var(--seek-before-width);
			background-color: ${color.textBlue};
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			cursor: pointer;
		}

		/* knobby - chrome and safari */
		::-webkit-slider-thumb {
			-webkit-appearance: none;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			border: none;
			background: ${color.textBlue};
			cursor: pointer;
			position: relative;
			margin: -4px 0 0 0;
			z-index: 3;
			box-sizing: border-box;
		}

		/* knobby while dragging - chrome and safari */
		:active::-webkit-slider-thumb {
			transform: scale(1.2);
		}
	`
);
