import { t } from 'i18n';
import { DataSet } from '../../graphs/CompositeScales';
import { MetricHash } from '../metrics';

export const getSpatialReasoningDataSet = (dataMap: MetricHash): DataSet => {
	//Parse the map and get values of interest in chart-friendly data structure
	const spatialReasoningMap = {
		commandClock: [
			{
				x: spatialReasoningStrings.clockFaceCircularity,
				y: dataMap?.COMClockfaceCircularity_s?.value,
			},
			{
				x: spatialReasoningStrings.componentPlacement,
				y: dataMap?.COMComponentPlacement_s?.value,
			},
			{
				x: spatialReasoningStrings.verticalSpatialPlacement,
				y: dataMap?.COMVerticalSpatialPlacement_s?.value,
			},
			{
				x: spatialReasoningStrings.horizontalSpatialPlacement,
				y: dataMap?.COMHorizontalSpatialPlacement_s?.value,
			},
		],
		copyClock: [
			{
				x: spatialReasoningStrings.clockFaceCircularity,
				y: dataMap?.COPClockfaceCircularity_s?.value,
			},
			{
				x: spatialReasoningStrings.componentPlacement,
				y: dataMap?.COPComponentPlacement_s?.value,
			},
			{
				x: spatialReasoningStrings.verticalSpatialPlacement,
				y: dataMap?.COPVerticalSpatialPlacement_s?.value,
			},
			{
				x: spatialReasoningStrings.horizontalSpatialPlacement,
				y: dataMap?.COPHorizontalSpatialPlacement_s?.value,
			},
		],
	};

	return spatialReasoningMap as DataSet;
};

export const spatialReasoningTooltipKeys = [
	'web.dcrReport.tooltips.clockFaceCircularity',
	'web.dcrReport.tooltips.componentPlacement',
	'web.dcrReport.tooltips.verticalSpatialPlacement',
	'web.dcrReport.tooltips.horizontalSpatialPlacement',
];

export const spatialReasoningStrings = {
	clockFaceCircularity: t('web.report.segmentUtils.clockFaceCircularity'),
	componentPlacement: t('web.report.segmentUtils.componentPlacement'),
	verticalSpatialPlacement: t(
		'web.report.segmentUtils.verticalSpatialPlacement'
	),
	horizontalSpatialPlacement: t(
		'web.report.segmentUtils.horizontalSpatialPlacement'
	),
};
