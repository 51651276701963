import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import { QueryKey } from 'api/query';
import { getUser, useAddRoleMutation } from 'api/user';
import { ERROR } from 'logging/linusLogger';

import { UserContext } from './UserContext';

export function useChangeDefaultOrganization() {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();
	const { mutateAsync: addUserRole, isPending, error } = useAddRoleMutation();

	const changeDefaultOrganization = async (organizationId: string) => {
		const user = await client.fetchQuery({
			queryKey: [QueryKey.User, organizationId, currentUser.id],
			queryFn: ({ signal }) =>
				getUser(organizationId, currentUser.id, signal),
			staleTime: Infinity,
		});

		const targetRole = user?.roles?.find(
			(roles) => roles.organizationId === organizationId
		);

		if (!targetRole) {
			return null;
		}

		try {
			await addUserRole({
				userId: currentUser.id,
				organizationRole: {
					roleId: targetRole.roleId,
					role: targetRole.roleName,
					organizationId: targetRole.organizationId,
					default: true,
				},
			});

			if (!error) {
				return { organizationId: targetRole.organizationId };
			}

			return null;
		} catch (err) {
			ERROR(
				`Error trying to add user role from useChangeDefaultOrganization, userId: ${currentUser.id}, organizationId: ${currentUser.organizationId}: `,
				err
			);
			return null;
		}
	};

	return {
		changeDefaultOrganization,
		updatingPrimaryOrganization: isPending,
	};
}
