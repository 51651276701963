import { HearingScreenerConcern } from './HearingScreenerConcern';

import { useContext } from 'react';

import { UserContext } from 'components/context';
import { getMetricItems } from './helpers';
import { Report } from '../CognitiveEvaluation.types';

import { HearingScreenerStatus } from './HearingScreenerStatus';
import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

type HearingScreenerProps = {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
};

const HearingScreener = ({
	batteryResult,
	segmentResult,
}: HearingScreenerProps) => {
	const { currentUser } = useContext(UserContext);

	const { organizationDefaultTimezone } = currentUser;

	const metricItems =
		batteryResult && getMetricItems({ batteryResult, segmentResult });

	if (!metricItems) {
		return null;
	}

	const { duration, finalVolume, endTime } = metricItems;

	return (
		<>
			{finalVolume && (
				<HearingScreenerConcern volume={finalVolume.value} />
			)}
			<HearingScreenerStatus
				endTime={endTime?.value}
				seconds={duration?.value}
				timezone={organizationDefaultTimezone}
			/>
		</>
	);
};

export { HearingScreener };
