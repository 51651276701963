import { Reason } from '@lh/eng-platform-organization-service-rest-client';

import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AnalyticsAction, sendEventData } from 'analytics';
import { UserContext } from 'components/context';
import { LinusInput } from 'components/shared/Forms/Components/LinusInput';
import { LinusTooltip } from 'components/shared/LinusTooltip';
import { Icon } from 'components/shared/designSystem/Icon';
import { searchReasonsByOrganizations } from 'components/shared/inputs/TypeAheadSearch/searchFunctions';
import {
	SearchFunctionProps,
	TDropdownElement,
} from 'components/shared/inputs/TypeAheadSearch/interface';
import { icons } from 'enums/icons';
import { ERROR } from 'logging/linusLogger';

import {
	Container,
	IconContainer,
	InputContainer,
	Subheader,
	Title,
	TitleContainer,
	TitleText,
	ToolTip,
	noMarginStyle,
} from './ReasonsForTesting.style';
import { handleSearchChange } from './ReasonsForTestint.utils';

export type ReasonsForTestingProps = {
	containerStyle?: React.CSSProperties;
	setReasons: React.Dispatch<React.SetStateAction<Reason[]>>;
};

export const ReasonsForTesting = ({
	containerStyle,
	setReasons,
}: ReasonsForTestingProps) => {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();
	const { t } = useTranslation();

	const lastSelections = useRef<TDropdownElement<Reason>[]>([]);
	const analyticsTimestamp = useRef<Date | null>(null);

	function applyHandleSearchChange(selections: TDropdownElement<Reason>[]) {
		handleSearchChange({
			selections,
			setReasons,
			lastSelections,
			analyticsTimestamp,
		});
	}

	const searchReasonsForTestingFunction = useCallback(
		async ({ search, pageSize, pageNumber }: SearchFunctionProps) => {
			if (search !== '' && pageNumber === 0) {
				analyticsTimestamp.current = new Date();

				sendEventData({
					eventType: AnalyticsAction.SearchedReasonStart,
					eventProperties: {
						first_timestamp:
							analyticsTimestamp.current.toISOString(),
					},
				});
			}
			try {
				return await searchReasonsByOrganizations(
					{
						search,
						pageSize,
						pageNumber,
						organizationId: currentUser.organizationId,
					},
					client
				);
			} catch (e) {
				ERROR('Error trying to fetch the organization service.', e);
				return [];
			}
		},
		[]
	);

	return (
		<Container style={containerStyle}>
			<TitleContainer>
				<Title>
					<TitleText>{t('web.reasonsForTesting.title')}</TitleText>
					<LinusTooltip
						overlay={
							<ToolTip>
								<Trans
									i18nKey={'web.reasonsForTesting.tooltip'}
								/>
							</ToolTip>
						}
						tooltipIcon={
							<IconContainer>
								<Icon
									icon={icons.InfoOutlined}
									iconWidth={18}
									iconHeight={18}
								/>
							</IconContainer>
						}
					/>
				</Title>
				<Subheader>{t('web.reasonsForTesting.helperTitle')}</Subheader>
			</TitleContainer>
			<InputContainer>
				<LinusInput
					styles={noMarginStyle}
					name='reasonsForTestingCode'
					type='typeAheadSearch'
					dropUpSpace='8px'
					label={t('web.reasonsForTesting.reasonsPlaceholder')}
					width='100%'
					height='fit-content'
					search={{
						searchFunction: searchReasonsForTestingFunction,
						onChange: applyHandleSearchChange,
					}}
				/>
				<LinusInput
					styles={noMarginStyle}
					width='100%'
					name='reasonsForTestingNote'
					label={t('web.reasonsForTesting.other')}
					type='input'
					placeholder={t('web.reasonsForTesting.notesPlaceholder')}
					data-id='reasons-for-testing-notes'
					value='reasons'
				/>
			</InputContainer>
		</Container>
	);
};
