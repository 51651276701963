import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { UserContext } from '../../context/UserContext';
import { Memoize } from 'helpers/Memoize';

type AuthenticatedRouteProps = {
	children: ReactNode;
};

export const AuthenticatedRoute = ({
	children,
}: AuthenticatedRouteProps): JSX.Element => {
	const { pathname } = useLocation();
	const { currentUser } = useContext(UserContext);
	return currentUser?.id ? (
		<Memoize>{children}</Memoize>
	) : (
		<Navigate to={`auth/login`} state={{ from: pathname }} replace />
	);
};
