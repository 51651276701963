import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ selected: boolean }>(
	({ selected, theme: { colors, spacing } }) => css`
		padding: 2px 8px 2px 8px;
		background-color: ${selected ? `${colors.gray_80}` : 'unset'};
		border: ${selected ? `1px solid ${colors.gray_60}` : 'unset'};
		border-radius: ${spacing.xs};
	`
);

export const Row = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
`;
