import styled from 'styled-components';

import { icons } from '../../../enums/icons';
import { Icon } from '../../shared/designSystem/Icon';

type SkipButtonsProps = {
	onSkipBack: () => void;
	onSkipFwd: () => void;
};
export const SkipButtons = ({
	onSkipBack,
	onSkipFwd,
}: SkipButtonsProps): JSX.Element => {
	return (
		<Container>
			<SkipButton data-testid='skip-back' onClick={onSkipBack}>
				<Icon icon={icons.Skip10Back} />
			</SkipButton>
			<SkipButton data-testid='skip-ahead' onClick={onSkipFwd}>
				<Icon icon={icons.Skip10Ahead} />
			</SkipButton>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 18px;
`;

const SkipButton = styled.button`
	background: none;
	border: none;
	color: inherit;
	outline: inherit;
	padding: 0;
	cursor: pointer;
	font: inherit;
`;
