import { useTranslation } from 'react-i18next';

export function useQuartileInterpretation(quartile: number | null) {
	const { t } = useTranslation();

	if (quartile === null) {
		return null;
	}

	const quartileText = t(`web.report.bdstReport.quartiles.${quartile}`);
	return t('web.report.bdstReport.performance.value', {
		value: quartileText,
	});
}
