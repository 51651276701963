import styled, { css } from 'styled-components';

import { P1, P2 } from 'components/shared/designSystem/TextComponents';

export const Container = styled.div`
	width: 770px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin: 0;
`;

export const TitleContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

export const Title = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xxs};
		align-items: center;
	`
);

export const TitleText = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const ToolTip = styled.div`
	width: 290px;
`;

export const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
`;
export const Subheader = styled(P2)(
	({ theme: { color, weight } }) => css`
		color: ${color.bodyTextSecondary};
		font-weight: ${weight.regular};
	`
);

export const InputContainer = styled.div(
	({ theme: { color, spacing } }) => css`
		background-color: ${color.body};
		padding: ${spacing.md};
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};

		input:nth-child(2)::placeholder {
			font-style: italic;
		}
	`
);

export const noMarginStyle = { margin: '0px' };
