import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { QuestionnaireLayout } from './QuestionnaireLayout';
import { QuestionnaireHeader } from './QuestionnaireHeader';
import { processAnswers } from '../../patientReport/actionPlan/ActionPlan/actionPlanLogic';
import { RefObject } from 'react';
import { SegmentType } from '../../../../generated/graphql';
import { QuestionsTable } from 'components/table/QuestionsTable';
import {
	Answer,
	answersAndQuestionToElements,
} from '@lh/eng-lhq-questions-common';
import { SegmentQuestionsPair } from '../types';

type QuestionnaireProps = {
	title: string;
	subHeader: string;
	segmentQuestions: SegmentQuestionsPair<string>;
	patientAnswers: Answer[];
	tooltipCopy: string;
	tooltipMeasures: string;
	elevatedRisk?: boolean;
	offsetComponentRef?: RefObject<HTMLDivElement>;
	segmentType?: SegmentType;
};

const QuestionnaireReport = ({
	title,
	subHeader,
	segmentQuestions,
	patientAnswers,
	tooltipCopy,
	tooltipMeasures,
	elevatedRisk,
	offsetComponentRef,
}: QuestionnaireProps): JSX.Element => {
	const { t } = useTranslation();

	const { segmentType, questions } = segmentQuestions;
	const categoriesWithRisks = processAnswers(patientAnswers, segmentType);

	return (
		<StyledWrapper>
			<QuestionnaireHeader
				title={title}
				subHeader={subHeader}
				tooltipCopy={tooltipCopy}
				tooltipMeasures={tooltipMeasures}
				categoriesWithRisks={categoriesWithRisks}
				elevatedRisk={elevatedRisk}
			/>
			<PrintOnlyBreak />
			{segmentType === SegmentType.Lhq32 ? (
				<QuestionnaireLayout
					questions={questions}
					patientAnswers={patientAnswers}
					offsetComponentRef={offsetComponentRef}
				/>
			) : (
				<QuestionsTable
					elements={answersAndQuestionToElements({
						questionsToUse: questions,
						userProvidedAnswers: patientAnswers,
					})}
					header={t(
						'web.report.lifeAndHealth.answers.individualAnswers'
					)}
				/>
			)}
		</StyledWrapper>
	);
};

export { QuestionnaireReport };

const StyledWrapper = styled.div`
	@media print {
		break-before: page;
	}
`;

const PrintOnlyBreak = styled.div`
	display: none;
	@media print {
		width: 100%;
		display: block;
		page-break-before: always;
	}
`;
