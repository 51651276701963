export const maxCharacterLimits = {
	firstname: 125,
	lastname: 125,
	notes: 2500,
	externalId: 250,
};

export function capitaliseText(text: string) {
	return text[0].toUpperCase() + text.slice(1);
}

export const truncateString = (str: string, num: number): string => {
	if (str.length > num) {
		return str.slice(0, num) + '...';
	}
	return str;
};

/**
 * @description This transforms the css styles from a React.CSSProperties
 * object to a CSS properties object for cases when the object element
 * cannot style a component.
 * @param object The CSS Properties object to use
 * @returns The css-styled element
 */
export function fromStyledToCSS(object: React.CSSProperties) {
	const regex = new RegExp(/[A-Z]/g);
	const elements = Object.keys(object);
	const kebabCase = (str: string) =>
		str.replace(regex, (v) => `-${v.toLowerCase()}`);
	return `${elements
		.map(
			(element) =>
				`${kebabCase(element)}:${
					object[element as keyof React.CSSProperties]
				}`
		)
		.join(';')}${elements.length ? ';' : ''}`;
}

export function reverseString(str: string) {
	return str.split('').reverse().join('');
}
