import { useTranslation } from 'react-i18next';

import { ReportInfoPanel } from 'components/mosaic';

import { Gad7LayoutContainer } from './Gad7Layout.style';
import { GadScore } from './GadScore';
import { IndividualAnswers } from './IndividualAnswers';
import { Gad7ResultData } from './IndividualAnswers/Table';

export type Gad7LayoutProps = {
	score: string;
	color: string;
	text: string;
	tableData: Gad7ResultData[];
};

export function Gad7Layout({
	color,
	tableData,
	score,
	text,
}: Readonly<Gad7LayoutProps>) {
	const { t } = useTranslation();

	return (
		<Gad7LayoutContainer>
			<ReportInfoPanel
				title={t('web.report.gad7.titleReport')}
				tooltipText={t('web.report.gad7.infoPanel.tooltip')}
			>
				<GadScore color={color} score={score} text={text} />
			</ReportInfoPanel>
			<IndividualAnswers resultsTableData={tableData} />
		</Gad7LayoutContainer>
	);
}
