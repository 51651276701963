import styled, { css } from 'styled-components';

import { Caption, P1, P2 } from 'components/shared/designSystem';

export const Container = styled.div(
	({ theme: { colors, spacing } }) => css`
		padding: 12px;
		background-color: ${colors.gray_90};
		border: 1px solid ${colors.gray_70};
		border-radius: ${spacing.xs};
	`
);

export const RemoteDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ContactInformationContainer = styled.div<{ isEdit: boolean }>(
	({ isEdit }) => css`
		display: flex;
		flex-direction: column;
		gap: ${isEdit ? 12 : 0};
	`
);

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;

export const Header = styled(P1)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const Row = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: ${spacing.lg};
	`
);

export const Subheader = styled(P2)(
	({ theme: { color, spacing, weight } }) => css`
		color: ${color.bodyTextSecondary};
		font-weight: ${weight.regular};
		margin-top: ${spacing.xs};
	`
);

export const EditButton = styled.a(
	({ theme: { colors, weight } }) => css`
		color: ${colors.blue};
		font-weight: ${weight.semi};
		display: flex;
		flex-direction: row;
		align-items: center;
	`
);

export const Info = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xxxl};
	`
);

export const ContactInformationCaption = styled(Caption)(
	({ theme: { colors, weight } }) =>
		css`
			color: ${colors.gray_30};
			font-weight: ${weight.medium};
		`
);

export const ContactInformationWrapper = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: ${spacing.xs};
	`
);
