import { Icon } from '../../../shared/designSystem/Icon';
import { theme } from '../../../providers/StyleProvider/theme';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { P1 } from '../../../shared/designSystem/TextComponents';
import { IconEnumType } from '../../../../types';

type SegmentEventProps = {
	icon: IconEnumType;
	textKey: string;
};

export const SegmentEvent = ({
	icon,
	textKey,
}: SegmentEventProps): JSX.Element => {
	return (
		<StyledWrapper>
			<StyledContainer>
				<StyledIcon>
					<Icon icon={icon} color={theme.color.bodyTextSecondary} />
				</StyledIcon>
				<StyledText>
					<Trans i18nKey={textKey} />
				</StyledText>
			</StyledContainer>
		</StyledWrapper>
	);
};

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => `
	display: inline-block;
	margin: 0 ${spacing.sm};
`
);

const StyledContainer = styled.div`
	display: flex;
	gap: 11px;
	align-items: center;
`;

const StyledIcon = styled.div`
	display: flex;
	flex-direction: row;
	align-self: flex-start;
`;

const StyledText = styled(P1)(
	({ theme: { color, weight } }) => `
    color: ${color.bodyTextSecondary};
    font-weight: ${weight.regular};
    `
);
