import { Reason } from '@lh/eng-platform-organization-service-rest-client';
import { sendEventData } from '../../../analytics/amplitude';
import { TDropdownElement } from '../../shared/inputs/TypeAheadSearch/interface';
import { AnalyticsAction } from '../../../generated/graphql';

export interface HandleSearchChangeProps {
	selections: TDropdownElement<Reason>[];
	setReasons: React.Dispatch<React.SetStateAction<Reason[]>>;
	lastSelections: React.MutableRefObject<TDropdownElement<Reason>[]>;
	analyticsTimestamp: React.MutableRefObject<Date | null>;
}

/**
 * @description Handles the search change.
 * @param param0 The search result.
 */
export function handleSearchChange({
	selections,
	setReasons,
	lastSelections,
	analyticsTimestamp,
}: HandleSearchChangeProps) {
	setReasons(selections.map((element) => element.dataElement));
	if (JSON.stringify(selections) !== JSON.stringify(lastSelections.current)) {
		if (selections.length > lastSelections.current.length) {
			if (analyticsTimestamp.current) {
				const now = new Date().getTime();
				const oldTimestamp = analyticsTimestamp.current.getTime();
				sendEventData({
					eventType: AnalyticsAction.SearchedReasonEnd,
					eventProperties: {
						duration: (now - oldTimestamp).toString(),
					},
				});
			}
		}
		lastSelections.current = [...selections];
	}
}
