import { useTranslation } from 'react-i18next';

import { TextLink } from '../../atoms/TextLink';

type ScoringDetailsCtaProps = {
	onClick: () => void;
};

export function ScoringDetailsCta({
	onClick,
}: Readonly<ScoringDetailsCtaProps>) {
	const { t } = useTranslation();

	return (
		<TextLink onClick={onClick}>
			{t('web.report.scoringDetails.cta')}
		</TextLink>
	);
}
