import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTheme } from 'styled-components';
import { EnumItem } from '../../../enums/enumeration';
import { Icon } from '../designSystem/Icon';
import { LinusTooltip } from '../LinusTooltip';

type MenuNodeProps = {
	text: string;
	menuIcon?: EnumItem;
	isBordered?: boolean;
	count?: number;
	path: string;
	isActive?: boolean;
	onClick: () => void;
	isCollapsed?: boolean;
	dataTestId?: string;
};
export const MenuNode = ({
	text,
	menuIcon,
	count,
	path,
	isActive,
	isBordered,
	onClick,
	isCollapsed,
	dataTestId,
}: MenuNodeProps): JSX.Element => {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<StyledListItem
			data-testid={dataTestId}
			$activeClassName={isActive}
			$isCollapsed={isCollapsed}
			$isActive={isActive}
			$isBordered={isBordered}
		>
			<StyledNavLink
				to={path}
				onClick={onClick}
				$state={{ $isCollapsed: isCollapsed }}
			>
				<StyledContentWrapper>
					{isCollapsed ? (
						<LinusTooltip
							overlay={<StyledTooltip>{text}</StyledTooltip>}
							overlayStyle={{
								...tooltipOverlayStyle,
								backgroundColor: theme.color.formText,
								color: theme.color.white,
							}}
							textAlign='center'
							tooltipIcon={
								<Icon
									icon={menuIcon}
									title={t`${text}`}
									color={
										!isActive
											? theme.color.iconMenuItem
											: theme.color.black
									}
								/>
							}
						/>
					) : (
						menuIcon && (
							<Icon
								icon={menuIcon}
								title={t`${text}`}
								color={
									!isActive
										? theme.color.iconMenuItem
										: theme.color.black
								}
							/>
						)
					)}
					<StyledSpan
						$activeClassName={isActive}
						id={text.replaceAll(' ', '-').toLowerCase()}
						$isCollapsed={isCollapsed}
					>
						{!isCollapsed && text}
					</StyledSpan>
				</StyledContentWrapper>
				<StyledCountWrapper
					data-id={text.replace(/\s/g, '-').toLowerCase() + 'Count'}
					$activeClassName={isActive}
				>
					{!isCollapsed && count}
				</StyledCountWrapper>
			</StyledNavLink>
		</StyledListItem>
	);
};

const tooltipOverlayStyle: React.CSSProperties = {
	position: 'fixed',
	zIndex: 1000,
	borderRadius: '5px',
	padding: '15px 5px',
};

const StyledListItem = styled.li<TextProps>(
	({ $isCollapsed, $isBordered, $activeClassName, theme: { color } }) => css`
		width: ${$isCollapsed ? '100%' : '196px'};
		height: ${$isBordered && !$isCollapsed ? '64px' : '48px'};
		border-top: ${$isBordered && !$isCollapsed
			? `1px solid ${color.menuActiveBg}`
			: ''};
		border-bottom: ${$isBordered && !$isCollapsed
			? `1px solid ${color.menuActiveBg}`
			: ''};
		border-radius: ${$isBordered || $isCollapsed
			? '0'
			: '40px 0 40px 40px'};
		margin: 16px 0;
		color: ${$activeClassName ? color.menuActiveText : color.menuText};
		background: ${$activeClassName ? color.menuActiveBg : 'none'};

		&:hover {
			color: ${color.menuHoverText};
			cursor: pointer;
		}
	`
);
const StyledNavLink = styled(NavLink)<TextProps>(
	({ $state, theme: { color } }) => css`
		display: flex;
		align-items: center;
		justify-content: ${$state?.$isCollapsed ? 'center' : 'space-between'};
		color: ${color.menuText};
		width: 100%;
		height: 100%;
		padding: ${$state?.$isCollapsed ? '0' : '8px 16px'};
		box-sizing: border-box;
		transition: 0.2s ease all;
	`
);

const StyledContentWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

type TextProps = {
	$activeClassName?: boolean;
	$isCollapsed?: boolean;
	$isActive?: boolean;
	$isBordered?: boolean;
	$state?: {
		$isCollapsed?: boolean;
	};
};
const StyledCountWrapper = styled.span<TextProps>(
	({ $activeClassName, theme: { color } }) => css`
		color: ${$activeClassName ? color.menuActiveText : color.menuText};
		transition: 0.2s ease all;
	`
);
const StyledSpan = styled.span<TextProps>(
	({ $isCollapsed, $activeClassName, theme: { color, weight } }) => css`
		color: ${$activeClassName ? color.menuActiveText : color.menuText};
		font-weight: ${$activeClassName ? weight.medium : weight.regular};
		padding-left: ${$isCollapsed ? '0' : '8px'};
		transition: 0.2s ease all;

		&:hover {
			color: ${$activeClassName
				? color.menuActiveText
				: color.menuHoverText};
		}
	`
);

const StyledTooltip = styled.div(
	({ theme: { fontSize } }) => css`
		font-size: ${fontSize._16};
	`
);
