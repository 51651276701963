import { Trans, useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import {
	LOCATION_BATTERY_TYPE,
	LocationBatteryType,
} from 'components/patients/patient.helpers';
import { theme } from 'components/providers/StyleProvider/theme';
import { LinusModalDialog } from 'components/shared/LinusModalDialog';
import { icons } from 'enums/icons';
import { useModalStore } from 'store';

export type BatteryConfirmationPayload = {
	type?: LocationBatteryType;
	firstName?: string;
	lastName?: string;
};

type BatteryConfirmationProps = {
	onClose: () => void;
};

export function BatteryConfirmation({
	onClose,
}: Readonly<BatteryConfirmationProps>) {
	const { t } = useTranslation();

	const payload = useModalStore(
		(state) => state.payload.BatteryAssignmentConfirmation
	);

	return (
		<LinusModalDialog
			dataId='battery-assigned-title'
			onClose={onClose}
			title={t`web.patients.forms.batteryAssigned`}
			titleIcon={icons.CheckmarkSolid}
			titleIconColor={theme.color.alertSuccess}
			acceptButtonText={t`web.shared.close`}
			acceptButtonCallback={onClose}
		>
			<StyledSuccessMessage data-id='battery-assigned-success-message'>
				<Trans
					i18nKey={
						payload?.type === LOCATION_BATTERY_TYPE.REMOTE
							? 'web.patients.forms.batteryAssignedLongRemote'
							: 'web.patients.forms.batteryAssignedLong'
					}
					values={{
						name: ` ${payload?.firstName} ${payload?.lastName} `,
					}}
					components={[<strong />]}
				/>
			</StyledSuccessMessage>
		</LinusModalDialog>
	);
}

const StyledSuccessMessage = styled.div`
	width: 548px;
`;
