import {
	GetUsersFilterField,
	GetUsersFilterOperator,
	GetUsersLogicalOperator,
	GetUsersSortField,
	GetUsersSortOrder,
} from '@lh/eng-platform-organization-service-rest-client';
import { SortProps } from './Team';

export const getUsersFilterOptions = (
	searchValue?: string | null | undefined
) => {
	if (searchValue && searchValue?.length > 0) {
		return {
			filterField: [
				GetUsersFilterField.FirstName,
				GetUsersFilterField.LastName,
			],
			filterOperator: [
				GetUsersFilterOperator.Ilike,
				GetUsersFilterOperator.Ilike,
			],
			filterValue: [searchValue, searchValue],
			logicalOperator: [GetUsersLogicalOperator.Or],
		};
	}
	return {
		filterField: undefined,
		filterOperator: undefined,
		filterValue: undefined,
		logicalOperator: undefined,
	};
};

export const getUsersSortOptions = (sortProps: SortProps | null) => {
	if (!sortProps?.prop || !sortProps?.dir) {
		return {
			sortField: undefined,
			sortOrder: undefined,
		};
	}
	const sortDir = [];
	if (sortProps?.dir === 'ASC') {
		sortDir.push(GetUsersSortOrder.Asc);
	} else if (sortProps?.dir === 'DESC') {
		sortDir.push(GetUsersSortOrder.Desc);
	}
	switch (sortProps?.prop) {
		case GetUsersSortField.Email:
			return {
				sortField: [GetUsersSortField.Email],
				...(sortDir && { sortOrder: sortDir }),
			};
		case GetUsersSortField.LastName:
			return {
				sortField: [GetUsersSortField.LastName],
				...(sortDir && { sortOrder: sortDir }),
			};
		case GetUsersSortField.Status:
			return {
				sortField: [GetUsersSortField.Status],
				...(sortDir && { sortOrder: sortDir }),
			};

		default:
			return {
				sortField: undefined,
				sortOrder: undefined,
			};
	}
};
