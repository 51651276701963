import { Gender } from '../../generated/graphql';
import { generateTranslationEnum } from '../../utils/enumTranslationUtils';

type PatientGender = Omit<
	typeof Gender,
	'NonDisclosed' | 'TransgenderMale' | 'TransgenderFemale'
>;

const patientGender: PatientGender = {
	Female: Gender.Female,
	Male: Gender.Male,
	NonBinary: Gender.NonBinary,
	Other: Gender.Other,
	ToBeCollectedAtTimeOfTesting: Gender.ToBeCollectedAtTimeOfTesting,
};

export const gender = generateTranslationEnum<PatientGender>(
	patientGender,
	'web.enums.gender'
);

gender.Male.index = 1;
gender.Female.index = 2;
gender.NonBinary.index = 3;
gender.Other.index = 4;
gender.ToBeCollectedAtTimeOfTesting.index = 5;
