export const MOCKED_ARTICLES = {
	'CDS-AmnesticConcern':
		'/cdsArticles/CDS-AmnesticConcern/CDS-AmnesticConcern.html',
	'CDS-DCRGreenHighRisk':
		'/cdsArticles/CDS-DCRGreenHighRisk/CDS-DCRGreenHighRisk.html',
	'CDS-DCRGreenLowRisk':
		'/cdsArticles/CDS-DCRGreenLowRisk/CDS-DCRGreenLowRisk.html',
	'CDS-DCRRedHighRisk':
		'/cdsArticles/CDS-DCRRedHighRisk/CDS-DCRRedHighRisk.html',
	'CDS-DCRRedLowRisk':
		'/cdsArticles/CDS-DCRRedLowRisk/CDS-DCRRedLowRisk.html',
	'CDS-DCRYellowHighRisk':
		'/cdsArticles/CDS-DCRYellowHighRisk/CDS-DCRYellowHighRisk.html',
	'CDS-DCRYellowLowRisk':
		'/cdsArticles/CDS-DCRYellowLowRisk/CDS-DCRYellowLowRisk.html',
	'CDS-HearingConcern':
		'/cdsArticles/CDS-HearingConcern/CDS-HearingConcern.html',
	'CDS-MixedDomainConcern':
		'/cdsArticles/CDS-MixedDomainConcern/CDS-MixedDomainConcern.html',
	'CDS-MoodConcern': '/cdsArticles/CDS-MoodConcern/CDS-MoodConcern.html',
	'CDS-MotorDysfunction-EssentialTremor':
		'/cdsArticles/CDS-MotorDysfunction-EssentialTremor/CDS-MotorDysfunction-EssentialTremor.html',
	'CDS-MotorDysfunction-General':
		'/cdsArticles/CDS-MotorDysfunction-General/CDS-MotorDysfunction-General.html',
	'CDS-NonAmnesticConcern':
		'/cdsArticles/CDS-NonAmnesticConcern/CDS-NonAmnesticConcern.html',
	'CDS-SleepConcern': '/cdsArticles/CDS-SleepConcern/CDS-SleepConcern.html',
	'CDS-VascularConcern':
		'/cdsArticles/CDS-VascularConcern/CDS-VascularConcern.html',
	'CDS-VisionConcern':
		'/cdsArticles/CDS-VisionConcern/CDS-VisionConcern.html',
};
