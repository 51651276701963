import { astNodeToElements, FontWeight } from './astNodeToElements';
import { parseRichText } from './parseRichText';

type RichTextProps = {
	children: string;
	fontWeight?: FontWeight;
};

export function RichText({ children, fontWeight }: Readonly<RichTextProps>) {
	const ast = parseRichText(children);
	return <span>{astNodeToElements(ast, { fontWeight })}</span>;
}
