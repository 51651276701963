import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useOrganizationStore } from 'store';
import { icons } from '../../enums/icons';
import { OrganizationStatus } from '../../generated/graphql';
import { UserContext } from '../context/UserContext';

import { Icon } from './designSystem/Icon';
import { H1 } from './designSystem/TextComponents';

type HeaderProps = { display?: string };
const Header = ({ display }: HeaderProps): JSX.Element => {
	const { t } = useTranslation();
	const { currentUser } = useContext(UserContext);

	const status = useOrganizationStore((state) => state.status);

	const isTest = status === OrganizationStatus.Test;

	return (
		<StyledHeaderContainer data-testid='patient-v1-details-header'>
			<StyledOrgTitleContainer>
				<H1 data-id='organization-name' data-testid='organization-name'>
					{display || currentUser.organizationName}
				</H1>
				{isTest && (
					<StyledTestLabel>
						<Icon
							dataId='test-organization-icon'
							icon={icons.TestGears}
							title={t`web.shared.header.test`}
						/>
						{t`web.shared.header.test`.toUpperCase()}
					</StyledTestLabel>
				)}
			</StyledOrgTitleContainer>
		</StyledHeaderContainer>
	);
};

export { Header };

const StyledHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	padding-top: 16px;
`;

const StyledOrgTitleContainer = styled.div(
	({ theme: { spacing } }) => `
	margin: ${spacing.md} 0 ${spacing.xl} 0;
	display: flex;	
	align-items: center;
	`
);

const StyledTestLabel = styled.div(
	({ theme: { color, fontSize, spacing, weight, borderRadius } }) => `
	display: flex;
	justify-content: space-around;
	border-radius: ${borderRadius.md};
	padding-right: 13px;
	padding-left: 13px;
	margin: 0 ${spacing.md};
	background-color: ${color.headerTestLabelBG};
	color: ${color.headerTestLabelFont};
	font-size: ${fontSize._12};
	font-weight: ${weight.medium};
	gap: 6px;
	`
);
