import { H4 } from 'components/shared/designSystem';
import { css, styled } from 'styled-components';

export const Container = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

export const Title = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);
