import { Typography } from '@lh/eng-web-mosaic-common';

import styled, { css } from 'styled-components';

import { theme } from 'components/providers/StyleProvider/theme';
import { StyledCard } from 'components/shared/StyledCard';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';

interface InfoTimeCardProps {
	title: string;
	subtitle: string;
	info: string;
	footer: string;
}

export function InfoTimeCard({
	footer,
	info,
	subtitle,
	title,
}: Readonly<InfoTimeCardProps>) {
	return (
		<StyledCard>
			<InfoTimeCardContainer>
				<Header>
					<Typography.H4 weight='500'>{title}</Typography.H4>
					<SubtitleContainer>
						<Icon icon={icons.Clock} color={theme.colors.blue} />
						<Typography.P2
							weight='500'
							color={theme.color.bodyTextSecondary}
						>
							{subtitle}
						</Typography.P2>
					</SubtitleContainer>
				</Header>
				<Info>
					<Typography.P1>{info}</Typography.P1>
					<Typography.P2 color={theme.color.bodyTextSecondary}>
						{footer}
					</Typography.P2>
				</Info>
			</InfoTimeCardContainer>
		</StyledCard>
	);
}

const InfoTimeCardContainer = styled.div(
	({ theme: { spacing } }) => css`
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
	`
);

const Header = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);

const SubtitleContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		align-items: center;
		gap: ${spacing.xs};
	`
);

const Info = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.sm};
	`
);
