import styled, { css } from 'styled-components';

const Dot = styled.div`
	border-radius: 50%;
	height: 12px;
	width: 12px;
`;

export const BadDot = styled(Dot)(
	({ theme }) => css`
		background: ${theme.colors.orange_light};
	`
);

export const GoodDot = styled(Dot)(
	({ theme }) => css`
		background: ${theme.colors.green_light};
	`
);
