import {
	LHQ32_QUESTIONS as questions32,
	LHQ15_QUESTIONS as questions15,
} from '@lh/eng-lhq-questions-common';
import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
	FormattedSegmentResults,
	getAssessmentData,
} from '../../cognitiveEvalParseData/cognitiveEvalParseData';
import { Report } from '../../../../components/report/CognitiveEvaluation.types';
import { getLHQMetrics } from '../../../../components/report/CognitiveEvaluation.helpers';
import { Auth } from 'features/auth-service';
import { SegmentType } from '../../../../generated/graphql';
import { QuestionnaireReport } from '../questionnaire/QuestionnaireReport';
import { useGetQuestionnaireAnswers } from '../../../shared/hooks/useGetQuestionnaireAnswers';
import { LoadingDots } from '../../../shared/LoadingDots';
import { SegmentQuestionsPair } from '../types';

type LifeAndHealthReportProps = {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
	offsetComponentRef?: RefObject<HTMLDivElement>;
	isLoading?: boolean;
	setIsLoading?: (isLoading: boolean) => void;
};

const LifeAndHealthReport = ({
	batteryResult,
	segmentResult,
	offsetComponentRef,
	isLoading,
	setIsLoading,
}: LifeAndHealthReportProps): JSX.Element | null => {
	const { t } = useTranslation();

	const { lifeAndHealthAssessment: lhqData } = getAssessmentData({
		batteryResult,
		segmentResult,
	});

	const [answers, setAnswers] = useState({ results: [] });
	const [lhqSegment, setLhqSegment] =
		useState<FormattedSegmentResults | null>(null);

	const { elevatedRisk } = getLHQMetrics(lhqData);

	useEffect(() => {
		const foundLHQSegment = lhqData?.segmentResults.find(
			(segment) =>
				segment.segmentType === SegmentType.Lhq32 ||
				segment.segmentType === SegmentType.Lhq15
		);
		setLhqSegment(foundLHQSegment || null);
	}, [batteryResult]);

	const segmentId = lhqSegment ? lhqSegment.id : '';
	const segmentType: SegmentType | undefined = lhqSegment?.segmentType as
		| SegmentType
		| undefined;
	useGetQuestionnaireAnswers({
		segmentId: segmentId,
		setAnswers,
		setIsLoading: setIsLoading,
		auth: Auth,
		segmentType,
	});

	if (isLoading)
		return (
			<StyledLoadingContainer>
				<LoadingDots />
			</StyledLoadingContainer>
		);

	if (!answers.results.length) {
		return null;
	}

	if (!lhqSegment) {
		return null;
	}

	let segmentQuestions: SegmentQuestionsPair<string>;
	if (segmentType === SegmentType.Lhq15) {
		segmentQuestions = { segmentType, questions: questions15 };
	} else {
		segmentQuestions = {
			segmentType: SegmentType.Lhq32,
			questions: questions32,
		};
	}

	return (
		<QuestionnaireReport
			title={t`web.report.lifeAndHealth.title`}
			subHeader={t`web.report.lifeAndHealth.subHeader`}
			segmentQuestions={segmentQuestions}
			patientAnswers={answers.results}
			tooltipCopy={t`web.report.lifeAndHealth.tooltip.copy`}
			tooltipMeasures={t`web.report.lifeAndHealth.tooltip.measures`}
			elevatedRisk={elevatedRisk}
			offsetComponentRef={offsetComponentRef}
		/>
	);
};

export { LifeAndHealthReport };

const StyledLoadingContainer = styled.div`
	height: 40vh;
`;
