import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { icons } from '../../../../enums/icons';
import { theme } from '../../../providers/StyleProvider/theme';
import { Icon } from '../../../shared/designSystem/Icon';
import { P1 } from '../../../shared/designSystem/TextComponents';

type ConcernsProps = {
	concerns: string[];
};

export const Concerns = ({ concerns }: ConcernsProps): JSX.Element => {
	const { t } = useTranslation();
	const theme = useTheme();
	return (
		<StyledConcerns>
			<StyledConcernsIcon>
				<Icon
					icon={icons.AlertSolid}
					title={t`web.report.concerns.icon.title`}
					color={theme.color.graphOrange}
				/>
			</StyledConcernsIcon>
			<P1>
				<StyledConcernsTitle>{t`web.report.concerns.content.title`}</StyledConcernsTitle>
				{concerns.map((concern, idx) => {
					return (
						<StyledConcernsList key={concern}>
							{idx === concerns.length - 1
								? `${concern} `
								: `${concern}, `}
						</StyledConcernsList>
					);
				})}
			</P1>
		</StyledConcerns>
	);
};

const StyledConcerns = styled.span`
	display: flex;
	align-items: center;
	margin-left: ${theme.spacing.xs};
`;

const StyledConcernsIcon = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 1px;
	margin-right: 10px;
	align-self: flex-start;
`;

const StyledConcernsTitle = styled.span(
	({ theme: { color } }) => `
		color: ${color.clockConcernsTitle};
		margin-right: 5px;
	`
);

const StyledConcernsList = styled.span(
	({ theme: { color } }) => `
		color: ${color.graphTextSecondary};
		white-space: pre-wrap;
	`
);
