import styled, { css } from 'styled-components';

import { StyledCard } from 'components/shared/StyledCard';
import { H2, H4 } from 'components/shared/designSystem';

export const OrientationLayoutWrapper = styled.div(
	({ theme: { spacing } }) => css`
		margin-bottom: ${spacing.lg};
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
	`
);

export const SectionCard = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.md};
		max-width: 100%;
		padding: ${spacing.lg};
	`
);

export const QuestionsTableWrapper = styled(StyledCard)(
	({ theme: { spacing } }) => css`
		max-width: 100%;
		padding: ${spacing.lg};
	`
);

export const Header = styled(H4)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
	`
);

export const Divider = styled.div<{ style?: React.CSSProperties }>(
	({ theme: { colors } }) => css`
		background-color: ${colors.gray_60};
		height: 1px;
	`
);

export const ScoreContainer = styled.div(
	({ theme: { colors, borderRadius } }) => css`
		align-items: center;
		background-color: ${colors.gray_90};
		border-radius: ${borderRadius.searchBox};
		display: flex;
		flex-direction: row;
		max-width: 380px;
		padding: 16px;
	`
);

export const ScoreDescription = styled(H4)`
	margin-left: 20px;
`;

export const TotalScoreContainer = styled.div(
	({ theme: { borderRadius } }) => css`
		border-radius: ${borderRadius.searchBox};
		display: flex;
		width: 64px;
	`
);

export const Score = styled(H2)(
	({ theme: { weight, colors, borderRadius } }) => css`
		align-items: center;
		background-color: ${colors.gray_70};
		border-radius: ${borderRadius.searchBox};
		display: flex;
		font-weight: ${weight.medium};
		min-height: 50px;
		padding-left: 12px;
		padding-right: 12px;
	`
);

export const SelectionsContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		gap: ${spacing.xs};
		align-items: center;
	`
);

export const IconContainer = styled.div<{ size: string }>(
	({ size }) => css`
		width: ${size};
		height: ${size};
		display: flex;
		align-items: center;
		justify-content: center;
	`
);
