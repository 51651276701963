import { Flex } from '@mantine/core';

import { theme } from 'components/providers/StyleProvider/theme';

type ReportCardHeaderContainerProps = {
	children: React.ReactNode;
};

export function ReportCardHeaderContainer({
	children,
}: Readonly<ReportCardHeaderContainerProps>) {
	return (
		<Flex
			align='center'
			data-testid='report-card-info'
			gap={theme.spacing.xs}
		>
			{children}
		</Flex>
	);
}
