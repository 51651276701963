import { MetricItem } from '@lh/eng-platform-battery-service-rest-client';

import i18n from 'i18next';

import { capitaliseText } from 'utils/stringUtils';

import {
	FluencyAudioRecording,
	FluencyScoreBreakdown,
	FluencyPerformance,
} from '../FluencyLayout.types';

enum PhonemicFluencyKeys {
	AgeRange = 'phonemic_fluency_age_range',
	Category = 'phonemic_category',
	Cutoff = 'phonemic_fluency_impairment_cutoff',
	EducationAdjustment = 'phonemic_fluency_impairment_threshold_adjusted_by_education',
	Epoch1 = 'phonemic_fluency_epoch1_score',
	Epoch2 = 'phonemic_fluency_epoch2_score',
	Epoch3 = 'phonemic_fluency_epoch3_score',
	Epoch4 = 'phonemic_fluency_epoch4_score',
	Impairment = 'phonemic_fluency_impairment_classification',
	LanguageCode = 'language_code',
	Percentile = 'phonemic_fluency_percentile',
	RecordingDuration = 'recording_duration',
	TotalScore = 'phonemic_fluency_total_score',
}

export function parsePhonemicFluencyMetrics(metricItems: MetricItem[]) {
	const breakdown: FluencyScoreBreakdown = {
		epoch1: '--',
		epoch2: '--',
		epoch3: '--',
		epoch4: '--',
	};
	let isEnglish = true;
	const performance: FluencyPerformance = {
		impaired: -1,
		percentile: null,
		score: {
			adjusted: false,
			value: null,
		},
	};
	const recording: FluencyAudioRecording = {
		duration: 0,
		prompt: '',
		url: '',
	};

	metricItems.forEach((metricItem) => {
		if (metricItem.key === PhonemicFluencyKeys.Epoch1) {
			breakdown.epoch1 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key === PhonemicFluencyKeys.Epoch2) {
			breakdown.epoch2 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key === PhonemicFluencyKeys.Epoch3) {
			breakdown.epoch3 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key === PhonemicFluencyKeys.Epoch4) {
			breakdown.epoch4 = metricItem.value
				? metricItem.value.toString()
				: '--';
		}

		if (metricItem.key === PhonemicFluencyKeys.Category) {
			const prompt = i18n.t(
				'web.report.phonemicFluencyReport.audioPrompt',
				{
					initialLetter: metricItem.value.toUpperCase(),
				}
			);
			recording.prompt = capitaliseText(prompt);
		}

		if (metricItem.key === PhonemicFluencyKeys.RecordingDuration) {
			recording.duration = Number(metricItem.value);
		}

		if (metricItem.key === PhonemicFluencyKeys.TotalScore) {
			performance.score.value = isNaN(Number(metricItem.value))
				? null
				: metricItem.value;
		}

		if (metricItem.key === PhonemicFluencyKeys.Percentile) {
			performance.percentile = isNaN(Number(metricItem.value))
				? null
				: Number(metricItem.value);
		}

		if (metricItem.key === PhonemicFluencyKeys.LanguageCode) {
			isEnglish =
				metricItem.value === 'en_US' || metricItem.value === 'en_GB';
		}

		if (metricItem.key === PhonemicFluencyKeys.AgeRange) {
			if (!performance.cutoff) {
				performance.cutoff = {};
			}

			performance.cutoff.ageRange = metricItem.value;
		}

		if (metricItem.key === PhonemicFluencyKeys.Cutoff) {
			if (!performance.cutoff) {
				performance.cutoff = {};
			}

			performance.cutoff.value = metricItem.value;
		}

		if (metricItem.key === PhonemicFluencyKeys.EducationAdjustment) {
			performance.score.adjusted = metricItem.value === 'true';
		}

		if (metricItem.key === PhonemicFluencyKeys.Impairment) {
			if (metricItem.value === 'IMPAIRED') {
				performance.impaired = 1;
			} else {
				performance.impaired = 0;
			}
		}
	});

	return {
		breakdown,
		isEnglish,
		performance,
		recording,
	};
}
