import { useTranslation } from 'react-i18next';

import { Chip } from 'components/mosaic';
import { Caption } from 'components/shared/designSystem';

import { OrderWrapper } from './BdstOrderField.style';

type BdstOrderFieldProps = {
	value?: string;
};

export function BdstOrderField({ value }: Readonly<BdstOrderFieldProps>) {
	const { t } = useTranslation();

	const info = !value
		? t('web.report.bdstReport.canNotCalculate')
		: t('web.report.bdstReport.numbers');

	return (
		<OrderWrapper>
			<Chip label={value ?? '--'} />
			<Caption>{info}</Caption>
		</OrderWrapper>
	);
}
