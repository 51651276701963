import { ListNode } from '../Pathway.list';

export interface ReversibleCause {
	id: ReversibleCauseID;
	title: string | string[];
	nextSteps: string | ListNode;
}

export enum ReversibleCauseID {
	Drugs = 'Drugs',
	Emotional = 'Emotional',
	Metabolic = 'Metabolic',
	EyesAndEarImpairment = 'EyesAndEarImpairment',
	NeoplasmAndSpaceOccupyingLesions = 'NeoplasmAndSpaceOccupyingLesions',
	TBITestosteroneDeficiency = 'TBITestosteroneDeficiency',
	Infection = 'Infection',
	AtrialFibrillation = 'AtrialFibrillation',
	SubsyndromalDelirium = 'SubsyndromalDelirium',
}
