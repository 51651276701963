import {
	FeatureType,
	OptionalFields,
} from '@lh/eng-platform-organization-service-rest-client';
import { Patient as RestPatient } from '@lh/eng-platform-subject-service-rest-client';

import i18n from 'i18n';
import { CurrentUser } from 'types';
import { formatPhoneNumber } from 'utils/phone';

import { TEXT } from './PatientProfileWidget.i18n';

export interface Patient {
	id: string;
	externalId: string;
	firstName: string;
	lastName: string;
	dateOfBirth: string;
	gender: string;
	race?: string;
	ethnicity?: string;
	education: string;
	handedness: string;
	language: string;
	sexAssignedAtBirth: string;
	contactEmail: string;
	contactPhone: string;
}
export interface Props {
	patient: RestPatient;
	onEdit(): void;
}

export interface Details {
	label: string;
	value: string;
	isRequired?: boolean;
	fieldName?: OptionalFields;
}

export function getDetails(patient: Patient, user: CurrentUser) {
	const details: Details[] = [
		{
			label: i18n.t(TEXT.fields.dateOfBirth),
			value: patient.dateOfBirth,
			isRequired: true,
		},
		{
			label: i18n.t(TEXT.fields.language),
			value: patient.language,
			isRequired: true,
		},
		{
			label: i18n.t(TEXT.fields.education),
			value: patient.education,
			isRequired: true,
		},
		{
			label: i18n.t(TEXT.fields.handedness),
			value: patient.handedness,
			isRequired: true,
		},
		{
			label: i18n.t(TEXT.fields.sex),
			value: patient.sexAssignedAtBirth,
			isRequired: true,
		},
		{
			label: i18n.t(TEXT.fields.gender),
			value: patient.gender,
			isRequired: false,
			fieldName: OptionalFields.Gender,
		},
		{
			label: i18n.t(TEXT.fields.race),
			value: patient.race ?? '',
			isRequired: false,
			fieldName: OptionalFields.Race,
		},
		{
			label: i18n.t(TEXT.fields.ethnicity),
			value: patient.ethnicity ?? '',
			isRequired: false,
			fieldName: OptionalFields.Ethnicity,
		},
		{
			label: i18n.t(TEXT.fields.id),
			value: patient.externalId ?? '',
			isRequired: false,
			fieldName: OptionalFields.ExternalId,
		},
		{
			label: i18n.t('web.patients.forms.contactEmailLabel'),
			value: patient.contactEmail,
			isRequired: user.organizationFeatures.includes(
				FeatureType.RemoteAssessment
			),
		},
		{
			label: i18n.t('web.patients.forms.contactPhoneLabel'),
			value: formatPhoneNumber(patient.contactPhone),
			isRequired: user.organizationFeatures.includes(
				FeatureType.RemoteAssessment
			),
		},
	];

	return details;
}
