import styled, { css } from 'styled-components';

type LineDividerProps = {
	/**
	 * Determines the orientation of the line divider.
	 * - 'horizontal': Displays the line horizontally.
	 * - 'vertical': Displays the line vertically.
	 * @default 'horizontal'
	 */
	orientation?: 'vertical' | 'horizontal';
};
export function LineDivider({ orientation = 'horizontal' }: LineDividerProps) {
	return orientation === 'horizontal' ? <HorizontalLine /> : <VerticalLine />;
}

const HorizontalLine = styled.div(
	({ theme: { color } }) => css`
		height: 1px;
		width: 100%;
		background-color: ${color.infoSeparator};
	`
);

const VerticalLine = styled.div(
	({ theme: { color } }) => css`
		height: 100%;
		width: 1px;
		background-color: ${color.infoSeparator};
	`
);
