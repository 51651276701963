import { Role } from '@lh/eng-platform-organization-service-rest-client';
import { DEPRECATED_ROLES } from 'components/shared/constants';

export const selectableRoles = (
	organizationRoles: Role[] | undefined,
	currentUserRole?: string | undefined
) => {
	if (!organizationRoles) return [];

	const deprecatedRolesMinusCurrentRole = currentUserRole
		? DEPRECATED_ROLES.filter((dr) => {
				return dr !== currentUserRole;
		  })
		: DEPRECATED_ROLES;

	return organizationRoles?.filter(
		(role) => !deprecatedRolesMinusCurrentRole.includes(role.name)
	);
};
