import { create } from 'zustand';

import { BatteryConfirmationPayload as BatteryConfirmationModalPayload } from 'components/modals/BatteryConfirmation';
import { CarePartnerConfirmationModalPayload } from 'components/patients/CarePartner/ConfirmationModal';
import { CarePartnerModalPayload } from 'components/patients/CarePartner/ControllModal';

const MODAL_IDS = [
	'BatteryAssignmentConfirmation',
	'AddCarePartner',
	'AddCarePartnerConfirmation',
	'EditCarePartner',
	'EditCarePartnerConfirmation',
] as const;

export type ModalId = (typeof MODAL_IDS)[number];

export type CareParterModalIds = Extract<
	ModalId,
	'AddCarePartner' | 'EditCarePartner'
>;

export type CareParterConfirmationModalIds = Extract<
	ModalId,
	'AddCarePartnerConfirmation' | 'EditCarePartnerConfirmation'
>;

type BasePayload<I extends ModalId, P = never> = {
	[id in I]: P | undefined;
};

type PayloadIds = Extract<
	ModalId,
	| 'BatteryAssignmentConfirmation'
	| 'AddCarePartner'
	| 'AddCarePartnerConfirmation'
	| 'EditCarePartner'
	| 'EditCarePartnerConfirmation'
>;

type NoPayload = BasePayload<Exclude<ModalId, PayloadIds>>;

type BatteryAssignmentConfirmationPayload = BasePayload<
	'BatteryAssignmentConfirmation',
	BatteryConfirmationModalPayload
>;

type CarePartnerPayload = BasePayload<
	CareParterModalIds,
	CarePartnerModalPayload
>;
type CarePartnerConfirmationPayload = BasePayload<
	CareParterConfirmationModalIds,
	CarePartnerConfirmationModalPayload
>;

type PayloadMap = NoPayload &
	BatteryAssignmentConfirmationPayload &
	CarePartnerPayload &
	CarePartnerConfirmationPayload;

type VisibleMap = Record<ModalId, boolean>;

type State = {
	payload: Partial<PayloadMap>;
	visible: Partial<VisibleMap>;
	open: (id: ModalId, payload?: PayloadMap[ModalId]) => void;
	close: (id: ModalId) => void;
	reset: () => void;
};

export const useModalStore = create<State>((set) => ({
	payload: {},
	visible: {},
	open: (id, payload) => {
		set((state) => ({
			payload: { ...state.payload, [id]: payload },
			visible: { ...state.visible, [id]: true },
		}));
	},
	close: (id) => {
		set((state) => ({
			payload: { ...state.payload, [id]: undefined },
			visible: { ...state.visible, [id]: false },
		}));
	},
	reset: () =>
		set({
			payload: {},
			visible: {},
		}),
}));
