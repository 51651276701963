import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { useQuestionnaireQuery } from 'api/questionnaire';
import { Report } from 'components/report/CognitiveEvaluation.types';
import {
	getQuestionnaireScore,
	parseQuestionnaireData,
	QnrRowData,
} from 'components/report/CustomQuestionnaire.utils';
import { Questionnaire } from 'enums/questionnaire';
import { LoadingLayout, RCRLayout } from '../../../../shared/ReportLoader';
import { IadlTableCard } from '../IadlTable';
import { SummaryCard } from '../SummaryCard';

import { IadlLayoutContainer } from './IadlLayout.style';
import { parseQuestions } from './iadlLayout.utils';

interface IadlLayoutProps {
	type?: 'iadl' | 'adl';
	iadlQuestionnaireData?: Report;
	segmentResult?: DeepSegmentResult;
	mockedReportData?: IadlParsedData;
}

type IadlParsedData = {
	score: number;
	tableData: QnrRowData[];
};

export function IadlLayout({
	type = 'iadl',
	iadlQuestionnaireData,
	segmentResult,
	mockedReportData,
}: Readonly<IadlLayoutProps>) {
	const { segmentResultId } = useMemo(() => {
		const { segmentResultId } = parseQuestionnaireData(
			type === 'iadl' ? Questionnaire.IADL : Questionnaire.ADL,
			iadlQuestionnaireData,
			segmentResult
		);

		return { segmentResultId };
	}, [iadlQuestionnaireData]);

	const { data: qnrData, isLoading: qnrLoading } = useQuestionnaireQuery(
		iadlQuestionnaireData?.batteryResultById?.id ?? '',
		segmentResultId ?? ''
	);

	const [reportData, setReportData] = useState<IadlParsedData | null>(
		mockedReportData ?? null
	);

	useEffect(() => {
		if (qnrData) {
			const score = getQuestionnaireScore(qnrData) as number;
			const qnrRowData = parseQuestions(qnrData);
			setReportData({
				tableData: qnrRowData,
				score,
			});
		}
	}, [qnrData]);

	if (!mockedReportData && qnrLoading) {
		return (
			<LoadingLayout
				title={`web.report.${type}.iadlReport`}
				tooltipText={`web.report.${type}.tooltip`}
			/>
		);
	}

	if (!reportData) {
		return (
			<RCRLayout
				description='web.report.unknown'
				title={`web.report.${type}.iadlReport`}
				tooltipText={`web.report.${type}.tooltip`}
			/>
		);
	}

	return (
		<IadlLayoutContainer>
			<SummaryCard type={type} score={reportData.score} />
			<IadlTableCard type={type} tableData={reportData.tableData} />
		</IadlLayoutContainer>
	);
}
