import { useDisclosure } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { t } from 'i18n';

import { AnalyticsAction, sendEventData } from 'analytics';
import { PatientScores } from 'components/patients/PatientDetails/PatientDetails_V2/PatientDetails.fixtures';
import { theme } from 'components/providers/StyleProvider/theme';
import { Caption } from 'components/shared/designSystem';
import { PathwayType } from 'generated/graphql';

import { ConcernDetailsModal, ReferencesModal } from '../Modals';
import { Table } from '../Pathway.table';
import { PathwayConcern, PathwayConcernID, PathwayID } from '../Pathway.types';
import { getConcern } from './Concern';
import { concernsMap } from './Concerns.data';

interface Props {
	ids: PathwayConcernID[];
	pathwayId: PathwayType | PathwayID;
	firstName?: string;
	scores?: PatientScores;
	onClick: {
		BHAP(): void;
	};
}

export function Concerns({
	ids,
	onClick,
	pathwayId,
	firstName,
	scores,
}: Props) {
	const [concernDetails, setConcernDetails] =
		useState<PathwayConcernID | null>(null);
	const [showReferences, references] = useDisclosure(false);

	const columns = [t('cds.concerns.findings'), t('cds.concerns.nextSteps')];
	const data = useMemo(() => ids.map((id) => concernsMap[id]), [ids]);

	function handleViewDetailsPress(row: PathwayConcern) {
		setConcernDetails(row.id);
		sendEventData({
			eventType: AnalyticsAction.ClickedConcernsDetails,
			eventProperties: {
				concernId: row.id,
			},
		});
	}

	function handleReferencesClick() {
		sendEventData({
			eventType: AnalyticsAction.ClickedRef,
			eventProperties: {
				source: 'Concern details',
			},
		});

		references.open();
	}

	return (
		<>
			<Table
				columns={columns}
				data={data}
				renderColumn={(column) => (
					<Column key={column}>{column}</Column>
				)}
				renderRow={(row) => (
					<Row
						pathwayId={pathwayId}
						key={row.id}
						concern={row}
						onClick={{
							BHAP: onClick.BHAP,
						}}
						onViewDetails={() => handleViewDetailsPress(row)}
					/>
				)}
			/>

			{concernDetails && !showReferences && (
				<ConcernDetailsModal
					firstName={firstName}
					id={concernDetails}
					scores={scores}
					onClick={{ references: handleReferencesClick }}
					onClose={() => setConcernDetails(null)}
				/>
			)}
			{showReferences && (
				<ReferencesModal
					concernId={concernDetails ?? undefined}
					onBack={references.close}
					onClose={() => {
						references.close();
						setConcernDetails(null);
					}}
				/>
			)}
		</>
	);
}

function Column({ children }: { children: React.ReactNode }) {
	return (
		<Table.Column>
			<Caption
				data-testid='concerns-table-column'
				style={{ fontWeight: theme.weight.medium }}
			>
				{children}
			</Caption>
		</Table.Column>
	);
}

function Row({
	concern,
	pathwayId,
	onClick,
	onViewDetails,
}: {
	concern: PathwayConcern;
	pathwayId: PathwayType | PathwayID;
	onClick: {
		BHAP(): void;
	};
	onViewDetails(): void;
}) {
	const { Summary, NextSteps } = getConcern(concern, pathwayId);

	return (
		<Table.Row>
			<Table.Cell width={350}>
				<Summary onViewDetails={onViewDetails} />
			</Table.Cell>
			<Table.Cell>
				<NextSteps onClick={onClick} />
			</Table.Cell>
		</Table.Row>
	);
}
