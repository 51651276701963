import { Link } from 'react-router-dom';
import styled, { DefaultTheme, css } from 'styled-components';

export const StyledIconContainer = styled.div`
	margin: 0 12px;
`;

export const StyledContainer = styled.div(
	({ theme: { color, spacing } }) => css`
		background-color: ${color.white};
		border-radius: 20px;
		padding: ${spacing.xl};
		margin: 0 auto ${spacing.xl};
	`
);

export const StyledLabel = styled.span`
	margin-left: 12px;
`;
export const StyledAddress = styled.span`
	width: 190px;
	margin-left: 12px;
	display: flex;
	flex-direction: column;
`;

export const StyledAddressLine = styled.span``;

interface StyledConsultantProps {
	theme: DefaultTheme;
}

export const StyledConsultant = styled.span(
	({ theme: { spacing } }: StyledConsultantProps) => css`
		margin-top: ${spacing.sm};
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
	`
);

export const StyledRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const StyledHeader = styled.div(
	({ theme: { fontSize, spacing } }) => css`
		font-size: ${fontSize._32};
		margin-bottom: ${spacing.md};
	`
);

export const StyledBoldTitle = styled.div(
	({ theme: { weight, spacing } }) => css`
		display: flex;
		font-weight: ${weight.bold};
		margin-top: ${spacing.xl};
		img {
			margin-right: ${spacing.sm};
		}
	`
);

export const StyledIsoImage = styled.img(
	({ theme: { spacing } }) => css`
		margin-left: ${spacing.md};
	`
);

export const StyledSectionTitle = styled.div(
	({ theme: { color, weight, spacing } }) => css`
		background-color: ${color.regulatoryTitleBar};
		padding: ${spacing.sm} ${spacing.md};
		font-weight: ${weight.bold};
		margin-top: ${spacing.xl};
		margin-bottom: ${spacing.sm};
	`
);

export const BottomRowImg = styled.img(
	({ theme: { spacing } }) => css`
		margin: ${spacing.md} 12px;
	`
);

export const BarcodeImage = styled.img(
	({ theme: { spacing } }) => css`
		margin: 0 ${spacing.lg};
		box-sizing: border-box;
	`
);

export const StyledLink = styled(Link)`
	text-decoration: underline;
`;
