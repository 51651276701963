import { useTranslation } from 'react-i18next';

import { theme } from 'components/providers/StyleProvider/theme';
import { List, tMany } from 'components/report/CDS/Pathway/Pathway.misc';
import { Accordion } from 'components/shared/Accordion';
import { LineDivider } from 'components/shared/LineDivider';
import { LinusModal } from 'components/shared/LinusModal';
import { ButtonSm, P1 } from 'components/shared/designSystem';

import {
	ButtonWrapper,
	Container,
	InterpretationContainer,
	InterpretationTitle,
	LineWrapper,
	P1Med,
	ReferenceWrapper,
	Score,
	ScoreRow,
	ScoreWrapper,
} from './MipDetailsModal.style';

type FicDetailsModalProps = {
	onClose: () => void;
};
export function FicDetailsModal({ onClose }: Readonly<FicDetailsModalProps>) {
	const { t } = useTranslation();

	return (
		<LinusModal
			onClose={onClose}
			title={t('web.report.fic.details.title')}
			width='760px'
		>
			<Container>
				<P1>{t('web.report.fic.details.paragraph1')}</P1>
				<LineWrapper>
					<LineDivider />
				</LineWrapper>
				<ScoreRanges />
				<ReferenceWrapper>
					<ReferencesAccordion />
				</ReferenceWrapper>
				<ButtonWrapper>
					<ButtonSm
						primary
						text={t`web.shared.close`}
						width='160px'
						onClick={onClose}
					/>
				</ButtonWrapper>
			</Container>
		</LinusModal>
	);
}

function ScoreRanges() {
	const { t } = useTranslation();

	return (
		<InterpretationContainer>
			<InterpretationTitle>
				{t('web.report.fic.details.table.title')}
			</InterpretationTitle>
			<ScoreWrapper>
				<ScoreRow>
					<Score
						backgroundColor={theme.colors.green_lightest}
						color={theme.colors.green_darkest}
					>
						{t('web.report.fic.green')}
					</Score>
					<P1>{t('web.report.fic.details.table.noConcern')}</P1>
				</ScoreRow>
				<ScoreRow>
					<Score
						backgroundColor={theme.colors.yellow_lightest}
						color={theme.colors.yellow_darkest}
					>
						{t('web.report.fic.yellow')}
					</Score>
					<P1>{t('web.report.fic.details.table.minorConcern')}</P1>
				</ScoreRow>
				<ScoreRow>
					<Score
						backgroundColor={theme.colors.orange_lightest}
						color={theme.colors.orange_darkest}
					>
						{t('web.report.fic.red')}
					</Score>
					<P1>{t('web.report.fic.details.table.majorConcern')}</P1>
				</ScoreRow>
			</ScoreWrapper>
		</InterpretationContainer>
	);
}

function ReferencesAccordion() {
	const { t } = useTranslation();
	const content = tMany('web.report.fic.details.table.references.content');

	return (
		<Accordion
			title={
				<P1Med>
					{t('web.report.fic.details.table.references.title')}
				</P1Med>
			}
		>
			<List type='ordered' listStyleType='decimal' gap={4}>
				{content.map((value) => (
					<List.Item
						key={value}
						styles={{
							color: theme.colors.gray_30,
						}}
					>
						{value}
					</List.Item>
				))}
			</List>
		</Accordion>
	);
}
