import React from 'react';

interface Props {
	children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
export const Memoize = React.memo<Props>(function ({ children }) {
	return <>{children}</>;
});
